import fetch from "@/axios/fetch"

export function prodList(params) { // 商品列表
	return fetch({
		url: "/product/status",
		method: "get",
		params: params
	})
}

export function wareProdList(params) { // 仓库中的商品列表
	return fetch({
		url: "/product/warehouse/search",
		method: "get",
		params: params
	})
}

export function saleProdList(params) { // 出售中的商品列表
	return fetch({
		url: "/product/sale/search",
		method: "get",
		params: params
	})
}

export function createProd(data) { // 新增商品
	return fetch({
		url: "/product",
		method: "post",
		data
	})
}

export function editProd(data) { // 修改商品
	return fetch({
		url: "/product",
		method: "put",
		data
	})
}

export function deleteProd(id) { // 删除商品
	return fetch({
		url: "/product/" + id,
		method: "delete"
	})
}

export function prodDetail(id, params) { // 商品详情
	return fetch({
		url: "/product/" + id,
		method: "get",
		params
	})
}

export function changeProdStatus(id) { // 修改商品状态
	return fetch({
		url: "/product/status/" + id,
		method: "put"
	})
}

export function shelfProd(data) { // 商品上架
	return fetch({
		url: "/product/shelf",
		method: "post",
		data
	})
}

export function shelfProdInfo(id) { // 获取商品上架的详情
	return fetch({
		url: "/product/shelf/" + id,
		method: "get"
	})
}

export function delProd(data) { // 商品下架
	return fetch({
		url: "/product/del/shelf",
		method: "put",
		data
	})
}
export function addShelf(data) { // 追加商品（增加库存）
	return fetch({
		url: "/product/addOn/shelf",
		method: "put",
		data
	})
}

export function uninstalledProdList(params) { // 未定价/未分类商品列表
	return fetch({
		url: "/product/uninstalled/search",
		method: "get",
		params: params
	})
}

export function goodsPriceSetting(data) { // 商品定价/划分类别
	return fetch({
		url: "/product/installed",
		method: "put",
		data
	})
}

export function getProductProdDetail(id) { // 未定价/未分类商品列表
	return fetch({
		url: "/product/prod/" + id,
		method: "get"
	})
}

export function batchMoveToStationPool(data) { // 批量移入商品池
	return fetch({
		url: "/product/up/station",
		method: "put",
		data
	})
}
export function exportSaleGoodsList(params) { // 导出商品列表
	return fetch({
		url: "/product/sale/export",
		method: "get",
		responseType: "blob",
		params
	})
}
export function exportWareGoodsList(params) { // 导出仓库列表
	return fetch({
		url: "/product/warehouse/export",
		method: "get",
		responseType: "blob",
		params
	})
}

export function batchGoodsOn(data) { // 批量上架
	return fetch({
		url: "/product/shelf-products",
		method: "post",
		data
	})
}

export function batchMoveOffStationPool(data) { // 批量移出
	return fetch({
		url: "/product/move/station",
		method: "put",
		data
	})
}

export function goodsForSales(params) { // 销量排列榜商品列表
	return fetch({
		url: "/product/search",
		method: "get",
		params
	})
}

export function saveGoodsVirtualSales(data) { // 保存商品虚拟销量
	return fetch({
		url: "/product/update_virtual_sales",
		method: "put",
		data
	})
}

// ================================================================================================================ 虚拟商品管理

export function saveVirtual(data) { // 修改商品
	return fetch({
		url: "/virtual",
		method: "put",
		data
	})
}
export function virtualGoodsInstalled(data) { // 商品定价/划分类别
	return fetch({
		url: "/virtual/installed",
		method: "put",
		data
	})
}
export function virtualSearchList(params) { // 查询商品列表
	return fetch({
		url: "/virtual/search",
		method: "get",
		params
	})
}
export function virtualShelves(data) { // 批量上/下架
	return fetch({
		url: "/virtual/shelves",
		method: "post",
		data
	})
}
export function virtualStatusList(params) { // 商品回收站列表
	return fetch({
		url: "/virtual/status",
		method: "get",
		params
	})
}
export function virtualSync(data) { // 同步虚拟商品
	return fetch({
		url: "/virtual/sync",
		method: "post",
		data
	})
}
export function getVirtualGoodsDetail(id, params) { // 通过id查找虚拟商品详情及以下sku详情
	return fetch({
		url: `/virtual/${id}`,
		method: "get",
		params
	})
}
export function deleteVirtualGoods(id) { // 删除虚拟商品
	return fetch({
		url: `/virtual/${id}`,
		method: "delete"
	})
}