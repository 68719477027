<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" size="40%">
		<el-form
			ref="formValidate"
			v-loading="loading"
			:model="formValidate"
			:rules="Rules"
			label-width="150px"
			class="demo-ruleForm"
		>
			<el-form-item label="等级名称：" prop="name">
				<mvk-input v-model.trim="formValidate.name" placeholder="请输入等级名称" class="inputWidth" />
			</el-form-item>
			<el-form-item label="等级：" prop="num">
				<el-input v-model.number="formValidate.num" placeholder="请输入等级" class="inputWidth" />
			</el-form-item>
			<el-form-item label="享受折扣(%)：" prop="discount">
				<el-input-number
					v-model="formValidate.discount"
					:min="0"
					:max="100"
					step-strictly
					placeholder="请输入享受折扣"
				/>
			</el-form-item>
			<!-- <el-form-item label="积分：" prop="integral">
                <el-input
                    v-model.number="formValidate.integral"
                    type="number"
                    placeholder="请输入积分"
                    class="inputWidth"
                />
            </el-form-item> -->
			<el-form-item label="等级条件：" prop="condition">
				<div>
					<div>
						<el-checkbox v-model="formValidate.condition.integral"> </el-checkbox>
						<span style="color: #606266; padding-left: 10px">
							条件一：积分达到
							<!-- <el-input v-model.number="formValidate.integral" type="number" min="0" placeholder="积分" /> -->
							<el-input-number
								v-model="formValidate.integral"
								:min="0"
								step-strictly
								placeholder="积分"
								style="margin: 0 8px"
							/>
							分
						</span>
					</div>
					<div>
						<el-checkbox v-model="formValidate.condition.turnover"> </el-checkbox>
						<span style="color: #606266; padding-left: 10px">
							条件二：交易额达到
							<!-- <el-input
								v-model.number="formValidate.turnover"
								type="number"
								min="0"
								placeholder="交易额"
								style="width: 100px; margin: 0 8px"
							/> -->
							<el-input-number
								v-model="formValidate.turnover"
								:min="0"
								:precision="2"
								step-strictly
								placeholder="交易额"
								style="margin: 0 8px"
							/>
							元
						</span>
					</div>
					<div>
						<el-checkbox v-model="formValidate.condition.trades"> </el-checkbox>
						<span style="color: #606266; padding-left: 10px">
							条件三：交易次数达到
							<!-- <el-input
								v-model.number="formValidate.trades"
								type="number"
								min="0"
								placeholder="交易次数"
								style="margin: 0 8px"
							/> -->
							<el-input-number
								v-model="formValidate.trades"
								:min="0"
								step-strictly
								placeholder="交易次数"
								style="margin: 0 8px"
							/>
							次
						</span>
					</div>

					<div style="color: #606266">注：以上条件可多选，会员满足其中一个即可升级</div>
				</div>
			</el-form-item>
			<el-form-item label="图标：" prop="logo">
				<input-upload-image :image-base64="formValidate.logo" @imageValue="getImageBase64" />
			</el-form-item>
			<el-form-item label="是否显示：" required>
				<el-radio-group v-model="formValidate.display">
					<el-radio :label="true" class="radio">显示</el-radio>
					<el-radio :label="false">隐藏</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" round @click="submitForm('formValidate')">确 定</el-button>
				<el-button round @click="resetForm('formValidate')">取 消</el-button>
			</el-form-item>
		</el-form>
	</el-drawer>
</template>

<script>
import { addGrade, editGrade } from "api/business/member/grade.js"
import InputUploadImage from "views/components/InputUploadImage"

const obj = {
	name: "",
	num: 1, // 等级
	discount: "",
	logo: "",
	display: true,
	id: null,
	integral: null, // 积分
	turnover: null, //交易额
	trades: null, //交易次数
	condition: {
		integral: false,
		turnover: false,
		trades: false
	} //等级条件
}

export default {
	name: "GradeAddEdit",
	components: {
		InputUploadImage
	},
	data() {
		return {
			title: "新增",
			addEditDialog: false,
			formValidate: Object.assign({}, obj),
			Rules: {
				name: [{ required: true, message: "请输入等级名称", trigger: "blur" }],
				num: [
					{ required: true, message: "请输入等级", trigger: "blur" },
					{ type: "number", message: "等级必须为数字值" }
				],
				discount: [{ required: true, message: "请输入折扣", trigger: "blur" }],
				experience: [
					{ required: true, message: "请输入经验", trigger: "blur" },
					{ type: "number", message: "经验必须为数字值" }
				],
				logo: [{ required: true, message: "请上传图标", trigger: "change" }],
				condition: [
					{
						required: true,
						message: "选择等级条件",
						trigger: "change"
					},
					{
						validator: (rule, value, callback) => {
							if (!value.integral && !value.turnover && !value.trades) {
								callback(new Error("选择等级条件"))
							} else {
								console.log(this.formValidate.integral)
								console.log(this.formValidate.integral == "")
								if (value.integral && this.formValidate.integral == null) {
									callback(new Error("请输入积分"))
								}
								if (value.turnover && this.formValidate.turnover == null) {
									callback(new Error("请输入交易额"))
								}
								if (value.trades && this.formValidate.trades == null) {
									callback(new Error("请输入交易次数"))
								}

								if (
									(value.integral && this.formValidate.integral) ||
									(value.turnover && this.formValidate.turnover) ||
									(value.trades && this.formValidate.trades)
								) {
									callback()
								}
							}
						}
					}
				]
			},
			loading: false,

			integral: false
		}
	},
	computed: {},
	methods: {
		add() {
			this.addEditDialog = true
			this.formValidate = JSON.parse(JSON.stringify(obj))
		},
		edit(record) {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.formValidate = JSON.parse(JSON.stringify(record))

				let condition = {}
				if (this.formValidate.integral != null) {
					condition.integral = true
				} else {
					condition.integral = false
				}

				if (this.formValidate.turnover != null) {
					condition.turnover = true
				} else {
					condition.turnover = false
				}

				if (this.formValidate.trades != null) {
					condition.trades = true
				} else {
					condition.trades = false
				}

				this.$set(this.formValidate, "condition", condition)
			})
		},

		getImageBase64(value) {
			this.formValidate.logo = value
		},

		submitForm(formName) {
			if (!this.formValidate.condition.integral) {
				this.formValidate.integral = null
			}

			if (!this.formValidate.condition.turnover) {
				this.formValidate.turnover = null
			}

			if (!this.formValidate.condition.trades) {
				this.formValidate.trades = null
			}

			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.loading = true
					this.formValidate.id
						? editGrade(this.formValidate)
								.then(() => {
									this.$notify.success({
										title: "成功",
										message: "编辑成功"
									})
									this.loading = false
									this.addEditDialog = false
									this.formValidate = Object.assign({}, obj)
									this.$emit("saveSuccess")
								})
								.catch(() => {
									this.loading = false
								})
						: addGrade(this.formValidate)
								.then(() => {
									this.loading = false
									this.$notify.success({
										title: "成功",
										message: "添加成功"
									})
									this.addEditDialog = false
									this.$emit("saveSuccess")
								})
								.catch(() => {
									this.loading = false
								})
				} else {
					return false
				}
			})
		},

		resetForm(formName) {
			this.addEditDialog = false
			this.$refs[formName].resetFields()
		}
	},
	mounted() {}
}
</script>

<style lang="scss" scoped></style>
