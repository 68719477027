import { render, staticRenderFns } from "./pickDetail.vue?vue&type=template&id=598366bf&scoped=true"
import script from "./pickDetail.vue?vue&type=script&lang=js"
export * from "./pickDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "598366bf",
  null
  
)

export default component.exports