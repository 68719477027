<template>
	<el-dialog title="导出 - 页码选择" :visible="value" width="830px" top="10vh" @close="close()">
		<el-form ref="memberForm" :model="memberForm" label-width="110px" :inline="false">
			<el-form-item label="订单状态：">
				<el-radio-group v-model="filterStatus" type="button" size="mini">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button v-for="(val, key, index) in orderStatus" :key="index" :label="key">
						{{ val }}
					</el-radio-button>
				</el-radio-group>
			</el-form-item>
			<div v-if="isPage">
				<el-form-item label="分页" prop="pageNum">
					<el-input-number v-model="memberForm.pageNum" :min="1" :max="999" />
				</el-form-item>
				<el-form-item label="页码" prop="memberMobile">
					<el-select v-model="memberForm.pageSize" placeholder="所属供应商" clearable filterable
						style="min-width: 220px">
						<el-option v-for="num in pageSizeList" :key="num" :label="num" :value="num" />
					</el-select>
				</el-form-item>
			</div>
			<el-form-item label="时间区间：">
				<el-radio-group v-model="dateLimit" type="button" class="mr20" size="mini" @change="onchangeDate">
					<el-radio-button v-for="(item, i) in dateList" :key="i" :label="item.value">
						{{ item.text }}
					</el-radio-button>
				</el-radio-group>
				<el-date-picker v-model="timeVal" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="mini" type="daterange"
					:default-value="timeDefaultShow" :picker-options="pickerOptions" placement="bottom-end"
					start-placeholder="开始时间" end-placeholder="结束时间" style="width: 220px" @change="onchangeTime" />
			</el-form-item>

			<el-row>
				<el-col :span="11">
					<el-form-item label="订单号：">
						<el-input v-model="queryParam.code" placeholder="请输入订单号" size="small" clearable />
					</el-form-item>
				</el-col>
				<el-col :span="13">
					<el-form-item label="订单类型：">
						<el-select v-model="queryParam.type" placeholder="订单类型" clearable filterable style="width: 100%">
							<el-option v-for="(item, index) in Object.values(orderTypeList)" :key="index" :label="item"
								:value="index" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="11">
					<el-form-item label="商品名称：">
						<el-input v-model="queryParam.productName" placeholder="请输入商品名称" size="small" clearable />
					</el-form-item>
				</el-col>
				<el-col :span="13">
					<el-form-item label="所属供应商：">
						<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable
							style="width: 100%">
							<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="收货人号码：">
						<el-input v-model="queryParam.receiptMobile" placeholder="请输入收货人手机号码" size="small" clearable />
					</el-form-item>
				</el-col>
				<el-col :span="13">
					<el-form-item label="支付方式：">
						<el-select v-model="queryParam.payType" placeholder="支付方式" clearable filterable style="width: 100%">
							<el-option v-for="(item, index) in Object.values(payTypeList)" :key="index" :label="item"
								:value="index" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="11">
					<el-form-item label="会员手机号：">
						<el-input v-model="queryParam.memberPhone" placeholder="请输入会员手机号码" size="small" clearable />
					</el-form-item>
				</el-col>
				<el-col :span="13">
				<el-form-item label="所属组织：">
					<organizationTreeSelect v-model="queryParam.organId" style="width: 100%" />
				</el-form-item>
				</el-col>
			</el-row>
			<div v-if="tips" class="tips">
				{{ tips }}
			</div>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="close()">关闭</el-button>
			<el-button type="primary" :loading="loading" @click="onSubmit('memberForm')">导出</el-button>
		</span>
	</el-dialog>
</template>

<script>
import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"
import { orderStatus, payType, orderType, dateTimeList } from "@/utils/constants"
import { mapState, mapActions } from "vuex"
import { GetDateTimeValue } from "@/utils/dateUtils"

export default {
	name: "ExportOption",
	components: {
		organizationTreeSelect
	},
	props: {
		value: {
			type: Boolean,
			default: false
		},
		tips: {
			type: String,
			default: ""
		},
		isPage: {
			type: Boolean,
			default: false
		}
	},
	model: {
		prop: "value",
		event: "change"
	},
	data() {
		return {
			dateList: dateTimeList,
			payTypeList: payType,
			orderTypeList: orderType,
			orderStatus,
			// 导出-页码选择
			loading: false,
			pageSizeList: [50, 100, 200, 500, 1000],
			memberForm: {
				pageNum: 0,
				pageSize: 50
			},

			dateLimit: "today",
			timeDefaultShow: "",
			timeVal: [],
			pickerOptions: {
				disabledDate(time) {
					let curDate = new Date().getTime()
					let three = 90 * 24 * 3600 * 1000
					let threeMonths = curDate - three
					// eslint-disable-next-line no-unused-vars
					var thatDay = time.getTime()
					return thatDay > Date.now() // || thatDay < threeMonths
				}
			},
			filterStatus: '',
			queryParam: {
				status: "", // 订单状态
				subStatus: "", // 订单子状态
				code: "", // 订单号
				productName: "", // 商品名称
				type: "", // 订单类型
				supplierId: "", // 供应商ID
				payType: "", // 支付类型
				receiptMobile: "", // 收货人手机号
				memberPhone: "", // 会员手机号
				startTime: "", // 开始时间
				endTime: "" // 结束时间
			}
		}
	},
	computed: {
		...mapState("dict", ["shoplist", "supplierlist"])
	},
	mounted() {
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
		this.onchangeDate("today")
		this.timeDefaultShow = new Date()
		this.timeDefaultShow.setMonth(new Date().getMonth() - 1)

	},
	watch: {
		filterStatus(newVal) {
			console.log(newVal)
			const newValue = Number(newVal)
			let mainStatus = [1, 2, 3, 5, 6, 11, 13] // 主状态
			let afterSaleStatus = [7, 8, 10, 12] // 申请退款，退款中
			if (mainStatus.indexOf(newValue) > -1) {
				this.queryParam.status = newValue
				this.queryParam.subStatus = ""
			} else if (afterSaleStatus.indexOf(newValue) > -1) {
				this.queryParam.status = ""
				this.queryParam.subStatus = newValue
			} else if (newValue == 4) {
				// 待评价
				this.queryParam.status = 5
				this.queryParam.subStatus = newValue
			} else if (newValue == 9) {
				// 已退款
				this.queryParam.status = 6
				this.queryParam.subStatus = newValue
			} else {
				this.queryParam.status = ""
				this.queryParam.subStatus = ""
			}
		}
	},
	methods: {
		...mapActions("dict", ["getSupplierList"]),

		close() {
			this.$emit("change", false)
		},
		onSubmit() {
			this.loading = true
			let params = JSON.parse(JSON.stringify(this.queryParam))
			if (this.isPage) { params = Object.assign(params, JSON.parse(JSON.stringify(this.memberForm))); params.pageNum -= 1 }

			setTimeout(() => {
				this.loading = false
				this.close()
			}, 1000)
			this.$emit("submit", params)
		},

		onchangeDate(val) {
			if (val != "") {
				let time = GetDateTimeValue(val, "YYYY-MM-DD")
				this.queryParam.startTime = time[0]
				this.queryParam.endTime = time[1]
			} else {
				this.queryParam.startTime = ""
				this.queryParam.endTime = ""
			}

			this.timeVal = []
		},
		onchangeTime(e) {
			this.timeVal = e
			this.dateLimit = e ? null : ""
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		}
	}
}
</script>

<style lang="scss" scoped>
.tips {
	margin-left: 80px;
	margin-bottom: -10px;
	font-size: 13px;
	color: #888;
}
</style>
