<template>
	<div class="mvk-layout-content-card">
		<div class="flex">
			<el-form size="small" label-width="120px" :inline="true">
				<el-button type="primary" class="mb20" @click="handleExport()"> 下载报表 </el-button>
				<el-button type="primary" class="mb20" :disabled="!checkList.length" @click="handleExportDetailForSelect()">
					批量导出明细 <span v-if="checkList.length">({{ checkList.length }})</span></el-button>
				<el-form-item label="所属供应商：">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
						<el-option v-for="(item,index) in supplierlist" :key="index" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="报表编号：">
					<el-input v-model="queryParam.reportCode" placeholder="报表编号" clearable />
				</el-form-item>

				<el-radio-group v-model="queryParam.dateType" class="ml20">
					<el-radio-button label="1">生成时间</el-radio-button>
					<el-radio-button label="2">结算时间</el-radio-button>
				</el-radio-group>
				<el-date-picker v-model="timeVal" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
					type="daterange" placement="bottom-end" placeholder="统计时间" class="ml10" style="width: 220px"
					@change="onchangeTime" />
			</el-form>
		</div>
		<div class="flex flex-sb">
			<el-tabs v-model="isSettle" class="flex-1">
				<el-tab-pane label="全部" name="-1" />
				<el-tab-pane label="结算中" name="1" />
				<el-tab-pane label="已结算" name="2" />
			</el-tabs>
			<div>
				<el-button :disabled="!checkList.length" type="primary" @click="submitCancal">取消结算</el-button>
			</div>
		</div>
		<mvk-table ref="mvkTable" :table-column="isSettle==='1' ? tableColumn2 : tableColumn" :table-data-func="tableDataFunc" border height="540"
			row-key="id" @select="selectItem" @select-all="selectAll">
			<el-table-column slot="head" type="selection" :selectable="selectable" reserve-selection width="40" />
			<el-table-column label="操作" width="110" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleExportDetail(row.reportCode,row.taxRate)">
						导出明细
					</el-button>
					<!-- <el-button type="text" size="small" @click="handleEdit(row)"> 取消 </el-button> -->
					<!-- <delete-button v-if="row.isSettle != '2'" :id="row.reportCode" button-type="text" size="small"
						delete-text="取消后已生成的报表将退回到订单结算，同时结算明细表同步退回到订单" button-text="取消" @delete="handleCancel(row.id)" /> -->
					<el-button v-if="row.isSettle != '2'" type="text" size="small" style="margin-left: 8px"
						@click="handleOpenSettleDialog(row)">
						结算
					</el-button>
				</template>
			</el-table-column>
			<template slot="footer">
				<el-button :disabled="checkList.length == 0" size="small" @click="clearSelectData()">
					取消全部勾选
				</el-button>
			</template>
		</mvk-table>
		<el-dialog title="订单结算" :visible.sync="dialogVisible" width="50%" top="50px" :close-on-click-modal="false">
			<el-descriptions size="medium">
				<el-descriptions-item label="报表编号">{{ currentInfo.reportCode }}</el-descriptions-item>
				<el-descriptions-item label="供应商">{{ currentInfo.supplierName }}</el-descriptions-item>
				<!-- <el-descriptions-item label="统计时段">{{ currentInfo.countTime }}</el-descriptions-item> -->
				<el-descriptions-item label="结算成本(含税）">{{ currentInfo.cosPrice }}</el-descriptions-item>
			</el-descriptions>
			<p class="mb5">结算备注：</p>
			<el-input v-model="settleRemarks" type="textarea" :rows="2" placeholder="请输入备注" />
			<p style="color: #f56c6c; margin-top: 8px">注意：结算后不可撤回，请谨慎操作</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleSettle">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {
	getSettleReport,
	getExportSettlement,
	getExportReportDetail,
	orderSettle,
	cancelReport
} from "api/business/financeManage/settle.js"
import { blobTofile } from "@/utils/utils"
import { mapState, mapActions } from "vuex"
import { GetDateTimeValue } from "@/utils/dateUtils"
const settleStatus = {
	0: "未结算",
	1: "结算中",
	2: "已结算"
}

export default {
	name: "SettleDetail",
	computed: {
		...mapState("dict", ["supplierlist"])
	},
	data() {
		return {
			isSettle: "-1",
			queryParam: {
				dateType: "1",
				supplierId: "",
				startTime: "",
				endTime: "",
				isSettle: ""
			},
			tableColumn: [
				{ prop: "reportCode", label: "报表编号", width: 176 },
				{ prop: "supplierName", label: "供应商", width: 200 },
				{
					prop: "cosPrice", label: "结算成本（含税）", width: 90,
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.cosPrice)
					}
				},
				{
					prop: "payMoney", label: "销售收入（含税）",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.payMoney)
					}
				},
				// { prop: "payMoney", label: "应收收入（含税）",
				// 	formatter: cellValue => {
				// 		return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.payMoney)
				// 	} },
				{
					prop: "refundPayMoney",
					label: "退款金额（含税） ",
					formatter: row => {
						return Number(row.refundPayMoney) == 0 ? 0 : "-" + row.refundPayMoney
					}
				},
				{
					prop: "refundCosPrice",
					label: "退款成本",
					width: 76,
					formatter: row => {
						return row.refundCosPrice
					}
				},
				{
					prop: "actualMoney", label: "支付金额", align: "right",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.actualMoney)
					}
				},
				{
					prop: "couponMoney", label: "优惠金额", align: "right",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.couponMoney)
					}
				},
				{
					prop: "taxRate", label: "税率", align: "right", width: 56,
					formatter: cellValue => {
				const taxRate = cellValue.taxRate && cellValue.taxRate * 100		
						return taxRate + '%'
					}
				},
				{ prop: "productNum", label: "销售商品数", width: 86, align: "center" },
				{ prop: "orderNum", label: "订单数", width: 60, align: "center" },
				{
					prop: "isSettle",
					label: "结算状态",
					width: 72, align: "center",
					formatter: row => {
						return settleStatus[row.isSettle]
					}
				},
				{ prop: "creationDate", label: "生成时间" },
				{ prop: "settleTime", label: "结算时间" },
				// { prop: "countTime", label: "统计时段" },
				{ prop: "settleRemarks", label: "结算备注" }
			],
			tableColumn2: [
				{ prop: "reportCode", label: "报表编号", width: 176 },
				{ prop: "supplierName", label: "供应商", width: 200 },
				{
					prop: "cosPrice", label: "结算成本（含税）", width: 90,
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.cosPrice)
					}
				},
				{
					prop: "payMoney", label: "销售收入（含税）",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.payMoney)
					}
				},
				{
					prop: "refundPayMoney",
					label: "退款金额（含税） ",
					formatter: row => {
						return Number(row.refundPayMoney) == 0 ? 0 : "-" + row.refundPayMoney
					}
				},
				{
					prop: "refundCosPrice",
					label: "退款成本",
					formatter: row => {
						return row.refundCosPrice
					}
				},
				{
					prop: "actualMoney", label: "支付金额", align: "right",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.actualMoney)
					}
				},
				{
					prop: "couponMoney", label: "优惠金额", align: "right",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.couponMoney)
					}
				},
				{ prop: "productNum", label: "销售商品数", width: 86, align: "center" },
				{ prop: "orderNum", label: "订单数", width: 60, align: "center" },
				{
					prop: "isSettle",
					label: "结算状态",
					width: 72, align: "center",
					formatter: row => {
						return row.isSettle && settleStatus[row.isSettle]
					}
				},
				{ prop: "creationDate", label: "生成时间" }
			],
			tableDataFunc: parameter => {
				this.pageNum = parameter.pageNum
				this.pageSize = parameter.pageSize
				return getSettleReport(Object.assign(parameter, this.queryParam)).then(res => {
					return res?.data || []
				})
			},
			timeVal: [],

			dialogVisible: false,
			currentInfo: {}, // 当前选中的数据
			settleRemarks: "",

			pageNum: 0, // 只用于导出传参
			pageSize: 0, // 只用于导出传参

			// selectkIds: [], // 普通勾选
			checkList: [], // 分页勾选
			checkListLength: 0
		}
	},
	watch: {
		queryParam: {
			handler() {
				if (
					(this.queryParam.startTime && this.queryParam.dateType) ||
					(!this.queryParam.startTime && !this.queryParam.dateType)
				) {
					this.searchList()
				}
			},
			deep: true
		},
		isSettle(newVal) {
			if (newVal == "-1") {
				this.queryParam.isSettle = ""
			} else {
				this.queryParam.isSettle = newVal
			}
		},
		checkList: {
			handler() {
				this.checkListLength = this.checkList.length
			},
			deep: true
		}
	},
	created() {
		this.timeVal = GetDateTimeValue("month", "YYYY-MM-DD")
		this.queryParam.startTime = GetDateTimeValue("month", "YYYY-MM-DD")[0]
		this.queryParam.endTime = GetDateTimeValue("month", "YYYY-MM-DD")[1]
	},
	mounted() {
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	},
	methods: {
		...mapActions("dict", ["getSupplierList"]),

		searchList() {
			this.$nextTick(() => {
				this.$refs.mvkTable.refresh()
			})
		},

		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},

		handleExport() {
			getExportSettlement(Object.assign(this.queryParam, { pageNum: this.pageNum, pageSize: this.pageSize })).then(res => {
				blobTofile(res, "供应商结算报表.xls", "application/vnd.ms-excel")
			})
		},
		handleExportDetail(reportCode,taxRate) {
			getExportReportDetail({
				reportCodes: reportCode
			}).then(res => {
				const fileRate = taxRate * 100 + '%'
				blobTofile(res, "供应商结算报表明细" + fileRate + ".xls", "application/vnd.ms-excel")
			})
		},
		handleExportDetailForSelect() {
			getExportReportDetail({
				reportCodes: this.checkList.join()
			}).then(res => {
				blobTofile(res, "供应商结算报表明细.xls", "application/vnd.ms-excel")
			})
		},

		handleOpenSettleDialog(row) {
			this.currentInfo = row
			this.dialogVisible = true
		},

		handleCancel(id) {
			cancelReport(id).then(() => {
				this.$notify({
					title: '取消成功',
					message: `编号${id}`,
					type: 'success'
				})
				this.searchList()
			})
		},
		// 批量取消结算
		submitCancal() {
			this.checkList.forEach(e => {
				this.handleCancel(e)
			})
		},

		handleSettle() {
			orderSettle({
				reportCode: this.currentInfo.reportCode,
				settleRemarks: this.settleRemarks
			}).then(() => {
				this.$notify.success("结算成功")
				this.searchList()
				this.dialogVisible = false
			})
		},
		selectItem(list) {
			this.checkList = list.map(e => e.reportCode)
		},
		selectAll(selection) {
			// 若取消全选，当前页的选中数据移除
			this.$nextTick(() => {
				if (selection.length === 0) {
					const ids = this.$refs.mvkTable.tableData.map(e => e.reportCode)
					for (var i = 0; i < this.checkList.length; i++) {
						if (ids.indexOf(this.checkList[i]) > -1) {
							this.checkList.splice(i, 1)
							i--
						}
					}
				}
				const selectionIds = selection.map(e => e.reportCode)
				// console.log("selectionIds", selectionIds)
				// console.log("...this.checkList", this.checkList)
				const arr = [...selectionIds, ...this.checkList]
				this.checkList = Array.from(new Set(arr))
			})
		},
		clearSelectData() {
			this.$nextTick(() => {
				this.$refs.mvkTable.clearSelection()
				this.checkList = []
			})
		}
		// handleSelectionChange(val) {
		// 	this.selectkIds = val.map(item => item.id)
		// }
	}
}
</script>

<style lang="scss" scoped></style>
