<template>
	<el-drawer title="缴费详情" :visible.sync="addEditDialog" size="50%">
		<el-form v-loading="loading" :model="dataForm" label-width="120px" :inline="false">
			<el-row :gutter="20">
				<el-col :span="12">
					<el-form-item label="订单号:">
						{{ dataForm.orderNo }}
					</el-form-item>
					<el-form-item label="第三方订单编号:">
						{{ dataForm.billId }}
					</el-form-item>
					<el-form-item label="商品编号:">
						{{ dataForm.itemId }}
					</el-form-item>
					<el-form-item label="商品名称:">
						{{ dataForm.itemName }}
					</el-form-item>
					<el-form-item label="商品类型:">
						{{ classTypeEnum[dataForm.classType] }}
					</el-form-item>
					<el-form-item label="商品成本价:">
						{{ dataForm.itemCost }}
					</el-form-item>
					<el-form-item label="商品数量:">
						{{ dataForm.itemNum }}
					</el-form-item>
					<el-form-item label="订单处理时间:">
						{{ dataForm.operateTime }}
					</el-form-item>
					<el-form-item label="订单成本(进价):">
						{{ dataForm.orderCost }}
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<!-- <el-form-item label="订单利润:">
                        {{ dataForm.orderProfit }}
                    </el-form-item> -->
					<el-form-item label="订单生成时间:">
						{{ dataForm.orderTime }}
					</el-form-item>
					<el-form-item label="订单付款状态:">
						{{ payStateEnum[dataForm.payState] }}
					</el-form-item>
					<el-form-item label="缴费项目:">
						{{ projectEnum[dataForm.projectId] }}
					</el-form-item>
					<el-form-item label="充值账号:">
						{{ dataForm.rechargeAccount }}
					</el-form-item>
					<el-form-item label="订单充值状态:">
						{{ typeEnum[dataForm.rechargeState] }}
						<!-- <span v-if="dataForm.rechargeAccount == 0">充值中</span>
						<span v-else-if="dataForm.rechargeAccount == 1">成功</span>
						<span v-else-if="dataForm.rechargeAccount == 9">撤销 </span>
						<span v-else-if="dataForm.rechargeAccount == 10">退款中 </span>
						<span v-else-if="dataForm.rechargeAccount == 11">退款成功 </span>
						<span v-else></span> -->
					</el-form-item>
					<el-form-item label="销售金额:">
						{{ dataForm.saleAmount }}
					</el-form-item>
					<el-form-item label="备注:">
						{{ dataForm.remark }}
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-drawer>
</template>

<script>
import { accountDetail } from "api/business/livePay"
export default {
	name: "AccountDetail",

	components: {},

	directives: {},

	data() {
		return {
			addEditDialog: false,
			loading: false,

			dataForm: {
				billId: "",
				classType: ""
			},

			classTypeEnum: ["", "实物商品", "直充商品", "卡密商品", "话费充值", "支付和金币充值"],
			payStateEnum: ["未付款", "已付款"],
			projectEnum: {
				c2670: "水费",
				c2680: "电费",
				c2681: "煤气"
			},
			typeEnum: {
				0: "充值中",
				1: "成功",
				9: "已撤销",
				10: "退款中",
				11: "退款成功"
			}
		}
	},

	mounted() {},

	methods: {
		open(id) {
			this.getAccountDetail(id)
			this.addEditDialog = true
		},

		getAccountDetail(id) {
			this.loading = true
			accountDetail({
				id: id
			})
				.then((res) => {
					if (res.data != null) {
						this.dataForm = res.data
					}
				})
				.finally(() => {
					console.log(111)
					this.loading = false
				})
		}
	}
}
</script>

<style lang="scss" scoped></style>
