<template>
	<mvk-table ref="mvkTable" :table-column="couponTableColumn" :table-data-func="couponTableDataFunc" class="mt20">
		<el-table-column slot="head" width="55">
			<template slot-scope="scope">
				<el-radio v-model="checkedId" :label="scope.row.id" @change.native="getCouponInfo(scope.row)">
					<span />
				</el-radio>
			</template>
		</el-table-column>
		<template slot="receiveTime" slot-scope="{ row }" label="领取时间">
			<span v-if="!row.isTime">不限时</span>
			<div v-else-if="row.isTime">{{ row.receiveTime.split(",")[0] }} - {{ row.receiveTime.split(",")[1] }}</div>
			<span v-else />
		</template>
		<template slot="termOfValidity" slot-scope="{ row }" label="使用时间">
			<span v-if="row.validityType == 0">{{ row.termOfValidity }} 天</span>
			<div v-else-if="row.validityType == 1">
				{{ row.termOfValidity.split(",")[0] }} - {{ row.termOfValidity.split(",")[1] }}
			</div>
			<span v-else />
		</template>
		<template slot="pushNum" slot-scope="{ row }">
			<span v-if="!row.isLimit">不限量</span>
			<div v-else>
				<span class="fa">发布：{{ row.pushNum }}</span>
				<span class="sheng">剩余：{{ row.surplusNum == null ? row.pushNum : row.surplusNum }}</span>
			</div>
		</template>
	</mvk-table>
</template>

<script>
import { couponList } from "api/business/marketing/coupon"
export default {
	name: "WorkspaceJsonCoupon",
	props: {
		checkedId: {
			type: Number | String,
			default: 0
		}
	},
	data() {
		return {
			radioKey: this.checkedId,
			couponTableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "name",
					label: "名称"
				},
				{
					prop: "types",
					label: "类型",
					formatter: (cellValue) => {
						if (cellValue.types == 10) {
							return "通用券"
						} else if (cellValue.types == 20) {
							return "商品券"
						} else if (cellValue.types == 30) {
							return "品类券"
						} else {
							return cellValue.types
						}
					}
				},
				{
					prop: "faceValue",
					label: "面值"
				},
				{
					prop: "obtainMethod",
					label: "领取方式",
					formatter: (cellValue) => {
						if (cellValue.obtainMethod == 10) {
							return "手动领取"
						} else if (cellValue.obtainMethod == 20) {
							return "新人券"
						} else if (cellValue.obtainMethod == 30) {
							return "赠送券"
						} else {
							return cellValue.obtainMethod
						}
					}
				},
				{
					prop: "receiveTime",
					label: "领取日期",
					width: "260",
					slot: true
				},
				{
					prop: "termOfValidity",
					label: "使用时间",
					width: "260",
					slot: true
				},
				{
					prop: "pushNum",
					label: "发布数量",
					slot: true
				}
			],
			tableData: [],
			couponTableDataFunc: (parameter) => {
				return couponList(Object.assign(parameter, { status: true, obtainMethod: 40 })).then((res) => {
					this.tableData = res.data.list
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {
		getCouponInfo(data) {
			this.$emit("couponInfo", data)
		}
	}
}
</script>

<style lang="scss" scoped></style>
