<template>
	<div class="box">
		<el-form size="small" label-width="120px">
			<el-form-item label="订单状态：">
				<el-radio-group v-model="queryParam.status" type="button">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button v-for="(iValue, iKey) in virtualOrderType" :key="iKey" :label="iKey">
						{{ iValue }}
					</el-radio-button>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="时间区间：" class="width100">
				<el-radio-group v-model="dateLimit" type="button" class="mr20" size="small" @change="onchangeDate">
					<el-radio-button v-for="(item, i) in dateTimeList" :key="i" :label="item.value">
						{{ item.text }}
					</el-radio-button>
				</el-radio-group>
				<el-date-picker v-model="timeVal" :default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" size="small" type="daterange"
					placement="bottom-end" start-placeholder="开始时间" end-placeholder="结束时间" style="width: 220px"
					@change="onchangeTime"
				/>
			</el-form-item>
			<el-form-item label="订单类型：" class="width100">
				<!-- <el-select
					v-model="queryParam.virtualType"
					placeholder="订单类型"
					clearable
					filterable
					style="width: 100%"
				>
					<el-option
						v-for="(item, index) in Object.values(virtualType)"
						:key="index"
						:label="item.label"
						:value="item.value"
					/>
				</el-select> -->
				<el-radio-group v-model="queryParam.virtualType" type="button">
					<el-radio-button v-for="(item, index) in Object.values(virtualType)" :key="index"
						:label="item.value"
					>
						{{ item.label }}
					</el-radio-button>
				</el-radio-group>
			</el-form-item>

			<div class="box-input flex-row flex-a-c">
				<el-form-item label="订单号 ：" class="width100">
					<el-input v-model="queryParam.code" placeholder="请输入订单号" size="small" clearable />
				</el-form-item>
				<el-form-item label="商品名称 ：" class="width100">
					<el-input v-model="queryParam.productName" placeholder="请输入商品名称" size="small" clearable />
				</el-form-item>
				<el-form-item label="订购用户：" class="width100">
					<el-input v-model="queryParam.receiptMobile" placeholder="请输入订购用户" size="small" clearable />
				</el-form-item>
			</div>
		</el-form>
		<mvk-table ref="mvkTable" :table-column="tableColumn" re-fresh :table-data-func="tableDataFunc" border>
			<template slot="items" slot-scope="{ row }">
				<div v-for="(item,index) in row.items" :key="index">
					{{ item.prodName }}
				</div>
			</template>
			<template slot="virtualType" slot-scope="{ row }">
				<div v-for="(item,index) in row.items" :key="index">
					{{ FindArrObjToLabel(virtualType,item.virtualType) }}
				</div>
			</template>
			<!-- <el-table-column label="操作" fixed="right" width="96">
				<template slot-scope="{ row }">
					<el-button type="text" @click="onOrderDetails(row)"> 详情 </el-button>
					<el-button type="text" @click="onOrderMark(row)"> 备注 </el-button>
				</template>
			</el-table-column> -->
		</mvk-table>
		<vOrderDetail ref="detailRef" />
	</div>
</template>

<script>
import {
	virtualOrderList
} from "api/business/selfManager/order.js"
import { virtualType, virtualOrderType, dateTimeList, payType, orderType } from "@/utils/constants"
import { FindArrObjToLabel } from "@/utils/utils"
import { GetDateTimeValue } from "@/utils/dateUtils"
import vOrderDetail from "./vOrderDetail.vue"
export default {
	components: { vOrderDetail },
	data() {
		return {
			name: "卡券订单",
			virtualType,
			virtualOrderType,
			dateTimeList,
			FindArrObjToLabel,
			orderType,
			dateLimit: "",
			timeVal: [],

			queryParam: {
				status: "",
				code: "",
				productId: "",
				productName: "",
				receiptMobile: "",
				startTime: "",
				endTime: "",
				virtualType: ""
			},

			tableDataFunc: parameter => {
				return virtualOrderList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			tableColumn: [
				{ prop: "code", label: "订单号", width: 200 },
				{ prop: "memberMobile", label: "订购用户手机", width: 100 },

				{ prop: "items", label: "商品名称", slot: true, minWidth: 100 },
				{
					prop: "virtualType",
					label: "商品类型",
					width: 76,
					slot: true,
					formatter: cellvalue => cellvalue.virtualType ? virtualType[cellvalue.virtualType] : ""
				},
				{
					prop: "status",
					label: "订单状态",
					width: 76,
					formatter: cellvalue => cellvalue.status ? virtualOrderType[cellvalue.status] : ""
				},
				{
					prop: "payType",
					label: "支付方式",
					width: 76,
					formatter: cellvalue => {
						let value = cellvalue.payType

						return payType[value]
					}
				},
				{ prop: "actualMoney", label: "支付金额", width: 80, formatter: cellvalue => cellvalue.actualMoney || 0 + "元", align: "right" },
				{ prop: "creationDate", label: "下单时间", width: 150 }
			],
			loading: false
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.searchList()
			},
			deep: true
		}
	},
	methods: {
		searchList() {
			this.$refs.mvkTable.refresh()
		},

		onchangeTime(e) {
			this.timeVal = e
			this.dateLimit = e ? null : ""
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},
		onchangeDate(val) {
			if (val != "") {
				let time = GetDateTimeValue(val, "YYYY-MM-DD HH:mm:ss")
				this.queryParam.startTime = time[0]
				this.queryParam.endTime = time[1]
			} else {
				this.queryParam.startTime = ""
				this.queryParam.endTime = ""
			}

			this.timeVal = []
		},
		// 订单详情
		onOrderDetails(row) {
			this.$refs.detailRef.open(row)
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
    padding: 10px;

    .el-form-item--small.el-form-item {
            margin-bottom: 6px;
        }
    .box-input {
        margin-bottom: 10px;
        .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }
    }
    .sync{
        margin-left: 20px;
    }
}
</style>