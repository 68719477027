import fetch from "@/axios/fetch"

export function uploadMedia(data) { // 上传素材
    return fetch({
        url: "/wxMedia/file?fileType=image",
        method: "post",
        data
    })
}

export function getMedia(params) { // 上传素材
    return fetch({
        url: "/wxMedia/get",
        method: "get",
        params
    })
}
