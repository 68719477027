<template>
	<div class="mvk-layout-content-card">
		<el-form ref="form" :model="form" :rules="rules" label-width="110px" :inline="false">
			<!-- <el-form-item label="拼团" prop="open">
				<el-radio-group v-model="form.open">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
			</el-form-item> -->
			<el-form-item label="团购名称" prop="name">
				<el-input
					v-model="form.name"
					class="inputWidth"
					placeholder="请输入团购名称"
					:disabled="addOrEdit == 'view'"
				/>
			</el-form-item>
			<el-form-item label="团购时间段" prop="datetime">
				<el-date-picker
					v-model="datetime"
					type="datetimerange"
					value-format="timestamp"
					:default-time="['00:00:00', '23:59:59']"
					format="yyyy-MM-dd HH:mm:ss"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					:disabled="addOrEdit == 'view'"
					@change="dateTimeChange"
				/>
			</el-form-item>
			<el-form-item label="团购商品" prop="productList">
				<div v-if="addOrEdit != 'view'" class="flex flex-column-center">
					<div class="upLoadPicBox" @click="changeGood">
						<div class="upLoad">
							<i class="el-icon-camera cameraIconfont" />
						</div>
					</div>
				</div>

				<el-table :data="productList" class="mt15 border-table" header-cell-class-name="custom-table1">
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-table :data="props.row.skuList" stripe>
								<el-table-column
									v-for="col in fixedColumn"
									:key="col.prop"
									:prop="col.prop"
									:label="col.label"
								>
									<template slot-scope="{ row }">
										<el-image
											v-if="col.prop == 'pic'"
											style="width: 30px; height: 30px"
											:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
											:preview-src-list="[row.pic]"
										/>
										<span v-else>
											{{ row[col.prop] }}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="groupPrice" label="拼团价格">
									<template slot-scope="scope">
										<el-input-number
											v-model="scope.row['groupPrice']"
											size="small"
											:min="0"
											:precision="2"
											:step="0.1"
											:disabled="addOrEdit == 'view'"
										/>
									</template>
								</el-table-column>
								<el-table-column v-if="addOrEdit != 'view'">
									<template slot-scope="scope">
										<delete-button
											:product-index="props.row.index"
											:sku="scope"
											button-type="text"
											size="small"
											@delete="handleSkuDelete"
										/>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</el-table-column>
					<el-table-column prop="productId" label="商品ID" width="100" />
					<el-table-column prop="pic" label="商品图" width="90">
						<template slot-scope="{ row }">
							<el-image style="width: 30px; height: 30px" :src="row.pic" :preview-src-list="[row.pic]" />
						</template>
					</el-table-column>
					<el-table-column prop="productName" label="商品名称" />
					<el-table-column prop="maxCount" label="最大开团数" width="140">
						<template slot-scope="scope">
							<el-input-number
								v-model="scope.row['maxCount']"
								size="small"
								type="number"
								:min="1"
								:step="1"
								step-strictly
								:disabled="addOrEdit == 'view'"
							/>
						</template>
					</el-table-column>
					<el-table-column prop="groupNum" label="拼团人数" width="140">
						<template slot-scope="scope">
							<el-input-number
								v-model="scope.row['groupNum']"
								size="small"
								type="number"
								:min="1"
								:step="1"
								step-strictly
								:disabled="addOrEdit == 'view'"
							/>
						</template>
					</el-table-column>
					<el-table-column prop="sort" label="商品排序" width="140">
						<template slot-scope="scope">
							<el-input-number
								v-model="scope.row['sort']"
								size="small"
								type="number"
								:min="1"
								:step="1"
								step-strictly
								:disabled="addOrEdit == 'view'"
							/>
						</template>
					</el-table-column>
					<el-table-column
						v-if="addOrEdit != 'view'"
						label="操作"
						width="120"
						align="center"
						header-align="center"
					>
						<template slot-scope="scope">
							<delete-button
								:id="scope.row.id"
								:index="scope.row.index"
								button-type="text"
								size="small"
								@delete="handleProductDelete"
							/>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-contain">
					<el-pagination
						background
						:current-page.sync="pageNum"
						:page-size="10"
						layout="total,prev, pager, next"
						:total="total"
						@current-change="handleCurrentChange"
					/>
				</div>
			</el-form-item>
			<el-form-item label="拼团规则">
				<div v-if="addOrEdit == 'view'" v-html="form.content" />
				<ueditor-from v-show="addOrEdit != 'view'" v-model="form.content" :content="form.content" />
			</el-form-item>
			<el-form-item label="结算优惠">
				<el-checkbox-group v-model="checkList" :disabled="addOrEdit == 'view'">
					<el-checkbox label="coupon">优惠券</el-checkbox>
					<el-checkbox label="hongbao">红包</el-checkbox>
					<el-checkbox label="cashBack">订单返现</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item v-if="addOrEdit != 'view'">
				<el-button type="primary" @click="handleSubmit">保存</el-button>
			</el-form-item>
		</el-form>
		<!--展示仓库中的商品-->
		<goods-list-model
			ref="goodsList"
			supplier
			:multi="true"
			type="ware"
			:checked="checkedGoods"
			@getGoodsList="getGoodsItem"
		/>
	</div>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import { saveGroupActivity, groupActivityDetail, groupActivityGoodsDetail } from "api/business/marketing/groupActivity"
import UeditorFrom from "@/components/UeditorFrom"
import { formatDate } from "@/utils/validate"
export default {
	name: "GroupActivityAdd",
	components: {
		UeditorFrom,
		GoodsListModel
	},
	data() {
		return {
			form: {
				id: "",
				name: "",
				open: true,
				content: "",
				cashBack: false,
				coupon: false,
				hongbao: false,
				productList: [],
				deleteIds: [],
				startTime: "",
				endTime: "",
				remark: ""
			},
			checkedGoods: [],
			fixedColumn: [
				{ prop: "skuId", label: "SKUId" },
				{ prop: "pic", label: "图片" },
				{ prop: "skuName", label: "SKU名称" },
				{ prop: "actualStocks", label: "库存" },
				{ prop: "sellingPrice", label: "售价" }
			],
			checkList: [],
			datetime: [],
			rules: {
				name: [
					{
						required: true,
						message: "请输入拼团名称",
						trigger: "blur"
					}
				]
			},
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},

			pageNum: 1,
			pageSize: 1000,
			// total: 0,
			addOrEdit: "add", // 新增修改标识

			groupId: ""
		}
	},
	computed: {
		productList() {
			// 当前页商品
			return this.form.productList && this.form.productList.slice((this.pageNum - 1) * 10, this.pageNum * 10)
		},
		total() {
			return this.form.productList && this.form.productList.length
		}
	},
	mounted() {
		if (this.$route.params.id) {
			if (this.$route.params.detail) {
				this.addOrEdit = "view"
				this.setTagsViewTitle("拼团活动查看")
			} else {
				this.addOrEdit = "edit"

				this.setTagsViewTitle("拼团活动编辑")
			}

			this.groupId = this.$route.params.id
			this.getDetailInfo(this.$route.params.id)
			this.getGoodsDetail()
		} else {
			this.addOrEdit = "add"
		}
	},

	methods: {
		setTagsViewTitle(title) {
			const route = Object.assign({}, this.$route, { title: `${title} - ${this.$route.params.id}` })
			this.$store.dispatch("tagsView/updateVisitedView", route)
		},
		getDetailInfo(id) {
			groupActivityDetail(id).then(res => {
				this.form = Object.assign(this.form, res.data)

				if (this.form.cashBack) {
					this.checkList.push("cashBack")
				}
				if (this.form.coupon) {
					this.checkList.push("coupon")
				}
				if (this.form.fullReduction) {
					this.checkList.push("fullReduction")
				}
				if (this.form.hongbao) {
					this.checkList.push("hongbao")
				}

				let timeVal = []
				timeVal.push(Math.round(new Date(this.form.startTime)))
				timeVal.push(Math.round(new Date(this.form.endTime)))
				this.$set(this, "datetime", timeVal)

				this.form.deleteIds = []
			})
		},

		getGoodsDetail() {
			console.log(this.groupId)
			groupActivityGoodsDetail(this.groupId, {
				id: this.groupId,
				pageNum: this.pageNum - 1,
				pageSize: this.pageSize
			}).then(res => {
				res.data.list &&
					res.data.list.forEach((item, index) => {
						item.index = index
					})
				this.$set(this.form, "productList", res.data.list)
			})
		},

		dateTimeChange(e) {
			this.form.startTime = e ? formatDate(new Date(e[0]), "yyyy-MM-dd HH:mm:ss") : ""
			this.form.endTime = e ? formatDate(new Date(e[1]), "yyyy-MM-dd HH:mm:ss") : ""
		},
		changeGood() {
			this.$refs.goodsList.open()
		},
		// 选择商品
		getGoodsItem(goodsInfo) {
			console.log(goodsInfo)

			let checkedProdIds = this.form.productList.map(item => item.productId)

			let tempGoods = []

			goodsInfo &&
				goodsInfo.forEach(item => {
					console.log(item.id)
					let index = checkedProdIds.indexOf(item.id)
					if (index > -1) {
						if (this.form.productList[index].skuList.length == item.skus.length) {
							this.$message.warning(`已经选择了商品ID为${item.id}的商品,已剔除该商品`)
							return
						}
					} else {
						tempGoods.push(JSON.parse(JSON.stringify(item)))
					}
				})

			// let tempGoods = JSON.parse(JSON.stringify(goodsInfo))
			// let checkedProdIds = this.form.newcomersProductList.map((item) => item.id)
			// let tempInfo = []
			let tempInfo = tempGoods.map((item, iindex) => {
				item.productId = item.id
				item.id = ""
				item.productName = item.prodName
				item.groupNum = 2
				item.maxCount = 1
				item.index = this.form.productList.length + iindex
				item.sort = this.form.productList.length + iindex + 1
				item.skuList = item.skus.map(sku => {
					sku.productId = item.productId
					sku.skuId = sku.id
					sku.id = ""
					sku.groupPrice = 0.01
					sku.groupId = ""
					let { productId, skuId, id, groupPrice, groupId, pic, skuName, actualStocks, sellingPrice } = {
						...sku
					}
					return { productId, skuId, id, groupPrice, groupId, pic, skuName, actualStocks, sellingPrice }
				})
				let { pic, productName, id, productId, groupNum, maxCount, sort, skuList, index } = { ...item }
				return { pic, productName, id, productId, groupNum, maxCount, sort, skuList, index }
			})

			console.log(tempInfo)
			this.form.productList = this.form.productList.concat(tempInfo)
		},

		// 单纯sku删除
		handleSkuDelete(data) {
			// console.log(data)
			let productIndex = data.productIndex
			let skuIndex = data.sku.$index
			if (!this.form.productList[productIndex].deleteIds) {
				this.form.productList[productIndex].deleteIds = []
			}
			if (data.sku.row.id != "") {
				this.form.productList[productIndex].deleteIds.push(data.sku.row.id)
			}

			this.form.productList[productIndex].skuList.splice(skuIndex, 1)

			if (this.form.productList[productIndex].skuList.length == 0) {
				if (data.sku.row.id != "") {
					this.form.deleteIds.push(this.form.productList[productIndex].id)
				}

				this.form.productList.splice(productIndex, 1)
			}
		},
		// 商品删除
		handleProductDelete(data) {
			// console.log(data)
			if (data.id != "") {
				this.form.deleteIds.push(data.id)
			}

			this.form.productList.splice(data.index, 1)
		},

		handleSubmit() {
			this.form.cashBack = this.checkList.indexOf("cashBack") > -1
			this.form.coupon = this.checkList.indexOf("coupon") > -1
			this.form.hongbao = this.checkList.indexOf("hongbao") > -1

			if (this.form.productList.length == 0) {
				this.$message.warning("请选择商品")
				return
			}

			saveGroupActivity(this.form)
				.then(() => {
					this.$notify.success({
						title: "保存成功"
					})
					this.$router.push("/marketingManager/groupActivity")
				})
				.catch(() => {
					this.$notify.error({
						title: "保存失败"
					})
				})
		},

		// 当前页变化
		handleCurrentChange(val) {
			this.pageNum = val
		}
	}
}
</script>

<style lang="sass" scoped></style>
