<template>
	<el-drawer title="拼团订单" :visible.sync="orderVisible" size="70%" :append-to-body="true">
		<div class="drawer-content">
			<el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="订单号" class="inputWidth">
					<el-input v-model="queryParam.orderNo" @change="searchList" />
				</el-form-item>

				<el-form-item label="订单状态：">
					<el-select v-model="status">
						<el-option label="全部" value="" />

						<el-option
							v-for="(item, index) in Object.values(orderStatus)"
							:key="item"
							:label="item"
							:value="index + 1"
						/>
					</el-select>
				</el-form-item>
			</el-form>
			<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
				<!--自定义操作-->
				<template slot="status" slot-scope="{ row }">
					<el-tag class="mr10">{{ row.status | OrderStatus }}</el-tag>
					<span v-show="row.subStatus"><el-tag type="danger">{{ row.subStatus | OrderStatus }}</el-tag></span>
				</template>
			</mvk-table>
		</div>
	</el-drawer>
</template>

<script>
import { getGroupActivityOrder } from "api/business/marketing/groupActivity.js"
import { orderStatus, payType, orderType } from "@/utils/constants"
export default {
	name: "GroupActivityOrder",
	filters: {
		OrderStatus(type) {
			return orderStatus[type]
		}
	},
	data() {
		return {
			orderVisible: false,
			orderStatus: orderStatus,
			tableColumn: [
				{ prop: "code", label: "订单号" },
				{ prop: "memberName", label: "订购用户" },
				// { prop:'prodName', label:'商品信息'},
				// { prop:'shopName', label:'门店名称'},

				{
					prop: "type",
					label: "订单类型",
					formatter: cellvalue => {
						let value = cellvalue.type
						return orderType[value]
					}
				},
				{ prop: "status", label: "订单状态", slot: true },
				{
					prop: "payType",
					label: "支付方式",
					formatter: cellvalue => {
						let value = cellvalue.payType

						return payType[value]
					}
				},
				{ prop: "actualMoney", label: "支付金额" },
				{ prop: "creationDate", label: "下单时间" }
			],
			status: "",
			queryParam: {
				status: "",
				subStatus: "",
				supplierId: "",
				orderNo: ""
			},
			tableDataFunc: parameter => {
				return getGroupActivityOrder(this.activityId, Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},
			activityId: ""
		}
	},
	watch: {
		status(newVal) {
			let mainStatus = [1, 2, 3, 5, 6, 11, 13] // 主状态
			let afterSaleStatus = [7, 8, 10, 12] // 申请退款，退款中
			if (mainStatus.indexOf(newVal) > -1) {
				this.queryParam.status = newVal
				this.queryParam.subStatus = ""
			} else if (afterSaleStatus.indexOf(newVal) > -1) {
				this.queryParam.status = ""
				this.queryParam.subStatus = newVal
			} else if (newVal == 4) {
				// 待评价
				this.queryParam.status = 5
				this.queryParam.subStatus = newVal
			} else if (newVal == 9) {
				// 已退款
				this.queryParam.status = 6
				this.queryParam.subStatus = newVal
			} else {
				this.queryParam.status = ""
				this.queryParam.subStatus = ""
			}
		},
		queryParam: {
			handler() {
				this.searchList()
			},
			deep: true
		}
	},
	mounted() {},

	methods: {
		open(id) {
			this.activityId = id
			this.orderVisible = true
			this.$refs.mvkTable.refresh()
		},

		searchList() {
			this.$refs.mvkTable.refresh()
		}
	}
}
</script>

<style lang="scss" scoped></style>
