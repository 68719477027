<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="120px">
			<el-form-item label="订单时间区间：" class="width100">
				<el-radio-group v-model="dateLimit" type="button" class="mr20" size="small" @change="onchangeDate">
					<el-radio-button v-for="(item, i) in dateList" :key="i" :label="item.value">
						{{ item.text }}
					</el-radio-button>
				</el-radio-group>
				<el-date-picker
					v-model="timeVal"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					placeholder="自定义时间"
					style="width: 220px"
					@change="onchangeTime"
				/>
			</el-form-item>
		</el-form>

		<!-- tabs -->

		<el-tabs v-model="queryParam.status">
			<el-tab-pane v-for="item in statusList" :key="item.label" :label="item.label" :name="item.value" />
		</el-tabs>
		<!-- 数据 -->

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" border class="mt10">
			<el-table-column label="操作" width="140" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleView(row.ticketId)"> 发票信息 </el-button>
					<el-button type="text" size="small" @click="handleViewOrder(row.orderCode)"> 订单 </el-button>
				</template>
			</el-table-column>
		</mvk-table>
		<detail-info ref="info" />
	</div>
</template>

<script>
import { GetDateTimeValue } from "@/utils/dateUtils"
import { dateTimeList } from "@/utils/constants"
import { ticketRecordsList } from "api/business/financeManage/all"
import { orderStatus } from "@/utils/constants"

export default {
	components: {
		"detail-info": () => import("./info.vue")
	},
	data() {
		return {
			dateList: dateTimeList,
			dateLimit: "",
			timeVal: [],
			queryParam: {
				startTime: "",
				endTime: "",
				status: "0"
			},

			statusList: [
				{
					value: "0",
					label: "待开发票"
				},
				{
					value: "1",
					label: "已开发票"
				},
				{
					value: "2",
					label: "退款发票"
				}
			],

			tableColumn: [
				{
					prop: "orderCode",
					label: "订单号",
					tooltip: true
				},
				{
					prop: "nickName",
					label: "订购用户",
					tooltip: true
				},
				{
					prop: "orderCreationDate",
					label: "下单时间"
				},

				{
					prop: "orderStatus",
					label: "订单状态",
					align: "center",
					width: 80,
					formatter: row => {
						return orderStatus[row.orderStatus]
					}
				},
				{
					prop: "status",
					label: "发票类型",
					formatter: value => {
						return this.statusList.filter(item => Number(item.value) === value.status)[0].label
					}
				},
				{
					prop: "headerType",
					label: "发票抬头",
					formatter: row => {
						return row.headerType == 1 ? "个人" : "企业"
					}
				},
				{
					prop: "kjje",
					label: "发票金额"
				}
			],
			tableDataFunc: parameter => {
				return ticketRecordsList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			}
		}
	},
	computed: {},
	watch: {
		queryParam: {
			handler() {
				this.$refs.mvkTable.refresh()
			},
			deep: true
		}
	},
	methods: {
		onchangeDate(val) {
			if (val != "") {
				let time = GetDateTimeValue(val, "YYYY-MM-DD")
				console.log(time)
				this.queryParam.startTime = time[0]
				this.queryParam.endTime = time[1]
			} else {
				this.queryParam.startTime = ""
				this.queryParam.endTime = ""
			}

			this.timeVal = []
		},
		onchangeTime(e) {
			this.timeVal = e
			this.dateLimit = e ? null : ""
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},
		handleView(id) {
			// this.$router.push("/marketingManager/groupActivityAdd/" + id + "/detail")
			this.$refs.info.open(id)
		},

		handleViewOrder(id) {
			this.$router.push({
				path: "/selfManager/order",
				query: {
					code: id
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
