<template>
	<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
		<el-table-column slot="head" width="55">
			<template slot-scope="scope">
				<el-radio :value="checkedId" :label="scope.row.wechatRoomId" @change.native="getInfo(scope.row,$event)">
					<span />
				</el-radio>
			</template>
		</el-table-column>
		<template slot="coverImgUrl" slot-scope="{ row }" label="直播间背景图">
			<el-image style="width: 30px; height: 30px" :src="row.coverImgUrl + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.coverImgUrl]" />
		</template>
		<template slot="shareImgUrl" slot-scope="{ row }" label="直播间分享图片">
			<el-image style="width: 30px; height: 30px" :src="row.shareImgUrl + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.shareImgUrl]" />
		</template>
		<template slot="status" slot-scope="{ row }" label="直播间状态">
			<el-tag>{{ statusMap[row.status] }}</el-tag>
		</template>
	</mvk-table>
</template>

<script>
import { getLiveRoom } from "api/business/broadcast/live"
export default {
	name: "AdvertBroadcast",
	props: {
		checkedId: {
			type: [Number, String],
			default: 0
		}
	},
	data() {
		return {
			templateRadio: 0,
			tableColumn: [
				{ prop: "id", label: "直播活动ID" },
				{ prop: "wechatRoomId", label: "直播房间ID" },
				{ prop: "name", label: "直播间标题" },
				{ prop: "coverImgUrl", label: "直播间背景图", slot: true },
				{ prop: "shareImgUrl", label: "直播间分享图片", slot: true },
				{ prop: "startTime", label: "开始时间" },
				{ prop: "endTime", label: "结束时间" },
				{ prop: "anchorName", label: "主播昵称" },
				{ prop: "anchorWechat", label: "主播微信号" },
				{
					prop: "type",
					label: "直播类型",
					formatter: value => {
						// 格式化内容
						const statusMap = {
							0: "手机直播",
							1: "推流直播"
						}

						return statusMap[value.type]
					}
				},
				{ prop: "status", label: "直播间状态", slot: true }
			],
			queryParam: {},
			tableDataFunc: parameter => {
				return getLiveRoom(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			statusMap: {
				101: "直播中",
				102: "未开始",
				103: "已结束",
				104: "禁播",
				105: "暂停",
				106: "异常",
				107: "已过期"
			}
		}
	},

	mounted() {},

	methods: {
		getInfo(info) {
			this.$emit("update:checkedId", info.wechatRoomId)
			this.$emit("getInfo", info)
		}
	}
}
</script>

<style lang="scss" scoped></style>
