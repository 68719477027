<template>
	<div>
		<div class="flex flex-sb">
			<div v-if="!readOnly" class="flex flex-column-center">
				<el-button type="primary" @click="addData()">新增会员</el-button>
				<el-upload
					action
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					:http-request="handleUpload"
					:headers="uploadHeaders"
					:show-file-list="false"
				>
					<el-button type="primary" style="margin-left:40px;">批量导入会员</el-button>
				</el-upload>
				<el-button :disabled="selectList.length == 0" style="margin-left:10px;" @click="detach()">批量移除</el-button>
				<el-button style="margin-left:10px;" @click="toDownloadTemplate()">导入模板下载</el-button>
				<el-button style="margin-left:10px;" :disabled="selectList.length == 0" @click="openDisLabour()">批量派发通用金</el-button>
				<el-button type="primary" @click="handleExport()"> 导出报表</el-button>

				<div class="filter-box">
					<el-input v-model="queryParam.name" placeholder="可输入姓名和手机号查询" clearable
						class="ml10 w200" maxlength="20" @input="search()" 
					/>
				</div>
			</div>

			<router-link :to="{ path: '/marketingManager/labour/list' }">
				<el-button>返回列表</el-button>
			</router-link>
		</div>
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			class="mt15"
			row-key="cid"
			@selection-change="handleSelectionChange"
		>
			<template slot="enabled" slot-scope="{ row }" label="状态">
				<el-switch v-model="row.enabled" @change="onStartStop(row)" />
			</template>
			<el-table-column
				v-if="!readOnly"
				slot="head"
				type="selection"
				width="55"
			/>
			<template slot="laborMoney" slot-scope="{ row }" label="当前账户余额(元)">
				<div class=" center" :class="{'money':row.laborMoney> 0}">{{ row.laborMoney }}</div>
			</template>
			<el-table-column v-if="!readOnly" label="操作" width="90" align="center">
				<template slot-scope="{ row }">
					<!-- <el-button
						type="text"
						@click="handleDelete(row)"
					>
						移除
					</el-button> -->
					<delete-button button-type="text" size="small" delete-text="确定删除吗？" margin-left="" @delete="handleDelete(row)">删除</delete-button>
				</template>
			</el-table-column>
		</mvk-table>
        
		<el-dialog
			title="新增邀请"
			:visible.sync="memberDialog"
			:close-on-click-modal="false"
			top="20vh"
			width="360px"
		>
			<el-form
				ref="memberForm"
				:model="memberForm"
				:rules="memberFormRules"
				label-width="80px"
				:inline="false"
			>
				<el-form-item label="会员姓名" prop="memberName">
					<el-input v-model="memberForm.memberName" placeholder="请输入会员姓名" />
				</el-form-item>
				<el-form-item label="手机号码" prop="memberMobile">
					<el-input v-model="memberForm.memberMobile" placeholder="请输入手机号码" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :loading="addloading" @click="onAddMemberSubmit('memberForm')">新增</el-button>
					<el-button @click="memberDialog = false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 派发通用金 -->
		<el-dialog
			title="派发通用金"
			:visible.sync="disDialogShow"
			:close-on-click-modal="false"
			top="20vh"
			width="680px"
		>
			<el-table ref="table" :data="disDataList" class="mt15 border-table">
				<el-table-column prop="id" label="ID" width="60" />
				<el-table-column prop="memberName" label="会员名称" width="96" />
				<el-table-column prop="memberMobile" label="手机号" width="100" />
				<el-table-column prop="laborMoney" label="当前账户余额(元)" align="center">
					<template slot-scope="scope">
						<div class=" center" :class="{'money':scope.row.laborMoney> 0}">{{ scope.row.laborMoney }}</div>
					</template>
				</el-table-column>
				<el-table-column label="本次派发金额(元)">
					<template slot-scope="scope">
						<el-input v-model="scope.row.addLaborMoney" clearable placeholder="请输入派发金额" />
					</template>
				</el-table-column>
				<el-table-column label="操作" width="70" align="center" header-align="center">
					<template slot-scope="scope">
						<el-button type="text" style="color:red" @click="removeMember(scope.$index)">移除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="disDialogShow = false">取 消</el-button>
				<el-button type="primary" class="w100" @click="disLabour()">确定派发</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mobileReg } from "@/utils/constants"
import { blobTofile } from "@/utils/utils"
import { getToken } from "api/auth"
import { debounce, deepClone } from "utils/utils"
import * as api from "api/business/selfManager/labour"
const contactValidator = (rule, value, callback) => {
	if (!mobileReg.test(value)) {
		callback("请输入正确的联系号码")
	} else {
		callback()
	}
}
const newMember = () => {
	return {
		memberMobile: "",
		memberName: ""
	}
}
export default {
	props: {
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			uploadHeaders: { "X-Token": getToken() },
			list: [],
			tableColumn: [
				{ prop: "id", label: "序号", width: 110 },
				{ prop: "memberName", label: "会员姓名" },
				{ prop: "memberMobile", label: "会员手机号" },
				{ prop: "laborMoney", label: "当前账户余额(元)", slot: true, headerAlign: "center" },
				{ prop: "lastCreateTime", label: "上次派发时间" }

			],
			queryParam: { name: "" },
			tableDataFunc: async parameter => {
				if (this.$route.query.id) {
					return api.membersList(Object.assign(parameter, { ...this.queryParam, shopId: this.$route.query.id })).then(res => {
						return res.data
					})
				} else {
					return await {}
				}
			},
			selectList: [],

			// 新增会员
			memberDialog: false,
			addloading: false,
			memberForm: newMember(),
			memberFormRules: {
				memberMobile: [
					{
						required: true,
						message: "手机号码",
						trigger: "blur"
					},
					{
						validator: contactValidator
					}
				],
				memberName: [
					{
						required: true,
						message: "会员名称",
						trigger: "blur"
					}
				]
			},
			// 派发通用金
			disDialogShow: false,
			disDataList: []
		}
	},
	mounted() {
	},
	methods: {
		getList() {
			this.$refs.mvkTable.refresh()
		},
		search: debounce(function() {
			this.$refs.mvkTable.refresh()
		}, 300),
		addData() {
			this.memberDialog = true
			this.$nextTick(() => {
				this.$refs.memberForm.resetFields()
			})
		},
		// 移除会员
		handleDelete(data) {
			this.removeApi([data.memberMobile])
		},
		// 移除接口
		removeApi(memberMobile) {
			api.removeMembers({ memberPhone: memberMobile }, this.$route.query.id)
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "删除成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "删除失败"
					})
				})
		},
		// 批量移除
		detach() {
			this.removeApi(this.selectList.map(e => e.memberMobile))
		},
		// 打开派发窗口
		openDisLabour() { 
			this.disDialogShow = true
			this.disDataList = deepClone(this.selectList)
		},
		// 批量派发通用金
		disLabour() { 
			console.log(this.disDataList)
			const params = this.disDataList.map(e => { 
				return {
					memberName: e.memberName,
					memberMobile: e.memberMobile,
					laborMoney: e.addLaborMoney
				}
			})
			api.disLabourToMember({ shopMemberDisReqs: params }, this.$route.query.id).then(res => { 
				if (res.status === "OK") { 
					this.$message.success("派发成功")
					this.$refs.mvkTable.refresh()
					this.disDialogShow = false
				}
			}).catch(err => { 
				this.$notify.error({
					title: "派发失败",
					message: err.msg
				})
			})
		},
		// 移除派发中通用会员
		removeMember(index) {
			this.disDataList.splice(index, 1)
		},
		// 改变勾选指集
		handleSelectionChange(val) {
			this.selectList = val
		},

		// 新增会员
		onAddMemberSubmit(formName) {
			this.addloading = true
			this.$refs[formName].validate(valid => {
				if (valid) {
					api.addMember(Object.assign(this.memberForm, { shopId: this.$route.query.id })).then(res => {
						if (res.status === "OK") {
							this.memberDialog = false
							this.$refs.mvkTable.refresh()
							this.$refs.memberForm.resetFields()
						}
					}).finally(() => { 
						this.addloading = false
					})
				}
			})
		},
		// 导入模板下载
		toDownloadTemplate() {
			api.getDownload().then(r => {
				blobTofile(r, "导入模版", "application/vnd.ms-excel")
			})
		},
		// 批量导入会员

		handleUpload(param) {
			const formData = new FormData()
			formData.append("file", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			api.importMembers(formData, this.$route.query.id)
				.then(() => {
					loading.close()
					this.$message.success("上传成功")
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					loading.close()
				})
		},
		// 导出关联会员
		handleExport() {
			api.getRelationDownload(this.queryParam, this.$route.query.id).then(res => {
				blobTofile(res, "关联会员.xls", "application/vnd.ms-excel")
			})
		}

	}
}
</script>

<style lang="scss" scoped>
.money{
	color: red;
}
.center{
	text-align: center;

}
</style>