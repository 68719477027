<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" size="75%">
		<!-- <el-tabs type="border-card">
			<el-tab-pane label="基本信息">

			</el-tab-pane>
			<el-tab-pane label="用户信息"></el-tab-pane>
		</el-tabs> -->

		<el-form
			v-if="!supplierForm.id"
			ref="userForm"
			:model="userForm"
			:rules="userRules"
			label-width="150px"
			style="width: 80%"
		>
			<el-form-item prop="username" label="用户账号">
				<el-input v-model="userForm.username" placeholder="用户账号" :disabled="!!supplierForm.id" />
			</el-form-item>
			<el-form-item prop="fullName" label="用户名称">
				<el-input v-model="userForm.fullName" placeholder="用户名称" :disabled="!!supplierForm.id" />
			</el-form-item>
			<el-form-item prop="mobile" label="手机号码">
				<el-input v-model="userForm.mobile" placeholder="手机号码" :disabled="!!supplierForm.id" />
			</el-form-item>
			<el-form-item prop="email" label="邮箱">
				<el-input v-model="userForm.email" placeholder="邮箱" :disabled="!!supplierForm.id" />
			</el-form-item>
			<el-form-item prop="roleIds" label="角色分配">
				<el-select v-model="userForm.roleIds" multiple placeholder="请选择角色" style="width: 100%" disabled>
					<el-option v-for="item in rolelist" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
		</el-form>

		<el-form
			ref="supplierForm"
			v-loading="loading"
			:model="supplierForm"
			:rules="Rules"
			label-width="150px"
			style="width: 80%"
		>
			<el-row :gutter="20">
				<!-- <el-col :span="12">
					<el-form-item label="是否系统内置">
						<el-radio-group v-model="supplierForm.isSystem" :disabled="!!supplierForm.id">
							<el-radio :label="true">是</el-radio>
							<el-radio :label="false">否</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col> -->

				<!-- <el-col :span="12">
					<el-form-item label="供应商编号：" prop="number">
						<mvk-input
							v-model.trim="supplierForm.number"
							placeholder="供应商编号"
							class="inputWidth"
							:disabled="!!supplierForm.id"
						/>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item label="供应商名称：" prop="name">
						<mvk-input v-model.trim="supplierForm.name" placeholder="供应商名称" class="inputWidth" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="联系人：" prop="contact">
						<mvk-input v-model.trim="supplierForm.contact" placeholder="联系人" class="inputWidth" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="联系电话：" prop="contactMobile">
						<el-input v-model="supplierForm.contactMobile" placeholder="联系电话" class="inputWidth" />
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="联系地址：" prop="address">
						<el-input v-model="supplierForm.address" type="textarea" :rows="2" placeholder="联系地址" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="供应产品类别：" prop="categoryIdArray">
						<!-- <mvk-input  v-model.number="supplierForm.code" placeholder="供应产品类别" class="inputWidth"></mvk-input> -->
						<prod-category-tree
							v-model="supplierForm.categoryIdArray"
							:multiple="true"
							collapse-tags
							class="inputWidth"
							placeholder="供应产品类别"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="增值税税率：" prop="taxRate">
						<el-input
							v-model.number="supplierForm.taxRate"
							type="number"
							placeholder="增值税税率"
							class="inputWidth"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开票名称：" prop="invoiceTitle">
						<mvk-input v-model="supplierForm.invoiceTitle" placeholder="开票名称" class="inputWidth" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="银行账号：" prop="bankNumber">
						<el-input
							v-model="supplierForm.bankNumber"
							type="text"
							placeholder="银行账号"
							class="inputWidth"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开户银行：" prop="bankName">
						<!-- <mvk-input  v-model.number="supplierForm.bankType" placeholder="开户银行" class="inputWidth"></mvk-input> -->
						<el-autocomplete
							v-model="supplierForm.bankName"
							class="inline-input"
							:fetch-suggestions="querySearch"
							placeholder="请输入内容"
							value-key="label"
						/>
						<!-- <el-select
							v-model="supplierForm.bankType"
							placeholder="开户银行"
							clearable
							filterable
							class="inputWidth"
						>
							<el-option
								v-for="item in bankList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-select> -->
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开户地址：" prop="bankAddress">
						<el-input v-model="supplierForm.bankAddress" type="textarea" :rows="2" placeholder="开户地址" />
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="备注：" prop="note">
						<el-input v-model="supplierForm.note" type="textarea" :rows="2" placeholder="备注" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item>
				<el-button type="primary" round @click="submitForm()">保存</el-button>
				<el-button round @click="resetForm('supplierForm')">取 消</el-button>
			</el-form-item>
		</el-form>
	</el-drawer>
</template>

<script>
import { addSupplier, editSupplier, supplierDetail } from "api/basePage/supplier"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import { bankList } from "@/utils/constants"
import { isMobile, isChinese } from "@/utils/validate"
import { mapState, mapActions } from "vuex"

export default {
	name: "SupplierAddedit",

	components: {
		ProdCategoryTree
	},
	computed: {
		...mapState("dict", ["rolelist"])
	},
	data() {
		return {
			title: "新增供应商",
			userForm: {
				id: "",
				username: "",
				mobile: "",
				email: "",
				fullName: "",
				roleIds: []
			},
			userRules: {
				username: [
					{
						required: true,
						message: "请输入用户账号",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							if (isChinese(value)) {
								callback(new Error("用户账号不能包含中文"))
							} else {
								callback()
							}
						},
						trigger: ["change", "blur"]
					}
				],
				fullName: [
					{
						required: true,
						message: "请输入用户名称",
						trigger: "blur"
					}
				],
				mobile: [
					{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							if (!isMobile(value)) {
								callback(new Error("手机号格式错误"))
							} else {
								callback()
							}
						},
						trigger: ["change", "blur"]
					}
				]
			},

			supplierForm: {
				id: "",
				number: "",
				name: "",
				contact: "",
				contactMobile: "",
				address: "",
				categoryIdArray: "",
				categoryIds: "",
				taxRate: "",
				invoiceTitle: "",
				bankNumber: "",
				bankName: "",
				bankAddress: "",
				note: ""
			},

			Rules: {
				number: [{ required: true, message: "请输入供应商编号", trigger: "blur" }],
				name: [{ required: true, message: "请输入供应商名称", trigger: "blur" }],
				contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
				contactMobile: [{ required: true, message: "请输入联系电话", trigger: "blur" }]
			},

			addEditDialog: false,

			loading: false,
			bankList: bankList
		}
	},
	mounted() {
		if (this.rolelist.length == 0) {
			this.getRoleList()
		}
	},
	methods: {
		...mapActions("dict", ["getRoleList"]),

		add() {
			this.addEditDialog = true
			this.userForm = {
				id: "",
				username: "",
				mobile: "",
				email: "",
				fullName: "",
				roleIds: [this.rolelist.filter(item => item.code === "SUPPLIER")[0].id] // 默认供应商角色id
			}

			this.supplierForm = {
				id: "",
				number: "",
				name: "",
				contact: "",
				contactMobile: "",
				address: "",
				categoryIdArray: [],
				categoryIds: "",
				taxRate: "",
				invoiceTitle: "",
				bankNumber: "",
				bankName: "",
				bankAddress: "",
				note: ""
			}
		},
		edit(record) {
			this.addEditDialog = true
			this.getDetail(record.id)
		},

		getDetail(id) {
			this.loading = true
			supplierDetail(id)
				.then(res => {
					this.supplierForm = JSON.parse(JSON.stringify(res.data))
					// console.log(res.data, "----")
					this.supplierForm.categoryIdArray = this.supplierForm.categoryIds
						? this.supplierForm.categoryIds.split(",")
						: []
					this.userForm = res.data.userVO
					this.userForm.roleIds = [this.rolelist.filter(item => item.code === "SUPPLIER")[0].id]
				})
				.finally(() => {
					this.loading = false
				})
		},

		resetForm(formName) {
			this.addEditDialog = false
			this.$refs[formName].resetFields()
		},

		getUserValid() {
			let validFlag = false
			this.$refs["userForm"].validate(valid => {
				if (valid) {
					validFlag = true
					return true
				} else {
					validFlag = false
					return false
				}
			})
			return validFlag
		},
		// 搜索建议- 开户银行

		querySearch(queryString, cb) {
			let results = queryString ? this.bankList.filter(this.createFilter(queryString)) : this.bankList
			// 调用 callback 返回建议列表的数据
			cb(results)
		},
		createFilter(queryString) {
			return restaurant => {
				return restaurant.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0
			}
		},
		//
		handleSelect(item) {
			this.supplierForm.bankType = item.label
		},

		getSupplierValid() {
			let validFlag = false
			this.$refs["supplierForm"].validate(valid => {
				if (valid) {
					validFlag = true
					return true
				} else {
					validFlag = false
					return false
				}
			})

			return validFlag
		},

		submitForm() {
			this.supplierForm.categoryIds = this.supplierForm.categoryIdArray.join()
			if (this.supplierForm.id) {
				// 编辑
				let suppilerValid = this.getSupplierValid()

				if (suppilerValid) {
					this.handleSave(this.supplierForm)
				}
			} else {
				// 新增
				let userValid = this.getUserValid()

				let suppilerValid = this.getSupplierValid()

				if (userValid && suppilerValid) {
					let params = Object.assign({}, this.userForm)
					params = Object.assign(params, this.supplierForm)

					// console.log(params)
					this.handleSave(params)
				}
			}
		},

		handleSave(params) {
			this.loading = true
			this.supplierForm.id
				? editSupplier(params)
					.then(() => {
						this.$notify.success({
							title: "成功",
							message: "编辑成功"
						})
						this.loading = false
						this.addEditDialog = false
						this.$emit("saveSuccess")
					})
					.catch(() => {
						this.loading = false
					})
				: addSupplier(params)
					.then(() => {
						this.loading = false
						this.$notify.success({
							title: "成功",
							message: "添加成功"
						})
						this.addEditDialog = false
						this.$emit("saveSuccess")
					})
					.catch(() => {
						this.loading = false
					})
		}
	}
}
</script>

<style lang="sass" scoped></style>
