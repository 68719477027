<template>
    <div class="mvk-layout-content-card">
        <el-button v-has="'sys:notice:add'" type="primary" class="mb15" round @click="addTemplate()">新增</el-button>
        <mvk-table
            ref="mvkTable"
            :table-column="tableColumn"
            :table-data-func="tableDataFunc"
            border
            :custom-page-size="100"
        >
            <template slot="status" slot-scope="scope" label="是否生效">
                <el-switch
                    v-model="scope.row.status"
                    :active-value="true"
                    :inactive-value="false"
                    @change="changeValid(scope.row)"
                />
            </template>

            <el-table-column label="操作" width="76" align="center">
                <template slot-scope="{ row }">
                    <el-button type="text" size="small" @click="handleEdit(row)"> 编辑 </el-button>
                    <!-- <delete-button
                        :id="row.id"
                        v-has="'sys:filter:delete'"
                        button-type="text"
                        size="small"
                        @delete="handleDelete"
                    >
                        删除
                    </delete-button> -->
                </template>
            </el-table-column>
        </mvk-table>
        <add-dom ref="addDom" @loadData="getList()" />
    </div>
</template>

<script>
import { getMessageTemplate, changeMessageTemplateType } from "api/business/marketing/message"
const TypeList = {
    0: "系统通知",
    1: "微信模板消息"
}
export default {
    components: {
        addDom: () => import("./add.vue")
    },
    data() {
        return {
            tableColumn: [
                { prop: "id", label: "ID", width: 60 },
                { prop: "name", label: "通知名称", align: "center", width: 150 },
                // {
                // 	prop: "type",
                // 	label: "通知类型",
                // 	width: 86,
                // 	formatter: (cellvalue) => {
                // 		let value = cellvalue.type
                // 		return TypeList[value]
                // 	}
                // },
                { prop: "content", label: "通知场景说明" },
                { prop: "status", label: "状态", slot: true, width: 80, align: "center" }
            ],
            tableDataFunc: parameter => {
                return getMessageTemplate(Object.assign(parameter)).then(res => {
                    return res.data
                })
            },
            loading: false
        }
    },
    methods: {
        getList() {
            this.$refs.mvkTable.refresh()
        },
        changeValid(params) {
            this.loading = true
            changeMessageTemplateType(params.id)
                .then(r => {
                    this.loading = false
                    if (r.status === "OK") {
                        this.getList()
                    }
                })
                .catch(() => {
                    this.loading = false
                })
        },
        addTemplate() {
            this.$refs.addDom.show()
        },
        handleEdit(row) {
            this.$refs.addDom.show(row)
        }
    }
}
</script>

<style lang="scss" scoped>
.opt {
	margin: 10px;
}
</style>
