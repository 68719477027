<template>
	<div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" height="640">
			<el-table-column slot="head" width="55">
				<template slot-scope="scope">
					<el-radio v-model="checkedId" :label="scope.row.id" @change.native="getInfo(scope.row)">
						<span />
					</el-radio>
				</template>
			</el-table-column>
			<template slot="images" slot-scope="{ row }" label="轮播图">
				<el-image v-for="(pic, index) in row.imagesArray" :key="index" style="width: 30px; height: 30px" :src="pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="row.imagesArray"
				/>
			</template>
		</mvk-table>
	</div>
</template>

<script>
import { getTopicList } from "api/business/marketing/topic.js"

export default {
	props: {
		checkedId: {
			type: [Number, String],
			default: 0
		}
	},
	data() {
		return {
			queryParam: {
				name: "",
				startTime: "",
				endTime: "",
				isAvailable: ""
			},
			tableColumn: [
				{ prop: "id", label: "ID" },
				{ prop: "name", label: "专题名称" },
				{ prop: "proCount", label: "专题商品数" },
				{ prop: "creationDate", label: "创建时间" }
			],
			tableDataFunc: parameter => {
				return getTopicList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			}
		}
	},
	methods: {
		getInfo(info) {
			console.log(info)
			this.$emit("getInfo", info)
		}
	}
}
</script>

<style lang="scss" scoped></style>
