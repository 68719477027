<template>
    <el-drawer title="充值详情" :visible.sync="viewDialog" size="500px">
        <el-descriptions :column="2" border>
            <el-descriptions-item label="订单编号" :span="2">{{ info.code }}</el-descriptions-item>
            <el-descriptions-item label="用户ID" :span="2">{{ info.id }}</el-descriptions-item>
            <el-descriptions-item label="用户名称">{{ info.userName }}</el-descriptions-item>
            <el-descriptions-item label="用户手机号">{{ info.userPhone }}</el-descriptions-item>
            <el-descriptions-item label="充值金额（元）">{{ info.payMoney }}</el-descriptions-item>
            <el-descriptions-item label="充值时间">{{ info.creationDate }}</el-descriptions-item>
            <el-descriptions-item label="支付方式">{{ rechargePayType[info.payType] }}</el-descriptions-item>
            <el-descriptions-item label="支付状态">{{ orderStatus[info.status] }}</el-descriptions-item>
        </el-descriptions>
    </el-drawer>
</template>

<script>
import { rechargeRecordDetail } from "api/business/financeManage/all.js"
import { rechargePayType } from "@/utils/constants"

const orderStatus = {
    1: "待支付",
    2: "已支付",
    3: "交易关闭"
}
export default {
    name: "RechargeView",
    data() {
        return {
            orderStatus,
            rechargePayType,
            info: {},
            viewDialog: false,
            i: 1
        }
    },
    methods: {
        show(info) {
            this.info = {}
            this.getInfo(info)
            this.viewDialog = true
        },

        getInfo(info) {
            rechargeRecordDetail(info).then(r => {
                if (r.status === "OK") this.info = r.data
            })
        }
    }
}
</script>

<style lang="scss" scoped></style>
