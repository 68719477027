<template>
	<div>
		<div class="flex-row" style="justify-content: space-between">
			<!-- <el-input v-model="queryParam.prodName" placeholder="输入商品名称搜索" clearable style="width:200px;" /> -->
			<el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="商品名称">
					<el-input v-model="queryParam.prodName" placeholder="输入商品名称搜索" clearable />
				</el-form-item>
				<el-form-item label="商品分类">
					<prod-category-tree v-model="queryParam.categoryId" check-strictly />
				</el-form-item>
			</el-form>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<el-table-column slot="head" width="55">
				<template slot-scope="scope">
					<el-radio v-model="currentId" :label="scope.row.key" @change.native="getGoodsInfo(scope.row)">
						<span />
					</el-radio>
				</template>
			</el-table-column>
			<template slot="pic" slot-scope="{ row }">
				<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
			</template>
		</mvk-table>
	</div>
</template>

<script>
import { saleProdList } from "api/business/selfManager/goods.js"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
export default {
	components: { ProdCategoryTree },
	name: "AdvertGoods",
	props: {
		checkedId: {
			type: [Number, String],
			default: 0
		}
	},
	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 50
				},
				{
					prop: "prodName",
					label: "商品名称"
				},
				{
					prop: "pic",
					label: "商品图片",
					slot: true
				},
				{
					prop: "shopName",
					label: "店铺"
				},
				{
					prop: "price",
					label: "商品价格"
				}
			],
			queryParam: {
				prodName: "",
				categoryId: ""
			},
			tableDataFunc: parameter => {
				// 出售中的商品，并过滤内购店的商品
				return saleProdList(Object.assign(parameter, { internal: false, prodName: this.queryParam.prodName })).then(res => {
					res.data.list.forEach(item => {
						item.key = item.id + "$$$" + item.shopId
					})
					return res.data
				})
			},
			currentId: this.checkedId
		}
	},

	mounted() {},

	methods: {
		getGoodsInfo(row) {
			console.log(row)
			this.$emit("update:checkedId", row.id)
			this.$emit("getInfo", row)
		}
	}
}
</script>

<style lang="scss" scoped></style>
