<template>
	<el-drawer :title="title" :visible="value" size="520px" @close="closeDrawer">
		<div class="drawer-content">
			<el-form ref="couponForm" :model="params" :rules="rules" label-width="100px">
				<el-form-item label="活动名称：" prop="name">
					<el-input v-model="params.name" placeholder="请输入抽奖活动名称" />
				</el-form-item>
				<el-form-item label="活动日期：">
					<el-date-picker v-model="timeVal" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd HH:mm:ss" size="small" type="datetimerange" placement="bottom-end" start-placeholder="开始日期"
						end-placeholder="结束日期" style="width: 386px" @change="onchangeTime"
					/>
				</el-form-item>
				<!-- rules -->
				<el-form-item label="规则：">
					<WangEditor
						style="width: 100%;"
						:content="params.rules"
						:menus="['fontSize','head','italic','underline','strikeThrough','indent','lineHeight','backColor','list','splitLine','quote']"
						:show-full-screen="false"
						:height="300"
						@editorContent="changeRules"
					/>
				</el-form-item>
				<el-form-item label="说明：">
					<el-input
						v-model="params.remarks"
						type="textarea"
						:rows="3"
						:maxlength="150"
						placeholder="请输入内容"
					/>
				</el-form-item>
			</el-form>
		</div>

		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button v-throttle="submitForm" type="primary" icon="el-icon-check" :loading="loading">
					立即保存
				</el-button>
				<el-button type="text" @click="closeDrawer()">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import * as api from "api/business/selfManager/lottery"
import WangEditor from "@/components/wangEditor/index.vue"

export default {
	name: "Drawbox",
	components: { WangEditor },
	model: {
		prop: "value",
		event: "changeShow"
	},
	props: {
		value: {
			type: Boolean,
			default: false
		},
		id: {
			type: [Number, null],
			default: null
		}
	},

	computed: {

	},

	data() {
		return {
			title: "新增活动",
			params: {
				name: "",
				startTime: "",
				endTime: "",
				rules: "",
				remarks: ""
			},
			timeVal: [],
			rules: [],
			loading: false
		}
	},

	mounted() {
		// this.getShopList()
		// if( this.$route.params.id ){
		//     this.getCouponInfo()
		// }
	},
	watch: {
		id(newVal) {
			// console.log(newVal)
			if (newVal) this.getDetail(newVal)
		}
	},

	methods: {
		onchangeTime(e) {
			console.log(e)
			this.timeVal = e
			this.date = e || ""
			this.$set(this.params, "startTime", e ? e[0] : "")
			this.$set(this.params, "endTime", e ? e[1] : "")
		},
		getDetail(id) {
			api.lotteryById(id).then(res => {
				// 		return res.data
				if (res.status && res.data) {
					this.$set(this, "params", res.data)
					this.timeVal = [this.params.startTime, this.params.endTime]
				}
			})
		},
		changeRules(v) { this.params.rules = v },
		closeDrawer() {
			this.$emit("changeShow", false)
		},
		submitForm() {
			this.loading = true
			console.log("this.params", this.params)
			// 修改
			api[this.id ? "editLottery" : "addLottery"](this.params).then(res => {
				if (res.status == "OK") {
					this.$message.success("修改成功")
					this.$emit("changeShow", false)
					this.$emit("success")
				}
			}).finally(() => {
				this.loading = false
			})

		}

	}
}
</script>

<style lang="scss" scoped></style>
