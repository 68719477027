<template>
	<el-drawer :title="title" :visible.sync="addEditVisible" size="70%" :append-to-body="true">
		<div class="drawer-content">
			<el-form :model="form" ref="form" :rules="rules" label-width="180px" :inline="false">
				<el-form-item label="节日类型" prop="type">
					<el-radio-group v-model="form.type">
						<el-radio :label="1">传统节日</el-radio>
						<el-radio :label="2">西方节日</el-radio>
						<el-radio :label="3">公众节日</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="节日名称" prop="name">
					<el-input v-model="form.name" class="inputWidth"></el-input>
				</el-form-item>
				<el-form-item label="节日公历日期">
					<el-input v-model="form.calendarDate" class="inputWidth" disabled></el-input>
				</el-form-item>
				<el-form-item label="节日农历日期" prop="lunarDate">
					<el-input v-model="form.lunarDate" class="inputWidth" disabled></el-input>
				</el-form-item>
				<el-form-item label="节日选择" prop="calendarDate">
					<mvk-calendar v-model="dateValue" @solarAndLunar="getSolarAndLunar" :selectedDates="selectedDates">
					</mvk-calendar>
				</el-form-item>
			</el-form>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button type="primary" @click="handleSubmit"> 保存 </el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { saveHoliday } from "api/business/marketing/holiday"
export default {
	name: "HolidayAdd",

	data() {
		return {
			title: "",
			addEditVisible: false,
			form: {
				type: "",
				name: "",
				calendarDate: "",
				lunarDate: ""
			},
			rules: {
				type: [
					{
						required: true,
						message: "请选择节日类型",
						trigger: "blur"
					}
				],
				name: [
					{
						required: true,
						message: "请选择节日名称",
						trigger: "blur"
					}
				],
				calendarDate: [
					{
						required: true,
						message: "请选择节日",
						trigger: "change"
					}
				]
			},
			holidayType: "",
			dateValue: new Date(),
			selectedDates: []
		}
	},

	mounted() {},

	methods: {
		add() {
			this.addEditVisible = true
			this.form = {
				type: "",
				name: "",
				calendarDate: "",
				lunarDate: ""
			}
		},

		edit(record) {
			this.addEditVisible = true
			this.selectedDates = []
			this.$nextTick(() => {
				this.form = JSON.parse(JSON.stringify(record))
				this.selectedDates.push(this.form.calendarDate.substr(0, 10))
			})
		},

		getSolarAndLunar(date) {
			this.form.calendarDate = date.solar
			this.form.lunarDate = date.lunar
		},

		handleSubmit() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					saveHoliday(this.form).then(() => {
						this.$notify.success({
							title: "保存成功"
						})
						this.addEditVisible = false
						this.$emit("saveSuccess")
					})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
