import fetch from "@/axios/fetch"

// 充值模块

export function rechargeRecordList(params) { // 充值列表
	return fetch({
		url: "/creditOrder/search",
		method: "get",
		params: params
	})
}

export function rechargeRecordDetail(params) { // 充值详情
	return fetch({
		url: "/creditOrder/" + params.id,
		method: "get"
	})
}

export function ticketRecordsList(params) { // 发票列表
	return fetch({
		url: "/umsTicketRecords/list",
		method: "get",
		params
	})
}

export function ticketDetail(params) { // 发票详情
	return fetch({
		url: "/umsTicketRecords/find_ticket_detail",
		method: "get",
		params
	})
}
