<template>
	<!-- 组织架构  -->

	<div class="mvk-layout-content-card height100">
		<el-container class="height100">
			<el-aside width="370px" class="height100">
				<el-input v-model="filterText" placeholder="输入关键字查找" class="round mb10" />
				<!-- <el-scrollbar style="height: calc(100% - 32px)"> -->
				<el-tree
					ref="tree"
					:data="organizationTreeData"
					:filter-node-method="filterNode"
					:props="defaultProps"
					node-key="id"
					:default-expanded-keys="expandedKey"
					highlight-current
					style="height: 500px"
					@node-click="handleNodeClick"
				>
					<div slot-scope="{ node, data }" class="custom-tree-node">
						<div>
							<span v-if="!data.editFlag" class="custom-tree-node-label" :title="node.label">
								{{ node.label }}
							</span>
							<el-input v-else v-model="data.value" placeholder="" class="test" size="small" />
						</div>
						<span v-if="data.type == 1" class="el-ic">
							<el-button v-if="!!data.editFlag" type="text" size="mini" @click.stop="handleSave(data)">
								保存
							</el-button>
							<el-button v-else type="text" size="mini" @click.stop="handleEdit(data)"> 编辑 </el-button>
							<el-button type="text" size="mini" @click.stop="handleAddChild(data)"> 新增子级 </el-button>
							<delete-button
								v-if="data.parentId != null"
								button-type="text"
								size="mini"
								@delete="handleDelete(node, data)"
							>
								删除
							</delete-button>
						</span>
					</div>
				</el-tree>
				<!-- </el-scrollbar> -->
			</el-aside>
			<el-main>
				<member-list :organization-id="currentTreeData" />
			</el-main>
		</el-container>
	</div>
</template>

<script>
import { organitionTree, organitionUpdate, organitionAdd, organitionDelete } from "api/basePage/organition"
export default {
	name: "OrganizationIndex",
	components: {
		"member-list": () => import("./memberList.vue")
	},
	data() {
		return {
			defaultProps: {
				children: "child",
				label: "name"
			},
			filterText: "",
			organizationTreeData: [],
			currentTreeData: 0, // 当前选中的机构
			expandedKey: []
		}
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val)
		}
	},
	mounted() {
		this.getDataTree()
	},

	methods: {
		// 搜索
		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},
		handleAddChild(data) {
			if (!Object.prototype.hasOwnProperty.call(data, "pidsArray")) {
				data.pidsArray = []
			}

			data.pidsArray.push(data.id)

			const newChild = {
				id: "",
				parentId: data.id,
				name: "",
				value: "",
				editFlag: true,
				pids: data.pidsArray.join(),
				pidsArray: data.pidsArray,
				type: 1
			}
			if (!data.child) {
				this.$set(data, "child", [])
			}
			data.child.push(newChild)
		},
		async handleSave(data) {
			data.name = data.value
			data.editFlag = false

			if (data.id) {
				// 编辑
				await organitionUpdate(data).then(() => {
					this.$notify.success({
						title: "保存成功"
					})
				})
			} else {
				// 新增
				await organitionAdd(data).then(() => {
					this.$notify.success({
						title: "保存成功"
					})
				})
			}

			this.getDataTree()
		},

		handleEdit(data) {
			this.$set(data, "value", data.name)
			this.$set(data, "editFlag", true)
		},

		// eslint-disable-next-line no-unused-vars
		handleNodeClick(data, node, tree) {
			// console.log(data)
			// console.log(node)
			// console.log(tree)
			if (data.id) {
				this.currentTreeData = data.id
			}
		},
		async handleDelete(node, data) {
			// console.log(node)
			// console.log(data)
			const parent = node.parent
			const children = parent.data.child || parent.data
			// console.log(children)
			const index = children.findIndex(d => d.id === data.id)
			children.splice(index, 1)

			let temp = JSON.parse(sessionStorage.getItem("eshop-organization"))

			if (data.id) {
				// 已存在的数据调接口删数据库数据
				await organitionDelete(data.id)
					.then(() => {
						this.$notify.success({
							title: "删除成功"
						})
					})
					.catch(() => {
						this.organizationTreeData = temp
					})
			}

			sessionStorage.setItem("eshop-organization", JSON.stringify(this.organizationTreeData))
		},

		getDataTree() {
			organitionTree().then(res => {
				this.getChildPids(res.data, [])
				this.organizationTreeData = res.data
				this.currentTreeData = this.organizationTreeData.length > 0 ? this.organizationTreeData[0].id : 0
				this.organizationTreeData.length > 0
					? this.expandedKey.push(this.organizationTreeData[0].id)
					: (this.expandedKey = [])
				sessionStorage.setItem("eshop-organization", JSON.stringify(this.organizationTreeData))
			})
		},

		getChildPids(data, parentPids) {
			var vm = this
			data.forEach(item => {
				if (!Object.prototype.hasOwnProperty.call(item, "pidsArray")) {
					item.pidsArray = []
				}

				item.pidsArray = item.pidsArray.concat(parentPids)

				if (item.parentId) {
					item.pidsArray.push(item.parentId)
				}

				item.pids = item.pidsArray.join()

				if (item.child && item.child.length > 0) {
					vm.getChildPids(item.child, item.pidsArray)
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-tree-node__content {
	height: 42px;
}

::v-deep .el-main {
	padding: 0;
	margin-left: 50px;
}
</style>
