<template>
	<el-dialog title="发货" :visible.sync="visible" :close-on-click-modal="false" top="150px" width="600px" :modal="false" :modal-append-to-body="false">
		<el-form ref="OrderForm" :model="orderForm" :rules="Rules" label-width="80px" :inline="false">
			<el-form-item label="快递公司" prop="courier">
				<mvk-select-page
					ref="mvkSelect"
					v-model="orderForm.courier"
					:list-data-func="listDataFunc"
					filterable
					name="expName"
					:filter-method="filterExpress"
					placeholder="快递公司"
					class="exp-input-page inputWidth"
					clearable
					@clear="clearExpCompay()"
					@change="checkSelect"
				/>
			</el-form-item>
			<el-form-item label="快递单号" prop="courierNum">
				<el-input v-model="orderForm.courierNum" placeholder="快递单号" class="inputWidth" clearable />
			</el-form-item>
			<el-form-item>
				<el-button v-if="changeModel" type="primary" @click="onSubmit(true)">修改</el-button>
				<el-button v-else type="primary" @click="onSubmit()">发货</el-button>
				<el-button @click="visible = false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { orderSend, orderSendUp } from "api/business/selfManager/order"
import { getExpressCompyV2 } from "api/business/selfManager/express"
let expName = ""
export default {
	name: "OrderSend",
	props: {
		changeModel: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			visible: false,
			orderForm: {
				orderId: "",
				productType: 0,
				courier: "",
				courierNum: ""
			},

			Rules: {
				courier: [
					{
						required: true,
						message: "请输入快递公司",
						trigger: "blur"
					}
				],
				courierNum: [
					{
						required: true,
						message: "请输入快递单号",
						trigger: ["blur", "change"]
					}
				]
			},

			queryParam: {
				expName: ""
			},

			// 获取快递公司列表
			listDataFunc(parameter) {
				return getExpressCompyV2(Object.assign(parameter, { expName })).then(res => {
					let temp = res.data
					return temp
				})
			}
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.$refs.mvkSelect.refresh()
			},
			deep: true
		}
	},
	mounted() { },

	methods: {
		open(orderId) {
			this.visible = true
			this.orderForm.orderId = orderId
			const expStr = localStorage.getItem("defaultExpress")
			if (expStr) this.orderForm.courier = expStr
			else this.orderForm.courier = null
			this.$nextTick(() => {
				this.$refs.mvkSelect.refresh()
				this.$refs["OrderForm"].clearValidate()
			})
		},
		clearExpCompay() {
			this.orderForm.courier = null
			this.orderForm.courierCompany = ""
			this.orderForm.courierCompanyCode = ""
		},
		// 发货和修改快递单号
		onSubmit(changeCourierNum = false) {
			const params = {
				courierCompany: this.orderForm.courier ? JSON.parse(this.orderForm.courier).courierCompany : "",
				courierCompanyCode: this.orderForm.courier ? JSON.parse(this.orderForm.courier).courierCompanyCode : "",
				courierNum: this.orderForm.courierNum,
				orderId: this.orderForm.orderId,
				productType: 0
			}
			this.$refs['OrderForm'].validate(valid => {
				if (valid) {
					let fn = orderSend
					if (changeCourierNum) { fn = orderSendUp; params.orderCode = this.orderForm.orderId }
					fn(params).then(() => {
						this.$notify.success({
							title: "成功",
							message: "发货成功"
						})
						this.orderForm.courierNum = ""
						this.visible = false
						this.$emit("success")
					})
				} else {
					return false
				}
			})
		},
		// 修改快递单号
		// changeCouierNum() {
		// 	console.log(this.orderForm);
		// 	const params = {
		// 		courierCompany: this.orderForm.courier ? JSON.parse(this.orderForm.courier).courierCompany : "",
		// 		courierCompanyCode: this.orderForm.courier ? JSON.parse(this.orderForm.courier).courierCompanyCode : "",
		// 		couierNum: this.orderForm.courierNum,
		// 		orderCode: this.orderForm.orderId
		// 	}
		// 	orderSendUp(params).then(res => {

		// 		this.$notify.success({
		// 			title: "成功",
		// 			message: "快递单号修改成功"
		// 		})
		// 		this.visible = false
		// 		this.$emit("success")
		// 	})
		// },
		checkSelect() {
			this.$refs.OrderForm.validateField("courier")
		},
		filterExpress(query) {
			this.queryParam.expName = query
			expName = query
			this.orderForm.courierCompany = query
		}
	}
}
</script>
