<template>
    <div class="mvk-layout-content-card">
        <el-form ref="form" :model="pwForm" label-width="120px" :inline="false">
            <el-form-item label="用户名:" prop="username" class="inputWidth">
                {{ pwForm.username }}
            </el-form-item>
            <el-form-item label="姓名:" prop="fullName" class="inputWidth">
                {{ pwForm.fullName }}
            </el-form-item>
            <el-form-item label="手机号码:" prop="mobile" class="inputWidth">
                {{ pwForm.mobile }}
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { personalInfo } from "api/basePage/user"

export default {
    components: {},
    props: {},
    data() {
        return {
            pwForm: {
                id: "",
                fullName: "",
                username: "",
                mobile: ""
            }
        }
    },
    watch: {},
    computed: {},
    methods: {
        getPersonInfo() {
            personalInfo().then(res => {
                this.pwForm = res.data
            })
        }
    },
    created() {},
    mounted() {
        this.getPersonInfo()
    }
}
</script>
<style lang="scss" scoped></style>
