<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="140px" :inline="true">
			<el-form-item label="所属供应商：">
				<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
					<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			<el-form-item label="订单号：">
				<el-input v-model="queryParam.code" placeholder="请输入订单号" size="small" clearable />
			</el-form-item>
			<el-form-item label="商品名称：">
				<el-input v-model="queryParam.productName" placeholder="请输入商品名称" size="small" clearable />
			</el-form-item>
			<el-form-item label="订单状态：">
				<el-select v-model="orderStatus" clearable>
					<el-option v-for="(item, index) in Object.values(orderStatusMap)" :key="item" :value="index + 1"
						:label="item" />
				</el-select>
			</el-form-item>
			<el-form-item label="订单时间：">
				<el-date-picker v-model="timeVal" :default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" size="small" type="daterange"
					placement="bottom-end" placeholder="自定义时间" style="width: 200px" @change="onchangeTime" />
			</el-form-item>
			<el-form-item label="报表编号：">
				<el-input v-model="queryParam.reportCode" placeholder="报表编号" size="small" clearable />
			</el-form-item>
		</el-form>

		<el-tabs v-model="queryParam.isSettle">
			<el-tab-pane label="未结算" name="0" />
			<el-tab-pane label="结算中" name="1" />
			<el-tab-pane label="已结算" name="2" />
		</el-tabs>
		<div v-show="queryParam.isSettle == '0'" class="mb10 flex">
			<el-button type="primary" :disabled="selectList.length == 0" :loading="loading" @click="handleGenReport()">
				生成结算报表
			</el-button>
			<span v-show="selectList.length > 0" class="select-text">
				共选择{{ selectList.length }} 条记录， 结算成本(含税):
				<span class="count-num"> ￥<count-to :start-val="0" :end-val="costPrice" :duration="1000" /> </span>

				销售收入(含税)：
				<span class="count-num"> ￥<count-to :start-val="0" :end-val="salePrice" :duration="1000" /> </span>
				退款金额：
				<span class="count-num"> ￥<count-to :start-val="0" :end-val="refundPayMoney" :duration="1000" /> </span>
				优惠金额：
				<span class="count-num"> ￥<count-to :start-val="0" :end-val="couponMoney" :duration="1000" /> </span>
			</span>
		</div>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc"
			:custom-page-sizes="[20, 50, 100, 200, 500, 1000]" :custom-page-size="20" row-key="code" border height="540"
			@selection-change="handleSelectionChange">
			<template slot="head">
				<el-table-column v-if="queryParam.isSettle == '0'" key="111key" type="selection" width="40"
					:reserve-selection="true" />
				<el-table-column v-else key="222key" width="200" prop="reportCode" label="报表编号" />
			</template>

			<template slot="code" slot-scope="{ row }">
				<p>{{ row.code }}</p>
				<p style="color: #6a79f9">[{{ orderTypeList[row.type] }}]</p>
			</template>
			<template slot="pic" slot-scope="{ row }">
				<div v-if="row.items && row.items.length > 0" class="flex-row">
					<el-image style="min-width: 40px; height: 40px; width: 40px" :src="row.items[0].pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'"
						:preview-src-list="[row.items[0].pic]" />
					<div style="margin-left: 4px">
						<p>{{ row.items[0].prodName }}</p>
						<p style="color: #ff5429">￥{{ row.items[0].price }} × {{ row.items[0].prodCount }}</p>
					</div>
				</div>
			</template>
			<template slot="supplyName" slot-scope="{ row }">
				<div v-if="row.source === 0">
					<span class="source source0">内</span>{{ row.items && row.items[0].supplierName }}
				</div>
				<div v-else>
					<span class="source source1">外</span>{{ row.items && row.items[0].supplierName }}
				</div>
			</template>
			<template slot="status" slot-scope="{ row }">
				<el-tag v-show="!row.subStatus" class="mr10">{{ row.status | OrderStatus }}</el-tag>
				<span v-show="row.subStatus">
					<el-tag type="danger">{{ row.subStatus | OrderStatus }}</el-tag>
				</span>
			</template>
		</mvk-table>
	</div>
</template>

<script>
import CountTo from "vue-count-to"
import { mapState, mapActions } from "vuex"
import { getSettleOrderList, orderSettleReport } from "api/business/financeManage/settle.js"
import { orderStatus, payType, orderType } from "@/utils/constants"
const settleStatus = {
	0: "未结算",
	1: "结算中",
	2: "已结算"
}
export default {
	name: "OrderSettle",
	components: {
		CountTo
	},
	computed: {
		...mapState("dict", ["supplierlist"]),

		costPrice() {
			if (this.selectList.length > 0) {
				return this.selectList.map(item => item.cosPrice * item.items[0].prodCount).reduce((x, y) => x + y)
			} else {
				return 0
			}
		},
		salePrice() {
			if (this.selectList.length > 0) {
				return this.selectList.map(item => item.actualMoney).reduce((x, y) => x + y)
			} else {
				return 0
			}
		},
		couponMoney() {
			if (this.selectList.length > 0) {
				return this.selectList.map(item => item.couponMoney).reduce((x, y) => x + y)
			} else {
				return 0
			}
		},
		refundPayMoney() {
			if (this.selectList.length > 0) {
				return this.selectList.map(item => item.refundPayMoney).reduce((x, y) => x + y)
			} else {
				return 0
			}
		}
	},
	data() {
		return {
			isSettle: "notstart",
			orderStatus: "",
			orderStatusMap: orderStatus,
			queryParam: {
				supplierId: "",
				code: "",
				productName: "",
				isSettle: "0",
				status: "",
				subStatus: "",
				startTime: "",
				endTime: "",
				reportCode: ""
			},
			tableColumn: [
				// { prop: "reportCode", label: "报表编号", width: 200 },
				{ prop: "code", label: "订单号|类型", slot: true, width: 200 },
				{ prop: "memberMobile", label: "手机号", width: 80 },
				{ prop: "pic", label: "商品信息", slot: true, tooltip: true, minWidth: 180 },
				{
					prop: "refundPayMoney", label: "退款金额", width: 80,
					formatter: cellvalue => {
						return (cellvalue.refundPayMoney || 0) + "元"
					}
				},
				{
					prop: "couponMoney", label: "优惠金额", width: 80,
					formatter: cellvalue => {
						if (cellvalue.code.indexOf("R") > -1) return "-"
						else return (cellvalue.couponMoney || 0) + "元"
					}
				},
				{
					prop: "actualMoney",
					label: "支付金额（含税）",
					width: 80, align: "center",
					formatter: cellvalue => {
						if (cellvalue.code.indexOf("R") > -1) return "-"
						else return (cellvalue.actualMoney || 0) + "元"
					}
				},
				// formatter: (row) => {
				// 	if (row.code && row.code.substr(0, 1) == "R") {
				// 		return "-" + row.actualMoney
				// 	} else {
				// 		return row.actualMoney
				// 	}
				// }
				{
					prop: "cosPrice", label: "商品成本（含税）", width: 80,
					formatter: cellvalue => {
						if (cellvalue.code.indexOf("R") > -1) return "-"
						else return (cellvalue.cosPrice || 0) + "元"
					}
				},
				{ prop: "supplyName", label: "供应商", slot: true, tooltip: true, minWidth: 86 },
				{ prop: "status", label: "订单状态", slot: true },
				{
					prop: "isSettle",
					label: "结算状态", width: 76, align: "center",
					formatter: row => {
						return settleStatus[row.isSettle]
					}
				},
				{
					prop: "payType",
					label: "支付方式",
					width: 76,
					formatter: cellvalue => {
						let value = cellvalue.payType
						return payType[value]
					}
				},
				{ prop: "creationDate", label: "下单时间", width: 150 }
				// { prop: "settleRemarks", label: "结算备注" },
			],
			tableDataFunc: parameter => {
				return getSettleOrderList(Object.assign(parameter, this.queryParam)).then(res => {
					res.data.list.forEach(item => {
						if (item.code && item.code.substr(0, 1) == "R") {
							item.actualMoney = -item.actualMoney
							item.cosPrice = -item.cosPrice
						}
					})
					return res.data
				})
			},
			orderTypeList: orderType,
			selectList: [],
			timeVal: [],
			loading: false
		}
	},
	filters: {
		OrderStatus(type) {
			return orderStatus[type]
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.searchList()
			},
			deep: true
		},
		orderStatus(newVal) {
			let mainStatus = [1, 2, 3, 5, 6, 11, 13] // 主状态
			let afterSaleStatus = [7, 8, 10, 12] // 申请退款，退款中
			if (mainStatus.indexOf(newVal) > -1) {
				this.queryParam.status = newVal
				this.queryParam.subStatus = ""
			} else if (afterSaleStatus.indexOf(newVal) > -1) {
				this.queryParam.status = ""
				this.queryParam.subStatus = newVal
			} else if (newVal == 4) {
				// 待评价
				this.queryParam.status = 5
				this.queryParam.subStatus = newVal
			} else if (newVal == 9) {
				// 已退款
				this.queryParam.status = 6
				this.queryParam.subStatus = newVal
			} else {
				this.queryParam.status = ""
				this.queryParam.subStatus = ""
			}
		}
	},
	created() {
		if (this.$route.query.supplierId) {
			this.queryParam.supplierId = Number(this.$route.query.supplierId)
			this.queryParam.productName = this.$route.query.productName
			this.isSettle = this.$route.query.isSettle
			this.queryParam.isSettle = this.$route.query.isSettle
		}
	},
	mounted() {
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	},

	methods: {
		...mapActions("dict", ["getSupplierList"]),

		searchList() {
			this.$refs.mvkTable.refresh()
			this.$refs.mvkTable.clearSelection()
		},

		handleSelectionChange(val) {
			this.selectList = val
		},

		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},

		// 生成报表
		handleGenReport() {
			this.loading = true
			orderSettleReport({
				orderCodes: this.selectList.map(item => item.code)
			}).then(() => {
				this.$notify.success("生成报表成功")
				this.$refs.mvkTable.refresh()
				this.$refs.mvkTable.clearSelection()
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.source {
	display: inline-block;
	margin-right: 4px;
	line-height: 1;
	border: 1px solid #4f8aff;
	color: #4f8aff;
	padding: 3px;
	border-radius: 50px;
	font-size: 12px;
}

.source0 {
	color: #80b568;
	border-color: #80b568;
}

.select-text {
	display: inline-block;
	margin-left: 20px;
}

.count-num {
	color: #ff5429;
	font-size: 24px;
}
</style>
