<template>
	<mvk-table
		ref="mvkTable"
		:table-column="tableColumn"
		:table-data-func="tableDataFunc"
		class="mt15"
		header-cell-class-name="custom-table1"
	>
		<template slot="pic" slot-scope="{ row }" label="商品图">
			<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
		</template>
		<el-table-column label="操作" width="100" align="center" header-align="center">
			<template slot-scope="{ row }">
				<router-link
					v-has="'pms:product:detail'"
					:to="{ path: '/selfManager/creatProduct/' + row.id + '/true/true' }"
					class="link"
				>
					<el-button type="text" size="small">详情</el-button>
				</router-link>
			</template>
		</el-table-column>
	</mvk-table>
</template>

<script>
import { prodList } from "api/business/selfManager/goods.js"

// 回收站商品
export default {
	name: "RecycleGoods",
	components: {},
	props: {},
	data() {
		return {
			tableColumn: [
				{ prop: "id", label: "商品ID", width: "80" },
				{ prop: "pic", label: "商品图", width: "80", slot: true },
				{ prop: "prodName", label: "商品名称" },
				{
					prop: "source",
					label: "来源",
					formatter: cellValue => {
						if (cellValue.source === 1) {
							return "云链美物平台"
						} else if (cellValue.source === 2) {
							return "商户平台"
						} else if (cellValue.source === 0) {
							return "自营平台"
						}
					}
				}
				// { prop: "orderNum", label: "排序", width: "50" }
			],

			// 查询条件参数
			queryParam: {
				status: "-1"
			},

			tableDataFunc: parameter => {
				return prodList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			}
		}
	},
	watch: {},
	computed: {},
	methods: {},
	created() {},
	mounted() {}
}
</script>
<style lang="scss">
.link + .link {
	margin-left: 8px;
}

.custom-table1 {
	background-color: #f7f7f7 !important;
}
</style>
