<template>
	<div class="mvk-layout-content-card">
		<el-tabs v-model="queryParam.projectId">
			<el-tab-pane v-for="(item,index) in tabsList" :key="index" :label="item.name" :name="item.value" />
		</el-tabs>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15" />
	</div>
</template>

<script>
import { getAccountList } from "api/business/livePay"
// import AccountDetail from './accountDetail'
export default {
	name: "AccountManager",
	components: {
	},
	data() {
		return {
			tabsList: [
				{ value: "c2670", name: "水费" },
				{ value: "c2680", name: "电费" },
				{ value: "c2681", name: "煤气" }
			],
			// 查询条件参数
			queryParam: {
				projectId: "c2670"
			},
			tableColumn: [
				{ prop: "userId", label: "会员ID" },
				{ prop: "userName", label: "微信昵称" },
				{ prop: "projectId", label: "缴费类型", formatter: value => {
					let map = {
						"c2670": "水费",
						"c2680": "电费",
						"c2681": "气费"
					}
					return map[value.projectId]
				} },
				{ prop: "rechargeAccount", label: "缴费户号" },
				{ prop: "payerUnit", label: "缴费单位" },
				{ prop: "payerCity", label: "缴费城市" }
			]

			// tableDataFunc:parameter => {
			// 	return getAccountList(Object.assign(parameter, this.queryParam))
			// 		.then(res => {
			// 			return res.data
			// 		})
			// },
		}
	},
	computed: {
		tableDataFunc() {
			let queryParams = JSON.parse(JSON.stringify(this.queryParam))

			return parameter => {
				return getAccountList(Object.assign(parameter, queryParams))
					.then(res => {
						// let temp = {
						// 	list:[{
						// 		itemId:0,
						// 		userId:'',
						// 		userName:'',
						// 		projectId:'',
						// 		rechargeAccount:'',
						// 		payerUnit:'',
						// 		payerCity:''
						// 	}],
						// 	totalElements: 0,
						// 	totalPages: 0
						// }
						// return temp
						return res.data

					})
			}
		}
	},

	mounted() {

	},

	methods: {
	}
}
</script>

<style lang="scss" scoped>

</style>
