<template>
	<div class="mvk-layout-content-card">
		<el-form :model="form" ref="form" :rules="rules" label-width="300px" :inline="false">
			<el-form-item label="开启会员签到" prop="status">
				<el-radio-group v-model="form.status">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="每日签到获得积分" prop="signIntegral">
				<el-input-number v-model="form.signIntegral" :min="0" />
			</el-form-item>
			<el-form-item label="签到阶梯赚积分" prop="checkPointsArry">
				<div style="color: #606266">
					<div>
						连续签到
						<el-input-number v-model="form.checkPoints[0].dayNum" :min="0" :max="7" style="margin: 0 8px" />
						天， 额外奖励
						<el-input-number v-model="form.checkPoints[0].integral" :min="0" style="margin: 0 8px" />
						积分
					</div>
					<div style="margin: 8px 0">
						连续签到
						<el-input-number v-model="form.checkPoints[1].dayNum" :min="0" :max="7" style="margin: 0 8px" />
						天， 额外奖励
						<el-input-number v-model="form.checkPoints[1].integral" :min="0" style="margin: 0 8px" />
						积分
					</div>
					<div>
						连续签到
						<el-input-number v-model="form.checkPoints[2].dayNum" :min="0" :max="7" style="margin: 0 8px" />
						天， 额外奖励
						<el-input-number v-model="form.checkPoints[2].integral" :min="0" style="margin: 0 8px" />
						积分
					</div>
					<div style="color: #acacac; margin-top: 8px">注：以上最多连续7天</div>
				</div>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" :disabled="loading" @click="handleSubmit">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { getSignInConfig, saveSignInConfig, editSignInConfig } from "api/business/marketing/integral"
export default {
	name: "IntegralSignIn",

	data() {
		return {
			form: {
				id: "",
				status: true,
				signIntegral: 0,
				checkPoints: [
					{
						dayNum: 0,
						integral: 0
					},
					{
						dayNum: 0,
						integral: 0
					},
					{
						dayNum: 0,
						integral: 0
					}
				]
			},
			rules: {},
			newFlag: true, //新增，修改标志
			loading: false
		}
	},

	mounted() {
		this.getConfig()
	},

	methods: {
		getConfig() {
			this.loading = true
			getSignInConfig().then((res) => {
				this.loading = false
				// console.log(res)
				if (res.data.list && res.data.list.length == 0) {
					this.newFlag = true
				} else {
					this.newFlag = false
					this.form = res.data.list[0]
					this.form.checkPoints = JSON.parse(this.form.checkPoints)
				}
			}).catch(()=>{
				this.loading = false
			})
		},
		handleSubmit() {
			if (this.newFlag) {
				saveSignInConfig(this.form).then((res) => {
					this.getConfig()
					this.$notify.success({
						title: "成功",
						message: "保存成功"
					})
				})
			} else {
				editSignInConfig(this.form).then((res) => {
					this.getConfig()
					this.$notify.success({
						title: "成功",
						message: "修改成功"
					})
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped></style>
