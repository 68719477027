<template>
	<div class="mvk-layout-content-card">
		<!-- 操作 -->
		<div class="options flex flex-sb">
			<div>
				<el-button size="small" type="primary" round @click="handleEdit()">
					新增分类
				</el-button>
			</div>
			<div>
				<el-input v-model="queryParam.name" placeholder="请输入分类名称查找" prefix-icon="el-icon-search" clearable
					@input="search()"
				/>
			</div>
		</div>
		<mvk-table ref="mvkTable" v-loading="switchLoading" :table-column="tableColumn" :table-data-func="tableDataFunc"
			stripe class="mt20"
		>
			<!-- <template slot="receiveTime" slot-scope="{ row }" label="店铺编号"> -->

			<template slot="enabled" slot-scope="{ row }">
				<el-switch v-model="row.enabled" active-color="#4F8AFF" inactive-color="#ccc"
					@change="changeSwitch(row)"
				/>
			</template>

			<template slot="productNums" slot-scope="{ row }">
				<div :class="{'link': row.productNums > 0}" @click="checkGoodsForDialog(row.productIds)">{{ row.productNums }}{{ row.productNums > 0? '个':'' }}</div>
			</template>
			
			</el-table-column>
			<el-table-column label="操作" width="100" align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
				</template>
			</el-table-column>
		</mvk-table>

		<el-dialog title="新增分类" :visible.sync="editClassifyShow" width="420px" @close="clearValidate()">
			<div class="mt20 p20">
				<el-form ref="editClassifyForm" :model="formData" :rules="rules" label-width="120px"
					class="demo-dynamic"
				>
					<el-form-item prop="name" label="分类名称">
						<el-input v-model="formData.name" maxlength="20" style="width: 200px" clearable />
					</el-form-item>
					<el-form-item prop="sort" label="排序">
						<el-input-number v-model="formData.sort" :min="1" label="描述文字" />
					</el-form-item>
					<el-form-item prop="enabled" label="启用">
						<el-switch v-model="formData.enabled" active-color="#4F8AFF" inactive-color="#ccc" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog()">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="submitForm('editClassifyForm')">提交</el-button>
			</span>
		</el-dialog>
		<!--展示仓库中的商品-->
		<goods-list-model ref="goodsList" :multi="true" type="ware" :delivery-mode="-1" list-read-only :selected="checkedGoods" />
	</div>
</template>

<script>
import * as api from "@/api/business/selfManager/labour"
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import { debounce } from "utils/utils"
export default {
	components: { GoodsListModel },
	data() {
		return {
			// 查询条件参数
			queryParam: { name: "" },
			dataList: [],

			tableColumn: [
				{
					prop: "name",
					label: "分类名称",
					minWidth: 100
				},
				{
					prop: "enabled",
					label: "是否启用",
					slot: true,
					width: 70
				},
				{
					prop: "sort",
					label: "排序",
					align: "center",
					width: 76
				},
				{
					prop: "productNums",
					label: "已配置商品",
					align: "center",
					width: 96,
					slot: true
				}
			],
			switchLoading: false,
			tableDataFunc: parameter => {
				return api.getLaborClassifyList(Object.assign(parameter, this.queryParam)).then(res => {
					res.data.list = res.data.list.map(e => {
						if (e.gsModel) e.gsModelArr = e.gsModel.split(";")

						// 增加员工表格
						e.clerkData = []
						e.loading = false
						return e
					})
					return res.data
				})
			},
			selectList: [],

			editClassifyShow: false,
			loading: false,
			formData: {},
			rules: {
				name: [
					{ required: true, message: "请输入分类名称", trigger: "blur" }
				]
			},
			checkedGoods: []
		}
	},
	methods: {
		
		// 新增 / 编辑
		handleEdit(row) {
			if (row) {
				this.formData = row
			} else { 
				this.formData = {
					name: "",
					sort: 1,
					enabled: true
				}
			}
			this.editClassifyShow = true
		},
		closeDialog() { 
			this.editClassifyShow = false
		},
		clearValidate() { 
			this.$refs.editClassifyForm?.clearValidate()
		},
		checkGoodsForDialog(ids) {
			this.checkedGoods = ids
			this.$refs.goodsList.open()
			this.$nextTick(() => { 

				this.$refs.goodsList.$refs.mvkTable.refresh()
			})

		},
		handleDelete(data) {
			this.$notify.error("删除未接入", data)
			// api.couponDelete(data.id).then(() => {
			// this.$notify.success("删除成功")
			// 	this.$refs.mvkTable.refresh()
			// })
		},
		search: debounce(function() {
			this.$refs.mvkTable.refresh()
		}, 300),
		// 是否启用
		changeSwitch(row) {
			this.switchLoading = true
			api.laborClassifyEnabled({ id: row.id }).finally(() => { 
				this.switchLoading = false
				this.$refs.mvkTable.refresh()
			})
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true
					api.addLaborClassify(this.formData).then(res => { 
						this.$refs.mvkTable.refresh()
						this.$message.success("添加成功")
						this.editClassifyShow = false
					}).finally(() => { 
						this.loading = false
					})
				}
			})
			// this.formData
		}
	}
}
</script>

<style lang="scss" scoped>

.link {
	cursor: pointer;
	color: #4B5EFF;
}
</style>