import fetch from "@/axios/fetch"

export function getWeRunList(params) {
	return fetch({
		url: "/enroll",
		method: "get",
		params
	})
}

export function delWeRun(id) {
	return fetch({
		url: `/enroll/remove/${id}`,
		method: "post",
		data: {}
	})
}

export function addWeRunData(data) {
	return fetch({
		url: "/enroll/save",
		method: "post",
		data
	})
}

export function updateWeRunData(data) {
	return fetch({
		url: "/enroll/update",
		method: "put",
		data
	})
}

export function getWeRunDetail(params) {
	return fetch({
		url: `/enroll/${params.id}`,
		method: "get",
		params: {}
	})
}

export function getWeRunMemberList(params) {
	return fetch({
		url: "/enroll/user",
		method: "get",
		params
	})
}

export function uploadWeRunMember(data, params) {
	return fetch({
		url: "/enroll/user/upload",
		method: "put",
		params,
		data
	})
}

export function getWeRunMemberDownloadTemplate(params) {
	return fetch({
		url: "/enroll/user/download",
		method: "get",
		params,
		responseType: "arraybuffer"
	})
}

export function updateWeRunEnable(params) {
	return fetch({
		url: "/enroll/enable",
		method: "get",
		params
	})
}

export function addWeRunMember(data) {
	return fetch({
		url: "/enroll/user",
		method: "post",
		data
	})
}

export function deleteWeRunMember(data) {
	return fetch({
		url: `/enroll/user/remove?enrollId=${data.enrollId}&ids=${data.ids}`,
		method: "delete",
		data: {}
	})
}

export function disableWeRunMember(data) {
	return fetch({
		url: `/enroll/user/disable?enrollId=${data.enrollId}&ids=${data.ids}`,
		method: "delete",
		data: {}
	})
}


export function addWeRunConfig(data) {
	return fetch({
		url: "/enroll/config/save",
		method: "post",
		data
	})
}

export function updateWeRunConfig(data) {
	return fetch({
		url: "/enroll/config/update",
		method: "put",
		data
	})
}

export function getWeRunConfigDetail(params) {
	return fetch({
		url: `/enroll/config/${params.id}`,
		method: "get",
		params:{}
	})
}

export function addWeRunGold(data) {
	return fetch({
		url: "/enroll/gold/save",
		method: "post",
		data
	})
}

export function addWeRunGold2(data) {
	return fetch({
		url: "/enroll/rule/save",
		method: "post",
		data
	})
}

export function updateWeRunGold(data) {
	return fetch({
		url: "/enroll/gold/update",
		method: "put",
		data
	})
}

export function updateWeRunGold2(data) {
	return fetch({
		url: "/enroll/rule/update",
		method: "put",
		data
	})
}

export function getWeRunGoldDetail(params) {
	return fetch({
		url: `/enroll/gold/${params.id}`,
		method: "get",
		params:{}
	})
}


export function getWeRunGoldDetail2(params) {
	return fetch({
		url: `/enroll/rule/${params.id}`,
		method: "get",
		params:{}
	})
}

export function getWeRunReceiveRecord(params) {
	return fetch({
		url: `/enroll/user/record/receive`,
		method: "get",
		params
	})
}

export function getWeRunSportRecord(params) {
	return fetch({
		url: `/enroll/user/record/sport`,
		method: "get",
		params
	})
}
