<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="120px">
			<el-form-item label="时间选择：" class="width100">
				<el-radio-group v-model="dateValue" type="button" class="mr20" size="small" @change="DateChange">
					<el-radio-button v-for="(item, i) in dateList" :key="i" :label="item.value">
						{{ item.text }}
					</el-radio-button>
				</el-radio-group>
				<el-date-picker
					v-model="timeVal"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					placeholder="自定义时间"
					style="width: 250px"
					@change="onchangeTime"
				/>
			</el-form-item>
			<el-form-item label="用户微信昵称：">
				<el-input v-model="queryParam.nickName" class="inputWidth">
					<el-button slot="append" icon="el-icon-search" @click="handleSelect" />
				</el-input>
			</el-form-item>
		</el-form>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
		</mvk-table>
	</div>
</template>

<script>
import { DateList } from "@/utils/constants"
import { GetIntegralLogs } from "api/business/marketing/integral"

export default {
	name: "IntegralLog",

	components: {},

	directives: {},

	data() {
		return {
			dateList: DateList,
			dateValue: [],
			timeVal: [],

			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "title",
					label: "标题"
				},
				{
					prop: "integralMargin",
					label: "积分余量"
				},
				{
					prop: "detailedNum",
					label: "明细数字"
				},
				{
					prop: "remarks",
					label: "备注"
				},
				{
					prop: "nickName",
					label: "领取人微信昵称"
				},
				{
					prop: "creationDate",
					label: "领取时间"
				}
			],

			// 查询条件参数
			queryParam: {
				date: "",
				nickName: ""
			},

			tableDataFunc: (parameter) => {
				return GetIntegralLogs(Object.assign(parameter, this.queryParam)).then((res) => {
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {
		// 具体日期
		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.date = e ? this.timeVal.join(",") : ""
			this.dateValue = null
			this.$refs.mvkTable.refresh()
		},

		DateChange() {
			this.queryParam.date = this.dateValue.join(",")
			this.timeVal = []
			this.$refs.mvkTable.refresh()
		},

		handleSelect() {
			this.$refs.mvkTable.refresh()
		}
	}
}
</script>

<style lang="sass" scoped></style>
