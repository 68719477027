import Cookies from "js-cookie"

const state = {
	sidebar: {
		opened: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : true,
		withoutAnimation: false
	},
	device: "desktop",
	title: "",
	theme: Cookies.get("theme") ? Cookies.get("theme") : "theme-white",
	cancalRequest: []
}

const mutations = {
	TOGGLE_SIDEBAR: state => {
		state.sidebar.opened = !state.sidebar.opened
		state.sidebar.withoutAnimation = false
		if (state.sidebar.opened) {
			Cookies.set("sidebarStatus", 1)
		} else {
			Cookies.set("sidebarStatus", 0)
		}
	},
	CLOSE_SIDEBAR: (state, withoutAnimation) => {
		Cookies.set("sidebarStatus", 0)
		state.sidebar.opened = false
		state.sidebar.withoutAnimation = withoutAnimation
	},
	TOGGLE_DEVICE: (state, device) => {
		state.device = device
	},
	SET_TITLE: (state, title) => {
		state.title = title
	},
	TOGGLE_THEME: (state, theme) => {
		state.theme = theme
		Cookies.set("theme", theme)
	},
	SET_CANCALREQUEST: (state, request) => {
		state.cancalRequest.push(request)
	}
}

const actions = {
	toggleSideBar({
		commit
	}) {
		commit("TOGGLE_SIDEBAR")
	},
	closeSideBar({
		commit
	}, {
		withoutAnimation
	}) {
		commit("CLOSE_SIDEBAR", withoutAnimation)
	},
	toggleDevice({
		commit
	}, device) {
		commit("TOGGLE_DEVICE", device)
	},
	cancalRequestAction() {
		state.cancalRequest.map((cancel) => {
			if (typeof cancel === "function") {
				cancel("强制取消了请求")
			} else {
				console.log("没有可取消的请求")
			}
		})
		state.cancalRequest = []
	},
	clearCancelRequest({ state }) {
		state.cancalRequest = []
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}