<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="90px" :inline="true">
			<el-form-item label="订单时间：">
				<el-date-picker
					v-model="timeVal"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					style="width: 220px"
					@change="onchangeTime"
				/>
			</el-form-item>
			<el-form-item label="支付方式：">
				<el-select
					v-model="queryParam.payType"
					placeholder="支付方式"
					clearable
					filterable
					style="width: 110px"
				>
					<el-option
						v-for="(item, index) in Object.values(payTypeList)"
						:key="index"
						:label="item"
						:value="index"
					/>
				</el-select>
			</el-form-item>
			<el-form-item label="订单状态">
				<!-- {{ queryParam.orderStatus }} -->
				<el-select
					v-model="queryParam.status"
					placeholder="支付方式"
					clearable
					filterable
					style="width: 120px"
					@clear="statusClear"
				>
					<el-option
						v-for="(item, index) in Object.values(orderStatus)"
						:key="index"
						:label="item"
						:value="index + 1"
					/>
				</el-select>
			</el-form-item>
			<el-form-item label="手机号码">
				<el-input v-model="queryParam.userPhone" placeholder="" />
			</el-form-item>
		</el-form>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleView(row)"> 查看 </el-button>
				</template>
			</el-table-column>
		</mvk-table>

		<view-dialog ref="viewModalRef" />
	</div>
</template>

<script>
import { rechargeRecordList } from "api/business/financeManage/all.js"
import { rechargePayType } from "@/utils/constants"
const orderStatus = {
	1: "待支付",
	2: "已支付",
	3: "交易关闭"
}

export default {
	name: "RechargeList",
	components: {
		"view-dialog": () => import("./view.vue")
	},
	data() {
		return {
			payTypeList: rechargePayType,
			orderStatus: orderStatus,
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 60
				},
				{
					prop: "userName",
					label: "用户名称",
					width: 96
				},
				{
					prop: "userPhone",
					label: "用户手机号",
					width: 112
				},
				{
					prop: "payMoney",
					label: "充值金额（元）"
				},
				{
					prop: "payType",
					label: "支付方式",
					width: 80,
					formatter: cellvalue => {
						let value = cellvalue.payType
						return rechargePayType[value]
					}
				},
				{
					prop: "payTime",
					label: "充值时间"
				},
				{
					prop: "status",
					label: "充值状态",
					formatter: cellvalue => {
						let value = cellvalue.status

						return orderStatus[value]
					}
				}
			],
			queryParam: {
				startTime: "",
				endTime: "",
				payType: "",
				status: "",
				userPhone: ""
			},
			timeVal: []
		}
	},

	mounted() {},

	computed: {
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))

			return parameter => {
				// console.log(Object.assign(parameter, params))
				return rechargeRecordList(Object.assign(parameter, params)).then(res => {
					return res.data
				})
			}
		}
	},
	methods: {
		onchangeTime(e) {
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},
		handleView(row) {
			this.$refs.viewModalRef.show(row)
		},

		statusClear() {
			console.log(this.queryParam.status)
		}
	}
}
</script>

<style lang="scss" scoped></style>
