<template>
    <el-calendar v-bind="$attrs" v-on="$listeners">
        <template slot="dateCell" slot-scope="{ date, data }">
            <div :class="{ selected: isSelected(date, data) }">
                <div class="solar">{{ data.day.split("-")[2] }}</div>
                <div class="lunar" :class="{ festival: isFestival(data) }">
                    {{ solarToLunar(data) }}
                </div>
            </div>
        </template>
    </el-calendar>
</template>

<script>
import calendar from "./calendar.js"
import { formatDate } from "@/utils/validate.js"
export default {
    name: "MvkCalendar",
    props: {
        selectedDates: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {}
    },
    computed: {
        dateValue() {
            return this.$attrs.value
        }
    },
    watch: {
        dateValue(newVal, oldVal) {
            let temp = formatDate(newVal, "yyyy-MM-dd")

            let solarDayArr = temp.split("-")
            let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])

            // 农历日期
            let lunarMD = lunarDay.IMonthCn + lunarDay.IDayCn

            this.$emit("solarAndLunar", {
                solar: temp,
                lunar: lunarMD
            })
        }
    },

    mounted() {},

    methods: {
        // 是否选中日期
        isSelected: function(slotDate, slotData) {
            return this.selectedDates.includes(slotData.day)
        },

        // 是否节假日
        isFestival(slotData) {
            let solarDayArr = slotData.day.split("-")
            let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])
            console.log(lunarDay)
            // 公历节日\农历节日\农历节气
            let festAndTerm = []
            festAndTerm.push(lunarDay.festival == null ? "" : " " + lunarDay.festival)
            festAndTerm.push(lunarDay.lunarFestival == null ? "" : "" + lunarDay.lunarFestival)
            festAndTerm.push(lunarDay.Term == null ? "" : "" + lunarDay.Term)
            festAndTerm = festAndTerm.join("")

            return festAndTerm != ""
        },

        solarToLunar(slotData) {
            let solarDayArr = slotData.day.split("-")
            let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])

            // 农历日期
            let lunarMD = lunarDay.IMonthCn + lunarDay.IDayCn

            // 公历节日\农历节日\农历节气
            let festAndTerm = []
            festAndTerm.push(lunarDay.festival == null ? "" : " " + lunarDay.festival)
            festAndTerm.push(lunarDay.lunarFestival == null ? "" : "" + lunarDay.lunarFestival)
            festAndTerm.push(lunarDay.Term == null ? "" : "" + lunarDay.Term)
            festAndTerm = festAndTerm.join("")

            return festAndTerm == "" ? lunarMD : festAndTerm
        }
    }
}
</script>

<style lang="scss" scoped>
/**隐藏上一月、本月、下一月*/
.el-calendar__button-group {
	display: none;
}
/**月份居中*/
.el-calendar__title {
	width: 100%;
	text-align: center;
}
/**日期div的样式*/
.el-calendar-table tr td:first-child {
	border-left: 0px;
}
.el-calendar-table td {
	min-height: 110px;
	min-width: 110px;
	border-right: 0px;
}
.el-calendar-table td.is-selected {
	background-color: white;
}
.el-calendar-table .el-calendar-day {
	height: 100%;
	padding: 0px;
	text-align: center;
}
// .el-calendar-table .el-calendar-day > div {
// 	height: 104px;
// }
/**日期div的样式-农历*/
// .el-calendar-table .el-calendar-day > div .lunar {
// 	padding-top: 10px;
// }
/**日期div的样式-选中*/
.el-calendar-table .el-calendar-day > div.selected {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 8px;
	background-color: #fef2f2;
	border: 3px solid #fb0;
	border-radius: 20px;
}

::v-deep .el-calendar-table .el-calendar-day {
	position: relative;
}

/**本月周末设置为红色*/
.el-calendar-table .current:nth-last-child(-n + 2) .solar {
	color: red;
}
/**本月农历设置为灰色*/
.el-calendar-table .current .lunar {
	color: #606266;
}
/**本月农历节日设置为红色*/
.el-calendar-table .current .lunar.festival {
	color: red;
}
/**禁用点击效果*/
/*.el-calendar-table td {*/
/*pointer-events: none;*/
/*}*/
</style>
