<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="86px" :inline="true">
			<el-form-item label="店铺名称：">
				<el-input v-model="queryParam.shopName" placeholder="请输入店铺名称查询" clearable />
			</el-form-item>
			<el-form-item label="创建时间：">
				<el-date-picker v-model="timeVal" 
					value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small" type="daterange"
					placement="bottom-end" start-placeholder="开始时间" end-placeholder="结束时间" style="width: 220px"
					@change="onchangeTime"
				/>
			</el-form-item>
			<el-button type="primary" class="ml20" @click="exportFile()">导出报表</el-button>
		</el-form>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" height="640">
			<template slot="laborMoney" slot-scope="{ row }">
				<router-link v-if="row.laborMoney"
					:to="{ path: '/marketingManager/labour/labourEdit/detail', query: { id: row.shopId,activeName:'disLabour',time: queryParam.time } }" class="link"
				>
					{{ row.laborMoney }}元
				</router-link>
				<span v-else> - </span>
			</template>
			<template slot="payMoney" slot-scope="{ row }">
				<router-link v-if="row.payMoney"
					:to="{ path: '/marketingManager/labour/labourEdit/detail', query: { id: row.shopId,activeName:'uselist',time: queryParam.time } }" class="link"
				>
					{{ row.payMoney }}元
				</router-link>
				<span v-else> - </span>
			</template>
			<template slot="orderMoney" slot-scope="{ row }">
				<router-link v-if="row.orderMoney"
					:to="{ path: '/selfManager/order', query: { time: queryParam.time} }" class="link"
				>
					{{ row.orderMoney }}元
				</router-link>
				<span v-else> - </span>
			</template>
			<template slot="refundMoney" slot-scope="{ row }">
				<router-link v-if="row.refundMoney"
					:to="{ path: '/selfManager/order', query: {status: 9,time: queryParam.time } }" class="link"
				>
					{{ row.refundMoney }}元
				</router-link>
				<span v-else> - </span>
			</template>
		</mvk-table>
	</div>
</template>
<!-- /marketingManager/labour/labourEdit/edit?id=2 -->
<script>
import { laborShopStatistics, getStatisticsExport } from "api/business/selfManager/labour.js"
import { debounce } from "utils/utils"
import { blobTofile } from "@/utils/utils"

export default {
	name: "LabourAnalysis",
	data() {
		return {
			queryParam: {
				shopName: "",
				time: ""
			},
			tableColumn: [
				{ prop: "shopCode", label: "店铺编号", minWidth: "120" },
				{ prop: "shopName", label: "店铺名称" },
				{ prop: "laborMoney", label: "累计发放通用金", align: "center", slot: true },
				{ prop: "payMoney", label: "累计支付总额", align: "center", slot: true },
				{ prop: "beUsedMoney", label: "当前待使用总额", align: "center", formatter: value => {
					return value.beUsedMoney ? value.beUsedMoney + "元" : " - "
				} },
				{ prop: "orderMoney", label: "累计订单总额", align: "center", slot: true },
				{ prop: "refundMoney", label: "累计退款总额", align: "center", slot: true },
				{ prop: "memberNum", label: "累计购买人数", align: "center", formatter: value => {
					return value.memberNum ? value.memberNum + "个" : " - "
				} }
			],
			tableDataFunc: parameter => {
				return laborShopStatistics(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},
			timeVal: []
		}
	},
	watch: {
		queryParam: {
			handler: debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},

	mounted() { },

	methods: {
		onchangeTime(e) {
			this.queryParam.time = e ? e[0] + "~" + e[1] : null
		},
		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},
		exportFile() {
			getStatisticsExport(this.queryParam).then(res => {
				blobTofile(res, "通用店铺统计.xls", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped>

.link {
	cursor: pointer;
	color: #4B5EFF;
}
</style>
