<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" size="75%">
		<el-form
			v-if="addEditDialog"
			ref="dataForm"
			v-loading="fullscreenLoading"
			:model="dataForm"
			:rules="readOnly ? {} : rules"
			label-width="180px"
			:inline="false"
			class="custom-form"
		>
			<el-form-item label="选择商品：" prop="productId">
				<div class="flex-row">
					<div class="upLoadPicBox" @click="changeGood">
						<div class="upLoad">
							<img v-if="dataForm.productId != ''" :src="dataForm.pic">
							<i v-else class="el-icon-camera cameraIconfont" />
						</div>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="商品名称:" prop="name">
				<el-input
					v-model="dataForm.name"
					placeholder="商品名称"
					class="inputWidth"
					maxlength="14"
					:readonly="readOnly"
					show-word-limit
				/>
			</el-form-item>
			<!-- <el-form-item label="商品小程序路径:">
				<el-input v-model="dataForm.url" placeholder="" class="inputWidth"></el-input>
			</el-form-item> -->
			<el-form-item label="直播商品封面图：" prop="coverImg">
				<upload-img-wx
					ref="coverImg"
					v-model="dataForm.coverImg"
					:img-url.sync="dataForm.coverImgUrl"
					:width="300"
					:height="300"
					:disabled="readOnly"
					upload-tip-text="图片尺寸最大300像素*300像素"
				/>
			</el-form-item>
			<el-form-item label="商品SKU：" :required="readOnly ? false : true" prop="skuIds">
				<el-table
					ref="multipleTable"
					:data="dataForm.liveSkuVO"
					tooltip-effect="dark"
					border
					style="width: 100%"
					@selection-change="handleSelectionChange"
				>
					<el-table-column v-if="!readOnly" key="1" type="selection" width="55" />
					<template v-if="!!manyTabDate">
						<el-table-column
							v-for="(item, iii) in manyTabDate"
							:key="iii"
							:prop="iii"
							align="center"
							:label="manyTabTit[iii].title"
							min-width="80"
						/>
					</template>
					<el-table-column key="pic" align="center" label="图片" min-width="80" prop="pic">
						<template slot-scope="scope">
							<div class="upLoadPicBox">
								<div v-if="scope.row.pic" class="pictrue">
									<img :src="scope.row.pic">
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column
						v-for="item in tableColumn"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						align="center"
						min-width="140"
					>
						<template slot-scope="scope">
							<el-form-item
								v-if="item.label === '直播价' && !readOnly"
								:key="'liveSkuVO.' + scope.$index + '.' + item.prop"
								:rules="[
									{ required: readOnly ? false : true, message: '请输入直播价', trigger: 'change' }
								]"
								:prop="'liveSkuVO.' + scope.$index + '.' + item.prop"
							>
								<el-input-number
									v-model="scope.row[item.prop]"
									size="small"
									:min="0.01"
									:precision="2"
									:step="0.1"
								/>
							</el-form-item>

							<el-form-item
								v-else-if="item.label === '直播库存' && !readOnly"
								:key="'liveSkuVO.' + scope.$index + '.' + item.prop"
								:rules="[
									{ required: readOnly ? false : true, message: '请输入直播库存', trigger: 'change' },
									{ validator: stockValid, trigger: 'change' }
								]"
								:prop="'liveSkuVO.' + scope.$index + '.' + item.prop"
							>
								<el-input-number
									v-model="scope.row[item.prop]"
									size="small"
									type="number"
									:min="1"
									:max="scope.row.actualStocks"
									:step="1"
									step-strictly
								/>
							</el-form-item>

							<span v-else>
								{{ scope.row[item.prop] }}
							</span>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>
			<!-- <el-form-item label="价格类型:" prop="priceType">
				<el-radio-group v-model="dataForm.priceType" @change="priceTypeChange">
					<el-radio :label="1">一口价</el-radio>
					<el-radio :label="2">价格区间</el-radio>
					<el-radio :label="3">显示折扣价</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="一口价" v-if="dataForm.priceType == 1" prop="price" :rules="dataForm.priceType == 1 ? [{required:true,message:'请输入一口价',trigger:'blur'}] : ''">
				<el-input v-model="dataForm.price" placeholder="" style="width:200px"></el-input>
			</el-form-item>
			<el-row v-else-if="dataForm.priceType == 2">
				<el-col :span="12" :offset="0">
					<el-form-item label="最低价格" :rules="dataForm.priceType == 2 ? [{required:true,message:'请输入最低价格',trigger:'blur'}] : ''">
						<el-input v-model="dataForm.price" placeholder="" style="width:200px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" :offset="0">
					<el-form-item label="最高价格" :rules="dataForm.priceType == 2 ? [{required:true,message:'请输入最高价格',trigger:'blur'}] : ''">
						<el-input v-model="dataForm.price2" placeholder="" style="width:200px"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-else>
				<el-col :span="12" :offset="0">
					<el-form-item label="市场价">
						<el-input v-model="dataForm.price" placeholder="" style="width:200px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" :offset="0">
					<el-form-item label="现价" :rules="dataForm.priceType == 3 ? [{required:true,message:'请输入现价',trigger:'blur'}] : ''">
						<el-input v-model="dataForm.price2" placeholder="" style="width:200px"></el-input>
					</el-form-item>
				</el-col>
			</el-row> -->
			<el-form-item>
				<el-button v-if="!readOnly" type="primary" @click="handleSubmit">保存</el-button>
				<el-button @click="addEditDialog = false">取消</el-button>
			</el-form-item>
		</el-form>

		<goods-list-model
			v-if="addEditDialog"
			ref="goodsList"
			:multi="false"
			type="ware"
			@getGoodsItem="getGoodsItem"
		/>
	</el-drawer>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import UploadImgWx from "views/bussiness/components/UploadImgWx.vue"
import { prodDetail } from "api/business/selfManager/goods.js"
import { isJson } from "@/utils/utils"
import { addLiveProduct, editLiveProduct, liveProductDetail } from "api/business/broadcast/live"

const dataParams = {
	id: "",
	brief: "",
	content: "",
	coverImg: "",
	coverImgUrl: "",
	imgs: "",
	imgsArr: [],
	name: "",
	pic: "",
	price: "",
	price2: "",
	priceType: 1, // 价格类型：1、一口价
	productId: "",
	propJson: "",
	propJsonArr: [],
	tempId: "",
	unit: "",
	liveSkuVO: [],

	skuIds: []
}

const addTableColumn = [
	{
		prop: "sellingPrice",
		label: "售价"
	},
	{
		prop: "livePrice",
		label: "直播价"
	},
	{
		prop: "actualStocks",
		label: "实际库存"
	},
	{
		prop: "totalStocks",
		label: "直播库存"
	},
	{
		prop: "skuName",
		label: "商品编号"
	},
	{
		prop: "weight",
		label: "重量(KG)"
	},
	{
		prop: "volume",
		label: "体积(m³)"
	}
]

const editTableColumn = [
	{
		prop: "sellingPrice",
		label: "售价"
	},
	{
		prop: "livePrice",
		label: "直播价"
	},
	{
		prop: "actualStocks",
		label: "实际库存"
	},
	{
		prop: "totalStocks",
		label: "直播库存"
	},
	{
		prop: "currentStocks",
		label: "直播剩余库存"
	},
	{
		prop: "skuName",
		label: "商品编号"
	},
	{
		prop: "weight",
		label: "重量(KG)"
	},
	{
		prop: "volume",
		label: "体积(m³)"
	}
]

export default {
	name: "GoodsAddEdit",

	components: {
		GoodsListModel,
		UploadImgWx
	},

	directives: {},

	data() {
		return {
			title: "新增",
			addEditDialog: false,
			readOnly: false,
			dataForm: Object.assign({}, dataParams),
			rules: {
				productId: [{ required: true, message: "请选择商品", trigger: "change" }],
				name: [{ required: true, message: "请输入商品标题", trigger: "blur" }],
				coverImg: [{ required: true, message: "请上传直播商品封面图", trigger: "change" }],
				skuIds: [{ required: true, message: "请选择商品SKU", trigger: "change" }],
				priceType: [{ required: true, message: "请选择价格类型", trigger: "change" }]
			},

			tableColumn: [],

			stockValid: (rule, value, callback) => {
				if (value == 0) {
					callback(new Error("直播库存不能为0"))
				} else {
					callback()
				}
			},

			fullscreenLoading: false,

			manyTabDate: {},
			manyTabTit: {},
			multipleSelection: []
		}
	},

	mounted() {},

	methods: {
		add() {
			this.tableColumn = addTableColumn
			this.addEditDialog = true
			this.dataForm = JSON.parse(JSON.stringify(dataParams))
		},

		view(recode) {
			this.edit(recode)
		},

		edit(recode) {
			var vm = this
			this.tableColumn = editTableColumn
			this.addEditDialog = true
			this.fullscreenLoading = true
			vm.manyTabTit = {}
			vm.manyTabDate = {}
			liveProductDetail(recode.id)
				.then(res => {
					let data = res.data
					this.dataForm = data
					this.dataForm.imgsArr = data.imgs.split(",")
					// this.dataForm.liveSkuVO = JSON.parse(res.data.skuAttr)
					let skuids = this.dataForm.liveSkuVO.filter(item => item.isChoice).map(item => item.id)
					this.$set(this.dataForm, "skuIds", skuids)

					this.dataForm.liveSkuVO.forEach(item => {
						if (item.isChoice) {
							// console.log(item)
							vm.$nextTick(() => {
								vm.$refs.multipleTable.toggleRowSelection(item, true)
							})
						}
						if (item.properties != "") {
							item.properties = JSON.parse(item.properties)

							Object.values(item.properties).forEach((v, i) => {
								item["value" + i] = v
							})
						}
					})

					if (
						this.dataForm.propJson != "" &&
						this.dataForm.propJson != "[]" &&
						this.dataForm.propJson != null
					) {
						let propJsonArr = JSON.parse(this.dataForm.propJson)
						let tmp = []
						propJsonArr.forEach((item, index) => {
							tmp.push(JSON.parse(item))

							vm.manyTabTit["value" + index] = { title: JSON.parse(item).attrName }
							vm.manyTabDate["value" + index] = JSON.parse(item).skuValue
						})

						this.dataForm.propJsonArr = tmp
					} else {
						this.dataForm.propJsonArr = []
					}
				})
				.finally(() => {
					this.fullscreenLoading = false
				})
		},

		changeGood() {
			if (!this.dataForm.id) this.$refs.goodsList.open()
		},

		getGoodsItem(goodsInfo) {
			// console.log(goodsInfo)
			var vm = this
			this.dataForm.productId = goodsInfo.id
			this.dataForm.pic = goodsInfo.pic
			this.dataForm.name = goodsInfo.prodName.substr(0, 14)

			prodDetail(goodsInfo.id)
				.then(res => {
					this.dataForm.brief = res.data.brief
					this.dataForm.content = res.data.content
					this.dataForm.imgs = res.data.imgs
					this.dataForm.imgsArr = JSON.parse(res.data.imgs)
					this.dataForm.tempId = res.data.deliveryTemplateId
					this.dataForm.unit = res.data.unit

					this.dataForm.liveSkuVO = JSON.parse(JSON.stringify(res.data.skus))
					this.dataForm.title = res.data.prodName
					this.dataForm.propJson = res.data.propJson

					if (res.data.propJson != "") {
						let propJsonArr = JSON.parse(res.data.propJson)
						// console.log(propJsonArr)
						let tmp = []
						propJsonArr.forEach((item, index) => {
							tmp.push(JSON.parse(item))

							vm.manyTabTit["value" + index] = { title: JSON.parse(item).attrName }
							vm.manyTabDate["value" + index] = JSON.parse(item).skuValue
						})
						// console.log(tmp)
						this.dataForm.propJsonArr = tmp
					}

					if (this.dataForm.liveSkuVO.length > 0) {
						this.dataForm.liveSkuVO.forEach(item => {
							if (item.properties != "") {
								let properties = JSON.parse(item.properties)
								Object.values(properties).forEach((v, i) => {
									if (!item["value" + i]) item["value" + i] = ""
									item["value" + i] = v
								})

								item.properties = properties
							}

							vm.$set(item, "livePrice", item.sellingPrice)
							vm.$set(item, "totalStocks", 1)
							// vm.$set(item,'limitedSurplus',item.actualStocks)
							// vm.$set(item,'prodId',goodsInfo.id)
							vm.$set(item, "isChoice", false)
						})
					}

					this.fullscreenLoading = false
				})
				.catch(() => {
					this.fullscreenLoading = false
				})
		},

		priceTypeChange(val) {
			if (val == 3) {
				// this.dataForm.price = this.dataForm
			}
		},

		handleSelectionChange(val) {
			this.dataForm.skuIds = val.map(item => item.id)
		},

		handleSubmit() {
			var vm = this
			this.dataForm.imgs = this.dataForm.imgsArr.join(",")

			if (this.dataForm.liveSkuVO.length > 0) {
				this.dataForm.liveSkuVO.forEach(item => {
					if (vm.dataForm.skuIds.indexOf(item.id) > -1) {
						item.isChoice = true
					} else {
						item.isChoice = false
					}

					item.currentStocks = item.totalStocks

					if (isJson(item.properties)) {
						item.properties = JSON.stringify(item.properties)
					}
				})
			}

			// 多规格商品，只选了其中某些规格
			if (this.dataForm.skuIds.length < this.dataForm.liveSkuVO.length) {
				const isChoice = this.dataForm.liveSkuVO.filter(item => item.isChoice)
				let temp = []
				isChoice.forEach(item => {
					let properties = isJson(item.properties) ? item.properties : JSON.parse(item.properties)
					Object.values(properties).forEach((v, i) => {
						if (temp[i]) {
							temp[i].add(v)
						} else {
							temp[i] = new Set()
							temp[i].add(v)
						}
					})
				})
				if (temp.length > 0) {
					temp.forEach((item, index) => {
						vm.dataForm.propJsonArr[index].skuValue = Array.from(item)
					})
				}
			}

			let temp = []
			this.dataForm.propJsonArr.forEach(item => {
				temp.push(JSON.stringify(item))
			})
			this.dataForm.propJson = JSON.stringify(temp)

			console.log(this.dataForm)

			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					this.dataForm.id
						? editLiveProduct(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改成功"
								})

								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.fullscreenLoading = false
							})
						: addLiveProduct(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "新增成功"
								})

								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.fullscreenLoading = false
							})
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.custom-form {
	height: calc(100% - 50px);
	overflow-y: auto;
	margin-right: 16px;
}
</style>
