<template>
	<div>
		<div class="flex flex-sb">
			<div v-if="!readOnly" class="flex flex-column-center">
				<el-button type="primary" @click="addCoupon()">新增优惠券</el-button>
				<!-- <el-button type="primary" @click="batchDelete()">批量移除</el-button> -->
			</div>
			<router-link :to="{ path: '/keyAccountManager/franchiseStore' }">
				<el-button>返回列表</el-button>
			</router-link>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15"
			row-key="cid" @selection-change="handleSelectionChange"
		>
			<template slot="receiveTime" slot-scope="{ row }" label="领取时间">
				<span v-if="!row.isTime">不限时</span>
				<div v-else-if="row.isTime">
					{{ row.receiveTime.split(",")[0] }} - {{ row.receiveTime.split(",")[1] }}
				</div>
				<span v-else />
			</template>
			<template slot="termOfValidity" slot-scope="{ row }" label="使用时间">
				<span v-if="row.validityType == 0">{{ row.termOfValidity }} 天</span>
				<div v-else-if="row.validityType == 1">
					{{ row.termOfValidity.split(",")[0] }} 至 {{ row.termOfValidity.split(",")[1] }}
				</div>
				<span v-else>无限期</span>
			</template>
			<template slot="distributeNum" slot-scope="{ row }" c>
				<div class="btn" @click="checkDistrbute(row)">{{ row.distributeNum }} 张</div>
				<!-- <span v-if="!row.isLimit">不限量</span>
				<div v-else>
					<span class="fa">发布：{{ row.pushNum }}</span>
					<span class="sheng">剩余：{{ row.surplusNum == null ? row.pushNum : row.surplusNum }}</span>
				</div> -->
			</template>
			<template slot="score" slot-scope="{ row }" label="状态">
				<el-switch v-model="row.status" :disabled="readOnly" @change="handleStatusChange(row.id, row.status)" />
			</template>
			<el-table-column slot="head" type="selection" width="55" />
			<el-table-column label="操作" width="170">
				<template slot-scope="{ row,$index }">
					<el-button v-if="!readOnly" type="text" @click="handleDeclareEdit(row.id,row.name,row.declares)">
						说明
					</el-button>
					<el-button type="text" @click="handleDistribute(row)"> 派发 </el-button>
					<!-- <el-button v-if="!readOnly" type="text" @click="handleEdit(row)"> 编辑 </el-button> -->
					<el-button type="text" @click="handleCheck(row)"> 查看 </el-button>
					<!-- <el-button v-if="!row.distributeNum" class="delete_btn" type="text" :disabled="loadingDel" @click="handleDelete(row)"> 删除 </el-button> -->
					<delete-button v-if="!row.distributeNum && !readOnly" :index="$index" button-type="text"
						size="small" @delete="handleDelete(row)"
					/>
				</template>
			</el-table-column>
		</mvk-table>
		<!-- 优惠券说明 -->
		<el-dialog title="优惠券使用说明" width="600px" :visible.sync="dialogShow">
			<el-form :model="declareParams">
				<el-form-item label="优惠券名称：" label-width="200">
					{{ declareParams.name }}
				</el-form-item>
				<el-form-item label="" label-width="200">
					<el-input v-model="declareParams.declares" type="textarea" placeholder="请输入使用说明" maxlength="500"
						rows="6" resize="none" show-word-limit autocomplete="off"
					/>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogShow = false">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="declareSubmit()">确 定</el-button>
			</div>
		</el-dialog>
		<!--  -->
		<addCouponDrawer ref="addCouponDrawer" @saveSuccess="getList()" />
		<!-- 派发模态框 -->
		<DialogDistribute :visible.sync="distributeVisible" :data.sync="distributeData" :loading="loadingDec"
			@open="WhiteListVisible = true" @submit="distributeSubmit"
		/>
		<!-- 白名单模态框 -->
		<DialogWhiteList :visible.sync="WhiteListVisible" :c-id="cID" :select="WhiteListSelectData"
			:check.sync="whiteListCheck" :select-id="WhiteListSelectDataId" @submit="addDistribute"
			@refresh="getList()"
		/>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { couponsList, distributeCoupon, removeDistributeCoupon } from "api/business/selfManager/keyAccount"
import { couponDeclare } from "api/business/marketing/coupon"
import addCouponDrawer from "./addEdit.vue"
import DialogDistribute from "./coupon-distribute-dialog.vue"
import DialogWhiteList from "./coupon-whitelist-dialog.vue"
import { changeStatus } from "api/business/marketing/coupon"

export default {
	components: { addCouponDrawer, DialogDistribute, DialogWhiteList },
	props: {
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 76
				},
				{
					prop: "name",
					label: "名称"
				},
				{
					prop: "faceValue",
					label: "面值",
					formatter: row => { return row.faceValue + "元" },
					width: 80
				},
				{
					label: "门槛",
					formatter: row => {
						if (row.isThreshold) {
							return `最低消费${row.minConsume}元`
						} else {
							return "无门槛"
						}
					},
					width: 120
				},
				// {
				// 	prop: "obtainMethod",
				// 	label: "领取方式",
				// 	formatter: cellValue => {
				// 		if (cellValue.obtainMethod == 10) {
				// 			return "手动领取"
				// 		} else if (cellValue.obtainMethod == 20) {
				// 			return "新人券"
				// 		} else if (cellValue.obtainMethod == 30) {
				// 			return "赠送券"
				// 		} else if (cellValue.obtainMethod == 40) {
				// 			return "活动券"
				// 		} else {
				// 			return cellValue.obtainMethod
				// 		}
				// 	}
				// },
				// {
				// 	prop: "receiveTime",
				// 	label: "领取日期",
				// 	slot: true
				// },
				{
					prop: "termOfValidity",
					label: "使用时段",
					slot: true,
					minWidth: 96
				},
				{
					prop: "declares",
					label: "说明",
					width: 120
				},
				{
					prop: "distributeNum",
					label: "派发数量",
					width: "120",
					align: "center",
					slot: true
				},
				// {
				// 	prop: "num",
				// 	label: "派发数量(张)",
				// 	width: 90,
				// 	align: "center"
				// },
				{
					prop: "score",
					label: "是否开启",
					slot: true,
					width: 86
				}
			],

			tableDataFunc: async parameter => {
				if (this.$route.query.id) {
					return couponsList(Object.assign(parameter, { shopId: this.$route.query.id })).then(res => {
						return res.data
					})
				} else {
					return {}
				}
			},
			selectList: [],

			cID: "",
			loading: false,
			// 派发
			distributeVisible: false,
			distributeData: [],
			// 白名单
			WhiteListVisible: false,
			whiteListCheck: false, // true = 不可勾选、可取消已经派发未使用的券
			WhiteListSelectData: [],
			WhiteListSelectDataId: [],

			visibleBtn: false,
			loadingDel: false,

			// 优惠券说明
			dialogShow: false,
			loadingDec: false,
			declareParams: {
				id: null,
				name: "",
				declares: ""
			}
		}
	},
	methods: {
		getList() {
			this.$refs.mvkTable.refresh()
		},
		// 改变勾选指集
		handleSelectionChange(val) {
			this.selectList = val
		},
		addCoupon() {
			this.$refs.addCouponDrawer.open({
				keyAccountData: {
					shopId: Number(this.$route.query.id)
				}
			})
		},
		handleStatusChange(id, status) {
			changeStatus({
				id: id,
				status: status
			})
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "修改成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "修改失败"
					})
				})
		},
		// 打开派发窗口
		handleDistribute(row) {
			this.cID = row.id
			this.distributeVisible = true
		},
		handleEdit(row) {
			this.$refs.addCouponDrawer.open(
				Object.assign(row, {
					keyAccountData: {
						shopId: Number(this.$route.query.id)
					}
				})
			)
		},
		handleDelete(row) {
			this.loadingDel = true
			const params = {
				ids: [row.id]
			}
			removeDistributeCoupon(params, this.$route.query.id).then(res => {
				if (res.status === "OK")
					this.$notify.success({
						title: "成功",
						message: "成功删除优惠券"
					})
				this.visibleBtn = false
				this.getList()
			}).finally(() => {
				this.loadingDel = false
			})
		},
		// 打开优惠券说明
		handleDeclareEdit(id, name, declares) {
			this.dialogShow = true
			this.declareParams.id = id
			this.declareParams.name = name
			this.declareParams.declares = declares || ""
		},
		// 优惠券说明提交
		declareSubmit() {
			this.loadingDec = true
			couponDeclare(this.declareParams).then(() => {
				this.$notify.success({
					title: "成功",
					message: "保存成功"
				})
				this.dialogShow = false
				this.$refs.mvkTable.refresh()

			}).finally(() => { this.loadingDec = false })
		},
		handleCheck(row) {
			this.$refs.addCouponDrawer.open(
				Object.assign(row, {
					keyAccountData: {
						shopId: Number(this.$route.query.id)
					}
				}),
				true
			)
		},
		// 查看已派发
		checkDistrbute(row) {
			console.log(row)
			this.cID = row.id
			this.whiteListCheck = true
			this.WhiteListVisible = true
		},

		// 白名单添加到派发表格中(去重后赋值)
		addDistribute(data) {
			let json = this.distributeData.concat(data.map(e => { return { memberName: e.memberName, memberMobile: e.memberMobile, id: e.id } })) // 两个数组对象合并
			let newJson = [] // 盛放去重后数据的新数组
			for (let item1 of json) {  // 循环json数组对象的内容
				let flag = true  // 建立标记，判断数据是否重复，true为不重复
				for (let item2 of newJson) {  // 循环新数组的内容
					if (item1.memberMobile == item2.memberMobile) { // 让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
						flag = false
					}
				}
				if (flag) { // 判断是否重复
					newJson.push(item1) // 不重复的放入新数组。  新数组的内容会继续进行上边的循环。
				}
			}
			this.distributeData = newJson
		},

		// 进行派发请求
		distributeSubmit(members) {
			this.loadingDec = true
			const params = {
				couponId: this.cID,
				shopId: Number(this.$route.query.id),
				members
			}
			return new Promise((resolve, reject) => {
				distributeCoupon(params).then(res => {
					if (res.status === "OK") {
						this.distributeVisible = false
						this.distributeData = []
						this.$notify.success({
							title: "成功",
							message: "派发成功"
						})
						this.getList()
					}
					resolve()
				}).finally(() => {
					this.loadingDec = false
					reject()
				})
			})

		}

	}
}
</script>

<style lang="scss" scoped>
.dialog-footer button {
	width: 120px;
}

.discolor {
	color: #ee8830;
}

.discolor.done {
	color: green;
}

.btn {
	color: #4B5EFF;
	cursor: pointer;
}

.delete_btn {
	color: #F56C6C;
}
</style>
