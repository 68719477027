<template>
	<el-dialog
		title="导出核销汇总表"
		:visible="value"
		:close-on-click-modal="false"
		center
		width="660px"
		append-to-body
		@update:visible="$emit('change', $event)"
	>
		<el-form ref="form" :model="form" label-width="140px" :inline="false">
			<el-form-item label="统计方式：" prop="open">
				<el-radio-group v-model="form.type">
					<el-radio :label="1">按供应商统计</el-radio>
					<el-radio :label="2">按门店统计</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="form.type === 1" label="供应商：">
				<el-select
					v-model="form.supplierId"
					multiple
					placeholder="所属供应商"
					clearable
					filterable
					style="width: 260px"
				>
					<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
				<el-button type="primary" class="ml10" @click="selection()">全选</el-button>
				<el-button type="default" @click="selection(false)">取消全选</el-button>
			</el-form-item>
			<el-form-item v-else label="门店：">
				<organizationTreeSelect v-model="form.organId" style="width: 260px" />
			</el-form-item>
			<el-form-item label="统计时间段：">
				<el-date-picker
					v-model="timeVal"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					style="width: 260px"
					@change="onchangeTime"
				/>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="mr20" type="warning" @click="reset()">重 置</el-button>
			<el-button @click="close()">取 消</el-button>
			<el-button type="primary" :disabled="(form.supplierId.length === 0 && form.type == 1) || form.type == 2 && !form.organId" @click="submitExport()">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"
import { getPickStatisticsExport } from "api/business/financeManage/pick.js"
import { blobTofile } from "@/utils/utils"
// 默认请求前置参数
const defaultForm = {
	type: 1,
	organId: "", // 组织架构ID
	supplierId: [], // 供应商数组
	startDate: "",
	endDate: ""
}
export default {
	components: { organizationTreeSelect },
	name: "",
	props: {
		value: {
			type: Boolean,
			default: false
		},
		supplierlist: Array
	},
	model: {
		prop: "value",
		event: "change"
	},
	data() {
		return {
			form: JSON.parse(JSON.stringify(defaultForm)),
			timeVal: []
		}
	},
	watch: {
		value(newVal) {
			if (!newVal) { 
				this.form.supplierId = []
			}
		}
	},
	methods: {
		// 重置
		reset() {
			this.form = JSON.parse(JSON.stringify(defaultForm))
			this.timeVal = []
		},
		// 关闭.sync方式绑定
		close() {
			this.$emit("change", false)
		},
		// 改变日期
		onchangeTime(e) {
			this.timeVal = e
			this.$set(this.form, "startDate", e ? e[0] : "")
			this.$set(this.form, "endDate", e ? e[1] : "")
		},
		// 选择/反选
		selection(select = true) {
			this.form.supplierId = select ? this.supplierlist.map(e => e.id) : []
		},
		// 确定导出
		submitExport() {
			const { type, organId, supplierId, startDate, endDate } = this.form
			const params = {
				type,
				ids: this.form.type === 1 ? supplierId.join() : organId,
				startDate,
				endDate
			}
			getPickStatisticsExport(params).then(res => {
				blobTofile(res, "核销统计汇总表.xls", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
