import fetch from "@/axios/fetch"

export function getNewComersConfig () { // 查询新人专享配置
	return fetch({
		url: "/newcomers/setting",
		method: "get",
	})
}

export function setNewComersConfig (data) { // 配置新人专享
	return fetch({
		url: "/newcomers/save",
		method: "post",
		data
	})
}


