<template>
	<!-- 仓库中商品 -->
	<div>
		<div class="flex-row" style="justify-content: space-between">
			<!-- <router-link :to=" { path:'/selfManager/creatProduct' } " v-has="'pms:product:add'">
				<el-button size="small" round type="primary" class="mr10">添加商品</el-button>
			</router-link> -->
			<el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="所属供应商">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
						<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="商品分类">
					<prod-category-tree v-model="queryParam.categoryId" check-strictly />
				</el-form-item>
				<el-form-item label="商品名称">
					<el-input v-model="queryParam.prodName" placeholder="输入商品名称搜索" clearable />
				</el-form-item>
				<el-form-item label="配送方式">
					<el-select v-model="queryParam.deliveryMode" placeholder="配送方式" clearable filterable
						style="width: 110px">
						<el-option v-for="(val, key, index) in deliveryMode" :key="index" :label="val" :value="key" />
					</el-select>
				</el-form-item>
				<el-form-item label="税率商品">
					<el-select v-model="queryParam.isTaxRate" placeholder="请选择">
						<el-option label="全部" :value="null" />
						<el-option label="已录入" :value="true" />
						<el-option label="未录入" :value="false" />
					</el-select>
				</el-form-item>
				<el-form-item label="商品标签">
					<el-select v-model="queryParam.labelName" placeholder="请选择" clearable>
						<el-option v-for="item in labelOptions" :key="item.id" :label="item.name" :value="item.name" />
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<el-button v-has="'pms:product:batch:move'" type="primary" :disabled="tableCheck.length == 0" round
			@click="handleBatchMove">
			批量移至站级商品池
		</el-button>
		<el-button v-has="'pms:product:shelf'" type="success" plain :disabled="tableCheck.length == 0" round
			@click="handleBatchGoodsOn('自营店')">
			批量上架自营店
		</el-button>
		<el-button v-has="'pms:product:shelf'" type="warning" plain :disabled="tableCheck.length == 0" round
			@click="handleBatchGoodsOn('内购店')">
			批量上架内购店
		</el-button>
		<el-button plain type="primary" round @click="exportGoodsList()">
			导出商品列表
		</el-button>

		<!-- <el-checkbox v-model="queryParam.isTaxRate" class="ml20">已录税率商品</el-checkbox> -->
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc"
			header-cell-class-name="custom-table1" class="mt15" @selection-change="handleSelectionChange">
			<el-table-column slot="head" type="selection" width="38" />
			<el-table-column slot="head" type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.skus" border stripe>
						<el-table-column v-for="col in fixedColumn" :key="col.prop" :prop="col.prop" :label="col.label" :width="col.width" :min-width="col.minWidth">
							<template slot-scope="{ row }">
								<div v-if="col.prop == 'skuName'" class="flex-row">
									<el-image style="min-width: 30px; height: 30px; width: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
										:preview-src-list="[row.pic]" />
									<div style="margin-left: 2px">{{ row.skuName }}</div>
								</div>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>

			<template slot="prodName" slot-scope="{ row }" label="商品图">
				<div class="flex-row">
					<el-image style="min-width: 30px; height: 30px; width: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
						:preview-src-list="[row.pic]" />
					<div style="margin-left: 4px">{{ row.prodName }}</div>
				</div>
			</template>
			<template slot="supplierName" slot-scope="{ row }" label="供应商">
				<div v-if="row.source === 0"><span class="source source0">内</span>{{ row.supplierName }}</div>
				<div v-else><span class="source source1">外</span>{{ row.supplierName }}</div>
			</template>
			<el-table-column label="操作" width="160" align="right" header-align="center">
				<template slot-scope="{ row }">
					<!-- <el-button v-if="row.source == 1" type="text" @click="handlePriceSetting(row)">
						定价归类
					</el-button> -->

					<el-button v-has="'pms:product:copy'" type="text" class="mr10" :disabled="row.source === 1"
						@click="handleCopy(row.id)">
						复制
					</el-button>

					<router-link v-has="'pms:product:detail'"
						:to="{ path: '/selfManager/creatProduct/' + row.id + '/true/false' }" class="link">
						<el-button type="text" size="small">详情</el-button>
					</router-link>

					<!-- <router-link
						:to="{ path: '/selfManager/creatProduct/' + row.id }"
						class="link"
						v-has="'pms:product:edit'"
					> -->
					<el-button v-has="'pms:product:edit'" type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
					<!-- </router-link> -->

					<delete-button :id="row.id" v-has="'pms:product:delete'" button-type="text" size="small"
						@delete="handleDelete" />
				</template>
			</el-table-column>
		</mvk-table>
		<!--上架-->
		<goods-on ref="goodsOnRef" @OnSuccess="searchList" />
		<!--定价-->
		<price-setting ref="pricesettingRef" @saveSuccess="handleRefresh" />
	</div>
</template>

<script>
import { deleteProd, wareProdList, batchMoveToStationPool, batchGoodsOn, exportWareGoodsList } from "api/business/selfManager/goods.js"

import GoodsOn from "./goodsOn.vue"
import PriceSetting from "./priceSetting"

import { commonMixin } from "./mixin"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import { deliveryMode } from "utils/constants"
import { blobTofile } from "@/utils/utils"
import { getLabelList } from "api/business/selfManager/label.js"

// 仓库中的商品
export default {
	name: "WareGoods",
	components: {
		GoodsOn,
		PriceSetting,
		ProdCategoryTree
	},
	mixins: [commonMixin], // 搜索条件中的店铺以及供应商索引
	data() {
		return {
			deliveryMode,
			tableColumn: [
				{ prop: "id", label: "商品ID", width: "80" },
				// { prop: "pic", label: "商品图" },
				{ prop: "prodName", label: "商品名称", slot: true, minWidth: 140 },
				{
					prop: "deliveryMode",
					label: "配送方式",
					width: 80,
					align: "center",
					formatter: cellValue => {
						switch (cellValue.deliveryMode) {
							case 0:
								return "邮寄"
							case 1:
								return "自提"
							default:
								return "任意"
						}
					}
				},
				{ prop: "categoryTree", label: "分类" },
				{ prop: "supplierName", label: "供应商", slot: true },
				{
					prop: "source",
					label: "来源",
					align: "center",
					formatter: cellValue => {
						if (cellValue.source === 1) {
							return "云链美物平台"
						} else if (cellValue.source === 2) {
							return "商户平台"
						} else if (cellValue.source === 0) {
							return "自营平台"
						}
					}
				},
				{
					prop: "isLevel",
					label: "已入站池",
					width: 80,
					align: "center",
					formatter: row => {
						if (row.isLevel) {
							return "已入"
						} else {
							return "-"
						}
					}
				},
				// {
				// 	prop: "labelNames", label: "商品标签",
				// 	formatter: row => {
				// 		return row.labelNames && row.labelNames.join()
				// 	}
				// },
				{
					prop: "isShelf", label: "已上架",
					align: "center",
					formatter: row => {
						return row.isShelf ? "已上架" : "否"
					}
				},
				{
					label: "商品税率",
					width: "76",
					formatter: row => {
						if (row.taxRate) {
							return row.taxRate * 100 + "%"
						} else {
							return "--"
						}
					}
				}
			],

			queryParam: {
				// deliveryMode: 0,
				supplierId: "",
				prodName: "",
				categoryId: "",
				deliveryMode: ""
			},

			tableDataFunc: parameter => {
				return wareProdList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			fixedColumn: [
				{ prop: "id", label: "SKUId" ,width: 86 },
				// { prop: "pic", label: "图片" },
				{ prop: "skuName", label: "SKU名称", minWidth: 280 },
				{ prop: "sellingPrice", label: "售价" },
				{ prop: "costPrice", label: "成本价" },
				{ prop: "marketPrice", label: "市场价" },
				{ prop: "internalPrice", label: "内购价" },
				{ prop: "actualStocks", label: "库存" },
				{ prop: "volume", label: "体积" },
				{ prop: "weight", label: "重量" }
			],

			tableCheck: [], // 选中的商品

			labelOptions: [] // 标签分类
		}
	},
	watch: {},
	computed: {
		tableData() {
			return this.$refs.mvkTable ? this.$refs.mvkTable.tableData : []
		}
	},
	mounted() {
		if (this.labelOptions.length == 0) {
			this.getLabelOptions()
		}
	},
	methods: {
		handleDelete(data) {
			deleteProd(data.id)
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "删除成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "删除失败"
					})
				})
		},

		handleEdit(row) {
			if (row.source == 1) {
				// 来自供应链的商品
				this.$router.push({ path: "/selfManager/creatProduct/" + row.id + "/true/false/true" })
			} else {
				this.$router.push({ path: "/selfManager/creatProduct/" + row.id })
			}
		},

		handleGoodsOn(id, row) {
			this.$refs.goodsOnRef.open(id, row)
		},

		// 待定价
		handlePriceSetting(row) {
			this.$refs.pricesettingRef.open([row])
		},

		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},
		// 是否可以上架
		isDisableOn(row) {
			if (row.source === 1) {
				return !row.installed
			} else {
				return false
			}
		},

		handleSelectionChange(val) {
			this.tableCheck = val
		},

		// 批量移至站级商品池
		handleBatchMove() {
			batchMoveToStationPool(this.tableCheck.map(item => item.id)).then(() => {
				this.$notify.success({
					title: "添加成功"
				})
			})
		},

		// 批量上架至单个店铺
		handleBatchGoodsOn(type) {
			let params = {}
			if (type == "自营店") {
				params.shopId = 2
			} else if (type == "内购店") {
				params.shopId = 1
			}

			let deliveryModeArray = this.tableCheck.map(item => item.deliveryMode)
			if (deliveryModeArray.some(item => item == 1)) {
				this.$message.warning("选择的商品存在自提商品，自提商品不允许上架到" + type + "，请先去掉自提商品")
				return
			}

			params.productIds = this.tableCheck.map(item => item.id)

			batchGoodsOn(params).then(() => {
				this.$notify.success({
					title: "添加成功"
				})
			})
		},

		// 非云链商品可复制
		handleCopy(id) {
			this.$router.push({
				name: "createProduct",
				params: {
					id: id
				},
				query: {
					copyFlag: true
				}
			})
		},
		// 导出仓库中商品列表
		exportGoodsList() {
			exportWareGoodsList(this.queryParam).then(res => {
				blobTofile(res, "仓库中商品列表", "application/vnd.ms-excel")
			})
		},
		getLabelOptions() {
			getLabelList({ pageSize: 1000, pageNum: 0 }).then(res => {
				if (res.status === "OK") {
					this.labelOptions = res.data.list.filter(e => e.enabled)
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.link+.link {
	margin-left: 8px;
}

// .el-form-item--small.el-form-item {
// 	margin-bottom: 0;
// }
.source {
	display: inline-block;
	margin-right: 4px;
	line-height: 1;
	border: 1px solid #4f8aff;
	color: #4f8aff;
	padding: 4px;
	border-radius: 50px;
	font-size: 12px;
}

.source0 {
	color: #80b568;
	border-color: #80b568;
}
</style>
