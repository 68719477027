<template>
	<el-drawer :title="readOnly ? '详情' : title" :visible.sync="addEditDialog" size="50%" @closed="closeFun()" @opened="openedFun()">
		<div class="drawer-content">
			<el-form ref="couponForm" :model="couponForm" :rules="readOnly ? {} : rules" label-width="200px">
				<el-form-item label="优惠劵名称：" prop="name">
					<el-input
						v-model="couponForm.name"
						:readonly="readOnly"
						style="width: 300px"
						placeholder="请输入优惠券名称"
					/>
				</el-form-item>
				<el-form-item label="优惠券面值：" prop="faceValue">
					<el-input-number v-model="couponForm.faceValue" :disabled="readOnly" :min="1" label="描述文字" />
				</el-form-item>
				<el-form-item label="使用门槛：">
					<el-radio-group v-model="couponForm.isThreshold" :disabled="readOnly">
						<el-radio :label="false">无门槛</el-radio>
						<el-radio :label="true">有门槛</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="couponForm.isThreshold" label="优惠券最低消费：" prop="minConsume">
					<el-input-number v-model="couponForm.minConsume" :min="1" label="描述文字" :disabled="readOnly" />
				</el-form-item>
				<el-form-item label="使用有效期：">
					<el-radio-group v-model="couponForm.validityType" :disabled="readOnly">
						<!-- <el-radio :label="0">天数</el-radio> -->
						<el-radio :label="1">时间段</el-radio>
						<el-radio :label="2">无限期</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-show="couponForm.validityType == 0" label="使用有效期限（天）：" prop="termOfValidity">
					<el-input-number
						v-model="couponForm.termOfValidity"
						:disabled="readOnly"
						:min="1"
						label="描述文字"
					/>
				</el-form-item>
				<el-form-item
					v-show="couponForm.validityType == 1"
					label="使用有效期限："
					prop="termTime"
					:rules="
						couponForm.validityType == 1 && !readOnly
							? [{ required: true, message: '请选择有效期限', trigger: 'change' }]
							: [{ required: false }]
					"
				>
					<el-date-picker
						v-model="couponForm.termTime"
						:disabled="readOnly"
						style="width: 400px"
						type="datetimerange"
						range-separator="至"
						value-format="yyyy-MM-dd HH:mm:ss"
						start-placeholder="开始日期"
						:picker-options="pickerOptions"
						end-placeholder="结束日期"
					/>
				</el-form-item>
				<el-form-item label="领取是否限时：" prop="isTime">
					<el-radio-group v-model="couponForm.isTime" :disabled="readOnly">
						<el-radio :label="true">限时</el-radio>
						<el-radio :label="false">不限时</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="couponForm.isTime" label="领取时间：">
					<el-date-picker
						v-model="isForeverTime"
						:disabled="readOnly"
						style="width: 400px"
						type="datetimerange"
						range-separator="至"
						value-format="yyyy-MM-dd HH:mm:ss"
						:picker-options="pickerOptions"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					/>
				</el-form-item>
				<!-- <el-form-item label="领取方式：" prop="resource">
                    <el-radio-group v-model="couponForm.obtainMethod" :disabled="true">
                        <el-radio :label="10">手动领取</el-radio>
                        <el-radio :label="20">新人券</el-radio>
                        <el-radio :label="30">赠送券</el-radio>
                        <el-radio :label="40">活动券</el-radio>
                    </el-radio-group>
                </el-form-item> -->
				<!-- <el-form-item label="是否限量：" prop="isLimit">
                    <el-radio-group v-model="couponForm.isLimit" :disabled="true">
                        <el-radio :label="true">限量</el-radio>
                        <el-radio :label="false">不限量</el-radio>
                    </el-radio-group>
                </el-form-item> -->
				<el-form-item v-if="couponForm.isLimit" label="发布数量：" prop="pushNum">
					<el-input-number v-model="couponForm.pushNum" :min="1" label="排序" :disabled="readOnly" />
				</el-form-item>
				<el-form-item label="排序：" prop="sort">
					<el-input-number v-model="couponForm.sort" :min="0" label="排序" :disabled="readOnly" />
				</el-form-item>
				<el-form-item label="状态：" prop="status">
					<el-radio-group v-model="couponForm.status" :disabled="readOnly">
						<el-radio :label="true">开启</el-radio>
						<el-radio :label="false">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="说明：" prop="sort">
					<el-input v-model="couponForm.declares" type="textarea"
						placeholder="请输入使用说明"
						maxlength="500" rows="4"
						:readonly="readOnly"
						resize="none"
						show-word-limit autocomplete="off"
					/>
				</el-form-item>
				<!--大客户店 start-->
				<el-form-item label="选择可使用的大客户店铺：" prop="checkedMajorShop">
					<el-select
						v-model="couponForm.checkedMajorShop"
						placeholder="选择可使用的店铺"
						filterable
						:disabled="Boolean(couponForm.keyAccountData)"
						value-key="id"
					>
						<el-option v-for="item in shopEnabledList" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="选择商品：" prop="checked">
					<goods-list-model
						ref="goodsList"
						:checked="couponForm.checked"
						:selected.sync="defaultShopId"
						:shop-id="shopId"
						:type="shopType"
						new-model
						:read-only="readOnly"
						@getMultiGoodsInfo="getGoodsItem"
					/>
					<!-- <div class="flex-row">
						<template v-if="couponForm.checked && couponForm.checked.length">
							<div v-for="(item, index) in couponForm.checked" :key="index" class="pictrue">
								<img :src="item.pic">
								<i class="el-icon-error btndel" @click="handleGoodsRemove(index)" />
							</div>
						</template>
						<div class="upLoadPicBox" @click="changeGood">
							<div class="upLoad">
								<i class="el-icon-camera cameraIconfont" />
							</div>
						</div>
					</div> -->
				</el-form-item>
			</el-form>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button
					v-if="!readOnly"
					type="primary"
					icon="el-icon-check"
					:loading="loading"
					@click="submitForm('couponForm')"
				>
					立即创建
				</el-button>
				<el-button type="text" @click="addEditDialog = false">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { couponAdd, couponInfo } from "api/business/marketing/coupon.js"
import GoodsListModel from "../../components/GoodsListModel.vue"
import { mapState, mapActions } from "vuex"
export default {
	name: "CouponAdd",
	components: {
		GoodsListModel
	},

	computed: {
		...mapState("dict", ["keyAccountShopList"]),

		shopEnabledList() {
			// 大客户店铺
			return this.keyAccountShopList.filter(item => item.enabled && item.type == 2)
		},
		shopType() {
			if (this.couponForm.types == 40) {
				return "major"
			} else {
				return "sale"
			}
		},

		shopId() {
			if (this.$route.query.id) {
				return parseInt(this.$route.query.id) || ""
			} else {
				return 0
			}
		}
	},

	data() {
		return {
			title: "添加优惠券",
			addEditDialog: false,
			loading: false,
			readOnly: false,
			couponForm: {
				id: "",
				name: "",
				faceValue: 1, // 面值
				isThreshold: false, // 门槛
				minConsume: 1, // 最低消费
				validityType: 2, // 使用有效期 0-天数;1-时间段;2:无限制
				termOfValidity: "", // 使用有效期限
				isTime: false, // 领取是否限时 0-不限时;1-限时
				receiveTime: "", // 领取时间
				obtainMethod: 30, // 领取方式  10-手动领取;20-新人券;30-赠送券
				isLimit: false, // 是否限量;false-不限量;true-限量
				pushNum: 1, // 发布数量
				surplusNum: 1, // 剩余数量
				sort: 0, // 排序
				status: true, // 状态;false-关闭;true-开启
				types: 10, // 优惠券类型:10-通用券;20-商品券;30-品类券

				pmsId: "", // 商品id
				pic: "", // 商品主图
				pmsType: "", // 品类id
				pmsTypeName: "", // 品类名称
				declares: "", // 优惠券说明
				shop: "", // 选择的可用店铺
				checkedShop: [],
				checkedMajorShop: "",
				checked: [], // 选择的商品
				termTime: []
			},

			rules: {
				name: [{ required: true, message: "请输入优惠券名称", trigger: "blur" }],
				// termOfValidity: [{ required: true, message: "请输入使用有效期限（天）", trigger: "blur" }],
				faceValue: [{ required: true, message: "请输入优惠券面值", trigger: "blur" }],
				pmsType: [{ required: true, message: "请选择品类", trigger: "change" }],
				checked: [{ required: true, message: "请至少选择一个商品", trigger: "change", type: "array" }],
				// isForeverTime: [{ required: true, message: "请选择领取时间", trigger: "change", type: "array" }],
				// pushNum: [{ required: true, message: "请输入发布数量", trigger: "blur" }],
				minConsume: [{ required: true, message: "请输入最低消费", trigger: "blur" }],
				checkedShop: [{ required: true, message: "请选择可使用的店铺", trigger: "change" }]
			},

			termTime: [], // 有效期
			isForeverTime: [], // 领取时间段
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},
			checkedShop: [],
			defaultShopId: []

		}
	},

	mounted() {},

	methods: {
		...mapActions("dict", ["getKeyAccountShopList"]),
		open(recode, readOnly) {
			this.addEditDialog = true
			this.readOnly = !!readOnly
			// console.log("readOnly", this.readOnly)
			const setShopInfo = () => {
				// 定制“大客户专用优惠券”
				this.$nextTick(() => {
					this.$set(this.couponForm, "keyAccountData", recode.keyAccountData)
				})
				if (recode.keyAccountData) {
					this.couponForm.types = 40
					this.$set(this.couponForm, "checkedMajorShop", Number(recode.keyAccountData.shopId))
				}
			}
			if (this.keyAccountShopList.length == 0) {
				this.getKeyAccountShopList()
			}
			if (recode && recode.id) {
				this.getCouponInfo(recode.id).then(() => {
					if (readOnly) setShopInfo()
				})
			} else {
				this.couponForm = {
					id: "",
					name: "",
					faceValue: 1, // 面值
					isThreshold: false, // 门槛
					minConsume: 1, // 最低消费
					validityType: 2, // 使用有效期 0-天数;1-时间段;2:无限制
					termOfValidity: "", // 使用有效期限
					isTime: false, // 领取是否限时 0-不限时;1-限时
					receiveTime: "", // 领取时间
					obtainMethod: 30, // 领取方式  10-手动领取;20-新人券;30-赠送券
					isLimit: false, // 是否限量;false-不限量;true-限量
					pushNum: 1, // 发布数量
					surplusNum: 1, // 剩余数量
					sort: 0, // 排序
					status: true, // 状态;false-关闭;true-开启
					types: 40, // 优惠券类型:10-通用券;20-商品券;30-品类券

					pmsId: "", // 商品id
					pic: "", // 商品主图
					pmsType: "", // 品类id
					pmsTypeName: "", // 品类名称
					declares: "", // 优惠券说明
					shop: "", // 选择的可用店铺
					checkedShop: [],
					checked: [], // 选择的商品
					termTime: []
				}
				setShopInfo()
			}
		},
		AllCheck() {
			let temp = []
			this.shopEnabledList.forEach(item => {
				temp.push(`${item.id}|${item.name}`)
			})
			this.couponForm.checkedShop = temp
		},

		getCouponInfo(id) {
			return new Promise((resolve, reject) => {
				couponInfo(id)
					.then(res => {
						this.couponForm = Object.assign(this.couponForm, res.data)
						this.couponForm.id = ""
						if (this.couponForm.isTime) this.isForeverTime = this.couponForm.receiveTime.split(",")
						if (this.couponForm.validityType)
							this.couponForm.termTime = this.couponForm.termOfValidity.split(",")
						this.couponForm.checked = this.couponForm.products
						this.defaultShopId = this.couponForm.pmsId.split(",").map(Number)
						resolve()
					})
					.catch(() => {
						reject()
					})
			})
		},

		changeGood() {
			this.$refs.goodsList.open()
		},

		getGoodsItem(goodsInfo) {
			this.couponForm.checked = goodsInfo
		},

		handleGoodsRemove(index) {
			this.couponForm.checked.splice(index, 1)
		},

		submitForm(formName) {
			if (
				(this.couponForm.isTime && !this.isForeverTime) ||
				(this.couponForm.isTime && !this.isForeverTime.length)
			)
				return this.$message.warning("请选择请选择领取时间")
			if (!this.couponForm.isThreshold) this.couponForm.minConsume = 0
			if (!this.couponForm.isLimit) this.couponForm.pushNum = 0
			if (this.couponForm.validityType && this.couponForm.termTime.length)
				this.couponForm.termOfValidity = this.couponForm.termTime.join(",")

			if (this.couponForm.isTime && this.isForeverTime.length)
				this.couponForm.receiveTime = this.isForeverTime.join(",")
			if (this.couponForm.types == 40 && this.couponForm.checked.length > 0) {
				// couponForm.pmsId = this.couponForm.checked.map(item => item.id).join(",")
				this.couponForm.pmsId = this.defaultShopId.join(",")
			} else {
				this.couponForm.pmsId = ""
			}
			this.couponForm.surplusNum = this.couponForm.pushNum
			if (this.couponForm.types == 40) {
				this.couponForm.shop = this.couponForm.checkedMajorShop
			}

			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true
					couponAdd(this.couponForm)
						.then(() => {
							this.$message.success("新增成功")
							this.loading = false
							this.addEditDialog = false
							this.$emit("saveSuccess")
						})
						.catch(() => {
							this.loading = false
						}).finally(() => { 
							this.$set(this.couponForm, "checked", [])
						})
				} else {
					this.loading = false
					return false
				}
			})
		},
		openedFun() { 
			this.$refs.couponForm.clearValidate()
		},
		closeFun() { 
			this.defaultShopId = []
			this.couponForm.checked = []
			this.$refs.couponForm.clearValidate()
		}
	}
}
</script>

<style lang="scss" scoped>
.el-tag + .el-tag {
	margin-left: 10px;
	margin-top: 10px;
}
</style>
