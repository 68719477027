<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="100px" :inline="true" @submit.native.prevent>
			<el-form-item label="会员名称：">
				<el-input v-model="queryParam.name" placeholder="会员名称" clearable />
			</el-form-item>
			<el-form-item label="手机号码：">
				<el-input v-model="queryParam.mobileOrWechatId" placeholder="手机号码" clearable />
			</el-form-item>
			<el-form-item label="注册时间：">
				<el-date-picker
					v-model="timeVal"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					placeholder="注册时间"
					style="width: 220px"
					@change="onchangeTime"
				/>
			</el-form-item>
		</el-form>

		<el-tabs v-model="queryParam.internal">
			<el-tab-pane label="平台会员" name="false" />
			<el-tab-pane label="内购会员" name="true" />
		</el-tabs>

		<div v-show="queryParam.internal == 'true'" class="flex-row mb15">
			<el-upload
				v-has="'ums:member:upload'"
				action
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
				:http-request="handleUpload"
				:headers="uploadHeaders"
				:show-file-list="false"
			>
				<el-button size="small" round type="primary">Excel导入</el-button>
			</el-upload>

			<el-button type="success" round class="ml8" @click="handleDownload">内购会员模板下载</el-button>
		</div>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
			<template slot="pic" slot-scope="{ row }" label="会员头像">
				<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
			</template>
			<template slot="level" slot-scope="{ row }" label="会员等级">
				{{ row.memberLevelVO && row.memberLevelVO.name }}
			</template>
			<template slot="group" slot-scope="{ row }" label="会员分组">
				{{ row.memberGroupVO && row.memberGroupVO.name }}
			</template>
			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button v-has="'ums:member:edit'" type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
				</template>
			</el-table-column>
		</mvk-table>

		<edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
	</div>
</template>

<script>
import { memberList, memberUpload, memberDownload } from "api/business/member/list.js"
import { listMixin } from "views/mixin/listMixin"
import { getToken } from "api/auth"

export default {
	name: "MemberListIndex",
	mixins: [listMixin],
	components: {
		"edit-dialog": () => import("./edit.vue")
	},
	data() {
		return {
			memberForm: {
				keywords: ""
			},

			collapse: false,

			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "pic",
					label: "会员头像",
					slot: true
				},
				{
					prop: "nickName",
					label: "会员昵称"
				},
				{
					prop: "realName",
					label: "会员名称"
				},
				{
					prop: "sex",
					label: "会员性别",
					formatter: cellValue => {
						if (cellValue.sex == 1) {
							return "男"
						} else if (cellValue.sex == 2) {
							return "女"
						} else {
							return "未知"
						}
					}
				},
				{
					prop: "level",
					label: "会员等级",
					slot: true
				},
				{
					prop: "group",
					label: "会员分组",
					slot: true
				},
				{
					prop: "userMobile",
					label: "手机号码"
				},
				{
					prop: "userMail",
					label: "邮箱"
				},
				{
					prop: "score",
					label: "积分"
				},
				{
					prop: "signUpByAppName",
					label: "引流渠道"
				}
			],
			timeVal: [],
			queryParam: {
				internal: "false",
				name: "",
				mobileOrWechatId: "",
				registerTimeStart: "",
				registerTimeEnd: ""
			},

			// tableDataFunc: () => {
			// 	if (this.queryParam.internal == "false") {
			// 		return (parameter) => {
			// 			delete parameter.internal
			// 			return memberList(Object.assign(parameter)).then((res) => {
			// 				return res.data
			// 			})
			// 		}
			// 	} else {
			// 		return (parameter) => {
			// 			return memberList(Object.assign(parameter, this.queryParam)).then((res) => {
			// 				return res.data
			// 			})
			// 		}
			// 	}
			// },

			uploadHeaders: { "X-Token": getToken() }
		}
	},
	computed: {
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))
			if (this.queryParam.internal == "false") {
				return parameter => {
					delete params.internal
					delete parameter.internal
					return memberList(Object.assign(parameter, params)).then(res => {
						return res.data
					})
				}
			} else {
				return parameter => {
					return memberList(Object.assign(parameter, params)).then(res => {
						return res.data
					})
				}
			}
		}
	},
	watch: {},
	mounted() {},

	methods: {
		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
		},

		handleUpload(param) {
			const formData = new FormData()
			formData.append("file", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			memberUpload(formData)
				.then(() => {
					loading.close()
					this.$message.success("上传成功")
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					loading.close()
				})
		},

		handleDownload() {
			memberDownload().then(res => {
				var blob = new Blob([res], { type: "application/vnd.ms-excel" })
				var downloadElement = document.createElement("a")
				var href = window.URL.createObjectURL(blob) // 创建下载的链接
				downloadElement.href = href
				downloadElement.download = "内购会员模板.xls" // 下载后文件名
				document.body.appendChild(downloadElement)
				downloadElement.click() // 点击下载
				document.body.removeChild(downloadElement) // 下载完成移除元素
				window.URL.revokeObjectURL(href) // 释放掉blob对象
			})
		},

		onchangeTime(e) {
			console.log(e)
			this.queryParam.registerTimeStart = e ? e[0] : ""
			this.queryParam.registerTimeEnd = e ? e[1] : ""
		}
	}
}
</script>

<style lang="scss" scoped>
.text-right {
	text-align: right;
}
</style>
