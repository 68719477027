<template>
	<div class="mvk-layout-content-card">
		<!-- 操作 -->
		<div class="options flex flex-sb">
			<div>
				<el-button size="small" type="primary" round @click="handleEdit('add')"> 新增通用用品店 </el-button>
			</div>
			<div>
				<el-input
					v-model="queryParam.name"
					placeholder="请输入店铺名称查找"
					prefix-icon="el-icon-search"
					clearable
					@input="search()"
				/>
			</div>
		</div>
		<mvk-table
			ref="mvkTable"
			v-loading="switchLoading"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			class="mt20"
			@selection-change="handleSelectionChange"
		>
			<!-- <template slot="receiveTime" slot-scope="{ row }" label="店铺编号"> -->
			<el-table-column slot="head" type="selection" width="38" />

			<template slot="productNum" slot-scope="{ row }">
				<router-link
					v-if="row.productNum"
					:to="{
						path: '/marketingManager/labour/labourEdit/detail',
						query: { id: row.id, activeName: 'goods' }
					}"
					class="link"
				>
					{{ row.productNum }}个
				</router-link>
				<span v-else> - </span>
			</template>
			<template slot="memberNum" slot-scope="{ row }">
				<router-link
					v-if="row.memberNum"
					:to="{
						path: '/marketingManager/labour/labourEdit/detail',
						query: { id: row.id, activeName: 'member' }
					}"
					class="link"
				>
					{{ row.memberNum }}人
				</router-link>
				<span v-else> - </span>
			</template>

			<template slot="enabled" slot-scope="{ row }">
				<el-switch
					v-model="row.enabled"
					active-color="#4F8AFF"
					inactive-color="#ccc"
					@change="changeSwitch(row)"
				/>
			</template>
			<el-table-column label="操作" width="140">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleEdit('edit', row)"> 编辑 </el-button>
					<el-button type="text" size="small" @click="handleEdit('detail', row)"> 详情 </el-button>
					<!-- <delete-button
						:id="row.id"
						button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button> -->

					<el-popover trigger="hover">
						<template #reference>
							<el-button type="text" class="ml10" @click="handleExport(row)">二维码</el-button>
						</template>
						<template #default>
							<div class="flex-column flex-a-c">
								<div class="qr_title">{{ row.name }}</div>
								<div class="qr_desc">微信扫描二维码进入专属店铺</div>
								<vue-qr
									ref="Qrcode"
									:text="'https://sxymini.mevikycloud.com/labor?shopId=' + row.id"
									qid="testQrId"
									:margin="10"
									:size="160"
								/>
							</div>
						</template>
					</el-popover>
					<!-- <el-button type="text" size="small" @click="getQRCodeForOne(row)"> 二维码 </el-button> -->
				</template>
			</el-table-column>
		</mvk-table>
	</div>
</template>

<script>
import VueQr from "vue-qr"
import * as api from "@/api/business/selfManager/labour"
import { debounce } from "utils/utils"
import { blobTofile } from "@/utils/utils"

export default {
	components: { VueQr },

	data() {
		return {
			// 查询条件参数
			queryParam: { name: "" },
			dataList: [],

			tableColumn: [
				{
					prop: "code",
					label: "商铺编号",
					width: 138
				},
				{
					prop: "name",
					label: "店铺名称",
					minWidth: 100
				},
				{
					prop: "productNum",
					label: "店铺商品",
					align: "center",
					width: 76,
					slot: true
				},
				{
					prop: "memberNum",
					label: "店铺会员",
					align: "center",
					width: 76,
					slot: true
				},
				{
					prop: "person",
					label: "通用用品负责人"
				},
				{
					prop: "phone",
					label: "联系人电话"
				},
				{
					prop: "enabled",
					label: "是否启用",
					slot: true,
					width: 70
				}
			],
			switchLoading: false,
			tableDataFunc: (parameter) => {
				return api.laborShop(Object.assign(parameter, this.queryParam)).then((res) => {
					res.data.list = res.data.list.map((e) => {
						if (e.gsModel) e.gsModelArr = e.gsModel.split(";")

						// 增加员工表格
						e.clerkData = []
						e.loading = false
						return e
					})
					return res.data
				})
			},
			selectList: [],
		}
	},
	methods: {
		// 新增 / 编辑 / 查看
		handleEdit(type, row) {
			const query = {}
			if (row) query.id = row.id
			this.$router.push({
				path: "/marketingManager/labour/labourEdit/" + type,
				query
			})
		},
		handleDelete(data) {
			this.$notify.error("删除未接入", data)
			// api.couponDelete(data.id).then(() => {
			// this.$notify.success("删除成功")
			// 	this.$refs.mvkTable.refresh()
			// })
		},
		handleSelectionChange(val) {
			this.selectList = val
		},
		search: debounce(function () {
			this.$refs.mvkTable.refresh()
		}, 300),
		// 导出二维码
		handleExport(row) {
			api.getLaborQRCodeDownload(row.id).then((r) => {
				blobTofile(r, row.name + "-通用二维码.png", "application/x-png")
			})
		},
		// 是否启用
		changeSwitch(row) {
			this.switchLoading = true
			api.laborShopEnabled({ id: row.id })
				.then((res) => {
					if (res.status == "OK") this.$refs.mvkTable.refresh()
				})
				.catch((err) => console.log(err))
				.finally(() => {
					this.switchLoading = false
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.qr_title {
	color: #4b5eff;
	font-size: 18px;
}

.qr_desc {
	color: #999;
	font-size: 12px;
}

.link {
	color: #4b5eff;
}
</style>
