<template>
	<div>
		<div class="flex-row flex-wrap" style="justify-content: space-between">
			<div style="min-width: 300px; height: 50px">
				<el-button size="small" round type="primary" @click="handleAdd"> 新增机构用户 </el-button>
				<span class="cr_999 fs12" style="margin-left: 20px">注：店长和店员不在此模块新增、修改</span>
			</div>

			<div class="flex-row" style="justify-content: space-between">
				<el-form size="small" label-width="76px" :inline="true">
					<el-form-item label="用户搜索">
						<el-input v-model="queryParam.userName" placeholder="输入用户名称搜索" clearable />
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model="queryParam.phone" placeholder="输入手机号搜索" clearable />
					</el-form-item>
				</el-form>
			</div>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
			<template slot="roles" slot-scope="{ row }" label="用户角色">
				<el-tag>{{ row.roles }}</el-tag>
			</template>
			<el-table-column label="操作" width="180">
				<template slot-scope="{ row }">
					<el-button v-if="row.account != '--'" type="text" size="small" @click="handleEdit(row.userId)">
						编辑
					</el-button>
				</template>
			</el-table-column>
		</mvk-table>
		<add-admin ref="modalForm" @saveSuccess="handleRefresh" />
	</div>
</template>

<script>
import { organitionUser } from "@/api/basePage/organition"
import { listMixin } from "views/mixin/listMixin"
export default {
	name: "Memberlist",
	components: {
		"add-admin": () => import("./addAdmin.vue")
	},
	props: {
		organizationId: {
			require: true,
			type: Number,
			default: 0
		}
	},
	mixins: [listMixin],
	data() {
		return {
			queryParam: {
				userName: "",
				phone: ""
			},
			tableColumn: [
				{ prop: "userName", label: "用户名称" },
				{ prop: "account", label: "用户账号" },
				{ prop: "roles", label: "用户角色", slot: true },
				{ prop: "phone", label: "手机号" },
				{ prop: "organName", label: "所属机构" }
			]
		}
	},
	computed: {
		tableDataFunc() {
			let organizationId = this.organizationId
			let temp = Object.assign({ id: organizationId }, this.queryParam)
			// console.log(temp)
			if (organizationId == 0) {
				return () => {
					return new Promise(resolve => {
						return resolve({
							list: [],
							state: "OK",
							totalElements: 0
						})
					})
				}
			} else {
				return parameter => {
					return organitionUser(Object.assign(parameter, temp)).then(res => {
						return res.data
					})
				}
			}
		}
	},

	mounted() {},

	methods: {
		handleRefresh() {
			this.$refs.mvkTable.refresh()
		}
	}
}
</script>

<style lang="scss" scoped></style>
