<template>
	<el-drawer title="商品上架" :visible.sync="onDrawer" size="50%">
		<div class="drawer-content">
			<el-button type="primary" round @click="addRelateShop">添加关联店铺</el-button>
			<el-form ref="shopForm" :model="formItem" :rules="rules">
				<el-card
					v-for="(shop, index) in formItem.shopArrInfo"
					:key="index"
					shadow="always"
					:body-style="{ padding: '20px' }"
					class="mt15"
				>
					<div slot="header">
						<el-form-item
							:rules="[{ required: true, message: '请选择店铺', trigger: 'change' }]"
							:prop="'shopArrInfo.' + index + '.shopId'"
							label="店铺名称："
							style="margin-bottom: 10px"
						>
							<el-select
								v-model="shop.shopId"
								placeholder="输入名称查找店铺"
								filterable
								style="width: 240px"
								@change="getShopInfo(index, shop.shopId)"
							>
								<el-option
									v-for="item in shops"
									:key="item.id"
									:label="item.name"
									:value="item.id"
									:disabled="item.disabled || deliveryJudge(item)"
								/>
							</el-select>
							<el-button
								type="danger"
								icon="el-icon-delete"
								circle
								style="float: right"
								@click.native="deleteShop(index, shop.shopId)"
							/>
						</el-form-item>
					</div>
					<div v-if="shop.shopId != ''">
						<el-row :gutter="20" style="font-size: 14px">
							<el-col :span="8"> 店长:{{ shop.shopFormInfo.shopManagerName }} </el-col>
							<el-col :span="8"> 联系电话:{{ shop.shopFormInfo.shopManagerMobile }} </el-col>
						</el-row>
						<el-row :gutter="20" class="mt15" style="font-size: 14px">
							<el-col :span="24">
								地址:{{ shop.shopFormInfo.shopAddress || shop.shopFormInfo.address }}
							</el-col>
						</el-row>

						<el-button type="success" round class="mt15 mb15" size="mini" @click="addGoodsSku(index)">
							添加商品规格
						</el-button>

						<el-form-item
							:rules="[{ required: true, message: '请添加商品规格', trigger: 'change' }]"
							:prop="'shopArrInfo.' + index + '.skuStocks'"
						>
							<el-table :data="shop.skuStocks" border stripe size="mini">
								<el-table-column label="sku名称" prop="skuId">
									<template slot-scope="scope">
										<el-form-item
											:rules="[{ required: true, message: '请选择商品规格', trigger: 'change' }]"
											:prop="'shopArrInfo.' + index + '.skuStocks.' + scope.$index + '.skuId'"
											style="margin-bottom: 0"
										>
											<el-select
												v-model="scope.row.skuId"
												placeholder="请选择商品规格"
												@change="changeSku(index, scope.row.skuId, scope.$index)"
											>
												<el-option
													v-for="item in shop.goodsSkuList"
													:key="item.id"
													:label="item.skuName"
													:value="item.id"
													:disabled="item.disabled"
												/>
											</el-select>
										</el-form-item>
									</template>
								</el-table-column>
								<el-table-column label="上架数量" prop="actualStocks">
									<template slot-scope="{ row }">
										<el-input-number
											v-model="row.actualStocks"
											size="small"
											type="number"
											:min="0"
											:max="row.stocks"
											:step="1"
											step-strictly
										/>
									</template>
								</el-table-column>
								<el-table-column label="库存" prop="stocks" width="76" />
								<el-table-column label="售价" prop="sellingPrice" width="76" />
								<el-table-column label="操作" width="55">
									<template slot-scope="scope">
										<el-button
											type="primary"
											icon="el-icon-delete"
											circle
											@click="deleteSku(index, scope.row.skuId, scope.$index)"
										/>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
					</div>
				</el-card>
			</el-form>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button type="text" @click="onDrawer = false">取消</el-button>
				<div>
					<el-button type="primary" @click="handleGoodsOn('shopForm')">上架</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { shopInfo } from "api/business/selfManager/shop.js"

import { shelfProd, prodDetail } from "api/business/selfManager/goods.js"

import { mapState, mapActions } from "vuex"

export default {
	name: "GoodsOn",
	data() {
		return {
			onDrawer: false,

			shops: [], // 所有店铺
			goodsSkuList: [], // 商品的sku

			shopArrInfo: [],

			formItem: {
				shopArrInfo: []
			},

			rules: {
				shopArrInfo: [
					{
						required: true,
						message: "请选择关联店铺",
						trigger: "change"
					}
				]
			},

			goodsId: "",
			deliveryMode: -1 // 邮寄方式
		}
	},
	computed: {
		...mapState("dict", ["shoplist"]),

		shopEnabled() {
			return this.shoplist.filter(item => item.enabled && item.id != 1 && item.id != 2)
		}
	},
	watch: {},
	mounted() {},
	methods: {
		...mapActions("dict", ["getShopList"]),

		open(id, row) {
			if (row) this.deliveryMode = row.deliveryMode
			this.goodsId = id
			this.onDrawer = true
			if (this.shoplist.length == 0) {
				this.getShopList()
			}
			this.shops = JSON.parse(JSON.stringify(this.shopEnabled))
			this.getGoodsSkuList()
			this.formItem.shopArrInfo = []
		},
		// 判断是否禁用
		deliveryJudge(item) {
			return this.deliveryMode === 1 && (item.type === 3 || item.type === 1)
		},

		// 根据所选店铺Id获取店铺信息
		getShopInfo(index, shopId) {
			let vm = this

			shopInfo(this.formItem.shopArrInfo[index].shopId).then(res => {
				this.formItem.shopArrInfo[index].shopFormInfo = res.data
			})

			this.shops.forEach(shop => {
				if (shop.id == shopId) {
					vm.$set(shop, "disabled", true)
				}
			})
		},

		// 根据商品Id获取商品sku
		getGoodsSkuList() {
			prodDetail(this.goodsId).then(res => {
				this.goodsSkuList = res.data.skus
			})
		},

		// 选择商品规格，带出商品库存等信息
		changeSku(index, skuId, skuIndex) {
			let vm = this
			let temp = this.goodsSkuList.filter(item => item.id == skuId)[0]

			let temp1 = this.formItem.shopArrInfo[index].skuStocks[skuIndex]

			this.formItem.shopArrInfo[index].skuStocks[skuIndex] = Object.assign(temp1, temp)

			this.formItem.shopArrInfo[index].skuStocks[skuIndex].stocks = this.formItem.shopArrInfo[index].skuStocks[
				skuIndex
			].actualStocks // 库存

			this.formItem.shopArrInfo[index].skuStocks[skuIndex].actualStocks = 0 // 上架数量

			this.formItem.shopArrInfo[index].goodsSkuList.forEach(item => {
				if (skuId == item.id) {
					vm.$set(item, "disabled", true)
				} else {
					// vm.$set(item,'disabled',false)
				}
			})
		},

		// 添加关联店铺
		addRelateShop() {
			this.formItem.shopArrInfo.push({
				shopId: "",
				shopFormInfo: {},
				skuStocks: [],
				goodsSkuList: JSON.parse(JSON.stringify(this.goodsSkuList))
			})
		},

		// 添加商品规格
		addGoodsSku(index) {
			let skusLength = this.formItem.shopArrInfo[index].skuStocks.length
			let goodsSkusLength = this.goodsSkuList.length

			if (goodsSkusLength <= skusLength) {
				this.$notify.warning({
					title: "提示",
					message: "不能添加多于该商品规格的数量"
				})
				return
			}

			this.formItem.shopArrInfo[index].skuStocks.push({
				id: "",
				skuId: "",
				shopId: this.formItem.shopArrInfo[index].shopId,
				skuName: "",
				stocks: 0, // 库存
				actualStocks: 0 // 上架数量
			})
		},

		// 删除关联店铺
		deleteShop(index, shopId) {
			let vm = this
			this.formItem.shopArrInfo.splice(index, 1)

			this.shops.forEach(shop => {
				if (shop.id == shopId) {
					vm.$set(shop, "disabled", false)
				}
			})
		},

		// 删除所选规格
		deleteSku(shopIndex, skuId, skuIndex) {
			this.formItem.shopArrInfo[shopIndex].skuStocks.splice(skuIndex, 1)

			this.formItem.shopArrInfo[shopIndex].goodsSkuList.forEach(item => {
				console.log(skuId)
				if (skuId == item.id) {
					item.disabled = false
				}
			})
		},

		handleGoodsOn(name) {
			if (this.formItem.shopArrInfo.length == 0) {
				this.$notify.warning({
					title: "提示",
					message: "请先选择关联店铺"
				})
				return
			}

			this.$refs[name].validate(valid => {
				if (valid) {
					let temp = []

					this.formItem.shopArrInfo.forEach(item => {
						temp = temp.concat(item.skuStocks)
					})

					let params = {}
					params.productId = this.goodsId
					params.skuStocks = temp.map(item => {
						return {
							id: "",
							skuId: item.skuId,
							shopId: item.shopId,
							actualStocks: item.actualStocks
						}
					})

					let isEmtry = params.skuStocks.some(item => {
						return item.actualStocks == 0
					})

					if (isEmtry) {
						this.$notify.warning({
							title: "提示",
							message: "选择的规格库存数量不足不能上架"
						})
					} else {
						shelfProd(params).then(() => {
							this.$notify.success({
								title: "成功",
								message: "上架成功"
							})

							this.onDrawer = false

							this.$emit("OnSuccess")
						})
					}
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
