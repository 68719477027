<template>
	<div class="mvk-layout-content-card">
		<div>
			<el-button v-has="'live:room:add'" type="primary" round @click="handleAdd">创建直播间</el-button>
			<el-button v-has="'live:room:sync'" type="success" round @click="handleSync">同步</el-button>
		</div>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<template slot="coverImgUrl" slot-scope="{row}" label="直播间背景图">
				<el-image
					style="width:30px;height:30px"
					:src="row.coverImgUrl + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="[row.coverImgUrl + '?imageMogr2/format/webp']"
				/>
			</template>
			<template slot="feedsImgUrl" slot-scope="{row}" label="购物封面图(列表)">
				<el-image
					style="width:30px;height:30px"
					:src="row.feedsImgUrl + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="[row.feedsImgUrl + '?imageMogr2/format/webp']"
				/>
			</template>
			<template slot="shareImgUrl" slot-scope="{row}" label="直播间分享图片">
				<el-image
					style="width:30px;height:30px"
					:src="row.shareImgUrl + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="[row.shareImgUrl + '?imageMogr2/format/webp']"
				/>
			</template>
			<template slot="status" slot-scope="{row}" label="直播间状态">
				<el-tag>{{ statusMap[row.status] }}</el-tag>
			</template>
			<el-table-column label="操作" min-width="90">
				<template slot-scope="{row}">
					<el-button v-if="row.status == '102'" v-has="'live:room:edit'" type="text" size="small" @click="handleEdit(row)">编辑</el-button>
					<el-button v-has="'live:room:product:import'" type="text" size="small" @click="handleImportProduct(row)">商品</el-button>
					<delete-button v-if="row.status != '101' || row.status != '105' || row.status != '106'" :id="row.id" v-has="'live:room:delete'" button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
		<add-product ref="addProductRef" />
	</div>
</template>

<script>
import { listMixin } from "views/mixin/listMixin"
import { permissionMixin } from "views/mixin/permissionMixin"
import { getLiveRoom, deleteLiveRoom, syncLiveRoom } from "api/business/broadcast/live"
import { getMedia } from "api/business/broadcast/media"
export default {
	name: "BroadcastList",
	components: {
		"add-edit-dialog": () => import("./addEdit.vue"),
		"add-product": () => import("./product.vue")
	},
	mixins: [listMixin, permissionMixin],
	data() {
		return {
			tableColumn: [
				{ prop: "id", label: "直播间ID", width: 70 },
				{ prop: "name", label: "直播间标题" },
				{ prop: "coverImgUrl", label: "直播间背景图", slot: true },
				{ prop: "feedsImgUrl", label: "购物封面图", slot: true },
				{ prop: "shareImgUrl", label: "直播间分享图片", slot: true },
				{ prop: "startTime", label: "开始时间" },
				{ prop: "endTime", label: "结束时间" },
				{ prop: "anchorName", label: "主播昵称" },
				{ prop: "anchorWechat", label: "主播微信号" },
				{
					prop: "type", label: "直播类型", formatter: value => { // 格式化内容
						const statusMap = {
							0: "手机直播",
							1: "推流直播"
						}

						return statusMap[value.type]
					}
				},
				{ prop: "status", label: "直播间状态", slot: true }
			],
			queryParam: {},
			tableDataFunc: parameter => {
				return getLiveRoom(Object.assign(parameter, this.queryParam))
					.then(res => {

						return res.data
					})
			},

			statusMap: {
				101: "直播中",
				102: "未开始",
				103: "已结束",
				104: "禁播",
				105: "暂停",
				106: "异常",
				107: "已过期"
			}
		}
	},

	mounted() {
		// this.getMediaBase64('25eyBi-Dzkz9AwP2E76s7phb5bg0rRuh1QnIi9N3affJi9T_BGb2FEuuqKogWu9D')
	},

	methods: {
		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
		},

		handleDelete(data) {
			deleteLiveRoom(data.id).then(() => {
				this.$notify.success("删除成功")
				this.$refs.mvkTable.refresh()
			})
		},

		handleSync() {
			syncLiveRoom().then(() => {
				this.$refs.mvkTable.refresh()
			})
		},

		getMediaBase64(mediaId) {
			getMedia({
				mediaId: mediaId
			}).then(res => {
				console.log(res)
			})
		},

		handleImportProduct(row) {
			this.$refs.addProductRef.open(row)
		}
	}
}
</script>

<style lang="scss" scoped></style>
