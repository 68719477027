export const listMixin = {
	data() {
		return {

		}
	},
	methods: {
		handleAdd() {
			this.$refs.modalForm.add()
			this.$refs.modalForm.readOnly = false
			this.$refs.modalForm.title = "新增"
		},

		handleEdit(record, readOnly) {
			this.$refs.modalForm.edit(record, readOnly)
			this.$refs.modalForm.readOnly = readOnly
			this.$refs.modalForm.title = readOnly ? "详情" : "编辑"
		},

		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
		}
	}
}
