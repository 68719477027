<template>
	<el-drawer v-loading="loading" :title="title" :visible.sync="addEditDialog" size="50%">
		<div class="drawer-content">
			<el-form ref="form" :model="dataForm" :rules="rules" label-width="180px" :inline="false">
				<el-form-item label="职级" prop="grade">
					<el-select v-model="dataForm.grade" placeholder="职级">
						<el-option v-for="item in gradeList" :key="item.value" :label="item.label" :value="item.value" />
					</el-select>
				</el-form-item>
				<el-form-item label="用户信息" prop="memberInfo">
					<el-select v-model="dataForm.memberInfo" filterable remote value-key="id" placeholder="请输入关键词" :remote-method="getMemberList"
					@change="memberChange"
						:loading="loading">
						<el-option v-for="(item,index) in memberList" :key="item.id" style="width:320px;"
							:value="item">
							<span style="float: left">{{ item.nickName || item.realName }}</span>
							<span style="float: right; color: #8492a6; font-size: 13px">{{ item.userMobile }}</span>
						</el-option>
					</el-select>
					

					<!-- <el-select v-model="dataForm.memberInfo" filterable value-key="id" placeholder="请输入微信号搜索"
						@change="memberChange">
						<el-option v-for="item in memberList" :key="item.id" :label="item.nickName" :value="item"
							style="width:320px;">
							<span style="float: left">{{ item.nickName }}</span>
							<span style="float: right; color: #8492a6; font-size: 13px">{{ item.userMobile }}</span>
						</el-option>
					</el-select> -->
				</el-form-item>
				<el-form-item>
					<el-collapse-transition>
						<el-alert v-show="dataForm.memberInfo" type="success" :closable="false">
							<p>用户昵称：{{ dataForm.nickName }}</p>
							<p>真实姓名：{{ dataForm.realName }}</p>
							<p>联系方式：{{ dataForm.mobile }}</p>
						</el-alert>
					</el-collapse-transition>
				</el-form-item>
				<el-form-item label="门店信息" prop="shopId">
					<el-select v-model="dataForm.shopId" filterable value-key="id" placeholder="请输入门店名称搜索"
						@change="shopChange">
						<el-option v-for="item in shopList" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-collapse-transition>
						<el-alert v-show="dataForm.shopInfo" type="success" :closable="false">
							<p>门店名称：{{ dataForm.shopInfo && dataForm.shopInfo.name }}</p>
							<p>门店地址：{{ dataForm.shopInfo && dataForm.shopInfo.address }}</p>
							<p>店长：{{ dataForm.shopInfo && dataForm.shopInfo.shopManagerName }}</p>
							<p>店长联系电话：{{ dataForm.shopInfo && dataForm.shopInfo.shopManagerMobile }}</p>
						</el-alert>
					</el-collapse-transition>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleSubmit('form')">保存</el-button>
					<el-button @click="addEditDialog = false">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-drawer>
</template>

<script>
import { memberList } from "api/business/member/list"
import { shopList, shopInfo } from "api/business/selfManager/shop"
import { addClerk } from "api/business/selfManager/clerk"

// const dataParams = {
// 	id: "",
// 	code: "",
// 	grade: "ASSISTANT",
// 	lat: 0,
// 	lng: 0,
// 	mobile: "",
// 	nickName: "",
// 	realName: "",
// 	shopId: "",
// 	shopName: "",
// 	userId: "",
// 	shopInfo: "",
// 	memberInfo: ""
// }
class DataParams {
	id = ""
	code = ""
	grade = "ASSISTANT"
	lat = 0
	lng = 0
	mobile = ""
	nickName = ""
	realName = ""
	shopId = ""
	shopName = ""
	userId = ""
	shopInfo = ""
	memberInfo = ""
}

export default {
	name: "ClerkAddEdit",

	components: {},

	directives: {},

	data() {
		return {
			title: "新增",
			addEditDialog: false,
			loading: false,

			// dataForm: Object.assign({}, dataParams),
			dataForm: new DataParams(),
			gradeList: [
				{
					label: "店员",
					value: "ASSISTANT"
				}
			],

			rules: {
				grade: [
					{
						required: true
					}
				],
				memberInfo: [
					{
						required: true,
						message: "请选择用户"
					}
				],
				shopId: [
					{
						required: true,
						message: "请选择门店"
					}
				]
			},
			memberList: [], // 会员列表
			shopList: [] // 店铺列表
		}
	},

	mounted() { },

	methods: {
		add() {
			this.addEditDialog = true
			this.$nextTick(() => {
				// this.dataForm = JSON.parse(JSON.stringify(dataParams))
				this.dataForm = new DataParams()
			})

			this.getShopList()
			this.getMemberList()
		},
		edit(record) {
			this.addEditDialog = true
			this.getShopList()
			this.getMemberList()
			this.$nextTick(() => {
				this.dataForm = JSON.parse(JSON.stringify(record))
			})
		},

		getShopList() {
			shopList({
				pageNum: 0,
				pageSize: 1000,
				enabled: true
			}).then(res => {
				this.shopList = res.data.list
			})
		},

		// 组装店铺入参
		shopChange(val) {
			this.dataForm.shopId = val

			shopInfo(this.dataForm.shopId).then(res => {
				this.$set(this.dataForm, "shopInfo", res.data)

				this.dataForm.shopName = this.dataForm.shopInfo.name
				this.dataForm.code = this.dataForm.shopInfo.code
				this.dataForm.enabled = this.dataForm.shopInfo.enabled
				this.dataForm.facadePhoto = this.dataForm.shopInfo.facadePhoto
				this.dataForm.lat = this.dataForm.shopInfo.lat
				this.dataForm.lng = this.dataForm.shopInfo.lng
			})
		},

		getMemberList(mobileOrWechatId = null) {
			memberList({
				mobileOrWechatId,
				pageNum: 0,
				pageSize: 20
			}).then(res => {
				this.memberList = res.data.list
			})
		},

		memberChange(memberInfo) {
			this.dataForm.userId = memberInfo.id
			this.dataForm.nickName = memberInfo.nickName
			this.dataForm.realName = memberInfo.realName
			this.dataForm.mobile = memberInfo.userMobile
		},

		handleSubmit(formName) {
			// console.log(this.dataForm);
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true

					addClerk(this.dataForm)
						.then(() => {
							this.$notify.success({
								title: "成功",
								message: "新增成功"
							})

							this.addEditDialog = false
							this.$emit("saveSuccess")
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
