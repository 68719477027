<template>
	<div>
		<el-drawer title="商品列表" :visible.sync="Model" :append-to-body="true" size="50%">
			<div class="height100" style="padding-bottom: 50px">
				<!-- <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
					<el-table-column slot="head" width="55">
						<template slot-scope="scope">
							<el-radio
								v-model="templateRadio"
								:label="scope.row.key"
								@change.native="getGoodsInfo(scope.row)"
							>
								<span />
							</el-radio>
						</template>
					</el-table-column>
					<template slot="pic" slot-scope="{ row }">
						<el-image style="width: 30px; height: 30px" :src="row.pic" :preview-src-list="[row.pic]" />
					</template>
				</mvk-table> -->
				<advert-broadcast :checked-id.sync="checkedId" @getInfo="changeRoow" />
			</div>
			<div class="custom-drawer-footer">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit">确定</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
export default {
	name: "Model",
	props: {
		checked: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	components: {
		"advert-broadcast": () => import("views/bussiness/marketingManager/advert/components/broadcast.vue")
	},

	directives: {},

	data() {
		return {
			Model: false,
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 50
				},
				{
					prop: "prodName",
					label: "商品名称"
				},
				{
					prop: "pic",
					label: "商品图片",
					slot: true
				},
				{
					prop: "shopName",
					label: "店铺"
				},
				{
					prop: "price",
					label: "商品价格"
				}
			],
			checkedId: "",
			info: ""
		}
	},
	methods: {
		open() {
			this.Model = true
		},

		changeRoow(info) {
			console.log("模态框checkedId", this.checkedId)
			this.info = info
			this.$emit("success", info)
			this.Model = false
		},

		handleSubmit() {
			this.$emit("success", this.info)
			this.Model = false
		}
	}
}
</script>

<style lang="scss" scoped></style>
