<template>
	<!-- 编辑 -->
	<el-drawer
		:title="title"
		:visible.sync="show"
		direction="rtl"
		size="900px"
		:before-close="handleClose"
		:wrapper-closable="false"
	>
		<div class="flex-column height100">
			<el-form ref="couponForm" :model="params" :rules="rules" class="flex-1" label-width="110px">
				<el-form-item label="兑换方式：" prop="mode">
					<el-radio-group v-model="params.mode">
						<el-radio :label="1">积分兑换</el-radio>
						<el-radio :label="2" disabled>积分+现金兑换</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="兑换的商品：" prop="product">
					<el-button v-if="!params.id" @click="changeGood">+ 新增商品</el-button>

					<el-table :data="params.product" style="width: 100%" class="mt10" size="mini" border>
						<!-- <el-table-column type="expand">
                            <template slot-scope="props">
                                {{ props.row }}
                            </template>
                        </el-table-column> -->

						<el-table-column type="expand">
							<template slot-scope="{ row, $index }">
								<el-table :data="row.newcomersSkuList" stripe :rules="childRules">
									<el-table-column
										v-for="col in fixedColumn"
										:key="col.prop"
										:prop="col.prop"
										:label="col.label"
										:width="widthForMinTableRow(col.prop)"
									>
										<template slot-scope="{ row }">
											<el-image
												v-if="col.prop == 'img'"
												style="width: 30px; height: 30px"
												:src="row.img"
												:preview-src-list="[row.img]"
											/>
											<span v-else>
												{{ row[col.prop] }}
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="grantStock" label="发放库存" width="100">
										<template slot-scope="scope">
											<el-input
												v-model="scope.row['grantStock']"
												v-number-input.float="0"
												maxlength="18"
												size="small"
												@input="checkNum($event, scope.row.actualStocks, $index, scope.$index)"
											/>
										</template>
									</el-table-column>
									<!-- 现金+积分 -->
									<!-- <el-table-column prop="integral" label="兑换条件" width="240"> -->
									<!-- 斋积分 -->
									<el-table-column prop="integral" label="兑换条件" width="140">
										<template slot-scope="scope">
											<div class="flex flex-column-center">
												<!-- <el-input
                                                    v-model="scope.row['element']"
                                                    v-number-input.float="0"
                                                    maxlength="18"
                                                    size="small"
                                                />
                                                <span class="input_text">元+</span> -->
												<el-input
													v-model="scope.row['integral']"
													v-number-input.float="0"
													maxlength="18"
													size="small"
												/>
												<span class="input_text">积分</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="操作" width="60">
										<template slot-scope="scope">
											<delete-button
												:product-index="$index"
												:sku="scope"
												:pop-width="230"
												button-type="text"
												size="small"
												@delete="handleSkuDelete"
											/>
										</template>
									</el-table-column>
								</el-table>
							</template>
						</el-table-column>
						<el-table-column property="id" label="ID" align="center" width="60" />

						<el-table-column label="商品图" align="center" width="81">
							<template slot-scope="{ row }">
								<el-image style="width: 60px; max-height: 100px" fit="contain" :src="row.img + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/60x'" />
							</template>
						</el-table-column>
						<el-table-column property="prodName" label="商品名称" />
						<el-table-column property="stock" label="发放总库存数" align="center" width="98" />
						<el-table-column label="操作" align="center" width="60">
							<template slot-scope="{ $index }">
								<el-button type="text" size="small" @click="handleProductDelete($index)">
									删除
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-button v-if="params.id && params.product.length > 0" :disabled="originSKUList.length === params.product[0].newcomersSkuList.length" type="primary" class="mt10" @click="openSKUDialog()">添加产品SKU</el-button>
				</el-form-item>
				<el-form-item label="设置状态：" prop="status">
					<el-radio-group v-model="params.status">
						<el-radio :label="1">立刻上架</el-radio>
						<el-radio :label="0">暂存仓库</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="每人可兑换：" prop="personExchange">
					<el-input-number
						v-model="params.personExchange"
						v-number-input.float="0"
						:min="1"
						:max="100000"
						label="描述文字"
					/>
				</el-form-item>
			</el-form>
			<div>
				<el-button type="primary" :loading="loading" :disabled="params.product.length === 0 || !params.personExchange" @click="save()">
					{{ params.id ? "保存" : title }}
				</el-button>
				<el-button @click="handleClose"> 关闭 </el-button>
			</div>
		</div>
		<div class="dialog-box">
			<el-dialog title="产品规格" :visible.sync="dialogSKU" :modal="false">
				<el-table :data="originSKUList">
					<el-table-column label="商品图" align="center" width="81">
						<template slot-scope="{ row }">
							<el-image style="width: 60px; max-height: 100px" fit="contain" :src="row.img + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/60x'" />
						</template>
					</el-table-column>
					<el-table-column property="skuName" label="SKU名称" width="150" />
					<el-table-column property="actualStocks" label="库存" />
					<el-table-column property="sellingPrice" label="售价" />
					<el-table-column label="操作" width="80">
						<template slot-scope="{ row,$index }">
							<el-button :key="key" :type="row.disabled ? '' : 'primary'" :disabled="row.disabled" @click="addSKU(row,$index)">{{ row.disabled?'已有':'添加' }}</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-dialog>
		</div>
		<goods-list-model
			ref="goodsList"
			:multi="true"
			type="ware"
			:show-is-level="false"
			:show-delivery-mode="false"
			:checked="checkedGoods"
			@getGoodsList="getGoodsItem"
		/>
	</el-drawer>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import * as api from "api/business/marketing/integral.js"
import { countTotal } from "@/utils/utils.js"
import  { prodDetail } from "api/business/selfManager/goods.js"
const defautlParams = {
	id: "",
	mode: 1, // 兑换方式;1-积分兑换;2-积分+现金
	personExchange: 0, // 每人可兑换数
	status: 1, // 状态;0-仓库中;1-已上架;
	stock: 0, //  发放总库存数
	product: [],
	singleProp: false // 规格类型
}
export default {
	props: {
		show: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: "---"
		}
	},

	components: {
		GoodsListModel
	},
	data() {
		return {
			params: JSON.parse(JSON.stringify(defautlParams)),
			rules: {
				mode: [{ required: true }],
				product: [{ required: true }],
				status: [{ required: true }],
				personExchange: [{ required: true, message: "请设置可兑换数" }]
			},
			childRules: {
				grantStock: [{ required: true }],
				integral: [{ required: true }]
			},
			// 添加商品
			fixedColumn: [
				{ prop: "skuId", label: "SKUId" },
				{ prop: "img", label: "图片" },
				{ prop: "skuName", label: "SKU名称" },
				{ prop: "sellingPrice", label: "价格" },
				{ prop: "stock", label: "库存" }
			],
			fullscreenLoading: false,
			checkedGoods: [],
			dialogSKU: false,
			originSKUList: [], // 产品里的原始SKU
			loading: false,
			key: 1 // 解决渲染问题
		}
	},
	methods: {
		widthForMinTableRow(prop) {
			switch (prop) {
				case "skuId":
					return "60"
				case "img":
					return "52"
				case "sellingPrice":
					return "76"
				case "actualStocks":
					return "76"
				default:
					return ""
			}
		},
		// 打开全部SKU
		openSKUDialog() {
			this.dialogSKU = true
			const selectSkuIDList = this.params.product[0].newcomersSkuList.map(e => e.skuId)
			this.originSKUList.forEach(e => {
				if (selectSkuIDList.indexOf(e.id) > -1) {
					e.disabled = true
				} else {
					e.disabled = false
				}
			})
		},
		// 获取产品详情SKU
		getProductDetail(id) {
			prodDetail(id).then(res => {
				if (res.status === "OK") {
					this.originSKUList = res.data.skus
				}
			})
		},
		// 添加SKU
		addSKU(row, index) {
			this.params.product[0].newcomersSkuList.push(this.convertSKU(row))
			this.$set(this.originSKUList[index], "disabled", true)
			// key-changing 解决渲染问题
			this.key++
		},
		// 转换录入的SKU格式
		convertSKU(row) {
			const obj = {
				grantStock: 1,
				integral: 10,
				element: 0,
				img: row.pic,
				price: row.sellingPrice,
				sellingPrice: row.sellingPrice,
				skuId: row.id,
				skuName: row.skuName,
				stock: row.actualStocks,
				properties: row.properties,
				modeNum: row.modeNum
			}
			return obj
		},
		// 关闭窗口
		handleClose() {
			this.params = JSON.parse(JSON.stringify(defautlParams))
			this.$emit("update:show", false)
		},
		// 重置表格数据
		reset() {
			this.params = JSON.parse(JSON.stringify(defautlParams))
		},
		save() {
			let newParams = null
			// ========= 检查SKU兑换与条件 =========
			let errorArr = this.params.product.filter(e => {
				return e.newcomersSkuList.some(e => {
					return e.grantStock < 1 || e.integral < 1
				})
			})
			errorArr = errorArr.map(e => e.id)
			if (errorArr.length > 0) {
				this.$message({
					type: "error",
					message: `商品id:${errorArr.join(",")}的sku项兑换条件数值不符合`,
					duration: 5000
				})
				return
			}
			// ====================================
			this.loading = true
			// 编辑
			if (this.params.id) {
				newParams = {
					id: this.params.id,
					mode: this.params.mode,
					personExchange: this.params.personExchange,
					productId: this.params.product[0].id,
					productImg: this.params.product[0].img,
					productName: this.params.product[0].prodName,
					sku: this.params.product[0].newcomersSkuList,
					status: this.params.status,
					stock: this.params.product[0].stock,
					modeNum: this.params.product[0].modeNum,
					singleProp: this.params.product[0].singleProp
				}
				api.postPonit(newParams).then(e => {
					if (e.status === "OK") {
						this.$message.success("编辑成功")
						this.$emit("getList")
						this.$emit("update:show", false)
					}
				}).finally(() => {
					this.loading = false
				})
			} else {
				// 新增
				newParams = []
				this.params.product.forEach(item => {
					const o = {
						id: "",
						mode: this.params.mode,
						personExchange: this.params.personExchange,
						productId: item.id,
						productImg: item.pic,
						productName: item.prodName,
						sku: [],
						status: this.params.status,
						stock: item.stock,
						totalStocks: item.totalStocks,
						singleProp: item.singleProp
					}
					// 更换新增的格式
					item.skus.forEach(sku => {
						o.sku.push({
							element: sku.element,
							grantStock: Number(sku.grantStock),
							img: sku.pic,
							integral: sku.integral,
							price: sku.sellingPrice,
							skuId: sku.id,
							skuName: sku.skuName,
							stock: sku.actualStocks,
							properties: sku.properties,
							modeNum: sku.modeNum

						})
					})
					newParams.push(o)
				})

				api.addPoint(newParams).then(e => {
					if (e.status === "OK") {
						this.$message.success("新增成功")
						this.$emit("getList")
						this.$emit("update:show", false)
					}
				}).finally(() => {
					this.loading = false
				})
			}
		},

		// 每页显示个数
		handleSizeChange(val) {
			this.pageSize = val
			this.pageNum = 1
			this.getProduct()
		},

		// 增改商品
		changeGood() {
			this.$refs.goodsList.open()
		},
		// 单纯sku删除
		handleSkuDelete(data) {
			let productIndex = data["product-index"]
			let skuIndex = data.sku.$index
			if (this.params.product[productIndex].newcomersSkuList.length === 1) {
				this.handleProductDelete(productIndex)
			} else {
				this.params.product[productIndex].newcomersSkuList.splice(skuIndex, 1)
			}
		},
		// 删除商品
		handleProductDelete(index) {
			this.params.product.splice(index, 1)
		},
		// 选择商品
		getGoodsItem(goodsInfo) {
			let checkedProdIds = this.params.product.map(item => item.id)

			let tempInfo = []

			goodsInfo &&
				goodsInfo.forEach(item => {
					let index = checkedProdIds.indexOf(item.id)
					if (index > -1) {
						if (this.params.product[index].newcomersSkuList.length == item.skus.length) {
							this.$message.warning(`已经选择了ID为${item.id}的商品,已剔除该商品`)
							return
						}
					} else {
						tempInfo.push(item)
					}
				})
			this.params.product = this.params.product.concat(tempInfo)
			// 添加产品，修改符合本页的键值
			// set用于跟踪input
			this.params.product.forEach((item, index) => {
				if (item.skus) {
					item.skus.forEach((sku, i) => {
						this.$set(this.params.product[index].skus[i], "stock", sku.actualStocks) // SKU库存
						this.$set(this.params.product[index].skus[i], "grantStock", 1) // 发放库存
						this.$set(this.params.product[index].skus[i], "integral", 10) // 兑换积分
						this.$set(this.params.product[index].skus[i], "element", 0) // 兑换价格 (二期未落实)
						this.$set(this.params.product[index].skus[i], "modeNum", 0) // 已兑换数
						sku.skuId = sku.id
						sku.img = sku.pic
					})
					this.params.product[index].img = item.pic
					this.$set(this.params.product[index], "newcomersSkuList", item.skus)
				}
				this.$set(this.params.product[index], "stock", countTotal(item.newcomersSkuList, "grantStock"))
			})

		},
		// 限制库存数量，和合计总库存
		checkNum(n, max, i, index) {
			if (n > max) {
				this.$set(this.params.product[i].newcomersSkuList[index], "grantStock", max)
				this.$set(this.params.product[i], "stock", countTotal(this.params.product[i].newcomersSkuList, "grantStock"))

			} else {
				this.$set(this.params.product[i], "stock", countTotal(this.params.product[i].newcomersSkuList, "grantStock"))

			}
		}
	}
}
</script>

<style lang="scss" scoped>
.input_text {
	display: inline-block;
	min-width: 32px;
	width: 32px;
	padding: 0 4px;
}
.mt10{
	margin-top: 10rpx;
}
</style>
