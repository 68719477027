<template>
	<div>
		<div class="flex-row">
			<el-button type="primary" :disabled="tableCheck.length == 0" round @click="handlePriceSetting">
				定价分类设置
			</el-button>
			<el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="商品名称">
					<el-input v-model="queryParam.prodName" placeholder="输入商品名称搜索" clearable />
				</el-form-item>
				<el-form-item label="所属供应商">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable style="width:270px">
						<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15"
			header-cell-class-name="custom-table1" @selection-change="handleSelectionChange">
			<el-table-column slot="head" type="selection" width="55" />
			<el-table-column slot="head" type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.skus" border stripe>
						<el-table-column v-for="col in fixedColumn" :key="col.prop" :prop="col.prop" :label="col.label" :width="col.width" :min-width="col.minWidth">
							<template slot-scope="{ row }">
								<div v-if="col.prop == 'skuName'" class="flex-row">
									<el-image style="min-width: 30px; height: 30px; width: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
										:preview-src-list="[row.pic]" />
									<div style="margin-left: 4px">{{ row.skuName }}</div>
								</div>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<template slot="prodName" slot-scope="{ row }" label="商品图">
				<div class="flex-row">
					<el-image style="min-width: 30px; height: 30px; width: 30px" :src="row.pic+ '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
						:preview-src-list="[row.pic]" />
					<div style="margin-left: 4px">{{ row.prodName }}</div>
				</div>
			</template>
			<el-table-column label="操作" width="100" align="center" header-align="center">
				<template slot-scope="{ row }">
					<router-link v-has="'pms:product:detail'"
						:to="{ path: '/selfManager/creatProduct/' + row.id + '/true/false' }" class="link">
						<el-button type="text" size="small">详情</el-button>
					</router-link>
				</template>
			</el-table-column>
		</mvk-table>
		<!--定价-->
		<price-setting ref="pricesettingRef" @saveSuccess="handleRefresh" />
	</div>
</template>

<script>
import { uninstalledProdList } from "api/business/selfManager/goods.js"

import PriceSetting from "./priceSetting"

import { commonMixin } from "./mixin"

// 仓库中的商品
export default {
	name: "SettingGoods",
	components: {
		PriceSetting
	},
	mixins: [commonMixin], // 搜索条件中的店铺以及供应商逻辑
	data() {
		return {
			tableColumn: [
				{ prop: "id", label: "商品ID", width: "80" },
				// { prop: "pic", label: "商品图", width: "80", slot: true },
				{ prop: "prodName", label: "商品名称", slot: true },
				{
					prop: "deliveryMode",
					label: "配送方式",
					width: 80,
					align: "center",
					formatter: cellValue => {
						switch (cellValue.deliveryMode) {
							case 0:
								return "邮寄"
							case 1:
								return "自提"
							default:
								return "任意"
						}
					}
				},
				{ prop: "supplierName", label: "来源" ,width:240,},
				{
					prop: "supplierName", label: "导入时间",width:160, formatter: cellvalue => {
						const creationData = cellvalue.skus?.[0]?.creationDate
						return creationData
					}
				}
				// {
				// 	prop: "source",
				// 	label: "来源",
				// 	formatter: cellValue => {
				// 		if (cellValue.source === 1) {
				// 			return "云链美物平台"
				// 		} else if (cellValue.source === 2) {
				// 			return "商户平台"
				// 		} else if (cellValue.source === 0) {
				// 			return "自营平台"
				// 		}
				// 	}
				// }
				// { prop: "orderNum", label: "排序", width: "80" }
			],

			queryParam: {
				prodName: "",
				supplierId: ""
			},

			tableDataFunc: parameter => {
				return uninstalledProdList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			fixedColumn: [
				{ prop: "id", label: "SKUId",width: 86 },
				{ prop: "prodName", label: "商品名称" ,minWidth: 240},
				{ prop: "skuName", label: "SKU名称",minWidth: 300 },
				{ prop: "sellingPrice", label: "售价" },
				{ prop: "costPrice", label: "成本价" },
				{ prop: "marketPrice", label: "市场价" },
				{ prop: "internalPrice", label: "内购价" },
				{ prop: "actualStocks", label: "库存" },
				{ prop: "volume", label: "体积" },
				{ prop: "weight", label: "重量" }
			],

			tableCheck: []
		}
	},
	watch: {},
	computed: {},
	methods: {
		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},

		handleSelectionChange(val) {
			this.tableCheck = val
		},

		// 待定价
		handlePriceSetting() {
			this.$refs.pricesettingRef.open(this.tableCheck)
		}
	},
	created() { },
	mounted() { }
}
</script>
<style lang="scss" scoped>
.link+.link {
	margin-left: 8px;
}

.el-form-item--small.el-form-item {
	margin-bottom: 0;
}
</style>
