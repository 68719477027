<template>
    <div class="upLoadPicBox">
        <div class="upLoad" @click="handleInputClick">
            <input ref="uploadImg" type="file" accept="image/*" style="display: none" @change="imgFileChange()">
            <img v-if="imageBase64" :src="imageBase64" style="width: 58px; height: 58px">
            <i v-else class="el-icon-camera cameraIconfont" />
        </div>
    </div>
</template>

<script>
export default {
    name: "InputUploadImage",
    components: {},
    props: {
        imageBase64: {
            type: String
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        // index: {
        //     type: Number | String
        // },
        disabled: {
            type: Boolean
        }
    },
    data() {
        return {
            image_base64: this.imageBase64
        }
    },
    computed: {},
    mounted() {},
    methods: {
        handleInputClick() {
            if (this.disabled) return
            this.$refs.uploadImg.value = null
            this.$refs.uploadImg.click()
        },

        async imgFileChange() {
            var vm = this
            var files = this.$refs.uploadImg.files[0]

            var flag = true

            // 图片大小限制
            if (this.width && this.height) {
                flag = await this.judgeSize(files)
            }

            if (flag) {
                var reader = new FileReader()
                reader.onloadend = function() {
                    vm.image_base64 = reader.result
                    vm.$emit("imageValue", vm.image_base64, vm.index)
                }
                if (files) {
                    reader.readAsDataURL(files)
                }
            }
        },

        async judgeSize(file) {
            var vm = this
            return await new Promise((resolve, reject) => {
                var img = new Image()
                img.src = URL.createObjectURL(file)
                img.onload = function() {
                    if (this.width > vm.width || this.height > vm.height) {
                        vm.$message({
                            type: "warning",
                            message: `请上传像素为${vm.width}*${vm.height}的图片`
                        })
                        reject(false)
                    } else {
                        resolve(true)
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped></style>
