import fetch from "@/axios/fetch"

export function getGroupActivityList (params) { // 拼团列表
	return fetch({
		url: "/groupActivity",
		method: "get",
		params
	})
}

export function saveGroupActivity (data) { // 新增拼团活动
	return fetch({
		url: "/groupActivity",
		method: "post",
		data
	})
}

export function groupActivityDetail (id) { // 拼团活动详情
	return fetch({
		url: "/groupActivity/" + id,
		method: "get",
	})
}

export function groupActivityGoodsDetail (id, params) { // 分页查询团购活动下团购商品
	return fetch({
		url: "/groupActivity/" + id + '/products',
		method: "get",
		params
	})
}

export function deleteGroupActivity (id) { // 删除
	return fetch({
		url: "/groupActivity/" + id,
		method: "delete",
	})
}

export function stopGroupActivity (id, data) { // 暂停
	return fetch({
		url: "/groupActivity/" + id + '/enabled',
		method: "put",
		data
	})
}

export function getGroupActivityOrder (id, params) { // 拼团订单
	return fetch({
		url: "/groupActivity/" + id + '/orders',
		method: "get",
		params
	})
}
