<template>
	<div class="mvk-layout-content-card">
		<div class="add-btn flex-row" style="justify-content: space-between;">
			<el-dropdown v-has="'living:order:export'" @command="batchExport">
				<el-button type="primary" round>批量导出 <i class="el-icon-arrow-down" /></el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item :command="true">导出所有</el-dropdown-item>
					<el-dropdown-item :command="false">导出本页</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>

			<el-form ref="form" :model="queryParam" :inline="true">
				<el-form-item label="订单状态">
					<el-select v-model="queryParam.rechargeState" placeholder="选择订单状态" clearable filterable>
						<el-option v-for="item in orderStatus"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型">
					<el-select v-model="queryParam.projectId" placeholder="选择订单类型" clearable filterable>
						<el-option v-for="item in orderType"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="查询时间">
					<el-date-picker
						v-model="timeVal"
						:default-time="['00:00:00', '23:59:59']"
						value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd"
						size="small"
						type="daterange"
						placement="bottom-end"
						style="width: 220px;"
						@change="changeDate"
					/>
				</el-form-item>
				<el-form-item label="第三方订单编号">
					<el-input v-model="queryParam.billId" placeholder="第三方订单编号" clearable />
				</el-form-item>
				<!-- <el-form-item>
					<el-button type="primary" @click="handleSearch" round>查询</el-button>
				</el-form-item> -->
			</el-form>
		</div>

		<!-- <el-button type="primary" @click="batchExport">批量导出</el-button> -->

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" border class="mt20" @selection-change="handleMultiChange">
			<!-- <el-table-column type="selection" width="55" slot="head"></el-table-column> -->
			<el-table-column label="操作">
				<template slot-scope="{row}">
					<el-button type="text" size="small" @click="handleDetail(row.id)">详情</el-button>
				</template>
			</el-table-column>
		</mvk-table>
		<account-detail ref="detailModel" />
	</div>
</template>

<script>
import { getOrderList, exportDetail } from "api/business/livePay"
export default {
	name: "PaymentDetail",
	components: {
		"account-detail": () => import("./accountDetail.vue")
	},
	data() {
		return {
			orderStatus: [
				{ value: "1", label: "成功" },
				{ value: "0", label: "充值中" },
				{ value: "9", label: "已撤销" },
				{ value: "10", label: "退款中" },
				{ value: "11", label: "退款成功" }
			],
			orderType: [
				{ value: "c2670", label: "水费" },
				{ value: "c2680", label: "电费" },
				{ value: "c2681", label: "煤气" }
			],

			// 查询条件参数
			queryParam: {
				rechargeState: "", // 订单充值状态
				projectId: "", // 订单类型
				startTime: "", // 开始时间
				endTime: "", // 结束时间
				billId: ""// 订单编号
			},
			timeVal: [],
			tableColumn: [
				{ prop: "orderNo", label: "订单号" },
				{ prop: "billId", label: "第三方订单编号" },
				{ prop: "userName", label: "微信昵称" },
				{ prop: "projectId", label: "缴费类型", formatter: value => {
					let map = {
						"c2670": "水费",
						"c2680": "电费",
						"c2681": "气费"
					}
					return map[value.projectId]
				} },
				{ prop: "rechargeAccount", label: "缴费户号" },
				// { prop:'orderNum', label:'缴费单位'},
				// { prop:'orderNum', label:'缴费城市'},
				{ prop: "classType", label: "商品类型", formatter: value => {
					let map = {
						1: "实物商品",
						2: "直充商品",
						3: "卡密商品",
						4: "话费充值",
						6: "支付和金币充值"
					}
					return map[value.classType]
				} },
				{ prop: "itemName", label: "商品名称" },
				{ prop: "itemCost", label: "商品单价" },
				{ prop: "itemNum", label: "商品数量" },
				{ prop: "orderCost", label: "订单金额" },
				{ prop: "rechargeState", label: "订单状态", formatter: value => {
					if (value.rechargeState) {
						let map = {
							0: "充值中",
							1: "成功",
							9: "已撤销",
							10: "退款中",
							11: "退款成功"
						}
						return map[value.rechargeState]
					} else {
						let map = {
							0: "未付款",
							1: "已付款"
						}
						return map[value.payState]
					}

				} },
				{ prop: "orderTime", label: "下单时间" }
			],

			multiChecked: []
		}
	},
	computed: {
		tableDataFunc() {
			let queryParams = JSON.parse(JSON.stringify(this.queryParam))

			return parameter => {
				let params = Object.assign(parameter, queryParams)

				Object.keys(params).forEach(item => {
					if (params[item] == "" && item != "pageNum") {
						// console.log(item);
						delete params[item]
					}
				})

				// console.log(params);

				return getOrderList(params)
					.then(res => {
						return res.data
					})
			}
		}
	},
	mounted() {

	},

	methods: {
		handleSearch() {
			this.$refs.mvkTable.refresh()
		},

		handleMultiChange(val) {
			this.multiChecked = val
		},

		batchExport(val) {
			let temp = JSON.parse(JSON.stringify(this.$refs.mvkTable.params))
			let params = Object.assign(temp, {
				isAll: val
			})

			// console.log(params);

			exportDetail(params).then(res => {
				// console.log(res);
				var blob = new Blob([res], { type: "application/vnd.ms-excel" })
				var downloadElement = document.createElement("a")
				var href = window.URL.createObjectURL(blob) // 创建下载的链接
				downloadElement.href = href
				downloadElement.download = "水电煤订单导出.xls" // 下载后文件名
				document.body.appendChild(downloadElement)
				downloadElement.click() // 点击下载
				document.body.removeChild(downloadElement) // 下载完成移除元素
				window.URL.revokeObjectURL(href) // 释放掉blob对象
			})
		},

		changeDate(val) {
			// console.log(val);
			this.queryParam.startTime = val ? val[0] : ""
			this.queryParam.endTime = val ? val[1] : ""
		},

		handleDetail(id) {
			this.$refs.detailModel.open(id)
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
