<template>
    <div class="mvk-layout-content-card">
        <el-button type="primary" round class="mb15" @click="handleAdd">
            新增油站
        </el-button>
        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button type="text" size="small" @click="handleEdit(row)">编辑</el-button>
                    <delete-button :id="row.id" button-type="text" size="small" @delete="handleDelete">删除</delete-button>
                </template>
            </el-table-column>
        </mvk-table>

        <add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
    </div>
</template>

<script>
import { listMixin } from "views/mixin/listMixin"
import { getStationList, DeleteStation } from "api/business/selfManager/station"
export default {
    name: "StationIndex",
    mixins: [listMixin],
    components: {
        "add-edit-dialog": () => import("./addEdit.vue")
    },

    directives: {  },

    data() {
        return {
            tableColumn: [
                {
                    prop: "id",
                    label: "ID"
                }, {
                    prop: "gsName",
                    label: "名称"
                }, {
                    prop: "gsAddrs",
                    label: "地址"
                }, {
                    prop: "phone",
                    label: "联系电话"
                }, {
                    prop: "gsModel",
                    label: "油号"
                }
            ],
            tableDataFunc: parameter => {
                return getStationList(Object.assign(parameter))
                    .then(res => {
                        return res.data
                    })
            }
        }
    },

    mounted() {

    },

    methods: {
        handleAddOrEditSave() {
            this.$refs.mvkTable.refresh()
        },

        handleDelete(data) {
            DeleteStation(data.id).then(() => {
                this.$notify.success("删除成功")
                this.$refs.mvkTable.refresh()
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
