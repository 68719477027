<template>
    <div class="mvk-layout-content-card">
        <el-form :inline="true" class="mt15">
            <el-form-item label="使用状态：">
                <el-select v-model="queryParam.status" placeholder="请选择使用状态" clearable @change="seachList">
                    <el-option label="已使用" value="0" />
                    <el-option label="未使用" value="1" />
                    <el-option label="已过期" value="-1" />
                </el-select>
            </el-form-item>
            <el-form-item label="领取人：" class="mr10">
                <el-input v-model="queryParam.userName" placeholder="请输入领取人">
                    <el-button slot="append" size="mini" icon="el-icon-search" @click="seachList" />
                </el-input>
            </el-form-item>
            <el-form-item label="优惠劵：">
                <el-input v-model="queryParam.couponName" placeholder="请输入优惠劵" clearable>
                    <el-button slot="append" icon="el-icon-search" @click="seachList" />
                </el-input>
            </el-form-item>
        </el-form>
        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
            <template slot="pic" slot-scope="{row}" label="头像">
                <el-image
                    style="width:30px;height:30px"
                    :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
                    :preview-src-list="[row.pic]"
                />
            </template>

            <template slot="startTime" slot-scope="{row}" label="开始时间">
                {{ row.startTime | formatTime }}
            </template>
            <template slot="endTime" slot-scope="{row}" label="结束时间">
                {{ row.endTime | formatTime }}
            </template>

            <template slot="isAvailable" slot-scope="{row}" label="是否可用">
                <i v-if="row.isAvailable" class="el-icon-check" style="font-size: 14px;color: #0092DC;" />
                <i v-else class="el-icon-close" style="font-size: 14px;color: #ed5565;" />
            </template>
        </mvk-table>
    </div>
</template>

<script>
import { userRecords } from "api/business/marketing/coupon"

export default {
    name: "CouponUserRecord",

    components: {  },

    directives: {  },

    data() {
        return {
            tableColumn: [{
                prop: "couponId",
                label: "优惠券ID"
            }, {
                prop: "couponName",
                label: "优惠券名称"
            }, {
                prop: "userName",
                label: "领取人"
            }, {
                prop: "pic",
                label: "头像",
                slot: true
            }, {
                prop: "faceValue",
                label: "面值"
            }, {
                prop: "minConsume",
                label: "最低消费额"
            }, {
                prop: "startTime",
                label: "开始使用时间"
            }, {
                prop: "endTime",
                label: "结束使用时间"
            }, {
                prop: "obtainMethod",
                label: "获取方式",
                formatter: value => {
                    const statusMap = {
                        "10": "手动领取",
                        "20": "新人券",
                        "30": "赠送券"
                    }

                    return statusMap[value.obtainMethod]
                }
            }, {
                prop: "isAvailable",
                label: "是否可用",
                slot: true
            }, {
                prop: "status",
                label: "使用状态",
                formatter: value => {
                    const statusMap = {
                        "0": "已使用",
                        "1": "未使用",
                        "-1": "已过期"
                    }

                    return statusMap[value.status]
                }
            }],

            queryParam: {
                status: "",
                userName: "",
                couponName: ""
            },

            tableDataFunc: parameter => {
                return userRecords(Object.assign(parameter, this.queryParam))
                    .then(res => {
                        return res.data
                    })
            }
        }
    },

    mounted() {

    },

    methods: {
        seachList() {
            this.$refs.mvkTable.refresh()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
