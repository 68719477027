<template>
	<el-drawer :title="title" :visible.sync="addEditVisible" size="70%" :append-to-body="true">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="活动信息" name="first">
				<editWeRun :weRunId="item.id" @closeDrawer="closeDrawer"/>
			</el-tab-pane>
			<el-tab-pane label="会员信息" name="second" :disabled="isDisabled">
				<weRunMember :weRunId="item.id" />
			</el-tab-pane>
			<el-tab-pane label="运动金" name="third" :disabled="isDisabled">
				<weRunGold :weRunId="item.id" />
			</el-tab-pane>
		</el-tabs>
	</el-drawer>
</template>
<script>
import editWeRun from './editWeRun.vue'
import weRunMember from './weRunMember.vue'
import weRunGold from './weRunGold.vue'
export default {
	name: "weEditDrawer",
	components: {
		editWeRun,
		weRunMember,
		weRunGold
	},
	props: {
		tabsActiveName: {
			type: String,
			default: 'first'
		}
	},
	data() {
		return {
			title: '新增',
			addEditVisible: false,
			isDisabled: true,
			activeName: 'first',
			item: {}
		}
	},
	watch: {
		tabsActiveName(newVal, oldVal) {
			if(newVal && newVal !== oldVal) {
				this.activeName = newVal
			}
		}
	},
	methods: {
        add() {
			this.title = '新增'
            this.addEditVisible = true
			this.item = {}
		},
		edit(item) {
			this.title = '编辑'
			this.isDisabled = false
			this.item = item
			this.addEditVisible = true
		},
		closeDrawer() {
			this.addEditVisible = false
			this.$emit("saveSuccess")
		},
		handleClick(tab, event) {
        	console.log(tab, event);
      	}
	}
}
</script>
<style>
</style>
