<template>
    <div class="mvk-layout-content-card">
        <div v-has="'ums:level:add'" style="display: flex;justify-content:space-between;margin-bottom:15px;">
            <el-button type="primary" round @click="handleAdd">
                新增会员等级
            </el-button>
        </div>

        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
            <template slot="logo" slot-scope="{row}" label="等级图标">
                <el-image
                    style="width:30px;height:30px"
                    :src="row.logo + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
                    :preview-src-list="[row.logo]"
                />
            </template>
            <template slot="display" slot-scope="{row}" label="是否显示">
                <el-switch
                    v-model="row.display" :disabled="!hasBtnPermission('ums:level:display')" @change="handleDisplayChange(row.id)"
                />
            </template>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button v-has="'ums:level:edit'" type="text" size="small" @click="handleEdit(row)">编辑</el-button>
                    <delete-button :id="row.id" v-has="'ums:level:delete'" button-type="text" size="small" delete-text="确定删除吗？所有会员已经关联的数据都会删除" @delete="handleDelete">删除</delete-button>
                </template>
            </el-table-column>
        </mvk-table>

        <add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
    </div>
</template>

<script>
import { levelList, deleteGrade, gradeDisplay } from "api/business/member/grade.js"
import { permissionMixin } from "views/mixin/permissionMixin"
import { listMixin } from "views/mixin/listMixin"
export default {
    name: "MemberGradeIndex",
    mixins: [listMixin, permissionMixin],
    components: {
        "add-edit-dialog": () => import("./addEdit.vue")
    },

    directives: {  },

    data() {
        return {
            tableColumn: [{
                prop: "id",
                label: "ID"
            }, {
                prop: "logo",
                label: "等级图标",
                slot: true
            }, {
                prop: "name",
                label: "等级名称"
            }, {
                prop: "num",
                label: "等级"
            }, {
                prop: "discount",
                label: "享受折扣(%)"
            }, {
                prop: "integral",
                label: "积分值"
            }, {
                prop: "display",
                label: "是否显示",
                slot: true
            }],

            tableDataFunc: parameter => {
                return levelList(Object.assign(parameter))
                    .then(res => {
                        return res.data
                    })
            }
        }
    },

    mounted() {

    },

    methods: {
        handleAddOrEditSave() {
            this.$refs.mvkTable.refresh()
        },

        handleDelete(data) {
            deleteGrade(data.id).then(() => {
                this.$notify.success("删除成功")
                this.$refs.mvkTable.refresh()
            })
        },

        handleDisplayChange(id) {
            gradeDisplay(id).then(() => {
                this.$notify.success("修改成功")
                this.$refs.mvkTable.refresh()
            })
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
