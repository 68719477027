import store from "@/store"
import { throttle } from "@/utils/utils"
const {
	body
} = document
const WIDTH = 992 // refer to Bootstrap's responsive design

export default {
	watch: {
		$route() {
			if (this.device === "mobile" && this.sidebar.opened) {
				store.dispatch("app/closeSideBar", {
					withoutAnimation: false
				})
			}
		}
	},
	beforeMount() {
		window.addEventListener("resize", throttle(this.$_resizeHandler))
	},
	beforeDestroy() {
		window.removeEventListener("resize", throttle(this.$_resizeHandler))
	},
	mounted() {
		const isMobile = this.$_isMobile()
		if (isMobile) {
			store.dispatch("app/toggleDevice", "mobile")
			store.dispatch("app/closeSideBar", {
				withoutAnimation: true
			})
		}
	},
	methods: {
		// use $_ for mixins properties
		// https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
		$_isMobile() {
			const rect = body.getBoundingClientRect()
			return rect.width - 1 < WIDTH
		},
		$_resizeHandler() {
			if (!document.hidden) {
				const isMobile = this.$_isMobile()
				store.dispatch("app/toggleDevice", isMobile ? "mobile" : "desktop")

				if (isMobile) {
					store.dispatch("app/closeSideBar", {
						withoutAnimation: true
					})
				} else {
					var preSidebarStatus = sessionStorage.getItem("mvk-preSidebarStatus")
					if (preSidebarStatus) {
						store.commit("app/SET_SIDEBAR", preSidebarStatus)
					}
				}
			}
		}
	}
}
