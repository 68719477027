<template>
	<el-drawer title="设置发放规则" :visible.sync="show" size="840px" :before-close="handleClose">
		<div class="flex-column flex-sb height100">
			<div>
				<el-form ref="form" size="mini" label-width="140px" :rules="rules" :model="params">
					<el-form-item label="发放规则：" prop="rulesMoney">
						<span class="cr_666">单笔消费金额满</span>
						<el-input
							v-model="params.rulesMoney"
							v-number-input.float="0"
							size="small"
							placeholder=""
							style="width: 90px; margin: 0 10px"
							@focus="foucusInputNum"
							@blur="blurInputNum($event, 1, '元')"
						>
							<template slot="suffix">
								<span style="line-height: 32px; color: #666">元</span>
							</template>
						</el-input>
						<span class="cr_666">发放一张</span>
					</el-form-item>
					<el-form-item label="购买商品获取：" prop="applyNum">
						<el-radio-group v-model="params.buyGoodsType">
							<el-radio :label="1">购买即送</el-radio>
							<el-radio :label="2">购买指定商品</el-radio>
							<el-radio :label="3">购买指定分类</el-radio>
						</el-radio-group>
					</el-form-item>
					<!-- 商品 -->
					<el-form-item v-if="params.buyGoodsType === 2" label="商品：" prop="products">
						<goods-list-model
							ref="goodsList"
							new-model
							show-table
							:selected.sync="selected"
							type="ware"
							:delivery-mode="-1"
						/>
					</el-form-item>
					<!-- 分类 -->
					<el-form-item v-if="params.buyGoodsType === 3" label="分类：" prop="productClassifis">
						<prod-category-tree v-model="params.productClassifis" check-strictly style="width: 200px" />
					</el-form-item>

					<el-form-item label="电子券发放限制：" prop="distrCoupons">
						<span class="cr_666">最多可获得代金劵</span>
						<el-input
							v-model.number="params.distrCoupons"
							placeholder=""
							size="small"
							oninput="value=value.replace(/[^\d^\.]/g,'')"
							style="width: 74px; margin: 0 10px"
							@focus="foucusInputNum"
							@blur="blurInputNum($event, 1, '张')"
						>
							<template slot="suffix">
								<span style="line-height: 32px; color: #666">张</span>
							</template>
						</el-input>
						<!-- <span class="cr_999 fs12">默认0为不限制</span> -->
					</el-form-item>
				</el-form>
				<!-- <span slot="footer" class="dialog-footer">
					<el-button @click="showDialog = false;resetInput('params')">取 消</el-button>
					<el-button type="primary" @click="submit">确 定</el-button>
				</span> -->
			</div>
			<div>
				<el-popover v-model="deleteRuleVisible" placement="top" width="160">
					<p>确认初始化规则吗？</p>
					<div style="margin: 12px">
						<el-button size="mini" type="text" @click="deleteRuleVisible = false">取消</el-button>
						<el-button
							type="danger"
							size="mini"
							@click="reset();deleteRuleVisible = false">确定</el-button>
					</div>
					<!-- <el-button slot="reference">删除</el-button> -->
					<el-button slot="reference" style="width: 120px; margin-right: 12px" type="warning">初始化规则</el-button>
				</el-popover>
				<el-button
					@click="show = false;resetInput('params')">取 消</el-button>
				<el-button type="primary" :disabled="loading" @click="submit()">确 定</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import GoodsListModel from "@/views/bussiness/components/GoodsListModel.vue"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import { ecouponRules, ecouponRulesEdit, resetRules } from "api/business/marketing/coupon"
const defaultParams = {
	id: null,
	ecouponTypeAlias: "", // 电子券类型别名

	distrRules: 1, // 发放规则;1-满多少元使用
	rulesMoney: 0, // 发放规则满多少元使用;如果distr_rules为1时取此值

	buyGoodsType: 1, // 购买商品方式;1- 购买即送;2-购买指定商品;3- 购买指定分类 ,
	products: "", // 商品ids;当buy_goods_type为2时，取此字段值
	productClassifis: "", // 商品分类ids;当buy_goods_type为3时，取此字段值

	distrRestrictions: 1, // 发放限制;1-最多可获得代金劵多少张
	distrCoupons: 1 // 发放张数;当distr_restrictions为1时取此值
}
export default {
	components: { ProdCategoryTree, GoodsListModel },
	data() {
		return {
			show: false,
			params: JSON.parse(JSON.stringify(defaultParams)),
			shopList: [],

			rules: {
				rulesMoney: [{ required: true, message: "单笔消费金额必须填", trigger: "blur" }]
			},
			selected: [],
			loading: false,

			// 初始化窗口
			deleteRuleVisible: false
		}
	},
	mounted() {
		// this.$nextTick(() => {
		// 	this.$refs.goodsList.open()
		// })
	},
	watch: {
		selected: {
			handler(newIdAry) {
				this.shopList = newIdAry
			},
			deep: true
		}
	},
	methods: {
		handleClose() {
			this.show = false
		},
		handleOpen(row) {
			// this.params.id = row.id
			// const ruleId = this.geteCouponRules(row.id)
			// console.log("ruleId", ruleId)
			this.params = JSON.parse(JSON.stringify(defaultParams))
			this.geteCouponRules(row.alias)
			this.params.ecouponTypeAlias = row.alias
			this.show = true
		},
		// 确定
		submit() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					this.params.products = this.selected.join()
					// console.log(this.params)
					// return
					switch (this.params.buyGoodsType) {
						case 0:
							this.params.products = ""
							this.params.productClassifis = ""
							break
						case 1:
							this.params.products = ""
							this.params.productClassifis = ""
							break
						case 2:
							this.params.productClassifis = ""
							break
						case 3:
							this.params.products = ""
							break
						default:
							break
					}
					// if (!this.params.distrCoupons) {
					// 	this.$message.error("请输入正确的数值")
					// 	return
					// }
					ecouponRulesEdit(this.params).then(() => {
						this.$emit("success")
						this.show = false
						this.$notify.success({
							title: "成功",
							message: "保存成功"
						})
					})
				}
			})
		},
		geteCouponRules(alias) {
			this.loading = true
			ecouponRules(alias)
				.then((res) => {
					if (res.status === "OK") {
						const { id, rulesMoney, distrCoupons, buyGoodsType, products, productClassifis } = res.data
						this.params.id = id
						this.params.buyGoodsType = buyGoodsType
						this.params.rulesMoney = rulesMoney
						this.params.distrCoupons = distrCoupons

						if (buyGoodsType == 2) this.selected = products.split(",")
						if (buyGoodsType == 3) this.params.productClassifis = Number(productClassifis)
					}
				})
				.finally(() => {
					this.loading = false
				})
			// return await new Promise((resolve, reject) => {
			// 	ecouponRules(id).then(res => {
			// 		resolve(res.data.id)
			// 	}).catch(() => {
			// 		reject()
			// 	}).finally(() => {
			// 		this.loading = false
			// 	})
			// })
		},
		// 清除输入框
		resetInput(obj) {
			Object.keys(this[obj]).forEach((key) => (this[obj][key] = ""))
		},
		foucusInputNum(e) {
			const value = e.currentTarget.value
			if (value === "0" || value === "") {
				e.currentTarget.value = ""
				return ""
			} else {
				return value
			}
		},
		blurInputNum(e, min = 1, unit = "") {
			const value = e.currentTarget.value
			const reg = /^[+-]?((\d*((\.\d{0,})$)|(\d+$)))/
			if (value < min) {
				this.$message.error(`输入数值不能低于${min}${unit}`)
				e.currentTarget.value = 0
			} else if (!reg.test(value)) {
				this.$message.error("请输入正确的数值")
				e.target.focus()
				return (e.currentTarget.value = 1)
			}
		},
		zeroFormatter(value) {
			if (value === "0" || value === "") {
				return value
			}
			return parseInt(value)
		},
		reset() {
			resetRules(this.params.ecouponTypeAlias).then((res) => {
				if (res.status === "OK") {
					this.$message.success("成功初始化")
					this.deleteRuleVisible = false
					this.show = false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
