<template>
    <el-drawer :title="title" :visible.sync="addEditDialog" size="74%">
        <div class="drawer-content">
            <el-form ref="dataForm" :model="dataForm" :rules="rules" label-width="180px" :inline="false">
                <el-form-item label="规则名称" prop="filterName">
                    <mvk-input v-model.trim="dataForm.filterName" class="inputWidth" />
                </el-form-item>
                <el-form-item label="是否启用" prop="isValid">
                    <el-switch v-model="dataForm.isValid" :active-value="1" :inactive-value="0" />
                </el-form-item>
                <el-form-item label="实体对象" prop="tableVo">
                    <el-select
                        v-model="dataForm.tableVo"
                        value-key="tableName"
                        placeholder="实体对象"
                        filterable
                        class="inputWidth"
                        @change="tableChange"
                    >
                        <el-option
                            v-for="item in tablelist"
                            :key="item.tableName"
                            :label="item.tableComment"
                            :value="item"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="规则说明" prop="filterItems">
                    <el-table :data="dataForm.filterItems" border stripe>
                        <el-table-column prop="logic" label="逻辑">
                            <template slot-scope="scope">
                                <el-form-item :prop="`filterItems.${scope.$index}.logicInfo`" :rules="rules.logicInfo">
                                    <el-select
                                        v-model="scope.row.logicInfo"
                                        value-key="value"
                                        placeholder="逻辑"
                                        @change="changeLogic(scope.$index)"
                                    >
                                        <el-option
                                            v-for="item in logicList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item"
                                        />
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldCode" label="字段" width="350">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="`filterItems.${scope.$index}.columnInfo`"
                                    :rules="rules.columnInfo"
                                >
                                    <el-select
                                        v-model="scope.row.columnInfo"
                                        v-loading="loading"
                                        value-key="columnName"
                                        placeholder="字段"
                                        style="width: 100%"
                                        no-data-text="请先选择实体对象"
                                        @change="changeColumn(scope.$index)"
                                    >
                                        <el-option
                                            v-for="item in columnList"
                                            :key="item.columnName"
                                            :label="item.columnName"
                                            :value="item"
                                        >
                                            <span style="float: left">{{ item.columnName }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{
                                                item.columnComment
                                            }}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="whereType" label="条件">
                            <template slot-scope="scope">
                                <el-form-item :prop="`filterItems.${scope.$index}.whereType`" :rules="rules.whereType">
                                    <el-select v-model="scope.row.whereType" placeholder="条件">
                                        <el-option
                                            v-for="item in filterCondition"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldValue" label="值">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="`filterItems.${scope.$index}.fieldValue`"
                                    :rules="rules.fieldValue"
                                >
                                    <el-input v-model="scope.row.fieldValue" placeholder="值" />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button type="primary" icon="el-icon-plus" circle @click="addColumn" />
                                <el-button
                                    type="danger"
                                    icon="el-icon-delete"
                                    circle
                                    @click="deleteColumn(scope.$index)"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="handleSubmit('dataForm')">保存</el-button>
                    <el-button @click="addEditDialog = false">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex"

import { permissionColumnList, addPermission, editPermission, permissionDetail } from "api/basePage/permission"

import { isJson } from "@/utils/utils"

const columnItem = {
    id: "",
    filterId: "",
    tableName: "",
    tableDesc: "",
    logic: 0,
    logicName: "",
    fieldCode: "",
    fieldName: "",
    dataType: "",
    fieldType: "",
    whereType: "",
    fieldValue: "",

    logicInfo: "",
    columnInfo: ""
}

export default {
    name: "PermissionAddEdit",

    components: {},

    computed: {
        ...mapState("dict", ["tablelist"])
    },
    data() {
        return {
            title: "新增",
            addEditDialog: false,
            loading: false,

            dataForm: {
                id: "",
                filterName: "",
                isValid: 1,
                filterItems: [
                    {
                        id: "",
                        filterId: "",
                        tableName: "", // 表名key
                        tableDesc: "", // 表名描述
                        logic: 0, // 逻辑value
                        logicName: "", // 逻辑名称
                        fieldCode: "", // 字段key
                        fieldName: "", // 字段名称
                        dataType: "", // 字段类型
                        fieldType: "",
                        whereType: "", // 条件value
                        fieldValue: "", // 值

                        logicInfo: "",
                        columnInfo: ""
                    }
                ],
                remark: "",
                tableVo: "" // 表格对象
            },
            rules: {
                filterName: [
                    {
                        required: true,
                        message: "请输入规则名称",
                        trigger: "change"
                    }
                ],
                tableVo: [
                    {
                        required: true,
                        message: "请选择实体对象",
                        trigger: "change"
                    }
                ],
                logicInfo: [
                    {
                        required: true,
                        message: "请选择逻辑",
                        trigger: "change"
                    }
                ],
                columnInfo: [
                    {
                        required: true,
                        message: "请选择字段",
                        trigger: "change"
                    }
                ],
                whereType: [
                    {
                        required: true,
                        message: "请选择条件",
                        trigger: "change"
                    }
                ],
                fieldValue: [
                    {
                        required: true,
                        message: "请输入值",
                        trigger: "blur"
                    }
                ]
            },

            logicList: [
                { value: 0, label: "或者" },
                { value: 1, label: "并且" }
            ],
            filterCondition: [
                { value: 1, label: "等于" },
                { value: 2, label: "不等于" },
                { value: 3, label: "为空" },
                { value: 4, label: "不为空" },
                { value: 5, label: "小于" },
                { value: 6, label: "小于等于" },
                { value: 7, label: "大于" },
                { value: 8, label: "大于等于" },
                { value: 9, label: "不包含" },
                { value: 10, label: "包含" },
                { value: 11, label: "左包含" },
                { value: 12, label: "右包含" },
                { value: 13, label: "包括" }
            ],

            columnList: []
        }
    },

    mounted() {
        if (this.tablelist.length == 0) {
            this.getTableList()
        }
    },

    methods: {
        ...mapActions("dict", ["getTableList"]),

        add() {
            this.addEditDialog = true
            this.$nextTick(() => {
                this.dataForm = {
                    id: "",
                    filterName: "",
                    isValid: 1,
                    filterItems: [
                        {
                            id: "",
                            filterId: "",
                            tableName: "",
                            tableDesc: "",
                            logic: 0,
                            logicName: "",
                            fieldCode: "",
                            fieldName: "",
                            dataType: "",
                            fieldType: "",
                            whereType: "",
                            fieldValue: "",

                            logicInfo: "",
                            columnInfo: ""
                        }
                    ],
                    remark: "",
                    tableVo: ""
                }
            })
        },
        edit(record) {
            this.addEditDialog = true
            this.getDetail(record.id)
        },

        getDetail(id) {
            permissionDetail(id).then(res => {
                this.dataForm = res.data

                let tableVo = {}
                this.dataForm.filterItems.forEach(item => {
                    let logicInfo = {
                        value: item.logic,
                        label: item.logicName
                    }
                    this.$set(item, "logicInfo", logicInfo)

                    let columnInfo = {
                        columnName: item.fieldCode,
                        columnComment: item.fieldName,
                        dataType: item.dataType
                    }
                    this.$set(item, "columnInfo", columnInfo)

                    tableVo.tableName = item.tableName
                    tableVo.tableComment = item.tableDesc
                })

                this.getColumnList(tableVo.tableName)

                this.$set(this.dataForm, "tableVo", tableVo)
            })
        },

        tableChange(val) {
            if (val.tableName) {
                this.getColumnList(val.tableName)

                var temp = []
                temp.push(JSON.parse(JSON.stringify(columnItem)))
                this.dataForm.filterItems = temp
            }
        },

        changeLogic(index) {
            this.dataForm.filterItems[index].logic = this.dataForm.filterItems[index].logicInfo.value
            this.dataForm.filterItems[index].logicName = this.dataForm.filterItems[index].logicInfo.label
        },

        changeColumn(index) {
            this.dataForm.filterItems[index].fieldCode = this.dataForm.filterItems[index].columnInfo.columnName
            this.dataForm.filterItems[index].fieldName = this.dataForm.filterItems[index].columnInfo.columnComment
            this.dataForm.filterItems[index].dataType = this.dataForm.filterItems[index].columnInfo.dataType
        },

        getColumnList(tableName) {
            (this.loading = true),
            permissionColumnList({
                table: tableName
            })
                .then(res => {
                    this.columnList = res
                })
                .finally(() => {
                    this.loading = false
                })
        },

        addColumn() {
            this.dataForm.filterItems.push(JSON.parse(JSON.stringify(columnItem)))
        },

        deleteColumn(index) {
            this.dataForm.filterItems.splice(index, 1)
        },

        handleSubmit(name) {
            this.dataForm.filterItems.forEach(item => {
                if (isJson(this.dataForm.tableVo)) {
                    item.tableName = this.dataForm.tableVo.tableName
                    item.tableDesc = this.dataForm.tableVo.tableComment
                }
            })

            this.$refs[name].validate(valid => {
                if (valid) {
                    this.dataForm.id
                        ? editPermission(this.dataForm)
                            .then(() => {
                                this.$notify.success({
                                    title: "成功",
                                    message: "编辑成功"
                                })
                                this.loading = false
                                this.addEditDialog = false
                                this.$emit("saveSuccess")
                            })
                            .catch(() => {
                                this.loading = false
                            })
                        : addPermission(this.dataForm)
                            .then(() => {
                                this.loading = false
                                this.$notify.success({
                                    title: "成功",
                                    message: "添加成功"
                                })
                                this.addEditDialog = false
                                this.$emit("saveSuccess")
                            })
                            .catch(() => {
                                this.loading = false
                            })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped></style>
