<template>
	<div class="mvk-layout-content-card">
		<el-form ref="form" :model="form" :rules="rules" label-width="180px" :inline="false">
			<el-form-item label="首页广告" prop="open">
				<el-radio-group v-model="form.open">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="广告形式" prop="type">
				<el-radio-group v-model="form.type">
					<el-radio :label="1">文本</el-radio>
					<el-radio :label="2">图片</el-radio>
				</el-radio-group>
			</el-form-item>
			<template v-if="form.type == 1">
				<el-form-item label="文本标题" prop="title">
					<el-input v-model="form.title" class="inputWidth" maxlength="17" show-word-limit clearable />
				</el-form-item>
				<el-form-item label="文本内容" prop="content">
					<el-input
						v-model="form.content"
						type="textarea"
						:rows="4"
						placeholder="请输入内容"
						maxlength="200"
						show-word-limit
						class="inputWidth"
					/>
				</el-form-item>
			</template>
			<template v-else-if="form.type == 2">
				<div v-for="(item, index) in advertItemList" :key="index" class="box">
					<el-form-item label="上传图片" :prop="`advertItemList[${index}].pic`" :rules="rules.pic">
						<upload-image ref="imageModel" v-model="item.pic" :image-url="item.pic" />
						<span style="color: #9e9e9e">建议上传尺寸为600*780的图片</span>
					</el-form-item>
					<el-form-item label="广告链接" :prop="`advertItemList[${index}].refType`" :rules="rules.refType">
						<div class="flex flex-column-center">
							<el-button type="primary" size="default" plain @click="handleOpenLink(index)">
								选择链接
							</el-button>
							<div style="margin-left: 8px">
								已选：{{ linkTypeArray[item.refType] }}
								<span v-show="item.refNo != ''">| {{ item.refDesc }}</span>
							</div>
						</div>
					</el-form-item>
					<el-button v-if="advertItemList.length > 1" type="text" class="del-btn" @click="handleDelete(index)">
						删除
					</el-button>
				</div>
				<div>
					<el-button v-show="advertItemList.length < 5" type="primary" @click="handleAdd">
						新增广告图片
					</el-button>
					<span style="display: inline-block; margin-left: 8px; color: #9e9e9e; font-size: 14px">最多只能新增5张广告图</span>
				</div>
			</template>

			<el-form-item>
				<el-button v-throttle=" onSubmit " type="primary">保存</el-button>
			</el-form-item>
		</el-form>
		<advert-link ref="link" @linkInfo=" getLinkInfo " />
	</div>
</template>

<script>
import UploadImage from "views/bussiness/components/UploadImage"
import AdvertLink from "./link"
import { getAdvertSetting, saveAdvertSetting } from "api/business/marketing/advert"
import monaco from "components/wangEditor"

export default {
	name: "AdvertIndex",
	components: {
		UploadImage,
		AdvertLink,
		monaco
	},
	data() {
		return {
			form: {
				open: true,
				type: 1,
				title: "",
				content: "",
				advertItemList: [],
				deleteIds: []
			},
			rules: {
				open: [
					{
						required: true,
						message: "请选择首页广告",
						trigger: "change"
					}
				],
				type: [
					{
						required: true,
						message: "请选择广告形式",
						trigger: "change"
					}
				],
				title: [
					{
						required: true,
						message: "请输入文本标题",
						trigger: "blur"
					}
				],
				content: [
					{
						required: true,
						message: "请输入文本内容",
						trigger: "blur"
					}
				],
				pic: [
					{
						required: true,
						message: "请选择图片",
						trigger: "change"
					}
				],
				refType: [
					{
						required: true,
						message: "请选择广告链接",
						trigger: "change"
					}
				]
			},
			advertItemList: [
				{
					id: "",
					pic: "",
					refType: "",
					refNo: ""
				}
			],
			linkIndex: 0,
			linkTypeArray: [
				"无链接",
				"商品链接",
				"商品分类链接",
				"秒杀活动",
				"拼团活动",
				"生活缴费",
				"直播间",
				"新人专享",
				"满减活动"
			]
		}
	},

	mounted() {
		this.getSetting()
	},

	methods: {
		// typeChange(val) {
		// 	if()
		// },
		handleAdd() {
			this.advertItemList.push({
				id: "",
				pic: "",
				refType: "",
				refNo: ""
			})
			this.$set(this.form, "advertItemList", this.advertItemList)
		},
		handleOpenLink(index) {
			this.linkIndex = index
			this.$refs.link.open(this.advertItemList[index])
		},

		getSetting() {
			getAdvertSetting().then(res => {
				this.form = Object.assign(this.form, res.data)
				if (this.form.advertItemList.length == 0) {
					this.form.advertItemList.push({ id: "", pic: "", refType: "" })
				} else {
					this.advertItemList = JSON.parse(JSON.stringify(this.form.advertItemList))
				}
			})
		},

		onSubmit() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					if (this.form.type == 1) {
						this.form.advertItemList = []
					} else if (this.form.type == 2) {
						this.form.title = ""
						this.form.content = ""
						this.form.advertItemList = this.advertItemList
					} 
					saveAdvertSetting(this.form).then(() => {
						this.$notify.success({
							title: "保存成功"
						})

						this.getSetting()
					})
				} else {
					return false
				}
			})
		},
		editorContent(v) {
			this.form.content = v
		},
		getLinkInfo(linkInfo) {
			let array = JSON.parse(JSON.stringify(this.advertItemList))
			let temp = array[this.linkIndex]
			array[this.linkIndex] = Object.assign(temp, linkInfo)
			console.log(array)
			this.$set(this, "advertItemList", array)
			this.$set(this.form, "advertItemList", array)
		},

		handleDelete(index) {
			let temp = this.advertItemList[index]
			if (temp.id) {
				this.form.deleteIds.push(temp.id)
			}

			this.advertItemList.splice(index, 1)
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
	border: 1px solid #eee;
	padding: 8px 0;
	margin: 8px 0;
	position: relative;

	.del-btn {
		position: absolute;
		top: 40%;
		right: 8px;
		color: red;
	}
}
</style>
