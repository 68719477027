<template>
	<div class="mvk-layout-content-card">
		<div class="flex">
			<el-button type="primary" class="mb20" @click="handleAdd"> 新增抽奖活动 </el-button>
		</div>
		<div v-if="prizes.length>0">
			<div class="mb10 cr_333">
				<span>奖品设置：</span>
			</div>
			<div class="flex width100">
				<!-- 奖品选择 -->
				<div class="luck-draw">
					<el-row v-for="(row, index) in luckDrawLayout" :key="index">
						<el-col v-for="(item, i) in row" :key="i" class="grid-content bg" :span="item.span"
							:offset="item.offset"
						>
							<div class="item" :class="{'active': currentSelection.sort==item.sort}" @click="selectPrizeSeat(item.sort)">{{ item.sort }}</div>
						</el-col>
					</el-row>
					<div class="mt10">
						<el-button type="success" @click="setPrizes()">保存</el-button>
						<el-button type="default" style="margin-left:30px" @click="closePrizes()">关闭</el-button>
					</div>
				</div>
				<!-- 填写 -->
				<div v-if="currentSelection.sort > 0" style="flex: 1 0 auto" class="ml20">
					<el-form ref="prizeForm" :model="currentSelection" :rules="prizeRule" label-width="100px" class="prizeForm" size="mini">
						<div class="flex">
							<div>
								<el-form-item label="序号" prop="sort">
									{{ currentSelection.sort }}
								</el-form-item>
								<el-form-item label="奖品类型" prop="type">
									<el-select v-model="currentSelection.type" clearable placeholder="请选择" style="width: 140px"
										@change="openTypePrize"
									>
										<el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
											:value="item.value"
										/>
									</el-select>
									<el-button type="primary" class="ml10" :disabled="currentSelection.type == 5" @click="openTypePrize(currentSelection.type)">产品</el-button>
								</el-form-item>
								<el-form-item label="奖品名称" prop="name">
									<el-input v-model="currentSelection.name" style="width: 210px" />
								</el-form-item>

								<el-form-item v-show="currentSelection.prizeId" label="奖品商品" prop="prizeId">
									<div class="ml10">{{ currentSelection.prizeId }}</div>
								</el-form-item>
							</div>
							<div class="ml10">
								<el-form-item v-if="currentSelection.type ==3 || currentSelection.type == 4" label="优惠券面额" prop="faceValue">
									<el-input v-model="currentSelection.faceValue" style="width: 100px" />
									<span class="cr_999 fs12 ml10">奖品轮盘产品图中的数值</span>
								</el-form-item>
								<el-form-item label="中奖概率" prop="probability">
									<mvk-input-num
										v-model="currentSelection.probability"
										:min="0.01"
										:max="0.99"
										size="small"
										clearable
										style="width: 100px"
									/>
									<!-- <el-input v-model="currentSelection.probability" style="width: 80px" /> -->
									<span class="cr_999 fs12 ml10"> {{ currentSelection.probability * 100 }}% 说明：奖品概率： 0.99 ~ 0.01</span>
								</el-form-item>
								<el-form-item v-if="currentSelection.type == 2 || currentSelection.type == 1" label="奖品图片" prop="pic">
									<!-- <el-input v-model="currentSelection.pic" style="width: 180px" /> -->
									<upload-image
										ref="imageDragModel"
										v-model="currentSelection.pic"
										:image-url="currentSelection.pic"
										:preview="false"
										:limit="1"
									/>
								</el-form-item>

								<!-- <el-form-item label="描述" prop="faceValue">
									<el-input v-model="currentSelection.remarks" :maxlength="120" />
								</el-form-item> -->
							</div>
						</div>
					<!-- <el-form-item label="序号" prop="name">
								{{ currentSelection.sort }}
							</el-form-item>
							<el-form-item label="奖品类型：" style="min-width: 120px">
								<el-select v-model="currentSelection.type" clearable placeholder="请选择" @change="changeType">
									<el-option
										v-for="item in typeOptions"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									/>
								</el-select>
							</el-form-item>
							<el-form-item label="奖品名称" prop="name">
								<el-input v-model="currentSelection.name" />
							</el-form-item> -->
					</el-form>
				</div>
			</div>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt20">
			<template slot="enabled" slot-scope="{ row }">
				<el-switch v-model="row.enabled" :disabled="loadingStartStop" active-color="#4F8AFF" inactive-color="#ccc" @change="changeSwitch(row)" />
			</template>
			<el-table-column label="操作" width="220" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" style="margin-right:20px" @click="handleSet(row)"> 设置奖品 </el-button>
					<el-button type="text" size="small" style="margin-right:20px" @click="handleEdit(row)"> 修改 </el-button>
					<!-- <el-popconfirm title="确定要删除该活动吗？" @confirm="handleDel(row)">
						<el-button slot="reference" type="danger" size="mini" style="margin-left: 10px;"> 删除 </el-button>
					</el-popconfirm> -->
				</template>
			</el-table-column>
		</mvk-table>
		<add-edit :id="rowId" ref="addRef" v-model="show" @success="getTableData()" />
		<set-prize ref="prizeRef" v-model="showPrize" :type.sync="currentPrizeType" @submit="changeTypePrize($event)" />
	</div>
</template>

<script>
import * as api from "api/business/selfManager/lottery"
import AddEdit from "./addEdit.vue"
import setPrize from "./setPrize.vue"
import UploadImage from "views/bussiness/components/UploadImage"

export default {
	components: {
		AddEdit, setPrize, UploadImage
	},
	data() {
		return {
			show: false, // 新增/编辑活动
			rowId: null, // 操作活动Id
			showPrize: false, // 设置奖品开关
			currentPrizeType: null, // 设置奖品类型
			lotteryDrawId: 0, // 活动ID - 用于设置活动
			date: [],
			timeVal: [],
			loading: false,
			loadingStartStop: false, // 启停

			lotteryData: {
				// "createdBy": 1,
				// "lastModifiedBy": 1,
				// "id": 1,
				// "name": "默认",
				// "startTime": "2023-01-30 00:00:00",
				// "endTime": "2023-12-30 23:59:59",
				// "enabled": false
			},
			luckDrawLayout: [
				[{ sort: 1, span: 8 }, { sort: 2, span: 8 }, { sort: 3, span: 8 }],
				[{ sort: 8, span: 8 }, { sort: 4, span: 8, offset: 8 }],
				[{ sort: 7, span: 8 }, { sort: 6, span: 8 }, { sort: 5, span: 8 }]
			],
			prizes: [],
			currentSelection: {},
			prizeRule: {
				pic: [{ required: true, message: "请上传奖品图", trigger: "change" }]
			},
			// 奖品类型 1.虚拟商品 2.指定商品优惠券 3.通用优惠券 4.电子券 5.谢谢参与
			typeOptions: [
				{ label: "虚拟商品", value: 1 },
				{ label: "指定商品优惠券", value: 2 },
				{ label: "通用优惠券", value: 3 },
				{ label: "电子券", value: 4 },
				{ label: "谢谢参与", value: 5 }
			],
			tableColumn: [
				{
					// 活动ID
					prop: "id",
					label: "ID",
					width: 60
				},
				{
					// 活动名称
					prop: "name",
					label: "活动名称"
				},
				{
					prop: "startTime",
					label: "开始时间"
				},
				{
					prop: "endTime",
					label: "结束时间"
				},
				{
					prop: "enabled",
					label: "是否启用",
					slot: true,
					width: 86
				}
			],
			queryParam: {},
			tableDataFunc: () => {
				return api.lotteryList(Object.assign(this.queryParam)).then(res => {
					return res.data
				})
			}

		}
	},
	created() {
		// this.getlottery()
		// this.initPrize()
	},
	methods: {
		getTableData() {
			console.log("刷新表格")
			this.$refs.mvkTable.refresh()
		},
		// 获取抽奖列表中第一个作为单一活动内容
		getlottery() {
			return new Promise((resolve, reject) => {
				api.lotteryList().then(res => {
					if (res.status === "OK" && res.data.list.length) {
						const obj = res.data.list[0]
						const { id, enabled, startTime, endTime } = obj
						this.lotteryData = { id, enabled, startTime, endTime }
						this.timeVal = [this.lotteryData.startTime, this.lotteryData.endTime]
						// this.getLuckDrawDetail()
						resolve()
					}
				}).catch(() => {
					reject()
				})
			})
		},
		// 启用控制
		// changeLotteryOpen() {
		// console.log(this.lotteryData)
		// this.loading = true
		// this.submitLottery().finally(() => {
		// 	this.getlottery().finally(() => {
		// 		setTimeout(() => {
		// 			this.loading = false
		// 		}, 500)
		// 	})
		// })
		// },
		// 打开对应奖品选择
		openTypePrize(type) {
			if (!type) return
			this.currentPrizeType = type
			if (type == 5) return
			this.showPrize = true
		},
		// 更改奖品类型
		changeTypePrize(row) {
			switch (this.currentPrizeType) {
				case 1:
					console.log("设置虚拟商品")
					this.currentSelection.prizeId = row.id.toString()
					this.currentSelection.name = row.parentProductName
					this.currentSelection.faceValue = row.marketPrice
					this.currentSelection.pic = row.pic
					break
				case 2:
					console.log("指定商品优惠券")
					this.currentSelection.prizeId = row.id.toString()
					this.currentSelection.name = row.name
					this.currentSelection.faceValue = row.faceValue
					break
				case 3:
					console.log("通用优惠券")
					this.currentSelection.prizeId = row.id.toString()
					this.currentSelection.name = row.name
					this.currentSelection.faceValue = row.faceValue
					break
				case 4:
					console.log("电子券")
					this.currentSelection.prizeId = row.alias
					this.currentSelection.name = row.title
					this.currentSelection.faceValue = row.faceValue
					break
				default:
					console.log("谢谢参与")
					break
			}
		},
		// 增加活动
		handleAdd() {
			this.rowId = null
			this.show = true
		},

		// 打开设置奖品
		handleSet(row) {
			this.currentSelection = {}
			this.rowId = row.id
			this.getPrizes(row.id)
		},
		handleEdit(row) {
			console.log("修改", row)
			this.rowId = row.id
			this.show = true
		},
		// handleDel(row) {
		// 	console.log("删除", row)
		// },
		// 启停
		changeSwitch(row) {
			this.loadingStartStop = true
			api.lotteryOpen(row.id).then(r => {
				console.log(r)
				if (r.status == "OK") {
					this.$message.success("修改成功")
				}
			}).catch(err => console.log(err)).finally(() => {
				setTimeout(() => {
					this.loadingStartStop = false
				}, 500)
			})
		},
		// 改变活动日期
		onchangeTime(e) {
			this.timeVal = e
			this.date = e || ""
			this.$set(this.lotteryData, "startTime", e ? e[0] : "")
			this.$set(this.lotteryData, "endTime", e ? e[1] : "")
		},
		// 获取抽奖奖品

		getPrizes(id) {
			return new Promise((resolve, reject) => {
				api.getPrizes(id).then(res => {
					this.prizes = []
					if (res.status == "OK" && res.data.length == 0) {
						this.initPrize(id)
					} else {
						this.prizes = res.data.slice(0, 8)
					}
					resolve(res)
				}).catch(() => {
					this.prizes = []
					reject()
				})
			})
		},
		// 关闭设置
		closePrizes() {
			this.rowId = null
			this.prizes = []
			this.currentSelection = {}
		},
		// 设置抽奖奖品
		setPrizes() {
			// console.log("设置奖品", JSON.parse(JSON.stringify(this.prizes)))
			const prizes = JSON.parse(JSON.stringify(this.prizes))
			prizes.forEach(e => { e.faceValue = e.faceValue ? e.faceValue.toString() : "" })

			// return
			return new Promise((resolve, reject) => {
				api.setPrizes(prizes).then(res => {
					if (res.status == "OK") {
						this.$message.success("修改成功")
						this.prizes = []
						resolve()
					} else {
						reject()
					}
				}).catch(() => {
					reject()

				})
			})
		},

		// 保存
		submitLottery() {
			const params = this.lotteryData
			return new Promise((resolve, reject) => {
				api.editLottery(params).then(res => {
					if (res.status == "OK") {
						this.$message.success("修改成功")
						resolve()
					} else {
						reject()
					}
				}).catch(() => {
					reject()

				})
			})
		},

		// 获取奖品设置信息
		// getLuckDrawDetail() {
		// 	api.lotteryById(this.lotteryData.id).then(res => {
		// 		// 		return res.data
		// 		if (res.status && res.data) {
		// 			console.log("奖品信息")
		// 		}
		// 	})
		// }
		// 初始化奖品8个
		initPrize(lotteryDrawId) {
			for (let index = 1; index < 9; index++) {
				this.prizes.push(
					{
						faceValue: "",
						lotteryDrawId,
						name: "",
						pic: "",
						prizeId: "", // 优惠券、电子券、虚拟商品ID
						probability: 0.01, // 抽到该奖品的概率
						sort: index,
						type: 5, // 奖品类型 1虚拟商品 2指定商品优惠券 3通用优惠券 4电子券 5谢谢惠顾
						remarks: ""
					}
				)
			}
		},
		// 点击选择序列奖品
		selectPrizeSeat(index) {
			let obj = this.prizes[index - 1]
			if (obj) { this.currentSelection = obj } else {
				this.currentSelection = {
					faceValue: "",
					lotteryDrawId: "",
					name: "",
					pic: "",
					prizeId: null, // 优惠券、电子券、虚拟商品ID
					probability: 0.01, // 抽到该奖品的概率
					sort: index,
					type: 5, // 奖品类型 1虚拟商品 2指定商品优惠券 3通用优惠券 4电子券 5谢谢惠顾
					remarks: ""
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.luck-draw {
	width: 160px;
	min-width: 160px;
	text-align: center;
	border-radius: 8px;
	overflow: hidden;

	.item {
		cursor: pointer;
		padding: 12px 10px;
		color: #333;
		user-select: none;
		border: 1px solid #e8e8e8;

		&:hover {
			color: #fff;
			background-color: #4B5EFF;
		}
	}
		.active{
			color: #fff;
			background-color: #4B5EFF;
		}
}

.prizeForm {
	width: 100%;
}

.grid-content {
	// background-color: #eee;
}

.bg {
	background-color: #eee;
}
</style>
