<template>
	<div class="mvk-layout-content-card">
		<div class="flex-row">
			<el-button v-has="'sys:supplier:add'" type="primary" round class="mb15" @click="handleAdd">
				新增供应商账号
			</el-button>
			<el-form size="small" :inline="true" style="margin-left: 20px">
				<el-form-item label="供应商名称:">
					<el-input v-model="queryParam.name" placeholder="输入供应商名称搜索" clearable />
				</el-form-item>
			</el-form>
			<div class="flex-row" style="justify-content: space-between" />
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button v-has="'sys:supplier:edit'" type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
					<el-button type="text" size="small" @click="handleAddUser(row)"> 新增用户账号 </el-button>
					<delete-button
						:id="row.id"
						v-has="'sys:supplier:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit-dialog
			v-if="hasBtnPermission('sys:supplier:add') || hasBtnPermission('sys:supplier:edit')"
			ref="modalForm"
			@saveSuccess="handleAddOrEditSave"
		/>

		<add-user ref="addUserRef" />
	</div>
</template>

<script>
import { supplierList, deleteSupplier } from "api/basePage/supplier"
import { listMixin } from "views/mixin/listMixin"
import { permissionMixin } from "views/mixin/permissionMixin"
export default {
	name: "SupplierList",
	mixins: [listMixin, permissionMixin],
	components: {
		"add-edit-dialog": () => import("./addEdit.vue"),
		"add-user": () => import("./addUser.vue")
	},
	props: {},
	data() {
		return {
			// 查询条件参数
			queryParam: { name: "" },
			tableColumn: [
				{ prop: "id", label: "供应商ID" },
				// { prop: "username", label: "用户账号" },
				{ prop: "name", label: "供应商名称" },
				{
					prop: "categoryNameArray",
					label: "供应商产品类别"
					// formatter: (cellValue) => {
					// 	return cellValue["productCategoryVO"] && cellValue["productCategoryVO"].name
					// }
				},
				{ prop: "taxRate", label: "增值税税率" },
				{ prop: "contact", label: "联系人" },
				{ prop: "contactMobile", label: "联系电话" }
			],

			tableDataFunc: parameter => {
				return supplierList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			}
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.$refs.mvkTable.refresh()
			},
			deep: true
		}
	},
	computed: {},
	methods: {
		handleDelete(data) {
			deleteSupplier(data.id).then(() => {
				this.$notify.success("删除成功")
				this.$refs.mvkTable.refresh()
			})
		},

		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
		},

		handleAddUser(row) {
			this.$refs.addUserRef.open(row.id)
		}
	},
	created() {},
	mounted() {}
}
</script>
<style lang="scss" scoped></style>
