<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="120px">
			<el-form-item label="订单状态：">
				<el-radio-group v-model="filterStatus" type="button" size="mini">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button v-for="(value, key, index) in orderStatus" :key="index" :label="key">
						{{ value }}
					</el-radio-button>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="时间区间：" class="width100">
				<el-radio-group v-model="dateLimit" type="button" class="mr20" size="mini" @change="onchangeDate">
					<el-radio-button v-for="(item, i) in dateList" :key="i" :label="item.value">
						{{ item.text }}
					</el-radio-button>
				</el-radio-group>
				<el-date-picker v-model="timeVal" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="mini"
					:default-value="timeDefaultShow" :picker-options="pickerOptions" type="daterange" placement="bottom-end"
					start-placeholder="开始时间" end-placeholder="结束时间" style="width: 220px" @change="onchangeTime" />
			</el-form-item>
			<el-collapse-transition>
				<div v-show="showfilter">
					<div class="flex-row">
						<el-form-item label="订单号：" class="width100">
							<el-input v-model.trim="queryParam.code" placeholder="通过订单号查询" size="small" clearable />
						</el-form-item>
						<el-form-item label="商品名称：" class="width100">
							<el-input v-model.trim="queryParam.productName" placeholder="通过查询商品名称查询" size="small" clearable />
						</el-form-item>
						<el-form-item label="收货人号码：" class="width100">
							<el-input v-model.trim="queryParam.receiptMobile" placeholder="通过收货人手机号码查询" size="small" clearable />
						</el-form-item>
						<el-form-item label="会员手机号：" class="width100">
							<el-input v-model.trim="queryParam.memberPhone" placeholder="通过会员手机号查询" size="small" clearable />
						</el-form-item>
					</div>
					<div class="flex-row">
						<el-form-item label="订单类型：" class="width100">
							<el-select v-model="queryParam.type" placeholder="订单类型" clearable filterable
								style="width: 100%">
								<el-option v-for="(item, index) in Object.values(orderTypeList)" :key="index" :label="item"
									:value="index" />
							</el-select>
						</el-form-item>

						<el-form-item label="所属供应商：" class="width100">
							<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
								<el-option v-for="item in supplierlist" :key="item.id" :label="item.name"
									:value="item.id" />
							</el-select>
						</el-form-item>
						<el-form-item label="支付方式：" class="width100">
							<el-select v-model="queryParam.payType" placeholder="支付方式" clearable filterable
								style="width: 100%">
								<el-option v-for="(item, index) in Object.values(payTypeList)" :key="index" :label="item"
									:value="index" />
							</el-select>
						</el-form-item>
						<el-form-item label="所属组织：" class="width100">
							<!-- <el-select v-model="queryParam.shopId" placeholder="所属门店" clearable filterable>
								<el-option
									v-for="item in shoplist"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								/>
							</el-select> -->
							<organizationTreeSelect v-model="queryParam.organId" />
						</el-form-item>
					</div>
					<!-- <el-button v-show="showfilter" class="mb20" type="primary" @click="switchFilter()">折叠筛选</el-button> -->
				</div>
			</el-collapse-transition>
			<div class="flex">
				<el-button class="mb20" type="primary" @click="switchFilter()">
					{{ !showfilter ? "更多筛选" : "折叠筛选" }}
				</el-button>
				<el-button class="mb20" type="primary" :disabled="selectList.length == 0" @click="openDelivers()">
					批量手动发货
				</el-button>
				<el-button class="mb20" type="primary" @click="openShowDdefaultSettingDialog()">
					默认快递设置
				</el-button>
				<el-button class="mb20" type="success" @click="orderExportOpenDialog()"> 订单导出 </el-button>
				<el-button class="mb20" type="success" @click="orderExcelExport()"> 批量发货excel导出 </el-button>
				<el-upload action
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					:http-request="orderExcelImport" :headers="uploadHeaders" :show-file-list="false" class="mb20">
					<el-button type="warning" size="small" style="margin-left: 10px">批量发货excel导入</el-button>
				</el-upload>
				<!-- <el-button class="mb20" type="success" @click="orderExcelImport()"> 批量发货excel导入 </el-button> -->
				<el-button class="mb20" icon="el-icon-refresh" style="margin-left: 10px" @click="searchList()">
					刷新
				</el-button>
			</div>
		</el-form>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" border
			@selection-change="handleSelectionChange">
			<el-table-column slot="head" type="selection" width="40" :selectable="judgeSendForSelect" />
			<!--自定义操作-->
			<template slot="status" slot-scope="{ row }">
				<el-tag class="mr10">{{ row.status | OrderStatus }}</el-tag>
				<span v-show="row.subStatus"><el-tag type="danger">{{ row.subStatus | OrderStatus }}</el-tag></span>
			</template>
			<template slot="code" slot-scope="{ row }">
				<p>{{ row.code }}</p>
				<p style="color: #6a79f9">[{{ orderTypeList[row.type] }}]</p>
			</template>

			<template slot="pic" slot-scope="{ row }">
				<div v-if="row.items && row.items.length > 0" class="flex-row">
					<el-image style="min-width: 40px; height: 40px; width: 40px" :src="row.srcList + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'"
						:preview-src-list="row.srcImages" />
					<div style="margin-left: 4px">
						<p>{{ row.items[0].prodName }}</p>
						<p style="color: #ff5429">￥{{ row.items[0].price }} × {{ row.items[0].prodCount }}</p>
					</div>
				</div>
			</template>
			<template slot="supplyName" slot-scope="{ row }">
				<div v-if="row.source === 0">
					<span v-if="row.items && row.items.length > 0" class="source source0">内</span>{{
						row.items[0].supplierName }}
				</div>
				<div v-else>
					<span v-if="row.items && row.items.length > 0" class="source source1">外</span>{{
						row.items[0].supplierName }}
				</div>
			</template>
			<el-table-column label="操作" fixed="right" min-width="134" :width="170">
				<template slot-scope="scope">
					<el-button v-if="judgeSend(scope.row.status, scope.row.subStatus)" v-has="'oms:order:ship'" type="text"
						:disabled="scope.row.source == 1" @click="onOrderSend(scope.row.id)">
						发货
					</el-button>

					<el-button v-has="'oms:order:detail'" type="text" @click="onOrderDetails(scope.row)">
						详情
					</el-button>
					<el-button v-has="'oms:order:remark'" type="text" @click="onOrderMark(scope.row)"> 备注 </el-button>
					<!-- <el-popover
						v-if="filterStatus == 2 && row.subStatus != 7"
						:ref="`popover-${$index}`"
						:class="`popover-${$index}`"
						placement="top"
						width="240"
					>
						<div class="mb10">确定不经过用户操作直接退款吗？</div>
						<div style="text-align: right; margin: 0">
							<el-button size="mini" type="text" @click="scope._self.$refs[`popover-${$index}`].doClose() ">取消</el-button>
							<el-button type="danger" size="mini" @click="orderRefund(row)">确定</el-button>
						</div>
						<el-button slot="reference" type="text" text="danger" class="p0410"> 主动退款 </el-button>
					</el-popover> -->

					<el-dropdown v-if="scope.row.subStatus === 7 || scope.row.status == 2"
						style="margin-left: 10px; font-size: 12px">
						<span class="el-dropdown-link">更多<i class="el-icon-arrow-down el-icon--right" /></span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="scope.row.subStatus === 7" v-has="'oms:order:refund:refuse'"
								@click.native="onOrderRefuse(scope.row)">
								拒绝退款
							</el-dropdown-item>
							<el-dropdown-item v-if="scope.row.subStatus === 7" v-has="'oms:order:refund:agree'"
								@click.native="onOrderRefund(scope.row.id)">
								立即退款
							</el-dropdown-item>
							<el-dropdown-item v-if="scope.row.subStatus !== 7" v-has="'oms:order:refund:agree'"
								@click.native="orderRefund(scope.row)">
								主动退款
							</el-dropdown-item>
							<!-- <el-dropdown-item v-if="scope.row.subStatus !== 7 && scope.row.status == 2" v-has="'oms:order:refund:agree'">
								<el-popover
									v-if=" scope.row.subStatus != 7"
									:ref="`popover-${scope.$index}`"
									:class="`popover-${scope.$index}`"
									placement="top"
									width="240"
								>
									<div class="mb10">确定不经过用户操作直接退款吗？</div>
									<div style="text-align: right; margin: 0">
										<el-button size="mini" type="text" @click="scope._self.$refs[`popover-${scope$index}`].doClose() ">取消</el-button>
										<el-button type="danger" size="mini" @click="orderRefund(scope.row)">确定</el-button>
									</div>
									<el-button slot="reference" type="text" text="danger"> 主动退款 </el-button>
								</el-popover>
							</el-dropdown-item> -->
							<!-- <el-dropdown-item v-show="row.statusStr.key === 'deleted'" @click.native="handleDelete(row.id)">删除订单</el-dropdown-item> -->
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</mvk-table>
		<!-- 1 -->
		<!--操作记录-->
		<el-dialog title="操作记录" :visible.sync="orderLogDialog" width="50%" />

		<order-detail ref="detailRef" />

		<order-send ref="orderSendRef" @success="searchList" />
		<deliverDialog ref="deliverDialog" @success="searchList" />
		<el-dialog title="拒绝退款原因" :visible.sync="refuseDialog" width="30%">
			<el-form ref="orderRefuseForm" :model="orderRefuseForm" :rules="orderRefuseRules" label-width="80px"
				:inline="false">
				<el-form-item label="订单号" prop="orderId">
					<el-input v-model="orderRefuseForm.orderId" disabled />
				</el-form-item>
				<el-form-item label="拒绝原因" prop="refuseReason">
					<el-input v-model="orderRefuseForm.refuseReason" type="textarea" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefuseSubmit('orderRefuseForm')">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 默认快递设置 -->
		<el-dialog title="请设置默认快递公司" :visible.sync="showDdefaultSettingDialog" width="420px">
			<div class="flex-row">
				<mvk-select-page ref="mvkSelect" v-model="defaultExpressValue" :list-data-func="listDataFunc" filterable
					autocomplete="off" :filter-method="filterExpress" placeholder="快递公司" class="exp-input-page inputWidth"
					clearable @change="changeCode" />
				<el-button class="ml10" type="primary" @click="setDefaultExpress()">保存</el-button>
				<el-button @click="clearDefaultExpress()">清除并关闭</el-button>
			</div>
		</el-dialog>

		<!-- 导出-页码选择 -->

		<exportOptionDialog v-model="showExport" @submit="orderExport" />
	</div>
</template>

<script>
import {
	orderList,
	orderRemark,
	agreeOrderRefund,
	refuseOrderRefund,
	getOrderExport,
	getOrderExcelExport,
	getOrderExcelImport,
	orderByRefund
} from "api/business/selfManager/order.js"

import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"
import OrderDetail from "./orderDetail"
import OrderSend from "./orderSend.vue"
import deliverDialog from "./delivers"
import { orderStatus, payType, orderType, dateTimeList } from "@/utils/constants"
import { mapState, mapActions } from "vuex"

import { GetDateTimeValue } from "@/utils/dateUtils"
import { getExpressCompyV2 } from "api/business/selfManager/express"
import { debounce, blobTofile } from "@/utils/utils"

import { getToken } from "api/auth"
import exportOptionDialog from "@/components/exportOptionDialog"
export default {
	name: "OrderIndex",
	components: {
		OrderDetail,
		OrderSend,
		deliverDialog,
		organizationTreeSelect,
		exportOptionDialog
	},
	computed: {
		...mapState("dict", ["shoplist", "supplierlist"])
	},
	filters: {
		OrderStatus(type) {
			return orderStatus[type]
		}
	},
	data() {
		return {
			showfilter: false,
			dateList: dateTimeList,
			orderStatus: orderStatus,
			payTypeList: payType,
			orderTypeList: orderType,
			timeVal: [],
			dateLimit: "",
			timeDefaultShow: "",
			pickerOptions: {
				disabledDate(time) {
					let curDate = new Date().getTime()
					let three = 90 * 24 * 3600 * 1000
					let threeMonths = curDate - three
					// eslint-disable-next-line no-unused-vars
					var thatDay = time.getTime()
					return thatDay > Date.now()// || thatDay < threeMonths
				}
			},

			tableColumn: [
				{ prop: "code", label: "订单号|类型", slot: true, width: 192 },
				// { prop: "memberName", label: "订购用户", width: 80 },
				// { prop:'prodName', label:'商品信息'},
				// { prop:'shopName', label:'门店名称'},
				// {
				// 	prop: "type",
				// 	label: "订单类型",
				// 	width: 76,
				// 	formatter: (cellvalue) => {
				// 		let value = cellvalue.type
				// 		return orderType[value]
				// 	}
				// },
				{ prop: "pic", label: "商品信息", slot: true, tooltip: true, minWidth: 180 },
				{
					prop: "actualMoney",
					label: "订单总额",
					width: 80,
					align: "center",
					formatter: (cellvalue) => {
						return (cellvalue.actualMoney || 0) + "元"
					}
				},
				{
					prop: "payMoney",
					label: "支付金额",
					width: 80,
					align: "center",
					formatter: (cellvalue) => {
						return (cellvalue.actualMoney || 0) + "元"
					}
				},
				{
					prop: "couponMoney",
					label: "优惠金额",
					width: 80,
					align: "center",
					formatter: (cellvalue) => {
						return (cellvalue.couponMoney || 0) + "元"
					}
				},
				{
					prop: "refundPayMoney",
					label: "退款金额",
					width: 80,
					align: "center",
					formatter: (cellvalue) => {
						return cellvalue.refundPayMoney ? (cellvalue.refundPayMoney || 0) + "元" : '-'
					}
				},
				{ prop: "supplyName", label: "供应商", slot: true, tooltip: true, minWidth: 94 },
				{ prop: "status", label: "订单状态", slot: true, minWidth: 76, align: "center" },
				{
					prop: "payType",
					label: "支付方式",
					width: 76,
					formatter: (cellvalue) => {
						let value = cellvalue.payType

						return payType[value]
					}
				},

				{ prop: "creationDate", label: "下单时间", width: 150 }
				// { prop: "dvyTime", label: "发货时间" },
				// {
				// 	prop: "source",
				// 	label: "平台",
				// 	formatter: (value) => {
				// 		if (value.source == "1") {
				// 			return "供应链平台"
				// 		} else {
				// 			return "本平台"
				// 		}
				// 	}
				// }
			],
			filterStatus: "",
			queryParam: {
				status: "",
				subStatus: "",
				code: "",
				productName: "",
				type: "",
				supplierId: "",
				payType: "",
				receiptMobile: "",
				memberPhone: "",
				shopId: "",
				startTime: "",
				endTime: "",
				isDelete: true
			},
			tableDataFunc: (parameter) => {
				this.pageNum = parameter.pageNum
				this.pageSize = parameter.pageSize
				return orderList(Object.assign(this.queryParam, parameter)).then((res) => {
					// console.log('res.data', res.data);
					res.data?.list.forEach(e => {

						e.srcList = this.inCludesUrlsChangeMinSize(e.items[0].pic) 
						e.srcImages = e.items.map(e => this.inCludesUrlsChangeMinSize(e.pic))
						// e.items?.forEach(item=>{
						// 	item.pic += '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'
						// })
					})
					return res.data
				})
			},

			orderLogDialog: false,
			orderRefundDialog: false,
			refuseDialog: false,
			orderRefuseForm: {
				id: "",
				orderId: "",
				refuseReason: ""
			},

			orderRefuseRules: {
				refuseReason: [
					{
						required: true,
						message: "请输入退款原因",
						trigger: "blur"
					}
				]
			},
			selectList: [], // 选择列表
			deliverstList: [],

			showDdefaultSettingDialog: false,
			queryExpName: "",
			defaultExpressValue: null,

			uploadHeaders: { "X-Token": getToken() },

			pageNum: 0, // 只用于导出传参
			pageSize: 0, // 只用于导出传参
			// 导出-页码选择
			showExport: false,
			loading: false
		}
	},
	watch: {

		filterStatus(newVal) {
			const newValue = Number(newVal)
			let mainStatus = [1, 2, 3, 5, 6, 11, 13] // 主状态
			let afterSaleStatus = [7, 8, 10, 12] // 申请退款，退款中
			if (mainStatus.indexOf(newValue) > -1) {
				this.queryParam.status = newValue
				this.queryParam.subStatus = ""
			} else if (afterSaleStatus.indexOf(newValue) > -1) {
				this.queryParam.status = ""
				this.queryParam.subStatus = newValue
			} else if (newValue == 4) {
				// 待评价
				this.queryParam.status = 5
				this.queryParam.subStatus = newValue
			} else if (newValue == 9) {
				// 已退款
				this.queryParam.status = 6
				this.queryParam.subStatus = newValue
			} else {
				this.queryParam.status = ""
				this.queryParam.subStatus = ""
			}
		},
		queryParam: {
			handler() {
				this.searchList()
			},
			deep: true
		}
	},

	mounted() {
		if (this.shoplist.length == 0) {
			this.getShopList()
		}

		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
		this.timeDefaultShow = new Date();
		this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
	},
	created() {
		const query = this.$route.query
		if (query.code) {
			this.queryParam.code = query.code
		}
		if (query.time) {
			this.onchangeTime(query.time.split("~"))
		}
		if (query.status) {
			this.filterStatus = query.status
		}
		const oderShowfilter = localStorage.getItem('oder_showfilter')
		this.showfilter = Boolean(Number(oderShowfilter))
	},
	methods: {
		...mapActions("dict", ["getShopList", "getSupplierList"]),
		inCludesUrlsChangeMinSize(url,size = "default") {
			if(!url)return ''
			const urlKey = "file.myqcloud.com"
			const urlSuffix = {
	 			default :"?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x",
				big: "?imageMogr2/format/webp"
			}
			return url.includes(urlKey) ? url + urlSuffix[size] : url
		},
		judgeSendForSelect(row) {
			return this.judgeSend(row.status, row.subStatus)
		},
		// 切换筛选
		switchFilter() {
			this.showfilter = !this.showfilter
			localStorage.setItem('oder_showfilter', Number(this.showfilter))
		},
		// 改变表格左侧勾选
		handleSelectionChange(val) {
			this.selectList = val
		},
		// 批量发货
		openDelivers() {
			let expStr = localStorage.getItem("defaultExpress")
			let expCache = null
			if (expStr) expCache = JSON.parse(expStr)
			const openData = this.selectList.map((e) => {
				return {
					code: e.code,
					memberName: e.memberName,
					type: e.type,
					items: e.items.map((el) => {
						return { pic: el.pic, prodName: el.prodName, prodCount: el.prodCount }
					}),
					courierCompany: expStr ? expCache.courierCompany : "",
					courierCompanyCode: expStr ? expCache.courierCompanyCode : "",
					courierNum: "",
					courier: expStr,
					orderId: e.id,
					productType: 0
				}
			})
			this.$refs.deliverDialog.open(openData)
		},
		// 判断是否能发货（拒绝退款，退款失败可以发货）
		judgeSend(status, subStatus) {
			if (status == 2 && subStatus == "") {
				return true
			}

			if (status == 2 && subStatus == null) {
				return true
			}

			if (status == 2 && subStatus == 10) {
				return true
			}

			if (status == 2 && subStatus == 12) {
				return true
			}

			return false
		},

		onchangeDate(val) {
			if (val != "") {
				let time = GetDateTimeValue(val, "YYYY-MM-DD")
				this.queryParam.startTime = time[0]
				this.queryParam.endTime = time[1]
			} else {
				this.queryParam.startTime = ""
				this.queryParam.endTime = ""
			}

			this.timeVal = []
		},

		onchangeTime(e) {
			this.timeVal = e
			this.dateLimit = e ? null : ""
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},

		// 订单详情
		onOrderDetails(row) {
			this.$refs.detailRef.open(row)
		},

		// 发货
		onOrderSend(orderId) {
			this.$refs.orderSendRef.open(orderId)
		},

		// 主动退款
		orderRefund(row) {
			const params = {
				id: row.id
			}
			orderByRefund(params).then((res) => {
				if (res.status === "OK") {
					this.$notify.success({
						title: "成功",
						message: "主动退款成功"
					})
					this.searchList()
				}
			})
		},

		// 备注
		onOrderMark(row) {
			this.$prompt("订单备注", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				inputErrorMessage: "请输入订单备注",
				inputType: "textarea",
				inputValue: row.remarks,
				inputPlaceholder: "请输入订单备注",
				inputValidator: (value) => {
					if (!value) return "输入不能为空"
				}
			})
				.then(({ value }) => {
					orderRemark({ remarks: value, orderId: row.id }).then(() => {
						this.$notify.success({
							title: "成功",
							message: "操作成功"
						})
						this.searchList()
					})
				})
				.catch(() => {
					// this.$message.info('取消输入')
				})
		},

		// 立即退款
		onOrderRefund(id) {
			agreeOrderRefund(id).then(() => {
				this.$notify.success({
					title: "成功",
					message: "退款成功"
				})

				this.searchList()
			})
		},

		// 拒绝退款
		onOrderRefuse(row) {
			this.orderRefuseForm.id = row.id
			this.orderRefuseForm.orderId = row.code
			this.refuseDialog = true
		},

		onRefuseSubmit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					refuseOrderRefund(this.orderRefuseForm.id, {
						refuseReason: this.orderRefuseForm.refuseReason
					}).then(() => {
						this.$notify.success({
							title: "成功",
							message: "拒绝成功"
						})

						this.refuseDialog = false

						this.searchList()
					})
				} else {
					return false
				}
			})
		},
		searchList: debounce(function () {
			this.$refs.mvkTable.refresh()
		}, 300),
		// 打开默认设置
		openShowDdefaultSettingDialog() {
			this.showDdefaultSettingDialog = true
			const expCache = localStorage.getItem("defaultExpress")
			if (expCache) this.defaultExpressValue = expCache
		},
		// 选择快递
		changeCode(obj) {
			this.defaultExpressValue = obj
		},
		// 设置默认快递
		setDefaultExpress() {
			if (this.defaultExpressValue) {
				// const { expCode, expName, id, label } = this.defaultExpressValue
				localStorage.setItem("defaultExpress", this.defaultExpressValue)
				this.$notify.success({
					title: "成功",
					message: "默认快递设置成功"
				})
				this.showDdefaultSettingDialog = false
			}
		},
		// 清除默认快递信息
		clearDefaultExpress() {
			this.defaultExpressValue = ""
			this.$notify.success({
				title: "成功",
				message: "默认快递清除成功"
			})
			localStorage.removeItem("defaultExpress")
			this.showDdefaultSettingDialog = false
		},
		// 防抖搜索
		filterExpress: debounce(function (query) {
			this.queryExpName = query
			this.$refs.mvkSelect.refresh()
		}, 300),
		// 获取快递公司列表
		listDataFunc(parameter) {
			return getExpressCompyV2(Object.assign(parameter, { expName: this.queryExpName })).then((res) => {
				let temp = res.data
				return temp
			})
		},

		// 订单导出
		orderExportOpenDialog() {
			this.showExport = true
			// getOrderExport(Object.assign(this.queryParam, { pageNum: this.pageNum, pageSize: this.pageSize })).then(res => {
			// 	blobTofile(res, "订单数据.xls", "application/vnd.ms-excel")
			// })
		},
		// 订单导出
		orderExport(pageParams) {
			getOrderExport(pageParams).then((res) => {
				blobTofile(res, "订单数据.xls", "application/vnd.ms-excel")
			})
		},

		orderExportOld() {
			getOrderExport(Object.assign(this.queryParam, { pageNum: this.pageNum, pageSize: this.pageSize })).then(res => {
				blobTofile(res, "订单数据.xls", "application/vnd.ms-excel")
			})
		},

		// 批量发货订单导出
		orderExcelExport() {
			getOrderExcelExport(
				Object.assign(this.queryParam, { pageNum: this.pageNum, pageSize: this.pageSize })
			).then((res) => {
				blobTofile(res, "待发货订单数据.xls", "application/vnd.ms-excel")
			})
		},

		// 批量发货订单导入
		orderExcelImport(param) {
			const formData = new FormData()
			formData.append("file", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			getOrderExcelImport(formData)
				.then((res) => {
					loading.close()
					if (res.status === "OK") {
						this.$notify.success({
							title: "成功",
							message: "批量发货成功"
						})
					}
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					loading.close()
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.exp-input-page {
	width: 200px;
}

.source {
	display: inline-block;
	margin-right: 4px;
	line-height: 1;
	border: 1px solid #4f8aff;
	color: #4f8aff;
	padding: 3px;
	border-radius: 50px;
	font-size: 12px;
}

.source0 {
	color: #80b568;
	border-color: #80b568;
}

.el-form-item--small.el-form-item {
	margin-bottom: 10px;
}
</style>
