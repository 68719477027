<template>
	<div class="box">
		<div class="flex">
			<el-button type="primary" class="mb20" @click="handleAdd()"> 新增 </el-button>
		</div>
		<mvk-table ref="mvkTable" :table-data-func="tableDataFunc" row-key="id">
			<el-table-column label="ID" prop="id" width="86" align="center" />
			<el-table-column label="标题" prop="title" width="240" />
			<el-table-column label="内容" prop="content" />
			<el-table-column label="操作" width="140" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleEdit(row,true)"> 详情 </el-button>
					<el-button type="text" size="small" @click="handleEdit(row)"> 编辑 </el-button>
					<!-- <el-button type="text" size="small" @click="handleDelete(row.id)"> 删除 </el-button> -->
					<delete-button
						button-type="text"
						size="small"
						@delete="handleDelete(row.id)"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>
		<edit-drawer ref="modalForm" @saveSuccess="refresh" />
	</div>
</template>

<script>
import * as api from "api/business/marketing/article"
import { listMixin } from "views/mixin/listMixin"
export default {
	name: "Article",
	mixins: [listMixin],
	components: {
		"edit-drawer": () => import("./edit.vue")
	},
	data() {
		return {
			tableDataFunc: parameter => {
				return api.getArticle(Object.assign(parameter, this.queryParams)).then(res => {
					return res.data
				})
			}
		}
	},
	methods: {
		// 删除
		handleDelete(id) { 
			api.deleteArticle(id).then(() => {
				this.$notify.success("删除成功")
				this.refresh()
			})
		},
		//  刷新
		refresh() {
			this.$refs.mvkTable.refresh()
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
	padding: 10px;
}
</style>
