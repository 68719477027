<template>
	<el-drawer :title="title" size="74%" :visible.sync="addEditDialog">
		<div class="drawer-content">
			<el-form
				ref="form"
				v-loading="loading"
				:model="shopForm"
				:rules="rules"
				label-width="180px"
				:inline="false"
				style="margin-right: 16px"
			>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="店铺头像：">
							<!-- <el-image
								:src="shopForm.facadePhoto"
								style="width: 150px; height: 150px; border: 1px solid #eee"
							/> -->

							<upload-image
								ref="imageModel"
								v-model="shopForm.facadePhoto"
								:image-url="shopForm.facadePhoto"
							/>
							<p class="tip">建议选择60*60的头像</p>
						</el-form-item>
						<el-form-item label="店铺编号：" prop="number">
							<el-input v-model="shopForm.number" class="" :disabled="title == '详情'" />
						</el-form-item>
						<el-form-item label="店铺名称：" prop="name">
							<mvk-input v-model.trim="shopForm.name" class="" :disabled="title == '详情'" />
						</el-form-item>
						<el-form-item label="所属上级：" prop="organId">
							<organizationTreeSelect v-model="shopForm.organId" type="1" />
						</el-form-item>

						<!-- <el-form-item label="经营品类：">
							<el-cascader v-model="shopForm.businessCategory"
								:options="prodCateList"
								:props="props2"
								clearable
								class=""
								:show-all-levels="false"
								:disabled="title == '详情'"/>
						</el-form-item> -->
						<!-- <el-form-item label="到期日期：">
							<el-date-picker
								v-model="shopForm.expireDate"
								type="date"
								value-format="yyyy-MM-dd HH:mm:ss"
								:picker-options="pickerOptions"
								placeholder="选择日期"
								:disabled="title == '详情'"
							/>
						</el-form-item> -->
					</el-col>
					<el-col :span="12">
						<el-form-item label="店长名称：">
							<mvk-input
								v-model.trim="shopForm.shopManagerName"
								placeholder="店长名称"
								:disabled="title == '详情'"
							/>
							<!-- <el-button type="text" class="ml10">更换</el-button> -->
						</el-form-item>
						<el-form-item label="店长手机号码：" prop="shopManagerMobile">
							<mvk-input
								v-model.trim="shopForm.shopManagerMobile"
								placeholder="店长手机号码"
								:disabled="title == '详情'"
							/>
						</el-form-item>
						<el-form-item label="店长身份证（正面）：">
							<div class="flex-row">
								<input-upload-image
									:image-base64="shopForm.idCardFront"
									index="idCardFront"
									:disabled="true"
									@imageValue="getImageBase64"
								/>
							</div>
						</el-form-item>
						<el-form-item label="店长身份证（反面）：">
							<div class="flex-row">
								<input-upload-image
									:image-base64="shopForm.idCardReverse"
									index="idCardReverse"
									:disabled="true"
									@imageValue="getImageBase64"
								/>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="油号：" prop="gsModelArry">
					<tag-add v-model="shopForm.gsModelArry" />
				</el-form-item>
				<el-form-item label="标签：" prop="labelNameArr">
					<tag-add v-model="shopForm.labelNameArr" />
				</el-form-item>

				<el-form-item label="店铺地址：">
					<mvk-input
						v-model.trim="shopForm.address"
						type="textarea"
						class="mb15"
						:disabled="title == '详情'"
						@change="selectAddress"
					/>
					<!-- <div id="map" style="width:600px;height:300px;"></div> -->
					<qq-map v-if="title != '详情'" ref="stationMap" @address="getShopAddress" />
				</el-form-item>

				<!-- <el-form-item label="店铺描述：">
					<el-input v-model="shopForm.name" type="textarea" class=""></el-input>
				</el-form-item> -->
				<!-- <el-form-item v-if="title != '详情'">
					<el-button v-throttle="onSubmit" type="primary" round icon="el-icon-check">保存</el-button>
					<el-button round @click="addEditDialog = false">取消</el-button>
				</el-form-item> -->
			</el-form>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button v-if="title != '详情'" v-throttle="onSubmit" type="primary" class="form-submit">
					保存
				</el-button>
				<el-button type="text" @click="addEditDialog = false">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import InputUploadImage from "views/components/InputUploadImage"
import QqMap from "views/bussiness/components/QQMap"
import UploadImage from "views/bussiness/components/UploadImage"

import { shopInfo, shopEdit } from "api/business/selfManager/shop.js"
import { proCategoryList } from "api/business/selfManager/category.js"

import { mobileReg, telReg } from "@/utils/constants"
import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"

export default {
	name: "ShopEdit",
	components: {
		"tag-add": () => import("@/components/TagAdd"),
		InputUploadImage,
		QqMap,
		UploadImage,
		organizationTreeSelect
	},
	props: {},
	data() {
		var validateMobile = (rule, value, callback) => {
			if (!mobileReg.test(value) && !telReg.test(value)) {
				callback(new Error("手机号格式错误"))
			} else {
				callback()
			}
		}
		return {
			addEditDialog: false,
			title: "",

			shopForm: {
				address: "",
				businessCategory: [],
				dept: "",
				expireDate: "",
				id: "",
				idCardFront: "", // 身份证正面
				idCardReverse: "", // 身份证反面
				lat: 0,
				lng: 0,
				name: "",
				number: "", // 店铺编号
				organId: "", // 组织架构-所属上级

				shopManagerId: "",
				shopManagerMobile: "",
				shopManagerName: "",
				gsModel: "",
				gsModelArry: [],
				labelName: "",
				labelNameArr: [],

				types: [], // 经营类别
				licenses: [], // 营业执照
				facadePhoto: ""
			},

			rules: {
				shopManagerMobile: [
					{ required: true, message: "手机号不能为空", trigger: "blur" },
					{ validator: validateMobile, trigger: "blur" }
				],
				number: [{ required: true, message: "请输入店铺编号", trigger: "blur" }],
				name: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
				gsModelArry: [{ required: false, message: "请输入油号", trigger: "change" }],
				labelNameArr: [{ required: false, message: "请输入油号", trigger: "change" }],
				organId: [{ required: true, message: "请选择所属上级", trigger: "blur" }]
			},

			prodCateList: [],
			props2: {
				children: "child",
				label: "name",
				value: "id",
				multiple: true,
				emitPath: false,
				checkStrictly: true
			},

			loading: false,

			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},

			map: null,
			markerLayer: null,
			getAddCode: null,
			getAddress: null
		}
	},
	watch: {},
	computed: {},
	methods: {
		edit(record) {
			this.addEditDialog = true
			this.$nextTick(() => {
				// this.prodForm = JSON.parse(JSON.stringify(record))
				this.getShopInfo(record.id)
			})
		},

		getShopInfo(id) {
			shopInfo(id).then(res => {
				this.shopForm = res.data

				if (typeof this.shopForm.businessCategory == "string") {
					this.shopForm.businessCategory = this.shopForm.businessCategory.split(",")
				}
				if (this.shopForm.gsModel) this.$set(this.shopForm, "gsModelArry", this.shopForm.gsModel.split(";"))
				else this.$set(this.shopForm, "gsModelArry", [])
				if (this.shopForm.labelName)
					this.$set(this.shopForm, "labelNameArr", this.shopForm.labelName.split(";"))
				else this.$set(this.shopForm, "labelNameArr", [])

				if (this.title !== "详情") {
					this.$refs.stationMap.showMap(this.shopForm.lat, this.shopForm.lng)
				}
			})
		},

		getShopAddress(data) {
			this.shopForm.address = data.address
			this.shopForm.lng = data.lng
			this.shopForm.lat = data.lat
		},

		selectAddress() {
			this.$refs.stationMap.selectAddress(this.shopForm.address)
		},

		getCategory() {
			proCategoryList().then(res => {
				this.prodCateList = res.data.list
			})
		},

		getImageBase64(value, index) {
			this.shopForm[index] = value
		},

		onSubmit() {
			this.loading = true

			this.shopForm.businessCategory = this.shopForm.businessCategory
				? this.shopForm.businessCategory.join(",")
				: ""
			this.shopForm.gsModel = this.shopForm.gsModelArry.join(";")
			this.shopForm.labelName = this.shopForm.labelNameArr.join(";")
			this.$refs["form"].validate(valid => {
				if (valid) {
					shopEdit(this.shopForm)
						.then(() => {
							this.$notify.success({
								title: "成功",
								message: "修改成功"
							})
							this.loading = false
							this.addEditDialog = false
							this.$emit("saveSuccess")
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					this.loading = false
					return false
				}
			})
		}
	},
	created() {},
	mounted() {
		this.getCategory()
	}
}
</script>
<style lang="scss" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
	width: 100%;
}

.tip {
	font-size: 12px;
	color: #999b9f;
	margin-top: 7px;
}
</style>
