<template>
	<el-dialog :title="title" :visible.sync="addEditDialog" :close-on-click-modal="false" width="500px" top="15%">
		<el-form ref="dataForm" :model="dataForm" :rules="rules" label-width="100px">
			<el-form-item prop="name" label="分类名称：">
				<mvk-input v-model.trim="dataForm.name" placeholder="标签名称" />
			</el-form-item>
			<el-form-item prop="sort" label="排序：">
				<el-input-number v-model="dataForm.sort" :min="0" />
			</el-form-item>
			<el-form-item prop="enabled" label="状态：">
				<el-switch v-model="dataForm.enabled" />
			</el-form-item>
			<el-form-item>
				<el-button v-throttle="addSubmit" type="primary" class="form-submit">保存</el-button>
				<el-button @click="addEditDialog = false">关闭</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import * as api from "api/business/selfManager/label.js"

export default {
	name: "AddEditModal",
	data() {
		return {
			title: "新增",
			addEditDialog: false,
			dataForm: {
				id: "",
				name: "",
				sort: "",
				enabled: false
			},
			rules: {
				name: [
					{
						required: true,
						message: "请输入分类名称",
						trigger: "blur"
					}
				]
			},

			labelList: []
		}
	},
	computed: {},
	methods: {
		add() {
			this.addEditDialog = true
			this.labelList = JSON.parse(sessionStorage.getItem("eshop-labelList"))
			this.$nextTick(() => {
				this.dataForm = {
					id: "",
					name: "",
					sort: "",
					enabled: false
				}
			})
		},
		edit(record) {
			this.addEditDialog = true
			this.labelList = JSON.parse(sessionStorage.getItem("eshop-labelList"))
			this.$nextTick(() => {
				let temp = JSON.parse(JSON.stringify(record))
				delete temp.productNums
				this.dataForm = temp
			})
		},

		addSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					if (this.dataForm.id) {
						api.editLabel(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "修改失败"
								})
							})
					} else {
						// 新增
						// this.dataForm.level = this.dataForm.parentId == '' ? 1:this.dataForm.parentIdArr.length + 1
						// console.log(this.dataForm.level);
						api.postLabel(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "添加成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "添加失败"
								})
							})
					}
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
