<template>
	<div :class="pictureType != '' ? 'height100' : 'mvk-layout-content-card'" style="padding-right: 10px">
		<el-row :gutter="20" class="height100">
			<el-col :span="8" class="height100">
				<div class="height100">
					<el-input v-model="treeFrom.name" placeholder="选择分类" class="round mb10" />
					<el-radio-group v-model="createRadio" size="mini" class="mb10" @change="changeRadio">
						<el-radio-button :label="false">素材库</el-radio-button>
						<el-radio-button :label="true">我的上传</el-radio-button>
					</el-radio-group>
					<el-tree
						ref="tree"
						:data="categoryTreeData"
						:filter-node-method="filterNode"
						:props="defaultProps"
						node-key="id"
						:current-node-key="10000"
						highlight-current
						@node-click="handleNodeClick"
					>
						<div slot-scope="{ node, data }" class="custom-tree-node">
							<div >
								<el-tooltip :disabled="node.label.length < 12"  effect="dark" :content="node.label" placement="top-start">
									<span class="custom-tree-node-label" >{{ node.label.length > 18 ? node.label.slice(0,15) + '...' : node.label }}</span>
								</el-tooltip>
							</div>
							<span class="el-ic">
								<el-dropdown>
									<span class="el-dropdown-link">
										<i class="el-icon-more" />
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item
											v-has="'sys:attachCategory:add'"
											@click.native="handleAdd(data.id)"
											>添加分类</el-dropdown-item
										>
										<el-dropdown-item
											v-if="node.label !== '全部图片'"
											v-has="'sys:attachCategory:edit'"
											@click.native="handleEdit(data)"
											>编辑分类</el-dropdown-item
										>
										<el-dropdown-item
											v-if="node.label !== '全部图片'"
											v-has="'sys:attachCategory:delete'"
											@click.native="handleDelete(data.id)"
											>删除分类</el-dropdown-item
										>
									</el-dropdown-menu>
								</el-dropdown>
							</span>
						</div>
					</el-tree>
				</div>
			</el-col>
			<el-col :span="16" class="height100">
				<div class="flex flex-row">
					<el-button
						v-if="pictureType != ''"
						size="small"
						type="primary"
						class="mr20 mb20"
						@click="checkPics"
					>
						使用选中图片
					</el-button>

					<el-button-group>
						<el-tooltip v-has="'sys:attach:add'" effect="dark" content="上传图片">
							<el-upload
								class="upload-demo"
								:accept="'image/*'"
								action
								:http-request="handleUploadForm"
								:headers="uploadHeaders"
								:show-file-list="false"
								:before-upload="beforeUpload"
								multiple
								:class="pictureType == '2' ? 'upload-demo-top' : ''"
							>
								<el-button icon="el-icon-upload2" size="mini" />
							</el-upload>
						</el-tooltip>
						<el-tooltip v-has="'sys:attachCategory:add'" effect="dark" content="添加分类">
							<el-button icon="el-icon-circle-plus-outline" size="mini" @click="handleAdd(10000)" />
						</el-tooltip>
						<el-tooltip v-has="'sys:attach:delete'" effect="dark" content="删除图片">
							<el-button icon="el-icon-delete" size="mini" @click.stop="deletePicList" />
						</el-tooltip>
					</el-button-group>
				</div>

				<div class="flex-row flex-wrap mt15">
					<div v-for="(item, index) in pictrueList.content" :key="index">
						<div
							v-if="item.attType.indexOf('video') > -1"
							:class="item.isSelect ? 'on' : ''"
							class="gridVideo"
							@click="changImage(item)"
						>
							<video controls width="200">
								<source :src="item.sattDir" :type="item.attType" />
							</video>
							<p class="text">{{ item.name }}</p>
						</div>

						<div v-else class="gridPic">
							<el-badge class="number" :value="item.num" v-if="item.num > 0" />
							<el-image
								:src="item.sattDir + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/150x'"
								lazy
								:class="item.isSelect ? 'on' : ''"
								@click.native="changImage(item)"
							/>
							<p class="text">{{ item.name }}</p>
						</div>
					</div>
				</div>
				<div class="mt10" style="text-align: right">
					<el-pagination
						:page-sizes="[10, 20, 30, 40]"
						:page-size="pictureParams.pageSize"
						:current-page="pictureParams.pageNum"
						:pager-count="5"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pictrueList.totalElements"
						@size-change="handleSizeChange"
						@current-change="pageChange"
					/>
				</div>
			</el-col>
		</el-row>

		<category-add-edit ref="modalForm" :tree-data="categoryTreeData" @saveSuccess="getCategroyTreeList" />
	</div>
</template>

<script>
import {
	GetAttachCategorysTree,
	DeleteCategory,
	GetCategoryPictures,
	GetCategoryPicturesByPid,
	fileImageApi,
	picPatchDeleteApi
} from "api/business/selfManager/picture.js"

import { getToken } from "api/auth"

export default {
	name: "PictureIndex",
	props: {
		pictureType: {
			type: String,
			default: ""
		},
		isMore: {
			type: Number,
			default: 1
		},
		checkedMore: {
			type: Array,
			default: () => []
		}
	},
	components: {
		CategoryAddEdit: () => import("./categoryAddEdit")
	},
	data() {
		return {
			treeFrom: {
				type: "2",
				status: "-1",
				name: ""
			},
			createRadio: false,
			categoryTreeData: [],
			defaultProps: {
				children: "child",
				label: "name"
			},

			pictureParams: {
				pid: 10000,
				pageNum: 1,
				pageSize: 10
			},

			pictrueList: [],
			checkPicList: [], // 选中的图片
			uploadHeaders: { "X-Token": getToken() }
		}
	},
	computed: {
		filterText() {
			return this.treeFrom.name
		}
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val)
		}
	},
	mounted() {
		this.getCategroyTreeList()
		this.getPictureList()
	},

	methods: {
		// 所有分类
		getCategroyTreeList() {
			const data = {
				name: "全部图片",
				id: 10000
			}
			GetAttachCategorysTree(this.treeFrom).then((res) => {
				let temp = res.data
				temp.unshift(data)
				this.categoryTreeData = [...temp]
			})
		},

		// 添加分类
		handleAdd(id) {
			this.$refs.modalForm.edit({
				pid: id,
				name: "",
				type: 2,
				sort: 1,
				status: 1,
				extra: "",
				url: "url",
				id: ""
			})
			this.$refs.modalForm.title = "添加分类"
		},

		// 编辑分类
		handleEdit(row) {
			this.$refs.modalForm.edit(row)
			this.$refs.modalForm.title = "编辑分类"
		},

		// 删除分类
		handleDelete(id) {
			this.$confirm("确定永久删除该分类", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				DeleteCategory(id).then(() => {
					this.$notify.success({
						title: "成功",
						message: "删除成功"
					})

					this.getCategroyTreeList()

					// 删除分类后，重新获取全部图片
					this.pictureParams.pid = 10000
					this.getPictureList()
				})
			})
		},

		// 搜索分类
		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},

		handleNodeClick(data) {
			if (data.id !== this.pictureParams.pid) {
				this.pictureParams.pageNum = 1
			}
			this.pictureParams.pid = data.id
			this.getPictureList()
		},
		// 切换素材库or我所上传图
		changeRadio() {
			this.getPictureList()
		},

		// 获取所有图片
		getPictureList() {
			// this.pictureParams.pid = this.pictureParams.pid == 10000 ? 0 : this.pictureParams.pid
			// GetCategoryPicturesByPid(this.pictureParams).then(res => {
			//     this.pictrueList = res.data
			// })

			this.checkPicList = []
			let params = {
				pageNum: this.pictureParams.pageNum - 1,
				pageSize: this.pictureParams.pageSize
			}
			if (this.pictureParams.pid == 10000) {
				// 如果选择自己上传的则附加createId
				params.mine = this.createRadio
				// 全部分类
				GetCategoryPictures(params).then((res) => {
					this.pictrueList = res.data
				})
			} else {
				params = JSON.parse(JSON.stringify(this.pictureParams))
				params.pageNum = params.pageNum - 1
				// 如果选择自己上传的则附加createId
				params.mine = this.createRadio

				GetCategoryPicturesByPid(params).then((res) => {
					this.pictrueList = res.data
				})
			}
		},

		beforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2

			if (!isLt2M) {
				this.$message.error("上传图片大小不能超过 2MB!")
			}
			return isLt2M
		},

		// 图片上传
		handleUploadForm(param) {
			let pid = this.pictureParams.pid == 10000 ? 0 : this.pictureParams.pid

			const formData = new FormData()
			const data = {
				model: "product",
				pid: pid
			}
			formData.append("multipart", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			fileImageApi(formData, data)
				.then(() => {
					loading.close()
					this.$message.success("上传成功")
					this.getPictureList()
				})
				.catch(() => {
					loading.close()
				})
		},

		// 选中图片
		changImage(item) {
			// this.$set(item, "isSelect", item.isSelect === undefined ? true : !item.isSelect)
			// this.checkPicList = this.pictrueList.content.filter((item, index) => {
			// 	return item.isSelect === true
			// })

			let activeIndex = 0
			if (!item.isSelect) {
				// item.isSelect = true
				this.$set(item, "isSelect", true)
				this.checkPicList.push(item)
			} else {
				// item.isSelect = false
				this.$set(item, "isSelect", false)

				this.checkPicList.map((el, index) => {
					if (el.attId == item.attId) {
						activeIndex = index
					}
				})
				this.checkPicList.splice(activeIndex, 1)
			}

			this.pictrueList.content.map((el, i) => {
				if (el.isSelect) {
					this.checkPicList.filter((el2, j) => {
						if (el.attId == el2.attId) {
							el.num = j + 1
						}
					})
				} else {
					el.num = 0
				}
			})
		},

		// 删除图片
		deletePicList() {
			if (!this.checkPicList.length) return this.$message.warning("请先选择")

			let deleteIds = this.checkPicList.map((item) => item.attId).join(",")
			this.$deteleModal("内容").then(() => {
				picPatchDeleteApi({
					ids: deleteIds
				}).then(() => {
					this.$notify.success({
						title: "成功",
						message: "删除成功"
					})

					// 清空，避免再次删除
					this.checkPicList = []

					this.getPictureList()
				})
			})
		},

		// 点击使用选中图片
		checkPics() {
			if (!this.checkPicList.length) return this.$message.warning("请先选择图片")
			if (this.$route && this.$route.query.field === "dialog") {
				let str = ""
				for (let i = 0; i < this.checkPicList.length; i++) {
					str += '<img src="' + this.checkPicList[i].sattDir + '">'
				}
				/* eslint-disable */
				nowEditor.dialog.close(true)
				nowEditor.editor.setContent(str, true)
			} else {
				console.log(this.checkPicList.length > this.isMore)
				if (this.checkPicList.length > this.isMore) {
					return this.$message.warning(`最多只能选${this.isMore}张图片`)
				}
				this.$emit(
					"getImage",
					[...this.checkedMore, ...this.checkPicList].map((item) => item.sattDir)
				)
			}
		},

		pageChange(page) {
			this.pictureParams.pageNum = page
			this.getPictureList()
		},
		handleSizeChange(val) {
			this.pictureParams.pageSize = val
			this.getPictureList()
		}
	}
}
</script>

<style lang="scss" scoped>
.gridPic {
	margin-right: 15px;
	margin-bottom: 40px;
	width: 150px;
	height: 150px;
	cursor: pointer;
	position: relative;
	border-width: 2px;
	box-sizing: border-box;

	// img{
	//     width: 100%;
	//     height: 100%;
	//     display: block;
	// }
	.el-image {
		width: 100%;
		height: 100%;
	}

	.on {
		border: 2px solid #5fb878;
	}

	.number {
		position: absolute;
		right: 6px;
		top: 6px;
		z-index: 1;
	}
}

.text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
}

.gridVideo {
	margin-right: 15px;
	margin-bottom: 40px;
	width: 204px;
	height: 154px;
	cursor: pointer;

	video {
		width: 200px;
		height: 150px;
	}

	&.on {
		border: 2px solid #5fb878;
	}
}

.upload-demo {
	float: left;
	display: inline-block !important;

	.el-button {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.upload-demo-top {
	top: -3px;
	position: relative;
}

::v-deep .el-badge__content {
	height: inherit;
}
</style>
