import fetch from "@/axios/fetch"

// ============================================================================== 专营店
export function getMajorShopList(params) { // 专营店列表
	return fetch({
		url: "/majorShop",
		method: "get",
		params: params
	})
}
export function saveMajorShop(data) { // 保存专营店信息
	return fetch({
		url: "/majorShop",
		method: "post",
		data
	})
}
export function putMajorShop(data) { // 修改专营店信息
	return fetch({
		url: "/majorShop",
		method: "put",
		data
	})
}
export function getMajorShopDetail(id) { // 专营店详情
	return fetch({
		url: `/majorShop/${id}`,
		method: "get"
	})
}

export function putMajorShopStatus(id) { // 专营店启停
	return fetch({
		url: `/majorShop/enabled/${id}`,
		method: "put"
	})
}
// ============================================================================== 二维码
export function getMajorShopBatchByQRCode(params) { // 批量专营店二维码
	return fetch({
		url: "/majorShop/getQRCodes",
		method: "get",
		params,
		responseType: "arraybuffer"
	})
}
export function getMajorShopByQRCode(id) { // 单独专营店二维码
	return fetch({
		url: `/majorShop/${id}/getQRCode`,
		method: "get",
		responseType: "arraybuffer"
	})
}

// ==============================================================================会员
export function membersList(params) { // 查询会员列表
	return fetch({
		url: `/majorShop/${params.shopId}/members`,
		method: "get",
		params
	})
}
export function addMember(data) { // 大客户专营店新增会员
	return fetch({
		url: "/majorShop/member",
		method: "post",
		data
	})
}

export function getDownload() { // 下载大客户会员导入excel模板
	return fetch({
		url: "/majorShop/template/download",
		method: "get",
		responseType: "arraybuffer"
		// headers: {
		//     Accept: "content-type:octet-stream"
		// }
	})
}

export function importMembers(data, shopId) { // 批量导入会员
	return fetch({
		url: `/majorShop/${shopId}/member/upload`,
		method: "put",
		data
	})
}
export function removeMembers(data, shopId) { // 移除会员（批量）
	return fetch({
		url: `/majorShop/${shopId}/members/remove`,
		method: "delete",
		data
	})
}

// ==============================================================================产品

export function productsList(params) { // 大客户店铺商品分页查询
	return fetch({
		url: `/majorShop/${params.shopId}/products`,
		method: "get",
		params
	})
}

export function addProducts(data, shopId) { // 添加商品（批量）
	return fetch({
		url: `/majorShop/${shopId}/products/add`,
		method: "post",
		data
	})
}
export function removeProducts(data, shopId) { // 移除商品（批量）
	return fetch({
		url: `/majorShop/${shopId}/products/remove`,
		method: "delete",
		data
	})
}
// ==============================================================================优惠券

export function couponsList(params) { // 店铺优惠券分页查询
	return fetch({
		url: `/majorShop/${params.shopId}/coupons`,
		method: "get",
		params
	})
}

// ============================================================================== 派发

export function membersWhiteList(params, shopId) { // 白名单用户分页查询
	return fetch({
		url: `/majorShop/${shopId}/members_white_list`,
		method: "get",
		params
	})
}

export function distributeCoupon(data) { // 优惠劵派发
	return fetch({
		url: "/majorShop/distribute_coupon",
		method: "post",
		data
	})
}
export function deleteDistributeCoupon(params) { // 优惠劵派发删除
	return fetch({
		url: "/majorShop/delete_distribute_coupon",
		method: "get",
		params
	})
}

export function distributeRecordsList(params, shopId) { // 派发使用记录
	return fetch({
		url: `/majorShop/${shopId}/distribute_records_list`,
		method: "get",
		params
	})
}
export function removeDistributeCoupon(data, shopId) { // 移除优惠（批量）
	return fetch({
		url: `/majorShop/${shopId}/coupons/remove`,
		method: "delete",
		data
	})
}
export function majorShopCouponUseExport(shopId = "", params) { // 导出派发使用记录
	return fetch({
		url: `/majorShop/${shopId}/export`,
		method: "get",
		params,
		responseType: "blob"
	})
}

// ============================================================================== 订单明细

export function getOrder(params, shopId) { // 大客户店铺关联订单
	return fetch({
		url: `/shop/${shopId}/order`,
		method: "get",
		params
	})
}
export function getOrderExport(params, shopId) { // 大客户店铺关联订单导出
	return fetch({
		url: `/shop/${shopId}/export/order`,
		method: "get",
		params,
		responseType: "blob"
	})
}
