<template>
	<div class="mvk-layout-content-card">
		<div class="flex-row">
			<el-button v-has="'live:product:add'" type="primary" round @click="handleAdd">新增直播商品</el-button>
			<el-button v-has="'live:product:sync'" type="success" :loading="syncLoading" round @click="handleSync">同步</el-button>

			<el-form size="small" label-width="100px" :inline="true" class="ml8">
				<el-form-item label="商品审核状态" class="query-form">
					<el-select
						v-model="queryParam.status"
						placeholder="审核状态"
						clearable
						filterable
						style="width: 120px"
					>
						<el-option
							v-for="(item, index) in Object.values(statusMap)"
							:key="index"
							:label="item"
							:value="index"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="商品名称：" class="query-form">
					<el-input v-model="queryParam.liveProductName" placeholder="请输入商品名称" size="small" clearable style="min-width: 180px;" />
				</el-form-item>
				<el-form-item label="所属供应商" class="query-form">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable style="min-width: 210px;">
						<el-option
							v-for="(item,i) in supplierlist"
							:key="i"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15" border>
			<template slot="coverImgUrl" slot-scope="{ row }" label="直播商品封面图">
				<el-image
					style="width: 30px; height: 30px"
					:src="row.coverImgUrl  + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="[row.coverImgUrl]"
				/>
			</template>
			<template slot="status" slot-scope="{ row }" label="审核状态">
				<el-tag>{{ statusMap[row.status] }}</el-tag>

				<el-tooltip
					v-if="row.status == 3"
					effect="dark"
					content="你好，该提审商品（标题、图片、详情页面等内容）不符合要求，请修改后重新提交审核。"
					style="margin-left: 8px"
				>
					<i class="el-icon-question" />
				</el-tooltip>
			</template>

			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button
						v-has="'live:product:edit'"
						type="text"
						size="small"
						:disabled="row.status == 1 || row.status == 2"
						@click="handleEdit(row, false)"
					>
						编辑
					</el-button>
					<el-button type="text" size="small" @click="handleEdit(row, true)"> 查看 </el-button>
					<el-button
						v-if="row.status == 1"
						v-has="'live:product:resetaudit'"
						type="text"
						size="small"
						@click="handleResetAudit(row)"
					>
						撤回审核
					</el-button>
					<el-button
						v-if="row.status == 3"
						v-has="'live:product:audit'"
						type="text"
						size="small"
						@click="handleReAudit(row)"
					>
						重新提审
					</el-button>
					<delete-button
						v-if="row.status != 1"
						:id="row.id"
						v-has="'live:product:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
	</div>
</template>

<script>
import { listMixin } from "views/mixin/listMixin"
import { permissionMixin } from "views/mixin/permissionMixin"
import { getLiveProduct, deleteLiveProduct, syncLiveProduct, resetaudit, reaudit } from "api/business/broadcast/live"
import { debounce } from "utils/utils"
import { mapState, mapActions } from "vuex"
export default {
	name: "BroadcastGoods",
	components: {
		"add-edit-dialog": () => import("./addEdit.vue")
	},
	mixins: [listMixin, permissionMixin],
	data() {
		return {
			tableColumn: [
				{ prop: "id", label: "直播商品ID" },
				{ prop: "productId", label: "关联商品ID " },
				{ prop: "name", label: "直播商品名称" },
				{ prop: "coverImgUrl", label: "直播商品封面图", slot: true },
				{
					prop: "priceType",
					label: "价格类型",
					formatter: value => {
						// 格式化内容
						const statusMap = {
							1: "一口价",
							2: "价格区间",
							3: "显示折扣价"
						}

						return statusMap[value.priceType]
					}
				},
				{
					prop: "price",
					label: "直播价格"
				},
				{ prop: "status", label: "审核状态", slot: true },
				{ prop: "url", label: "商品小程序路径" }
			],
			queryParam: {
				status: "",
				liveProductName: "",
				supplierId: ""
				
			},
			tableDataFunc: parameter => {
				return getLiveProduct(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			statusMap: {
				0: "未审核",
				1: "审核中",
				2: "审核通过",
				3: "审核失败"
			},
			syncLoading: false
		}
	},
	watch: {
		queryParam: {
			handler: debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},
	mounted() {
		// 获取供应商
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	},
	computed: {
		...mapState("dict", ["supplierlist"])
	},
	methods: {
		...mapActions("dict", ["getSupplierList"]),
		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
		},

		// 删除
		handleDelete(data) {
			deleteLiveProduct(data.id).then(() => {
				this.$notify.success("删除成功")
				this.$refs.mvkTable.refresh()
			})
		},

		// 同步
		handleSync() {
			this.syncLoading = true
			syncLiveProduct().then(() => {
				this.$refs.mvkTable.refresh()
			}).finally(() => { 
				this.syncLoading = false
			})
		},

		// 撤销审核
		handleResetAudit(row) {
			resetaudit(row.id).then(() => {
				this.$notify.success({
					title: "成功",
					message: "撤销成功"
				})

				this.$refs.mvkTable.refresh()
			})
		},

		// 重新提审
		handleReAudit(row) {
			reaudit(row.id).then(() => {
				this.$notify.success({
					title: "成功",
					message: "审核成功"
				})

				this.$refs.mvkTable.refresh()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.query-form {
	margin-bottom: 0 !important;
}
</style>
