<template>
	<div>
		<div class="flex flex-sb">
			<div class="flex flex-center">
				<div v-if="!readOnly" class="flex flex-column-center">
					<el-button type="primary" @click="changeGood">添加商品</el-button>
					<el-button type="primary" :disabled="form.productList.length == 0 || goodsListSelect == 0" @click="batchDelete">批量移除</el-button>
					<el-button v-if="!setCategor" type="success" :disabled="form.productList.length == 0" @click="setCategor = true">设置分类</el-button>
					<el-button v-else @click="setCategorClose()">设置完成</el-button>
				</div>
				<div>
					<el-button
						type="primary"
						class="ml10"
						@click="exportGoodsList()"
					>
						导出商品列表
					</el-button>
				</div>
				<div class="filter-box ml20">
					<span>商品名称</span>
					<el-input v-model="queryParam.prodName" placeholder="可输入商品名称查询" clearable class="ml10 w200"
						maxlength="20" @input="search()"
					/>
				</div>
				<div class="flex filter-box ml20">
					<span>商品分类</span>
					<el-select v-model="queryParam.categoryId" placeholder="请选择" clearable @change="search()">
						<el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
					<!-- <prod-category-tree v-model="queryParam.categoryId" check-strictly @change="search()" /> -->
				</div>
			</div>
			<router-link :to="{ path: '/marketingManager/labour/list' }">
				<el-button>返回列表</el-button>
			</router-link>
		</div>

		<el-table ref="table" :data="form.productList" class="mt15 border-table" @selection-change="handleSelectionChange">
			<el-table-column v-if="!readOnly" type="selection" width="55" />
			<!-- <el-table-column type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.skus" stripe>
						<el-table-column v-for="col in fixedColumn" :key="col.prop" :prop="col.prop" :label="col.label"
							:width="col.width"
						>
							<template slot-scope="{ row }">
								<el-image v-if="col.prop == 'pic'" style="width: 30px; height: 30px" :src="row.pic"
									:preview-src-list="[row.pic]"
								/>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column> -->
			<el-table-column prop="id" label="ID" width="60" />
			<el-table-column prop="pic" label="商品图" width="76">
				<template slot-scope="{ row }">
					<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/45x'" :preview-src-list="[row.pic]" />
				</template>
			</el-table-column>
			<el-table-column prop="prodName" label="商品名称" />
			<el-table-column prop="minCostPrice" label="最低成本价" width="110" align="center">
				<template slot-scope="{ row }">
					{{ row.minCostPrice || 0 }}  元
				</template>
			</el-table-column>
			<el-table-column prop="minPrice" label="最低售价" width="110" align="center">
				<template slot-scope="{ row }">
					{{ row.minPrice || 0 }}  元
				</template>
			</el-table-column>
			<el-table-column prop="unit" label="单位" width="86" align="center"/>
			<el-table-column prop="categoryName" label="分类">
				<template slot-scope="{ row }">
					<el-select v-if="setCategor == true" v-model="row.categoryId" placeholder="请选择" @change="changeCategoryForRow($event, row.prodId)">
						<el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
					<span v-else>{{ row.categoryName }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="supplierName" label="供应商" />

			<el-table-column v-if="!readOnly" label="操作" width="160" align="center">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="handleAppend(scope.row)"> 追加 </el-button>
					<el-button type="text" :disabled="topLoding" style="width: 40px;" @click="goodToTop(scope.row.id)">置顶</el-button>
					<delete-button :index="scope.$index" button-type="text" size="small"
						@delete="handleProductDelete"
					/>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination-contain">
			<el-pagination background :current-page.sync="pageNum" :page-size="pageSize"
				layout="total,prev, pager, next" :total="total" @size-change="handleSizeChange"
				@current-change="handleCurrentChange"
			/>
		</div>
		<!--展示仓库中的商品-->
		<goods-list-model ref="goodsList" :multi="true" type="labour" :selected.sync="checkedGoods"
			:disabled-product="disabledProduct" @getGoodsList="getGoodsItem"
		/>
		<!-- 追加商品 -->
		<GoodsAppend ref="goodsAppend" :data.sync="appendDataRow" @submit="getList"></GoodsAppend>
	</div>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import GoodsAppend from "./append.vue"
import * as api from "api/business/selfManager/labour"
import { debounce } from "utils/utils"
import { blobTofile } from "@/utils/utils"

export default {
	components: { GoodsListModel ,GoodsAppend},
	props: {
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			shopId: this.$route.query.id,
			form: {
				productList: [],
				deleteIds: []
			},
			queryParam: {
				categoryId: "",
				prodName: ""
			},
			categoryProps: {
				label: "name",
				value: "id"
			},
			goodsListSelect: [],
			checkedGoods: [], // 选中产品
			fixedColumn: [
				{ prop: "id", label: "SKUId" },
				{ prop: "pic", label: "图片" },
				{ prop: "skuName", label: "SKU名称" },
				{ prop: "sellingPrice", label: "售价" },
				{ prop: "marketPrice", label: "市场价" },
				{ prop: "actualStocks", label: "库存" },
				{ prop: "volume", label: "体积" },
				{ prop: "weight", label: "重量" }
			],
			deleteIds: [],
			pageNum: 1,
			pageSize: 10,
			total: 0,

			disabledProduct: [],

			loading: false,

			// 分类
			setCategor: false,
			categoryOptions: [],

			topLoding: false,

			// 追加库存

			appendVisibie: false,
			appendDataRow: null
		}
	},
	// computed: {
	// 	disabledProduct() {
	// 		return this.form.productList.map(e => e.prodId)
	// 	}
	// },
	mounted() {
		this.$route.query.id && this.getList()
		if (this.categoryOptions.length == 0) {
			this.$route.query.id && this.getCategoryOptions()
		}
		this.$route.query.id && this.getProductIds()
	},
	methods: {
		search: debounce(function () {
			this.getList()
		}, 300),
		getList() {
			this.form.productList = []
			const params = {
				...this.queryParam,
				shopId: this.$route.query.id,
				pageNum: this.pageNum - 1,
				pageSize: this.pageSize
			}
			api.productsList(params).then(r => {
				if (r.status === "OK") {
					this.form.productList = r.data.list
					this.total = r.data.totalElements
				}
			}).finally(() => {
				this.loading = false
			})
		},
		// 通过店铺ID查找已经关联的商品ids
		getProductIds() {
			api.getProductIds(this.$route.query.id).then(res => {
				if (res.status == "OK") {
					this.disabledProduct = res.data || []
				}
			})
		},
		// 表格 勾选
		handleSelectionChange(v) {
			this.goodsListSelect = v
		},
		// 获取分类列表
		getCategoryOptions() {
			api.getLaborClassifyList({ pageSize: 1000, pageNum: 0 }).then(res => {
				if (res.status === "OK") {
					this.categoryOptions = res.data.list.filter(e => e.enabled)
				}
			})
		},
		// 选择分类
		changeCategoryForRow(cid, pid) {
			const params = {
				productClassifyReqs: [
					{
						categoryId: cid,
						productId: pid
					}
				]
			}
			api.setClassifyForGoods(params, this.shopId).catch(err => {
				this.$message.warning(err)

			})
		},

		// 追加
		handleAppend(row) {
			// this.appendVisibie = true
			this.$refs.goodsAppend.visible = true
			this.appendDataRow = row
		},
		// 提交库存追加
		sudbmitAppend() {
			const params = {
				addOnItems: []
			}
			params.addOnItems.push(
				{
					adds: 0,
					shopId: this.appendDataRow.shopId,
					shopSkuId: this.appendDataRow.shopSkuId
				}
			)

			api.LaborShopAddOnShelf(params).then(res => {
				this.appendVisibie = false
				this.$refs.mvkTable.refresh()
				this.$notify.success({
					title: "追加成功"
				})
			})
		},
		// 关闭设置
		setCategorClose() {
			this.setCategor = false
			this.getList()
		},
		// 添加打开商品列
		changeGood() {
			this.checkedGoods.length = 0
			this.checkedGoods = []
			this.$refs.goodsList.open()
		},
		// 选择商品
		// eslint-disable-next-line no-unused-vars
		async getGoodsItem(goodsInfo) {
			// let checkedProdIds = this.form.productList.map(e => e.id)
			// let tempInfo = []
			// goodsInfo &&
			// 	goodsInfo.forEach(item => {
			// 		let index = checkedProdIds.indexOf(item.id)
			// 		if (index > -1) {
			// 			if (this.form.productList[index].skus.length == item.skus.length) {
			// 				this.$message.warning(`已经选择了ID为${item.id}的商品,已剔除相同商品`)
			// 				return
			// 			}
			// 		} else {
			// 			tempInfo.push(item)
			// 		}
			// 	})
			// if (tempInfo.length === 0) return
			// this.updateProduct(tempInfo.map(e => e.id)).then(() => {
			// 	this.getList()
			// }).catch(() => {
			// })
			this.$nextTick(() => {
				this.updateProduct(this.checkedGoods)
			})
		},
		async updateProduct(productIds) {
			return await api.addProducts({ productIds }, this.$route.query.id).then(res => {
				if (res.status === "OK") {
					this.getList()
					this.getProductIds()
					return true
				} else return false
			})
		},
		// 用于左侧勾选
		tableRowClassName(row) {
			// 设置row对象的index
			row.row.index = row.rowIndex
		},
		// 置顶
		goodToTop(goodId) {
			this.topLoding = true
			api.laborTop(goodId).then(res => {
				this.getList()
				this.getProductIds()
			}).finally(()=>{
				this.topLoding = false
			})
		},
		// 商品删除
		handleProductDelete(data) {
			const id = this.form.productList[data.index].prodId
			api.removeProducts({ productIds: [id] }, this.$route.query.id).then(r => {
				if (r.status === "OK") {
					this.$message.success("删除成功")
					this.getList()
					this.getProductIds()
				}
			})
		},
		// 批量移除
		batchDelete() {
			const list = this.$refs.table.selection.map(e => e.prodId)
			api.removeProducts({ productIds: list }, this.$route.query.id).then(r => {
				if (r.status === "OK") {
					this.$message.success("删除成功")
					this.getList()
					this.getProductIds()
				}
			})
		},
		// 每页显示个数
		handleSizeChange(val) {
			this.pageSize = val
			this.pageNum = 1
			this.getList()
		},
		// 当前页变化
		handleCurrentChange(val) {
			this.pageNum = val
			this.getList()
		},

		// 导出出售中商品列表
		exportGoodsList() {
			const params = {
				productName: this.queryParam.prodName,
				categoryId: this.queryParam.categoryId
			}
			api.getRelationGoodsDownload(Object.assign(params, { shopId: this.$route.query.id })).then(res => {
				blobTofile(res, "商品列表", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.filter-box {
	display: flex;
	align-items: center;
	width: 300px;

	span {
		min-width: 80px;
	}
}
</style>
