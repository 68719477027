<template>
	<div>
		<!-- 内容 -->
		<el-card>
			<div slot="header" class="tip-header">
				<span>商品概况</span>
				<!-- 筛选 -->
				<div class="options">
					<el-date-picker
						v-model="timeVal"
						type="datetimerange"
						format="yyyy-MM-dd"
						value-format="yyyyMMdd"
						:picker-options="pickerOptions"
						size="small"
						range-separator="至"
						style="width: 320px"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="onchangeTime"
					/>
				</div>
			</div>
			<div class="live-data-list">
				<div v-for="(item, index) in totalTotal" :key="item.name" class="flex">
					<div class="icon-circular" :class="'color' + (index % 4)">
						<icon-svg :icon="item.icon" size="18" />
					</div>
					<div>
						<div class="text">
							{{ item.label }}
							<el-tooltip class="item" effect="dark" :content="item.tip" placement="top">
								<i class="el-icon-question" />
							</el-tooltip>
						</div>
						<count-to :start-val="0" :end-val="item.value" :duration="2600" class="count-num num" />
					</div>
				</div>
			</div>

			<line-bar-chart
				v-if="chartShow"
				id="goodsLineChart"
				ref="goodsLineChart"
				v-loading="loading_chart"
				:xdata="xAxisData"
				:ydata="seriesData"
				class="mt15"
			/>
		</el-card>
		<!-- <el-card class="box-card mt15">
            <div slot="header" class="tip-header">
                <span>商品排行</span>
            </div>

            <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
                <template slot="pic" slot-scope="{ row }" label="商品图">
                    <el-image style="width: 30px; height: 30px" :src="row.pic" :preview-src-list="[row.pic]" />
                </template>
            </mvk-table>
        </el-card> -->
	</div>
</template>

<script>
import CountTo from "vue-count-to"
import LineBarChart from "views/components/LineBarChart"
import { goodsAnalysisTable } from "./data.js"
import { DateList, pickerOptions } from "@/utils/constants"
import { debounce } from "utils/utils"

import { productStatistic } from "api/business/dashboard"
import moment from "moment"
const today = moment().startOf("day").format("YYYYMMDD")
const startDay = moment().subtract(14, "days").format("YYYYMMDD")
export default {
	name: "GoodsAnalysis",

	components: {
		CountTo,
		LineBarChart
	},

	directives: {},

	data() {
		return {
			// 筛选
			dateList: DateList,
			dateValue: [startDay, today],
			timeVal: [startDay, today],
			queryParam: {
				date: []
			},
			totalTotal: [],
			xAxisData: [],
			seriesData: [],
			loading_chart: false,
			tableColumn: [
				{
					prop: "pic",
					label: "商品图片",
					slot: true,
					width: 80
				},
				{
					prop: "name",
					label: "商品名称",
					tooltip: true
				},
				{
					prop: "browse",
					label: "浏览量"
				},
				{
					prop: "user",
					label: "访客数"
				},
				{
					prop: "cart",
					label: "加购数"
				},
				{
					prop: "orders",
					label: "下单数"
				},
				{
					prop: "pay",
					label: "支付件数"
				},
				{
					prop: "payPrice",
					label: "支付金额"
				},
				{
					prop: "changes",
					label: "访客-支付转换率"
				}
			],

			// eslint-disable-next-line no-unused-vars
			tableDataFunc: (parameter) => {
				return new Promise((resolve) => {
					resolve(goodsAnalysisTable.data)
				})
			},

			chartShow: false,
			censusData: {},
			statistics: [],

			pickerOptions
		}
	},

	watch: {
		queryParam: {
			// 增加防抖
			handler: debounce(function () {
				this.initData()
				console.log("参数改变，请求新数据")
			}, 300),
			deep: true
		}
	},
	mounted() {
		this.initData()
	},

	methods: {
		initData() {
			const params = {
				startDate: this.timeVal[0],
				endDate: this.timeVal[1]
			}
			this.loading_chart = true
			productStatistic(params)
				.then((res) => {
					this.statistics = res.data.data
					this.censusData = res.data
					this.toData()
					this.xAxisData = this.statistics.xaxis
					this.seriesData = this.statistics.series
					this.seriesData &&
						this.seriesData.forEach((item) => {
							if (item.name == "支付金额" || item.name == "退款金额") {
								item.type = "bar"
								item.value = item.values
								item.tooltip = {
									valueFormatter: function (value) {
										return value + " 元"
									}
								}
							} else {
								item.type = "line"
								item.yAxisIndex = 1
								item.value = item.values
								item.tooltip = {
									valueFormatter: function (value) {
										return value + " 元"
									}
								}
							}
						})
					this.chartShow = true
				})
				.finally(() => {
					this.loading_chart = false
				})
		},
		toData() {
			this.totalTotal = [
				{
					label: "商品浏览量",
					name: "browse",
					tip: "在选定条件下，用户浏览商城页面的次数。每打开一个页面或每刷新一次页面都记录1次",
					icon: "liulanliang",
					value: this.censusData.browse
				},
				{
					label: "商品访客数",
					name: "user",
					tip: "在选定条件下，访问任何商品详情页的人数，一个人在统计时间范围内访问多次只记为一个",
					icon: "yonghu1",
					value: this.censusData.user
				},
				{
					label: "加购件数",
					name: "cart",
					tip: "在选定条件下，添加商品进入购物车的商品件数",
					icon: "gouwuche",
					value: this.censusData.cart
				},
				{
					label: "支付件数",
					name: "pay",
					tip: "在选定条件下， 成功付款订单的商品件数之和（拼团商品在成团之后计入，不剔除退款订单）",
					icon: "zhifujianshu",
					value: this.censusData.pay
				},
				{
					label: "支付金额",
					name: "payAmount",
					tip: "在选定条件下， 成功付款订单的商品金额之和（拼团商品在成团之后计入，不剔除退款订单）",
					icon: "zhifujine",
					value: this.censusData.payAmount
				},

				{
					label: "退款金额",
					name: "refundAmount",
					tip: "在选定条件下，成功退款的商品金额之和",
					icon: "tuikuanjine",
					value: this.censusData.refundAmount
				},
				{
					label: "退款件数",
					name: "refund",
					tip: "在选定条件下，成功退款的商品件数之和",
					icon: "tuikuanjianshu",
					value: this.censusData.refund
				}
			]
		},
		// 重新渲染
		reRender() {
			this.$refs.goodsLineChart.resize()
		},

		// 具体日期
		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.date = e ? this.timeVal.join(",") : ""
		}
	}
}
</script>

<style lang="scss" scoped>
.options {
	margin: 10px 0;
}
.tip-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-left: 5px solid #4b5eff;
	padding-left: 8px;
}
.live-data-list {
	display: flex;
	justify-content: space-between;
	.icon-circular {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 8px;
		background: #1890ff;

		.svg-icon {
			margin-right: 0;
		}
	}

	.color1 {
		background: #00c050;
	}

	.color2 {
		background: #ffab2b;
	}

	.color3 {
		background: #b37feb;
	}

	.text {
		color: #666;
		line-height: 1.8;
		i {
			font-size: 16px;
			color: #4f8aff;
		}
	}
	.num {
		font-weight: bold;
		color: #4b5eff;
		line-height: 1.8;
	}
}
</style>
