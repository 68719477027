<template>
	<div class="app-login">
		<div class="login-container">
			<div class="login-left">
				<div class="text-box">
					<div class="text-box-item">
						<span class="word1">专业的</span>
						<span class="txt1">管理平台</span>
					</div>
					<div class="text-box-item">
						<span class="word1">-------</span>
						<span class="txt1">-------</span>
					</div>
				</div>
			</div>
			<div class="login-right">
				<div class="login-form">
					<div class="login-header">
						<p class="welcomeText">Welcome {{ title }}</p>
						<!-- <p class="welcomeText sys-title" style="font-weight: 500">{{title}}</p> -->
						<!-- <p class="sys-title-sup">让数字化高度智能，让绿色能源赋能万物</p> -->
					</div>
					<el-tabs v-model="activeName" class="login-tab">
						<el-tab-pane label="验证码登录" name="codeLogin">
							<el-form ref="codeForm" :model="codeForm" :rules="codeRules" label-position="top">
								<el-form-item prop="mobile" label="请输入您的手机号码">
									<el-input
										v-model="codeForm.mobile"
										v-enter-next-input
										placeholder="请输入您的手机号码"
										size="large"
										clearable
										autocomplete="on"
										class="inputStyle"
									>
										<icon-svg slot="prefix" icon="shouji" size="26" class="iconsvg" />
									</el-input>
								</el-form-item>
								<el-form-item prop="smsCode" label="请输入验证码" style="margin-bottom: 76px">
									<div class="flex-row inputStyle">
										<el-input
											ref="smsCodeInput"
											v-model="codeForm.smsCode"
											placeholder="请输入验证码"
											size="large"
											clearable
											@keyup.enter.native="loginSubmit('codeForm')"
										>
											<icon-svg slot="prefix" icon="code" size="26" class="iconsvg" />
										</el-input>

										<el-button
											v-show="show"
											type="primary"
											class="ml20 code-btn"
											@click="sendValidCode"
										>
											发送验证码
										</el-button>
										<el-button
											v-show="!show"
											disabled
											type="primary"
											class="ml20 code-btn"
											@click="sendValidCode"
										>
											{{ count }}s后重新发送验证码
										</el-button>
									</div>
								</el-form-item>
								<el-form-item>
									<el-button
										v-loading="loading"
										v-debounce="[() => loginSubmit('codeForm'), `click`, 500]"
										type="primary"
										class="black-btn bg"
									>
										登录
									</el-button>
								</el-form-item>
							</el-form>
						</el-tab-pane>
						<!-- v-if="env == 'development' || env == 'alpha'" -->
						<el-tab-pane label="账号登录" name="accordLogin">
							<el-form ref="loginForm" :model="loginForm" :rules="rules" label-position="top">
								<el-form-item prop="principal" label="请输入您的账户">
									<el-input
										v-model="loginForm.principal"
										v-enter-next-input
										placeholder="请输入您的账户"
										size="large"
										clearable
										class="inputStyle"
									>
										<icon-svg slot="prefix" icon="zhanghao" size="26" class="iconsvg" />
									</el-input>
								</el-form-item>
								<el-form-item prop="credentials" label="请输入您的密码">
									<el-input
										v-model="loginForm.credentials"
										placeholder="请输入您的登录密码"
										type="password"
										size="large"
										show-password
										class="inputStyle"
									>
										<icon-svg slot="prefix" icon="mima" size="26" class="iconsvg" />
									</el-input>
								</el-form-item>
								<!-- <el-form-item prop="verifyCode" label="请输入验证码" style="margin-bottom: 76px">
									<div class="flex-row inputStyle">
										<el-input
											v-model="loginForm.verifyCode"
											placeholder="请输入验证码"
											type="text"
											size="large"
											@keyup.enter.native="loginSubmit('loginForm')"
										>
											<icon-svg slot="prefix" icon="mima" size="26" class="iconsvg" />
										</el-input>
										<el-image
											class="ml20 code-btn"
											:src="VerificationCodePic"
											@click="getVerificationCode"
										/>
									</div>
								</el-form-item> -->
								<el-form-item>
									<el-button
										v-loading="loading"
										v-debounce="[() => loginSubmit('loginForm'), `click`, 500]"
										type="primary"
										class="black-btn bg"
									>
										登录
									</el-button>
								</el-form-item>
							</el-form>
						</el-tab-pane>
						
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { isMobile } from "@/utils/validate"
// eslint-disable-next-line no-unused-vars
import { sendCode, getVerifyCode } from "api/login"

// 必须是8位数字+字母组合，特殊字符可有可无
const passRegex = /^(?![0-9]+$)(?![a-zA-Z]+$)([0-9A-Za-z\\W]{8,}$)/
import { getToken } from "@/api/auth"

export default {
	name: "",
	components: {},
	data() {
		return {
			env: process.env.NODE_ENV,

			loginForm: {
				principal: "",
				credentials: "",
				verifyCode: "",
				uuid: ""
			},
			rules: {
				principal: [
					{
						required: true,
						message: "请输入账户",
						trigger: "blur"
					},
					{
						// validator:(rule, value, callback) => {
						// 	const reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/
						// 	if(reg.test(value)){
						// 		callback(new Error('不能输入特殊字符'))
						// 	}else{
						// 		callback()
						// 	}
						// },
						// trigger: ['change','blur']
					}
				],
				credentials: [
					{
						required: true,
						message: "请输入密码",
						trigger: "blur"
					}
				]
				// verifyCode: [
				// 	{
				// 		required: true,
				// 		message: "请输入验证码",
				// 		trigger: "blur"
				// 	}
				// ]
			},
			loading: false,
			// activeName: process.env.NODE_ENV === "production" ? "codeLogin" : "accordLogin",
			activeName:"codeLogin",

			codeForm: {
				type: "mobile",
				mobile: "",
				smsCode: ""
			},
			codeRules: {
				mobile: [
					{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							if (!isMobile(value)) {
								callback(new Error("手机号格式错误"))
							} else {
								callback()
							}
						},
						trigger: ["change", "blur"]
					}
				],
				smsCode: [
					{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}
				]
			},

			show: true,
			count: 60, // 倒计时
			timer: null,
			VerificationCodePic: "", // 图形验证码
			VerificationCodePicCopy: process.env.VUE_APP_BASE_API + "/verify/get_code"
		}
	},
	computed: {
		title() {
			return this.$store.state.settings.title
		}
	},
	directives: {
		enterNextInput: {
			inserted: function (el) {
				el.addEventListener("keypress", function (e) {
					e = e || window.event
					let charcode = typeof e.charCode == "number" ? e.charCode : e.keyCode
					if (charcode == 13) {
						var dom = document.getElementsByTagName("input")
						for (var i = 0; i < dom.length; i++) {
							if (dom[i] == document.activeElement) {
								if (i == dom.length) {
									return
								}
								dom[i + 1].focus()
								return
							}
						}
					}
				})
			}
		}
	},
	methods: {
		loginSubmit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true

					let params = {}
					if (this.activeName == "accordLogin") {
						params = this.loginForm
					} else {
						params = this.codeForm
					}

					this.$store
						.dispatch("user/LoginByUserName", params)
						.then(() => {
							// 登录成功后，先判定密码是否符合规则，不符合规则，引导到修改密码界面
							if (this.activeName == "accordLogin") {
								if (!passRegex.test(this.loginForm.credentials)) {
									this.$confirm("你的登录密码过于简单，建议先修改密码", "提示", {
										confirmButtonText: "确定",
										cancelButtonText: "取消",
										type: "warning"
									})
										.then(() => {
											this.$router.push("/reset/password")
										})
										.catch(() => { })
								} else {
									// 有首页的情况，直接跳转到首页
									this.$router.push("/dashboard")
								}
							} else {
								localStorage.setItem("cache_username", params.mobile)
								// 有首页的情况，直接跳转到首页
								this.$router.push("/dashboard")
							}
						})
						.catch(() => {
							// this.getVerificationCode()
							this.$refs.smsCodeInput.focus()
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					return false
				}
			})
		},

		sendValidCode() {
			this.$refs.codeForm.validateField("mobile", verifycode_check => {
				if (verifycode_check) return

				sendCode({
					mobile: this.codeForm.mobile
				}).then(() => {
					// console.log(res);
					this.$notify.success({
						title: "成功",
						message: "验证码已成功发送，请查收"
					})

					this.getAuthCode()
				})
			})
		},

		getAuthCode() {
			this.show = false
			this.count = 60
			this.timer = setInterval(() => {
				this.count--
				if (this.count <= 0) {
					this.show = true
					clearInterval(this.timer)
				}
			}, 1000)
		},
		// 获取图形验证码
		// getVerificationCode() {
		// 	getVerifyCode().then(res => {
		// 		this.VerificationCodePic = "data:image/png;base64," + res.data.img
		// 		this.loginForm.uuid = res.data.uuid
		// 	})
		// },

		handleChangeCode() {
			this.VerificationCodePic = this.VerificationCodePicCopy + "?t=" + Math.random()
		}
	},
	mounted() {
		console.log('env', this.env);
		if (getToken()) {
			this.$router.push("/dashboard")
		}
		const moblieNum = localStorage.getItem("cache_username")
		if (moblieNum) this.codeForm.mobile = moblieNum
		// this.getVerificationCode()
	}
}
</script>

<style lang="scss" scoped>
.app-login {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-image: linear-gradient(135deg, #59b3ff 0%, #1c87f0 100%);
	// background: url('@/assets/img/bg/login_bg.png');

	.login-container {
		position: relative;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		// top: calc(50% - 450px);
		// left: calc(50% - 720px);
		// width: 1440px;
		// height: 900px;
		// padding:87px 60px;
		// border-radius: 20px;
		background: #fff;
		// box-shadow: 1px 1px 0px #eee;
		display: flex;

		.login-left {
			width: calc(40% - 120px);
			min-width: 230px;
			height: 100%;
			background-image: linear-gradient(to top, #2e3cb3 0%, #222653 100%);
			background: url("~@/assets/img/bg/login_bg.png") left;
			background-size: cover;

			.text-box {
				font-size: 40px;
				color: #fff;
				margin-top: 120px;
				margin-left: 40px;

				.text-box-item {
					margin: 16px 0;
				}

				.txt1 {
					color: #ff683d;
				}
			}
		}

		.login-right {
			flex: 1;
			padding-top: 100px;
			overflow-y: auto;
			height: 100vh;
			// padding-left: 170px;
			// padding-right: 170px;
		}

		.login-form {
			width: calc(300px + 30%);
			// width: 100%;
			padding: 10px;
			margin: 0 auto;
		}

		.login-header {
			padding: 0 0 30px;
			// text-align: center;
			font-size: 20px;

			.welcomeText {
				font-size: 36px;
				font-weight: bold;
				color: #3a3f63;
				line-height: 54px;
			}

			.sys-title-sup {
				font-size: 16px;
				font-weight: 400;
				color: #6a74a5;
				line-height: 24px;
				margin-top: 8px;
			}
		}

		.svg-icon {
			vertical-align: middle;
		}

		.black-btn {
			width: 240px;
			height: 54px;
			border-radius: 29px;
			font-size: 26px;
		}

		.inputStyle {
			// width: 80%;
			min-width: 200px;
			max-width: 600px;
		}
	}
}

.login-form .el-form-item {
	margin-bottom: 40px;
}

::v-deep .el-input--large .el-input__inner {
	height: 50px;
	// border-radius: 25px;
	line-height: 50px;
	// background-color: rgba(240, 250, 255, 1) !important;
	// border: 1px solid rgba(240, 250, 255, 1);
	padding-left: 48px;
	font-size: 22px;
}

::v-deep .el-input__prefix {
	left: 16px;
	line-height: 50px;
}

::v-deep .el-input__suffix {
	right: 16px;
	line-height: 50px;
}

::v-deep .el-form-item__label {
	font-size: 16px;
}

::v-deep .el-tabs__nav-wrap::after {
	background-color: transparent;
}

::v-deep .el-tabs__item {
	font-size: 16px;
}

::v-deep .el-tabs__header {
	margin: 0 0 30px;
}

.code-btn {
	font-size: 16px;
}
</style>
