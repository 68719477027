<template>
	<div class="flex-row">
		<div
			v-for="(item, index) in imageArray"
			:key="index"
			class="pictrue"
			draggable="true"
			@dragstart="handleDragStart(item)"
			@dragover.prevent="handleDragOver($event, item)"
			@dragenter="handleDragEnter($event, item)"
			@dragend="handleDragEnd()"
		>
			<img :src="item">
			<i v-show="!disabled" class="el-icon-error btndel" @click="handleRemove(index)" />
		</div>
		<div v-if="imageArray.length < 10" v-show="!disabled" class="upLoadPicBox" @click="uploadMulImages">
			<div class="upLoad">
				<input
					ref="mulImg"
					type="file"
					accept="image/*"
					multiple
					style="display: none"
					@change="imgFileChange()"
				>
				<i class="el-icon-camera cameraIconfont" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "UploadDragImage",
	components: {},
	props: {
		imageArray: {
			type: Array,
			require: true,
			default: []
		},
		disabled: {
			type: Boolean
		}
	},
	data() {
		return {
			dragging: null
		}
	},
	watch: {},
	computed: {},
	methods: {
		uploadMulImages() {
			if (this.disabled) return
			this.$refs.mulImg.value = null
			this.$refs.mulImg.click()
		},

		imgFileChange() {
			var vm = this
			var files = this.$refs.mulImg.files

			if (files.length > 10) return this.$message.warning("最多选择10张图片！")
			if (files.length + this.imageArray.length > 10) return this.$message.warning("最多选择10张图片！")

			let imageArray = vm.imageArray
			files.forEach(file => {
				var reader = new FileReader()
				reader.onloadend = function() {
					imageArray.push(reader.result)
				}
				if (file) {
					reader.readAsDataURL(file)
				}
			})

			this.$emit("input", imageArray)
		},

		handleRemove(i) {
			let imageArray = this.imageArray
			imageArray.splice(i, 1)
			this.$emit("input", imageArray)
		},

		// 移动
		handleDragStart(item) {
			this.dragging = item
		},
		handleDragEnd() {
			this.dragging = null
		},
		handleDragOver(e) {
			e.dataTransfer.dropEffect = "move"
		},
		handleDragEnter(e, item) {
			e.dataTransfer.effectAllowed = "move"
			if (item === this.dragging) return
			const newItems = [...this.imageArray]
			const src = newItems.indexOf(this.dragging)
			const dst = newItems.indexOf(item)
			newItems.splice(dst, 0, ...newItems.splice(src, 1))
			// this.imageArray = newItems
			this.$emit("input", newItems)
		}
	},
	created() {},
	mounted() {}
}
</script>
<style lang="scss" scoped></style>
