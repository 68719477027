<template>
	<el-drawer
		:title="info.id ? '修改模板' : '新增模板'"
		:visible.sync="viewDialog"
		:wrapper-closable="false"
		size="500px"
	>
		<el-form ref="template" :model="info" :rules="rules" label-width="100px">
			<el-form-item label="模板名称" prop="name">
				<el-input v-model="info.name" placeholder="请输入模板名称" />
			</el-form-item>

			<!-- <el-form-item label="模板类型" prop="type">
                <el-radio-group v-model="info.type">
                    <el-radio-button :label="0">系统通知</el-radio-button>
                    <el-radio-button :label="1">微信模板消息</el-radio-button>
                </el-radio-group>
            </el-form-item> -->
			<el-form-item label="消息内容" prop="content">
				<el-input
					v-model="info.content"
					type="textarea"
					:autosize="{ minRows: 3, maxRows: 3 }"
					placeholder="请输入内容"
				/>
			</el-form-item>
			<!-- <el-form-item label="消息变量" prop="variable">
                <el-input
                    v-model="info.variable"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3}"
                    placeholder="{order_id}订单号;{store_name}商品名称"
                />
            </el-form-item> -->
			<el-form-item style="margin-top: 60px">
				<el-button type="primary" :loading="loading" @click="save()">保存</el-button>
				<el-button :loading="loading" @click="close()">关闭</el-button>
			</el-form-item>
		</el-form>
	</el-drawer>
</template>

<script>
import { saveMessageTemplate, putMessageTemplate } from "api/business/marketing/message"
// MessageTemplateAddReq
// {
// content (string, optional): 消息内容 ,
// name (string, optional): 模板名称 ,
// type (integer, optional): 模板类型 0:系统通知，1:微信模板消息 ,
// variable (string, optional): 消息内容变量
// }
export default {
	name: "RechargeView",
	data() {
		return {
			info: {},
			viewDialog: false,
			rules: {
				name: {
					required: true,
					message: "请输入通知名称"
				},
				content: {
					required: true,
					message: "请输入通知内容"
				}
			},
			loading: false
		}
	},
	methods: {
		initInfo() {
			return {
				name: "",
				type: 0,
				content: "",
				variable: ""
			}
		},
		show(info) {
			if (info && info.id) {
				this.info = JSON.parse(JSON.stringify(info))
			} else {
				console.log("新增")
				this.info = this.initInfo()
			}
			this.viewDialog = true
		},
		save() {
			this.$refs.template.validate((valid) => {
				if (valid) {
					this.loading = true
					const fn = (res) => {
						this.loading = false
						if (res.status === "OK") {
							this.viewDialog = false
							this.$emit("loadData")
						}
					}
					if (this.info && this.info.id) {
						putMessageTemplate(this.info)
							.then((res) => {
								fn(res)
							})
							.catch(() => {
								this.loading = false
							})
					} else {
						saveMessageTemplate(this.info)
							.then((res) => {
								fn(res)
							})
							.catch(() => {
								this.loading = false
							})
					}
				} else {
					// console.log("验证失败")
				}
			})
		},
		close() {
			this.viewDialog = false
		}
	}
}
</script>

<style lang="scss" scoped></style>
