<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" size="50%">
		<div class="drawer-content">
			<el-form ref="couponForm" :model="couponForm" :rules="rules" label-width="200px">
				<el-form-item label="优惠劵名称：" prop="name">
					<el-input v-model="couponForm.name" style="width: 350px" placeholder="请输入优惠券名称" />
				</el-form-item>
				<el-form-item label="优惠劵类型：">
					<el-radio-group v-model="couponForm.types" @change="changeShop()">
						<el-radio :label="10">通用券</el-radio>
						<el-radio :label="20">商品券</el-radio>
						<el-radio :label="30">品类券</el-radio>
						<el-radio :label="40">大客户券</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="抽奖券：">
					<el-switch
						v-model="couponForm.isLottery"
						active-text="是"
						inactive-text="否"
					/>
				</el-form-item>
				<el-form-item v-if="couponForm.types === 30" label="选择商品类别：" prop="pmsType">
					<prod-category-tree v-model="couponForm.pmsType" style="width: 350px" />
				</el-form-item>
				<el-form-item v-if="couponForm.types === 20" label="商品：" prop="checked">
					<!-- <div class="flex-row">
						<template v-if="couponForm.checked && couponForm.checked.length">
							<div v-for="(item, index) in couponForm.checked" :key="index" class="pictrue">
								<img :src="item.pic">
								<i class="el-icon-error btndel" @click="handleGoodsRemove(index)" />
							</div>
						</template>
						<div class="upLoadPicBox" @click="changeGood">
							<div class="upLoad">
								<i class="el-icon-camera cameraIconfont" />
							</div>
						</div>
					</div> -->
					<goods-list-model
						ref="goodsList"
						:checked="couponForm.checked"
						:selected.sync="defaultShopId"
						:shop-id="shopId"
						:type="shopType"
						new-model
						@getMultiGoodsInfo="getGoodsItem"
					/>
				</el-form-item>
				<el-form-item label="优惠券面值：" prop="faceValue">
					<el-input-number v-model="couponForm.faceValue" :min="1" label="描述文字" />
				</el-form-item>
				<el-form-item label="使用门槛：">
					<el-radio-group v-model="couponForm.isThreshold">
						<el-radio :label="false">无门槛</el-radio>
						<el-radio :label="true">有门槛</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="couponForm.isThreshold" label="优惠券最低消费：" prop="minConsume">
					<el-input-number v-model="couponForm.minConsume" :min="1" label="描述文字" />
				</el-form-item>
				<el-form-item label="使用有效期：">
					<el-radio-group v-model="couponForm.validityType" :disabled="couponForm.types === 40">
						<!-- <el-radio :label="0">天数</el-radio> -->
						<el-radio :label="1">时间段</el-radio>
						<el-radio :label="2">无限期</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-show="couponForm.validityType == 0" label="使用有效期限（天）：" prop="termOfValidity">
					<el-input-number v-model="couponForm.termOfValidity" :min="1" label="描述文字" />
				</el-form-item>
				<el-form-item
					v-show="couponForm.validityType == 1"
					label="使用有效期限："
					prop="termTime"
					:rules="
						couponForm.validityType == 1
							? [{ required: true, message: '请选择有效期限', trigger: 'change' }]
							: [{ required: false }]
					"
				>
					<el-date-picker
						v-model="couponForm.termTime"
						style="width: 550px"
						type="datetimerange"
						range-separator="至"
						value-format="yyyy-MM-dd HH:mm:ss"
						start-placeholder="开始日期"
						:picker-options="pickerOptions"
						end-placeholder="结束日期"
					/>
				</el-form-item>
				<el-form-item label="领取是否限时：" prop="isTime">
					<el-radio-group v-model="couponForm.isTime" :disabled="couponForm.types === 40">
						<el-radio :label="true">限时</el-radio>
						<el-radio :label="false">不限时</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="couponForm.isTime" label="领取时间：">
					<el-date-picker
						v-model="isForeverTime"
						style="width: 550px"
						type="datetimerange"
						range-separator="至"
						value-format="yyyy-MM-dd HH:mm:ss"
						:picker-options="pickerOptions"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					/>
				</el-form-item>
				<el-form-item v-show="couponForm.types !== 40" label="领取方式：" prop="resource">
					<el-radio-group v-model="couponForm.obtainMethod">
						<el-radio :label="10">手动领取</el-radio>
						<el-radio :label="20">新人券</el-radio>
						<el-radio :label="30">赠送券</el-radio>
						<el-radio :label="40">活动券</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否限量：" prop="isLimit">
					<el-radio-group v-model="couponForm.isLimit" :disabled="couponForm.types === 40">
						<el-radio :label="true">限量</el-radio>
						<el-radio :label="false">不限量</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="couponForm.isLimit" label="发布数量：" prop="pushNum">
					<el-input-number v-model="couponForm.pushNum" :min="1" label="排序" />
				</el-form-item>
				<el-form-item label="排序：" prop="sort">
					<el-input-number v-model="couponForm.sort" :min="0" label="排序" />
				</el-form-item>
				<el-form-item label="状态：" prop="status">
					<el-radio-group v-model="couponForm.status">
						<el-radio :label="true">开启</el-radio>
						<el-radio :label="false">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="说明：" prop="sort">
					<el-input v-model="couponForm.declares" type="textarea"
						placeholder="请输入使用说明"
						maxlength="500" rows="4"
						resize="none"
						show-word-limit autocomplete="off"
					/>
				</el-form-item>
				<el-form-item
					v-show="couponForm.types != 40"
					label="选择可使用的组织架构："
					prop="organId"
					:rules="couponForm.types != 40 ? rules.organId : [{ required: false }]"
				>
					<!-- <el-select
						v-model="couponForm.checkedShop"
						placeholder="选择可使用的店铺"
						filterable
						multiple
						collapse-tags
						value-key="id"
					>
						<el-option
							v-for="item in shopEnabledList"
							:key="item.id"
							:label="item.name"
							:value="`${item.id}|${item.name}`"
						/>
					</el-select>
					<el-button type="primary" class="ml20" @click="AllCheck">全选</el-button> -->
					<organizationTreeSelect v-model="couponForm.organId" />
				</el-form-item>
				<!--大客户店 start-->
				<el-form-item
					v-if="couponForm.types === 40"
					label="选择可使用的大客户店铺："
					prop="checkedMajorShop"
					:rules="couponForm.types == 40 ? rules.checkedShop : [{ required: false }]"
				>
					<el-select
						v-model="couponForm.checkedMajorShop"
						placeholder="选择可使用的店铺"
						filterable
						value-key="id"
					>
						<el-option
							v-for="item in shopEnabledList"
							:key="item.id"
							:label="item.name"
							:value="`${item.id}|${item.name}`"
						/>
					</el-select>
				</el-form-item>
				<el-form-item
					v-if="couponForm.types === 40 && couponForm.checkedMajorShop"
					label="选择商品："
					prop="checked"
				>
					<!-- <div class="flex-row">
						<template v-if="couponForm.checked && couponForm.checked.length">
							<div v-for="(item, index) in couponForm.checked" :key="index" class="pictrue">
								<img :src="item.pic">
								<i class="el-icon-error btndel" @click="handleGoodsRemove(index)" />
							</div>
						</template>
						<div class="upLoadPicBox" @click="changeGood">
							<div class="upLoad">
								<i class="el-icon-camera cameraIconfont" />
							</div>
						</div>
					</div> -->
					<goods-list-model
						ref="goodsList"
						:checked="couponForm.checked"
						:selected.sync="defaultShopId"
						:shop-id="shopId"
						:type="shopType"
						new-model
						@getMultiGoodsInfo="getGoodsItem"
					/>
				</el-form-item>
				<!--大客户店 end-->

				<el-form-item v-show="checkedShopName.length > 0 && couponForm.types != 40" label="选择的店铺：">
					<el-tag v-for="item in checkedShopName" :key="item.id" size="default">{{ item.name }}</el-tag>
				</el-form-item>
			</el-form>
		</div>

		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button v-throttle="submitForm" type="primary" icon="el-icon-check" :loading="loading">
					立即创建
				</el-button>
				<el-button type="text" @click="addEditDialog = false">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { couponAdd, couponInfo } from "api/business/marketing/coupon.js"
import GoodsListModel from "../../components/GoodsListModel.vue"

import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import { mapState, mapActions } from "vuex"
import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"

export default {
	name: "CouponAdd",
	components: {
		GoodsListModel,
		ProdCategoryTree,
		organizationTreeSelect
	},

	computed: {
		...mapState("dict", ["shoplist", "keyAccountShopList"]),

		shopEnabledList() {
			if (this.couponForm.types == 40) {
				// 大客户店铺
				return this.keyAccountShopList
			} else {
				return this.shoplist
			}
		},

		checkedShopName() {
			let temp = []
			this.couponForm.checkedShop.forEach(item => {
				temp.push({
					id: item.split("|")[0],
					name: item.split("|")[1]
				})
			})
			return temp
		},

		shopType() {
			if (this.couponForm.types == 40) {
				return "major"
			} else {
				return "ware"
			}
		},

		shopId() {
			if (this.couponForm.checkedMajorShop) {
				return parseInt(this.couponForm.checkedMajorShop.split("|")[0])
			} else {
				return 0
			}
		}
	},

	data() {
		return {
			title: "添加优惠券",
			addEditDialog: false,
			loading: false,
			couponForm: {
				id: "",
				name: "",
				faceValue: 1, // 面值
				isThreshold: false, // 门槛
				minConsume: 1, // 最低消费
				validityType: 2, // 使用有效期 0-天数;1-时间段
				termOfValidity: "", // 使用有效期限
				isTime: false, // 领取是否限时 0-不限时;1-限时
				receiveTime: "", // 领取时间
				obtainMethod: 10, // 领取方式  10-手动领取;20-新人券;30-赠送券
				isLimit: false, // 是否限量;false-不限量;true-限量
				pushNum: 1, // 发布数量
				surplusNum: 1, // 剩余数量
				sort: 0, // 排序
				status: true, // 状态;false-关闭;true-开启
				types: 10, // 优惠券类型:10-通用券;20-商品券;30-品类券
				isLottery: false, // 是否抽奖券
				organId: "", // 组织架构
				pmsId: "", // 商品id
				pic: "", // 商品主图
				pmsType: "", // 品类id
				pmsTypeName: "", // 品类名称
				declares: "", // 优惠券说明
				shop: "", // 选择的可用店铺
				checkedShop: [],
				checkedMajorShop: "",
				checked: [], // 选择的商品
				termTime: []
			},

			rules: {
				name: [{ required: true, message: "请输入优惠券名称", trigger: "blur" }],
				termOfValidity: [{ required: true, message: "请输入使用有效期限（天）", trigger: "blur" }],
				faceValue: [{ required: true, message: "请输入优惠券面值", trigger: "blur" }],
				pmsType: [{ required: true, message: "请选择品类", trigger: "change" }],
				checked: [{ required: true, message: "请至少选择一个商品", trigger: "change", type: "array" }],
				isForeverTime: [{ required: true, message: "请选择领取时间", trigger: "change", type: "array" }],
				pushNum: [{ required: true, message: "请输入发布数量", trigger: "blur" }],
				minConsume: [{ required: true, message: "请输入最低消费", trigger: "blur" }],
				checkedShop: [{ required: true, message: "请选择可使用的店铺", trigger: "change" }],
				organId: [{ required: true, message: "请选择可使用的组织架构", trigger: "change" }]
			},

			termTime: [], // 有效期
			isForeverTime: [], // 领取时间段
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},

			// shoplist:[],
			checkedShop: [],
			defaultShopId: []

		}
	},

	mounted() {
		// this.getShopList()
		// if( this.$route.params.id ){
		//     this.getCouponInfo()
		// }
	},

	methods: {
		...mapActions("dict", ["getShopList", "getKeyAccountShopList"]),
		open(recode) {
			this.addEditDialog = true

			if (this.shoplist.length == 0) {
				this.getShopList()
			}

			if (this.keyAccountShopList.length == 0) {
				this.getKeyAccountShopList()
			}

			if (recode && recode.id) {
				this.getCouponInfo(recode.id)
			} else {
				this.couponForm = {
					id: "",
					name: "",
					faceValue: 1, // 面值
					isThreshold: false, // 门槛
					minConsume: 1, // 最低消费
					validityType: 2, // 使用有效期 0-天数;1-时间段
					termOfValidity: "", // 使用有效期限
					isTime: false, // 领取是否限时 0-不限时;1-限时
					receiveTime: "", // 领取时间
					obtainMethod: 10, // 领取方式  10-手动领取;20-新人券;30-赠送券
					isLimit: false, // 是否限量;false-不限量;true-限量
					pushNum: 1, // 发布数量
					surplusNum: 1, // 剩余数量
					sort: 0, // 排序
					status: true, // 状态;false-关闭;true-开启
					types: 10, // 优惠券类型:10-通用券;20-商品券;30-品类券

					pmsId: "", // 商品id
					pic: "", // 商品主图
					pmsType: "", // 品类id
					pmsTypeName: "", // 品类名称
					declares: "",
					shop: "", // 选择的可用店铺
					checkedShop: [],
					checked: [], // 选择的商品
					termTime: []
				}
				this.defaultShopId = []
				this.checkedShop = []
			}
		},

		AllCheck() {
			let temp = []
			this.shopEnabledList.forEach(item => {
				temp.push(`${item.id}|${item.name}`)
			})
			this.couponForm.checkedShop = temp
		},
		changeShop() {
			// 清空店铺
			this.couponForm.checkedShop = []
			if (this.couponForm.types === 40) {
				this.couponForm.validityType = 2
				this.couponForm.isTime = false
				this.couponForm.isLimit = false
			}
		},
		getCouponInfo(id) {
			couponInfo(id).then(res => {
				this.couponForm = Object.assign(this.couponForm, res.data)

				this.couponForm.id = ""

				if (this.couponForm.isTime) this.isForeverTime = this.couponForm.receiveTime.split(",")
				if (this.couponForm.validityType) this.couponForm.termTime = this.couponForm.termOfValidity.split(",")
				this.defaultShopId = this.couponForm.pmsId.split(",").map(Number)

				this.couponForm.shop = ""
				this.couponForm.checkedShop = []

				// this.couponForm.checked = this.couponForm.products

				// if (this.couponForm.shop != "" && this.couponForm.shop != null) {
				// 	let shopArr = JSON.parse(this.couponForm.shop)

				// 	let tmp = []
				// 	shopArr.forEach((item) => {
				// 		let itemJson = JSON.parse(item)
				// 		tmp.push(`${itemJson.id}|${itemJson.name}`)
				// 	})

				// 	this.$set(this.couponForm, "checkedShop", tmp)
				// } else if (this.couponForm.shop == "") {
				// 	// shop = '' 时，代表选择所有店铺

				// 	let tmp = []
				// 	this.shoplist.forEach((item) => {
				// 		tmp.push(`${item.id}|${item.name}`)
				// 	})

				// 	this.$set(this.couponForm, "checkedShop", tmp)
				// }
			})
		},

		changeGood() {
			this.$refs.goodsList.open()
		},

		getGoodsItem(goodsInfo) {
			this.couponForm.checked = goodsInfo
			// this.couponForm.pmsId = this.couponForm.checked.map(item => item.id).join(',')
		},

		handleGoodsRemove(index) {
			this.couponForm.checked.splice(index, 1)
		},

		submitForm() {
			// if( (this.couponForm.validityType && !this.couponForm.termTime) || this.couponForm.validityType && !this.couponForm.termTime.length) return this.$message.warning("请选择使用有效期限")
			if (
				(this.couponForm.isTime && !this.isForeverTime) ||
				(this.couponForm.isTime && !this.isForeverTime.length)
			)
				return this.$message.warning("请选择请选择领取时间")
			// if( this.couponForm.types === 2 ) this.couponForm.primaryKey = this.couponForm.checked.map(item => {return item.id}).join(',')
			// if( this.couponForm.types === 1 ) this.couponForm.primaryKey = ''
			if (!this.couponForm.isThreshold) this.couponForm.minConsume = 0
			if (!this.couponForm.isLimit) this.couponForm.pushNum = 0
			if (this.couponForm.validityType && this.couponForm.termTime.length)
				this.couponForm.termOfValidity = this.couponForm.termTime.join(",")

			if (this.couponForm.isTime && this.isForeverTime.length)
				this.couponForm.receiveTime = this.isForeverTime.join(",")

			if (this.couponForm.types == 20 && this.couponForm.checked.length > 0) {
				this.couponForm.pmsId = this.defaultShopId.join(",")
				// this.couponForm.pmsId = this.couponForm.checked.map(item => item.id).join(",")
			} else if (this.couponForm.types == 40 && this.couponForm.checked.length > 0) {
				// this.couponForm.pmsId = this.couponForm.checked.map(item => item.id).join(",")
				this.couponForm.pmsId = this.defaultShopId.join(",")
			} else {
				this.couponForm.pmsId = ""
			}
			this.couponForm.surplusNum = this.couponForm.pushNum

			// 如果是大客户（40）则采用特殊数组做处理
			if (this.couponForm.types == 40) {
				this.couponForm.shop =
					this.couponForm.checkedMajorShop && this.couponForm.checkedMajorShop.split("|")[0]
				this.couponForm.obtainMethod = 30
			} else if (this.couponForm.checkedShop && this.couponForm.checkedShop.length > 0) {
				// 遍历抽出选择ID数组
				let temp = []
				this.couponForm.checkedShop.forEach(item => {
					temp.push(JSON.stringify(Number(item.split("|")[0])))
				})
				// 如果选择等于列表数（或全选），即为空，否则按选择id字符串
				if (this.couponForm.checkedShop.length == this.shopEnabledList.length) this.couponForm.shop = ""
				else this.couponForm.shop = temp.join()
			}
			// 如果大客户，则去除领取方式
			this.$refs["couponForm"].validate(valid => {
				if (valid) {
					this.loading = true

					couponAdd(this.couponForm)
						.then(() => {
							this.$message.success("新增成功")
							this.loading = false
							this.$refs.couponForm.clearValidate()
							// this.$router.push({ path: `/marketingManager/coupon/list` })
							this.addEditDialog = false
							this.$emit("saveSuccess")
						})
						.catch(() => {
							this.loading = false
						})
				} else {
					this.loading = false
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.el-tag + .el-tag {
	margin-left: 10px;
	margin-top: 10px;
}
</style>
