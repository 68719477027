<template>
	<div class="mvk-layout-content-card">
		<div class="flex-row" style="justify-content: space-between">
			<div>
				<el-button v-has="'ums:clerkshop:create'" size="small" round type="primary" @click="handleAdd">
					新增
				</el-button>
			</div>

			<div class="flex-row" style="justify-content: space-between">
				<!-- <router-link :to=" { path:'/selfManager/creatProduct' } " v-has="'pms:product:add'">
				<el-button size="small" round type="primary" class="mr10">添加商品</el-button>
			</router-link> -->
				<el-form size="small" label-width="76px" :inline="true">
					<el-form-item label="门店名称">
						<el-select v-model="queryParam.shopId" placeholder="选择门店" clearable filterable>
							<el-option v-for="item in shoplist" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="店员搜索">
						<el-input v-model="queryParam.realName" placeholder="输入店员名称搜索" clearable />
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model="queryParam.mobile" placeholder="输入手机号搜索" clearable />
					</el-form-item>
				</el-form>
				<el-button icon="el-icon-refresh-right" style="height: 32px" @click="clearFilter()">重置</el-button>
			</div>
			<!-- <el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="店铺名称：">
					<el-select v-model="queryParam.shopId" placeholder="店铺名称" clearable filterable>
						<el-option v-for="item in shoplist"
							:key="item.id"
							:label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
                </el-form-item>
				<el-form-item label="店员名称：">
                    <el-input v-model="queryParam.clerkName" placeholder="店员名称"></el-input>
                </el-form-item>
			</el-form> -->
		</div>
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			class="mt15"
			row-key="cid"
			lazy
			:load="handleLoad"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<!-- :row-class-name="tableRowClassName" -->
			<template slot="grade" slot-scope="{ row }" label="职级">
				<el-tag v-if="row.grade == 'MANAGER'">店长</el-tag>
				<el-tag v-else-if="row.grade == 'ASSISTANT'" type="success">店员</el-tag>
				<el-tag v-else type="warning">{{ row.grade }}</el-tag>
			</template>
			<el-table-column label="操作" width="180">
				<template slot-scope="{ row }">
					<delete-button
						v-if="!row.hasChildren && row.grade == 'ASSISTANT'"
						:id="row"
						v-has="'ums:clerkshop:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit-dialog
			v-if="hasBtnPermission('ums:clerkshop:create')"
			ref="modalForm"
			@saveSuccess="handleAddOrEditSave"
		/>
	</div>
</template>

<script>
import { clerkList, deleteClerk, getDetailShop } from "api/business/selfManager/clerk"
import { listMixin } from "views/mixin/listMixin"
import { commonMixin } from "../goods/mixin"
import { permissionMixin } from "views/mixin/permissionMixin"
export default {
	name: "ClerkIndex",
	components: {
		"add-edit-dialog": () => import("./addEdit.vue")
	},
	mixins: [listMixin, permissionMixin, commonMixin],
	data() {
		return {
			queryParam: {
				shopId: "",
				realName: "",
				mobile: ""

				// clerkName:'',
			},

			tableColumn: [
				// { prop:'userId', label:'ID'},
				{ prop: "nickName", label: "店员昵称" },
				{ prop: "realName", label: "店员名称" },
				{ prop: "mobile", label: "手机号码" },
				{ prop: "grade", label: "职级", slot: true },
				{ prop: "shopNames", label: "管理门店" }
			],

			tableDataFunc: parameter => {
				return clerkList(Object.assign(parameter, this.queryParam)).then(res => {
					let data = res.data
					data.content &&
						data.content.forEach((item, index) => {
							// if(item.shopNames && item.shopNames.split(',').length > 1){

							// }
							item.cid = item.userId + "" + index
							this.$set(item, "hasChildren", true)
						})
					return data
				})
			},

			maps: new Map()
		}
	},

	mounted() {},

	methods: {
		clearFilter() {
			Object.keys(this.queryParam).forEach(key => (this.queryParam[key] = ""))
		},
		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
			for (var key of this.maps) {
				let { tree, treeNode, resolve } = key[1]
				this.$set(this.$refs.mvkTable.$refs.tableTemplate.store.states.lazyTreeNodeMap, key, [])
				this.handleLoad(tree, treeNode, resolve)
			}
		},

		handleDelete(row) {
			let data = row.id
			deleteClerk(data.id)
				.then(() => {
					this.$notify.success({
						title: "删除成功"
					})
					this.$refs.mvkTable.refresh()

					const { tree, treeNode, resolve } = this.maps.get(data.parentId)
					this.$set(this.$refs.mvkTable.$refs.tableTemplate.store.states.lazyTreeNodeMap, data.userId, [])
					this.handleLoad(tree, treeNode, resolve)
				})
				.catch(() => {
					this.$notify.error({
						title: "删除失败"
					})
				})
		},

		handleLoad(tree, treeNode, resolve) {
			this.maps.set(tree.cid, { tree, treeNode, resolve })
			getDetailShop({
				userId: tree.userId,
				grade: tree.grade,
				pageNum: 0,
				pageSize: 100
			}).then(res => {
				// console.log(res);
				let data = res.data.content
				data.forEach(item => {
					item.cid = item.userId + "" + item.id
					item.shopNames = item.shopName
					item.parentId = tree.cid
				})
				resolve(data)
			})
		}
	}
}
</script>
