<template>
    <el-upload
        v-bind="$attrs"
        action
        :accept="'image/*'"
        :http-request="handleUploadForm"
        list-type="picture-card"
        :show-file-list="false"
        :before-upload="beforeUpload"
        v-on="$listeners"
    >
        <img v-if="imageUrl" :src="imageUrl" class="uploadImg">
        <i v-else class="el-icon-upload" />
        <div slot="tip" class="el-upload__tip">{{ uploadTipText }}</div>
    </el-upload>
</template>

<script>
// 微信的素材上传
import { uploadMedia } from "api/business/broadcast/media"

export default {
    name: "UploadImgWx",
    props: {
        imgUrl: {
            type: String
        },
        uploadTipText: {
            type: String,
            defalut: ""
        },
        fileSize: {
            type: Number
        },
        fileSizeUnit: {
            type: String
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        }
    },
    components: {},
    computed: {},
    data() {
        return {
            imageUrl: this.imgUrl,
            imgWHFlag: true
        }
    },
    watch: {
        imgUrl: function(newVal) {
            this.imageUrl = newVal
        },
        imageUrl: function(newVal) {
            this.$emit("update:imgUrl", newVal)
        }
    },
    mounted() {},

    methods: {
        // 判断图片尺寸
        async handleValidImgWH(file) {
            var vm = this
            return await new Promise((resolve, reject) => {
                var img = new Image()
                img.src = URL.createObjectURL(file)
                img.onload = function() {
                    if (this.width > vm.width || this.height > vm.height) {
                        vm.$message.error(`上传图片尺寸不能超过 ${vm.width}像素 * ${vm.height}像素!`)
                        vm.imgWHFlag = false
                        reject(false)
                    } else {
                        vm.imgWHFlag = true
                        resolve(true)
                    }
                }
            })
        },

        async beforeUpload(file) {
            if (this.width && this.height) {
                await this.handleValidImgWH(file)
                return false
            }

            if (this.fileSize && this.fileSizeUnit) {
                let isLt = true

                if (this.fileSizeUnit == "M") {
                    isLt = file.size / 1024 / 1024 < this.fileSize
                } else if (this.fileSizeUnit == "KB") {
                    isLt = file.size / 1024 < this.fileSize
                }
                if (!isLt) {
                    this.$emit("input", "")
                    this.imageUrl = ""
                    this.imgWHFlag = false
                    this.$message.error(`上传图片大小不能超过 ${this.fileSize}${this.fileSizeUnit}!`)
                } else {
                    this.imgWHFlag = true
                }
                return isLt
            }
        },

        handleUploadForm(param) {
            // console.log(this.imgWHFlag);
            if (this.imgWHFlag) {
                const formData = new FormData()
                formData.append("multipart", param.file)
                uploadMedia(formData)
                    .then(res => {
                        // console.log(res);
                        this.imageUrl = res.data.url
                        this.$emit("input", res.data.mediaId)
                    })
                    .catch(() => {})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}

.uploadImg {
	width: 100%;
	height: 100%;
	border-radius: 6px;
}

.el-upload__tip {
	color: #acacac;
}
</style>
