<template>
	<div class="mvk-layout-content-card">
		<el-steps :active="currentTab" align-center finish-status="success">
			<el-step title="选择秒杀商品" />
			<el-step title="填写基础信息" />
			<el-step title="修改商品详情" />
		</el-steps>
		<el-form
			ref="productForm"
			v-loading="fullscreenLoading"
			:model="productForm"
			:rules="productRules"
			label-width="150px"
			:inline="false"
			@submit.native.prevent
		>
			<!-- 秒杀商品-->
			<div v-show="currentTab === 0">
				<el-form-item label="选择商品：" prop="productId">
					<div class="flex-row">
						<div class="upLoadPicBox" @click="changeGood">
							<div class="upLoad">
								<img v-if="productForm.productId != ''" :src="productForm.pic">
								<i v-else class="el-icon-camera cameraIconfont" />
							</div>
						</div>
					</div>
				</el-form-item>
			</div>
			<!-- 商品信息-->
			<div v-show="currentTab === 1">
				<el-row :gutter="24">
					<el-col :span="24">
						<el-form-item label="商品主图：" prop="productId">
							<upload-image ref="imageModel" v-model="productForm.pic" :image-url="productForm.pic" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="商品轮播图：" prop="imgsArr">
							<upload-image
								ref="imageDragModel"
								v-model="productForm.imgsArr"
								:image-array="productForm.imgsArr"
								:draggable="true"
								:limit="10"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="商品标题：" prop="title">
							<el-input v-model="productForm.title" maxlength="249" placeholder="请输入商品名称" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="秒杀活动简介：">
							<el-input
								v-model="productForm.brief"
								type="textarea"
								maxlength="250"
								:rows="3"
								placeholder="请输入商品简介"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="单位：" prop="unit">
							<el-input v-model="productForm.unit" placeholder="请输入单位" class="selWidth" />
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="运费模板：" prop="tempId">
							<el-select v-model="productForm.tempId" placeholder="请选择" class="selWidth">
								<el-option
									v-for="item in shippingLists"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="当天参与活动次数：" prop="num">
							<el-input-number
								v-model="productForm.num"
								:step="1"
								step-strictly
								:min="1"
								placeholder="请输入活动次数"
								class="selWidth"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="活动日期：" prop="timeVal">
							<el-date-picker
								v-model="productForm.timeVal"
								:default-time="['00:00:00', '23:59:59']"
								value-format="timestamp"
								format="yyyy-MM-dd"
								size="small"
								type="daterange"
								placement="bottom-end"
								:picker-options="pickerOptions"
								placeholder="请选择活动日期"
								@change="onchangeTime"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="活动时间：" prop="timeId">
							<el-select v-model="productForm.timeId" placeholder="请选择" class="selWidth">
								<el-option
									v-for="item in seckillTime"
									:key="item.id"
									:label="item.rangeTime"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="活动状态：" required>
							<el-radio-group v-model="productForm.status">
								<el-radio :label="true">开启</el-radio>
								<el-radio :label="false" class="radio">关闭</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<!-- 规格表格-->
					<el-col :span="24">
						<el-form-item label="商品属性：" required prop="skuIds">
							<el-table
								ref="multipleTable"
								:data="productForm.seckillSkuVO"
								tooltip-effect="dark"
								style="width: 100%"
								@selection-change="handleSelectionChange"
								@select="handleTableChange"
							>
								<el-table-column key="1" type="selection" width="55" />
								<template v-if="manyTabDate">
									<el-table-column
										v-for="(item, iii) in manyTabDate"
										:key="iii"
										:prop="iii"
										align="center"
										:label="manyTabTit[iii].title"
										min-width="80"
									/>
								</template>
								<el-table-column key="pic" align="center" label="图片" min-width="80" prop="pic">
									<template slot-scope="scope">
										<div class="upLoadPicBox">
											<div v-if="scope.row.pic" class="pictrue">
												<img :src="scope.row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/60x'">
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column
									v-for="item in tableColumn"
									:key="item.prop"
									:label="item.label"
									:prop="item.prop"
									align="center"
									min-width="140"
								>
									<template slot-scope="scope">
										<el-form-item
											v-if="item.label === '秒杀价'"
											:key="'seckillSkuVO.' + scope.$index + '.' + item.prop"
											:rules="[{ required: true, message: '请输入秒杀价', trigger: 'change' }]"
											:prop="'seckillSkuVO.' + scope.$index + '.' + item.prop"
										>
											<el-input-number
												v-model="scope.row[item.prop]"
												size="small"
												:min="0"
												:precision="2"
												:step="0.1"
											/>
										</el-form-item>

										<el-form-item
											v-else-if="item.label === '限量'"
											:key="'seckillSkuVO.' + scope.$index + '.' + item.prop"
											:rules="[
												{ required: true, message: '请输入限量', trigger: 'change' },
												{ validator: stockValid, trigger: 'change' }
											]"
											:prop="'seckillSkuVO.' + scope.$index + '.' + item.prop"
										>
											<el-input-number
												v-model="scope.row[item.prop]"
												size="small"
												type="number"
												:min="1"
												:max="scope.row.actualStocks"
												:step="1"
												step-strictly
											/>
										</el-form-item>

										<span v-else>
											{{ scope.row[item.prop] }}
										</span>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
			<!-- 商品详情-->
			<div v-show="currentTab === 2">
				<el-form-item label="商品详情：">
					<ueditor-from v-model="productForm.content" />
				</el-form-item>
			</div>

			<!--下一步操作-->
			<el-form-item style="margin-top: 30px">
				<el-button
					v-show="(!$route.params.id && currentTab > 0) || ($route.params.id && currentTab === 2)"
					type="primary"
					@click="handleSubmitUp"
				>
					上一步
				</el-button>
				<el-button v-show="currentTab == 0" type="primary" @click="handleSubmitNest1()"> 下一步 </el-button>
				<el-button v-show="currentTab == 1" type="primary" @click="handleSubmitNest2('productForm')">
					下一步
				</el-button>
				<el-button
					v-show="currentTab === 2"
					:loading="loading"
					type="primary"
					class="submission"
					size="small"
					@click="handleSubmit('productForm')"
				>
					提交
				</el-button>
			</el-form-item>
		</el-form>
		<!--展示仓库中的商品-->
		<goods-list-model ref="goodsList" supplier :multi="false" type="ware" @getGoodsItem="getGoodsItem" />
	</div>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import UploadImage from "views/bussiness/components/UploadImage"
import UeditorFrom from "@/components/UeditorFrom"

import { prodDetail } from "api/business/selfManager/goods.js"
import {
	seckillConfigAll,
	seckillProductAdd,
	seckillProductEdit,
	seckillProductDetail
} from "api/business/marketing/seckill"
import { shippingList } from "@/utils/constants"

import { formatTime, formatDate } from "@/utils/validate"
import { isJson } from "@/utils/utils"

export default {
	name: "SeckillProduct",

	components: {
		GoodsListModel,
		UploadImage,
		UeditorFrom
	},

	directives: {},

	data() {
		return {
			currentTab: 0,
			fullscreenLoading: false,
			loading: false,
			productForm: {
				id: "",
				productId: "",
				pic: "",
				imgs: "",
				imgsArr: [],
				title: "",
				brief: "",
				unit: "",
				tempId: "",
				timeId: "",
				num: 1,
				startTime: "",
				endTime: "",
				timeVal: [],
				status: true,
				seckillSkuVO: [],
				skuIds: [],
				propJson: "",
				propJsonArr: [],
				content: ""
			},
			productRules: {
				productId: [{ required: true, message: "请选择商品", trigger: "change" }],
				title: [{ required: true, message: "请输入商品标题", trigger: "blur" }],
				imgsArr: [{ required: true, message: "请上传商品轮播图", type: "array", trigger: "change" }],
				unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
				tempId: [{ required: true, message: "请选择运费模板", trigger: "change" }],
				num: [{ required: true, message: "请输入活动次数", trigger: "blur" }],
				timeVal: [{ required: true, message: "请选择活动日期", trigger: "change", type: "array" }],
				timeId: [{ required: true, message: "请选择活动时间", trigger: "change" }],
				skuIds: [{ required: true, message: "请选择商品属性", trigger: "change" }]
			},
			stockValid: (rule, value, callback) => {
				if (value == 0) {
					callback(new Error("销量不能为0"))
				} else {
					callback()
				}
			},
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},
			shippingLists: shippingList,
			seckillTime: [],

			tableColumn: [
				{
					prop: "spikePrice",
					label: "秒杀价"
				},
				{
					prop: "costPrice",
					label: "成本价"
				},
				{
					prop: "sellingPrice",
					label: "售价"
				},
				{
					prop: "actualStocks",
					label: "库存"
				},
				{
					prop: "limitedStock",
					label: "限量"
				},
				{
					prop: "skuName",
					label: "商品编号"
				},
				{
					prop: "weight",
					label: "重量(KG)"
				},
				{
					prop: "volume",
					label: "体积(m³)"
				}
			],

			manyTabDate: {},
			manyTabTit: {},
			multipleSelection: []
		}
	},

	mounted() {
		this.getConfigList()

		if (this.$route.params.id) {
			this.setTagsViewTitle()
			this.getDetailInfo()
			this.currentTab = 1
		}
	},

	methods: {
		getConfigList() {
			seckillConfigAll().then(res => {
				this.seckillTime = res.data
			})
		},

		setTagsViewTitle() {
			const title = "编辑秒杀商品"
			const route = Object.assign({}, this.$route, { title: `${title}-${this.$route.params.id}` })
			this.$store.dispatch("tagsView/updateVisitedView", route)
		},

		getDetailInfo() {
			var vm = this
			this.fullscreenLoading = true
			seckillProductDetail(this.$route.params.id).then(res => {
				let data = res.data
				this.productForm = data
				this.productForm.imgsArr = data.imgs.split(",")
				// this.productForm.seckillSkuVO = this.productForm.seckillSkuVO
				this.productForm.skuIds = this.productForm.seckillSkuVO
					.filter(item => item.isChoice)
					.map(item => item.id)
				let timeVal = []
				timeVal.push(Math.round(new Date(this.productForm.startTime)))
				timeVal.push(Math.round(new Date(this.productForm.endTime)))
				this.$set(this.productForm, "timeVal", timeVal)
				this.fullscreenLoading = false

				this.productForm.seckillSkuVO.forEach(item => {
					if (item.isChoice) {
						console.log(item)
						vm.$nextTick(() => {
							vm.$refs.multipleTable.toggleRowSelection(item, true)
						})
					}
					if (item.properties != "") {
						item.properties = JSON.parse(item.properties)

						Object.values(item.properties).forEach((v, i) => {
							item["value" + i] = v
						})
					}
				})

				if (
					this.productForm.propJson != "" &&
					this.productForm.propJson != "[]" &&
					this.productForm.propJson != null
				) {
					let propJsonArr = JSON.parse(this.productForm.propJson)
					let tmp = []
					propJsonArr.forEach((item, index) => {
						tmp.push(JSON.parse(item))

						vm.manyTabTit["value" + index] = { title: JSON.parse(item).attrName }
						vm.manyTabDate["value" + index] = JSON.parse(item).skuValue
					})

					this.productForm.propJsonArr = tmp
				} else {
					this.productForm.propJsonArr = []
				}

				// this.$refs.multipleTable.toggleAllSelection()
			})
		},

		changeGood() {
			this.$refs.goodsList.open()
		},
		getGoodsItem(goodsInfo) {
			var vm = this
			this.productForm.productId = goodsInfo.id
			this.productForm.pic = goodsInfo.pic
			this.fullscreenLoading = true
			prodDetail(goodsInfo.id)
				.then(res => {
					this.productForm.imgsArr = JSON.parse(res.data.imgs)
					this.productForm.title = res.data.prodName
					this.productForm.brief = res.data.brief
					this.productForm.unit = res.data.unit
					this.productForm.tempId = res.data.deliveryTemplateId
					this.productForm.seckillSkuVO = JSON.parse(JSON.stringify(res.data.skus))
					this.productForm.content = res.data.content
					this.productForm.timeId = this.$route.params.timeId ? Number(this.$route.params.timeId) : ""
					this.productForm.propJson = res.data.propJson

					if (res.data.propJson != "") {
						let propJsonArr = JSON.parse(res.data.propJson)
						let tmp = []
						propJsonArr.forEach((item, index) => {
							tmp.push(JSON.parse(item))

							vm.manyTabTit["value" + index] = { title: JSON.parse(item).attrName }
							vm.manyTabDate["value" + index] = JSON.parse(item).skuValue
						})

						this.productForm.propJsonArr = tmp
					}

					if (this.productForm.seckillSkuVO.length > 0) {
						this.productForm.seckillSkuVO.forEach(item => {
							if (item.properties != "") {
								let properties = JSON.parse(item.properties)
								Object.values(properties).forEach((v, i) => {
									if (!item["value" + i]) item["value" + i] = ""
									item["value" + i] = v
								})

								item.properties = properties
							}

							vm.$set(item, "spikePrice", item.sellingPrice)
							vm.$set(item, "limitedStock", item.actualStocks)
							vm.$set(item, "limitedSurplus", item.actualStocks)
							vm.$set(item, "prodId", goodsInfo.id)
							vm.$set(item, "isChoice", false)
						})
					}

					this.fullscreenLoading = false
				})
				.catch(() => {
					this.fullscreenLoading = false
				})
		},

		handleSubmitUp() {
			if (this.currentTab-- < 0) this.currentTab = 0
		},

		handleSubmitNest1() {
			if (!this.productForm.productId) {
				return this.$message.warning("请选择商品！")
			} else {
				this.currentTab++
				// if (!this.$route.params.id) this.getProdect(this.productId);
			}
		},

		handleSubmitNest2(name) {
			this.$refs[name].validate(valid => {
				if (valid) {
					this.currentTab++
				} else {
					return false
				}
			})
		},

		handleSubmit(name) {
			var vm = this
			this.productForm.imgs = this.productForm.imgsArr.join(",")

			// this.productForm.seckillSkuVO.forEach(item => {
			//     item.limitedSurplus = item.limitedStock
			// })

			if (this.productForm.seckillSkuVO.length > 0) {
				this.productForm.seckillSkuVO.forEach(item => {
					if (vm.productForm.skuIds.indexOf(item.id) > -1) {
						item.isChoice = true
					}

					item.limitedSurplus = item.limitedStock

					if (isJson(item.properties)) {
						item.properties = JSON.stringify(item.properties)
					}
				})
			}

			// 多规格商品，只选了其中某些规格
			if (this.productForm.skuIds.length < this.productForm.seckillSkuVO.length) {
				const isChoice = this.productForm.seckillSkuVO.filter(item => item.isChoice)
				let temp = []
				isChoice.forEach(item => {
					let properties = isJson(item.properties) ? item.properties : JSON.parse(item.properties)
					Object.values(properties).forEach((v, i) => {
						if (temp[i]) {
							temp[i].add(v)
						} else {
							temp[i] = new Set()
							temp[i].add(v)
						}
					})
				})
				if (temp.length > 0) {
					temp.forEach((item, index) => {
						vm.productForm.propJsonArr[index].skuValue = Array.from(item)
					})
				}
			}

			let temp = []
			this.productForm.propJsonArr.forEach(item => {
				temp.push(JSON.stringify(item))
			})
			this.productForm.propJson = JSON.stringify(temp)

			// console.log(this.productForm);

			this.$refs[name].validate(valid => {
				if (valid) {
					this.fullscreenLoading = true
					this.loading = true

					this.productForm.id
						? seckillProductEdit(this.productForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改成功"
								})

								this.fullscreenLoading = false
								this.loading = false

								this.$router.push("/marketingManager/seckill/list")
							})
							.catch(() => {
								this.fullscreenLoading = false
								this.loading = false
							})
						: seckillProductAdd(this.productForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "新增成功"
								})

								this.fullscreenLoading = false
								this.loading = false

								this.$router.push("/marketingManager/seckill/list")
							})
							.catch(() => {
								this.fullscreenLoading = false
								this.loading = false
							})
				} else {
					return false
				}
			})
		},

		onchangeTime(e) {
			this.productForm.timeVal = e
			this.productForm.startTime = e ? formatDate(new Date(e[0]), "yyyy-MM-dd HH:mm:ss") : ""
			this.productForm.endTime = e ? formatDate(new Date(e[1]), "yyyy-MM-dd HH:mm:ss") : ""
		},

		handleSelectionChange(val) {
			this.productForm.skuIds = val.map(item => item.id)
		},

		handleTableChange(val, row) {
			row.isChoice = false
		}
	}
}
</script>

<style lang="scss" scoped></style>
