<template>
	<el-table ref="mvkTable" class="mt15" :data="tableData">
		<el-table-column width="55">
			<template slot-scope="scope">
				<el-radio v-model="checkedId" :label="scope.row.productId" @change.native="getInfo(scope.row)">
					<span />
				</el-radio>
			</template>
		</el-table-column>
		<el-table-column prop="productId" label="商品ID" width="180"> </el-table-column>
		<el-table-column prop="pic" label="商品图">
			<template slot-scope="{ row }">
				<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
			</template>
		</el-table-column>
		<el-table-column prop="prodName" label="商品名称"> </el-table-column>
	</el-table>
</template>

<script>
import { getNewComersConfig } from "api/business/marketing/newcomers"
export default {
	name: "AdvertNewcomer",
	props: {
		checkedId: {
			type: Number | String,
			default: 0
		}
	},
	data() {
		return {
			templateRadio: 0,
			tableData: []
		}
	},

	mounted() {
		this.getConfig()
	},

	methods: {
		getConfig() {
			getNewComersConfig().then((res) => {
				this.tableData = res.data.newcomersProductList
			})
		},

		getInfo(info) {
			console.log(info)
			this.$emit("getInfo", info)
		}
	}
}
</script>

<style lang="scss" scoped></style>
