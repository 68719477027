<template>
	<div class="mvk-layout-content-card">
		<el-form ref="form" :model="form" :rules="rules" label-width="180px" :inline="false">
			<el-form-item label="首页广告" prop="open">
				<el-radio-group v-model="form.open">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
			</el-form-item>

			<el-form-item label="文本标题" prop="title">
				<el-input v-model="form.title" class="inputWidth" maxlength="17" show-word-limit clearable />
			</el-form-item>
			<el-form-item label="文本内容" prop="content">
				<!-- <monaco v-if="form.open" ref="monaco" :value="form.content" :show-full-screen="false" :height="200" @editorContent="editorContent" /> -->

				<WangEditor style="width: 100%" :content="form.content" :menus="tool" :showFullScreen="false" @editorContent="getEditorContent" />
			</el-form-item>

			<el-form-item>
				<el-button v-throttle="onSubmit" type="primary">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import * as api from "@/api/basePage/base"
export default {
	data() {
		return {
			form: {
				open: true,
				title: "",
				content: ""
			},
			tool: [
				"head",
				"bold",
				"fontSize",
				"fontName",
				"italic",
				"underline",
				"strikeThrough",
				"indent",
				"lineHeight",
				"foreColor",
				"backColor",
				"list",
				"justify",
				"alertMenuKey",
				"code",
				"splitLine"
			],
			done: false
		}
	},
	created() {},
	mounted() {
		this.getSetting()
	},
	methods: {
		getSetting() {
			api.getNotification().then((res) => {
				if (res.status === "OK") {
					this.form.open = res.data.open
					this.form.title = res.data.title
					this.form.content = res.data.content
					this.done = true
				}
			})
		},
		getEditorContent(data) {
			this.form.content = data
		},
		onSubmit() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					console.log(this.form)
					api.setNotification(this.form).then((res) => {
						if (res.status === "OK") {
							this.$notify.success({
								title: "保存成功"
							})
						}
					})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
	border: 1px solid #eee;
	padding: 8px 0;
	margin: 8px 0;
	position: relative;
}
</style>
