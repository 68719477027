import fetch from "@/axios/fetch"

export function GetIntegralLogs (params) { // 获取积分领取日志
	return fetch({
		url: "/integralLogs",
		method: "get",
		params
	})
}

export function GetIntegral () { // 获取积分配置
	return fetch({
		url: "/integrals",
		method: "get"
	})
}

export function EditIntegral (data) { // 修改积分配置
	return fetch({
		url: "/integrals",
		method: "put",
		data
	})
}

export function AddIntegral (data) { // 新增积分配置
	return fetch({
		url: "/integrals",
		method: "post",
		data
	})
}

export function getSignInConfig () { // 签到配置
	return fetch({
		url: "/signIns",
		method: "get",
	})
}

export function saveSignInConfig (data) { // 新增签到配置
	return fetch({
		url: "/signIns",
		method: "post",
		data
	})
}

export function editSignInConfig (data) { // 修改签到配置
	return fetch({
		url: "/signIns",
		method: "put",
		data
	})
}


// ====================================================================================================
// ==============================================积分商城===============================================
// ====================================================================================================

export function getPointList (params) { // 获取分页查询积分
	return fetch({
		url: "/smsPointsMalls",
		method: "get",
		params
	})
}
export function addPoint (data) { // 新增积分商品
	return fetch({
		url: "/smsPointsMalls",
		method: "post",
		data
	})
}
export function postPonit (data) { // 修改积分商品
	return fetch({
		url: "/smsPointsMalls",
		method: "put",
		data
	})
}

export function delPonit (id) { // 删除积分商品
	return fetch({
		url: "/smsPointsMalls/" + id,
		method: "delete"
	})
}
export function getPointDetail (id) { // 通过ID查询单个积分商品
	return fetch({
		url: "/smsPointsMalls/" + id,
		method: "get"
	})
}
