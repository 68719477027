<template>
	<el-drawer
		:title="title"
		:visible.sync="addEditDialog"
		:close-on-click-modal="false"
		size="50%"
		@close="showGoodsList = false"
	>
		<div class="drawer-content">
			<el-form
				ref="giftCardForm"
				:model="giftCardForm"
				:rules="giftCardRules"
				label-width="150px"
				style="width: 80%"
			>
				<el-form-item prop="name" label="礼品卡名称">
					<el-input v-model="giftCardForm.name" placeholder="请输入礼品卡名称" />
				</el-form-item>
				<el-form-item label="添加商品" prop="productId">
					<div class="flex flex-column-center">
						<div class="upLoadPicBox" @click="changeGood">
							<div class="upLoad">
								<img v-if="giftCardForm.productId != ''" :src="giftCardForm.pic">
								<i v-else class="el-icon-camera cameraIconfont" />
							</div>
						</div>
						<p style="margin-left: 20px">{{ giftCardForm.prodName }}</p>
					</div>
					<p class="cr_999 fs12">只可选择一款商品，选中商品将适用于礼品兑换</p>
				</el-form-item>
				<el-form-item prop="num" label="礼品卡数量">
					<el-input-number
						v-model="giftCardForm.num"
						:min="1"
						label="礼品卡数量"
						:disabled="giftCardForm.id != ''"
					/>
				</el-form-item>
				<el-form-item prop="amount" label="卡券金额">
					<el-input-number v-model="giftCardForm.amount" :min="1" :precision="2" label="卡券金额" />
				</el-form-item>
				<el-form-item prop="type" label="卡券类型">
					<el-select v-model="giftCardForm.type" placeholder="券类型" clearable>
						<el-option
							v-for="(item, ikey) in giftCardTypeMap"
							:key="Number(ikey)"
							:label="item"
							:value="Number(ikey)"
						/>
					</el-select>
				</el-form-item>
				<el-form-item prop="startTime" label="有效期设置">
					<el-date-picker
						v-model="datetime"
						type="datetimerange"
						value-format="timestamp"
						:default-time="['00:00:00', '23:59:59']"
						format="yyyy-MM-dd HH:mm:ss"
						:picker-options="pickerOptions"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="dateTimeChange"
					/>
				</el-form-item>
				<el-form-item prop="remark" label="备注">
					<el-input v-model="giftCardForm.remark" type="textarea" :rows="2" placeholder="请输入备注" />
				</el-form-item>
			</el-form>
		</div>

		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button v-if="title != '详情'" v-throttle="onSubmit" type="primary" class="form-submit">
					保存
				</el-button>
				<el-button type="text" @click="addEditDialog = false">取消</el-button>
			</div>
		</div>

		<!--展示仓库中的商品-->
		<goods-list-model
			v-if="showGoodsList"
			ref="goodsList"
			:multi="false"
			type="ware"
			:delivery-mode="-1"
			@getGoodsItem="getGoodsItem"
		/>
	</el-drawer>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import { giftCardType } from "@/utils/constants"
import { formatDate } from "@/utils/validate"
import { addGiftCardGroup, editGiftCardGroup } from "api/business/marketing/giftCard.js"

const gift = {
	id: "",
	name: "",
	num: "",
	amount: "",
	type: 1,
	startTime: "",
	endTime: "",
	remark: "",
	productId: "",
	pic: "",
	prodName: "",
	productIds: []
}

export default {
	name: "GiftCardAddEdit",
	components: {
		GoodsListModel
	},
	data() {
		return {
			title: "新增",
			addEditDialog: false,
			giftCardForm: Object.assign({}, gift),
			giftCardRules: {
				name: [{ required: true, message: "请输入礼品卡名称", trigger: "blur" }],
				productId: [{ required: true, message: "请选择商品", trigger: "change" }],
				num: [{ required: true, message: "请输入礼品卡数量", trigger: "blur" }],
				amount: [{ required: true, message: "请输入礼品卡金额", trigger: "blur" }],
				type: [{ required: true, message: "请选择礼品卡类型", trigger: "change" }]
				// startTime: [{ required: true, message: "请选择有效期", trigger: "change" }]
			},
			productList: [],
			giftCardTypeMap: giftCardType,
			datetime: [],
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},
			showGoodsList: false
		}
	},

	mounted() {},

	methods: {
		add() {
			this.addEditDialog = true
			this.giftCardForm = JSON.parse(JSON.stringify(gift))
			this.datetime = []
			this.showGoodsList = true
		},
		edit(record) {
			// console.log(record)
			this.addEditDialog = true
			this.showGoodsList = true
			this.giftCardForm = JSON.parse(JSON.stringify(record))
			this.$set(
				this.giftCardForm,
				"productId",
				this.giftCardForm.productVOList && this.giftCardForm.productVOList[0].id
			)
			this.$set(
				this.giftCardForm,
				"pic",
				this.giftCardForm.productVOList && this.giftCardForm.productVOList[0].pic
			)
			this.$set(
				this.giftCardForm,
				"prodName",
				this.giftCardForm.productVOList && this.giftCardForm.productVOList[0].prodName
			)

			let timeVal = []
			timeVal.push(Math.round(new Date(this.giftCardForm.startTime)))
			timeVal.push(Math.round(new Date(this.giftCardForm.endTime)))

			this.$set(this, "datetime", timeVal)
		},

		changeGood() {
			this.$refs.goodsList.open()
		},

		// 选择商品
		getGoodsItem(goodsInfo) {
			// console.log(goodsInfo)
			this.giftCardForm.productId = goodsInfo.id
			this.giftCardForm.pic = goodsInfo.pic
			this.giftCardForm.prodName = goodsInfo.prodName
		},

		dateTimeChange(e) {
			this.giftCardForm.startTime = e ? formatDate(new Date(e[0]), "yyyy-MM-dd HH:mm:ss") : ""
			this.giftCardForm.endTime = e ? formatDate(new Date(e[1]), "yyyy-MM-dd HH:mm:ss") : ""
		},

		onSubmit() {
			this.$refs["giftCardForm"].validate(valid => {
				if (valid) {
					this.giftCardForm.productIds = []
					this.giftCardForm.productIds.push(this.giftCardForm.productId)
					this.giftCardForm.startTime =
						this.datetime && this.datetime.length > 0
							? formatDate(new Date(this.datetime[0]), "yyyy-MM-dd HH:mm:ss")
							: ""
					this.giftCardForm.endTime =
						this.datetime && this.datetime.length > 0
							? formatDate(new Date(this.datetime[1]), "yyyy-MM-dd HH:mm:ss")
							: ""
					this.giftCardForm.id
						? editGiftCardGroup(this.giftCardForm)
							.then(() => {
								this.$notify.success({
									title: "修改成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "修改失败"
								})
							})
						: addGiftCardGroup(this.giftCardForm)
							.then(() => {
								this.$notify.success({
									title: "添加成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "添加失败"
								})
							})
				} else {
					return false
				}
			})
		}
	},
	destroyed() {
		this.showGoodsList = false
	}
}
</script>

<style lang="scss" scoped></style>
