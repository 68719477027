<template>
	<div class="mvk-layout-content-card">
		<el-form :model="queryParam" :inline="true">
			<el-form-item label="状态：">
				<el-select v-model="queryParam.status" placeholder="请选择" clearable @change="selectList">
					<el-option label="关闭" :value="false" />
					<el-option label="开启" :value="true" />
				</el-select>
			</el-form-item>
			<el-form-item label="配置名称：">
				<el-select v-model="queryParam.timeId" placeholder="请选择" clearable @change="selectList">
					<el-option
						v-for="item in seckillTime"
						:key="item.id"
						:label="item.seckillName + ' - ' + item.rangeTime"
						:value="item.id"
					/>
				</el-select>
			</el-form-item>
			<el-form-item label="商品搜索：">
				<el-input v-model="queryParam.product">
					<el-button slot="append" icon="el-icon-search" @click="selectList" />
				</el-input>
			</el-form-item>
		</el-form>

		<router-link v-has="'sms:seckillProduct:add'" :to="{ path: '/marketingManager/seckill/seckillProduct/create' }">
			<el-button size="small" type="primary" class="mr10">添加秒杀商品</el-button>
		</router-link>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<template slot="pic" slot-scope="{ row }" label="轮播图">
				<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
			</template>
			<template slot="status" slot-scope="{ row }" label="状态">
				<el-switch
					v-model="row.status"
					:disabled="!hasBtnPermission('sms:seckillProduct:updateStatus')"
					@change="changeStatus(row)"
				/>
			</template>
			<el-table-column label="操作" fixed="right" width="100">
				<template slot-scope="{ row }">
					<router-link
						v-has="'sms:seckillProduct:edit'"
						:to="{ path: '/marketingManager/seckill/seckillProduct/update/' + row.timeId + '/' + row.id }"
					>
						<el-button type="text" size="small">编辑</el-button>
					</router-link>

					<delete-button
						:id="row.id"
						v-has="'sms:seckillProduct:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>
	</div>
</template>

<script>
import {
	seckillProductList,
	seckillConfigList,
	seckillProductDelect,
	seckillProductStatusChange
} from "api/business/marketing/seckill"
import { permissionMixin } from "views/mixin/permissionMixin"
export default {
	name: "SeckillList",
	components: {},
	mixins: [permissionMixin],
	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: "50"
				},
				{
					prop: "seckillName",
					label: "秒杀名称",
					width: "150",
					tooltip: true
				},
				{
					prop: "rangeDate",
					label: "活动期间",
					formatter: value => {
						return value.startTime.substr(0, 10) + "~" + value.endTime.substr(0, 10)
					},
					width: "180"
				},
				{
					prop: "rangeTime",
					label: "秒杀时段",
					width: "100"
				},
				{
					prop: "pic",
					label: "商品图片",
					slot: true
				},
				{
					prop: "title",
					label: "活动标题",
					width: "200",
					tooltip: true
				},
				{
					prop: "brief",
					label: "活动简介",
					width: "200",
					tooltip: true
				},
				{
					prop: "sellingPrice",
					label: "原价"
				},
				{
					prop: "spikePrice",
					label: "秒杀价"
				},
				{
					prop: "limitedStock",
					label: "限量"
				},
				{
					prop: "limitedSurplus",
					label: "限量剩余"
				},
				{
					prop: "stat",
					label: "秒杀状态"
				},
				{
					prop: "status",
					label: "状态",
					slot: true
				},
				{
					prop: "creationDate",
					label: "创建时间"
				}
			],

			// 查询条件参数
			queryParam: {
				status: "",
				timeId: "",
				product: ""
			},

			tableDataFunc: parameter => {
				return seckillProductList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			seckillTime: []
		}
	},

	mounted() {
		this.getConfigList()
	},

	methods: {
		getConfigList() {
			seckillConfigList().then(res => {
				this.seckillTime = res.data.list
			})
		},

		selectList() {
			this.$refs.mvkTable.refresh()
		},

		changeStatus(row) {
			seckillProductStatusChange({
				id: row.id,
				status: row.status
			}).then(() => {
				this.$notify.success({
					title: "成功",
					message: "修改成功"
				})

				this.selectList()
			})
		},

		handleDelete(data) {
			seckillProductDelect(data.id).then(() => {
				this.$notify.success({
					title: "成功",
					message: "删除成功"
				})

				this.selectList()
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
