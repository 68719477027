<template>
	<el-dialog title="商品库存设置" :visible.sync="show" :close-on-click-modal="false" top="20vh" width="580px">
		<div class="title">
			<div>商品名:<span>{{ item.prodName }}</span></div>
			<div>店铺名:<span>{{ item.shopName }}</span></div>
		</div>
		<el-form 
			v-if="item.skus"
			ref="form"
			:model="item"
			class="goods_stock_add"
		>
			<el-table :data="item.skus" size="mini">
				<el-table-column prop="skuName" label="SKU名称" min-width="120" show-overflow-tooltip />

				<el-table-column label="上架数量" prop="limitCount" width="100" align="center" />
				<el-table-column label="剩余上架数量" prop="stocks" width="100" align="center" />
				<el-table-column prop="adds" label="追加上架数量" width="120">
					<template slot-scope="scope">
						<el-form-item
							:rules="[
								{ required: true, message: '请设置追加数量', trigger: 'blur' },{ validator: stockValid }
							]"
							:prop="'skus.' + scope.$index + '.adds'"
						>
							<el-input v-model="scope.row.adds" style="margin-top:10px;" type="number" :min="0" />
						</el-form-item>
					</template>
				</el-table-column>
			</el-table>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submit()">确认追加</el-button>
			<el-button @click="show = false">取消</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { addShelf } from "api/business/selfManager/goods.js"

export default {
	// 追加上架数量
	name: "GoodsStockAdd",
	data() {
		return {
			show: false,
			stockShopList: [],
			form: {},
			formRules: {},
			item: {
			},
			stockValid: (rule, value, callback) => {
				console.log(value)
				if (!value && value !== 0) {
					callback(new Error("库存不能为空"))
				} else {
					callback()
				}
			}
		}
	},
	methods: {
		open(row) {
			const data = JSON.parse(JSON.stringify(row))
			data.skus = data.skus.map(e => {
				e.adds = 0
				return e
			})
			this.item = data
			this.show = true
		},
		submit() {
			this.$refs.form.validate(valid => {
				console.log("valid", valid)
				if (valid) {
					console.log("验证通过", this.item.skus)
					const addOnItems = []
					this.item.skus.forEach(e => {
						addOnItems.push({
							adds: Number(e.adds) || 0,
							shopSkuId: e.id,
							shopId: this.item.shopId
						})
					})
					console.log(addOnItems)
					addShelf({ addOnItems }).then(() => {
						this.$notify.success({
							title: "成功",
							message: "库存成功添加"
						})

						this.show = false
						this.$emit("success")
					})
				} else {
					console.log("验证失败")
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.title{
    padding-left: 10px;
    margin-bottom: 16px;
	span{
		margin-left: 10px;
		color: #666;
	}
}
</style>

<style>
.goods_stock_add .el-table__cell{
	padding: 0 !important;
}
.goods_stock_add .el-form-item--small.el-form-item{
	margin-bottom: 10px;
}
</style>
