// list 转成树形json
export function listToTree(list, parentId) {
	const ret = []
	for (const i in list) {
		if (list[i].parentId === parentId) {
			const leftList = list.filter(l => l.id !== list[i].id)
			list[i].children = listToTree(leftList, list[i].id)
			ret.push(list[i])
		}
	}
	return ret
}

export function treeToList(treeList, res) {
	// const res = []
	treeList.forEach(item => {
		if (item.children.length > 0) {
			res.concat(treeToList(item.children, res))
		}

		res.push(item)
	})

	return res
}

// 判断一维数组是否存在重复数据
export function arrRepeat(arr) {
	let repeat = false

	var tempArr = new Array()

	for (let i = 0; i < arr.length; i++) {
		if (tempArr[arr[i]]) {
			repeat = true
			break
		}

		tempArr[arr[i]] = true
	}

	return repeat

}

/**
 * 查找枚举label
 * 为了label和value中符合value的值，空则"全部"
 * @param {Array} arr 
 * @param {String} value 
 * @returns 
 */
export const FindArrObjToLabel = (arr, value) => { 
	if (!value) return "全部"
	return arr.find(e => e.value == value).label
}
/**
 * var arr = [
            {
                name:'root',
                user_name:'root',
                id:1
            },
            {
                name:'123',
                user_name:'123',
                id:2
            }
        ]
 *  转成 arr = [
     {key:name,value:[root,123]}
     ...
 ]
 */
export function arrToJson(arr) {
	if (arr.length == 0) {
		return []
	}

	let arrValue = []
	arr.forEach((jsonItem, index) => {

		if (index == 0) {
			Object.keys(jsonItem).forEach(key => {
				let temp = {}
				temp.key = key
				temp.value = [],
				temp.value.push(jsonItem[key])
				arrValue.push(temp)
			})
		} else {
			arrValue.forEach(arrItem => {
				arrItem.value.push(jsonItem[arrItem.key])
			})
		}

	})

	return arrValue
}

/**
 * 寻找不满足条件的父节点
 * @param {*} parent  父节点数组
 * @param {*} key     匹配的key 字符串
 * @param {*} children  children的字符串
 * @param {*} allArray  对比的数组
 * @param {*} notExistent  不存在的数组
 * @param {*} type    寻找的方式
 * @returns
 */

export function filterNotExistent(parent, key, children, allArray, notExistent, type) {
	// console.log(parent);
	allArray = JSON.parse(JSON.stringify(allArray))
	if (type == "down") { // 向下寻找
		if (parent[children] && parent[children].length > 0) {

			parent[children].forEach(item => {
				item.parent = parent
				return filterNotExistent(item, key, children, allArray, notExistent, "down")
			})
		}

		if (allArray.indexOf(parent[key]) == -1) { // 不存在
			notExistent.push(parent.parent[key])
			return filterNotExistent(parent.parent, key, children, allArray, notExistent, "up")
		}
	} else { // 向上寻找
		if (parent.parent) {
			notExistent.push(parent.parent[key])
			return filterNotExistent(parent.parent, key, children, allArray, notExistent, "up")
		}
	}

	return notExistent
}

export function isJson(obj) {
	var isjson = typeof (obj) == "object" && Object.prototype.toString.call(obj).toLowerCase() == "[object object]" && !obj.length
	return isjson
}

export function isArray(arr) {
	var isarray = typeof (arr) === Array && arr.length > 0
	return isarray
}

/**
 * 生成len位随机码
 * @param {number} len
 * @returns
 */
export function randomCode(len = 6) {
	function getRandom(min, max) {
		return Math.round(Math.random() * (max - min) + min)
	}
	let code = ""
	for (var i = 0; i < len; i++) {
		var type = getRandom(1, 3)
		switch (type) {
			case 1:
				code += String.fromCharCode(getRandom(48, 57))// 数字
				break
			case 2:
				code += String.fromCharCode(getRandom(65, 90))// 大写字母
				break
			case 3:
				code += String.fromCharCode(getRandom(97, 122))// 小写字母
				break
		}
	}
	return code
}

// 防抖
export const debounce = (fn, delay) => {
	let t = delay || 500
	let timer
	return function() {
		clearTimeout(timer)
		timer = setTimeout(() => {
			// 改变this指向并传递参数
			fn.apply(this, arguments)
		}, t)
	}
}
/**
 * [throttle 节流]
 * @param  {function}   fn          [函数]
 * @param  {Number}     interval    [间隔]
 * @return {[type]}                 [description]
 */
export function throttle(fn, interval) {
	var last
	var timer
	var iv = interval || 200
	return function() {
		var th = this
		var args = arguments
		var now = +new Date()
		if (last && now - last < iv) {
			clearTimeout(timer)
			timer = setTimeout(function() {
				last = now
				fn.apply(th, args)
			}, iv)
		} else {
			last = now
			fn.apply(th, args)
		}
	}
}
// 利用 WeekMap() 的键对自己所引用对象的引用都是弱引用的特性，在没有其他引用和该键引用同一对象的情况下，这个对象将会被垃圾回收
// 为了解决循环引用的问题，设置一个哈希表存储已拷贝过的对象进行循环检测，当检测到当前对象已存在于哈希表中时，取出该值并返回即可
export function deepClone(o) {
	// 判断如果不是引用类型，直接返回数据即可
	if (typeof o === "string" || typeof o === "number" || typeof o === "boolean" || typeof o === "undefined") {
		return o
	} else if (Array.isArray(o)) { // 如果是数组，则定义一个新数组，完成复制后返回
		// 注意，这里判断数组不能用typeof，因为typeof Array 返回的是object
		console.log(typeof [])  // --> object
		var _arr = []
		o.forEach(item => { _arr.push(item) })
		return _arr
	} else if (typeof o === "object") {
		var _o = {}
		for (let key in o) {
			_o[key] = this.deepClone(o[key])
		}
		return _o
	}
}

// 计算对象数组中某个属性合计
export function countTotal(arr, keyName) {
	let $total = 0
	// eslint-disable-next-line no-unused-vars
	$total = arr.reduce(function(total, currentValue, currentIndex, arr) {
		return currentValue[keyName] ? total + Number(currentValue[keyName]) : total
	}, 0)
	return $total
}

// 对象转formdata格式
export function jsToFormData(config) {
	const formData = new FormData()
	// 循环传入的值转换formData
	Object.keys(config).forEach(key => {
		formData.append(key, config[key])
	})
	return formData
}
export function filterParams(obj) { // 剔除对象的空属性
	const empty_arr = ["", undefined, null]

	function ArrayFilterParams(arr) { // 剔除数组中的空值
		var err = []
		arr.forEach(item => {
			if (isEmpty(item)) return
			err.push(
				typeof item === "object"
					? (
						item instanceof Array ? ArrayFilterParams(item) : filterParams(item)
					)
					: item
			)
		})
		return err
	}
	function isEmpty(obj) { // 为空情况
		return (empty_arr.indexOf(obj) > -1 || obj.toString().trim() === "")
	}

	var _newObj = {}
	for (var key in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(key)) { // 判断对象中是否有这个属性
			if (isEmpty(obj[key])) continue
			_newObj[key] = typeof obj[key] === "object"
				? (
					obj[key] instanceof Array ? ArrayFilterParams(obj[key]) : filterParams(obj[key])
				)
				: obj[key]

		}
	}
	return _newObj
}

export function queryStringify(obj, prefix) {
	var has = Object.prototype.hasOwnProperty
	var pairs = []
	for (var key in obj) {
		// eslint-disable-next-line no-undef
		if (!has.call(obj, key)) {
			continue
		}
  
		var value = obj[key]
		var enkey = encodeURIComponent(key)
		var pair
		if (typeof value === "object") {
			pair = queryStringify(value, prefix ? prefix + "[" + enkey + "]" : enkey)
		} else {
			pair = (prefix ? prefix + "[" + enkey + "]" : enkey) + "=" + encodeURIComponent(value)
		}
		pairs.push(pair)
	}
	return pairs.join("&")
}
// 文件流下载
export function blobTofile(res, fileName = "", type) {
	const blob = new Blob([res], { type })
	const dlink = document.createElement("a")
	dlink.download = fileName
	dlink.style.display = "none"
	dlink.href = URL.createObjectURL(blob)
	document.body.appendChild(dlink)
	dlink.click()
	URL.revokeObjectURL(dlink.href) // 释放URL 对象
	document.body.removeChild(dlink)
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */
export const getIntersection = (arr1, arr2) => {
	const len = Math.min(arr1.length, arr2.length)
	let i = -1
	const res = []
	while (++i < len) {
		const item = arr2[i]
		if (arr1.indexOf(item) > -1) res.push(item)
	}
	return res
}
  
/**
   * @param {Array} arr1
   * @param {Array} arr2
   * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
   */
export const getUnion = (arr1, arr2) => {
	return Array.from(new Set([...arr1, ...arr2]))
}

/**
   * @param {Array} arr1
   * @param {Array} arr2
   * @description 得到两个数组的差集, 那arr1 多出来的
   */
export const getDifferenceSet = (arr1, arr2) => {
	return [...arr1].filter(x => [...arr2].every(y => y !== x))
}

/**
   * @param {Array} arr
   * @description 去掉重复数值
   */
export const removeRepeat = arr => {
	return Array.from(new Set(arr))
}

/**
   * @param {Array} arr
   * @param {String} key
   * @description 去掉重复对象中指定key的数组
   */
export const removeRepeatArrObj = (arr, key) => { 
	let newJson = [] // 盛放去重后数据的新数组
	for (let item1 of arr) {  // 循环json数组对象的内容
		let flag = true  // 建立标记，判断数据是否重复，true为不重复
		for (let item2 of newJson) {  // 循环新数组的内容
			if (item1[key] == item2[key]) { // 让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
				flag = false
			}
		}
		if (flag) { // 判断是否重复
			newJson.push(item1) // 不重复的放入新数组。  新数组的内容会继续进行上边的循环。
		}
	}
}

/**
   * @param {Array} arr1
   * @param {Array} arr2
   * @description 两个数组对象去除相同的数据
   */
export const arrSubtract = (arr2, arr1) => {
	return arr2.filter(item => {
		return !arr1.some(e => e === item)
	})
}
/**
 * 千分符
 * @param {Number} oldNum
 * @param {Boolean} isFixed 
 */
export const groupSeparator = (oldNum, isFixed) => { // 例（123456.78）
	if (!oldNum) return 0 // 传入的数值为空直接返回空对象
	let newNum = oldNum.toLocaleString("en-US") // 数字转成千分位 = 123,456.78
	const numArr = newNum.split(".") // 按小数点吧数字拆分 = [123,456, 78]
	if (!isFixed) { // 如果传了第二个参数，如果有小数位直接返回，否则向下执行
		if (!numArr[1]) { // 如果数组没有下标1的元素，就加.00，例：123,456 = 123,456.00
			newNum = newNum + ".00"
		} else if (numArr[1].length === 1) { // 如果数组下标1的元素只有一位小数，就加个0，例：123,456.7 = 123,456.70
			newNum = newNum + "0"
		} else if (numArr[1].length >= 2) {  // 如果数组下标1的元素小数位大于等于2位，就截取前两位，例：123,456.789 = 123,456.78
			console.log(numArr)
			newNum = numArr[0] + "." + numArr[1].substring(0, 2)
		}
	}
	return newNum
}