<template>
	<div class="container mvk-layout-content-card">
		<!-- 筛选 -->
		<el-form size="small" label-width="100px">
			<div class="filter flex-row">
				<el-form-item label="账单日期：" class="width100">
					<el-date-picker
						v-model="queryParam.data"
						format="yyyy-MM-dd"
						size="small"
						type="date"
						placeholder="选择日期"
						clearable
					/>
				</el-form-item>
			</div>
		</el-form>
		<!-- 内容 -->

		<!-- 电子券列表 -->
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
			<el-table-column label="操作" width="100" align="center">
				<template slot-scope="{ row }">
					<el-button size="small" style="width: 80px" @click="Download(row)">下载</el-button>
				</template>
			</el-table-column>
		</mvk-table>
	</div>
</template>

<script>
import { getEcouponBill, downloadEcouponBill } from "api/business/marketing/coupon.js"
import { blobTofile } from "@/utils/utils"
export default {
	data() {
		return {
			// 查询条件参数
			queryParam: {
				data: ""
			},

			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "billName",
					label: "账单名称"
				},
				{
					prop: "billDate",
					label: "账单日期"
				},
				{
					prop: "createAt",
					label: "申请时间"
				}
			],

			// eslint-disable-next-line no-unused-vars
			tableDataFunc: parameter => {
				return getEcouponBill(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},
			selectList: []
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.$refs.mvkTable.refresh()
			},
			deep: true
		}
	},
	created() {},
	methods: {
		// 下载
		Download(e) {
			downloadEcouponBill({
				date: e.billDate
			}).then(res => {
				blobTofile(res, "电子券核销统计.xls", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
