<template>
	<div>
		<el-table
			v-bind="$attrs"
			ref="tableTemplate"
			v-loading="loading"
			:data="tableData"
			class="border-table"
			v-on="$listeners"
		>
			<slot name="head" />
			<slot name="expand" />
			<el-table-column v-if="extend" type="expand">
				<slot name="detail" />
			</el-table-column>
			<el-table-column
				v-for="column in tableColumn"
				:key="column.label"
				:prop="column.prop"
				:label="column.label"
				:width="column.width"
				:align="column.align"
				:sortable="column.sortable"
				:min-width="column.minWidth"
				:show-overflow-tooltip="!column.tooltip"
				:formatter="column.formatter"
				:header-align="column.headerAlign"
			>
				<template slot-scope="scope">
					<p v-if="column.vHtml" v-html="scope.row[column.prop]" />

					<template v-else-if="column.slot">
						<slot :name="column.prop" :row="scope.row" :$index="scope.$index" :label="scope.row['$' + column.prop]">
							<!-- {{column.prop}} -->
						</slot>
					</template>
					<span v-else>{{ handleDetail(scope.row, column) }}</span>
				</template>
			</el-table-column>
			<template>
				<slot />
			</template>
		</el-table>
		<div v-if="!noPage" class="pagination-contain">
			<div>
				<template>
					<slot name="footer" />
				</template>
				<el-button
					v-if="reFresh"
					type="primary"
					icon="el-icon-refresh"
					:loading="loading"
					round
					@click="loadData()"
				>
					刷新
				</el-button>
			</div>
			<el-pagination
				background
				:current-page.sync="pageNum"
				:page-size="pageSize"
				:page-sizes="customPageSizes"
				layout="total,sizes,prev, pager, next"
				:total="total"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "TableWithPagination",
	props: {
		tableColumn: {
			// 表格列属性
			type: Array,
			default: () => {
				return []
			},
			require: true
		},
		tableDataFunc: {
			// 表格的数据接口
			type: Function,
			// required: true
			async default() {
				return await {}
			}
		},
		extend: {
			type: Boolean,
			default: false
		},
		reFresh: {
			type: Boolean,
			default: false
		},
		checkedId: {
			type: Array,
			default: () => {
				return []
			}
		},
		customPageSize: {
			type: [Number, String],
			default: 10
		},
		customPageSizes: {
			type: Array,
			default: () => {
				return [10, 20, 50, 100, 200, 500]
			}
		},
		initData: {
			type: Array,
			default: () => {
				return []
			}
		},
		noPage: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		tableDataFunc: {
			handler() {
				// 针对调接口经常变化的情况
				this.refresh()
			},
			deep: true
		}
	},
	computed: {
		checkedIds() {
			return this.checkedId
		},
		params() {
			return {
				pageNum: this.pageNum - 1,
				pageSize: this.pageSize
			}
		}
	},
	data() {
		return {
			tableData: this.initData || [], // 表格的数据

			pageNum: 1,
			pageSize: this.customPageSize || 10,
			total: 0,

			loading: false
		}
	},
	mounted() {},
	created() {
		if (this.initData.length == 0) this.loadData()
	},
	methods: {
		refresh() {
			this.pageNum = 1
			this.loadData()
		},
		// 加载表格的接口，获得返回数据
		loadData() {
			this.loading = true
			let dataPromise = this.tableDataFunc(this.params)
			// 未填写方法内容则跳过请求
			if (!dataPromise) {
				this.loading = false
				this.tableData = []
				this.total = 0
				return
			}
			return new Promise((resolve, reject) => {
				dataPromise
					.then(res => {
						if (!res) return
						this.tableData = res.list || res.content
						this.total = res.totalElements
						// console.log("this.tableData", this.tableData)
						// 默认选上已选的
						this.selectForIds()
						resolve(res)
					})
					.catch(err => {
						reject(err)
					})
					.finally(() => {
						this.loading = false
					})
			})
		},
		selectForIds() {
			if (this.checkedIds?.length > 0) {
				this.$refs.tableTemplate.clearSelection()
				this.checkedIds.forEach(item => {
					let temp = this.tableData.filter(titem => titem.id == item)[0]
					this.$nextTick(() => {
						if (temp && this.$refs.tableTemplate) {
							this.$refs.tableTemplate.toggleRowSelection(temp, true)
						}
					})
				})
			}
		},

		clearSelection() {
			this.$refs.tableTemplate.clearSelection()
		},

		handleDetail(row, column) {
			if (column.formatter) {
				return column.formatter(row, column, row[column.prop])
			} else {
				return row[column.prop]
			}
		},

		// 每页显示个数
		handleSizeChange(val) {
			this.pageSize = val
			this.pageNum = 1
			this.loadData()
		},
		// 当前页变化
		handleCurrentChange(val) {
			this.pageNum = val
			this.loadData().then(() => {
				this.$emit("currentChangeSync")
			})
			this.$emit("currentChange", val)
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
