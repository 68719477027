import fetch from "@/axios/fetch"

export function getLabelList(params) { // 标签列表
	return fetch({
		url: "/label",
		method: "get",
		params
	})
}

export function postLabel(data) { // 新增
	return fetch({
		url: "/label",
		method: "post",
		data
	})
}

export function editLabel(data) { // 修改
	return fetch({
		url: "/label",
		method: "put",
		data
	})
}

export function enabledLabel(id) { // 是否启用
	return fetch({
		url: "/label/enabled/" + id,
		method: "put"
	})
}

export function getLabelDetail(id) { // 查询标签详情
	return fetch({
		url: "/label/" + id,
		method: "get"
	})
}
