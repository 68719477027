<template>
	<div>
		<div class="flex ">
			<div class="filter-box">
				<span>派发时间</span>
				<el-date-picker v-model="timeVal"
					value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small" type="daterange"
					start-placeholder="开始时间" end-placeholder="结束时间" placement="bottom-end" style="width: 240px"
					class="ml10" @change="onchangeTime"
				/>
			</div>
			<el-button type="primary" @click="handleExport()"> 导出报表</el-button>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<template slot="orderCode" slot-scope="{ row }" label="订单">
				<!-- <div v-for="(code,index) in row._orderCode" :key="index">
					<router-link :to="{ path: '/selfManager/order', query: {code} }" class="order-link">{{ code }}</router-link>
				</div> -->
				<router-link v-if="row._orderCode.length"
					:to="{ path: '/selfManager/order', query: { code: row._orderCode.join() } }" class="order-link"
				>
					{{
						row._orderCode.length
					}}个订单
				</router-link>
				<span v-else> - </span>
			</template>
			<template slot="status" slot-scope="{ row }" label="使用状态">
				<span :style="{ color: row._color }">{{ row._statusName }}</span>
			</template>
			<!-- <template slot="termOfValidity" slot-scope="{ row }" label="有效期">
				<span>{{ row.termOfValidity == 1 ? '无限期' : row.termOfValidity }}</span>
			</template> -->
		</mvk-table>
	</div>
</template>

<script>
import { payType, orderType } from "@/utils/constants"
import { getOrder, getOrderExport } from "api/business/selfManager/keyAccount"
import { blobTofile } from "@/utils/utils"

export default {
	name: "",
	data() {
		return {
			timeVal: [],
			queryParam: {
				time: ""
			},
			tableColumn: [
				{ prop: "code", label: "订单编号", width: 200 },
				{ prop: "memberName", label: "购买人", width: 120 },
				{
					prop: "payType", label: "支付类型", width: 76,
					formatter: cellvalue => {
						let value = cellvalue.payType
						return payType[value]
					}
				},
				{
					prop: "payMoney", label: "支付金额", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.payMoney || 0) + "元"
					}
				},
				{
					prop: "actualMoney", label: "实付金额", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.actualMoney || 0) + "元"
					}
				},

				{
					prop: "type", label: "订单类型", width: 76,
					formatter: cellvalue => {
						let value = cellvalue.type
						return orderType[value]
					}
				},
				{ prop: "supplierName", label: "供应商" },
				{ prop: "prodName", label: "商品名称" },

				{
					prop: "couponMoney", label: "优惠金额", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.couponMoney || 0) + "元"
					}
				},
				{ prop: "creationDate", label: "创建时间", width: 150 }
			],

			tableDataFunc: async parameter => {
				if (this.$route.query.id) {
					return getOrder(Object.assign(parameter, this.queryParam), this.$route.query.id).then(res => {
						return res.data
					})
				} else {
					return {}
				}
			}
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.$refs.mvkTable.refresh()
			},
			deep: true
		}
	},
	methods: {
		getList() {
			this.$refs.mvkTable.refresh()
		},
		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.time = e ? e[0] + "~" +  e[1] : ""
		},
		handleExport() {
			getOrderExport(this.queryParam, this.$route.query.id).then(res => {
				blobTofile(res, "专营店订单明细.xls", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.filter-box {
	display: flex;
	align-items: center;
	margin-right: 10px;

	span {
		font-size: 14px;
		color: #333;
	}
}

.order-link {
	color: #4B5EFF;
}
</style>
