<template>
	<div class="mvk-layout-content-card">
		<!-- 列表信息 -->
		<mvk-table v-if="tableData.length" :table-column="tableCol" :init-data="tableData" size="mini" no-page />
		<!-- 筛选 -->
		<el-form size="small" label-width="100px">
			<el-collapse-transition name="el-zoom-in-top">
				<div v-show="!showfilter" class="mt10 flex flex-sb flex-a-e">
					<div>
						<el-button type="primary" icon="el-icon-arrow-down" @click="switchFilter()">展开筛选</el-button>
						<el-button class="ml20" type="warning" :disabled="!selectList.length" @click="cancellation()">
							电子券注销
						</el-button>
					</div>
					<el-button class="ml20" @click="$router.go(-1)">返回</el-button>
				</div>
			</el-collapse-transition>

			<el-collapse-transition>
				<div v-show="showfilter">
					<div class="filter flex-row flex-wrap">
						<el-form-item label="电子券批次号" class="width100">
							<el-input
								v-model="queryParam.batchNo"
								placeholder="请输入电子券批次号"
								size="small"
								clearable
							/>
						</el-form-item>
						<el-form-item label="电子券状态" class="width100">
							<el-select
								v-model="queryParam.state"
								placeholder="请选择电子券状态"
								clearable
								filterable
								style="width: 100%"
							>
								<el-option
									v-for="(val, key, i) in voucherStateList"
									:key="i"
									:label="val"
									:value="key"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="电子券标识" class="width100">
							<el-input
								v-model="queryParam.voucher"
								placeholder="请输入电子券标识"
								size="small"
								clearable
							/>
						</el-form-item>
						<el-form-item label="领取时间" style="width: auto">
							<el-date-picker
								v-model="receiveTime"
								:default-time="['00:00:00', '23:59:59']"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd"
								size="small"
								type="daterange"
								placement="bottom-end"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								style="width: 220px"
								@change="onchangeReceiveTime"
							/>
						</el-form-item>
						<el-form-item label="使用时间" class="mr40" style="width: auto">
							<el-date-picker
								v-model="usedTime"
								:default-time="['00:00:00', '23:59:59']"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd"
								size="small"
								type="daterange"
								placement="bottom-end"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								style="width: 220px"
								@change="onchangeUsedTime"
							/>
						</el-form-item>
						<div class="mt10 flex flex-1 flex-sb flex-a-e">
							<div class="flex">
								<el-button type="primary" icon="el-icon-refresh-right" @click="query()">刷新</el-button>
								<el-button icon="el-icon-refresh-right" @click="clearFilter()">重置</el-button>
								<el-button
									v-show="showfilter"
									type="primary"
									icon="el-icon-arrow-up "
									@click="switchFilter()"
								>
									折叠筛选
								</el-button>
							</div>
							<el-button class="ml20" @click="$router.go(-1)">返回</el-button>
						</div>
					</div>

					<div class="mt10 flex flex-1 flex-sb flex-a-e">
						<el-button type="warning" :disabled="!selectList.length" @click="cancellation()">
							电子券注销
						</el-button>
					</div>
				</div>
			</el-collapse-transition>
		</el-form>
		<!-- 电子券批次列表 -->
		<mvk-table
			ref="voucherList"
			:table-column="tableColumn"
			size="mini"
			class="mt20"
			:table-data-func="tableDataFunc"
			@selection-change="handleSelectionChange"
		>
			<el-table-column slot="head" type="selection" width="55" />
		</mvk-table>
	</div>
</template>

<script>
import { voucherStateList, voucherTypeList, distrStateList } from "@/utils/constants"
import { couponTypeDetail, queryListByAlias, couponCance } from "api/business/marketing/coupon"
import { debounce } from "utils/utils"

export default {
	data() {
		return {
			voucherStateList,
			id: this.$route.query.id,
			showfilter: true,

			queryParam: {
				sort: "",
				alias: "",
				batchNo: "",
				state: "",
				voucher: "",
				receiveTime: "",
				usedTime: ""
			},
			receiveTime: [],
			usedTime: [],
			tableData: [],
			// 详情表格-券类型
			tableCol: [
				{
					prop: "title",
					label: "券名称",
					width: 140
				},
				{
					prop: "couCategory",
					label: "券类型",
					width: 76,
					align: "center",
					formatter: row => {
						return voucherTypeList[row.couCategory]
					}
				},
				{
					prop: "bizTypeName",
					label: "券使用类型",
					width: 86,
					align: "center"
				},
				{
					prop: "couDiscountValue",
					label: "折扣率",
					width: 76,
					align: "center"
				},
				{
					prop: "couDiscountMaxValue",
					label: "最大折扣金额",
					width: 120,
					align: "center"
				},
				{
					prop: "faceValue",
					label: "面值金额",
					width: 76,
					align: "center"
				},
				{
					prop: "cnt",
					label: "库存数量",
					width: 90,
					align: "center"
				},
				{
					prop: "declares",
					label: "券使用说明",
					minWidth: 120,
					vHtml: true
				},
				{
					prop: "bizTypeDesc",
					label: "使用类型描述",
					width: 96,
					align: "center"
				},
				{
					prop: "validDate", // validType
					label: "劵有效期",
					width: 220,
					formatter: cellValue => {
						if (cellValue.validType == 1) {
							return cellValue.validDate && cellValue.validDate.replace("#", "到")
						} else if (cellValue.validType == 2) {
							return cellValue.validDay + "天"
						}
					}
				}
			],
			// 批次列表
			tableColumn: [
				{
					prop: "batchNo",
					label: "电子劵批次号",
					width: 190
				},
				{
					prop: "voucher",
					label: "电子券标识"
				},
				{
					prop: "phone",
					label: "领取用户"
				},
				{
					prop: "distrMethod",
					label: "派发方式"
				},
				{
					prop: "distrState",
					label: "派发状态",
					formatter: row => {
						// return row.distrState
						return distrStateList[row.distrState]
					}
				},
				{
					prop: "createTime",
					label: "申请时间",
					formatter: cellValue => {
						return cellValue.createTime && cellValue.createTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
					}
				},
				{
					prop: "statusName",
					label: "当前状态"
					// formatter: row => {
					// 	return voucherStateList[row.couCategory]
					// }
				},
				{
					prop: "usedTime", // end
					label: "使用时间",
					formatter: cellValue => {
						return cellValue.usedTime && cellValue.usedTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
					}
				}
			],
			// eslint-disable-next-line no-unused-vars
			tableDataFunc: parameter => {
				const params = Object.assign(this.queryParam, { alias: this.alias })
				return queryListByAlias(Object.assign(parameter, params)).then(res => {
					return res.data
				})
			},
			selectList: []
		}
	},
	created() {
		const urlParams = this.$route.query
		this.alias = urlParams.alias
		this.getDetail(urlParams.alias)
	},
	watch: {
		queryParam: {
			handler: debounce(function() {
				this.$refs.voucherList.refresh()
			}, 300),
			deep: true
		}
	},
	methods: {
		// 折叠展开筛选
		switchFilter() {
			this.showfilter = !this.showfilter
		},
		// 清空请求参数
		clearFilter() {
			Object.keys(this.queryParam).forEach(key => (this.queryParam[key] = ""))
			this.usedTime = []
			this.receiveTime = []
		},
		// 券有效期
		onchangeReceiveTime(e) {
			this.receiveTime = e
			this.queryParam.receiveTime = e ? e.join("~") : ""
		},
		// 券有效期
		onchangeUsedTime(e) {
			this.usedTime = e
			this.queryParam.usedTime = e ? e.join("~") : ""
		},

		getDetail(alias) {
			couponTypeDetail(alias).then(res => {
				this.tableData.push(res.data)
			})
		},
		// 查询
		query() {
			this.$refs.voucherList.refresh()
		},
		// 勾选
		handleSelectionChange(val) {
			this.selectList = val
		},
		// 电子券注销
		cancellation() {
			const vouchers = this.selectList.map(e => e.voucher)
			this.$confirm("此操作将所选项进行电子券注销, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "error"
			})
				.then(() => {
					couponCance(vouchers).then(res => {
						if (res.status === "OK") {
							this.$refs.voucherList.refresh()
							this.$message({
								type: "success",
								message: "删除成功!"
							})
						}
					})
				})
				.catch(() => {})
		}
	}
}
</script>

<style lang="scss" scoped>
.el-form-item--small.el-form-item {
	margin-top: 18px;
	margin-bottom: 0;
}
.filter .el-form-item {
	width: 320px;
	margin-right: 10px;
}
.mr40 {
	margin-right: 40px !important;
}
</style>
