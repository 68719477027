<template>
	<div class="flex-row">
		<!--多图片-->
		<template v-if="limit > 1">
			<div
				v-for="(item, index) in imgList"
				:key="index"
				class="pictrue"
				:draggable="draggable"
				@dragstart="handleDragStart(item)"
				@dragover.prevent="handleDragOver($event, item)"
				@dragenter="handleDragEnter($event, item)"
				@dragend="handleDragEnd()"
			>
				<el-image v-if="imgUrl != ''" :src="item" :preview-src-list="disabled ? [imgUrl] : []" style="width:58px;height:58px" />
				<img v-else :src="item">

				<i v-show="!disabled" class="el-icon-error btndel" @click="handleRemove(index)" />
			</div>
			<div v-if="imageArray.length < 10" v-show="!disabled" class="upLoadPicBox" @click="uploadMulImages">
				<div class="upLoad">
					<i class="el-icon-camera cameraIconfont" />
				</div>
			</div>
		</template>
		<!--单图片-->
		<div v-else class="upLoadPicBox">
			<div class="upLoad" @click="uploadMulImages">
				<el-image v-if="imgUrl != '' || !imageUrl" :src="imgUrl" :preview-src-list="preview ? [imgUrl] : []" style="width:58px;height:58px">
					<div slot="error" class="image-slot">
						<i class="el-icon-camera cameraIconfont" />
					</div>
				</el-image>
			</div>
		</div>

		<el-dialog
			title="选择图片"
			:visible.sync="visible"
			:close-on-click-modal="false"
			append-to-body
			custom-class="upload-dialog"
			top="50px"
			width="74%"
		>
			<picture-index v-if="visible" picture-type="1" :is-more="limit" @getImage="getImage" />
		</el-dialog>
	</div>
</template>

<script>
import PictureIndex from "views/bussiness/selfManager/picture/index"
export default {
	name: "UploadImage",
	components: {
		PictureIndex
	},
	props: {
		imageArray: {
			// 多图片的传值
			type: Array
		},
		imageUrl: {
			// 单图片的传值
			type: String
		},
		// 操作
		disabled: {
			type: Boolean
		},
		// 数量
		limit: {
			type: Number,
			default: 1
		},
		// 拖拽
		draggable: {
			type: Boolean,
			default: false
		},
		preview: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			dragging: null,
			visible: false,

			imgList: this.imageArray,
			imgUrl: this.imageUrl
		}
	},
	watch: {
		imageUrl(newVal) {
			this.imgUrl = newVal
		},

		imageArray(newVal) {
			this.imgList = newVal
		}
	},
	computed: {
		// imgUrl(){
		//     return this.imageUrl
		// }
	},
	methods: {
		uploadMulImages() {
			if (this.disabled) return
			this.visible = true
		},
		getImage(files) {
			if (files.length > this.limit) return this.$message.warning(`最多选择${this.limit}张图片！`)

			if (this.limit == 1) {
				this.imgUrl = files.join()
				this.$emit("input", this.imgUrl)
				this.$emit("imageValue", this.imgUrl)
			} else {
				if (files.length + this.imageArray.length > this.limit)
					return this.$message.warning(`最多选择${this.limit}张图片！`)

				this.imgList = [...this.imageArray, ...files]
				this.$emit("input", this.imgList)
			}
			this.visible = false
		},

		handleRemove(i) {
			this.imgList.splice(i, 1)
		},

		// 移动
		handleDragStart(item) {
			this.dragging = item
		},
		handleDragEnd() {
			this.dragging = null
		},
		handleDragOver(e) {
			e.dataTransfer.dropEffect = "move"
		},
		handleDragEnter(e, item) {
			e.dataTransfer.effectAllowed = "move"
			if (item === this.dragging) return
			const newItems = [...this.imageArray]
			const src = newItems.indexOf(this.dragging)
			const dst = newItems.indexOf(item)
			newItems.splice(dst, 0, ...newItems.splice(src, 1))
			// this.imageArray = newItems
			this.$emit("input", newItems)
		}
	},
	created() { },
	mounted() {
		this.imgUrl = this.imageUrl
	}
}
</script>
<style lang="scss" scoped>
// .upload-dialog {

// }

::v-deep .el-dialog__body {
	height: 700px;
}

.is-error .upLoadPicBox .upLoad {
	border-color: red;
}

::v-deep .image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #f5f7fa;
	color: #909399;
	font-size: 24px;
}
</style>
