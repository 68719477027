<template>
	<div class="mvk-layout-content-card">
		<el-button
			v-has="'pms:productCategory:add'"
			type="primary"
			round
			class="add-btn"
			size="small"
			@click="handleAdd"
		>
			新增商品分类
		</el-button>

		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			row-key="id"
			:tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
		>
			<!--自定义操作-->
			<template slot="image" slot-scope="{ row }" label="图标">
				<el-image style="width: 30px; height: 30px" :src="row.image + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.image + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/400x']" />
			</template>
			<template slot="status" slot-scope="{ row }" label="状态">
				<el-switch
					v-model="row.status"
					:active-value="1"
					:inactive-value="0"
					:disabled="!hasBtnPermission('pms:productCategory:updateStatus')"
					@change="handleStatusChange(row.id)"
				/>
			</template>
			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button
						v-if="row.level < 3"
						v-has="'pms:productCategory:add'"
						type="text"
						size="small"
						@click="handleAddMenu(row)"
					>
						添加子类
					</el-button>
					<el-button v-has="'pms:productCategory:edit'" type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
					<delete-button
						v-has="'pms:productCategory:delete'"
						button-type="text"
						size="small"
						:data="row"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
	</div>
</template>

<script>
import { proCategoryList, deleteProCategory, changeProStatus } from "api/business/selfManager/category.js"

import { listMixin } from "views/mixin/listMixin"
import { permissionMixin } from "views/mixin/permissionMixin"

export default {
	components: {
		"add-edit-dialog": () => import("./addEdit.vue")
	},
	props: {},
	mixins: [listMixin, permissionMixin],
	data() {
		return {
			tableColumn: [
				{
					prop: "name",
					label: "名称"
				},
				{
					prop: "level",
					label: "类型",
					formatter: (cellValue) => {
						if (cellValue.level == 1) {
							return "一级分类"
						} else if (cellValue.level == 2) {
							return "二级分类"
						} else if (cellValue.level == 3) {
							return "三级分类"
						}
					}
				},
				{
					prop: "image",
					label: "图标",
					slot: true
				},
				{
					prop: "status",
					label: "状态",
					slot: true
				},
				{
					prop: "orderNum",
					label: "排序"
				}
			],
			tableDataFunc: (parameter) => {
				return proCategoryList(parameter).then((res) => {
					let resData = JSON.parse(JSON.stringify(res.data.list))
					sessionStorage.setItem("eshop-categoryList", JSON.stringify(this.handleList(resData)))
					// console.log(this.handleList(res.data.list))
					return res.data
				})
			}
		}
	},
	watch: {},
	computed: {},
	methods: {
		handleList(list) {
			var vm = this
			if (list.length > 0) {
				list.forEach((item) => {
					if (item.level == 2) {
						delete item.child
						// vm.handleList(item.child)
					} else {
						if (item.child && item.child != null && item.child.length > 0) {
							vm.handleList(item.child)
						} else {
							delete item.child
						}
					}
				})
			}

			return list
		},
		handleDelete(data) {
			if (data.data.status == 1) {
				this.$message.warning("已上架的分类不允许删除")
			} else {
				deleteProCategory(data.data.id)
					.then(() => {
						this.$notify.success({
							title: "成功",
							message: "删除成功"
						})
						this.$refs.mvkTable.refresh()
					})
					.catch(() => {
						// this.$notify.error({
						//     title: "错误",
						//     message: "删除失败"
						// })
					})
			}
		},

		// 添加子目录
		handleAddMenu(row) {
			let parentIdArr = []
			if (row.path) {
				let temp = row.path.split("/")
				console.log(temp)
				temp &&
					temp.forEach((item) => {
						console.log(item)
						if (item) {
							parentIdArr.push(Number(item))
						}
					})
			}

			parentIdArr.push(row.id)

			this.handleEdit({
				id: "",
				parentId: row.id,
				parentIdArr: parentIdArr,
				name: "",
				image: "",
				level: row.level + 1,
				orderNum: "",
				status: 1
			})
		},

		// 上架、下架操作
		handleStatusChange(id) {
			changeProStatus(id)
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "修改成功"
					})
					// this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					// this.$notify.error({
					// 	title: "错误",
					// 	message: "修改失败"
					// })
				})
		}
	},
	created() {},
	mounted() {}
}
</script>
<style lang="scss" scoped></style>
