import { shopList } from "api/business/selfManager/shop.js"
import { supplierList } from "api/basePage/supplier"
import { getMajorShopList } from "api/business/selfManager/keyAccount"
import { menuList } from "api/basePage/menu"
import { roleList } from "api/basePage/role"
import { permissionTableList } from "api/basePage/permission"
import { organitionTree } from "api/basePage/organition"
import { laborShop } from "api/business/selfManager/labour"

const state = {
	shoplist: [],
	supplierlist: [],
	keyAccountShopList: [], // 专营店列表
	menulist: [], // 菜单列表
	rolelist: [], // 角色列表
	tablelist: [], // 可配置表列表
	organizationlist: [], // 组织架构列表
	labourlist: [] // 通用店铺列表
}

const mutations = {
	GET_SHOPLIST: (state, list) => {
		state.shoplist = list
	},

	GET_SUPPLIERLIST: (state, list) => {
		state.supplierlist = list
	},
	GET_KEYACCOUNTSHOPLIST: (state, list) => {
		state.keyAccountShopList = list
	},

	GET_MENULLIST: (state, list) => {
		state.menulist = list
	},

	GET_ROLELIST: (state, list) => {
		state.rolelist = list
	},

	GET_TABLELIST: (state, list) => {
		state.tablelist = list
	},

	GET_ORGANIZATIONLIST: (state, list) => {
		state.organizationlist = list
	},
	GET_LABOURSHOPLIST: (state, list) => {
		state.labourlist = list
	}

}

const actions = {
	// 全部店铺列表
	getShopList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			shopList({
				pageNum: 0,
				pageSize: 1000
			}).then(res => {
				commit("GET_SHOPLIST", res.data.list)
				resolve(res.data.list)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取专营店列表
	getKeyAccountShopList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getMajorShopList({
				pageNum: 0,
				pageSize: 1000
			}).then(res => {
				commit("GET_KEYACCOUNTSHOPLIST", res.data.list)
				resolve(res.data.list)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取通用店铺列表
	getLabourShopList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			laborShop({
				pageNum: 0,
				pageSize: 1000
			}).then(res => {
				commit("GET_LABOURSHOPLIST", res.data.list)
				resolve(res.data.list)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 供应商列表
	getSupplierList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			supplierList({
				pageNum: 0,
				pageSize: 1000
			}).then(res => {
				commit("GET_SUPPLIERLIST", res.data.list)
				resolve(res.data.list)
			}).catch(err => {
				reject(err)
			})
		})
	},

	// 菜单列表
	getMenuList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			menuList().then(res => {
				commit("GET_MENULLIST", res.data)
				resolve(res.data)
			}).catch(err => {
				reject(err)
			})
		})
	},

	// 角色列表
	getRoleList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			roleList({
				pageNum: 0,
				pageSize: 1000
			}).then(res => {
				commit("GET_ROLELIST", res.data.list)
				resolve(res.data)
			}).catch(err => {
				reject(err)
			})
		})
	},

	getTableList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			permissionTableList().then(res => {
				commit("GET_TABLELIST", res)
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},

	// 组织架构
	getOrganizationTree({
		commit
	}) {
		return new Promise((resolve, reject) => {
			organitionTree().then(res => {
				commit("GET_ORGANIZATIONLIST", res.data)
				resolve(res.data)
			}).catch(err => {
				reject(err)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
