<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" label-width="100px" :inline="true">
			<el-form-item label="所属供应商：">
				<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
					<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			<el-form-item label="商品名称：">
				<el-input v-model="queryParam.prodName" placeholder="请输入商品名称" size="small" clearable />
			</el-form-item>
			<el-form-item label="报表编号：">
				<el-input v-model="queryParam.reportCode" placeholder="报表编号" size="small" clearable />
			</el-form-item>
			<div>
				<el-radio-group v-model="queryParam.dateType">
					<el-radio-button label="1">生成时间</el-radio-button>
					<el-radio-button label="2">结算时间</el-radio-button>
				</el-radio-group>
				<el-date-picker
					v-model="timeVal"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					placeholder="统计时间"
					class="ml10"
					style="width: 220px"
					@change="onchangeTime"
				/>
				<el-button type="primary" class="ml20" @click="handleExport()"> 下载报表 </el-button>
			</div>
		</el-form>
		<el-tabs v-model="isSettle" class="mt10">
			<el-tab-pane label="全部" name="-1" />
			<el-tab-pane label="结算中" name="1" />
			<el-tab-pane label="已结算" name="2" />
		</el-tabs>
		<div class="flex flex-sb">
			<el-card v-for="item in statisticsLabel" :key="item.prop" class="box-card">
				{{ item.label }}
				<count-to :start-val="0" :end-val="statisticsNum[item.prop]" :duration="1000" class="count-num" />
			</el-card>
		</div>
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			border
			height="400"
			:default-sort="{ prop: 'supplierName' }"
			class="mt15"
		>
			<template slot="prodName" slot-scope="{ row }">
				<el-button type="text" class="underline" @click="handleToOrder(row)">
					{{ row.prodName }}
				</el-button>
			</template>
		</mvk-table>
	</div>
</template>

<script>
import { getSettleDetailList, getSettlementDetailExport, getStatistics } from "api/business/financeManage/settle.js"
import { blobTofile } from "@/utils/utils"
import { mapState, mapActions } from "vuex"
import { GetDateTimeValue } from "@/utils/dateUtils"
import CountTo from "vue-count-to"

const settleStatus = {
	0: "未结算",
	1: "结算中",
	2: "已结算"
}

export default {
	name: "SettleDetail",
	components: {
		CountTo
	},
	computed: {
		...mapState("dict", ["shoplist", "supplierlist"])
	},
	data() {
		return {
			isSettle: "-1",
			queryParam: {
				dateType: "1",
				supplierId: "",
				shopId: "",
				prodName: "",
				startTime: "",
				endTime: "",
				reportCode: "",
				isSettle: ""
			},
			tableColumn: [
				{ prop: "reportCode", label: "报表编号", width: 200, sortable: true },
				{ prop: "supplierName", label: "供应商", sortable: true },
				// { prop: "shopName", label: "店铺" },
				// { prop: "prodId", label: "商品ID" },
				{ prop: "prodName", label: "商品", slot: true },
				{ prop: "productNum", label: "销售商品数", sortable: true },
				{ prop: "orderNum", label: "订单数", sortable: true },
				{ prop: "cosPrice", label: "结算成本(含税)", sortable: true },
				{ prop: "payMoney", label: "销售收入（含税）", sortable: true },
				{ prop: "salePrice", label: "销售价", sortable: true },
				{
					prop: "isSettle",
					label: "结算状态",
					formatter: row => {
						return settleStatus[row.isSettle]
					}
				},
				{ prop: "creationDate", label: "生成时间" },
				{ prop: "settleTime", label: "结算时间" }
				// { prop: "countTime", label: "统计时段" }
			],
			tableDataFunc: parameter => {
				this.pageNum = parameter.pageNum
				this.pageSize = parameter.pageSize
				return getSettleDetailList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},
			timeVal: [],
			statisticsLabel: [
				{
					prop: "productNum",
					label: "累计销售商品数（个）"
				},
				{
					prop: "orderNum",
					label: "累计订单数（个）"
				},
				{
					prop: "cosPrice",
					label: "累计结算成本(含税 ：元）"
				},
				{
					prop: "payMoney",
					label: "销售收入(含税 ：元）"
				}
			],
			statisticsNum: {
				productNum: 0,
				orderNum: 0,
				cosPrice: 0,
				payMoney: 0
			},

			pageNum: 0, // 只用于导出传参
			pageSize: 0 // 只用于导出传参
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.searchList()
				this.getAllNum()
			},
			deep: true
		},
		isSettle(newVal) {
			if (newVal == "-1") {
				this.queryParam.isSettle = ""
			} else {
				this.queryParam.isSettle = newVal
			}
		}
	},
	created() {
		this.timeVal = GetDateTimeValue("month", "YYYY-MM-DD HH:mm:ss")
		this.queryParam.startTime = GetDateTimeValue("month", "YYYY-MM-DD HH:mm:ss")[0]
		this.queryParam.endTime = GetDateTimeValue("month", "YYYY-MM-DD HH:mm:ss")[1]
	},
	mounted() {
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}

		if (this.shoplist.length == 0) {
			this.getShopList()
		}

		this.getAllNum()
	},

	methods: {
		...mapActions("dict", ["getShopList", "getSupplierList"]),

		searchList() {
			this.$refs.mvkTable.refresh()
		},

		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},

		handleExport() {
			getSettlementDetailExport(Object.assign(this.queryParam, { pageNum: this.pageNum, pageSize: this.pageSize })).then(res => {
				blobTofile(res, "供应商结算明细报表.xls", "application/vnd.ms-excel")
			})
		},

		handleToOrder(row) {
			this.$router.push({
				path: "/orderSettle",
				query: {
					supplierId: row.supplierId,
					productName: row.prodName,
					isSettle: row.isSettle
				}
			})
		},

		getAllNum() {
			getStatistics(this.queryParam).then(res => {
				this.statisticsNum = res.data
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.underline {
	text-decoration: underline;
}

.count-num {
	color: #ff5429;
	font-size: 24px;
}
</style>
