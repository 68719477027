<template>
	<div>
		<el-card>
			<div slot="header" class="tip-header">
				<span>会员概况</span>
				<!-- 筛选 -->
				<div class="options">
					<el-date-picker
						v-model="timeVal"
						type="datetimerange"
						format="yyyy-MM-dd"
						value-format="yyyyMMdd"
						:picker-options="pickerOptions"
						size="small"
						range-separator="至"
						style="width: 320px"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="onchangeTime"
					/>
				</div>
			</div>
			<div class="live-data-list">
				<div v-for="(item, index) in totalTotal" :key="item.name" class="flex">
					<div class="icon-circular" :class="'color' + (index % 4)">
						<icon-svg :icon="item.icon" size="18" />
					</div>
					<div>
						<div class="text">
							{{ item.label }}
							<el-tooltip class="item" effect="dark" :content="item.tip" placement="top">
								<i class="el-icon-question" />
							</el-tooltip>
						</div>
						<count-to :start-val="0" :end-val="item.value" :duration="1000" class="count-num num" />
					</div>
				</div>
			</div>
			<line-chart v-if="chartShow" id="userLineChart" ref="userLineChart" :xdata="xAxisData" :ydata="seriesData" class="mt15" />
		</el-card>
	</div>
</template>

<script>
import CountTo from "vue-count-to"
import LineChart from "views/components/LineChart"
import { debounce } from "utils/utils"
import { DateList, pickerOptions } from "@/utils/constants"

import {
	memberStatistic
} from "api/business/dashboard"
import moment from "moment"
const today = moment().startOf("day").format("YYYYMMDD")
const startDay = moment().subtract(14, "days").format("YYYYMMDD")
export default {
	name: "UserAnalysis",

	components: {
		CountTo,
		LineChart
	},

	directives: {},

	data() {
		return {
			pickerOptions,
			// 筛选
			dateList: DateList,
			dateValue: [startDay, today],
			timeVal: [startDay, today],
			loading_chart: false,
			queryParam: {
				date: []
			},
			totalTotal: [
			],
			xAxisData: [],
			seriesData: [],
			chartShow: false,
			censusData: {},
			statistics: []

		}
	},
	watch: {
		queryParam: {
			// 增加防抖
			handler: debounce(function() {
				this.initData()
			}, 300),
			deep: true
		}
	},
	created() { this.dateList.shift() },
	mounted() {
		this.initData()
	},

	methods: {
		initData() {
			this.chartShow = true
			const params = {
				startDate: this.timeVal[0],
				endDate: this.timeVal[1]
			}
			this.loading_chart = true

			memberStatistic(params).then(res => {
				this.statistics = res.data.data
				this.censusData = res.data
				this.toData()
				this.xAxisData = this.statistics.xaxis
				this.seriesData = this.statistics.series.map(e => {
					e.value = e.values
					return e
				})
				this.reRender()
			}).finally(() => {
				this.loading_chart = false
			})
		},
		toData() {
			this.totalTotal = [
				{
					label: "访客数",
					name: "people",
					tip: "在选定条件下，访问商城页面的去重人数",
					icon: "yonghu1",
					value: this.censusData.people
				},
				{
					label: "新增用户数",
					name: "newUser",
					tip: "在选定条件下，下单并支付成功的用户",
					icon: "xinzengyonghu",
					value: this.censusData.newUser
				},
				{
					label: "成交用户数",
					name: "payPeople",
					tip: "在选定条件下，下单并支付成功的用户",
					icon: "leijichengjiao",
					value: this.censusData.payPeople
				},
				{
					label: "充值用户数",
					name: "rechargePeople",
					tip: "筛选时间截止时，商城成功充值过的用户",
					icon: "fufeihuiyuan",
					value: this.censusData.rechargePeople
				}
				// {
				//     label: "商品浏览量",
				//     name: "browse",
				//     tip: "在选定条件下，用户浏览商城页面的次数。每打开一个页面或每刷新一次页面都记录1次",
				//     icon: "liulanliang",
				//     value: this.censusData.payPeople
				// },
				// {
				//     label: "客单价",
				//     name: "payPrice",
				//     tip: "在选定条件下，用户支付的总金额 / 支付人数",
				//     icon: "kedanjia",
				//     value: this.censusData.payPeople
				// },
				// {
				//     label: "访客--支付转换率",
				//     name: "payPercent",
				//     tip: "在选定条件下，全部成交客户数 / 商城访客数",
				//     icon: "zhuanhuan",
				//     value: this.censusData.payPeople
				// }
			]
		},
		// 重新渲染
		reRender() {
			this.$refs.userLineChart.resize()
		},

		// 具体日期
		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.date = e ? this.timeVal.join(",") : ""
		}
	}
}
</script>

<style lang="scss" scoped>
.options{
    margin: 10px 0;
}
.tip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
	border-left: 5px solid #4b5eff;
	padding-left: 8px;
}
.live-data-list {
	display: flex;
	justify-content: space-around;
	.icon-circular {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 8px;
		background: #1890ff;

		.svg-icon {
			margin-right: 0;
		}
	}

	.color1 {
		background: #00c050;
	}

	.color2 {
		background: #ffab2b;
	}

	.color3 {
		background: #b37feb;
	}

	.text {
		color: #666;
		line-height: 1.8;
		i {
			font-size: 16px;
			color: #4f8aff;
		}
	}
	.num {
		font-weight: bold;
		color: #4b5eff;
		line-height: 1.8;
	}
}
</style>
