const types = {
    404: {
        img: require("./404.svg"),
        title: "404",
        desc: "抱歉，你访问的页面不存在或无权访问"
    }
//   500: {
//     img: require('./500.svg'),
//     title: '500',
//     desc: '抱歉，服务器出错了'
//   }
}

export default types
