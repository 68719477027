import fetch from "@/axios/fetch"

export function getExpressList(data) { // 快递节点
    return fetch({
        url: "/emsExpress/yy/point",
        method: "post",
        data
    })
}

export function getExpressCompy(params) { // 快递公司
    return fetch({
        url: "/emsExpress/comList",
        method: "get",
        params
    })
}

export function getExpressCompyV2(params) { // 分页查询快递公司表v2
    return fetch({
        url: "/jumExpress/comList",
        method: "get",
        params
    })
}

export function getExpressListV2(data) { // 快递单号查询v2
    return fetch({
        url: "/jumExpress/query",
        method: "post",
        data
    })
}