<template>
	<el-drawer
		v-loading="loading"
		title="已导入商品列表"
		:visible.sync="addEditDialog"
		size="75%"
		:append-to-body="true"
	>
		<el-button v-has="'live:room:product:import'" type="primary" @click="importDialog = true">导入</el-button>

		<mvk-table
			ref="productTable"
			:table-column="productTableColumn"
			:table-data-func="productTableDataFunc"
			class="mt15"
			border
		>
			<template slot="coverImgUrl" slot-scope="{ row }" label="直播商品封面图">
				<el-image
					style="width: 30px; height: 30px"
					:src="row.coverImgUrl  + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="[row.coverImgUrl]"
				/>
			</template>
			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button
						v-if="row.onsale"
						v-has="'live:room:product:onsale'"
						type="text"
						size="small"
						@click="handleOnSale(row)"
					>
						下架
					</el-button>
					<el-button
						v-else
						v-has="'live:room:product:onsale'"
						type="text"
						size="small"
						@click="handleOnSale(row)"
					>
						上架
					</el-button>
					<el-button
						v-if="roomStatus == '101'"
						v-has="'live:room:product:push'"
						type="text"
						size="small"
						@click="handlePush(row)"
					>
						推送
					</el-button>
					<delete-button
						v-has="'live:room:product:delete'"
						button-type="text"
						size="small"
						:data="row"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<el-drawer title="导入商品" :visible.sync="importDialog" size="50%" append-to-body>
			<div v-if="importDialog" class="drawer-content">
				<mvk-table
					ref="importTable"
					:table-column="importTableColumn"
					:table-data-func="importTableDataFunc"
					class="mt15"
					border
					@selection-change="handleSelectionChange"
				>
					<el-table-column slot="head" type="selection" width="55" />
					<template slot="coverImgUrl" slot-scope="{ row }" label="直播商品封面图">
						<el-image
							style="width: 30px; height: 30px"
							:src="row.coverImgUrl  + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
							:preview-src-list="[row.coverImgUrl]"
						/>
					</template>
				</mvk-table>
			</div>

			<div class="custom-drawer-footer">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" :disabled="importGoodsLoadding" @click="handleGoodsOn()">导入并上架</el-button>
				</div>
			</div>
		</el-drawer>
	</el-drawer>
</template>

<script>
import {
	liveRoomProductSelection,
	importProduct,
	liveRoomProduct,
	liveRoomOnsale,
	liveRoomProductDelect,
	liveRoomProductPush
} from "api/business/broadcast/live"
export default {
	name: "LiveProductImport",

	components: {},

	directives: {},

	data() {
		return {
			addEditDialog: false,
			loading: false,

			productTableColumn: [
				{ prop: "name", label: "直播商品名称" },
				{ prop: "coverImgUrl", label: "直播商品封面图", slot: true },
				{ prop: "price", label: "价格" },
				{
					prop: "onsale",
					label: "状态",
					formatter: value => {
						// 格式化内容
						if (value.onsale) {
							return "已上架"
						} else {
							return "未上架"
						}
					}
				}
			],
			productTableDataFunc: parameter => {
				return liveRoomProduct(this.roomId, Object.assign(parameter)).then(res => {
					return res.data
				})
			},

			importDialog: false,
			importTableColumn: [
				{ prop: "name", label: "直播商品名称" },
				{ prop: "coverImgUrl", label: "直播商品封面图", slot: true },
				{ prop: "price", label: "价格" }
			],

			roomId: "", // 直播间id
			roomStatus: "", // 直播间状态
			queryParam: {},
			importTableDataFunc: parameter => {
				return liveRoomProductSelection(this.roomId, Object.assign(parameter)).then(res => {
					return res.data
				})
			},
			checkedProducts: [],
			importGoodsLoadding: false
		}
	},
	computed: {},
	watch: {
		roomId() {
			if (this.$refs.productTable) {
				this.$refs.productTable.refresh()
			}
		}
	},
	mounted() {},
	methods: {
		open(recode) {
			this.roomId = recode.id
			this.roomStatus = recode.status
			this.addEditDialog = true
		},

		handleSelectionChange(val) {
			this.checkedProducts = val
		},

		handleOnSale(row) {
			liveRoomOnsale({
				productId: row.productId,
				roomId: this.roomId
			}).then(() => {
				this.$notify.success({
					title: "成功",
					message: "操作成功"
				})

				this.$refs.productTable.refresh()
			})
		},

		handlePush(data) {
			liveRoomProductPush(data.roomId, data.productId).then(() => {
				this.$notify.success({
					title: "成功",
					message: "推送成功"
				})

				this.$refs.productTable.refresh()
			})
		},

		handleDelete(data) {
			liveRoomProductDelect(data.data.roomId, data.data.productId).then(() => {
				this.$notify.success({
					title: "成功",
					message: "删除成功"
				})

				this.$refs.productTable.refresh()
			})
		},

		handleGoodsOn() {
			this.importGoodsLoadding = true
			if (this.checkedProducts.length == 0) {
				this.$notify.warning({
					title: "提示",
					message: "请先选择商品"
				})
				return
			}

			importProduct({
				roomId: this.roomId,
				productIds: this.checkedProducts.map(item => item.id)
			}).then(() => {
				this.importDialog = false
				this.$refs.productTable.refresh()
			}).finally(() => { 
				setTimeout(() => {
					this.importGoodsLoadding = false
				}, 300)
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
