<template>
    <el-drawer :title="title" :visible.sync="addEditDialog" size="40%">
        <el-form ref="formValidate" v-loading="loading" :model="formValidate" label-width="150px">
            <el-form-item label="会员编号：" prop="id">
                <el-input v-model="formValidate.id" disabled class="inputWidth" />
            </el-form-item>
            <el-form-item label="会员昵称：" prop="nickName">
                <el-input v-model="formValidate.nickName" disabled placeholder="会员昵称" class="inputWidth" />
            </el-form-item>
            <el-form-item label="手机号码：" prop="userMobile">
                <el-input
                    v-model.number="formValidate.userMobile"
                    type="number"
                    disabled
                    placeholder="手机号码"
                    class="inputWidth"
                />
            </el-form-item>
            <el-form-item label="邮箱：" prop="userMail">
                <el-input v-model="formValidate.userMail" type="text" disabled placeholder="邮箱" class="inputWidth" />
            </el-form-item>
            <el-form-item label="会员等级：" prop="levelId">
                <el-select v-model="formValidate.levelId" class="inputWidth">
                    <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="会员分组：" prop="groupId">
                <el-select v-model="formValidate.groupId" class="inputWidth">
                    <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item label="会员标签：" prop="labelIds">
                <el-select v-model="formValidate.labelIds" multiple class="inputWidth">
                    <el-option v-for="item in labelList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" round @click="submitForm('formValidate')">确 定</el-button>
                <el-button round @click="resetForm('formValidate')">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import { levelList } from "api/business/member/grade.js"
import { groupList } from "api/business/member/group.js"
import { labelList } from "api/business/member/label.js"
import { memberEdit } from "api/business/member/list.js"

const obj = {
    id: "",
    groupId: "", // 等级
    labelIds: [],
    levelId: "",
    status: 1,
    userMemo: null
}

export default {
    name: "MemberEdit",
    components: {},
    data() {
        return {
            title: "新增",
            addEditDialog: false,
            formValidate: Object.assign({}, obj),
            loading: false,

            gradeList: [],
            groupList: [],
            labelList: []
        }
    },
    computed: {},
    methods: {
        add() {
            this.addEditDialog = true
            this.$nextTick(() => {
                this.$refs["formValidate"].resetFields()
            })
        },
        edit(record) {
            this.addEditDialog = true
            this.$nextTick(() => {
                this.formValidate = Object.assign(obj, JSON.parse(JSON.stringify(record)))

                this.formValidate.levelId = this.formValidate.memberLevelVO && this.formValidate.memberLevelVO.id
                this.formValidate.groupId = this.formValidate.memberGroupVO && this.formValidate.memberGroupVO.id
                this.formValidate.labelIds =
                    this.formValidate.memberLabelVO &&
                    this.formValidate.memberLabelVO.map(item => {
                        return item.id
                    })
            })
        },

        getGradeList() {
            levelList().then(res => {
                this.gradeList =
                    res.data.list &&
                    res.data.list.map(item => {
                        return {
                            id: item.id,
                            name: item.name
                        }
                    })
            })
        },

        getGroupList() {
            groupList().then(res => {
                this.groupList =
                    res.data.list &&
                    res.data.list.map(item => {
                        return {
                            id: item.id,
                            name: item.name
                        }
                    })
            })
        },

        getLabelList() {
            labelList().then(res => {
                this.labelList =
                    res.data.list &&
                    res.data.list.map(item => {
                        return {
                            id: item.id,
                            name: item.name
                        }
                    })
            })
        },

        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.loading = true

                    let param = {}

                    Object.entries(this.formValidate).forEach(item => {
                        // console.log(item);
                        if (Object.prototype.hasOwnProperty.call(obj, item[0])) {
                            param[item[0]] = item[1]
                        }
                    })

                    param = Object.assign(obj, param)

                    memberEdit(param).then(() => {
                        this.$notify.success({
                            title: "成功",
                            message: "编辑成功"
                        })
                        this.loading = false
                        this.addEditDialog = false
                        this.formValidate = Object.assign({}, obj)
                        this.$emit("saveSuccess")
                    })
                } else {
                    return false
                }
            })
        },

        resetForm(formName) {
            this.addEditDialog = false
            this.$refs[formName].resetFields()
        }
    },
    mounted() {
        this.getGradeList()
        this.getGroupList()
        this.getLabelList()
    }
}
</script>

<style lang="scss" scoped></style>
