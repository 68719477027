<template>
	<el-drawer v-loading="loading" title="出货价设置" :visible.sync="onDrawer" size="860px">
		<div class="drawer-content">
			<el-form ref="goodsform" :model="goodsform" :rules="rules" :inline="false">
				<el-card
					v-for="(goods, index) in goodsform.goodslist"
					:key="index"
					shadow="always"
					:body-style="{ padding: '12px' }"
					class="mt15 custom-card"
				>
					<div slot="header">
						<span>{{ goods.prodName }}</span>
					</div>
					<div class="flex-row">
						<el-form-item
							label="商品分类："
							:prop="'goodslist.' + index + '.categoryId'"
							:rules="[{ required: true, message: '请设置商品分类', trigger: 'change' }]"
							class="flex mr10"
						>
							<prod-category-tree v-model="goods.categoryId" style="width:120px;" />
						</el-form-item>
						<el-form-item label="税率" :prop="'goodslist.' + index + '.taxRate'" :rules="rules.taxRate" class="flex mr10">
							<el-input-number v-model="goods.taxRate" :min="0" :max="0.5" :step="0.01" label="请设置税率" @change="checkRate($event,index)" />
							<span class="taxrate ml20 cr_999">税率{{ Math.floor(goods.taxRate * 100) }}%</span>

							<!-- <el-input v-model="goods.taxRate" placeholder="请设置税率" clearable /> -->
						</el-form-item>
						<el-form-item label="商品费率编码" :prop="'goodslist.' + index + '.categories'" :rules="rules.categories" class="flex mr10">
							<el-input v-model="goods.categories" placeholder="请设置商品代码搜索" clearable />
						</el-form-item>
					</div>
					<el-form-item>
						<el-table :data="goods.productSkuPageVos" border stripe>
							<el-table-column label="SKU名称" prop="skuName" min-width="160" />
							<el-table-column label="成本价(元)" prop="costPrice" width="80" />
							<el-table-column label="售价（元）" prop="sellingPrice" align="center">
								<template slot-scope="scope">
									<el-form-item
										:rules="[{ required: true, message: '请设置SKU的售价', trigger: 'change' }]"
										:prop="'goodslist.' + index + '.productSkuPageVos.' + scope.$index + '.sellingPrice'"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.sellingPrice"
											size="small"
											placeholder="设置售价"
											type="number"
											:min="scope.row.costPrice"
											:precision="2"
											:step="0.01"
											step-strictly
										/>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="市场价（元）" prop="marketPrice" align="center">
								<template slot-scope="scope">
									<el-form-item
										:rules="[{ required: true, message: '请设置SKU的市场价', trigger: 'change' }]"
										:prop="'goodslist.' + index + '.productSkuPageVos.' + scope.$index + '.marketPrice'"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.marketPrice"
											size="small"
											placeholder="设置市场价"
											type="number"
											:min="0.01"
											:precision="2"
											:step="0.01"
											step-strictly
										/>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="内购价（元）" prop="internalPrice" align="center">
								<template slot-scope="scope">
									<el-form-item
										:rules="[{ required: true, message: '请设置SKU的内购价', trigger: 'change' }]"
										:prop="'goodslist.' + index + '.productSkuPageVos.' + scope.$index + '.internalPrice'"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.internalPrice"
											size="small"
											placeholder="设置内购价"
											type="number"
											:min="0.01"
											:precision="2"
											:step="0.01"
											step-strictly
										/>
									</el-form-item>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-card>
			</el-form>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button type="primary" @click="handleSubmit('goodsform')">设置</el-button>
				<el-button type="text" @click="onDrawer = false">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { virtualGoodsInstalled } from "api/business/selfManager/goods.js"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"

export default {
	name: "PriceSetting",
	components: { ProdCategoryTree },
	data() {
		return {
			onDrawer: false,
			loading: false,

			goodsform: {
				goodslist: []
			},
			rules: {
				taxRate: [{ required: true, message: "请录入商品税率", trigger: "change" }],
				categories: [{ required: true, message: "请录入商品费率编码", trigger: "change" }]
			}
		}
	},
	computed: {},
	watch: {},
	mounted() {},
	methods: {
		open(record) {
			const temp = JSON.parse(JSON.stringify(record))
			temp.forEach(e => { 
				e.productSkuPageVos.forEach(el => { 
					el.internalPrice = 0
				})
				e.categories = ""
				e.taxRate = e.taxRate || 0
			})
			this.goodsform.goodslist = temp
			this.onDrawer = true

		},
		checkRate(val, index) { 
			console.log(val, index)
			this.$nextTick(() => { 
				this.goodsform.goodslist[index].taxRate = Math.trunc(val * 100) / 100
			})
		},
		handleSubmit(name) {
			this.$refs[name].validate(valid => {
				if (valid) {
					let params = {}
					params.products = []
					this.goodsform.goodslist.forEach(item => {
						let temp = {}
						temp.id = item.id
						temp.categories = item.categories
						temp.categoryId = item.categoryId
						temp.taxRate = item.taxRate
						temp.skus = []
						item.productSkuPageVos.forEach(sku => {
							let skuJson = {}
							skuJson.id = sku.id
							skuJson.internalPrice = sku.internalPrice
							skuJson.marketPrice = sku.marketPrice
							skuJson.sellingPrice = sku.sellingPrice
							temp.skus.push(skuJson)
						})
						params.products.push(temp)
					})
					this.loading = true
					virtualGoodsInstalled(params)
						.then(res => {
							if (res) {
								this.$notify.success({
									title: "成功",
									message: "设置成功"
								})
								this.onDrawer = false
								this.$emit("saveSuccess")
							}
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	.taxrate{
		display: inline-block;
		width: 60px;
	}
</style>
