import fetch from "@/axios/fetch"

// 核销统计

export function getPickList(params) { // 核销统计列表
	return fetch({
		url: "/shop/pick/statistics",
		method: "get",
		params
	})
}

export function getPickListsss(params) { // 核销记录
	return fetch({
		url: "/shop/pick/list",
		method: "get",
		params
	})
}
export function getPickExport(params) { // 核销统计导出
	return fetch({
		url: "/shop/pick/export",
		method: "get",
		params,
		responseType: "blob"
	})
}
export function getPickStatisticsExport(params) { // 生成核销统计汇总表
	return fetch({
		url: "/shop/pick/statistics",
		method: "get",
		params,
		responseType: "blob"
	})
}
export function getPickDetail(shopId, params) { // 核销详情
	return fetch({
		url: "/shop/pick/" + shopId + "/export",
		method: "get",
		params
	})
}
