<template>
	<div class="mvk-layout-content-card">
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" />
	</div>
</template>

<script>
import { getLoginLog } from "api/basePage/log.js"
export default {
	name: "LoginLog",

	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "account",
					label: "用户账号"
				},
				{
					prop: "ip",
					label: "登录IP"
				},
				{
					prop: "lastModifiedDate",
					label: "登录时间"
				}
			],
			tableDataFunc: parameter => {
				return getLoginLog(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {}
}
</script>

<style lang="scss" scoped></style>
