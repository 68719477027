<template>
    <el-dialog
        title="领取记录"
        :visible.sync="recordDialog"
        width="70%"
        top="60px"
    >
        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
            <template slot="pic" slot-scope="{row}">
                <el-image
                    v-if="row.pic"
                    style="width:30px;height:30px"
                    :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
                    :preview-src-list="[row.pic]"
                />
            </template>
        </mvk-table>
    </el-dialog>
</template>

<script>
import { singleRecordList } from "api/business/marketing/coupon"
export default {
    name: "SingleRecordList",
    components: {  },

    directives: {  },

    data() {
        return {
            recordDialog: false,

            tableColumn: [{
                prop: "userName",
                label: "用户名"
            }, {
                prop: "pic",
                label: "头像",
                slot: true
            }, {
                prop: "creationDate",
                label: "创建时间"
            }],

            queryParam: {
                id: ""
            },

            tableDataFunc: parameter => {
                return singleRecordList(Object.assign(parameter), this.queryParam.id)
                    .then(res => {
                        return res.data
                    })
            }
        }
    },

    mounted() {
        
    },

    methods: {
        open(id) {
            this.recordDialog = true
            this.queryParam.id = id
            this.$nextTick(() => {
                this.$refs.mvkTable.refresh()
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>