<template>
	<div class="mvk-layout-content-card">
		<div class="add-btn flex-row" style="justify-content: space-between">
			<el-button size="small" type="primary" round @click="handleAdd"> 新增节日 </el-button>
			<el-form ref="form" :model="queryParam" :inline="true">
				<el-form-item label="节日名称：">
					<el-input v-model="queryParam.name" />
				</el-form-item>
				<el-form-item label="节日类型：">
					<el-select v-model="queryParam.type" placeholder="请选择" clearable>
						<el-option label="传统节日" :value="1" />
						<el-option label="西方节日" :value="2" />
						<el-option label="公众节日" :value="3" />
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<el-table-column label="操作" width="90" align="center" header-align="center">
				<template slot-scope="{ row }">
					<!-- <el-button type="text" size="small" @click="handleEdit(row)"> 编辑 </el-button> -->
					<delete-button :id="row.id" button-type="text" size="small" @delete="handleDelete" />
				</template>
			</el-table-column>
		</mvk-table>
		<add-edit-dialog ref="modalForm" @saveSuccess="refresh" />
	</div>
</template>

<script>
import { getHolidayList, deleteHoliday } from "api/business/marketing/holiday"
import { listMixin } from "views/mixin/listMixin"
export default {
	name: "HolidaySetting",
	mixins: [listMixin],
	components: {
		"add-edit-dialog": () => import("./add.vue")
	},
	data() {
		return {
			// 查询条件参数
			queryParam: {
				name: "",
				type: ""
			},
			tableColumn: [
				{
					prop: "id",
					label: "节日ID",
					width: 90
				},
				{
					prop: "name",
					label: "节日名称"
				},
				{
					prop: "calendarDate",
					label: "公历时间"
				},
				{
					prop: "lunarDate",
					label: "农历时间"
				},
				{
					prop: "type",
					label: "节日类型",
					formatter: value => {
						let temp = {
							1: "传统节日",
							2: "西方节日",
							3: "公众节日"
						}
						return temp[value.type]
					}
				}
			]
		}
	},
	computed: {
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))
			return parameter => {
				return getHolidayList(Object.assign(parameter, params)).then(res => {
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {
		refresh() {
			this.$refs.mvkTable.refresh()
		},

		handleDelete(data) {
			deleteHoliday(data.id).then(() => {
				this.$notify.success({
					title: "删除成功"
				})
				this.$refs.mvkTable.refresh()
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
