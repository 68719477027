<template>
	<el-drawer title="派发" :visible.sync="viewDialog" size="530px">
		<el-form ref="template" :model="info" :rules="rules" label-width="116px">
			<el-form-item label="派发任务名称" prop="distrName">
				<!-- <el-input
					v-model="info.distrName"
					placeholder="请输入需要派发的批次号，以分号隔开”;”支持填写多批次号"
				/> -->
				<el-input
					v-model="info.distrName"
					placeholder="请输入派发任务名称"
				/>
			</el-form-item>

			<el-form-item label="券名称" prop="alias">
				<el-select v-model="info.alias" placeholder="下拉选择劵类型" style="width: 240px">
					<el-option v-for="(item, i) in aliasList" :key="i" :label="item.title" :value="item.alias" />
				</el-select>
			</el-form-item>
			<el-form-item label="数量" prop="num">
				<el-input
					v-model="info.num"
					type="number"
					style="width: 240px"
					:min="1"
					:max="10"
					placeholder="每人派券张数，最大不超过10张"
					@blur="checkNum"
				/>
			</el-form-item>
			<el-form-item label="上传附件" prop="fileUri">
				<el-upload
					ref="upload"
					action
					:headers="uploadHeaders"
					:http-request="handleUpload"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					:on-preview="handlePreview"
					:limit="1"
					:on-remove="handleRemove"
					:file-list="fileList"
					style="height:100px"
				>
					<el-button slot="trigger" size="small" type="primary" :disabled="!!fileList.length">选取文件</el-button>
					<el-button style="margin-left: 10px" size="small" @click="toDownloadTemplate">下载派发模版</el-button>
					<div slot="tip" class="upload-tips">支持文件格式：.xlsx ，单个文件不能超过500kb</div>
				</el-upload>
			</el-form-item>
			<el-form-item style="margin-top: 60px">
				<el-button :loading="loading" @click="close()">取消</el-button>
				<el-button type="primary" :loading="loading" style="width: 200px" @click="send()">派发</el-button>
			</el-form-item>
		</el-form>
	</el-drawer>
</template>

<script>
import { getToken } from "api/auth"
import { fileUploadApi  } from "api/business/selfManager/picture.js"
import { couponDistrAdd, ecouponAliasList, couponDistrDownload } from "api/business/marketing/coupon"
import { blobTofile } from "@/utils/utils"
// import { voucherTypeList } from "@/utils/constants"
export default {
	data() {
		return {
			viewDialog: false,
			info: {
				distrName: "",
				alias: "",
				num: "",
				fileUri: null
			},
			rules: {
				distrName: {
					required: true,
					message: "请输入批次号"
				},
				alias: {
					required: true,
					message: "请输入通知内容"
				},
				num: [
					{
						required: true,
						message: "请输入数量"
					},
					
					{ pattern: /^([1-9]|10)$/, message: "最多只能派送10张", trigger: "blur" }
					
				],
				fileUri: {
					required: true,
					message: "请上传派送名单"
				}
			},
			fileList: [],
			loading: false,

			uploadHeaders: { "X-Token": getToken() },

			aliasList: []
		}
	},
	methods: {
		show() {
			this.viewDialog = true
			if (this.aliasList.length === 0) this.getAliasList()
		},
		getAliasList() {
			ecouponAliasList().then(res => {
				this.aliasList = res.data
			})
		},

		handleUpload(param) {
			const formData = new FormData()
			formData.append("multipart", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			// this.fileList = [formData]
			const params = {
				model: "ecoupon",
				pid: 20
			}
			fileUploadApi(formData, params).then(res => {
				this.fileList = [{
					name: res.data.fileName,
					url: res.data.url
				}]
				this.info.fileUri = res.data.url
				this.$nextTick(() => {
					this.$refs.template.validateField("fileUri")
				})
			}).finally(() => {
				loading.close()
				
			})
		},
		submitUpload() {
			this.$refs.upload.submit()
			const params = {
				model: "ecoupon",
				pid: 20
			}
			fileUploadApi(this.fileList[0], params).then(res => {
				this.fileList = [{
					name: res.data.fileName,
					url: res.data.url
				}]
				this.info.fileUri = res.data.url
			})
		},
		handlePreview(file) {
			console.log(file)
		},
		handleRemove(file, fileList) {
			console.log(file, fileList)
		},
		// 导入模板下载
		toDownloadTemplate() {
			couponDistrDownload().then(r => {
				blobTofile(r, "导入模版", "application/vnd.ms-excel")
			})
		},
		close() {
			this.viewDialog = false
		},
		send() {
			this.loading = true
			this.$refs.template.validate(voild => {
				if (voild) {
					couponDistrAdd(this.info).then(() => {
						this.$emit("saveSuccess")
						this.viewDialog = false
						this.$notify.success({
							title: "成功",
							message: "派发成功"
						})
					}).finally(() => { 
						this.loading = false
					})
				}
			})
		},
		checkNum(e) {
			const value = e.currentTarget.value
			const reg = /^([1-9]|10)$/
			if (!reg.test(value) && value !== 0 && value !== "") {
				this.$message.error("请输入正确的数值")
				e.target.focus()
				return e.currentTarget.value = ""
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.upload-tips {
	color: #aaa;
	font-size: 12px;
}
</style>
