<template>
	<el-drawer :title="title" :visible.sync="visible" size="70%" :append-to-body="true" :wrapper-closable="false">
		<div class="flex-column height100">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" :inline="false">
				<el-form-item label="链接形式">
					{{ form.refType }}
					<el-radio-group v-model="form.refType" @change="linkTypeChange">
						<el-radio v-for="(item, index) in linkTypeArray" :key="item" :label="index" border>
							{{ item }}
						</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<!--商品链接-->
			<advert-goods v-if="form.refType == 1" :checked-id.sync="checkedId" @getInfo="getInfo" />
			<!--商品分类-->
			<!-- <advert-category v-if="form.refType == 2" @getInfo="getInfo" :checkedId="checkedId"> </advert-category> -->
			<!--秒杀-->
			<!-- <advert-seckill v-if="form.refType == 3" @getInfo="getInfo" :checkedId="checkedId"> </advert-seckill> -->
			<!--拼团-->
			<!-- <advert-groupActivity v-if="form.refType == 4" @getInfo="getInfo" :checkedId="checkedId">
			</advert-groupActivity> -->
			<div v-if="form.refType == 5">
				<!-- 水电煤 -->
				<el-radio-group v-model="livingValue" @change="livingChange">
					<el-radio v-for="item in livingArray" :key="item.id" :label="item.id" border>
						{{ item.label }}
					</el-radio>
				</el-radio-group>
			</div>
			<!--直播间-->
			<advert-broadcast v-if="form.refType == 6" :checked-id.sync="checkedId" @getInfo="getInfo" />
			<!--新人专享-->
			<!-- <advert-newcomer v-if="form.refType == 7" @getInfo="getInfo" :checkedId="checkedId"></advert-newcomer> -->
			<!-- 专题活动 -->
			<topic v-if="form.refType == 10" :checked-id="checkedId" @getInfo="getInfo" />
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="">
				<el-button type="primary" @click="handleSubmit"> 保存 </el-button>
				<el-button style="margin-left:20px" @click="visible = false"> 关闭 </el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
export default {
	name: "AdvertLink",
	components: {
		"advert-goods": () => import("./components/goods.vue"),
		"advert-category": () => import("./components/category.vue"),
		"advert-seckill": () => import("./components/seckill.vue"),
		"advert-groupActivity": () => import("./components/groupActivity.vue"),
		"advert-broadcast": () => import("./components/broadcast.vue"),
		"advert-newcomer": () => import("./components/newcomer.vue"),
		"topic": () => import("./components/topic.vue")
	},
	data() {
		return {
			visible: false,
			title: "广告链接",
			form: {
				refType: 0,
				refNo: "",
				refDesc: ""
			},
			rules: {},
			linkTypeArray: [
				"无链接", // 0
				"商品链接", // 1
				"商品分类链接", // 2
				"秒杀活动", // 3
				"拼团活动", // 4
				"生活缴费",  // 5
				"直播间", // 6
				"新人专享", // 7
				"满减活动", // 8
				"领券中心",  // 9
				"专题活动"  // 10
				// "URL链接"  // 10
			],
			livingArray: [
				{ id: "c2670", label: "水费" },
				{ id: "c2680", label: "电费" },
				{ id: "c2681", label: "煤气" }
			],
			livingValue: "",
			checkedId: ""
		}
	},

	mounted() {},

	methods: {
		open(recode) {
			this.visible = true
			this.$nextTick(() => {
				this.form = JSON.parse(JSON.stringify(recode))

				if (this.form.refType == 1) {
					let temp = JSON.parse(this.form.refNo)

					this.checkedId = temp.id + "$$$" + temp.shopId
				} else if (this.form.refType == 5) {
					this.livingValue = this.form.refNo
				} else {
					this.checkedId = parseInt(this.form.refNo)
				}
			})
		},

		livingChange(value) {
			this.form.refNo = value
			this.form.refDesc = this.livingArray.filter(item => item.id == value)[0].label
		},
		linkTypeChange() {
			this.form.refNo = ""
			this.form.refDesc = ""
		},

		getInfo(info) {
			// console.log(info)
			this.form.refNo = info.id
			if (this.form.refType == 1) {
				// 商品链接

				this.form.refDesc = info.prodName
				let temp = {}
				temp.id = info.prodId
				temp.shopId = info.shopId
				this.form.refNo = JSON.stringify(temp)
			} else if (this.form.refType == 2) {
				// 商品分类链接

				this.form.refDesc = info.name
			} else if (this.form.refType == 3) {
				// 秒杀链接

				this.form.refDesc = info.title
			} else if (this.form.refType == 4) {
				// 拼团链接
				this.form.refDesc = info.name
			} else if (this.form.refType == 6) {
				// 直播间
				this.form.refDesc = info.name
				this.form.refNo = info.wechatRoomId
			} else if (this.form.refType == 7) {
				// 新人专享
				this.form.refDesc = info.prodName
				this.form.refNo = info.productId
			} else if (this.form.refType == 10) {
				// 专题活动
				this.form.refDesc = info.name
				this.form.refNo = info.id
				console.log(this.form.refDesc)
				console.log(this.form.refNo)
			}
		},

		handleSubmit() {
			// if ((this.form.refType != 0 && this.form.refNo == "") || this.form.refNo == null) {
			// 	this.$message.warning("请先选择对应的链接")
			// 	return
			// }
			this.$emit("linkInfo", this.form)
			this.visible = false
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio.is-bordered {
	margin-top: 12px;
}
</style>
