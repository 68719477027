<template>
	<el-drawer title="供应商用户账号" :visible.sync="addUserDialog" :close-on-click-modal="false" size="50%">
		<div class="drawer-content">
			<el-button type="primary" size="small" class="mb20" @click="handleAdd()"> 新增系统账号 </el-button>
			<el-form ref="formRef" :model="supplierUserForm" :rules="supplierUserRoles">
				<el-table :data="supplierUserForm.userInfoArray" stripe class="border-table">
					<el-table-column type="index" label="序号" width="50px" />
					<el-table-column prop="username" label="用户账号">
						<template slot-scope="scope">
							<el-form-item
								:prop="'userInfoArray.' + scope.$index + '.username'"
								:rules="supplierUserRoles.username"
							>
								<el-input v-if="!scope.row.id" v-model="scope.row.username" placeholder="用户账号" />
								<span v-else>{{ scope.row.username }}</span>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="fullName" label="用户名称">
						<template slot-scope="scope">
							<el-form-item
								:prop="'userInfoArray.' + scope.$index + '.fullName'"
								:rules="supplierUserRoles.fullName"
							>
								<el-input v-if="!scope.row.id" v-model="scope.row.fullName" placeholder="用户名称" />
								<span v-else>{{ scope.row.fullName }}</span>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="mobile" label="手机号码">
						<template slot-scope="scope">
							<el-form-item
								:prop="'userInfoArray.' + scope.$index + '.mobile'"
								:rules="supplierUserRoles.mobile"
							>
								<el-input v-if="!scope.row.id" v-model="scope.row.mobile" placeholder="手机号码" />
								<span v-else>{{ scope.row.mobile }}</span>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button v-if="!scope.row.id" type="text" @click="handleDelete(scope.$index)">
								删除
							</el-button>
							<delete-button v-else v-has="'sys:supplier-user:delete'" button-type="text" size="small" @delete="handleRealDelete(scope.row.id, scope.$index)">
								删除
							</delete-button>
						</template>
					</el-table-column>
				</el-table>
			</el-form>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button type="primary" class="form-submit" @click="addSubmit()">保存</el-button>
				<el-button type="text" @click="addUserDialog = false">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { isMobile, isChinese } from "@/utils/validate"
import { mapState, mapActions } from "vuex"

import { addUser,deleteSupplierUser } from "api/basePage/user.js"
import { supplierUser } from "api/basePage/supplier.js"

const formDemo = {
	id: "",
	username: "",
	mobile: "",
	password: "supplier123456",
	confirmPassword: "supplier123456",
	email: "",
	fullName: "",
	roleIds: []
}
export default {
	name: "AddSupplierUser",
	computed: {
		...mapState("dict", ["rolelist"]),

		supplierRole() {
			return this.rolelist.filter(item => item.code === "SUPPLIER")[0].id
		}
	},
	data() {
		return {
			addUserDialog: false,
			supplierUserForm: {
				organId: "",
				type: 2,
				userInfoArray: [
					{
						id: "",
						username: "",
						mobile: "",
						password: "supplier123456",
						confirmPassword: "supplier123456",
						email: "",
						fullName: "",
						roleIds: []
					}
				]
			},
			supplierUserRoles: {
				username: [
					{
						required: true,
						message: "请输入用户账号",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							// console.log(rule)
							if (isChinese(value)) {
								callback(new Error("用户账号不能包含中文"))
							} else {
								callback()
							}
						},
						trigger: ["change", "blur"]
					}
				],
				fullName: [
					{
						required: true,
						message: "请输入用户名称",
						trigger: "blur"
					}
				],
				mobile: [
					{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							if (!isMobile(value)) {
								callback(new Error("手机号格式错误"))
							} else {
								callback()
							}
						},
						trigger: ["change", "blur"]
					}
				]
			},
			supplierUserList: []
		}
	},

	mounted() {
		if (this.rolelist.length == 0) {
			this.getRoleList()
		}
	},

	methods: {
		...mapActions("dict", ["getRoleList"]),
		open(supplierId) {
			this.addUserDialog = true
			this.supplierUserForm.organId = supplierId
			supplierUser(supplierId).then(res => {
				res.data &&
					res.data.forEach(item => {
						item.id = item.userId
					})
				this.supplierUserForm.userInfoArray = res.data
			})
		},

		handleAdd() {
			let temp = { ...formDemo }
			temp.roleIds = [this.supplierRole]
			this.supplierUserForm.userInfoArray.push({ ...temp })
		},

		handleDelete(index) {
			this.supplierUserForm.userInfoArray.splice(index, 1)
		},

		handleRealDelete(id, index) {
			deleteSupplierUser(id).then(res => {
				this.supplierUserForm.userInfoArray.splice(index, 1)
			}).catch(err => {
				this.$notify.error({
					title: "删除用户失败"
				})
			});
		},

		addSubmit() {
			this.$refs["formRef"].validate(valid => {
				if (valid) {
					this.supplierUserForm.userInfoReqs = this.supplierUserForm.userInfoArray.filter(item => {
						return item.id == ""
					})
					addUser(this.supplierUserForm)
						.then(() => {
							this.$notify.success({
								title: "添加用户成功"
							})
							this.addEditDialog = false
							this.$emit("saveSuccess")
						})
						.catch(() => {
							this.$notify.error({
								title: "添加用户失败"
							})
						})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item--small.el-form-item {
	margin-bottom: 0;
}
</style>
