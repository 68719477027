<template>
	<div>
		<vue-ueditor-wrap
			v-model="contents"
			:config="myConfig"
			style="width: 90%"
			@beforeInit="addCustomDialog"
			@ready="ready"
		/>

		<!-- <el-dialog
            title="上传图片"
            v-if="dialogVisible"
            :visible.sync="dialogVisible"
            width="30%"
            >
            <upload-image @submit="getAllImage"></upload-image>
        </el-dialog> -->

		<el-dialog title="选择图片" :visible.sync="dialogVisible" :close-on-click-modal="false" top="50px" width="74%">
			<picture-index pictureType="2" @getImage="getAllImage" :isMore="1000"></picture-index>
		</el-dialog>
	</div>
</template>

<script>
import PictureIndex from "views/bussiness/selfManager/picture/index"
export default {
	name: "UeditorFrom",
	components: {
		PictureIndex
	},
	props: {
		content: {
			type: String,
			default: ""
		}
	},
	beforeMount() {
		// 接收 v-model 数据
		if (this.value) {
			this.contents = this.value
		}
	},
	data() {
		return {
			contents: "",
			myConfig: {
				autoHeightEnabled: false, // 编辑器不自动被内容撑高
				initialFrameHeight: 500, // 初始容器高度
				initialFrameWidth: "100%", // 初始容器宽度
				UEDITOR_HOME_URL: "/UEditor/",
				serverUrl: "",
				maximumWords: 1000000
			},

			dialogVisible: false,

			editorInstance: null
		}
	},
	watch: {
		content: function () {
			this.contents = this.content
		},
		contents: function (val) {
			this.$emit("input", val)
		}
	},
	methods: {
		ready(editorInstance) {
			this.editorInstance = editorInstance
		},

		// 添加自定义弹窗
		addCustomDialog() {
			let vm = this
			window.UE.registerUI(
				"test-dialog",
				function (editor, uiName) {
					var btn = new window.UE.ui.Button({
						name: "dialog-button",
						title: "上传图片",
						cssRules: `background-image: url(@/assets/images/icons.png);background-position: -726px -77px;`,
						onclick: function () {
							vm.dialogVisible = true
							editor.execCommand(uiName)
						}
					})
					return btn
				},
				37
			)
		},

		getAllImage(files) {
			let vm = this
			this.dialogVisible = false

			// files.forEach(file => {
			//     var reader = new FileReader();
			//     reader.onloadend = function () {
			//         vm.contents += `<img src="${reader.result}" >`
			//         console.log(vm.contents);
			//     };
			//     if (file) {
			//         reader.readAsDataURL(file);
			//     }

			//     // let url = window.URL.createObjectURL(file)
			//     // vm.contents += `<img src="${url}" >`
			// })

			files.forEach((file) => {
				vm.contents += `<img src="${file}" style="display:block">`
			})
		}
	}
}
</script>

<style scoped></style>
