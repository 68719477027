<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" :close-on-click-modal="false" size="50%">
		<el-form
			ref="userForm"
			:model="userForm.userInfoReqs[0]"
			:rules="userRules"
			label-width="100px"
			style="width: 80%"
		>
			<el-form-item prop="organId" label="所属机构">
				<el-cascader
					v-model="userForm.organId"
					:options="organizationList"
					:props="props2"
					clearable
					filterable
					class="width100"
					@change="changeOrgan"
				/>
			</el-form-item>
			<el-form-item prop="username" label="用户账号">
				<el-input v-model="userForm.userInfoReqs[0].username" placeholder="用户账号" />
			</el-form-item>
			<el-form-item prop="fullName" label="用户名称">
				<el-input v-model="userForm.userInfoReqs[0].fullName" placeholder="用户名称" />
			</el-form-item>
			<el-form-item v-if="!userForm.userInfoReqs[0].id" label="用户密码" prop="password">
				<el-input
					v-model="userForm.userInfoReqs[0].password"
					type="password"
					placeholder="请输入用户密码"
					autocomplete="off"
					show-password
					class="inputWidth"
				/>
				<el-button type="primary" @click="autoGeneratePassword">自动生成</el-button>
				<p class="cr_999 fs12">注：密码不少于8位，由数字+字母组成</p>
			</el-form-item>
			<el-form-item v-if="!userForm.userInfoReqs[0].id" label="密码强度">
				<el-progress
					:percentage="percentage"
					:stroke-width="26"
					:color="customColor"
					:format="progressFormat"
					class="inputWidth"
				/>
			</el-form-item>
			<el-form-item v-if="!userForm.userInfoReqs[0].id" label="确认密码" prop="confirmPassword">
				<el-input
					v-model="userForm.userInfoReqs[0].confirmPassword"
					type="password"
					placeholder="请输入确认密码"
					autocomplete="off"
					show-password
					class="inputWidth"
				/>
				<p class="cr_999 fs12">注：密码不少于8位，由数字+字母组成</p>
			</el-form-item>
			<el-form-item prop="mobile" label="手机号码">
				<el-input v-model.number="userForm.userInfoReqs[0].mobile" placeholder="手机号码" />
			</el-form-item>
			<el-form-item prop="email" label="邮箱">
				<el-input v-model="userForm.userInfoReqs[0].email" placeholder="邮箱" />
			</el-form-item>
			<el-form-item
				v-show="userForm.userInfoReqs[0].roleIds.indexOf(supplierRoleId) == -1"
				prop="roleIds"
				label="角色分配"
			>
				<el-select
					v-model="userForm.userInfoReqs[0].roleIds"
					multiple
					placeholder="请选择角色"
					style="width: 100%"
				>
					<el-option v-for="item in roleNotSupplier" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button v-throttle="addSubmit" type="primary" class="form-submit"> 保存 </el-button>
			</el-form-item>
		</el-form>
	</el-drawer>
</template>

<script>
import { isMobile, isChinese } from "@/utils/validate"
import { mapState, mapActions } from "vuex"
import { editeUser, addUser, searchUser } from "api/basePage/user.js"

// 高强度：数字+字母+特殊符号，不少于8位字符
const strongRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/

// 中强度：数字+字母（包含大小写），不少于8位字符
const mediumRegex = /^(?=.{8,})((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).*$/

const passRegex = /^(?=.{8,})(?![0-9]+$)(?![a-zA-Z]+$)([0-9A-Za-z].*$)/

export default {
	name: "OrganizationAddEdit",

	data() {
		return {
			title: "新增",
			addEditDialog: false,
			userForm: {
				organId: "",
				type: 1,
				userInfoReqs: [
					{
						id: "",
						username: "",
						mobile: "",
						password: "",
						confirmPassword: "",
						email: "",
						fullName: "",
						roleIds: []
					}
				]
			},
			userRules: {
				organId: [
					{
						required: true,
						message: "请选择所属机构",
						trigger: "change"
					}
				],
				password: [
					{
						required: true,
						message: "请输入密码",
						trigger: ["blur", "change"]
					},
					{
						validator: (rule, value, callback) => {
							if (value === "") {
								callback(new Error("请输入密码"))
							} else if (!passRegex.test(value)) {
								callback(new Error("密码必须包含数字和字母,且不少于8位"))
							} else {
								callback()
							}
						},
						trigger: ["blur", "change"]
					}
				],
				confirmPassword: [
					{
						required: true,
						message: "请输入确认密码",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							if (value === "") {
								callback(new Error("请输入确认密码"))
							} else if (value !== this.password_watch) {
								callback(new Error("两次输入密码不一致!"))
							} else {
								callback()
							}
						},
						trigger: ["blur", "change"]
					}
				],
				username: [
					{
						required: true,
						message: "请输入用户账号",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							// console.log(rule)
							if (isChinese(value)) {
								callback(new Error("用户账号不能包含中文"))
							} else {
								callback()
							}
						},
						trigger: ["change", "blur"]
					}
				],
				fullName: [
					{
						required: true,
						message: "请输入用户名称",
						trigger: "blur"
					}
				],
				mobile: [
					{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					},
					{
						validator: (rule, value, callback) => {
							if (!isMobile(value)) {
								callback(new Error("手机号格式错误"))
							} else {
								callback()
							}
						},
						trigger: ["change", "blur"]
					}
				]
			},

			roleLists: [], // 角色列表

			props2: {
				children: "child",
				label: "name",
				value: "id",
				emitPath: false,
				checkStrictly: true
			},
			organizationList: [],

			percentage: 0,
			format: "",
			customColor: "#f56c6c"
		}
	},
	computed: {
		...mapState("dict", ["rolelist"]),

		roleNotSupplier() {
			// 过滤供应商角色
			return this.rolelist.length > 0 && this.rolelist.filter(item => item.code !== "SUPPLIER")
		},

		supplierRoleId() {
			// 供应商角色id
			return this.rolelist.length > 0 && this.rolelist.filter(item => item.code === "SUPPLIER")[0].id
		},

		password_watch() {
			return this.userForm.userInfoReqs[0].password
		}
	},
	watch: {
		password_watch(newValue) {
			this.checkStrong(newValue)
		}
	},

	mounted() {
		if (this.rolelist.length == 0) {
			this.getRoleList()
		}
	},

	methods: {
		...mapActions("dict", ["getRoleList", "getOrganizationTree"]),
		add() {
			this.addEditDialog = true
			this.organizationList = JSON.parse(sessionStorage.getItem("eshop-organization"))
			this.userForm = {
				organId: "",
				type: 1,
				userInfoReqs: [
					{
						id: "",
						username: "",
						mobile: "",
						password: "",
						confirmPassword: "",
						email: "",
						fullName: "",
						roleIds: [],
						organId: ""
					}
				]
			}
		},
		edit(id) {
			this.addEditDialog = true
			this.organizationList = JSON.parse(sessionStorage.getItem("eshop-organization"))
			searchUser(id).then(res => {
				let temp = res.data

				temp.roleIds = temp.role.map(item => item.id)

				this.userForm.organId = temp.organId
				this.userForm.type = 1

				this.$set(this.userForm, "userInfoReqs", [temp])
			})
		},

		addSubmit() {
			this.$refs["userForm"].validate(valid => {
				if (valid) {
					this.userForm.userInfoReqs[0].id
						? editeUser(this.userForm)
							.then(() => {
								this.$notify.success({
									title: "修改成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "修改失败"
								})
							})
						: addUser(this.userForm)
							.then(() => {
								this.$notify.success({
									title: "添加用户成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "添加用户失败"
								})
							})
				} else {
					return false
				}
			})
		},

		// 自动生成密码
		autoGeneratePassword() {
			var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
			var list = []
			for (let i = 0; i < 8; i++) {
				list.push(Math.round(Math.random() * 61))
			}
			// console.log(list)

			var password = ""
			for (var n = 0; n < 8; n++) {
				password += chars.charAt(list[n])
			}
			if (passRegex.test(password)) {
				this.userForm.userInfoReqs[0].password = password
			} else {
				this.autoGeneratePassword()
			}
		},

		// 密码校验
		checkStrong(value) {
			if (value == "") {
				this.customColor = "#f56c6c"
				this.percentage = 0
				this.format = ""
				return
			}
			if (strongRegex.test(value)) {
				this.customColor = "#67c23a"
				this.percentage = 100
				this.format = "强"
			} else if (mediumRegex.test(value)) {
				this.customColor = "#e6a23c"
				this.percentage = 66
				this.format = "中"
			} else {
				this.customColor = "#f56c6c"
				this.percentage = 30
				this.format = "弱"
			}
		},

		progressFormat() {
			return this.format
		},

		changeOrgan(value) {
			this.userForm.userInfoReqs[0].organId = value
		}
	}
}
</script>

<style lang="scss" scoped></style>
