<template>
    <div class="mvk-layout-content-card">
        <el-button v-has="'sys:filter:insert'" size="small" round type="primary" class="mb15" @click="handleAdd">
            新增
        </el-button>

        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
            <template slot="isValid" slot-scope="{ row }" label="是否生效">
                <el-switch v-model="row.isValid" disabled :active-value="1" :inactive-value="0" />
            </template>
            <el-table-column label="操作" width="180">
                <template slot-scope="{ row }">
                    <el-button v-has="'sys:filter:update'" type="text" size="small" @click="handleEdit(row)">
                        编辑
                    </el-button>
                    <delete-button
                        :id="row.id"
                        v-has="'sys:filter:delete'"
                        button-type="text"
                        size="small"
                        @delete="handleDelete"
                    >
                        删除
                    </delete-button>
                </template>
            </el-table-column>
        </mvk-table>

        <add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
    </div>
</template>

<script>
import { permissionList, deletePermission } from "api/basePage/permission"

import { listMixin } from "views/mixin/listMixin"

export default {
    name: "DataPermissionIndex",

    components: {
        "add-edit-dialog": () => import("./addEdit.vue")
    },
    mixins: [listMixin],

    data() {
        return {
            tableColumn: [
                { prop: "id", label: "ID" },
                { prop: "filterName", label: "权限名称" },
                { prop: "remark", label: "备注" },
                { prop: "isValid", label: "是否有效", slot: true }
            ],

            tableDataFunc: parameter => {
                return permissionList(Object.assign(parameter)).then(res => {
                    return res.data
                })
            }
        }
    },

    mounted() {},

    methods: {
        handleAddOrEditSave() {
            this.$refs.mvkTable.refresh()
        },

        handleDelete(data) {
            deletePermission(data.id)
                .then(() => {
                    this.$notify.success({
                        title: "删除成功"
                    })
                    this.$refs.mvkTable.refresh()
                })
                .catch(() => {
                    this.$notify.error({
                        title: "删除失败"
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped></style>
