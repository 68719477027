<template>
	<div class="mvk-layout-content-card">
		<!-- 操作 -->
		<div class="options custom-tree-node">
			<div>
				<router-link :to="{ path: '/keyAccountManager/createKeyAccount' }" class="add">
					<el-button size="small" type="primary" class="mr10">+ 新增大客户店</el-button>
				</router-link>
				<el-button type="primary" :disabled="selectList.length == 0" @click="exportQRCode">
					批量导出二维码
				</el-button>
			</div>
			<div>
				<el-input v-model="shopName" placeholder="请输入店铺名称查找" prefix-icon="el-icon-search" clearable @input="search()" />
			</div>
		</div>
		<!-- 内容 -->
		<div>
			<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15"
				row-key="cid" @selection-change="handleSelectionChange"
			>
				<template slot="enabled" slot-scope="{ row }" label="状态">
					<el-switch v-model="row.enabled" @change="onStartStop(row)" />
				</template>
				<template slot="productNum" slot-scope="{ row }" label="店铺商品">
					<div class="tolink" @click="toLink(row.id, 'goods')">{{ row.productNum }} 款</div>
				</template>
				<template slot="memberNum" slot-scope="{ row }" label="店铺会员">
					<div class="tolink" @click="toLink(row.id, 'member')">{{ row.memberNum }} 人</div>
				</template>
				<el-table-column slot="head" type="selection" width="55" />
				<el-table-column label="操作" width="180">
					<template slot-scope="{ row }">
						<el-button type="text" @click="handleDetail(row)">查看</el-button>
						<el-button type="text" @click="handleEditor(row)">编辑</el-button>
						<el-popover trigger="hover">
							<template #reference>
								<el-button type="text" class="ml10" @click="handleExport(row)">导出店铺二维码</el-button>
							</template>
							<template #default>
								<div class="flex-column flex-a-c">
									<div class="qr_title">{{ row.name }}</div>
									<div class="qr_desc">
										微信扫描二维码进入专属店铺
									</div>
									<vue-qr ref="Qrcode"
										:text="'https://sxymini.mevikycloud.com/shopHome?shopId=' + row.id"
										qid="testQrId" :margin="10" :size="160"
									/>
								</div>
							</template>
						</el-popover>
						<!-- <el-dropdown style="margin-left: 10px; font-size: 12px">
							<span class="el-dropdown-link"> 更多<i class="el-icon-arrow-down el-icon--right" /> </span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="handleEditor(row)"> 编辑 </el-dropdown-item>
								<el-dropdown-item @click.native="handleDel(row)">
									删除
								</el-dropdown-item> 
								<el-dropdown-item>
									<el-popover trigger="hover">
										<template #reference>
											<div @click="handleExport(row)">导出店铺二维码</div>
										</template>
										<template #default>
											<div class="flex-column flex-a-c">
												<div class="qr_title">{{ row.name }}</div>
												<div class="qr_desc">
													微信扫描二维码进入专属店铺
												</div>
												<vue-qr ref="Qrcode"
													:text="'https://sxymini.mevikycloud.com/shopHome?shopId=' + row.id"
													qid="testQrId" :margin="10" :size="160"
												/>
											</div>
										</template>
									</el-popover>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown> -->
					</template>
				</el-table-column>
			</mvk-table>
		</div>
	</div>
</template>

<script>
import { listMixin } from "views/mixin/listMixin"
import {
	getMajorShopList,
	putMajorShopStatus,
	getMajorShopBatchByQRCode,
	getMajorShopByQRCode
} from "api/business/selfManager/keyAccount"
import { blobTofile } from "@/utils/utils"
import VueQr from "vue-qr"

import { debounce } from "@/utils/utils"
export default {
	mixins: [listMixin],
	components: { VueQr },
	data() {
		return {
			shopName: "",
			tableColumn: [
				{ prop: "id", label: "店铺编号", width: 76 },
				{ prop: "name", label: "店铺名称", minWidth: 120 },
				{ prop: "productNum", label: "店铺商品", slot: true },
				{ prop: "memberNum", label: "店铺会员", slot: true },
				{ prop: "salesperson", label: "大客户销售" },
				{ prop: "shopManagerName", label: "大客户联系人", width: 110 },
				{ prop: "shopManagerMobile", label: "联系人电话" },
				{ prop: "enabled", label: "是否启用", slot: true }
			],

			tableDataFunc: async parameter => {
				return getMajorShopList(Object.assign(parameter, { name: this.shopName })).then(res => {
					return res.data
				})
			},
			selectList: []
		}
	},
	methods: {
		handleSelectionChange(val) {
			this.selectList = val
		},
		exportQRCode() {
			const str = this.selectList.map(e => e.id).join(",")
			getMajorShopBatchByQRCode({ ids: str }).then(r => {
				blobTofile(r, "专营店二维码集", "application/zip")
			})
		},
		onStartStop(row) {
			putMajorShopStatus(row.id).then(r => {
				if (r.status === "OK") {
					this.$refs.mvkTable.refresh()
				}
			})
		},
		search: debounce(function() {
			this.$refs.mvkTable.refresh()
		}, 300),
		handleExport(row) {
			getMajorShopByQRCode(row.id).then(r => {
				blobTofile(r, row.name + "专营店二维码.png", "application/x-png")
			})
		},
		handleDetail(row) {
			this.$router.push({
				path: "/keyAccountManager/createKeyAccount",
				query: {
					id: row.id,
					readOnly: true
				}
			})
		},
		toLink(id, linkName) {
			this.$router.push({
				path: "/keyAccountManager/createKeyAccount",
				query: {
					id,
					readOnly: true,
					activeName: linkName
				}
			})
		},
		// 新增或保存
		handleEditor(row) {
			this.$router.push({
				path: "/keyAccountManager/createKeyAccount",
				query: {
					id: row.id
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	padding: 10px;
}

.qr_title {
	color: #4B5EFF;
	font-size: 18px;
}

.qr_desc {
	color: #999;
	font-size: 12px;
}

.el-dropdown-link {
	color: #4B5EFF;
	font-weight: 600;
	cursor: pointer;
}

.tolink {
	color: #4B5EFF;
	cursor: pointer;
}
</style>
