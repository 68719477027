<template>
	<el-drawer title="订单详情" :visible.sync="visible" size="75%">
		<el-steps v-if="!orderInfo.subStatus" :active="orderStatusStep()" style="width: 80%; margin: 0 auto">
			<template v-if="orderInfo.status == '6'">
				<el-step title="用户下单" :description="orderInfo.creationDate" />
				<el-step title="交易关闭" :description="orderInfo.finallyTime" />
			</template>
			<template v-else>
				<el-step title="用户下单" :description="orderInfo.creationDate" />
				<el-step title="待付款" :description="orderInfo.payTime" />
				<el-step title="待分享" :description="orderInfo.dvyTime" />
				<el-step title="待发货" :description="orderInfo.dvyTime" />
				<el-step title="待收货" :description="orderInfo.finallyTime" />
				<el-step title="已完成" :description="orderInfo.finallyTime" />
			</template>
		</el-steps>
		<el-steps v-else :active="orderStatusStep()" style="width: 80%; margin: 0 auto">
			<el-step title="用户下单" :description="orderInfo.creationDate" />
			<el-step title="申请退款" />
			<template v-if="orderInfo.subStatus == 8">
				<el-step title="退款中" :description="orderInfo.creationDate" />
				<el-step title="退款成功" :description="orderInfo.finallyTime" />
			</template>
			<template v-else-if="orderInfo.subStatus == 12">
				<el-step title="拒绝退款" :description="orderInfo.refuseReason" />
			</template>
			<template v-else-if="orderInfo.subStatus == 10">
				<el-step title="退款失败" :description="orderInfo.finallyTime" />
			</template>
			<template v-else>
				<el-step title="退款成功" :description="orderInfo.finallyTime" />
			</template>
		</el-steps>

		<el-tabs v-model="activeName" type="border-card" class="mt20">
			<el-tab-pane label="基本信息" name="1">
				<el-row :gutter="20">
					<el-col :span="12">
						<div class="description-term">用户昵称：{{ orderInfo.memberName }}</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">绑定电话：{{ orderInfo.memberMobile }}</div>
					</el-col>
				</el-row>
			</el-tab-pane>
			<el-tab-pane label="订单信息" name="2">
				<el-row :gutter="20">
					<el-col :span="12">
						<div class="description-term">订单编号：{{ orderInfo.code }}</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">下单时间：{{ orderInfo.creationDate }}</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">支付方式：{{ orderInfo.payType | PayType }}</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">支付时间：{{ orderInfo.payTime }}</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term" style="color: red">
							订单状态：
							{{ orderInfo.status | OrderStatus }}
							<span v-show="orderInfo.subStatus">【{{ orderInfo.subStatus | OrderStatus }}】</span>
						</div>
					</el-col>
					<el-col v-if="orderInfo.status == 6" :span="12">
						<div class="description-term" style="color: red">
							交易关闭原因：{{ orderInfo.closeType | OrderCloseType }}
						</div>
					</el-col>
					<el-col v-if="orderInfo.subStatus == 12" :span="12">
						<div class="description-term" style="color: red">
							拒绝退款原因：{{ orderInfo.refuseReason }}
						</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">订单类型：{{ orderInfo.type | OrderType }}</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">商品总额：{{ orderInfo.payMoney }}</div>
					</el-col>
					<el-col :span="12">
						<div v-if="orderInfo.type != 6" class="description-term">
							实际支付：{{ orderInfo.actualMoney }}
						</div>
						<div v-else class="description-term">积分支付：{{ orderInfo.payPoints }}积分</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">订单备注：{{ orderInfo.remarks }}</div>
					</el-col>
				</el-row>
			</el-tab-pane>
			<el-tab-pane label="商品信息" name="3">
				<el-table :data="orderInfo.items" border stripe>
					<el-table-column
						v-if="orderInfo.type == 0 || orderInfo.type == 2"
						prop="shopName"
						label="店铺名称"
					/>
					<el-table-column prop="prodName" label="商品名称" />
					<el-table-column prop="pic" label="商品图片">
						<template slot-scope="{ row }">
							<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
						</template>
					</el-table-column>
					<el-table-column prop="skuName" label="商品规格" />
					<el-table-column prop="productTotalAmount" label="商品总金额" />
					<el-table-column prop="prodCount" label="数量" />
					<el-table-column prop="supplierName" label="供应商" />
				</el-table>
			</el-tab-pane>
			<!-- <el-tab-pane label="收货信息" name="4">
				<el-row :gutter="20">
					<el-col :span="12">
						<div class="description-term">收货人：{{ orderInfo.receiptName }}</div>
					</el-col>
					<el-col :span="12">
						<div class="description-term">收货电话：{{ orderInfo.receiptMobile }}</div>
					</el-col>
					<el-col :span="24">
						<div class="description-term">收货地址：{{ orderInfo.receiptAddress }}</div>
					</el-col>
				</el-row>
			</el-tab-pane> -->
		</el-tabs>
	</el-drawer>
</template>

<script>
import { orderStatus, payType, orderType, orderCloseType } from "@/utils/constants"
import { getExpressListV2 } from "api/business/selfManager/express"
import { formatDate } from "@/utils/validate"
export default {
	name: "OrderDetail",

	components: {},

	filters: {
		OrderStatus(type) {
			return orderStatus[type]
		},
		PayType(type) {
			return payType[type]
		},
		OrderType(type) {
			return orderType[type]
		},
		OrderCloseType(type) {
			return orderCloseType[type]
		}
	},
	computed: {},

	data() {
		return {
			visible: false,
			orderInfo: {},
			activeName: "1",
			expressList: [],
			errorMsg: ""
		}
	},

	mounted() {},

	methods: {
		orderStatusStep() {
			if (!this.orderInfo.subStatus) {
				switch (this.orderInfo.status) {
					case 1:
						return 2
					case 2:
						return 4
					case 3:
						return 5
					case 5:
						return 6
					case 6:
						return 2
					case 11:
						return 5
					case 13:
						return 3
				}
			} else {
				switch (this.orderInfo.subStatus) {
					case 7:
						return 2
					case 8:
						return 3
					case 9:
						return 3
					case 10:
						return 3
					case 12:
						return 3
				}
			}
		},
		open(record) {
			this.visible = true
			this.$nextTick(() => {
				this.orderInfo = JSON.parse(JSON.stringify(record))
				console.log("this.orderInfo", this.orderInfo)
			})
		},

		// 查询快递信息
		handleExpress() {
			let params = {}
			if (!this.orderInfo.courierCompany) {
				params.com = "auto"
			} else {
				params.com = this.orderInfo.courierCompany
			}

			params.nu = this.orderInfo.courierNum
			params.phone = this.orderInfo.receiptMobile ? this.orderInfo.receiptMobile.substr(-4) : ""

			// {
			// 	'com':'auto',
			// 	'nu':'75495019990698',
			// 	'phone':'7655'
			// }
			// this.errorMsg = ""
			const param = {
				expCode: this.orderInfo.courierCompanyCode,
				mobile: this.orderInfo.receiptMobile,
				number: this.orderInfo.courierNum
			}
			getExpressListV2(param).then(res => {
				if (res.message === "OK" && res.status === "OK" && res.data.code === 200) {
					this.expressList = res.data.data.logisticsTraceDetails.map(e => {
						e.timestamp = formatDate(new Date(e.time), "yyyy-MM-dd HH:mm:ss")
						return e
					})
				} else {
					this.expressList = []
					this.errorMsg = res.data.msg
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.description {
	&-term {
		display: table-cell;
		padding-bottom: 5px;
		line-height: 20px;
		width: 50%;
		font-size: 14px;
	}
	::v-deep .el-divider--horizontal {
		margin: 12px 0 !important;
	}
}
.pane-box {
	// display: flex;
	// flex-direction: column;
	// overflow: hidden;
	// height: calc(100% - 200px);
}
.time-line-box {
	margin-top: 10px;
	// flex: 1;
	// overflow-y: auto;
	height: calc(100vh - 300px);
	overflow-y: auto;
	overflow-x: hidden;
}
</style>
