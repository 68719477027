<template>
	<div>
		<el-tabs v-model="status" @tab-click="changeTabs">
			<el-tab-pane ref="setting" label="待入仓商品" name="0">
				<setting-goods v-if="status == 0" />
			</el-tab-pane>
			<el-tab-pane ref="ware" label="云仓商品库" name="1">
				<ware-goods v-if="status == 1" />
			</el-tab-pane>
			<el-tab-pane ref="recycle" label="商品回收站" name="2">
				<recycle-goods v-if="status == 2" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as api from "api/business/selfManager/goods"
import SettingGoods from "./vCouponPage/settingGoods.vue"
import WareGoods from "./vCouponPage/wareGoods.vue"
import recycleGoods from "./vCouponPage/recycleGoods.vue"
export default {
	components: {
		SettingGoods,
		WareGoods,
		recycleGoods
	},
	data() {
		return {
			status: "0"
		}
	},
	mounted() {
		if (this.$route.query.status !== undefined) {
			this.status = this.$route.query.status
		} else {
			this.status = "0"
		}
	},
	methods: {
		changeTabs(e) {
			this.$router.push(this.$route.path + "?status=" + e.name)
		}
	}
}
</script>

<style lang="scss" scoped>
</style>