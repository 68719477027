<template>
	<div class="mvk-layout-content-card">
		<div>
			<router-link :to="{ path: '/marketingManager/groupActivityAdd' }" class="add">
				<el-button size="small" type="primary" class="mr10">新增拼团活动</el-button>
			</router-link>
		</div>
		<el-tabs v-model="queryParam.status">
			<el-tab-pane v-for="item in statusList" :key="item.label" :label="item.label" :name="item.value" />
		</el-tabs>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<el-table-column label="操作" width="230" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button v-if="row.status == 0 || row.status == 1" type="text" size="small" @click="handleEdit(row.id)">
						编辑
					</el-button>
					<el-button type="text" size="small" @click="handleView(row.id)"> 查看 </el-button>
					<el-button v-if="row.status != 0" type="text" size="small" @click="handleViewOrder(row.id)">
						订单
					</el-button>
					<el-button v-if="row.status == 1" type="text" size="small" @click="handleStop(row.id)">
						暂停
					</el-button>
					<el-button v-if="row.status == 3" type="text" size="small" @click="handleOpen(row.id)">
						开启
					</el-button>
					<delete-button
						v-if="row.status == 0"
						:id="row.id"
						button-type="text"
						size="small"
						@delete="handleDelete"
					/>
				</template>
			</el-table-column>
		</mvk-table>
		<order-list ref="orderRef" />
	</div>
</template>

<script>
import { getGroupActivityList, deleteGroupActivity, stopGroupActivity } from "api/business/marketing/groupActivity"
export default {
	name: "GroupActivityList",
	components: {
		"order-list": () => import("./order.vue")
	},
	data() {
		return {
			statusList: [
				{
					value: "-1",
					label: "全部"
				},
				{
					value: "0",
					label: "未开始"
				},
				{
					value: "1",
					label: "进行中"
				},
				{
					value: "2",
					label: "已结束"
				},
				{
					value: "3",
					label: "已暂停"
				}
			],
			// 查询条件参数
			queryParam: {
				status: "-1"
			},

			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: "50"
				},
				{
					prop: "name",
					label: "团购名称",
					tooltip: true
				},
				{
					prop: "rangeDate",
					label: "团购时间段",
					formatter: value => {
						return value.startTime + "~" + value.endTime
					}
				},
				{
					prop: "status",
					label: "活动状态",
					formatter: value => {
						return this.statusList.filter(item => Number(item.value) === value.status)[0].label
					}
				}
			]
		}
	},
	computed: {
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))
			return parameter => {
				return getGroupActivityList(Object.assign(parameter, params)).then(res => {
					return res.data
				})
			}
		}
	},
	mounted() {},

	methods: {
		handleEdit(id) {
			this.$router.push("/marketingManager/groupActivityAdd/" + id)
		},
		// 暂停
		handleStop(id) {
			stopGroupActivity(id, { id: id }).then(res => {
				this.$notify.success({
					title: "暂停成功"
				})
				this.$refs.mvkTable.refresh()
			})
		},
		// 开启
		handleOpen(id) {
			stopGroupActivity(id, { id: id }).then(res => {
				this.$notify.success({
					title: "开启成功"
				})
				this.$refs.mvkTable.refresh()
			})
		},
		// 删除
		handleDelete(data) {
			deleteGroupActivity(data.id).then(() => {
				this.$notify.success({
					title: "删除成功"
				})
				this.$refs.mvkTable.refresh()
			})
		},

		handleView(id) {
			this.$router.push("/marketingManager/groupActivityAdd/" + id + "/detail")
		},

		handleViewOrder(id) {
			this.$refs.orderRef.open(id)
		}
	}
}
</script>

<style lang="scss" scoped></style>
