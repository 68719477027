import fetch from "@/axios/fetch"

export function getTopicList(params) { // 主题营销列表
	return fetch({
		url: "/special",
		method: "get",
		params
	})
}

export function changeTopicStatus(data) { // 修改主题营销状态
	return fetch({
		url: "/special/is-available",
		method: "put",
		data
	})
}

export function getTopicDetail(id) { // 获取主题营销详情
	return fetch({
		url: "/special/" + id,
		method: "get"
	})
}

export function addTopic(data) { // 新增主题营销
	return fetch({
		url: "/special/save",
		method: "post",
		data
	})
}

export function editTopic(data) { // 修改主题营销
	return fetch({
		url: "/special/update",
		method: "put",
		data
	})
}

export function deleteTopic(id) { // 删除主题营销
	return fetch({
		url: "/special/" + id,
		method: "delete"
	})
}
