<template>
	<div class="mvk-layout-content-card">
		<div v-has="'sys:config:add'" style="display: flex; justify-content: space-between; margin-bottom: 15px">
			<el-button type="primary" round @click="handleAdd"> 新增字典 </el-button>
		</div>

		<mvk-table ref="dictTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
			<template slot-scope="{ row }" slot="isValid" label="状态">
				<el-switch v-model="row.isValid" disabled> </el-switch>
			</template>

			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button v-has="'sys:config:edit'" type="text" size="small" @click="handleEdit(row)"
						>编辑</el-button
					>
					<!-- <delete-button
						:id="row.id"
						v-has="'sys:config:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
						>删除</delete-button
					> -->
				</template>
			</el-table-column>
		</mvk-table>

		<el-dialog :title="addOrEdit == 'add' ? '新增' : '修改'" :visible.sync="addDialog" width="30%">
			<add-dict-type
				v-if="addDialog"
				:dictInfo="currentDictInfo"
				:addOrEdit="addOrEdit"
				@success="addSuccess"
				@cancle="addDialog = false"
			/>
		</el-dialog>
	</div>
</template>

<script>
import { getSysDictionary, deleteDictType } from "api/basePage/dictionary"

export default {
	name: "DictionaryIndex",
	components: {
		"mvk-table": () => import("@/components/Table/index"),
		"add-dict-type": () => import("./addDictType")
	},
	directives: {},
	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "configKey",
					label: "名称"
				},
				{
					prop: "configValue",
					label: "值"
				},
				{
					prop: "remark",
					label: "备注"
				},
				{
					prop: "isValid",
					label: "是否启用",
					slot: true
				}
			],

			queryParam: {},
			tableDataFunc: (parameter) => {
				return getSysDictionary(Object.assign(parameter, this.queryParam)).then((res) => {
					return res.data
				})
			},

			addOrEdit: "add",
			addDialog: false,
			currentDictInfo: {
				id: "",
				configKey: "",
				configValue: "",
				remark: "",
				isValid: true
			},

			childDictDialog: false
		}
	},
	mounted() {},
	methods: {
		// 新增修改成功
		addSuccess() {
			this.addDialog = false
			this.$refs.dictTable.refresh()
		},

		// 点击新增
		handleAdd() {
			this.addDialog = true
			this.addOrEdit = "add"
			this.currentDictInfo = {
				id: "",
				configKey: "",
				configValue: "",
				remark: "",
				isValid: true
			}
		},

		// 点击修改
		handleEdit(row) {
			this.addDialog = true
			this.currentDictInfo = JSON.parse(JSON.stringify(row))
			this.addOrEdit = "edit"
		},

		// 点击删除
		handleDelete(data) {
			deleteDictType(data.id).then(() => {
				this.$notify.success({
					title: "成功",
					message: "删除成功"
				})

				this.$refs.dictTable.refresh()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.add-box {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
</style>
