<template>
	<div class="mvk-layout-content-card">
		<!-- 派发页面 -->
		<!-- 筛选 -->
		<el-form size="small" label-width="100px">
			<el-collapse-transition>
				<div v-show="showfilter">
					<div class="filter flex-row flex-wrap">
						<el-form-item label="派发任务名" class="width100">
							<el-input
								v-model="queryParam.distrName"
								placeholder="请输入派发任务名称"
								size="small"
								clearable
							/>
						</el-form-item>
						<el-form-item label="券名称" class="width100">
							<el-input v-model="queryParam.title" placeholder="请输入券名称" size="small" clearable />
						</el-form-item>
						<!-- <el-form-item label="电子券标识" class="width100">
							<el-input v-model="queryParam.code" placeholder="请输入电子券标识" size="small" clearable />
						</el-form-item>
						<el-form-item label="派发手机号" class="width100">
							<el-input v-model="queryParam.code" placeholder="请输入派发手机号" size="small" clearable />
						</el-form-item> -->
						<!-- <el-form-item label="电子券状态" class="width100">
							<el-select
								v-model="queryParam.validType"
								placeholder="电子券状态"
								clearable
								filterable
								style="width: 100%"
							>
								<el-option v-for="item in []" :key="item.id" :label="item.name" :value="item.id" />
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="券有效期" style="width:auto">
							<el-date-picker
								v-model="timeVal"
								:default-time="['00:00:00', '23:59:59']"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd"
								size="small"
								type="daterange"
								placement="bottom-end"
								placeholder="自定义时间"
								style="width: 220px"
								@change="onchangeTime"
							/>
						</el-form-item> -->

						<el-form-item label="券有效期：" style="width: auto">
							<div class="flex">
								<el-select
									v-model="queryParam.validType"
									placeholder="券有效期类型"
									clearable
									filterable
									style="min-width: 86px; width: 86px"
								>
									<el-option
										v-for="(val, key, i) in voucherValidType"
										:key="i"
										:label="val"
										:value="key"
									/>
								</el-select>
								<el-input
									v-if="queryParam.validType == 2"
									v-model="queryParam.validDay"
									class="ml10"
									placeholder="请输入有效天数"
									size="small"
									clearable
									@clear="clearValidDate"
								/>
								<el-date-picker
									v-else
									v-model="timeVal1"
									:default-time="['00:00:00', '23:59:59']"
									value-format="yyyy-MM-dd HH:mm:ss"
									format="yyyy-MM-dd"
									size="small"
									type="daterange"
									placement="bottom-end"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									:disabled="!queryParam.validType"
									class="ml10"
									style="width: 230px"
									@change="onchangeTime1"
								/>
							</div>
						</el-form-item>

						<!-- <el-form-item label="券使用时间" style="width:auto">
							<el-date-picker
								v-model="timeVal"
								:default-time="['00:00:00', '23:59:59']"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd"
								size="small"
								type="daterange"
								placement="bottom-end"
								placeholder="自定义时间"
								style="width: 220px"
								@change="onchangeTime"
							/>
						</el-form-item> -->
						<el-form-item label="派发时间" style="width: auto">
							<el-date-picker
								v-model="timeVal2"
								:default-time="['00:00:00', '23:59:59']"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd"
								size="small"
								type="daterange"
								placement="bottom-end"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								style="width: 220px"
								@change="onchangeTime2"
							/>
						</el-form-item>
						<div class="mt10 ml10 flex flex-a-e">
							<el-button icon="el-icon-refresh-right" @click="clearFilter()">重置</el-button>
						</div>
					</div>

					<!-- <el-button v-show="showfilter" class="mb20" type="primary" @click="switchFilter()">折叠筛选</el-button> -->
				</div>
			</el-collapse-transition>
			<div>
				<!-- <el-button class="mb20" type="primary" @click="switchFilter()">{{ !showfilter?'更多筛选':'折叠筛选' }}</el-button> -->
				<!-- <el-button class="mb20" type="primary" :disabled="selectList.length == 0" @click="openDelivers()">批量发货</el-button> -->
			</div>
		</el-form>
		<!-- 操作 -->
		<div class="mt20">
			<el-button type="primary" @click="distribute()">新建派发</el-button>
		</div>
		<!-- 内容 -->
		<mvk-table ref="mvkTable" :table-column="tableColumn" size="mini" class="mt20" :table-data-func="tableDataFunc">
			<template slot="fileUri" slot-scope="{ row }">
				<el-link :href="row.fileUri" type="primary">下载</el-link>
			</template>
		</mvk-table>
		<!-- 抽屉 -->
		<distributeDrawer ref="modalForm" @saveSuccess="success" />
	</div>
</template>

<script>
import { payType, orderType, dateTimeList, voucherTypeList, voucherValidType } from "@/utils/constants"
import distributeDrawer from "./distributeDrawer.vue"
import { couponDistrList } from "api/business/marketing/coupon"
import { debounce } from "utils/utils"

export default {
	components: { distributeDrawer },
	data() {
		return {
			voucherValidType,
			showfilter: true,
			dateList: dateTimeList,
			payTypeList: payType,
			orderTypeList: orderType,
			timeVal1: [],
			timeVal2: [],
			// 查询条件参数
			queryParam: {
				distrName: "",
				title: "",
				validType: "",
				validDay: "",
				validStart: "",
				validEnd: "",
				distributeStart: "",
				distributeEnd: ""
			},

			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 76
				},
				{
					prop: "distrName",
					label: "派发任务名称",
					minWidth: 100
				},
				{
					prop: "title",
					label: "券名称",
					width: 92
				},
				{
					prop: "couCategory",
					label: "券类型",
					width: 76,
					formatter: row => {
						return voucherTypeList[row.couCategory]
					}
				},
				{
					prop: "bizTypeName",
					label: "券使用类型",
					width: 86,
					align: "center"
				},
				{
					prop: "faceValue",
					label: "券面值金额",
					width: 92,
					align: "center",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.faceValue)
					}
				},
				{
					prop: "couDiscountValue",
					label: "折扣率",
					width: 86,
					align: "center"
				},
				{
					prop: "couDiscountMaxValue",
					label: "最大折扣金额",
					width: 92,
					align: "center",
					formatter: cellValue => {
						return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.couDiscountMaxValue)
					}
				},
				// {
				// 	prop: "businessId",
				// 	label: "派发手机号",
				// 	width: "110"
				// },

				{
					prop: "num",
					label: "发放张数",
					width: 86,
					align: "center"
				},
				{
					prop: "createAt",
					label: "派发时间",
					formatter: cellValue => {
						return cellValue.createAt && cellValue.createAt.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
					}
				},
				{
					prop: "validStart",
					label: "劵可用时间"
				},
				{
					prop: "validEnd",
					label: "劵结束时间"
				},
				{
					prop: "fileUri",
					label: "派发附件",
					slot: true
				}
			],

			// eslint-disable-next-line no-unused-vars
			tableDataFunc: parameter => {
				return couponDistrList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
				// return new Promise(resolve => {
				// 	return resolve()
				// })
			}
		}
	},
	computed: {
		watchQueryParam: function() {
			return JSON.stringify(this.queryParam)
		}
	},
	watch: {
		watchQueryParam: {
			// 增加防抖
			handler: debounce(function(newVal) {
				const newParams = JSON.parse(newVal)
				// console.log(newParams)
				if (!newParams.validType) {
					this.$refs.mvkTable.refresh()
				} else if (newParams.validType == "1" && newParams.startDate) {
					this.$refs.mvkTable.refresh()
				} else if (newParams.validType == "2" && newParams.day) {
					this.$refs.mvkTable.refresh()
				}
			}, 300),
			deep: true
		}
	},
	methods: {
		clearFilter() {
			Object.keys(this.queryParam).forEach(key => (this.queryParam[key] = ""))
		},
		clearValidDate() {
			this.queryParam.validDay = ""
			this.queryParam.validStart = ""
			this.queryParam.validEnd = ""
		},
		// 有效期
		onchangeTime1(e) {
			this.timeVal = e
			this.queryParam.validStart = e ? e[0] : ""
			this.queryParam.validEnd = e ? e[1] : ""
		},
		// 派发时间
		onchangeTime2(e) {
			this.timeVal = e
			this.queryParam.distributeStart = e ? e[0] : ""
			this.queryParam.distributeEnd = e ? e[1] : ""
		},
		distribute() {
			this.$refs.modalForm.show()
		},
		success() {
			this.$refs.mvkTable.refresh()
		}
	}
}
</script>

<style lang="scss" scoped>
.el-form-item--small.el-form-item {
	margin-top: 18px;
	margin-bottom: 0;
}
.filter .el-form-item {
	width: 300px;
	margin-right: 10px;
}
</style>
