import fetch from "@/axios/fetch"

export function getAdvertSetting () { // 查找广告设置
	return fetch({
		url: "/advert/setting",
		method: "get",
	})
}

export function saveAdvertSetting (data) { // 保存广告设置
	return fetch({
		url: "/advert/save",
		method: "post",
		data
	})
}
