<template>
	<el-drawer title="发票信息" :visible.sync="visible" size="400px" :append-to-body="true">
		<div class="drawer-content">
			<el-descriptions class="margin-top" :column="1" :colon="true" border>
				<el-descriptions-item>
					<template slot="label"> 发票类型 </template>
					{{ detailData.fplx | TicketType }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label"> 抬头类型 </template>
					{{ detailData.headerType == 1 ? "个人" : "企业" }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label"> 发票抬头 </template>
					{{ detailData.header }}
				</el-descriptions-item>
				<template v-if="detailData.headerType === 2">
					<el-descriptions-item>
						<template slot="label"> 企业税号 </template>
						{{ detailData.dwsh }}
					</el-descriptions-item>
					<el-descriptions-item>
						<template slot="label"> 开户银行 </template>
						{{ detailData.bank }}
					</el-descriptions-item>
					<el-descriptions-item>
						<template slot="label"> 银行账户 </template>
						{{ detailData.bankAccount }}
					</el-descriptions-item>
					<el-descriptions-item>
						<template slot="label"> 企业地址 </template>
						{{ detailData.address }}
					</el-descriptions-item>
					<el-descriptions-item>
						<template slot="label"> 企业电话 </template>
						{{ detailData.phone }}
					</el-descriptions-item>
				</template>
			</el-descriptions>
		</div>
	</el-drawer>
</template>

<script>
import { ticketDetail } from "api/business/financeManage/all"
import { ticketType } from "@/utils/constants"
export default {
	name: "InvoicesDetail",
	data() {
		return {
			visible: false,
			type: 1,
			detailData: {}
		}
	},
	filters: {
		TicketType(type) {
			return ticketType[type]
		}
	},
	methods: {
		open(id) {
			this.visible = true

			ticketDetail({ id: id }).then(res => {
				this.detailData = res.data
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
