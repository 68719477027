<template>
	<el-drawer :title="title" :visible="value" size="640px" @close="closeDrawer">
		<div v-if="show">
			<!-- 1 虚拟商品 -->
			<mvk-table
				v-if="type==1"
				ref="mvkTable1"
				:table-column="tableColumn1"
				:table-data-func="tableDataFunc1"
				header-cell-class-name="custom-table1"
				class="mt15"
			>
				<el-table-column slot="head" type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.productSkuPageVos" border stripe>
							<el-table-column v-for="col in fixedColumn" :key="col.prop" :formatter="col.formatter" :prop="col.prop" :width="col.width" :label="col.label">
								<template slot-scope="{ row }">
									<div v-if="col.prop == 'skuName'" class="flex-row">
										<el-image
											style="min-width: 30px; height: 30px; width: 30px"
											:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
											:preview-src-list="[row.pic]"
										/>
										<div style="margin-left: 2px">{{ row.skuName }}</div>
									</div>
									<span v-else>
										{{ col.suffix && row[col.prop] ?row[col.prop] + col.suffix:row[col.prop] }}
									</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="80">
								<template slot-scope="{ row }">
									<el-button type="primary" @click="selectPrize(row,props.row)">
										选择
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<template slot="shelfStatus" slot-scope="{ row }">
					<div class="incheck" :class="{'uncheck':!row.shelfStatus}">
						{{ row.shelfStatus ? '已上架': '未上架' }}
					</div>
				</template>
				<template slot="prodName" slot-scope="{ row }">
					<div class="flex-row">
						<el-image
							style="min-width: 30px; height: 30px; width: 30px"
							:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
							:preview-src-list="[row.pic]"
						/>
						<div style="margin-left: 4px">{{ row.prodName }}</div>
					</div>
				</template>
			</mvk-table>
			<!-- 2 指定商品优惠券 -->

			<mvk-table v-if="type==2" ref="mvkTable2" :table-column="tableColumn2" :table-data-func="tableDataFunc2" class="mt20">
				<template slot="pushNum" slot-scope="{ row }">
					<span v-if="!row.isLimit">不限量</span>
					<div v-else>
						<span class="fa">发布：{{ row.pushNum }}</span>
						<span class="sheng">剩余：{{ row.surplusNum == null ? row.pushNum : row.surplusNum }}</span>
					</div>
				</template>
				<el-table-column label="操作" width="80">
					<template slot-scope="{ row }">
						<el-button type="primary" @click="selectPrize(row)">
							选择
						</el-button>
					</template>
				</el-table-column>
			</mvk-table>
			<!--3 通用优惠券 -->
			<mvk-table v-if="type==3" ref="mvkTable2" :table-column="tableColumn2" :table-data-func="tableDataFunc3" class="mt20">
				<template slot="pushNum" slot-scope="{ row }">
					<span v-if="!row.isLimit">不限量</span>
					<div v-else>
						<span class="fa">发布：{{ row.pushNum }}</span>
						<span class="sheng">剩余：{{ row.surplusNum == null ? row.pushNum : row.surplusNum }}</span>
					</div>
				</template>
				<el-table-column label="操作" width="80">
					<template slot-scope="{ row }">
						<el-button type="primary" @click="selectPrize(row)">
							选择
						</el-button>
					</template>
				</el-table-column>
			</mvk-table>
			<!-- 4 电子券 -->
			<mvk-table v-if="type==4" ref="mvkTable4" :table-column="tableColumn4" :table-data-func="tableDataFunc4" class="mt20">
				<template slot="pushNum" slot-scope="{ row }">
					<span v-if="!row.isLimit">不限量</span>
					<div v-else>
						<span class="fa">发布：{{ row.pushNum }}</span>
						<span class="sheng">剩余：{{ row.surplusNum == null ? row.pushNum : row.surplusNum }}</span>
					</div>
				</template>
				<el-table-column label="操作" width="76" align="center">
					<template slot-scope="{ row }">
						<el-button type="primary" @click="selectPrize(row)">
							选择
						</el-button>
					</template>
				</el-table-column>
			</mvk-table>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<!-- <el-button v-throttle="submitForm" type="primary" icon="el-icon-check" :loading="loading">
					立即创建
				</el-button> -->
				<el-button type="text" @click="closeDrawer">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { couponList, queryEcouponTypeList } from "api/business/marketing/coupon"
import { voucherTypeList } from "@/utils/constants"
import { virtualSearchList } from "api/business/selfManager/goods.js"

export default {
	model: {
		prop: "value",
		event: "changeShow"
	},
	props: {
		value: {
			type: Boolean,
			default: false
		},
		id: {
			type: [Number, null],
			default: null
		},
		type: {
			type: [Number, null],
			default: null
		}
	},
	watch: {
		value(v) {
			setTimeout(() => {
				this.show = v
			}, 10)
		}
	},
	data() {
		return {
			title: "设置奖品",
			show: false,
			currentPrizeType: null,
			tableColumn1: [
				{ prop: "id", label: "商品ID", width: "80" },
				{ prop: "prodName", label: "商品名称", slot: true, minWidth: 140 },
				{ prop: "categoryName", label: "商品分类", width: "80", align: "center" }
			],
			fixedColumn: [
				{ prop: "teteProductId", label: "商品代码", width: 78 },
				{ prop: "skuName", label: "SKU名称", minWidth: 120 },
				{ prop: "faceValue", label: "商品面值", width: 76, suffix: "元" },
				{ prop: "costPrice", label: "成本价", width: 60, suffix: "元" },
				{ prop: "marketPrice", label: "市场价", width: 60, suffix: "元" }
			],
			tableColumn2: [
				{
					prop: "id",
					label: "ID",
					width: 80
				},

				{
					prop: "name",
					label: "名称"
				},
				{
					prop: "faceValue",
					label: "面值",
					width: 80,
					align: "center"
				},
				{
					prop: "types",
					label: "类型",
					width: 80,
					formatter: cellValue => {
						if (cellValue.types == 10) {
							return "通用券"
						} else if (cellValue.types == 20) {
							return "商品券"
						} else if (cellValue.types == 30) {
							return "品类券"
						} else if (cellValue.types == 40) {
							return "大客户券"
						} else {
							return cellValue.types
						}
					}
				},
				{
					prop: "declares",
					label: "说明",
					width: 60
				}
			],
			tableColumn4: [

				{
					prop: "id",
					label: "ID",
					width: 55
				},
				{
					prop: "title",
					label: "劵名称"
				},
				{
					prop: "couCategory",
					label: "券类型",
					width: 76,
					formatter: row => {
						return voucherTypeList[row.couCategory]
					}
				},
				{
					prop: "bizTypeName",
					label: "使用类型",
					width: 86,
					align: "center"
				},
				{
					prop: "faceValue",
					label: "面值金额",
					width: 76
				},
				{
					prop: "cnt",
					label: "库存数量",
					width: 76,
					align: "center"
				},
				{
					prop: "validDate",
					label: "劵有效期",
					// width: 170,
					formatter: cellValue => {
						if (cellValue.validType == 1) {
							// 固定
							return cellValue.validDate.replace("#", " - ")
						} else if (cellValue.validType == 2) {
							// 动态
							return cellValue.validDate + "天"
						}
					}
				}
			],
			tableDataFunc1: parameter => {
				return virtualSearchList(Object.assign(parameter, { installed: true })).then(res => {
					return res.data
				})
			},
			tableDataFunc2: parameter => {
				return couponList(Object.assign(parameter, { types: 20, isLottery: true })).then(res => {
					return res.data
				})
			},
			tableDataFunc3: parameter => {
				return couponList(Object.assign(parameter, { types: 10, isLottery: true })).then(res => {
					return res.data
				})
			},
			tableDataFunc4: parameter => {
				return queryEcouponTypeList(Object.assign(parameter, {})).then(res => {
					return res.data
				})
			},

			currentTarget: null
		}
	},

	methods: {
		getCoupon() {
			// couponList
		},
		closeDrawer() {
			this.$emit("changeShow", false)
		},
		selectPrize(row, parent) {
			// 应对sku专用
			if (parent) {
				row.parentProductName = parent.prodName
			}
			console.log(row)
			this.$emit("submit", row)
			this.closeDrawer()
		},
		submitForm() {
			console.log("保存")
			// this.loading = true
			// api.editLottery(this.params).then(res => {
			// 	if (res.status == "OK") {
			// 		this.$message.success("修改成功")
			// 	}
			// }).finally(() => {
			// 	this.loading = false
			// })

		}
	}
}
</script>

<style lang="scss" scoped>
.box{
font-size: 12px;
}
</style>
