<template>
	<el-drawer title="商品下架" :visible.sync="offDrawer" :destroy-on-close="true" size="50%">
		<div style="height: calc(100% - 80px); overflow-y: auto">
			<el-card
				v-for="(shop, index) in shopList"
				:key="index"
				shadow="always"
				:body-style="{ padding: '20px' }"
				class="mt15 custom-card"
			>
				<div slot="header">店铺名称：{{ shop.shopVO.name }}</div>
				<el-row :gutter="20">
					<el-col :span="8"> 店长:{{ shop.shopVO.shopManagerName }} </el-col>
					<el-col :span="8"> 联系电话:{{ shop.shopVO.shopManagerMobile }} </el-col>
					<el-col :span="8"> 地址:{{ shop.shopVO.address }} </el-col>
				</el-row>

				<el-table :data="shop.skus" border stripe class="mt15">
					<!-- <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column> -->
					<el-table-column width="55">
						<template slot-scope="scope">
							<el-checkbox v-model="shop.checkedSku" :label="scope.row.shopSkuId"><span /></el-checkbox>
						</template>
					</el-table-column>

					<el-table-column v-for="col in tableColumn" :key="col.prop" :prop="col.prop" :label="col.label" />
				</el-table>
			</el-card>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button type="text" @click="offDrawer = false">取消</el-button>
				<div>
					<el-button type="primary" @click="handleGoodsOff">下架</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
/**
 * 商品下架
 */
import { shelfProdInfo, delProd } from "api/business/selfManager/goods.js"

export default {
	name: "GoodsOff",
	data() {
		return {
			offDrawer: false,
			activeNames: "0",
			shopList: [],

			tableColumn: [
				{ label: "SKU编码", prop: "skuName" },
				{ label: "上架数量", prop: "num" },
				{ label: "库存", prop: "actualStocks" }
			],

			checkedSkus: [],

			repeatFlag: false,

			goodsId: ""
		}
	},
	watch: {},
	mounted() {},

	methods: {
		open(id) {
			this.offDrawer = true
			this.goodsId = id

			this.getDetailInfo()
		},

		getDetailInfo() {
			var vm = this
			shelfProdInfo(this.goodsId).then(res => {
				this.shopList = res.data

				this.shopList.forEach(item => {
					vm.$set(item, "checkedSku", [])
				})
			})
		},

		handleGoodsOff() {
			let params = this.shopList.map(item => item.checkedSku).flat()

			// console.log(params);
			if (params.length == 0) {
				this.$notify.warning({
					title: "提示",
					message: "请先选择商品规格"
				})

				return
			}

			delProd({
				shopSkuIds: params
			}).then(() => {
				this.$notify.success({
					title: "成功",
					message: "下架成功"
				})

				this.offDrawer = false

				this.$emit("OffSuccess")
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
