<template>
	<el-dialog :title="title" :visible.sync="addEditDialog" :close-on-click-modal="false" width="800px" top="60px">
		<el-form ref="categoryForm" :model="categoryForm" :rules="categoryRules" label-width="180px">
			<el-form-item prop="name" label="分类名称：">
				<!-- <el-input placeholder="分类名称" v-model.trim="categoryForm.name" class="inputWidth">
				</el-input> -->
				<mvk-input v-model.trim="categoryForm.name" placeholder="分类名称" class="inputWidth" />
			</el-form-item>
			<el-form-item prop="parentId" label="父级：">
				<!-- <el-select v-model="categoryForm.parentId" :disabled="categoryForm.parentId == null" clearable>
                    <el-option v-for="item in categoryList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>  -->
				<el-cascader
					v-model="categoryForm.parentIdArr"
					:options="categoryList"
					:props="props2"
					clearable
					class="inputWidth"
					:disabled="categoryForm.parentId == null"
				/>
			</el-form-item>
			<el-form-item prop="image" label="分类图标：">
				<upload-image ref="imageModel" v-model="categoryForm.image" :image-url="categoryForm.image" />
			</el-form-item>
			<el-form-item prop="orderNum" label="排序：">
				<el-input-number v-model="categoryForm.orderNum" :min="0" />
			</el-form-item>
			<el-form-item prop="status" label="状态：">
				<el-switch v-model="categoryForm.status" :active-value="1" :inactive-value="0" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" class="form-submit" v-throttle="addSubmit">保存</el-button>
				<el-button @click="addEditDialog = false">关闭</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import UploadImage from "views/bussiness/components/UploadImage"
import { addProCategory, editProCategory } from "api/business/selfManager/category.js"

export default {
	name: "AddEditModal",
	components: {
		UploadImage
	},
	data() {
		return {
			title: "新增",
			addEditDialog: false,
			categoryForm: {
				id: "",
				parentId: "",
				parentIdArr: [],
				name: "",
				image: "",
				level: 1,
				orderNum: "",
				status: 1
			},
			categoryRules: {
				name: [
					{
						required: true,
						message: "请输入分类名称",
						trigger: "blur"
					}
				],
				image: [
					{
						required: true,
						message: "请上传图片"
					}
				]
			},

			categoryList: [],

			props2: {
				children: "child",
				label: "name",
				value: "id",
				multiple: false,
				// emitPath: false,
				checkStrictly: true
			}
		}
	},
	computed: {},
	methods: {
		add() {
			this.addEditDialog = true
			this.categoryList = JSON.parse(sessionStorage.getItem("eshop-categoryList"))
			this.$nextTick(() => {
				this.categoryForm = {
					id: "",
					parentId: "",
					parentIdArr: [],
					name: "",
					image: "",
					level: 1,
					orderNum: "",
					status: 1
				}
			})
		},
		edit(record) {
			var vm = this
			this.addEditDialog = true
			this.categoryList = JSON.parse(sessionStorage.getItem("eshop-categoryList"))
			this.$nextTick(() => {
				this.categoryForm = JSON.parse(JSON.stringify(record))
				if (!this.categoryForm.parentIdArr) {
					let temp = this.categoryForm.path && this.categoryForm.path.split("/")
					let tempArr = []
					temp &&
						temp.forEach((item) => {
							if (item) {
								tempArr.push(Number(item))
							}
						})
					vm.$set(this.categoryForm, "parentIdArr", tempArr)
				}
			})
		},

		addSubmit() {
			this.$refs["categoryForm"].validate((valid) => {
				if (valid) {
					this.categoryForm.level = this.categoryForm.parentIdArr.length + 1
					this.categoryForm.parentId =
						this.categoryForm.parentIdArr[this.categoryForm.parentIdArr.length - 1] || ""

					if (this.categoryForm.id) {
						editProCategory(this.categoryForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "修改失败"
								})
							})
					} else {
						// 新增
						// this.categoryForm.level = this.categoryForm.parentId == '' ? 1:this.categoryForm.parentIdArr.length + 1
						// console.log(this.categoryForm.level);
						addProCategory(this.categoryForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "添加成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "添加失败"
								})
							})
					}
				} else {
					return false
				}
			})
		},

		handleInputClick() {
			this.$refs.uploadImg.value = null
			this.$refs.uploadImg.click()
		}
	},
	mounted() {}
}
</script>

<style lang="scss" scoped></style>
