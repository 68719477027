<template>
	<el-drawer title="追加商品" :visible.sync="visible" :append-to-body="true" size="720px" :before-close="close">
		<div class="" style="padding-bottom: 50px">
			<div v-if="dataRow" style="margin-bottom: 10px">{{ dataRow.prodName }}</div>
			<mvk-table ref="mvkTable" :table-column="column" :initData="dataList" no-page>
				<template slot="pic" slot-scope="{ row }">
					<el-image
						style="width: 45px; height: 45px"
						:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/45x'"
						:preview-src-list="[row.pic]"
					/>
				</template>
				<template slot="adds" slot-scope="scope">
					<el-input-number
						:value="scope.row.adds"
						size="small"
						type="number"
						:min="0"
						:max="Infinity"
						:step="1"
						:controls="true"
						:readOnly="false"
						@change="change($event, scope.$index)"
					/>
				</template>
			</mvk-table>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button type="primary" @click="handleSubmit">确定</el-button>
			</div>
		</div>
	</el-drawer>
</template>
<script>
import { LaborShopAddOnShelf } from '@/api/business/selfManager/labour'
export default {
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            visible: false,
            column: [
                {
                    prop: "id",
                    label: "SkuId"
                },
                {
                    prop: "skuName",
                    label: "商品名称"
                },
                {
                    prop: "pic",
                    label: "商品图片",
                    align: "center",
                    width: 70,
                    slot: true
                },
                {
                    prop: "adds",
                    label: "追加数量",
                    slot: true
                }
            ],
            dataRow: null,
            dataList: []
        }
    },
    watch: {
        visible: {
            handler(newVal) {
                if (newVal) {
                    this.dataRow = this.data
                    const tempData = JSON.parse(
                        JSON.stringify(
                            this.dataRow.skus.map((e) => {
                                e.adds = 1
                                return e
                            })
                        )
                    )
                    tempData.forEach((e) => {
                        this.dataList.push(e)
                    })
                    console.log('遍历赋值', this.dataRow.id);
                }
            },
            deep: true
        }
    },
    methods: {
        close() {
            this.dataList.splice(0, this.dataList.length)
            this.visible = false
        },

        change(currentValue, index) {
            this.dataList[index].adds = currentValue
        },
        handleSubmit() {
            const params = {
                addOnItems: this.dataList.map(e => {
                    return {
                        adds: e.adds,
                        shopId: this.dataRow.shopId,
                        shopSkuId: e.id
                    }
                })
            }
            LaborShopAddOnShelf(params).then(res => {
                this.$emit('submit')
                this.close()
            })
        }
    }
}
</script>
<style lang="scss" scoped></style>
