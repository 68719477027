<template>
    <div class="mvk-layout-content-card">
        <div class="add-btn">
            <el-button v-has="'pms:productProd:add'" type="primary" round size="small" @click="handleAdd">
                新增商品规格
            </el-button>
        </div>

        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
            <!--自定义内容-->
            <template slot="values" slot-scope="{ row }">
                <el-tag v-for="(item, index) in row.values" :key="item.id" :type="tagsType[index]">
                    {{
                        item.name
                    }}
                </el-tag>
            </template>
            <!--自定义操作-->
            <el-table-column label="操作">
                <template slot-scope="{ row }">
                    <el-button v-has="'pms:productProd:edit'" type="text" size="small" @click="handleEdit(row)">
                        编辑
                    </el-button>
                    <delete-button
                        :id="row.id"
                        v-has="'pms:productProd:delete'"
                        button-type="text"
                        size="small"
                        @delete="handleDelete"
                    >
                        删除
                    </delete-button>
                </template>
            </el-table-column>
        </mvk-table>

        <add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
    </div>
</template>

<script>
import { prodList, deleteProd } from "api/business/selfManager/prod.js"
import { listMixin } from "views/mixin/listMixin"

export default {
    mixins: [listMixin],
    components: {
        "add-edit-dialog": () => import("./addEdit.vue")
    },
    data() {
        return {
            tableColumn: [
                {
                    prop: "name",
                    label: "规格名称"
                },
                {
                    prop: "values",
                    label: "商品属性",
                    slot: true
                }
            ],

            tableDataFunc: parameter => {
                return prodList(parameter).then(res => {
                    return res.data
                })
            },

            tagsType: ["", "success", "info", "warning", "danger"]
        }
    },
    mounted() {},
    methods: {
        handleDelete(data) {
            deleteProd(data.id)
                .then(() => {
                    this.$notify.success({
                        title: "成功",
                        message: "删除成功"
                    })
                    this.$refs.mvkTable.refresh()
                })
                .catch(() => {
                    this.$notify.error({
                        title: "错误",
                        message: "删除失败"
                    })
                })
        },

        handleEdit(record) {
            // record.values = record.values.map(item => item.name)
            this.$set(
                record,
                "valueName",
                record.values.map(item => item.name)
            )
            this.$refs.modalForm.edit(record)
            this.$refs.modalForm.title = "编辑"
        }
    }
}
</script>

<style scoped lang="scss">
.selWidth {
	width: 350px !important;
}
.seachTiele {
	line-height: 35px;
}
.fr {
	float: right;
}
</style>
