<template>
	<!-- 仓库中商品 -->
	<div>
		<div class="flex-row" style="justify-content: space-between">
			<!-- <router-link :to=" { path:'/selfManager/creatProduct' } " v-has="'pms:product:add'">
				<el-button size="small" round type="primary" class="mr10">添加商品</el-button>
			</router-link> -->
			<el-form size="small" label-width="100px" :inline="true">
				<!-- <el-form-item label="所属供应商">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
						<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item> -->
				<el-form-item label="商品代码">
					<el-input v-model="queryParam.teteProductId" placeholder="可输入商品代码搜索" clearable />
				</el-form-item>
				<el-form-item label="商品名称">
					<el-input v-model="queryParam.prodName" placeholder="输入商品名称搜索" clearable />
				</el-form-item>
				<el-form-item label="商品分类">
					<prod-category-tree v-model="queryParam.categoryId" check-strictly />
				</el-form-item>
				<el-form-item label="产品类型">
					<el-select v-model="queryParam.virtualType" clearable placeholder="可选择产品类型搜索">
						<el-option
							v-for="(item, index) in Object.values(virtualType)"
							:key="index"
							:value="item.value"
							:label="item.label"
						/>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="税率商品">
					<el-select v-model="queryParam.isTaxRate" placeholder="请选择">
						<el-option label="全部" :value="null" />
						<el-option label="已录入" :value="true" />
						<el-option label="未录入" :value="false" />
					</el-select>
				</el-form-item> -->
			</el-form>
		</div>

		<el-button
			type="primary"
			:disabled="tableCheck.length == 0"
			round
			@click="handleBatchGoodsOn(true)"
		>
			批量上架生活服务
		</el-button> 
		<!-- <el-button
			v-has="'pms:product:batch:move'"
			type="primary"
			:disabled="tableCheck.length == 0"
			round
			@click="handleBatchMove"
		>
			批量移至站级商品池
		</el-button>
		<el-button
			v-has="'pms:product:shelf'"
			type="success"
			plain
			:disabled="tableCheck.length == 0"
			round
			@click="handleBatchGoodsOn('自营店')"
		>
			批量上架自营店
		</el-button> -->
		<!-- <el-button
			v-has="'pms:product:shelf'"
			type="warning"
			plain
			:disabled="tableCheck.length == 0"
			round
			@click="handleBatchGoodsOn('内购店')"
		>
			批量上架内购店
		</el-button> -->
		<el-button type="success" icon="el-icon-refresh" :loading="goodSyncing" circle @click="goodSync(true)" />

		<!-- <el-checkbox v-model="queryParam.isTaxRate" class="ml20">已录税率商品</el-checkbox> -->
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			header-cell-class-name="custom-table1"
			class="mt15"
			@selection-change="handleSelectionChange"
		>
			<el-table-column slot="head" type="selection" :selectable="selectInit" width="38" />
			<el-table-column slot="head" type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.productSkuPageVos" border stripe>
						<el-table-column v-for="col in fixedColumn" :key="col.prop" :prop="col.prop" :label="col.label">
							<template slot-scope="{ row }">
								<div v-if="col.prop == 'skuName'" class="flex-row">
									<el-image
										style="min-width: 30px; height: 30px; width: 30px"
										:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
										:preview-src-list="[row.pic]"
									/>
									<div style="margin-left: 2px">{{ row.prodName }}</div>
								</div>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<template slot="shelfStatus" slot-scope="{ row }">
				<div class="incheck" :class="{'uncheck':!row.shelfStatus}">
					{{ row.shelfStatus ? '已上架': '未上架' }}
				</div>
			</template>
			<template slot="prodName" slot-scope="{ row }">
				<div class="flex-row">
					<el-image
						style="min-width: 30px; height: 30px; width: 30px"
						:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
						:preview-src-list="[row.pic]"
					/>
					<div style="margin-left: 4px">{{ row.prodName }}</div>
				</div>
			</template>
			<el-table-column label="操作" width="160" align="right" header-align="center">
				<template slot-scope="{ row }">
					<el-button v-if="row.shelfStatus" type="text" size="small" style="margin-right:10px;" @click="handleBatchGoodsOn(false,row)">
						下架
					</el-button>
					<router-link
						v-has="'pms:product:detail'"
						:to="{ path: '/livePay/creatProduct/' + row.id + '/true/false' }"
						class="link"
					>
						<el-button type="text" size="small">详情</el-button>
					</router-link>
					<el-button v-has="'pms:product:edit'" type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
					<delete-button
						v-if="!row.shelfStatus"
						:id="row.id"
						v-has="'pms:product:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					/>
				</template>
			</el-table-column>
		</mvk-table>
		<!--上架-->
		<!-- <goods-on ref="goodsOnRef" @OnSuccess="searchList" /> -->
		<!--定价-->
		<price-setting ref="pricesettingRef" @saveSuccess="handleRefresh" />
	</div>
</template>

<script>
import * as api from "api/business/selfManager/goods.js"

// import GoodsOn from "./goodsOn.vue"
import PriceSetting from "./priceSetting"

import { commonMixin } from "./mixin"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import { virtualType } from "@/utils/constants"

// 仓库中的商品
export default {
	name: "WareGoods",
	components: {
		// GoodsOn,
		PriceSetting,
		ProdCategoryTree
	},
	mixins: [commonMixin], // 搜索条件中的店铺以及供应商索引
	data() {
		return {
			virtualType,
			tableColumn: [
				{ prop: "id", label: "商品ID", width: "80" },
				// { prop: "pic", label: "商品图" },
				{ prop: "prodName", label: "商品名称", slot: true, minWidth: 140 },
				{ prop: "categoryName", label: "商品分类", width: "80", align: "center" },
				{ prop: "virtualType", label: "产品类型", width: "80", align: "center", formatter: row => { return  virtualType.find(e => e.value == row.virtualType)?.label } },
				{
					label: "商品税率",
					width: "76",
					formatter: row => {
						if (row.taxRate) {
							return row.taxRate * 100 + "%"
						} else {
							return "--"
						}
					}
				},
				{ prop: "shelfStatus", label: "是否上架", width: "80", slot: true }

			],

			queryParam: {
				teteProductId: "",
				supplierId: "",
				prodName: "",
				categoryId: "",
				installed: true
			},

			tableDataFunc: parameter => {
				return api.virtualSearchList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			fixedColumn: [
				{ prop: "teteProductId", label: "商品代码" },
				{ prop: "skuName", label: "SKU名称", minWidth: 160 },
				{ prop: "faceValue", label: "商品面值(元)" },
				{ prop: "costPrice", label: "成本价(元)" },
				{ prop: "marketPrice", label: "市场价(元)" },
				{ prop: "internalPrice", label: "内购价(元)" }
			],

			tableCheck: [], // 选中的商品
			goodSyncing: false,
			virtualGoodsSync: sessionStorage.getItem("virtualGoodsSync") // sessionStore
		}
	},
	watch: {},
	computed: {},
	created() {
		if (!this.virtualGoodsSync) { 
			this.goodSync()
		}
	},
	methods: {
		goodSync(notify) {
			this.goodSyncing = true
			api.virtualSync().then(() => {
				if (notify) { 
					this.$notify.success({
						title: "同步完毕！"
					})
				}
				sessionStorage.setItem("virtualGoodsSync", true)
				this.$refs.mvkTable.refresh()
			}).finally(() => { this.goodSyncing = false })
		},
		selectInit(row) { 
			return !row.shelfStatus
		},
		handleDelete(data) {
			api.deleteVirtualGoods(data.id)
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "删除成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "删除失败"
					})
				})
		},

		handleEdit(row) {
			this.$router.push({ path: "/livePay/creatProduct/" + row.id + "/" })
		},

		handleGoodsOn(id, row) {
			this.$refs.goodsOnRef.open(id, row)
		},

		// 待定价
		handlePriceSetting(row) {
			this.$refs.pricesettingRef.open([row])
		},

		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},
		// 是否可以上架
		isDisableOn(row) {
			if (row.source === 1) {
				return !row.installed
			} else {
				return false
			}
		},

		handleSelectionChange(val) {
			this.tableCheck = val
		},

		// 批量移至站级商品池
		// handleBatchMove() {
		// 	batchMoveToStationPool(this.tableCheck.map(item => item.id)).then(() => {
		// 		this.$notify.success({
		// 			title: "添加成功"
		// 		})
		// 	})
		// },

		// 上架下架 row非空则为单选
		handleBatchGoodsOn(shelfStatus, row) {
			let params = { shelfStatus }
			if (row) {
				params.prodIds = [row.id]
			} else { 
				params.prodIds = this.tableCheck.map(item => item.id)
			}
			api.virtualShelves(params).then(() => {
				this.$notify.success({
					title: "添加成功"
				})
				this.handleRefresh()
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.link + .link {
	margin-left: 8px;
}

// .el-form-item--small.el-form-item {
// 	margin-bottom: 0;
// }
.source {
	display: inline-block;
	margin-right: 4px;
	line-height: 1;
	border: 1px solid #4f8aff;
	color: #4f8aff;
	padding: 4px;
	border-radius: 50px;
	font-size: 12px;
}
.source0 {
	color: #80b568;
	border-color: #80b568;
}
.incheck{
	padding: 0 8px;
	color:#4B5EFF;
	border: 1px solid #4B5EFF;
	border-radius: 20px;
	text-align: center;
}
.uncheck{
	color:rgb(224, 145, 0);
	border-color: rgb(224, 145, 0);
}
</style>
