<template>
	<!-- 白名单模态框 -->
	<el-dialog
		title="白名单"
		:visible="visible"
		width="90%"
		top="5vh"
		destroy-on-close
		:close-on-click-modal="false"
		@close="whiteListClose()"
	>
		<div class="mb10 flex">
			<el-radio-group v-model="params.state">
				<el-radio-button :label="0">全部</el-radio-button>
				<el-radio-button :label="1">已派发</el-radio-button>
				<el-radio-button :label="2">未派发</el-radio-button>
			</el-radio-group>
			<el-input
				v-model="params.memberMobile"
				v-number-input.float="0"
				placeholder="请输入用户手机号查找"
				clearable
				class="ml10 w200"
				maxlength="20"
			/>
		</div>
		<div class="title-label">白名单列表</div>
		<el-table
			ref="multipleTable"
			size="mini"
			:v-loading="loading.table"
			:data="listResult"
			align="center" stripe
			max-height="440"
			@select="selectL"
			@select-all="selectAll"
		>
			<el-table-column v-if="!check" type="selection" width="38" :selectable="handeDisable" />
			<el-table-column property="id" label="序号" width="60" />
			<el-table-column property="memberName" label="姓名" />
			<el-table-column property="memberMobile" label="电话" />
			<el-table-column property="state" label="派发状态" width="120">
				<template slot-scope="{ row }">
					<span class="discolor" :class="{ done: row.isDistribute }">{{
						row.isDistribute ? '已派发': '未派发'
					}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="77">
				<template slot-scope="scope">
					<!-- <el-button v-if="row.status === 1" type="text" text="danger" @click="handleDelete(row)"> 删除 </el-button> -->
					<el-popover
						:ref="`popover-${scope.$index}`"
						:class="`popover-${scope.$index}`"
						placement="top"
						width="160"
					>
						<div class="mb10">确定撤回吗？</div>
						<div style="text-align: right; margin: 0">
							<el-button size="mini" type="text" @click="scope._self.$refs[`popover-${scope.$index}`].doClose() ">取消</el-button>
							<el-button type="danger" size="mini" @click="handleDelete(scope.row,scope.$index)">确定</el-button>
						</div>
						<el-button v-show="scope.row.isDistribute && scope.row.status == 1" slot="reference" type="text" text="danger"> 撤回 </el-button>
					</el-popover>
				</template>
			</el-table-column>
		</el-table>

		<div class="flex flex-end mt10">
			<el-pagination
				:current-page.sync="pageInfoResult.pageNum"
				:page-sizes="[10, 50, 100, 500, 1000]"
				:page-size="pageInfoResult.pageSize"
				layout="sizes, prev, pager, next"
				:total="totalElements"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
			/>
		</div>
		<div v-if="!check" class="data">
			<div class="title-label">已选名单</div>
			<div v-if="listSelect.length" class="data-list">
				<div v-for="(item, index) in listSelect" :key="index" class="data-list-item">
					<span>{{ item.memberName }} {{ item.memberMobile }}</span>
					<i class="el-icon-error" @click="removeWhiteListItem(item, index)" />
				</div>
			</div>
			<div v-else class="mt20">- 空 -</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button v-if="!check" type="primary" :disabled="listSelectId.length == 0" @click="whiteListSubmit()">保 存</el-button>
			<el-button @click="whiteListClose()">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { debounce } from "utils/utils"
import { membersWhiteList, deleteDistributeCoupon } from "api/business/selfManager/keyAccount"
import { distributeState } from "@/utils/constants"

export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		select: {
			type: Array,
			default: () => {
				return []
			}
		},
		selectId: {
			type: Array,
			default: () => {
				return []
			}
		},
		cId: {
			type: [String, Number],
			default: ""
		},
		check: {
			type: [Boolean],
			default: false
		}
	},
	data() {
		return {
			params: {
				state: 0,
				memberMobile: "",
				couponId: ""
			},
			WhiteList: [],

			listResult: [],
			listSelect: [],
			listSelectId: [],
			loading: {
				table: true
			},
			pageInfoResult: {
				pageNum: 1,
				pageSize: 10
			},
			totalElements: 0,
			selectAllCheck: 1 // 奇非全偶已全
		}
	},
	watch: {
		visible() {
			this.fetchSelectAssets()
		},
		params: {
			// 增加防抖
			handler: debounce(function() {
				this.fetchSelectAssets()
			}, 300),
			deep: true
		}
	},
	methods: {
		// 派发状态
		checkStatus(status) { 
			const obj = distributeState.find(e => e.value === status)
			return obj ? obj.label : " - "
		},
		// 白名单保存
		whiteListSubmit() {
			this.$emit("submit", this.listSelect)
			this.$refs.multipleTable.clearSelection()
			this.listSelect = []
			this.listSelectId = []
			this.whiteListClose()
		},
		// 模态框关闭
		whiteListClose() {
			this.$emit("update:check", false)
			this.$emit("update:visible", false)
		},
		// 查询选择列表
		fetchSelectAssets() {
			this.loading.table = true
			this.params.couponId = this.cId
			const params = Object.assign(this.params, JSON.parse(JSON.stringify(this.pageInfoResult)))
			params.pageNum = params.pageNum - 1
			membersWhiteList(params, this.$route.query.id).then(res => {
				this.listResult = res.data.list
				// 设置listSelect中存在的数据项默认选中
				this.$nextTick(() => {
					this.listResult.forEach(item => {
						if (this.listSelectId.indexOf(item.id) != -1) {
							this.selectAllCheck = 2
							this.$refs.multipleTable.toggleRowSelection(item)
						} else {
							this.selectAllCheck = 1
						}
					})
				})
				this.totalElements = res.data.totalElements
				this.loading.table = false
			})
		},
		// 删除
		removeWhiteListItem(item) {
			// console.log(item)
			this.selectAllCheck = 1 // 当前页面的数据项已经不是全部被选中
			for (let i in this.listSelectId) {
				if (item.id === this.listSelectId[i]) {
					this.listSelect.splice(i, 1)
					this.listSelectId.splice(i, 1)
				}
			}
			this.$nextTick(() => {
				this.listResult.find(obj => {
					if (item.id === obj.id) this.$refs.multipleTable.toggleRowSelection(obj, false)
				})
			})
		},
		handleDelete(row, index) { 
			const params = {
				memberMobile: row.memberMobile,
				couponId: this.cId
			}
			deleteDistributeCoupon(params).then(res => { 
				if (res.status === "OK")
					this.$notify.success({
						title: "成功",
						message: "删除派发成功"
					})
				this.fetchSelectAssets()
				this.$emit("refresh")
				this.$refs[`popover-${index}`].doClose()
			})
		},

		// 设置页码
		handleSizeChange(val) {
			this.pageInfoResult.pageSize = val
			this.pageInfoResult.pageNum = 1
			this.fetchSelectAssets()
		},
		// 设置当前页码
		handleCurrentChange(val) {
			this.pageInfoResult.pageNum = val
			this.fetchSelectAssets()
		},
		// 选中添加/不选中删除---点击全选
		selectAll(selection) {
			if (this.selectAllCheck % 2) {
				// console.log(this.selectAllCheck, this.selectAllCheck % 2, "未全选")
				selection.forEach(item => {
					if (this.listSelectId.indexOf(item.id) === -1) {
						this.listSelect.push(item)
						this.listSelectId.push(item.id)
					}
				})
			} else {
				// console.log(this.selectAllCheck, this.selectAllCheck % 2, "已全选")
				this.listResult.forEach(item => {
					const i = this.listSelectId.indexOf(item.id)
					if (i !== -1) {
						this.listSelect.splice(i, 1)
						this.listSelectId.splice(i, 1)
					}
				})
			}
			this.selectAllCheck++
		},
		// 选中添加/不选中删除---点击行
		selectL(selection, row) {
			if (event.target.checked) {
				this.listSelect.push(row)
				this.listSelectId.push(row.id)
				if (selection.length == this.listResult.length) {
					// 判断是否当前页面的数据项是否已全部被选中
					this.selectAllCheck = 2
				}
			} else {
				this.selectAllCheck = 1 // 当前页面的数据项已经不是全部被选中
				for (let i in this.listSelectId) {
					if (row.id === this.listSelectId[i]) {
						this.listSelect.splice(i, 1)
						this.listSelectId.splice(i, 1)
					}
				}
			}
		},
		// 不可勾选函数
		handeDisable(row) {
			return !row.isDistribute
		}
	}
}
</script>

<style lang="scss" scoped>
.data-list {
	display: flex;
	margin-top: 10px;
	flex-wrap: wrap;

	.data-list-item {
		position: relative;
		margin-right: 16px;
		margin-bottom: 10px;
		padding: 4px 20px 4px 14px;
		border: 1px solid #ddd;
		border-radius: 20px;

		.el-icon-error {
			position: absolute;
			top: -4px;
			right: -6px;
			font-size: 16px;
			color: #cc0000;
		}
	}
}

.discolor {
	color: #4b5eff;
}
.done {
	color: orange;
}
.title-label {
	position: relative;
	margin: 0 0 10px 14px;
}

.title-label::after {
	content: "";
	position: absolute;
	left: -14px;
	top: 2px;
	width: 6px;
	height: 16px;
	background-color: #409eff;
}
</style>
