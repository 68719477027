<template>
    <div class="mvk-layout-content-card">
        <el-form ref="form" :model="configForm" label-width="580px" :inline="false">
            <el-form-item label="积分抵用比例(1积分抵多少金额)" prop="integralCreditRatio">
                <el-input-number v-model="configForm.integralCreditRatio" :min="0.0" :precision="1" />
            </el-form-item>
            <el-form-item label="下单赠送积分比例（实际支付1元赠送多少积分）" prop="orderFree">
                <el-input-number v-model="configForm.orderFree" :min="0" />
            </el-form-item>
            <el-form-item label="注册送积分（新用户注册赠送积分）" prop="register">
                <el-input-number v-model="configForm.register" :min="0" />
            </el-form-item>
            <el-form-item label="推荐好友送积分（会员推荐好友注册成功赠送积分）" prop="recommend">
                <el-input-number v-model="configForm.recommend" :min="0" />
            </el-form-item>
            <el-form-item label="分享送积分（会员分享商品给好友，好友通过分享链接进入商品页面送积分）" prop="share">
                <el-input-number v-model="configForm.share" :min="0" />
            </el-form-item>
            <el-form-item label="新会员首次下单送积分（订单交易完成后，如果会员是首次下单则赠送积分）" prop="newMember">
                <el-input-number v-model="configForm.newMember" :min="0" />
            </el-form-item>
            <!-- <el-form-item label="积分冻结时间(天)" prop="freezingTime">
                <el-input-number v-model="configForm.freezingTime" :min="0" />
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" :disabled="loading" @click="handleSubmit">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { GetIntegral, EditIntegral, AddIntegral } from "api/business/marketing/integral"
export default {
    name: "IntegralConfig",

    components: {},

    directives: {},

    data() {
        return {
            configForm: {
                id: "",
                integralCreditRatio: 0,
                orderFree: 1,
                register: 0,
                recommend: 0,
                freezingTime: 0
            },

            rules: {
                integralCreditRatio: [
                    {
                        required: true,
                        message: "请输入积分抵用比例",
                        trigger: "blur"
                    }
                ],
                orderFree: [
                    {
                        required: true,
                        message: "请输入下单赠送积分比例",
                        trigger: "blur"
                    }
                ],
                freezingTime: [
                    {
                        required: true,
                        message: "请输入积分冻结时间",
                        trigger: "blur"
                    }
                ]
            },

            newFlag: false,
            loading: true
        }
    },

    mounted() {
        this.getIntegralInfo()
    },

    methods: {
        handleSubmit() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.newFlag) {
                        AddIntegral(this.configForm).then(() => {
                            this.$notify.success({
                                title: "成功",
                                message: "保存成功"
                            })

                            this.getIntegralInfo()
                        })
                    } else {
                        EditIntegral(this.configForm).then(() => {
                            this.$notify.success({
                                title: "成功",
                                message: "修改成功"
                            })

                            this.getIntegralInfo()
                        })
                    }
                } else {
                    return false
                }
            })
        },

        getIntegralInfo() {
            this.loading = true
            GetIntegral().then(res => {
                this.loading = false
                if (res.data.id == null) {
                    this.newFlag = true
                } else {
                    this.configForm = res.data
                }
            }).catch(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped></style>
