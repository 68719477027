<template>
	<div>
		<el-row :gutter="20">
			<el-col v-for="(item, i) in kpiList" :key="i" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
				<div class="kpi-boxes" :style="{ background: item.background }">
					<div class="item_txt">
						<span>{{ item.name }}</span>
					</div>
					<div class="item_num">
						<div>
							<count-to :start-val="0" :end-val="item.value" :duration="2600" class="count-num" />
						</div>
					</div>
					<img class="item_img" :src="item.src">
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="15">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header">
						<span>会员统计</span>
						<div style="float: right">单位（位）</div>
					</div>
					<div>
						<el-row :gutter="20">
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="userStatistic.todayNew"
									:duration="2600"
									class="count-num"
								/>
								<p>今日新增</p>
							</el-col>
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="userStatistic.yesterdayNew"
									:duration="2600"
									class="count-num"
								/>
								<p>昨日新增</p>
							</el-col>
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="userStatistic.monthTotal"
									:duration="2600"
									class="count-num"
								/>
								<p>今月新增</p>
							</el-col>
							<el-col :span="6" class="text-center">
								<count-to
									:start-val="0"
									:end-val="userStatistic.total"
									:duration="2600"
									class="count-num"
								/>
								<p>会员总数</p>
							</el-col>
						</el-row>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header">
						<span>商品统计</span>
						<div style="float: right">单位（件）</div>
					</div>
					<div>
						<el-row :gutter="20">
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="prodStatistic.totalProds"
									:duration="2600"
									class="count-num"
								/>
								<p>商品总数</p>
							</el-col>
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="prodStatistic.totalSelfProds"
									:duration="2600"
									class="count-num"
								/>
								<p>自营商品</p>
							</el-col>
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="prodStatistic.totalBusinessProds"
									:duration="2600"
									class="count-num"
								/>
								<p>商家商品</p>
							</el-col>
							<el-col :span="6" class="text-center">
								<count-to
									:start-val="0"
									:end-val="prodStatistic.totalBusinessCategory"
									:duration="2600"
									class="count-num"
								/>
								<p>商品类目</p>
							</el-col>
						</el-row>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header">
						<span>订单情况</span>
						<div style="float: right">单位（个）</div>
					</div>
					<div>
						<el-row :gutter="20">
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="orderStatistic.todayNew"
									:duration="2600"
									class="count-num"
								/>
								<p>今日新增</p>
							</el-col>
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="orderStatistic.yesterdayNew"
									:duration="2600"
									class="count-num"
								/>
								<p>昨日新增</p>
							</el-col>
							<el-col :span="6" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="orderStatistic.weekNew"
									:duration="2600"
									class="count-num"
								/>
								<p>本周新增</p>
							</el-col>
							<el-col :span="6" class="text-center">
								<count-to
									:start-val="0"
									:end-val="orderStatistic.total"
									:duration="2600"
									class="count-num"
								/>
								<p>订单总数</p>
							</el-col>
						</el-row>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header">
						<span>商家情况</span>
					</div>
					<div>
						<el-row :gutter="20">
							<el-col :span="8" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="merchantStatistic.totalMerchants"
									:duration="2600"
									class="count-num"
								/>
								<p>商家总数</p>
							</el-col>
							<el-col :span="8" class="text-center right-border">
								<count-to
									:start-val="0"
									:end-val="merchantStatistic.totalBusinessProds"
									:duration="2600"
									class="count-num"
								/>
								<p>商家商品</p>
							</el-col>
							<el-col :span="8" class="text-center">
								<count-to
									:start-val="0"
									:end-val="merchantStatistic.totalBusinessCategory"
									:duration="2600"
									class="count-num"
								/>
								<p>商家类目</p>
							</el-col>
						</el-row>
					</div>
				</el-card>
			</el-col>
			<el-col :span="24">
				<el-card class="box-card">
					<div slot="header">
						<span>销售统计</span>
						<div style="float: right">
							<el-date-picker
								v-model="dateTime"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyyMMdd"
								style="top: -5px"
								@change="getSaleTendencyData"
							/>
						</div>
					</div>
					<line-chart v-if="show" :xdata="xAxisData" :ydata="seriesData" />
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import CountTo from "vue-count-to"
import moment from "moment"
import {
	getStatistic,
	getUserStatistic,
	getProdStatistic,
	getOrderStatistic,
	getMerchantStatistic,
	getSaleTendency
} from "api/business/dashboard"

import LineChart from "views/components/LineChart"
export default {
	components: {
		CountTo,
		LineChart
	},
	props: {},
	data() {
		return {
			bgImg: require("@/assets/img/bg/img_03@2x.png"),
			kpiList: [
				{
					name: "今日销售总额",
					src: require("@/assets/img/bg/img_01@2x.png"),
					background: "#00B7F4",
					value: 0
				},
				{
					name: "今日订单数量",
					src: require("@/assets/img/bg/img_02@2x.png"),
					background: "#FF6565",
					value: 0
				},
				{
					name: "今日人流量",
					src: require("@/assets/img/bg/img_03@2x.png"),
					background: "#1677FF",
					value: 0
				}
			],
			userStatistic: {
				todayNew: 0,
				yesterdayNew: 0,
				monthTotal: 0,
				total: 0
			},

			prodStatistic: {
				totalProds: 0,
				totalSelfProds: 0,
				totalBusinessProds: 0,
				totalBusinessCategory: 0
			},

			orderStatistic: {
				todayNew: 0,
				yesterdayNew: 0,
				weekNew: 0,
				total: 0
			},

			merchantStatistic: {
				totalMerchants: 0,
				totalBusinessProds: 0,
				totalBusinessCategory: 0
			},

			dateTime: [],
			xAxisData: [],
			seriesData: [],
			show: false
		}
	},
	watch: {},
	computed: {},
	methods: {
		// 首页统计
		getStatisticData() {
			getStatistic({
				date: moment().startOf("day").subtract(1, "days").format("YYYYMMDD")
			}).then(res => {
				this.kpiList[0].value = res.data.todaySales
				this.kpiList[1].value = res.data.todayOrders
				this.kpiList[2].value = res.data.todayUV
			})
		},
		// 会员统计
		getUserStatisticData() {
			getUserStatistic({
				date: moment().startOf("day").format("YYYYMMDD")
			}).then(res => {
				let itemData = res.data
				Object.keys(itemData).forEach(item => {
					if (Object.prototype.hasOwnProperty.call(this.userStatistic, item)) {
						this.userStatistic[item] = parseInt(itemData[item])
					}
				})
			})
		},
		// 商品统计
		getProdStatisticData() {
			getProdStatistic({
				date: moment().startOf("day").format("YYYYMMDD")
			}).then(res => {
				let itemData = res.data
				Object.keys(itemData).forEach(item => {
					if (Object.prototype.hasOwnProperty.call(this.prodStatistic, item)) {
						this.prodStatistic[item] = parseInt(itemData[item])
					}
				})
			})
		},
		// 订单统计
		getOrderStatisticData() {
			getOrderStatistic({
				date: moment().startOf("day").format("YYYYMMDD")
			}).then(res => {
				let itemData = res.data
				Object.keys(itemData).forEach(item => {
					if (Object.prototype.hasOwnProperty.call(this.orderStatistic, item)) {
						this.orderStatistic[item] = parseInt(itemData[item])
					}
				})
			})
		},
		// 商家统计
		getMerchantStatisticData() {
			getMerchantStatistic({
				date: moment().startOf("day").format("YYYYMMDD")
			}).then(res => {
				let itemData = res.data
				Object.keys(itemData).forEach(item => {
					if (Object.prototype.hasOwnProperty.call(this.merchantStatistic, item)) {
						this.merchantStatistic[item] = parseInt(itemData[item])
					}
				})
			})
		},
		// 销售统计
		getSaleTendencyData() {
			var vm = this
			vm.show = false
			getSaleTendency({
				startDate: this.dateTime[0],
				endDate: this.dateTime[1]
			}).then(res => {
				if (res.data && res.data.length > 0) {
					let orderData = []
					let salesData = []
					vm.xAxisData = []
					res.data.forEach(item => {
						vm.xAxisData.push(item.calcDate.substr(0, 10))
						orderData.push(item.orders)
						salesData.push(item.sales)
					})
					vm.seriesData = []
					vm.seriesData.push({
						name: "订单量",
						value: orderData
					})
					vm.seriesData.push({
						name: "销售额",
						value: salesData
					})

					vm.show = true
				}
			})
		}
	},
	created() {},
	mounted() {
		const today = moment().startOf("day").format("YYYYMMDD")
		const month = moment().subtract(30, "days").format("YYYYMMDD")
		this.dateTime.push(month)
		this.dateTime.push(today)
		this.getStatisticData()
		this.getUserStatisticData()
		this.getProdStatisticData()
		this.getOrderStatisticData()
		this.getMerchantStatisticData()
		this.getSaleTendencyData()

		// window.isLogin = false
	}
}
</script>
<style lang="scss" scoped>
.kpi-boxes {
	border: 1px solid #e8e8e8;
	cursor: pointer;
	border-radius: 5px;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	min-width: 180px;
	overflow: hidden;
	padding: 20px;
	height: 134px;
	margin-bottom: 15px;
	background-color: #ff6565;
	position: relative;
	-webkit-transition: -webkit-transform 0.2s;
	transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
	-webkit-transition: transform 0.2s;
	-webkit-box-shadow: 0 0 40px 0 rgb(0 0 0 / 8%);
	box-shadow: 0 0 40px 0 rgb(0 0 0 / 8%);

	.item_txt {
		margin-bottom: 10px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 15px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #fff;
	}

	.item_num {
		font-size: 24px;
		font-weight: 500;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: #fff;
	}

	.item_img {
		height: 134px;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.box-card {
	margin-bottom: 15px;
}

.text-center {
	text-align: center;

	.count-num {
		font-size: 20px;
	}

	p {
		color: #6a74a5;
		margin-top: 8px;
	}
}

.right-border {
	border-right: 1px solid #e5e5e5;
}

.el-date-editor .el-range-separator {
	padding: 0 1px;
}
</style>
