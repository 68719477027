import fetch from "@/axios/fetch"

export function getArticle() { // 分页查询
	return fetch({
		url: "/article",
		method: "get"
	})
}

export function setArticle(data) { // 新增资讯
	return fetch({
		url: "/article/save",
		method: "post",
		data
	})
}

export function editArticle(data) { // 修改
	return fetch({
		url: "/article/update",
		method: "put",
		data
	})
}

export function deleteArticle(id) { // 删除
	return fetch({
		url: "/article/" + id,
		method: "delete"
	})
}
export function detailArticle(id) { // 查询详情
	return fetch({
		url: "/article/" + id,
		method: "get"
	})
}