<template>
	<el-dialog :title="title" :visible.sync="addEditDialog" :close-on-click-modal="false" width="600px" top="60px">
		<el-form ref="prodForm" :model="prodForm" :rules="Rules" label-width="120px">
			<el-form-item prop="name" label="规格名称：">
				<mvk-input v-model.trim="prodForm.name" placeholder="规格名称" />
			</el-form-item>
			<el-form-item prop="values" label="规格属性：">
				<tag-add v-model="prodForm.values" @check="checkValues('prodForm')" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" class="form-submit" @click="addSubmit('prodForm')">保存</el-button>
				<el-button @click="addEditDialog = false">关闭</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { addProd, editProd } from "api/business/selfManager/prod.js"
export default {
	name: "",
	components: {
		"tag-add": () => import("@/components/TagAdd")
	},
	data() {
		return {
			title: "新增",
			addEditDialog: false,
			prodForm: {
				id: "",
				name: "",
				values: [],
				valueName: []
			},
			Rules: {
				name: [
					{
						required: true,
						message: "请输入规格名称",
						trigger: "blur"
					}
				],
				values: [
					{
						required: true,
						message: "请输入规格属性",
						trigger: "change"
					}
				]
			}
		}
	},
	computed: {},
	methods: {
		add() {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.prodForm = {
					id: "",
					name: "",
					values: [],
					valueName: []
				}
			})
		},
		edit(record) {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.prodForm = JSON.parse(JSON.stringify(record))
				this.prodForm.values = this.prodForm.valueName
			})
		},
		checkValues(formName) {
			this.$refs[formName].validateField("values")
		},
		addSubmit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.prodForm.id) {
						editProd(this.prodForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改规格成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "修改规格失败"
								})
							})
					} else {
						// 新增
						addProd(this.prodForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "添加规格成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "添加规格失败"
								})
							})
					}
				} else {
					return false
				}
			})
		}
	},
	mounted() {}
}
</script>

<style lang="scss" scoped></style>
