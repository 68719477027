<template>
	<el-dialog title="优惠券派发" :top="data.length > 5 ? '5vh' : '15vh'" :visible="visible" destroy-on-close
		:close-on-click-modal="false" width="500px" @close="close()"
	>
		<div class="flex flex-sb mb10">
			<div class="flex-1 flex">
				<el-button type="primary" @click="openWhiteList">白名单选择</el-button>
				<el-upload action
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					:http-request="handleUpload" :headers="uploadHeaders" :show-file-list="false"
				>
					<!-- :http-request="httpRequest" :headers="uploadHeaders" :show-file-list="false"
				> -->
					<el-button type="primary" icon="el-icon-upload2" style="margin:0 10px;">
						Excel导入
					</el-button>
				</el-upload>
				<el-button type="default" icon="el-icon-download" @click="toDownloadTemplate()">
					导入模版下载
				</el-button>
			</div>
			<div>
				<el-button type="default" @click="clearData">清空</el-button>
			</div>
		</div>
		<el-table :data="data" border :height="440">
			<el-table-column property="id" label="序号" align="right" width="60" />
			<!-- <el-table-column property="realName" label="姓名" width="100" /> -->
			<el-table-column property="memberName" label="姓名">
				<template slot-scope="{ row }">
					{{ row.memberName }}
				</template>
			</el-table-column>
			<el-table-column property="memberMobile" label="电话" />
			<el-table-column label="操作" width="64" align="center">
				<template slot-scope="{ row, $index }">
					<el-button type="text" @click="handleDistributeDelete(row, $index)"> 删除 </el-button>
				</template>
			</el-table-column>
		</el-table>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" :disabled="data.length === 0" :loading="loading" @click="distributeSubmit()">派 发</el-button>
			<el-button @click="close()">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { getToken } from "api/auth"
import { blobTofile } from "@/utils/utils"
import { getDownload, importMembers } from "api/business/selfManager/keyAccount"
import * as XLSX from "xlsx/xlsx.mjs"
export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		data: {
			type: Array,
			default: () => {
				return []
			}
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			uploadHeaders: { "X-Token": getToken() },
			tableData: [],
			fileContent: "",
			file: "",
			list: []
		}
	},
	methods: {
		// 关闭
		close() {
			this.$emit("update:visible", false)
		},
		// 打开白名单模态框
		openWhiteList() {
			this.$emit("open", true)
		},
		// 派发操作
		distributeSubmit() {
			this.$emit("submit", this.data)
		},
		// 派发模态框 - 删除
		handleDistributeDelete(row, index) {
			const data = this.data
			data.splice(index, 1)
			this.$emit("update:data", data)
		},
		// 清空所有
		clearData() {
			this.$emit("update:data", [])
		},
		// 导入模板下载
		toDownloadTemplate() {
			getDownload().then(r => {
				blobTofile(r, "导入模版", "application/vnd.ms-excel")
			})
		},
		// 批量导入会员 -- 静态上传（不用了、废弃！）
		httpRequest(e) {
			let file = e.file // 文件信息
			if (!file) {
				// 没有文件
				return false
			} else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
				// 格式根据自己需求定义
				this.$message.error("上传格式不正确，请上传xls或者xlsx格式")
				return false
			}
			const fileReader = new FileReader()
			fileReader.onload = ev => {
				try {
					const dataTemp = ev.target.result
					const workbook = XLSX.read(dataTemp, {
						type: "binary" // 以字符编码的方式解析
					})
					const exlname = workbook.SheetNames[0] // 取第一张表
					let exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]) // 生成json表格内容
					let list = []
					exl.forEach(item => {
						const arr = Object.values(item)
						list.push({
							memberName: arr[0],
							memberMobile: arr[1]
						})
					})

					// 获取
					let json = this.data.concat(list.map(e => { return { memberName: e.memberName, memberMobile: e.memberMobile } })) // 两个数组对象合并
					// 去重
					let newJson = [] // 盛放去重后数据的新数组
					for (let item1 of json) {  // 循环json数组对象的内容
						let flag = true  // 建立标记，判断数据是否重复，true为不重复
						for (let item2 of newJson) {  // 循环新数组的内容
							if (item1.memberMobile == item2.memberMobile) { // 让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
								flag = false
							}
						}
						if (flag) { // 判断是否重复
							newJson.push(item1) // 不重复的放入新数组。  新数组的内容会继续进行上边的循环。
						}
					}
					// 赋值
					this.$emit("update:data", newJson)
				} catch (e) {
					console.log("出错了：：")
					return false
				}
			}
			fileReader.readAsBinaryString(file)
		},
		// 批量导入会员

		handleUpload(param) {
			const formData = new FormData()
			formData.append("file", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			importMembers(formData, this.$route.query.id)
				.then(res => {
					console.log(res)
					if (res.status == "OK") {
						const dataList = res.data.map(e => { return { memberName: e.memberName, memberMobile: e.memberMobile, id: e.id } })
						// // 赋值
						const newData = this.data.concat(dataList)
						this.$emit("update:data", newData)
						this.$message.success("上传成功")
					}
				})
				.finally(() => {
					loading.close()
				})
		}

	}
}
</script>

<style lang="scss" scoped>

</style>