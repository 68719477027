<template>
	<div>
		<el-page-header content="详情页面" @back="goBack" />
		<el-alert type="success" :closable="false" class="detail-box">
			<el-descriptions size="medium">
				<el-descriptions-item v-for="item in detailItem" :key="item.prop" :label="item.label">
					<template v-if="item.prop == 'productVOList'">
						<div class="flex">
							<el-image
								style="min-width: 40px; height: 40px; width: 40px"
								:src="detailData.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'"
								:preview-src-list="[detailData.pic]"
							/>
							<span style="margin-left: 4px">
								{{ detailData.prodName }}
							</span>
						</div>
					</template>
					<template v-else-if="item.prop == 'type'">
						<el-tag v-if="detailData['type'] == 1"> 实体卡 </el-tag>
						<el-tag v-else>电子卡</el-tag>
					</template>
					<template v-else-if="item.prop == 'time'">
						<span v-show="detailData.startTime != '' && detailData.startTime != null">
							{{ detailData["startTime"] }} ~ {{ detailData["endTime"] }}
						</span>
						<span v-show="detailData.startTime == ''">无限期</span>
					</template>
					<span v-else>
						{{ detailData[item.prop] }}
					</span>
				</el-descriptions-item>
			</el-descriptions>
		</el-alert>

		<el-form size="small" label-width="100px" :inline="true" class="mt15">
			<el-form-item label="礼品卡状态">
				<el-select v-model="queryParam.state" placeholder="礼品卡状态" clearable>
					<el-option label="不可用" value="false" />
					<el-option label="可用" value="true" />
				</el-select>
			</el-form-item>
			<el-form-item label="派发状态">
				<el-select v-model="queryParam.distributeState" placeholder="派发状态" clearable>
					<el-option v-for="(item, ikey) in distributeStateMap" :key="ikey" :label="item" :value="ikey" />
				</el-select>
			</el-form-item>
		</el-form>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15" />
	</div>
</template>

<script>
import { giftCardType } from "@/utils/constants"
import { getGiftCardList } from "api/business/marketing/giftCard.js"

const distributeState = {
	0: "未领取",
	1: "已领取"
}

export default {
	name: "GiftCardDetail",
	props: {
		groupId: {
			type: Number,
			required: true,
			default: 0
		}
	},
	data() {
		return {
			giftCardTypeMap: giftCardType,
			distributeStateMap: distributeState,
			detailItem: [
				{
					prop: "name",
					label: "礼品卡名称"
				},
				{
					prop: "productVOList",
					label: "商品信息"
				},
				{
					prop: "num",
					label: "数量"
				},
				{
					prop: "type",
					label: "卡类型"
				},
				{
					prop: "amount",
					label: "金额"
				},
				{
					prop: "creationDate",
					label: "创建时间"
				},
				{
					prop: "time",
					label: "礼品卡有效期"
				},
				{
					prop: "remark",
					label: "备注"
				}
			],
			detailData: {},
			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "cardsNo",
					label: "卡号"
				},
				{
					prop: "secretKey",
					label: "卡密",
					formatter: row => {
						return row.secretKey.substr(0, 3) + "******" + row.secretKey.substr(9, 12)
					}
				},
				{
					prop: "user",
					label: "领取用户",
					formatter: row => {
						return row.user && row.user.nickName
					}
				},
				{
					prop: "state",
					label: "礼品卡状态",
					formatter: row => {
						return row.state ? "可用" : "不可用"
					}
				},
				{
					prop: "distributeState",
					label: "派发状态",
					formatter: row => {
						return row.distributeState ? "已领取 " : "未领取"
					}
				},
				{
					prop: "useTime",
					label: "使用时间",
					formatter: row => {
						return row.useTime ? row.useTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "") : ""
					}
				}
			],
			queryParam: {
				state: "",
				distributeState: ""
			}
		}
	},
	computed: {
		tableDataFunc() {
			let temp = JSON.parse(JSON.stringify(this.queryParam))
			temp.distributeState = temp.distributeState != 0
			let parameTemp = Object.assign({ groupId: this.groupId }, this.queryParam)
			if (this.groupId == 0) {
				return () => {
					return new Promise(resolve => {
						return resolve({
							list: [],
							state: "OK",
							totalElements: 0
						})
					})
				}
			} else {
				return parameter => {
					return getGiftCardList(Object.assign(parameter, parameTemp)).then(res => {
						return res.data
					})
				}
			}
		}
	},

	mounted() {},

	methods: {
		goBack() {
			this.$emit("goback")
		},

		view(record) {
			this.detailData = record
			this.detailData.pic = record.productVOList && record.productVOList[0].pic
			this.detailData.prodName = record.productVOList && record.productVOList[0].prodName
			console.log(this.detailData)
		}
	}
}
</script>

<style lang="scss" scoped>
.detail-box {
	padding: 15px;
	margin-top: 15px;
	font-size: 16px;

	::v-deep .el-descriptions__body {
		background: transparent;
	}
}
</style>
