<template>
    <el-drawer :title="title" :visible.sync="addEditVisible" size="70%" :append-to-body="true">
        <el-form ref="form" :model="form" :rules="rules" label-width="180px" :inline="false">
            <el-form-item label="活动名称" prop="name">
                <el-input v-model="form.name" class="inputWidth" />
            </el-form-item>
            <el-form-item label="节日名称" prop="holidayId">
                <el-button type="primary" @click="holidayDialog = true"> 节日选择 </el-button>
                <span v-show="holidayValue" style="display: inline-block; margin-left: 8px">
                    已选择{{ holidayValue && holidayValue.name }}({{ holidayValue && holidayValue.calendarDate }})
                </span>
            </el-form-item>
            <el-form-item label="参与对象" prop="target">
                <el-radio-group v-model="form.target" @change="targetChange">
                    <el-radio :label="0">所有会员</el-radio>
                    <el-radio :label="1">按会员标签</el-radio>
                    <el-radio :label="2">按会员分组</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-show="form.target == 1" label="会员标签" prop="targetNoArray" :rules="rules.targetNoArray">
                <el-checkbox-group v-model="form.targetNoArray">
                    <el-checkbox v-for="item in memberLabelList" :key="item.id" :label="item.id">
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item v-show="form.target == 2" label="会员分组" prop="targetNoArray" :rules="rules.targetNoArray">
                <el-checkbox-group v-model="form.targetNoArray">
                    <el-checkbox v-for="item in memberGroupList" :key="item.id" :label="item.id">
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="活动奖品" prop="prizeTypeArray">
                <el-checkbox-group v-model="form.prizeTypeArray" style="display: flex; flex-direction: column">
                    <el-checkbox :label="1">
                        优惠券
                        <el-button type="primary" @click="couponDialog = true">选择优惠券</el-button>
                        <span v-if="checkedCouponInfo.name">已选择 优惠券: {{ checkedCouponInfo.name }}</span>
                    </el-checkbox>
                    <el-checkbox :label="2" class="mt10">
                        积分
                        <span style="display: inline-block; margin-left: 8px">
                            送
                            <el-input v-model="form.points" style="width: 100px" />
                            积分
                        </span>
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="发放时间" prop="issueDate">
                <el-select v-model="sendDate" placeholder="">
                    <el-option v-for="item in sendDateArray" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
                <el-time-select
                    v-model="sendDateTime"
                    :picker-options="{
                        start: '00:00',
                        step: '1:00',
                        end: '24:00'
                    }"
                    :clearable="false"
                    class="ml10"
                    placeholder="选择时间"
                />
            </el-form-item>
            <el-form-item label="短信提醒">
                <el-switch v-model="form.smsNotice" />
            </el-form-item>
            <el-form-item
                v-show="form.smsNotice"
                label="短信模板Id"
                prop="templateId"
                :rules="form.smsNotice ? rules.templateId : {}"
            >
                <el-input v-model="form.templateId" class="inputWidth" />
                <p class="text-tip">请输入腾讯云中对应的短信模板ID</p>
                <p class="text-tip">
                    温馨提示：请先在腾讯云短信服务中维护短信模板，并将需要发送的模板ID填入短信模板ID输入框
                </p>
            </el-form-item>
            <!-- <el-form-item>
				<el-button type="primary" @click="onSubmit">立即创建</el-button>
				<el-button>取消</el-button>
			</el-form-item> -->
        </el-form>
        <div class="custom-drawer-footer">
            <div class="flex-row" style="justify-content: space-between">
                <el-button type="primary" @click="handleSubmit"> 保存 </el-button>
            </div>
        </div>

        <el-dialog title="节日选择" :visible.sync="holidayDialog" width="50%" :append-to-body="true">
            <el-row :gutter="20">
                <el-col :span="8" :offset="0">
                    <p class="tip">传统节日</p>
                    <el-radio-group v-model="form.holidayId" style="display: flex; flex-direction: column">
                        <el-radio
                            v-for="item in traditionHoliday"
                            :key="item.id"
                            :label="item.id"
                            style="margin-top: 12px"
                        >
                            {{ item.name }}({{ item.lunarDate }})
                        </el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="8" :offset="0">
                    <p class="tip">西方节日</p>
                    <el-radio-group v-model="form.holidayId" style="display: flex; flex-direction: column">
                        <el-radio v-for="item in westHoliday" :key="item.id" :label="item.id" style="margin-top: 12px" class="ellipsis">
                            {{ item.name }}({{ item.calendarDate }})
                        </el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="8" :offset="0">
                    <p class="tip">公众节日</p>
                    <el-radio-group v-model="form.holidayId" style="display: flex; flex-direction: column">
                        <el-radio
                            v-for="item in publicHoliday"
                            :key="item.id"
                            :label="item.id"
                            style="margin-top: 12px"
                        >
                            {{ item.name }}({{ item.calendarDate }})
                        </el-radio>
                    </el-radio-group>
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button @click="holidayDialog = false">取 消</el-button>
                <el-button type="primary" @click="holidayDialog = false">确 定</el-button>
            </span>
        </el-dialog>

        <el-drawer title="选择活动优惠券" :visible.sync="couponDialog" width="960px" append-to-body>
            <coupon :checked-id="checkedCouponId" @couponInfo="getCouponInfo" />
        </el-drawer>
    </el-drawer>
</template>

<script>
import { getHolidayList, saveHolidayActivity } from "api/business/marketing/holiday"
import { labelList } from "api/business/member/label.js"
import { groupList } from "api/business/member/group.js"
import { debounce } from "@/utils/utils"

import moment from "moment"
export default {
    name: "HolidayActivityAdd",
    components: {
        coupon: () => import("./coupon.vue")
    },
    data() {
        return {
            title: "",
            addEditVisible: false,
            form: {
                id: "",
                name: "",
                holidayId: "",
                issueDate: "",
                target: "",
                targetNo: "", // 参与对象的id
                targetNoArray: [],
                prizeType: "",
                prizeTypeArray: [],
                prizeNo: "", // 优惠券id
                points: "",
                smsNotice: false,
                remark: "",
                templateId: ""
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入活动名称",
                        trigger: "blur"
                    }
                ],
                holidayId: [
                    {
                        required: true,
                        message: "请选择节日",
                        trigger: "change"
                    }
                ],
                target: [
                    {
                        required: true,
                        message: "请选择参与对象",
                        trigger: "change"
                    }
                ],
                prizeTypeArray: [
                    {
                        required: true,
                        message: "请选择活动奖品",
                        trigger: "change"
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.prizeTypeArray.indexOf(1) > -1 && !this.form.prizeNo) {
                                callback(new Error("请选择优惠券"))
                            }

                            if (this.form.prizeTypeArray.indexOf(2) > -1 && !this.form.points) {
                                callback(new Error("请输入积分"))
                            }
                            console.log("dddd")
                            callback()
                        },
                        trigger: "change"
                    }
                ],
                issueDate: [
                    {
                        required: true,
                        message: "请选择发放时间",
                        trigger: "change"
                    }
                ],
                targetNoArray: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.form.target == 1 && value.length == 0) {
                                callback(new Error("请选择会员标签"))
                            }

                            if (this.form.target == 2 && value.length == 0) {
                                callback(new Error("请选择会员分组"))
                            }

                            callback()
                        },
                        trigger: "change"
                    }
                ],
                templateId: [
                    {
                        required: true,
                        message: "请输入短信模板ID",
                        trigger: "change"
                    }
                ]
            },
            holidayType: "",
            sendDate: "0",
            sendDateTime: "00:00",
            sendDateArray: [
                {
                    value: "0",
                    label: "当天"
                },
                {
                    value: "1",
                    label: "前一天"
                },
                {
                    value: "2",
                    label: "前二天"
                },
                {
                    value: "3",
                    label: "前三天"
                },
                {
                    value: "4",
                    label: "前四天"
                },
                {
                    value: "5",
                    label: "前五天"
                },
                {
                    value: "6",
                    label: "前六天"
                }
            ],
            holidayDialog: false,
            holidayList: [],
            memberLabelList: [], // 会员标签
            memberGroupList: [], // 会员分组
            couponDialog: false,
            checkedCouponInfo: {}, // 选择的优惠券信息
            checkedCouponId: ""
        }
    },
    computed: {
        traditionHoliday() {
            return this.holidayList.filter(item => item.type == 1)
        },
        westHoliday() {
            return this.holidayList.filter(item => item.type == 2)
        },
        publicHoliday() {
            return this.holidayList.filter(item => item.type == 3)
        },

        holidayValue() {
            return this.holidayList.filter(item => item.id == this.form.holidayId)[0]
        }
    },

    mounted() {
        this.getHodidayLists()
        this.getMemberLabel()
        this.getMemberGroup()
    },

    methods: {
        add() {
            this.addEditVisible = true
            this.form = {
                id: "",
                name: "",
                holidayId: "",
                issueDate: "",
                target: "",
                targetNo: "",
                targetNoArray: [],
                prizeType: "",
                prizeTypeArray: [],
                prizeNo: "", // 优惠券id
                points: "",
                smsNotice: false,
                remark: "",
                templateId: ""
            }
        },

        edit(record) {
            this.addEditVisible = true
            this.$nextTick(() => {
                this.form = JSON.parse(JSON.stringify(record))

                let strDateS = moment(this.holidayValue && this.holidayValue.calendarDate.substr(0, 10))
                let strDateE = moment(this.form.issueDate.substr(0, 10))
                this.sendDate = strDateS.diff(strDateE, "days") + ""
                this.sendDateTime = this.form.issueDate.substr(11, 15)
                // 按用户标签或用户分组，勾选的选项
                if (this.form.target != 0) {
                    this.form.targetNoArray = JSON.parse(this.form.targetNo)
                } else {
                    this.form.targetNoArray = []
                }

                // 活动奖品的处理，1：优惠券；2：积分；3：优惠券+积分
                let temp = []
                if (this.form.prizeType == 3) {
                    temp.push(1)
                    temp.push(2)
                } else {
                    temp.push(this.form.prizeType)
                }
                // console.log(temp)
                this.$set(this.form, "prizeTypeArray", temp)

                // 优惠券id
                this.checkedCouponId = this.form.prizeNo == null ? "" : this.form.prizeNo
            })
        },

        getHodidayLists() {
            getHolidayList({
                pageNum: 0,
                pageSize: 200
            }).then(res => {
                this.holidayList = res.data.list
            })
        },

        handleSubmit() {
            if (this.holidayValue) {
                this.form.issueDate =
                    moment(this.holidayValue.calendarDate).subtract(this.sendDate, "days").format("YYYY-MM-DD") +
                    " " +
                    this.sendDateTime +
                    ":00"
            }

            // 参与对象的处理
            if (this.form.target != 0) {
                this.form.targetNo = JSON.stringify(this.form.targetNoArray)
            } else {
                this.form.targetNo = ""
            }
            // 活动奖品的处理
            if (this.form.prizeTypeArray.length > 1) {
                this.form.prizeType = 3
            } else {
                this.form.prizeType = this.form.prizeTypeArray.toString()
            }

            if (this.form.prizeType == 2) {
                this.form.prizeNo = ""
            }

            if (this.form.prizeType == 1) {
                this.form.points = ""
            }

            this.$refs["form"].validate(valid => {
                if (valid) {
                    saveHolidayActivity(this.form).then(() => {
                        this.$notify.success({
                            title: "保存成功"
                        })
                        this.addEditVisible = false
                        this.$emit("saveSuccess")
                    })
                } else {
                    return false
                }
            })
        },

        targetChange() {
            this.form.targetNoArray = []
        },

        // 获取会员标签
        getMemberLabel() {
            labelList({
                pageNum: 0,
                pageSize: 100
            }).then(res => {
                this.memberLabelList = res.data.list
            })
        },

        // 获取会员分组
        getMemberGroup() {
            groupList({
                pageNum: 0,
                pageSize: 100
            }).then(res => {
                this.memberGroupList = res.data.list
            })
        },

        getCouponInfo(info) {
            this.checkedCouponInfo = info
            this.form.prizeNo = info.id
        }
    }
}
</script>

<style lang="scss" scoped>
.tip {
	font-size: 16px;
	border-left: 5px solid #4b5eff;
	padding-left: 8px;
	margin-bottom: 8px;
}

.text-tip {
	color: #a2a2a2;
}
</style>
