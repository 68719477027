<template>
	<div>
		<el-page-header content="详情页面" @back="goBack" />
		<el-form size="small" label-width="100px" :inline="true" class="mt15">
			<el-form-item label="所属供应商">
				<el-select v-model="supplierId" placeholder="所属供应商" clearable filterable>
					<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
		</el-form>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<template slot="productImg" slot-scope="{ row }">
				<div class="flex-row">
					<el-image
						style="min-width: 40px; height: 40px; width: 40px"
						:src="row.productImg + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'"
						:preview-src-list="[row.productImg]"
					/>
					<div style="margin-left: 4px">
						<p>{{ row.prodName }}</p>
					</div>
				</div>
			</template>
		</mvk-table>
	</div>
</template>

<script>
import { getPickDetail } from "api/business/financeManage/pick.js"
import { mapState, mapActions } from "vuex"

export default {
	name: "PickDetail",
	props: {
		queryParam: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			supplierId: "",
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 80
				},
				{
					prop: "orderCode",
					label: "订单编号",
					width: 240
				},
				{
					prop: "productImg",
					label: "商品信息",
					tooltip: true,
					slot: true,
					width: 360
				},
				{
					prop: "skuName",
					label: "商品SKU"
				},
				{
					prop: "supplierName",
					label: "供应商"
				},
				{
					prop: "prodCount",
					label: "商品数量"
				},
				{
					prop: "pickByName",
					label: "核销员"
				},
				{
					prop: "creationDate",
					label: "核销时间 "
				},
				{
					prop: "goodsAmount",
					label: "核销金额 "
				},
				{
					prop: "quality",
					label: "本次核销/剩余 ",
					formatter: row => {
						return row.quality + "/" + row.unQuality
					}
				}
			]
		}
	},
	computed: {
		...mapState("dict", ["supplierlist"]),

		queryParamC() {
			return Object.assign(this.queryParam, {
				supplierId: this.supplierId
			})
		},

		tableDataFunc() {
			let temp = JSON.parse(JSON.stringify(this.queryParamC))
			if (this.queryParam.shopId == 0) {
				return () => {
					return new Promise(resolve => {
						return resolve({
							list: [],
							state: "OK",
							totalElements: 0
						})
					})
				}
			} else {
				return parameter => {
					return getPickDetail(this.queryParam.shopId, Object.assign(parameter, temp)).then(res => {
						return res.data
					})
				}
			}
		}
	},
	mounted() {
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	},

	methods: {
		...mapActions("dict", ["getSupplierList"]),
		goBack() {
			this.$emit("goback")
		}
	}
}
</script>

<style lang="scss" scoped></style>
