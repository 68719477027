<template>
	<div class="mvk-layout-content-card">
		<el-tabs v-model="activeName" @tab-click="changeTab">
			<el-tab-pane label="基本信息" name="base">
				<base-info :info="info" :read-only="readOnly" />
			</el-tab-pane>
			<el-tab-pane label="商品信息" name="goods" :disabled="!id">
				<!-- <base-info /> -->
				<goods-info ref="goods" :read-only="readOnly" />
			</el-tab-pane>
			<el-tab-pane label="会员信息" name="member" :disabled="!id">
				<member-info ref="member" :read-only="readOnly" />
			</el-tab-pane>
			<el-tab-pane label="优惠券" name="coupon" :disabled="!id">
				<coupon-info ref="coupon" :read-only="readOnly" />
			</el-tab-pane>
			<el-tab-pane label="派发使用记录" name="coupon-distribute" :disabled="!id">
				<coupon-distribute ref="couponDistribute" :read-only="readOnly" />
			</el-tab-pane>
			<el-tab-pane label="订单明细" name="orderDetail" :disabled="!id">
				<order-detail ref="orderDetail" :read-only="readOnly" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
export default {
	components: {
		"base-info": () => import("./base.vue"),
		"goods-info": () => import("./goods.vue"),
		"member-info": () => import("./member.vue"),
		"coupon-info": () => import("./coupon.vue"),
		"coupon-distribute": () => import("./coupon-distribute.vue"),
		"order-detail": () => import("./orderDetail.vue")
	},
	data() {
		return {
			activeName: this.$router.history.current.query.activeName || "base",
			info: null,
			readOnly: Boolean(this.$route.query.readOnly)
		}
	},
	computed: {
		id() {
			return this.$route.query.id
		}
	},
	methods: {
		changeTab() {
			const id = this.id
			const params = Object.assign(this.$route.query, { activeName: this.activeName })
			this.$router.push({
				url: this.$route.path,
				query: params
			})
			switch (this.activeName) {
				case "goods":
					id && this.$refs.goods.getList(id)
					break
				case "member":
					id && this.$refs.member.getList(id)
					break
				case "coupon":
					id && this.$refs.coupon.getList(id)
					break
				case "coupon-distribute":
					id && this.$refs.couponDistribute.getList(id)
					break
				default:
					break
			}
		}
	}
}
</script>

<style lang="scss" scoped></style>
