<template>
	<div class="mvk-layout-content-card">
		<div class="add-btn flex-row" style="max-height: 70px">
			<!-- <el-button v-has="'sms:coupon:add'" size="small" type="primary" round @click="handleAdd('')"> -->
			<el-button size="small" type="primary" class="mr10" round @click="handleAdd('')"> + 批量增加 </el-button>
			<el-form ref="form" :model="queryParam" :inline="true" class="ml10 flex-row">
				<el-form-item label="商品名称：" style="min-width: 120px">
					<el-input v-model="queryParam.productName" clearable />
				</el-form-item>
				<el-form-item label="兑换方式：">
					<el-select
						v-model="queryParam.mode"
						placeholder="请选择"
						style="width: 120px"
						clearable
						@change="getList"
					>
						<!-- <el-option label="全部" :value="-1" /> -->
						<el-option label="积分" :value="1" />
						<el-option label="积分+现金" :value="2" disabled />
					</el-select>
				</el-form-item>
				<el-form-item label="状态：">
					<el-select
						v-model="queryParam.status"
						placeholder="请选择"
						style="width: 120px"
						clearable
						@change="getList"
					>
						<el-option label="已上架" :value="true" />
						<el-option label="仓库中" :value="false" />
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<!-- 表格内容 -->
		<el-table :data="list" class="mt15 border-table" border header-cell-class-name="custom-table1">
			<el-table-column type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.sku" stripe>
						<el-table-column prop="skuId" label="SKUID" align="center" width="76" />
						<el-table-column prop="img" label="图片" width="80">
							<template slot-scope="{ row }">
								<el-image
									v-if="row.img"
									style="width: 60px; max-height: 60px"
									fit="contain"
									:src="row.img  + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/60x'"
								/>

								<span v-else>暂无图片</span>
							</template>
						</el-table-column>
						<el-table-column prop="skuName" label="SKU名称" />
						<el-table-column prop="integral" label="兑换条件" width="140">
							<template slot-scope="{ row }">
								{{ row.integral + "积分" }}
								<!-- {{ row.integral+'积分' + '+' + row.price +'元' }} -->
							</template>
						</el-table-column>

						<el-table-column prop="grantStock" label="发放库存" align="center" width="80" />
						<el-table-column prop="modeNum" label="当前兑换" align="center" width="84">
							<template slot-scope="{ row }">
								{{ row.modeNum || 0 }}
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<el-table-column prop="id" label="ID" width="60" />
			<el-table-column prop="productImg" label="产品图" width="80">
				<template slot-scope="{ row }">
					<div v-if="row.productImg" class="image_box">
						<el-image style="width: 60px; max-height: 60px" fit="contain" :src="row.productImg" />
					</div>
					<span v-else>暂无图片</span>
				</template>
			</el-table-column>
			<el-table-column prop="productName" label="商品名称" :show-overflow-tooltip="true" />
			<el-table-column prop="mode" label="兑换方式" width="76">
				<template slot-scope="{ row }">
					{{ row.mode | pointType }}
				</template>
			</el-table-column>
			<el-table-column prop="stock" label="发放总库存数" align="center" width="96" />
			<el-table-column prop="modeNum" label="当前已兑换总数" align="center" width="112">
				<template slot-scope="{ row }">
					{{ row.modeNum || 0 }}
				</template>
			</el-table-column>
			<el-table-column prop="status" label="状态" align="center" width="68">
				<template slot-scope="{ row }">
					{{ row.status ? "已上架" : "仓库中" }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" width="140">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" :disabled="row.status" @click="handleEdit(row)"> 编辑 </el-button>
					<el-button v-if="!row.status" type="text" size="small" @click="handleChange(row)">上架</el-button>
					<delete-button
						v-if="row.status"
						button-type="text"
						size="small"
						button-text="下架"
						delete-text="确认下架吗？"
						@delete="handleChange(row)"
					/>
					<!-- <el-button type="text" size="small" @click="handleDelete(row)"> 删除 </el-button> -->
					<delete-button button-type="text" size="small" @delete="handleDelete(row)" />
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="pagination-contain">
			<el-pagination
				background
				:current-page="queryParam.pageNum"
				:page-size="queryParam.pageSize"
				layout="total,prev, pager, next"
				:total="totalElements"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
			/>
		</div>
		<!-- 抽屉 -->
		<div class="setheight-drawer">
			<add ref="edit" :show.sync="itemEditShow" :title="itemTitle" @getList="getList()" />
		</div>
	</div>
</template>

<script>
import add from "./add"
import * as api from "api/business/marketing/integral.js"
import { debounce } from "utils/utils"
import { pointType } from "@/utils/constants"

export default {
	name: "PointProductMall",
	components: { add },
	filters: {
		pointType(type) {
			return pointType[type]
		}
	},
	data() {
		return {
			// 查询条件参数
			queryParam: {
				pageNum: 1,
				pageSize: 10,
				productName: "",
				mode: "",
				status: ""
			},
			list: [],
			totalElements: 0,

			recordId: "",

			// 内结构
			childColumn: [
				{
					prop: "name",
					label: "状态"
				}
			],
			itemTitle: "新增",
			itemEditShow: false
		}
	},
	watch: {
		queryParam: {
			// 增加防抖
			handler: debounce(function() {
				this.getList()
			}, 300),
			deep: true
		}
	},
	created() {
		this.getList()
	},
	mounted() {},

	methods: {
		getList() {
			const params = JSON.parse(JSON.stringify(this.queryParam))
			params.pageNum--
			api.getPointList(params).then(res => {
				if (res || res.data) {
					res.data.list.forEach(item => {
						item.sku = JSON.parse(item.sku)
					})
				}
				this.list = res.data.list
				this.totalElements = res.data.totalElements
			})
		},
		handleAdd() {
			this.$refs.edit.reset()
			this.itemTitle = "新增"
			this.itemEditShow = true
		},
		// 编辑
		handleEdit(item) {
			// ！！ 新增修改同一个页面，但新增是数组格式，修改是对象格式。造成这个原因是因为新增原本是对象后来因为需求有批量而改成数组
			// 所以需要格式转换成适合数组形式
			item.sku.forEach(e => {
				e.sellingPrice = e.price
			})
			const product = [
				{
					id: item.productId,
					lastModifiedDate: item.lastModifiedDate,
					newcomersSkuList: item.sku,
					img: item.productImg,
					prodName: item.productName,
					stock: item.stock
				}
			]
			this.$refs.edit.params.id = item.id
			this.$refs.edit.params.mode = item.mode
			this.$refs.edit.params.personExchange = item.personExchange
			this.$refs.edit.params.product = product
			this.$refs.edit.params.status = Number(item.status)
			this.$refs.edit.getProductDetail(item.productId)
			this.itemEditShow = true
		},
		// 上下架
		handleChange(row) {
			const params = JSON.parse(JSON.stringify(row))
			params.status = !params.status
			api.postPonit(params).then(res => {
				if (res.status === "OK") {
					this.getList()
				}
			})
		},
		// 删除
		handleDelete(row) {
			api.delPonit(row.id).then(res => {
				if (res.status === "OK") {
					this.getList()
					this.$message.success("删除成功")
				}
			})
		},
		// 每页显示个数
		handleSizeChange(val) {
			this.queryParam.pageSize = val
			this.queryParam.pageNum = 1
			this.getList()
		},
		// 当前页变化
		handleCurrentChange(val) {
			this.queryParam.pageNum = val
			this.getList()
		}
	}
}
</script>

<style lang="scss">
.el-table__expanded-cell[class*="cell"] {
	padding: 0;
}

.setheight-drawer .el-drawer__body {
	height: calc(100% - 60px);
}
.image_box {
	width: 60px;
	height: 60px;
}
</style>
