import fetch from "@/axios/fetch"

export function addLiveRoom(data) { // 新增直播间
    return fetch({
        url: "/liveRoom",
        method: "post",
        data
    })
}

export function editLiveRoom(data) { // 编辑直播间
    return fetch({
        url: "/liveRoom",
        method: "put",
        data
    })
}

export function getLiveRoom(params) { // 直播间列表
    return fetch({
        url: "/liveRoom",
        method: "get",
        params
    })
}

export function syncLiveRoom() { // 同步直播间
    return fetch({
        url: "/liveRoom/sync",
        method: "put"
    })
}

export function deleteLiveRoom(id) { // 删除直播间
    return fetch({
        url: "/liveRoom/" + id,
        method: "delete"
    })
}

export function importProduct(data) { // 导入商品
    return fetch({
        url: "/liveRoom/import",
        method: "post",
        data
    })
}

export function liveRoomDetail(id) { // 直播间详情
    return fetch({
        url: "/liveRoom/" + id,
        method: "get"
    })
}

export function liveRoomProduct(id, params) { // 直播间商品
    return fetch({
        url: "/liveRoom/" + id + "/products",
        method: "get",
        params
    })
}

export function liveRoomProductSelection(id, params) { // 直播间可选商品
    return fetch({
        url: "/liveRoom/" + id + "/product/selection",
        method: "get",
        params
    })
}

export function liveRoomProductDelect(id, productId) { // 删除直播间商品
    return fetch({
        url: "/liveRoom/" + id + "/product/" + productId,
        method: "delete"
    })
}

export function liveRoomProductPush(id, productId) { // 推送直播间商品
    return fetch({
        url: "/liveRoom/" + id + "/push/" + productId,
        method: "put"
    })
}

export function liveRoomOnsale(data) { // 商品上下架
    return fetch({
        url: "/liveRoom/onsale",
        method: "put",
        data
    })
}

export function checkAuth(data) { // 检测主播是否实名认证
    return fetch({
        url: "/liveRole/add",
        method: "post",
        data
    })
}

export function getLiveProduct(params) { // 直播商品列表
    return fetch({
        url: "/liveProduct",
        method: "get",
        params
    })
}

export function addLiveProduct(data) { // 新增直播商品
    return fetch({
        url: "/liveProduct",
        method: "post",
        data
    })
}

export function editLiveProduct(data) { // 新增直播商品
    return fetch({
        url: "/liveProduct",
        method: "put",
        data
    })
}

export function deleteLiveProduct(id) { // 删除直播商品
    return fetch({
        url: "/liveProduct/" + id,
        method: "delete"
    })
}

export function syncLiveProduct() { // 同步直播商品
    return fetch({
        url: "/liveProduct/sync",
        method: "put"
    })
}

export function liveProductDetail(id) { // 直播商品详情
    return fetch({
        url: "/liveProduct/" + id,
        method: "get"
    })
}

export function resetaudit(id) { // 撤回审核
    return fetch({
        url: "/liveProduct/resetaudit/" + id,
        method: "put"
    })
}

export function reaudit(id) { // 重新提审
    return fetch({
        url: "/liveProduct/audit/" + id,
        method: "put"
    })
}

