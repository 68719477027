<template>
    <div class="mvk-layout-content-card">
        <div style="display: flex;margin-bottom:15px;">
            <el-input v-model="queryParam.beanName" placeholder="定时任务名称" clearable style="width:200px;margin-right:10px" @change="handleAddOrEditSave" />
            <!-- <el-button type="primary" round @click="handleSelect">
				查询
			</el-button> -->
            <el-button v-has="'sys:schedule:add'" type="primary" round @click="handleAdd">
                新增
            </el-button>
            <el-button v-has="'sys:schedule:delete'" type="danger" round :disabled="checked.length == 0" @click="handleMulti('delete')">
                批量删除
            </el-button>
            <el-button v-has="'sys:schedule:pause'" type="success" round :disabled="checked.length == 0" @click="handleMulti('pause')">
                批量暂停
            </el-button>
            <el-button v-has="'sys:schedule:resume'" type="success" round :disabled="checked.length == 0" @click="handleMulti('resume')">
                批量恢复
            </el-button>
            <el-button v-has="'sys:schedule:run'" type="success" round :disabled="checked.length == 0" @click="handleMulti('run')">
                批量立即执行
            </el-button>
        </div>
        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" @selection-change="handleChecked">
            <!-- <template slot-scope="{row}" slot="isValid" label="状态">
                <el-switch
                    v-model="row.isValid" @change="handleStatusChange(row.id,row.isValid)">
                </el-switch>
            </template> -->
            <el-table-column
                slot="head"
                type="selection"
                width="55"
            />
            <el-table-column label="操作" width="250">
                <template slot-scope="{row}">
                    <el-button v-has="'sys:schedule:edit'" type="text" size="small" @click="handleEdit(row)">编辑</el-button>
                    <el-button v-if="row.status != 0" v-has="'sys:schedule:pause'" type="text" size="small" @click="handlePause(row.jobId)">暂停</el-button>
                    <el-button v-if="row.status != 1" v-has="'sys:schedule:resume'" type="text" size="small" @click="handleResume(row.jobId)">恢复</el-button>
                    <el-button v-has="'sys:schedule:run'" type="text" size="small" @click="handleRun(row.jobId)">立即执行</el-button>
                    <delete-button :id="row.jobId" v-has="'sys:schedule:delete'" button-type="text" size="small" @delete="handleDelete">删除</delete-button>
                </template>
            </el-table-column>
        </mvk-table>

        <add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
    </div>
</template>

<script>
import { getScheduleList, PauseSchedule, ResumeSchedule, RunSchedule, DelectSchedule } from "api/basePage/schedule"
import { listMixin } from "views/mixin/listMixin"

export default {
    name: "ScheduleIndex",
    components: {
        "add-edit-dialog": () => import("./addEdit.vue")
    },
    mixins: [listMixin],
    data() {
        return {
            tableColumn: [
                {
                    prop: "jobId",
                    label: "ID"
                },
                {
                    prop: "beanName",
                    label: "定时任务名称"
                },
                {
                    prop: "methodName",
                    label: "方法名称"
                },
                {
                    prop: "params",
                    label: "参数"
                },
                {
                    prop: "cronExpression",
                    label: "表达式"
                },
                {
                    prop: "remark",
                    label: "备注"
                },
                {
                    prop: "status",
                    label: "状态",
                    formatter: cellValue => {
                        if (cellValue.status == 1) {
                            return "正常"
                        } else if (cellValue.status == 0) {
                            return "暂停"
                        } else {
                            return cellValue.status
                        }
                    }
                }
            ],

            queryParam: {
                beanName: ""
            },
            tableDataFunc: parameter => {
                return getScheduleList(Object.assign(parameter, this.queryParam))
                    .then(res => {
                        return res.data
                    })
            },

            checked: []
        }
    },

    mounted() {

    },

    methods: {
        handleAddOrEditSave() {
            this.$refs.mvkTable.refresh()
        },

        handleSelect() {
            if (!this.queryParam.beanName) {
                this.$message.error({ message: "bean名称不能为空" })
                return
            }
            this.$refs.mvkTable.refresh()
        },

        handleDelete(data) {
            // console.log(data)
            let params = {
                jobIds: [data.id]
            }

            this.onDelete(params)
        },

        handleMulti(type) {
            let params = {
                jobIds: this.checked.map(item => item.jobId)
            }
            if (type == "delete") {
                this.onDelete(params)
            } else if (type == "pause") {
                this.onPause(params)
            } else if (type == "resume") {
                this.onResume(params)
            } else if (type == "run") {
                this.onRun(params)
            }
        },

        onDelete(params) {
            // console.log(params)
            DelectSchedule(params).then(() => {
                this.$notify.success({
                    title: "成功",
                    message: "删除成功"
                })

                this.$refs.mvkTable.refresh()
            })
        },

        onPause(params) {
            PauseSchedule(params).then(() => {
                this.$notify.success({
                    title: "成功",
                    message: "暂停成功"
                })

                this.$refs.mvkTable.refresh()
            })
        },

        onResume(params) {
            ResumeSchedule(params).then(() => {
                this.$notify.success({
                    title: "成功",
                    message: "恢复成功"
                })

                this.$refs.mvkTable.refresh()
            })
        },

        onRun(params) {
            RunSchedule(params).then(() => {
                this.$notify.success({
                    title: "成功",
                    message: "立即执行成功"
                })

                this.$refs.mvkTable.refresh()
            })
        },

        handlePause(id) {
            let params = {
                jobIds: [id]
            }

            this.onPause(params)
        },

        handleResume(id) {
            let params = {
                jobIds: [id]
            }

            this.onResume(params)
        },

        handleRun(id) {
            let params = {
                jobIds: [id]
            }

            this.onRun(params)
        },

        handleChecked(val) {
            this.checked = val
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
