<template>
	<el-dialog
		class="delivers"
		title="批量发货"
		:visible.sync="show"
		:close-on-click-modal="false"
		top="50px"
		width="75%"
	>
		<el-form ref="form" :model="formData">
			<el-table :data="formData.data">
				<el-table-column prop="code" label="订单号" width="190" show-overflow-tooltip />
				<el-table-column prop="memberName" label="订购用户" width="100" show-overflow-tooltip />
				<el-table-column label="商品信息" width="180">
					<template slot-scope="{ row }">
						<div v-if="row.items && row.items.length > 0" class="flex-row">
							<el-image
								style="min-width: 30px; height: 30px; width: 30px"
								:src="row.items[0].pic"
								:preview-src-list="[row.items[0].pic]"
							/>
							<div style="margin-left: 2px">{{ row.items[0].prodName }}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="数量">
					<template slot-scope="{ row }">
						{{ row.items[0].prodCount }}
					</template>
				</el-table-column>
				<el-table-column prop="type" label="订单状态" width="76">
					<template slot-scope="{ row }">
						{{ orderType[row.type] }}
					</template>
				</el-table-column>
				<el-table-column prop="courier" label="快递公司">
					<template slot-scope="scope">
						<el-form-item
							:rules="[{ required: true, message: '请选择快递公司', trigger: 'blur' }]"
							:prop="'data.' + scope.$index + '.courier'"
						>
							<div v-if="toRender">
								<mvk-select-page
									ref="mvkSelect"
									v-model="scope.row.courier"
									:exp-name="scope.row.courierCompany"
									:list-data-func="listDataFunc"
									filterable
									:init-data="initData"
									:init-total="initTotal"
									autocomplete="new-password"
									:filter-method="filterExpress"
									placeholder="快递公司"
									class="exp-input-page inputWidth"
									clearable
									@clear="clearExpCompay(scope.$index)"
									@change="changeCode($event, scope.$index)"
								/>
							</div>
						</el-form-item>
					</template>
				</el-table-column>
				<el-table-column prop="courierNum" label="发货单号" width="210">
					<template slot-scope="scope">
						<el-form-item
							:rules="[{ required: true, message: '请输入发货号', trigger: 'blur' }]"
							:prop="'data.' + scope.$index + '.courierNum'"
						>
							<el-input v-model="scope.row.courierNum" autocomplete="off" />
						</el-form-item>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="60">
					<template slot-scope="scope">
						<el-button type="text" @click="remove(scope.$index)"> 移除 </el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submit()">新增</el-button>
			<el-button @click="show = false">取消</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { orderType } from "@/utils/constants"
import { getExpressCompyV2 } from "api/business/selfManager/express"
// eslint-disable-next-line no-unused-vars
import { batchShip } from "api/business/selfManager/order"
import { debounce } from "@/utils/utils"

export default {
	// 追加上架数量
	name: "GoodsStockAdd",
	data() {
		return {
			show: false,
			orderType,
			formData: {
				data: []
			},
			defaultExp: null,
			queryExpName: "",
			toRender: true,
			initData: [],
			initTotal: 0
		}
	},
	methods: {
		open(list) {
			this.formData.data = JSON.parse(JSON.stringify(list))
			this.show = true
			this.toRender = false
			this.listDataFunc({ pageNum: 0, pageSize: 10 }).then((res) => {
				this.initData = res.content.map((e) => {
					const value = JSON.stringify({ courierCompany: e.expName, courierCompanyCode: e.expCode })
					const label = e.expName
					return { label, value }
				})
				// 确保数据中不重复
				this.initTotal = res.totalElements
				const expCache = localStorage.getItem("defaultExpress")
				const temp = JSON.parse(expCache)
				if (expCache && !this.initData.some((e) => e.label === temp.courierCompany)) {
					const item = {
						label: temp.courierCompany,
						value: expCache
					}
					this.initData.unshift(item)
				}
				// ------ end ------
				this.toRender = true
			})
			// console.log(this.initData)
		},
		// 获取快递公司列表
		listDataFunc(parameter) {
			return getExpressCompyV2(Object.assign(parameter, { expName: this.queryExpName })).then((res) => {
				let temp = res.data
				return temp
			})
		},
		// 选择快递公司
		changeCode(obj, index, noRefresh) {
			const data = obj ? JSON.parse(obj) : {}
			if (!noRefresh) this.toRender = false
			this.$set(this.formData.data[index], "courier", obj)
			this.formData.data[index].courierCompany = data.courierCompany
			this.formData.data[index].courierCompanyCode = data.courierCompanyCode
			if (!noRefresh) this.toRender = true
		},
		checkSelect(index) {
			this.$refs.form.validateField("data." + index + ".courier")
		},
		clearExpCompay(index) {
			this.formData.data[index].courier = ""
			this.formData.data[index].courierCompany = ""
			this.formData.data[index].courierCompanyCode = ""
		},
		// 防抖搜索
		filterExpress: debounce(function (query) {
			this.queryExpName = query
			this.$refs.mvkSelect.refresh()
		}, 300),
		submit() {
			const data = JSON.parse(JSON.stringify(this.formData.data))
			data.map((e) => {
				delete e.courier
				return e
			})
			this.$refs.form.validate((valid) => {
				if (valid) {
					batchShip({ shipItems: data })
						.then((res) => {
							if (res.status === "OK") {
								this.$notify.success({
									title: "成功",
									message: "发货成功"
								})
								this.show = false
							}
							this.$emit("success")
						})
						.catch((err) => {
							console.log("发货失败", err)
						})
				}
			})
		},
		// 移除项
		remove(index) {
			this.formData.data.splice(index, 1)
			if (this.formData.data.length === 0) this.show = false
		}
	}
}
</script>
<style lang="scss" scoped>
.title {
	padding-left: 10px;
	margin-bottom: 16px;
}
.delivers .el-form-item--small.el-form-item {
	margin-bottom: 0;
}
</style>
