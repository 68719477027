<template>
	<el-select
		ref="selectTree"
		v-model="treeData"
		placeholder="请选择组织搜索"
		style="width: 220px"
		filterable
		:filter-method="searchTreeData"
		clearable
		popper-class="tree-select"
		@clear="change"
	>
		<el-option :value="organizationValue" style="height: auto; width: 100%">
			<el-tree
				ref="tree"
				:data="categoryOptions"
				
				node-key="id"
				empty-text="无匹配数据"
				:props="defaultProps"
				highlight-current
				:filter-node-method="filterNode"
				@node-click="handleNodeClick"
			/>
		</el-option>
	</el-select>
</template>

<script>
import { organitionTree } from "api/basePage/organition"
export default {
	props: {
		value: [String, Number],
		type: {
			type: String,
			default: ""
		}
	},
	model: {
		prop: "value", // 指定 v-model 要绑定的参数叫什么名字，来自于 props 中定义的参数
		event: "change" // 指定要触发的事件名字，将被用于 $emit
	},
	data() {
		return {
			selectList: [],
			organizationValue: null,
			// 分类
			categoryOptions: [],
			// 树型数据
			treeData: "",

			defaultProps: {
				/** 唯一标识 **/
				value: "id",
				/** 标签显示 **/
				label: "name",
				/** 子级 **/
				children: "child"
				/** 是否禁用 **/
				// disabled: function(data, node) {
				// 	if (data.isLeaf == 1) {
				// 		return false
				// 	} else {
				// 		return true
				// 	}
				// }
			}
		}
	},
	watch: {
		value(newv) {
			// console.log(newv)
			this.echoDataSet(newv)
		}
	},
	mounted() {
		this.loadCategoryList()
		this.echoDataSet(this.value)
	},
	methods: {
		searchTreeData(val) {
			this.$refs.tree.filter(val)
		},
		getDataTree() {
			organitionTree({
				type: this.type
			}).then(res => {
				console.log(res)
			})
		},
		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},
		change() {
			this.$emit("change", "")
		},
		// 节点点击事件
		handleNodeClick(data) {
			this.treeData = data.name
			this.organizationValue = data.id
			this.$emit("change", data.id)
			// this.$emit("update:value", data.id)
			// 选择器执行完成后，使其失去焦点隐藏下拉框的效果
			this.$refs.selectTree.blur()
		},
		// 初始化列表
		async loadCategoryList() {
			// type:1(代表公司)，2(代表油站)
			const { status, data } = await organitionTree({
				type: this.type
			})
			this.categoryOptions = data
			if (status != "OK") return
		},
		// 数据集回显
		async echoDataSet(val) {
			this.organizationValue = val || ""
			if (val == "" || val == null) {
				this.$refs.tree.setCurrentKey()
				this.treeData = ""
			} else {
				await this.loadCategoryList()
				this.$refs.tree.setCurrentKey(this.organizationValue) // 设置节点高亮
				// console.log(this.$refs.tree.getCurrentNode())
				this.treeData = this.$refs.tree.getCurrentNode().name
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.el-select-dropdown__item.selected{
	font-weight: 400;
}
</style>

<style>
.tree-select .el-select-dropdown__item {
	background-color: #fff;
	padding: 0 8px;
}
</style>
