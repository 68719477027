import fetch from "@/axios/fetch"

export function laborShop(params) { // 获取通用店铺列表
	return fetch({
		url: "/laborShop",
		method: "get",
		params
	})
}
export function laborShopEnabled(data) { // 是否启用
	return fetch({
		url: "/laborShop/enabled/" + data.id,
		method: "put"
	})
}

export function AddLaborShop(data) { // 新增通用店铺
	return fetch({
		url: "/laborShop",
		method: "post",
		data
	})
}
export function EditLaborShop(data) { // 修改通用店铺
	return fetch({
		url: "/laborShop",
		method: "put",
		data
	})
}
export function LaborShopAddOnShelf(data) { // 追加商品增加库存)
	return fetch({
		url: "/laborShop/addOn/shelf",
		method: "put",
		data
	})
}
export function laborShopDetail(params) { // 获取通用店铺详情
	return fetch({
		url: "/laborShop/" + params.id,
		method: "get"
	})
}
export function getLaborQRCodeDownload(shopId) { // 导出通用店铺二维码
	return fetch({
		url: `laborShop/${shopId}/getQRCode`,
		method: "get",
		responseType: "arraybuffer"
	})
}

// ==============================================================================会员
export function membersList(params) { // 查询会员列表
	return fetch({
		url: `/laborShop/${params.shopId}/members`,
		method: "get",
		params
	})
}

export function addMember(data) { // 新增会员
	return fetch({
		url: "/laborShop/member",
		method: "post",
		data
	})
}

export function getDownload() { // 下载会员导入excel模板
	return fetch({
		url: "/laborShop/template/download",
		method: "get",
		responseType: "arraybuffer"
	})
}
export function getRelationDownload(params, shopId) { // 导出通用店铺关联的会员
	return fetch({
		url: `laborShop/${shopId}/member/export`,
		method: "get",
		responseType: "arraybuffer"
		// headers: {
		//     Accept: "content-type:octet-stream"
		// }
	})
}

export function importMembers(data, shopId) { // 批量导入会员
	return fetch({
		url: `/laborShop/${shopId}/member/upload`,
		method: "put",
		data
	})
}
export function removeMembers(data, shopId) { // 移除会员（批量）
	return fetch({
		url: `/laborShop/${shopId}/remove/member`,
		method: "post",
		data
	})
}
export function disLabourToMember(data, shopId) { // 批量派发通用金
	return fetch({
		url: `/laborShop/${shopId}/members/dis`,
		method: "post",
		data
	})
}
// ==============================================================================产品

export function productsList(params) { // 商品分页查询
	return fetch({
		url: `/laborShop/${params.shopId}/products`,
		method: "get",
		params
	})
}

export function addProducts(data, shopId) { // 添加商品（批量）
	return fetch({
		url: `/laborShop/${shopId}/products/add`,
		method: "post",
		data
	})
}
export function removeProducts(data, shopId) { // 移除商品（批量）
	return fetch({
		url: `/laborShop/${shopId}/products/remove`,
		method: "delete",
		data
	})
}
export function getProductIds(shopId) { // 店铺关联商品
	return fetch({
		url: `/laborShop/${shopId}/productIds`,
		method: "get"
	})
}
export function getRelationGoodsDownload(params) { // 导出通用店铺关联的商品
	return fetch({
		url: `laborShop/${params.shopId}/product/export`,
		method: "get",
		responseType: "arraybuffer",
		params
	})
}
export function setClassifyForGoods(data, shopId) { // 批量设置商品分类
	return fetch({
		url: `/laborShop/${shopId}/products/classify`,
		method: "put",
		data
	})
}

export function laborTop(id) { // 置顶
	return fetch({
		url: `/laborShop/top/${id}`,
		method: "post"
	})
}
// ==============================================================================通用分类

export function getLaborClassifyList(params) { // 分类分页查询
	return fetch({
		url: "/labor/classify",
		method: "get",
		params
	})
}
export function addLaborClassify(data) { // 新增
	return fetch({
		url: "/labor/classify",
		method: "post",
		data
	})
}
export function putLaborClassify(data) { // 新增
	return fetch({
		url: "/labor/classify",
		method: "put",
		data
	})
}
export function laborClassifyEnabled(data) { // 是否启用
	return fetch({
		url: "/labor/classify/enabled/" + data.id,
		method: "put"
	})
}
export function getLaborClassifyDetail(params) { // 查询详情
	return fetch({
		url: "/labor/classify" + params.id,
		method: "get"
	})
}

// ==============================================================================使用记录

export function labourUseList(params, shopId) { // 查询通用金使用记录
	return fetch({
		url: `laborShop/${shopId}/labor/use`,
		method: "get",
		params
	})
}

export function getLabourUseListDownload(params, shopId) { // 导出通用金使用记录
	return fetch({
		url: `laborShop/${shopId}/labor/use/export`,
		method: "get",
		responseType: "arraybuffer"
	})
}
// ==============================================================================派发记录

export function labourDisList(params, shopId) { // 查询通用金派发记录
	return fetch({
		url: `laborShop/${shopId}/labor/dis`,
		method: "get",
		params
	})
}

export function getLabourDisListDownload(params, shopId) { // 导出通用金派发记录
	return fetch({
		url: `laborShop/${shopId}/labor/dis/export`,
		method: "get",
		responseType: "arraybuffer"
	})
}

export function laborShopStatistics(params) { // 查询通用金统计分析
	return fetch({
		url: "laborShop/statistics",
		method: "get",
		params
	})
}

export function getStatisticsExport(params) { // 导出通用金统计
	return fetch({
		url: "laborShop/statistics/export",
		method: "get",
		responseType: "arraybuffer",
		params
	})
}

// ==============================================================================订单明细

export function getOrder(params, shopId) { // 店铺关联订单
	return fetch({
		url: `/laborShop/${shopId}/order`,
		method: "get",
		params
	})
}
export function getOrderExport(params, shopId) { // 店铺关联订单导出
	return fetch({
		url: `/laborShop/${shopId}/export/order`,
		method: "get",
		params,
		responseType: "blob"
	})
}
