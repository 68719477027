<template>
	<div class="mvk-layout-content-card">
		<div class="flex">
			<el-button type="primary" class="mb20" @click="handleAdd"> 新增专题 </el-button>
			<el-form size="small" label-width="150px" :inline="true">
				<el-form-item label="专题名称：">
					<el-input v-model="queryParam.name" clearable />
				</el-form-item>
				<el-form-item label="创建时间：">
					<el-date-picker
						v-model="timeVal"
						:default-time="['00:00:00', '23:59:59']"
						value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd"
						size="small"
						type="daterange"
						placement="bottom-end"
						placeholder="统计时间"
						style="width: 220px"
						@change="onchangeTime"
					/>
				</el-form-item>
			</el-form>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" height="640">
			<template slot="images" slot-scope="{ row }" label="轮播图">
				<el-image
					v-for="(pic, index) in row.imagesArray"
					:key="index"
					style="width: 30px; height: 30px"
					:src="pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="row.imagesArray"
				/>
			</template>
			<template slot="isAvailable" slot-scope="{ row }" label="状态">
				<el-switch v-model="row.isAvailable" @change="handleStatusChange(row)" />
			</template>
			<el-table-column label="操作" width="230" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleEdit(row)"> 编辑 </el-button>
					<delete-button :id="row.id" button-type="text" size="small" @delete="handleDelete">
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit ref="modalForm" @saveSuccess="handleRefresh" />
	</div>
</template>

<script>
/**
 * 专题营销
 */
import { listMixin } from "views/mixin/listMixin"
import { getTopicList, changeTopicStatus, deleteTopic } from "api/business/marketing/topic.js"
import { debounce } from "utils/utils"
export default {
	name: "TopicIndex",
	mixins: [listMixin],
	components: {
		"add-edit": () => import("./addEdit.vue")
	},
	data() {
		return {
			queryParam: {
				name: "",
				startTime: "",
				endTime: "",
				isAvailable: ""
			},
			tableColumn: [
				{ prop: "id", label: "ID" },
				{ prop: "name", label: "专题名称" },
				{ prop: "images", label: "轮播图", slot: true },
				{ prop: "proCount", label: "专题商品数" },
				{ prop: "isAvailable", label: "状态", slot: true },
				{ prop: "creationDate", label: "创建时间" }
			],
			tableDataFunc: parameter => {
				return getTopicList(Object.assign(parameter, this.queryParam)).then(res => {
					res.data.list &&
						res.data.list.forEach(item => {
							item.imagesArray = item.images.split(",")
						})
					return res.data
				})
			},
			timeVal: []
		}
	},
	watch: {
		queryParam: {
			handler: debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},

	mounted() {},

	methods: {
		onchangeTime(e) {
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},

		handleDelete(data) {
			deleteTopic(data.id).then(() => {
				this.$notify.success("删除成功")
				this.$refs.mvkTable.refresh()
			})
		},
		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},

		handleStatusChange(row) {
			changeTopicStatus({
				id: row.id,
				isAvailable: row.isAvailable
			}).then(() => {
				this.$notify.success({
					title: "成功",
					message: "修改成功"
				})
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
