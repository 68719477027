<template>
	<div>
		<div class="flex mt10 flex-sb">
			<div class="flex">
				<router-link :to="{ path: '/marketingManager/labour/list' }" class="mr20">
					<el-button>返回列表</el-button>
				</router-link>
				<div class="filter-box">
					<span>手机号</span>
					<el-input v-model="queryParam.mobile" v-number-input.float="0" placeholder="可输入电话查询" clearable
						class="ml10 w200" maxlength="20"
					/>
				</div>
				<div class="filter-box">
					<span>支付时间</span>
					<el-date-picker v-model="timeVal" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd"
						format="yyyy-MM-dd" size="small" type="daterange" start-placeholder="开始时间"
						end-placeholder="结束时间" placement="bottom-end" style="width: 240px" class="ml10"
						@change="onchangeTime"
					/>
				</div>
			</div>
			<el-button type="primary" @click="handleExport()"> 导出报表</el-button>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<template slot="orderIds" slot-scope="{ row }" label="订单">
				<router-link v-if="row.orderIds && row.orderIds.length"
					:to="{ path: '/selfManager/order', query: { code: row.orderIds[0] } }" class="order-link"
				>
					{{
						row.orderIds[0]
					}}
				</router-link>
				<span v-else> - </span>
			</template>
			<!-- <template slot="termOfValidity" slot-scope="{ row }" label="有效期">
				<span>{{ row.termOfValidity == 1 ? '无限期' : row.termOfValidity }}</span>
			</template> -->
		</mvk-table>
	</div>
</template>

<script>
import * as api from "api/business/selfManager/labour"
import { blobTofile, debounce } from "@/utils/utils"
export default {
	name: "",
	data() {
		return {
			timeVal: [],
			queryParam: {
				payTime: "",
				mobile: ""
			},
			tableColumn: [
				{ prop: "id", label: "ID" },
				{ prop: "memberName", label: "姓名" },
				{ prop: "memberMobile", label: "手机号", width: 110 },
				{
					prop: "orderIds", label: "关联订单号", slot: true, width: 192
				},
				{
					prop: "orderMoney", label: "订单总金额(元)", width: 140, align: "center", formatter: value => {
						return value.orderMoney || value.orderMoney == 0  ? (value.orderMoney || 0) + "元" : "-"
					}
				},
				{
					prop: "laborMoney", label: "通用金支付", width: 140, align: "center", formatter: value => {
						return value.laborMoney  || value.laborMoney == 0 ? (value.laborMoney || 0) + "元" : "-"
					}
				},
				{
					prop: "actualMoney", label: "实际支付", width: 140, align: "center", formatter: value => {
						return value.actualMoney || value.actualMoney == 0  ? (value.actualMoney || 0) + "元" : "-"
					}
				},
				{
					prop: "balance", label: "支付后余额", width: 140, align: "center", formatter: value => {
						return value.balance || value.balance == 0 ? (value.balance || 0) + "元" : "-"
					}
				},
				{ prop: "creationDate", label: "支付时间", width: 140 }

			],

			tableDataFunc: async parameter => {
				if (this.$route.query.id) {
					return api.labourUseList(Object.assign(parameter, this.queryParam), this.$route.query.id).then(res => {
						return res.data
					})
				} else {
					return {}
				}
			}
		}
	},
	watch: {
		queryParam: {
			handler: debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},
	created() {
		if (this.$route.query.time) {
			this.onchangeTime(this.$route.query.time.split("~"))
		}
	},
	methods: {
		getList() {
			this.$refs.mvkTable.refresh()
		},
		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.payTime = e ? e[0] + "~" + e[1] : ""
		},
		handleExport() {
			api.getLabourUseListDownload(this.queryParam, this.$route.query.id).then(res => {
				blobTofile(res, "使用记录.xls", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.filter-box {
	display: flex;
	align-items: center;
	margin-right: 10px;

	span {
		font-size: 14px;
		color: #333;
	}
}

.order-link {
	color: #4B5EFF;
}
</style>