<template>
	<div>
		<div class="flex flex-sb">
			<div v-if="!readOnly" class="flex flex-column-center">
				<el-button type="primary" @click="addData()">新增会员</el-button>
				<el-button @click="detach()">批量移除</el-button>
				<el-upload
					action
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					:http-request="handleUpload"
					:headers="uploadHeaders"
					:show-file-list="false"
				>
					<el-button type="primary" style="margin-left:40px;">批量导入会员</el-button>
				</el-upload>
				<el-button style="margin-left:10px;" @click="toDownloadTemplate()">导入模板下载</el-button>
			</div>
			<router-link :to="{ path: '/keyAccountManager/franchiseStore' }">
				<el-button>返回列表</el-button>
			</router-link>
		</div>
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			class="mt15"
			row-key="cid"
			@selection-change="handleSelectionChange"
		>
			<template slot="enabled" slot-scope="{ row }" label="状态">
				<el-switch v-model="row.enabled" @change="onStartStop(row)" />
			</template>
			<el-table-column
				v-if="!readOnly"
				slot="head"
				type="selection"
				width="55"
			/>
			<el-table-column v-if="!readOnly" label="操作" width="90">
				<template slot-scope="{ row }">
					<el-button
						type="text"
						@click="handleDelete(row)"
					>
						移除
					</el-button>
				</template>
			</el-table-column>
		</mvk-table>
        
		<el-dialog
			title="新增邀请"
			:visible.sync="memberDialog"
			:close-on-click-modal="false"
			top="20vh"
			width="360px"
		>
			<el-form
				ref="memberForm"
				:model="memberForm"
				:rules="memberFormRules"
				label-width="80px"
				:inline="false"
			>
				<el-form-item label="会员姓名" prop="memberName">
					<el-input v-model="memberForm.memberName" placeholder="请输入会员姓名" />
				</el-form-item>
				<el-form-item label="手机号码" prop="memberMobile">
					<el-input v-model="memberForm.memberMobile" placeholder="请输入手机号码" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onAddMemberSubmit('memberForm')">新增</el-button>
					<el-button @click="memberDialog = false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { mobileReg } from "@/utils/constants"
import { blobTofile } from "@/utils/utils"
import { getToken } from "api/auth"

import { membersList, addMember, removeMembers, getDownload, importMembers } from "api/business/selfManager/keyAccount"
const contactValidator = (rule, value, callback) => {
	if (!mobileReg.test(value)) {
		callback("请输入正确的联系号码")
	} else {
		callback()
	}
}
const newMember = () => {
	return {
		memberMobile: "",
		memberName: ""
	}
}
export default {
	props: {
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {

			uploadHeaders: { "X-Token": getToken() },
			list: [],
			tableColumn: [
				{ prop: "id", label: "序号", width: 110 },
				{ prop: "memberName", label: "会员姓名" },
				{ prop: "memberMobile", label: "会员手机号" }
			],

			tableDataFunc: async parameter => {
				if (this.$route.query.id) {
					return membersList(Object.assign(parameter, { shopId: this.$route.query.id })).then(res => {
						return res.data
					})
				} else {
					return await {}
				}
			},
			selectList: [],

			// 新增会员
			memberDialog: false,
			memberForm: newMember(),
			memberFormRules: {
				memberMobile: [
					{
						required: true,
						message: "手机号码",
						trigger: "blur"
					},
					{
						validator: contactValidator
					}
				],
				memberName: [
					{
						required: true,
						message: "会员名称",
						trigger: "blur"
					}
				]
			}
		}
	},
	mounted() {
	},
	methods: {
		getList() {
			this.$refs.mvkTable.refresh()
		},
		addData() {
			this.memberDialog = true
			this.$nextTick(() => {
				this.$refs.memberForm.resetFields()
			})
		},
		// 用于左侧勾选
		tableRowClassName(row) {
			// 设置row对象的index
			row.row.index = row.rowIndex
		},
		// 移除会员
		handleDelete(data) {
			this.removeApi([data.id])
		},
		removeApi(ids) {
			removeMembers({ ids }, this.$route.query.id)
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "删除成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "删除失败"
					})
				})
		},
		// 批量移除
		detach() {
			this.removeApi(this.selectList.map(e => e.id))
		},
		// 改变勾选指集
		handleSelectionChange(val) {
			this.selectList = val
		},

		// 新增会员
		onAddMemberSubmit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					addMember(Object.assign(this.memberForm, { shopId: this.$route.query.id })).then(res => {
						if (res.status === "OK") {
							this.memberDialog = false
							this.$refs.mvkTable.refresh()
							this.$refs.memberForm.resetFields()
						}
					})  
				}
			})
		},
		// 导入模板下载
		toDownloadTemplate() {
			getDownload().then(r => {
				blobTofile(r, "导入模版", "application/vnd.ms-excel")
			})
		},
		// 批量导入会员

		handleUpload(param) {
			const formData = new FormData()
			formData.append("file", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			importMembers(formData, this.$route.query.id)
				.then(() => {
					loading.close()
					this.$message.success("上传成功")
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					loading.close()
				})
		}

	}
}
</script>

<style lang="scss" scoped>

</style>