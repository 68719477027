<template>
	<div class="mvk-layout-content-card">
		<div class="add-btn flex-row" style="max-height: 70px">
			<!-- <el-button v-has="'sms:coupon:add'" size="small" type="primary" round @click="handleAdd('')"> -->
			<el-button size="small" type="primary" round @click="handleAdd('')"> 添加满减活动 </el-button>
			<el-form ref="form" :model="queryParam" :inline="true" class="ml10 flex-row">
				<el-form-item label="活动名称：">
					<el-input v-model="queryParam.activityName" style="width: 120px" placeholder="活动名称" />
				</el-form-item>
				<el-form-item label="时间区间：">
					<el-date-picker
						v-model="timeVal"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						size="small"
						type="daterange"
						placement="bottom-end"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
						style="width: 210px"
						@change="onchangeTime"
					/>
				</el-form-item>
			</el-form>
		</div>
		<!-- <el-tabs v-model="queryParam.status">
            <el-tab-pane v-for="item in statusList" :key="item.value" :label="item.label" :name="item.value" />
        </el-tabs> -->
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt20">
			<!-- <template slot="receiveTime" slot-scope="{ row }" label="领取时间">
                <span v-if="!row.isTime">不限时</span>
                <div v-else-if="row.isTime">
                    {{ row.receiveTime.split(",")[0] }} - {{ row.receiveTime.split(",")[1] }}
                </div>
                <span v-else />
            </template> -->
			<el-table-column prop="tag" label="活动商品" width="130">
				<template slot-scope="scope">
					<span v-if="scope.row.productId == 'all'">全部商品</span>
					<span v-else><a style="color: #4b5eff" @click="openItem(scope.row.productId)">部分商品</a></span>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="活动状态" width="110">
				<template slot-scope="scope">
					<span :style="{ color: scope.row.status === 0 ? '#11c911' : '' }">
						{{ returnStatusText(scope.row.status) }}
					</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="150">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleEdit(row, true)"> 查看 </el-button>
					<el-button type="text" size="small"  @click="handleEdit(row)">
						编辑
					</el-button>
					<delete-button :id="row.id" button-type="text" size="small" @delete="handleDelete">
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>
		<!-- 弹出商品列表 -->
		<el-dialog title="商品列表" :visible.sync="itemShow" width="600" center :close-on-click-modal="false">
			<el-table :data="checkedGoods" border stripe height="500">
				<el-table-column prop="id" label="ID" width="50" />
				<el-table-column prop="prodName" label="商品名称" />
				<el-table-column prop="pic" label="商品图片">
					<template slot-scope="{ row }">
						<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="itemShow = false">关闭</el-button>
			</span>
		</el-dialog>
		<div class="setheight-drawer">
			<add-edit-dialog ref="modalForm" @saveSuccess="selectList" />
		</div>
	</div>
</template>

<script>
// import add from "./add"
import { getFullMinussList, getFullMinussProduct, deleteFullMinuss } from "api/business/marketing/fullminuss.js"
import { listMixin } from "views/mixin/listMixin"

export default {
	name: "PointProductMall",
	mixins: [listMixin], // handleEdit, handleAdd
	components: {
		"add-edit-dialog": () => import("./add.vue")
	},
	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 50
				},
				{
					prop: "activityName",
					label: "活动名称",
					minWidth: 120
				},
				{
					prop: "rangeDate",
					label: "活动时段",
					formatter: (value) => {
						return value.startTime + "~" + value.endTime
					},
					width: 280
				}
			],

			statusList: [
				{
					value: "100",
					label: "全部"
				},
				{
					value: "0",
					label: "进行中"
				},
				{
					value: "1",
					label: "未开始"
				},
				{
					value: "2",
					label: "已结束"
				}
			],
			// 查询条件参数
			queryParam: {
				status: "100",
				activityName: "",
				activityTime: ""
			},
			timeVal: [],

			itemShow: false,
			checkedGoods: []
		}
	},
	computed: {
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))
			if (params.status === "100") delete params.status
			return (parameter) => {
				return getFullMinussList(Object.assign(parameter, params)).then((res) => {
					return res.data
				})
			}
		}
	},
	mounted() {},

	methods: {
		returnStatusText(val) {
			return this.statusList.filter((e) => e.value === val + "")[0].label
		},
		selectList() {
			this.$refs.mvkTable.refresh()
		},

		handleDelete(data) {
			deleteFullMinuss(data.id).then(() => {
				this.$notify.success({
					title: "删除成功"
				})
				this.$refs.mvkTable.refresh()
			})
		},

		onchangeTime(e) {
			this.queryParam.activityTime = (e && e.join(",")) || ""
		},
		// 查看商品
		openItem(productId) {
			this.getProduct(productId)
			this.itemShow = true
		},
		getProduct(productId) {
			getFullMinussProduct({
				ids: productId
			}).then((res) => {
				this.checkedGoods = res.data
			})
		}
	}
}
</script>

<style lang="scss">
.setheight-drawer .el-drawer__body {
	height: calc(100% - 60px);
}
</style>
