<template>
	<mvk-table
		ref="mvkTable"
		:table-column="tableColumn"
		:table-data-func="tableDataFunc"
		class="mt15"
		header-cell-class-name="custom-table1"
	>
		<template slot="prodName" slot-scope="{ row }" label="商品图">
			<div class="flex-row">
				<el-image
					style="min-width: 30px; height: 30px; width: 30px"
					:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="[row.pic]"
				/>
				<div style="margin-left: 4px">{{ row.prodName }}</div>
			</div>
		</template>
		<el-table-column label="操作" width="100" align="center" header-align="center">
			<template slot-scope="{ row }">
				<router-link
					v-has="'pms:product:detail'"
					:to="{ path: '/livePay/creatProduct/' + row.id + '/true/false' }"
					class="link"
				>
					<el-button type="text" size="small">详情</el-button>
				</router-link>
			</template>
		</el-table-column>
	</mvk-table>
</template>

<script>
import * as api from "api/business/selfManager/goods.js"
import { virtualType } from "@/utils/constants"

// 回收站商品
export default {
	name: "RecycleGoods",
	components: {},
	props: {},
	data() {
		return {
			virtualType,
			tableColumn: [
				{ prop: "id", label: "商品ID", width: "80" },
				{ prop: "prodName", label: "商品名称", slot: true, minWidth: 120 },
				{ prop: "virtualType", label: "产品类型", width: "80", align: "center", formatter: row => { return  virtualType.find(e => e.value == row.virtualType)?.label } },
				{ prop: "supplierName", label: "供应商", minWidth: "120" }
			],

			// 查询条件参数
			queryParam: {
				teteProductId: "",
				supplierId: "",
				prodName: "",
				categoryId: "",
				status: "-1"
			},

			tableDataFunc: parameter => {
				return api.virtualStatusList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			}
		}
	}
}
</script>
<style lang="scss">
.link + .link {
	margin-left: 8px;
}

.custom-table1 {
	background-color: #f7f7f7 !important;
}
</style>
