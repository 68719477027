<template>
	<!-- 编辑 -->
	<el-drawer :title="title" :visible.sync="addEditVisible" size="70%" :append-to-body="true">
		<div class="flex-column height100">
			<el-form
				ref="form"
				:model="form"
				:rules="readOnly ? {} : rules"
				class="flex-1"
				label-width="126px"
				style="width: 80%"
			>
				<el-form-item label="活动名称：" prop="activityName">
					<el-input
						v-model="form.activityName"
						placeholder="活动名称"
						class="inputWidth"
						:readonly="readOnly || form.status === 0"
					/>
				</el-form-item>
				<el-form-item label="活动时间：" prop="startTime">
					<el-date-picker
						v-model="datetime"
						type="datetimerange"
						value-format="timestamp"
						:default-time="['00:00:00', '23:59:59']"
						format="yyyy-MM-dd HH:mm:ss"
						:picker-options="pickerOptions"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="dateTimeChange"
						/>
						<!-- :readonly="readOnly || form.status === 0" -->
				</el-form-item>
				<el-form-item label="优惠条件：" prop="termsArray">
					<el-table :data="form.termsArray" style="width: 100%" size="mini" border class="child-form">
						<el-table-column type="index" label="优惠层级" width="76" align="center" />
						<el-table-column label="优惠门槛" align="center">
							<template slot-scope="scope">
								<div class="flex flex-center">
									<span>满</span>
									<span v-if="readOnly || form.status === 0">{{ scope.row.menkan }}</span>
									<el-form-item
										v-else
										:prop="'termsArray.' + scope.$index + '.menkan'"
										:rules="rules.menkan"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.menkan"
											size="small"
											type="number"
											:min="0"
											:step="1"
											style="margin: 0 8px; width: 120px"
										/>
									</el-form-item>

									<span>元</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="优惠方式" align="center">
							<template slot-scope="scope">
								<div class="flex flex-center">
									<span>减</span>
									<span v-if="readOnly || form.status === 0">{{ scope.row.youhui }}</span>
									<el-form-item
										v-else
										:prop="'termsArray.' + scope.$index + '.youhui'"
										:rules="rules.menkan"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.youhui"
											size="small"
											type="number"
											:min="1"
											:step="1"
											style="margin: 0 8px; width: 120px"
										/>
									</el-form-item>

									<span>元</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column v-if="!readOnly && form.status !== 0" label="操作" align="center" width="60">
							<template slot-scope="{ row, $index }">
								<el-button type="text" size="small" @click="delItem(row, $index)"> 删除 </el-button>
							</template>
						</el-table-column>
					</el-table>
					<div>
						<el-button v-if="!readOnly && form.status !== 0" v-show="form.termsArray.length < 3" class="mt10" @click="addItem">
							+ 新增优惠条件
						</el-button>
						<span v-if="!readOnly && form.status !== 0" style="color: red; display: inline-block; margin-left: 8px"
							>注：优惠条件最多设置三个层级</span
						>
					</div>
				</el-form-item>
				<el-form-item v-if="!readOnly" label="参加优惠商品：" prop="productId">
					<el-radio-group v-model="type" @change="typeChange">
						<!-- <el-radio :label="0">全部商品</el-radio> -->
						<el-radio :label="1">部分商品</el-radio>
					</el-radio-group>
					<el-button v-if="type == 1" type="primary" style="margin-left: 8px" @click="openGoodsList">
						选择商品
					</el-button>
				</el-form-item>
				<el-form-item v-if="checkedGoods.length > 0 && type == 1" label="选择的商品：" prop="productId">
					<el-table :data="checkedGoods" border stripe class="child-form">
						<el-table-column prop="id" label="ID" width="50" />
						<el-table-column prop="prodName" label="商品名称" />
						<el-table-column prop="pic" label="商品图片">
							<template slot-scope="{ row }">
								<el-image
									style="width: 30px; height: 30px"
									:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
									:preview-src-list="[row.pic]"
								/>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</el-form>
		</div>

		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button v-if="!readOnly" type="primary" @click="handleSubmit"> 保存 </el-button>
				<el-button v-else @click="close">关闭</el-button>
			</div>
		</div>
		<!--展示仓库中的商品-->
		<goods-list-model
			ref="goodsList"
			:multi="true"
			type="ware"
			:checked="checkedGoods"
			@getGoodsList="getGoodsItem"
		/>
	</el-drawer>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import { formatDate } from "@/utils/validate"
import { saveFullMinuss, putFullMinuss, getFullMinussProduct } from "api/business/marketing/fullminuss.js"
export default {
	components: {
		GoodsListModel
	},
	data() {
		const checkGoodsListValidate = (rule, value, callback) => {
			if (this.checkedGoods.length === 0) {
				return callback(new Error("请选择商品"))
			} else {
				callback()
			}
		}
		return {
			title: "新增",
			addEditVisible: false,
			readOnly: false,
			form: {
				id: "",
				activityName: "",
				startTime: "",
				endTime: "",
				productId: "",
				terms: "",
				termsArray: [
					{
						menkan: 100,
						youhui: 10
					}
				],
				status: null
			},
			rules: {
				activityName: [
					{
						required: true,
						message: "请输入名称",
						trigger: "blur"
					}
				],
				startTime: [
					{
						required: true,
						message: "请选择时间范围",
						trigger: "change"
					}
				],
				termsArray: [
					{
						required: true,
						type: "array",
						message: "请选择优惠条件",
						trigger: "change"
					}
				],
				productId: [
					{
						required: true,
						validator: checkGoodsListValidate,
						trigger: "change"
					}
				]
			},
			datetime: [],
			tableData: [
				{
					menkan: 100,
					youhui: 10
				}
			],
			type: 1,
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},
			checkedGoods: [],
			checkedGoodsColumn: [
				{
					prop: "id",
					label: "ID",
					width: 50
				},
				{
					prop: "prodName",
					label: "商品名称"
				},
				{
					prop: "pic",
					label: "商品图片",
					slot: true
				}
			]
		}
	},
	methods: {
		add() {
			this.addEditVisible = true
			this.$nextTick(() => {
				this.form = {
					id: "",
					activityName: "",
					startTime: "",
					endTime: "",
					productId: "",
					terms: "",
					termsArray: [
						{
							menkan: 100,
							youhui: 10
						}
					]
				}
				this.type = 1
				this.datetime = []
				this.checkedGoods = []
				this.readOnly = false
			    
			})
            setTimeout(() => {
                this.$refs.form.clearValidate()
            }, 1);
		},
		edit(record, readOnly) {
			this.addEditVisible = true
			this.$nextTick(() => {
				this.form = JSON.parse(JSON.stringify(record))
				let timeVal = []
				timeVal.push(Math.round(new Date(this.form.startTime)))
				timeVal.push(Math.round(new Date(this.form.endTime)))
				this.$set(this, "datetime", timeVal)

				// this.form.termsArray = JSON.parse(this.form.terms)
				this.$set(this.form, "termsArray", JSON.parse(this.form.terms))

				if (this.form.productId == "all") {
					this.type = 0
				} else {
					this.type = 1
					this.getProduct()
				}
				this.readOnly = false
				readOnly && (this.readOnly = readOnly)
			    this.$refs.form.clearValidate()
			})
		},
		close() {
			this.addEditVisible = false
		},

		getProduct() {
			getFullMinussProduct({
				ids: this.form.productId
			}).then((res) => {
				this.checkedGoods = res.data
			})
		},

		dateTimeChange(e) {
			this.form.startTime = e ? formatDate(new Date(e[0]), "yyyy-MM-dd HH:mm:ss") : ""
			this.form.endTime = e ? formatDate(new Date(e[1]), "yyyy-MM-dd HH:mm:ss") : ""
		},
		delItem(row, index) {
			this.form.termsArray.splice(index, 1)
		},
		handleSubmit() {
			this.form.terms = JSON.stringify(this.form.termsArray)
			this.$refs["form"].validate((valid) => {
				if (valid) {
					const done = (title) => {
						this.$notify.success({
							title
						})
						this.addEditVisible = false
						this.$emit("saveSuccess")
					}
					if (this.form.id) {
						putFullMinuss(this.form).then(() => {
							done("修改成功")
						})
					} else {
						saveFullMinuss(this.form).then(() => {
							done("新增成功")
						})
					}
				} else {
					return false
				}
			})
		},
		addItem() {
			this.form.termsArray.push({
				menkan: "100",
				youhui: "200"
			})
		},

		getGoodsItem(info) {
			// 已存在的商品不添加
			let checkedProdIds = this.checkedGoods.map((item) => item.id)

			let tempInfo = []
			info &&
				info.forEach((item) => {
					let index = checkedProdIds.indexOf(item.id)
					console.log(index)
					if (index == -1) {
						tempInfo.push(item)
					}
				})

			this.checkedGoods = this.checkedGoods.concat(tempInfo)
			if (this.type == 0) {
				this.form.productId = "all"
			} else {
				this.form.productId = this.checkedGoods.map((item) => item.id).join(",")
			}
			this.$refs.form.validateField("productId")
		},

		typeChange(val) {
			if (val == 1) {
				this.$refs.goodsList.open()
			}
		},
		openGoodsList() {
			this.$refs.goodsList.open()
		}
	}
}
</script>

<style lang="scss" scoped>
.form-style {
	max-width: 900px;
}

.child-form {
	min-width: 500px;
}
</style>
