<template>
    <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
        <el-table-column slot="head" width="55">
            <template slot-scope="scope">
                <el-radio v-model="checkedId" :label="scope.row.id" @change.native="getInfo(scope.row)">
                    <span />
                </el-radio>
            </template>
        </el-table-column>
    </mvk-table>
</template>

<script>
import { getGroupActivityList } from "api/business/marketing/groupActivity"
export default {
    name: "GroupActivityList",
    props: {
        checkedId: {
            type: [Number, String],
            default: 0
        }
    },
    data() {
        return {
            templateRadio: 0,
            statusList: [
                {
                    value: "-1",
                    label: "全部"
                },
                {
                    value: "0",
                    label: "未开始"
                },
                {
                    value: "1",
                    label: "进行中"
                },
                {
                    value: "2",
                    label: "已结束"
                },
                {
                    value: "3",
                    label: "已暂停"
                }
            ],
            tableColumn: [
                {
                    prop: "id",
                    label: "ID",
                    width: "50"
                },
                {
                    prop: "name",
                    label: "团购名称",
                    tooltip: true
                },
                {
                    prop: "rangeDate",
                    label: "团购时间段",
                    formatter: value => {
                        return value.startTime + "~" + value.endTime
                    }
                },
                {
                    prop: "status",
                    label: "活动状态",
                    formatter: value => {
                        return this.statusList.filter(item => Number(item.value) === value.status)[0].label
                    }
                }
            ],
            tableDataFunc: parameter => {
                return getGroupActivityList(Object.assign(parameter)).then(res => {
                    return res.data
                })
            }
        }
    },
    computed: {},
    mounted() {},

    methods: {
        getInfo(info) {
            this.$emit("getInfo", info)
        }
    }
}
</script>

<style lang="scss" scoped></style>
