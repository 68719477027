<template>
	<div>
		<el-form ref="form" :model="form" :rules="rules" label-width="180px" :inline="false">
			<el-form-item label="活动名称" prop="name">
				<el-input v-model="form.name" class="inputWidth" />
			</el-form-item>
			<el-form-item label="活动联系人" prop="contact">
				<el-input v-model="form.contact" class="inputWidth" />
			</el-form-item>
			<el-form-item label="联系人电话" prop="mobile">
				<el-input v-model="form.mobile" class="inputWidth" />
			</el-form-item>
			<el-form-item label="活动有效期" prop="timeVal1">
				<el-date-picker
					v-model="form.timeVal1"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					class="ml10"
					style="width: 230px"
				/>
			</el-form-item>
			<el-form-item label="首页banner配置" prop="imagesArray1">
				<upload-image
					ref="imageDragModel1"
					v-model="form.imagesArray1"
					:image-array="form.imagesArray1"
					:limit="2"
				/>
				<span class="cr_999 fs12">建议像素750*325，大小不超过2M</span>
			</el-form-item>
			<el-form-item label="报名banner配置" prop="imagesArray2">
				<upload-image
					ref="imageDragModel2"
					v-model="form.imagesArray2"
					:image-array="form.imagesArray2"
					:limit="2"
				/>
				<span class="cr_999 fs12">建议像素750*325，大小不超过2M</span>
			</el-form-item>
			<el-form-item label="活动规则" prop="rules">
				<WangEditor
					style="width: 100%"
					:content="form.rules"
					@editorContent="getEditorContent"
				/>
			</el-form-item>
			<el-form-item label="备注" prop="remark">
				<el-input v-model="form.remark" type="textarea" :rows="4" class="inputWidth" />
			</el-form-item>
			<div class="custom-drawer-footer" style="position: static;">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit"> 保存 </el-button>
				</div>
        	</div>
		</el-form>
	</div>
</template>

<script>
import WangEditor from "@/components/wangEditor/index.vue"
import UploadImage from "views/bussiness/components/UploadImage"
import { addWeRunData, updateWeRunData, getWeRunDetail } from "api/business/marketing/weRun"

export default {
	name: "EditWeRun",
	components: {
		WangEditor,
		UploadImage
	},
	props: {
		weRunId: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			form: {
                id: "",
				name: "",
				contact: "",
				mobile: "",
				bannerOne: "",
				bannerTwo: "",
				rules: "",
				remark: "",
				deleted: true,
				endTime: "",
				startTime: "",
				status: 0,
				timeVal1: [],
				imagesArray1: [],
				imagesArray2: [],
            },
			rules:{
				name: [{ required: true, message: "活动名称不能为空", trigger: "blur" }],
				contact: [{ required: true, message: "活动联系人不能为空", trigger: "blur" }],
				mobile: [{ required: true, message: "联系人电话数不能为空", trigger: "blur" }],
				timeVal1: [{ required: true, message: '请选择活动有效期', trigger: 'blur' }],
				imagesArray1: [{ required: true, message: '请上传首页banner配置图片', trigger: 'change' }],
				imagesArray2: [{ required: true, message: '请上传报名banner配置图片', trigger: 'change' }],
				rules: [{ required: true, message: "活动规则不能为空", trigger: "blur" }]
			}
		}
	},
	mounted() {
		if(this.weRunId) {
			this.getDetail()
		}
	},
	watch: {
		weRunId(newVal, oldVal) {
			if(newVal && newVal !== oldVal) {
				this.getDetail()
			} else if(!newVal) {
				this.init()
			}
		}
	},
	methods: {
        init() {
            this.form = {
                id: "",
				name: "",
				contact: "",
				mobile: "",
				bannerOne: "",
				bannerTwo: "",
				rules: "",
				remark: "",
				deleted: true,
				endTime: "",
				startTime: "",
				status: 0,
				timeVal1: [],
				imagesArray1: [],
				imagesArray2: [],
            }
        },
		handleSubmit() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					this.form.bannerOne = this.form.imagesArray1.join(",")
					this.form.bannerTwo = this.form.imagesArray2.join(",")

					this.form.startTime = this.form.timeVal1[0]
					this.form.endTime = this.form.timeVal1[1]
					this.form.id ? updateWeRunData(this.form).then(r => {
						 this.$notify.success({
                            title: "保存成功"
                        })
					}).catch(err => console.log(err))
					:
					addWeRunData(this.form).then(r => {
						this.$notify.success({
                            title: "保存成功"
                        })
						console.log('hhhhh')
						this.$emit("closeDrawer")
					}).catch(err => console.log("err", err))
				}
			})
		},
		getDetail() {
			getWeRunDetail({id: this.weRunId}).then(r => {
				const imagesArray1 = r.data.bannerOne.split(",")
				const imagesArray2 = r.data.bannerTwo.split(",")
				const startTime = r.data.startTime
				const endTime = r.data.endTime
				const timeVal1 = [startTime, endTime]
				this.form = {
					...r.data,
					timeVal1,
					imagesArray1,
					imagesArray2
				}
			}).catch(err => console.log(err))
		},
		getEditorContent(data) {
			this.form.rules = data
		}
	}
}
</script>

<style lang="scss" scoped></style>
