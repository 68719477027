<template>
	<div class="mvk-layout-content-card">
		<!-- <div class="flex"  v-has="'pms:product:virtual_sales'">
			<el-button type="primary" class="mb20" @click="handleBitchEdit"  > 批量更改虚拟销量 </el-button>
			<el-button type="success" class="mb20" :disabled="selectList.length == 0" @click="handleSave">
				批量保存
			</el-button>
		</div> -->
		<el-form size="small" :inline="true">
			<!-- <el-form-item label="排行榜结果是否统计虚拟销量：">
				<el-switch v-model="queryParams.isSumByVirtualSales" />
			</el-form-item> -->
			<el-form-item label="商品名称：" style="min-width: 120px">
				<el-input v-model="queryParams.name" clearable />
			</el-form-item>
		</el-form>
		<el-table
			ref="mvkTable"
			:data="tableData"
			height="620"
			class="border-table"
			row-key="id"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="40" :reserve-selection="true"   v-has="'pms:product:virtual_sales'"/>
			<el-table-column type="index" width="100" label="销量排名" />
			<el-table-column label="商品信息" prop="pic" min-width="120">
				<template slot-scope="{ row }">
					<div class="flex-row">
						<el-image
							style="min-width: 40px; height: 40px; width: 40px"
							:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'"
							:preview-src-list="[row.pic]"
						/>
						<div style="margin-left: 4px">
							<p>{{ row.prodName }}</p>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="商品价格" width="100" prop="price">
				<template slot-scope="{ row }"> ￥ {{ row.price }} </template>
			</el-table-column>
			<el-table-column label="销量" prop="realSales" width="86" />

			<!-- <el-table-column label="虚拟销量" width="230" align="center" header-align="center"  v-has="'pms:product:virtual_sales'" >
				<template slot-scope="{ row }">
					<el-input-number
						v-model="row.virtualSales"
						:min="0"
						:disabled="!row.editFlag"
						@change="handleChangeSale(row)"
					/>
				</template>
			</el-table-column> -->
			<el-table-column label="展示总销量" prop="sales" width="130" />
		</el-table>
	</div>
</template>

<script>
import { goodsForSales } from "api/business/selfManager/goods"
// import { goodsForSales, saveGoodsVirtualSales } from "api/business/selfManager/goods"
import { debounce } from "utils/utils"

export default {
	name: "SalesRankIndex",

	data() {
		return {
			queryParams: {
				sortType: 3,
				shopId: 2,
				name: "",
				isSumByVirtualSales: true,
				pageNum: 0,
				pageSize: 20
			},
			tableData: [],
			selectList: []
		}
	},
	watch: {
		queryParams: {
			handler: debounce(function() {
				this.getTableData()
			}, 300),
			deep: true
		}
	},

	mounted() {
		this.getTableData()
	},

	methods: {
		getTableData() {
			goodsForSales(this.queryParams).then(res => {
				res.data.list &&
					res.data.list.forEach(item => {
						item.editFlag = false
					})
				this.tableData = res.data.list
			})
		},
		handleBitchEdit() {
			this.tableData.forEach(item => {
				item.editFlag = true
			})
		},

		handleSelectionChange(val) {
			this.selectList = val
		},

		// handleChangeSale(row) {
		// 	row.sales = Number(row.realSales) + Number(row.virtualSales)
		// },

	// 	handleSave() {
	// 		// to-do 调保存接口
	// 		saveGoodsVirtualSales({
	// 			virtualSalesReq: this.selectList.map(item => {
	// 				let { id, virtualSales } = item
	// 				return {
	// 					id,
	// 					virtualSales
	// 				}
	// 			})
	// 		}).then(() => {
	// 			// 成功后，刷新数据
	// 			this.getTableData()
	// 		})
	// 	}
	}
}
</script>

<style lang="scss" scoped></style>
