<template>
	<div>
		<el-form size="small" label-width="100px" :inline="true">
			<el-button type="primary" :disabled="tableCheck.length == 0" round @click="handlePriceSetting">
				定价分类设置
			</el-button>
			<el-button type="success" icon="el-icon-refresh" :loading="goodSyncing" circle @click="goodSync(true)" />

			<el-form-item label="商品代码">
				<el-input v-model="queryParam.teteProductId" placeholder="可输入商品代码搜索" clearable />
			</el-form-item>
			<el-form-item label="商品名称">
				<el-input v-model="queryParam.prodName" placeholder="可输入商品名称搜索" clearable />
			</el-form-item>
			<el-form-item label="产品类型">
				<!-- <el-input v-model="queryParam.virtualType" placeholder="输入商品名称搜索" clearable /> -->
				<el-select v-model="queryParam.virtualType" clearable placeholder="可选择产品类型搜索">
					<el-option
						v-for="(item, index) in Object.values(virtualType)"
						:key="index"
						:value="item.value"
						:label="item.label"
					/>
				</el-select>
			</el-form-item>
		</el-form>

		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			class="mt15"
			header-cell-class-name="custom-table1"
			@selection-change="handleSelectionChange"
		>
			<el-table-column slot="head" type="selection" width="55" />
			<el-table-column slot="head" type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.productSkuPageVos" border stripe>
						<el-table-column v-for="col in fixedColumn" :key="col.prop" :prop="col.prop" :label="col.label" :min-width="col.minWidth">
							<template slot-scope="{ row }">
								<div v-if="col.prop == 'skuName'" class="flex-row">
									<el-image
										style="min-width: 30px; height: 30px; width: 30px"
										:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
										:preview-src-list="[row.pic]"
									/>
									<div style="margin-left: 4px">{{ row.skuName }}</div>
								</div>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<template slot="prodName" slot-scope="{ row }" label="商品图">
				<div class="flex-row">
					<el-image
						style="min-width: 30px; height: 30px; width: 30px"
						:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
						:preview-src-list="[row.pic]"
					/>
					<div style="margin-left: 4px">{{ row.prodName }}</div>
				</div>
			</template>
			<template slot="installed" slot-scope="{ row }" label="商品图">
				<div class="is-installed" :class="{'uninstalled':!row.installed}">
					{{ row.installed ? '已入库': '待入库' }}
				</div>
			</template>
			<el-table-column label="操作" width="100" align="center" header-align="center">
				<template slot-scope="{ row }">
					<router-link
						v-has="'pms:product:detail'"
						:to="{ path: '/livePay/creatProduct/' + row.id + '/true/false' }"
						class="link"
					>
						<el-button type="text" size="small">详情</el-button>
					</router-link>
				</template>
			</el-table-column>
		</mvk-table>
		<!--定价-->
		<price-setting ref="pricesettingRef" @saveSuccess="handleRefresh" />
	</div>
</template>

<script>
import * as api from "api/business/selfManager/goods.js"

import PriceSetting from "./priceSetting"

import { commonMixin } from "./mixin"

import { virtualType } from "@/utils/constants"
// 仓库中的商品
export default {
	name: "SettingGoods",
	components: {
		PriceSetting
	},
	mixins: [commonMixin], // 搜索条件中的店铺以及供应商逻辑
	data() {
		return {
			virtualType,
			tableColumn: [
				{ prop: "id", label: "商品ID", width: "80" },
				{ prop: "prodName", label: "商品名称", slot: true, minWidth: 120 },
				{ prop: "virtualType", label: "产品类型", width: "80", align: "center", formatter: row => { return  virtualType.find(e => e.value == row.virtualType)?.label } },
				{ prop: "supplierName", label: "供应商", minWidth: "120" },
				{ prop: "installed", label: "是否入库", width: "80", slot: true }
			],

			queryParam: {
				prodName: "", // 商品名称
				virtualType: "", // 产品类型
				teteProductId: "", // 商品编码
				installed: false
			},

			tableDataFunc: parameter => {
				return api.virtualSearchList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			fixedColumn: [
				{ prop: "teteProductId", label: "商品代码" },
				{ prop: "skuName", label: "SKU名称", minWidth: 160 },
				{ prop: "faceValue", label: "商品面值(元)" },
				{ prop: "costPrice", label: "成本价(元)" },
				{ prop: "marketPrice", label: "市场价(元)" },
				{ prop: "internalPrice", label: "内购价(元)" }
			],

			tableCheck: [],
			goodSyncing: false,
			virtualGoodsSync: sessionStorage.getItem("virtualGoodsSync") // sessionStore
		}
	},
	created() {
		if (!this.virtualGoodsSync) { 
			this.goodSync()
		}
	},
	methods: {
		goodSync(notify) {
			this.goodSyncing = true
			api.virtualSync().then(() => {
				if (notify) { 
					this.$notify.success({
						title: "同步完毕！"
					})
				}
				sessionStorage.setItem("virtualGoodsSync", true)
				this.$refs.mvkTable.refresh()
			}).finally(() => { this.goodSyncing = false })
		},
		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},

		handleSelectionChange(val) {
			this.tableCheck = val
		},

		// 待定价
		handlePriceSetting() {
			this.$refs.pricesettingRef.open(this.tableCheck)
		}
	}
}
</script>
<style lang="scss" scoped>
.link + .link {
	margin-left: 8px;
}

.el-form-item--small.el-form-item {
	margin-bottom: 0;
}

.is-installed{
	padding: 0 8px;
	color:#4B5EFF;
	border: 1px solid #4B5EFF;
	border-radius: 20px;
	text-align: center;
}
.uninstalled{
	color:rgb(224, 145, 0);
	border-color: rgb(224, 145, 0);
}
</style>
