<template>
	<el-drawer
		:title="title"
		:visible.sync="addEditDialog"
		:close-on-click-modal="false"
		size="50%"
		@close="showGoodsList = false"
	>
		<div class="drawer-content">
			<el-form ref="topicForm" :model="topicForm" :rules="topicRules" label-width="150px">
				<el-form-item prop="name" label="专题名称">
					<el-input v-model="topicForm.name" placeholder="请输入专题名称" class="inputWidth" />
				</el-form-item>
				<el-form-item label="专题简介：">
					<el-input
						v-model="topicForm.brief"
						type="textarea"
						maxlength="250"
						:rows="3"
						placeholder="请输入专题简介"
						class="inputWidth"
					/>
				</el-form-item>
				<el-form-item label="轮播图" prop="imagesArray">
					<upload-image
						ref="imageDragModel"
						v-model="topicForm.imagesArray"
						:image-array="topicForm.imagesArray"
						:limit="5"
					/>
					<span class="cr_999 fs12">建议像素800*800，大小不超过2M</span>
				</el-form-item>
				<el-form-item label="选择专题商品" prop="specialProducts">
					<div class="flex flex-column-center">
						<div class="upLoadPicBox" @click="changeGood">
							<div class="upLoad">
								<i class="el-icon-camera cameraIconfont" />
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="选择的商品" size="normal">
					<el-table :data="topicForm.specialProducts" class="mt15 border-table">
						<el-table-column prop="prodId" label="商品ID" width="80" />
						<el-table-column prop="pic" label="商品图">
							<template slot-scope="{ row }">
								<div class="flex-row">
									<el-image
										style="width: 30px; height: 30px"
										:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
										:preview-src-list="[row.pic]"
									/>
									<div style="margin-left: 4px">
										<p>{{ row.prodName }}</p>
									</div>
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="prodName" label="商品名称"> </el-table-column> -->
						<el-table-column prop="supplierName" label="供应商" />
						<el-table-column label="操作" width="80">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="handleRemove(scope.$index)">
									移除
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>

				<el-form-item label="专题状态" prop="isAvailable">
					<el-radio-group v-model="topicForm.isAvailable">
						<el-radio :label="true">开启</el-radio>
						<el-radio :label="false">关闭</el-radio>
					</el-radio-group>
					<p class="cr_999 fs12">开启后banner配置可以选择该专题</p>
				</el-form-item>
			</el-form>
		</div>

		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button v-if="title != '详情'" v-throttle="onSubmit" type="primary" class="form-submit">
					保存
				</el-button>
				<el-button type="text" @click="addEditDialog = false">取消</el-button>
			</div>
		</div>

		<!--展示仓库中的商品-->
		<goods-list-model v-if="showGoodsList" ref="goodsList" supplier type="ware" @getGoodsList="getGoodsList" />
	</el-drawer>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import { getTopicDetail, addTopic, editTopic } from "api/business/marketing/topic.js"

import UploadImage from "views/bussiness/components/UploadImage"

const formDemo = {
	id: "",
	name: "",
	images: "",
	isAvailable: true,
	imagesArray: [],
	specialProducts: []
}

export default {
	name: "TopicAddEdit",
	components: {
		GoodsListModel,
		UploadImage
	},
	data() {
		return {
			title: "新增",
			addEditDialog: false,
			topicForm: Object.assign({}, formDemo),
			topicRules: {
				name: [{ required: true, message: "请输入礼品卡名称", trigger: "blur" }],
				imagesArray: [{ required: true, message: "请选择轮播图", trigger: "change" }],
				specialProducts: [{ required: true, message: "请输入商品", trigger: "change" }]
			},
			showGoodsList: false,
			specialId: ""
		}
	},
	computed: {
		checkProdId() {
			return this.topicForm.specialProducts.map(item => item.prodId)
		}
	},

	mounted() {},

	methods: {
		add() {
			this.specialId = ""
			this.addEditDialog = true
			this.topicForm = JSON.parse(JSON.stringify(formDemo))
			this.showGoodsList = true
		},
		edit(record) {
			this.specialId = record.id
			this.addEditDialog = true
			this.showGoodsList = true
			// this.topicForm = JSON.parse(JSON.stringify(record))
			getTopicDetail(record.id).then(res => {
				this.topicForm = res.data
				let temp = this.topicForm.images.split(",")
				this.$set(this.topicForm, "imagesArray", temp)
			})
		},

		changeGood() {
			this.$refs.goodsList.open()
		},

		// 选择商品
		getGoodsList(goodsInfo) {
			// console.log(goodsInfo)
			let temp = []
			goodsInfo.forEach(item => {
				item.prodId = item.id
				if (this.checkProdId.indexOf(item.id) == -1) {
					temp.push(item)
				}
			})

			this.topicForm.specialProducts = this.topicForm.specialProducts.concat(temp)
		},

		handleRemove(index) {
			this.topicForm.specialProducts.splice(index, 1)
		},

		onSubmit() {
			this.$refs["topicForm"].validate(valid => {
				if (valid) {
					this.topicForm.images = this.topicForm.imagesArray.join(",")

					this.topicForm.specialProducts.forEach(item => {
						item.prodId = item.id || item.prodId
						item.specialId = this.specialId
					})
					this.topicForm.id
						? editTopic(this.topicForm)
							.then(() => {
								this.$notify.success({
									title: "修改成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "修改失败"
								})
							})
						: addTopic(this.topicForm)
							.then(() => {
								this.$notify.success({
									title: "添加成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "添加失败"
								})
							})
				} else {
					return false
				}
			})
		}
	},
	destroyed() {
		this.showGoodsList = false
	}
}
</script>

<style lang="scss" scoped></style>
