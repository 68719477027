import Vue from "vue"

function onInput(el, ele, binding) {
	function handle() {
		let val = ele.value
		// modifiers为修饰符对象，传入了float，则其float属性为true
		if (binding.modifiers.float) {
			// 清除"数字"和"."以外的字符
			val = val.replace(/[^\d.]/g, "")
			// 只保留第一个“点”号, 清除多余的
			const idx = val.indexOf(".")
			if (!(idx === -1 || idx === val.length - 1)) {
				val =
					val.substr(0, idx) +
					"." +
					val.substr(idx + 1).replace(/\./g, "")
			}
		}
		// 第一个字符如果是.号，则补充前缀0
		val = val.replace(/^\./g, "0.")
		if (typeof binding.value !== "undefined") {
			// 期望保留的最大小数位数
			let pointKeep = 0
			if (
				typeof binding.value === "string" ||
				typeof binding.value === "number"
			) {
				pointKeep = parseInt(binding.value)
			}
			if (!isNaN(pointKeep)) {
				if (!Number.isInteger(pointKeep) || pointKeep < 0) {
					pointKeep = 0
				}
				const str = "^(\\d+)\\.(\\d{" + pointKeep + "}).*$"
				const reg = new RegExp(str)
				if (pointKeep === 0) {
					// 不需要小数点
					val = val.replace(reg, "$1")
				} else {
					// 通过正则保留小数点后指定的位数
					val = val.replace(reg, "$1.$2")
				}
			}
		} else {
			val = ele.value.replace(/[^\d]/g, "")
		}
		ele.value = val
	}
	return handle
}
const numberInput = {
	bind(el, binding, vnode) {
		const ele = el.tagName === "INPUT" ? el : el.querySelector("input")
		ele.addEventListener("input", onInput(el, ele, binding, vnode), true)
	}
}

// 限制文本框输入数字 - 支持小数保留位最大位数限制
Vue.directive("number-input", numberInput)

// 按钮权限
Vue.directive("has", {
	inserted: (el, binding) => {
		// console.log(binding.value);
		let permissionList = sessionStorage.getItem("eshop-btnLimit")
		if (!permissionList.includes(binding.value)) {
			el.parentNode.removeChild(el)
		}
	}
})

/**
 * 防抖指令 单位时间只触发最后一次
 * @param {Function} fn - 执行事件
 * @param {?String|"click"} event - 事件类型 例："click"
 * @param {?Number|500} time - 间隔时间
 * @param {Array} binding.value - [fn,event,time]
 * 直接使用： <XXX v-debounce="reset]">刷新</XXX>
 * 配置事件，间隔时间： <button v-debounce="[reset,'click',500]">刷新</button>
 * 事件传递参数则： <button v-debounce="[()=>reset(param),`click`,500]">刷新</button>
 */
Vue.directive("debounce", {
	bind: function(el, binding) {
		try {
			let fn, event = "click", time = "500"
			if (typeof binding.value == "function") {
				fn = binding.value
			} else {
				[fn, event = "click", time = "500"] = binding.value
			}
			let timer
			el.addEventListener(event, () => {
				timer && clearTimeout(timer)
				timer = setTimeout(() => fn(), time)
			})
		} catch (e) {
			console.log(e)
		}
	}
})

/**
 * 节流指令 一段时间内首次触发时立即执行，此时间段内再次触发，不会执行
 * @param {Function} fn - 执行事件
 * @param {?String|"click"} event - 事件类型 例："click"
 * @param {?Number|500} time - 间隔时间
 * @param {Array} binding.value - [fn,event,time]
 * 直接使用： <XXX v-throttle="reset]">刷新</XXX>
 * 配置事件，间隔时间： <button v-throttle="[reset,'click',500]">刷新</button>
 * 事件传递参数则： <button v-throttle="[()=>reset(param),`click`,500]">刷新</button>
 */
Vue.directive("throttle", {
	bind: function(el, binding) {
		let fn, event = "click", time = 2000
		if (typeof binding.value == "function") {
			fn = binding.value
		} else {
			[fn, event = "click", time = 2000] = binding.value
		}

		/**
		 * el.preTime 记录上次触发事件，
		 * 每次触发比较nowTime（当前时间） 和 el.preTime 的差是否大于指定的时间段！
		 */
		el.addEventListener(event, () => {
			const nowTime = new Date().getTime()
			if (!el.preTime || nowTime - el.preTime > time) {
				el.preTime = nowTime
				fn()
			}
		})
	}
})
