<template>
	<div class="mvk-layout-content-card">
		<div style="position: relative">
			<router-link v-has="'pms:product:add'" :to="{ path: '/selfManager/creatProduct' }" class="add">
				<el-button size="small" type="primary" class="mr10" round>添加商品</el-button>
			</router-link>
		</div>

		<el-tabs v-model="status" @tab-click="changeTabs">
			<el-tab-pane v-if="hasBtnPermission('pms:product:uninstalled')" label="待定价归类商品" name="2">
				<setting-goods v-if="status == 2" />
			</el-tab-pane>
			<el-tab-pane v-if="hasBtnPermission('pms:product:warehousesearch')" label="仓库中商品" name="0">
				<ware-goods v-if="status == 0 && hasBtnPermission('pms:product:warehousesearch')" />
			</el-tab-pane>
			<el-tab-pane v-if="hasBtnPermission('pms:product:stationgoods')" label="站级商品池" name="3">
				<station-goods v-if="status == 3" />
			</el-tab-pane>
			<el-tab-pane v-if="hasBtnPermission('pms:product:salesearch')" label="出售中商品" name="1">
				<sale-goods v-if="status == 1 && hasBtnPermission('pms:product:salesearch')" />
			</el-tab-pane>

			<el-tab-pane v-if="hasBtnPermission('pms:product:recyclebin')" label="商品回收站" name="-1">
				<recycle-goods v-if="status == -1 && hasBtnPermission('pms:product:recyclebin')" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import SettingGoods from "./settingGoods.vue"
import SaleGoods from "./saleGoods.vue"
import WareGoods from "./wareGoods.vue"
import StationGoods from "./stationGoods.vue"
import RecycleGoods from "./recycleGoods.vue"
import { permissionMixin } from "views/mixin/permissionMixin"

export default {
	name: "GoodsIndex",
	components: {
		SettingGoods,
		SaleGoods,
		WareGoods,
		StationGoods,
		RecycleGoods
	},
	mixins: [permissionMixin],
	props: {},
	data() {
		return {
			status: "2"
		}
	},
	watch: {},
	computed: {},
	methods: {
		changeTabs(e) {
			this.$store.dispatch('app/cancalRequestAction')
			this.$router.push(this.$route.path + "?status=" + e.name)
		}
	},
	mounted() {
		if (this.$route.query.status !== undefined) {
			this.status = this.$route.query.status
		} else {
			if (this.hasBtnPermission("pms:product:uninstalled")) {
				this.status = "2"
			} else if (this.hasBtnPermission("pms:product:warehousesearch")) {
				this.status = "0"
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.add {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 101;
}
</style>
