<template>
	<div class="container">
		<el-form ref="dataForm" :model="dataForm" :rules="readOnly ? {} :dataRule" label-width="130px" class="form-box">
			<el-form-item label="大客户店铺名称" prop="name">
				<mvk-input v-model.trim="dataForm.name" :readonly="readOnly" placeholder="请输入大客户店铺名称" />
			</el-form-item>
			<el-form-item label="大客户企业名称" prop="companyName">
				<mvk-input v-model.trim="dataForm.companyName" :readonly="readOnly" placeholder="请输入大客户企业名称" />
			</el-form-item>
			<el-form-item label="大客户联系人" prop="shopManagerName">
				<mvk-input v-model.trim="dataForm.shopManagerName" :readonly="readOnly" placeholder="请输入大客户联系人" />
			</el-form-item>
			<el-form-item label="联系人电话" prop="shopManagerMobile">
				<el-input v-model="dataForm.shopManagerMobile" :readonly="readOnly" placeholder="请输入大客户联系人电话" />
			</el-form-item>
			<el-form-item label="大客户企业地址" prop="address">
				<mvk-input v-model.trim="dataForm.address" :readonly="readOnly" placeholder="请输入大客户企业地址" />
			</el-form-item>
			<el-form-item label="大客户销售人员" prop="salesperson">
				<mvk-input v-model.trim="dataForm.salesperson" :readonly="readOnly" placeholder="请输入大客户销售人员名称" />
			</el-form-item>
			<el-form-item label="销售联系电话" prop="salespersonMobile">
				<el-input v-model="dataForm.salespersonMobile" :readonly="readOnly" placeholder="请输入销售人员联系电话" />
			</el-form-item>
			<div style="padding: 0 0 10px 130px; font-size: 12px; color: #999">例如：0757-12345678 或 13012345678</div>
			<el-form-item label="店铺状态" prop="enabled">
				<el-radio-group v-model="dataForm.enabled" :disabled="readOnly">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="店铺照片" prop="pic">
				<upload-image ref="imageModel" v-model="dataForm.facadePhoto" :image-url="dataForm.facadePhoto" :disabled="readOnly" :limit="1" />
				<p style="color: #aaa">图片建议2:1的尺寸，像素大小为：760x380</p>
			</el-form-item>
			<el-form-item>
				<el-button v-if="!readOnly" type="primary" :disabled="absolutely" @click="Submit('dataForm')">保存</el-button>
				
				<el-popconfirm v-if="!absolutely" title="已修改未保存，是否关闭?" @confirm="close()">
					<template #reference>
						<el-button style="margin-left: 10px;">关闭</el-button>
					</template>
				</el-popconfirm>
				<el-button v-else @click="close()">关闭</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mobileReg, telReg } from "@/utils/constants"
const contactValidator = (rule, value, callback) => {
	if (!mobileReg.test(value) && !telReg.test(value)) {
		callback("请输入正确的联系号码")
	} else {
		callback()
	}
}
import { saveMajorShop, putMajorShop, getMajorShopDetail } from "api/business/selfManager/keyAccount"
import {  mapActions } from "vuex"
import UploadImage from "views/bussiness/components/UploadImage"

export default {
	props: {
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	components: {
		UploadImage
	},
	data() {
		return {
			title: "新增",
			loading: false,
			dataForm: this.dataParams(),
			dataRule: {
				address: [{ required: true, message: "请输入大客户企业地址", trigger: "change" }],
				companyName: [{ required: true, message: "请输入大客户企业名称", trigger: "change" }],
				enabled: [{ required: true }],
				name: [{ required: true, message: "请输入大客户店铺名称", trigger: "change" }],
				salesperson: [{ required: true, message: "请输入销售人员", trigger: "change" }],
				salespersonMobile: [
					{ required: true, message: "请输入销售人员联系电话", trigger: "blur" },
					{
						validator: contactValidator
					}
				],
				shopManagerName: [{ required: true, message: "请输入大客户联系人", trigger: "change" }],
				shopManagerMobile: [
					{ required: true, message: "请输入联系人电话", trigger: "change" },
					{
						validator: contactValidator
					}
				]
			},
			menuList: [],
			menuListTreeProps: {
				label: "title",
				children: "children"
			},

			// 底片数据
			original: {}
		}
	},
	mounted() {
		this.$route.query.id && this.getDetail(this.$route.query.id)
		// bus.$on("info", e => {
		//     this.dataForm = e
		//     console.log(this.dataForm)
		//     console.log("e", e)
		// })
	},
	computed: {
		absolutely() {
			return JSON.stringify(this.original) === JSON.stringify(this.dataForm)
		}
	},
	methods: {
		...mapActions("dict", ["getKeyAccountShopList"]),

		dataParams() {
			return {
				id: "",
				companyName: "",
				address: "",
				enabled: true,
				name: "",
				salesperson: "",
				salespersonMobile: "",
				shopManagerMobile: "",
				shopManagerName: ""
			}
		},
		async getDetail(id) {
			await getMajorShopDetail(id).then(res => {
				if (res.status === "OK") {
					this.original = JSON.parse(JSON.stringify(res.data))
					this.dataForm = res.data
				} else this.$router.back()
			})
		},
		add() {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.dataForm = this.dataParams()
			})
		},
		Submit() {
			// this.dataForm = {
			//     name: "中油BP(南海桂澜加油站)",
			//     companyName: "中油碧辟石油有限公司",
			//     address: "广东省佛山市南海区季华东路(九景商务酒店西侧)",
			//     enabled: true,
			//     salesperson: "某先生",
			//     salespersonMobile: "13055556666",
			//     shopManagerMobile: "0757-86260360",
			//     shopManagerName: "某销售"
			// }
			const successSave = r => {
				if (r.status === "OK") {
					this.$router.push({ path: this.$route.path, query: { id: r.data } })
					this.$message.success("保存成功")
				}
			}
			this.$refs.dataForm.validate(valid => {
				if (valid) {
					if (this.dataForm.id) {
						// 修改
						const params = JSON.parse(JSON.stringify(this.dataForm))
						delete params.type
						putMajorShop(params).then(r => successSave(r)).finally(() => {
							this.getKeyAccountShopList()
						})
					} else {
						// 新增
						saveMajorShop(this.dataForm).then(r => successSave(r)).finally(() => {
							this.getKeyAccountShopList()

						})
					}
				}
			})
		},
		close() {
			if (this.dataForm.id) {
				// 如果曾保存过（以路由跳转方式导致多了一层，所以改为路径跳转而不是后退）
				this.$router.push("/keyAccountManager/franchiseStore")
			} else {
				this.$confirm("此操作表格内容不被保存, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						this.$router.back()
					})
					.catch(() => {})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.form-box {
	max-width: 600px;
}
.container {
	margin-top: 20px;
}
</style>
