import fetch from "@/axios/fetch"

export function getFullMinussList(params) { // 满减列表
    return fetch({
        url: "/smsFullMinuss",
        method: "get",
        params
    })
}

export function saveFullMinuss(data) { // 新增满减
    return fetch({
        url: "/smsFullMinuss",
        method: "post",
        data
    })
}
export function putFullMinuss(data) { // 修改满减
    return fetch({
        url: "/smsFullMinuss",
        method: "put",
        data
    })
}

export function getFullMinussProduct(params) { // 满减的商品
    return fetch({
        url: "/smsFullMinuss/product",
        method: "get",
        params
    })
}

export function deleteFullMinuss(id) { // 删除满减
    return fetch({
        url: "/smsFullMinuss/" + id,
        method: "delete"
    })
}

