<template>
	<el-drawer v-loading="loading" :title="title" :visible.sync="addEditDialog" size="75%" :append-to-body="true">
		<div class="">
			<el-form
				ref="dataForm"
				:model="dataForm"
				:rules="rules"
				label-width="180px"
				:inline="false"
				:status-icon="true"
			>
				<el-row :gutter="20">
					<el-col :span="12" :offset="0">
						<el-form-item label="直播类型:" prop="type">
							<el-radio-group v-model="dataForm.type">
								<el-radio :label="0">手机直播</el-radio>
								<!-- <el-radio :label="1">推流直播</el-radio> -->
							</el-radio-group>
						</el-form-item>
						<el-form-item label="直播间标题:" prop="name">
							<el-input
								v-model="dataForm.name"
								style="width:100%;"
								maxlength="17"
								show-word-limit
								clearable
							/>
							<p class="p-tip">最短3个汉字，最长17个汉字</p>
						</el-form-item>
						<el-form-item label="直播间背景图:" prop="coverImg">
							<upload-img-wx
								ref="coverImg"
								v-model="dataForm.coverImg"
								:img-url.sync="dataForm.coverImgUrl"
								upload-tip-text="建议像素800*1600，比例约1:2，大小不超过2M"
								:file-size="2"
								file-size-unit="M"
							/>
						</el-form-item>
						<el-form-item label="直播间分享图片:" prop="shareImg">
							<upload-img-wx
								ref="shareImg"
								v-model="dataForm.shareImg"
								:img-url.sync="dataForm.shareImgUrl"
								upload-tip-text="建议像素800*640，大小不超过1M"
								:file-size="1"
								file-size-unit="M"
							/>
						</el-form-item>
						<el-form-item label="购物直播频道封面图:" prop="feedsImg">
							<upload-img-wx
								ref="feedsImg"
								v-model="dataForm.feedsImg"
								:img-url.sync="dataForm.feedsImgUrl"
								upload-tip-text="建议像素400*560，大小不超过100KB"
								:file-size="100"
								file-size-unit="KB"
							/>
						</el-form-item>
						<el-form-item label="开播开始时间:" prop="startTime">
							<el-date-picker
								v-model="dataForm.startTime"
								type="datetime"
								:picker-options="pickerOptions"
								value-format="yyyy-MM-dd HH:mm:ss"
								placeholder="选择日期"
							/>
							<p class="p-tip">开播时间需要在当前时间的10分钟后 并且 开始时间不能在 6 个月后</p>
						</el-form-item>
						<el-form-item label="开播结束时间:" prop="endTime">
							<el-date-picker
								v-model="dataForm.endTime"
								type="datetime"
								:picker-options="pickerOptions"
								value-format="yyyy-MM-dd HH:mm:ss"
								placeholder="选择日期"
							/>
							<p class="p-tip">开播时间和结束时间间隔不得短于30分钟，不得超过24小时</p>
						</el-form-item>
					</el-col>
					<el-col :span="12" :offset="0">
						<el-form-item label="主播昵称:" prop="anchorName">
							<el-input v-model="dataForm.anchorName" 
								style="width:100%;" maxlength="15" show-word-limit
							/>
							<p class="p-tip">最短2个汉字，最长15个汉字</p>
						</el-form-item>
						<el-form-item label="主播微信号:" prop="anchorWechat">
							<el-input v-model="dataForm.anchorWechat" 
								style="width:100%;"
							/>
							<el-button type="primary" class="ml8" clearable @click="handleAuth(dataForm.anchorWechat)">
								实名认证
							</el-button>
						</el-form-item>
						<el-form-item label="主播副号微信号:" prop="subAnchorWechat">
							<el-input v-model="dataForm.subAnchorWechat" 
								style="width:100%;"
							/>
							<el-button
								type="primary"
								class="ml8"
								clearable
								@click="handleAuth(dataForm.subAnchorWechat)"
							>
								实名认证
							</el-button>
						</el-form-item>
						<el-form-item label="官方收录:" prop="isFeedsPublic">
							<el-switch v-model="dataForm.isFeedsPublic" />
						</el-form-item>
						<el-form-item label="关闭点赞:" prop="closeLike">
							<el-switch v-model="dataForm.closeLike" />
							<span class="span-tip">若关闭，观众端将隐藏点赞按钮，直播开始后不允许开启</span>
						</el-form-item>
						<el-form-item label="关闭商品货架:" prop="closeGoods">
							<el-switch v-model="dataForm.closeGoods" />
							<span class="span-tip">若关闭，观众端将隐藏商品货架，直播开始后不允许开启</span>
						</el-form-item>
						<el-form-item label="关闭评论:" prop="closeComment">
							<el-switch v-model="dataForm.closeComment" />
							<span class="span-tip">若关闭，观众端将隐藏评论入口，直播开始后不允许开启</span>
						</el-form-item>
						<el-form-item label="关闭回放:" prop="closeReplay">
							<el-switch v-model="dataForm.closeReplay" />
							<span class="span-tip">若关闭回放，直播结束后不支持回放</span>
						</el-form-item>
						<el-form-item label="关闭分享:" prop="closeShare">
							<el-switch v-model="dataForm.closeShare" />
							<span class="span-tip">默认开启分享，直播开始后不允许修改</span>
						</el-form-item>
						<el-form-item label="关闭客服:" prop="closeKf">
							<el-switch v-model="dataForm.closeKf" />
							<span class="span-tip">默认开启客服</span>
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item>
					<el-button type="primary" @click="handleSumbit">保存</el-button>
					<el-button @click="addEditDialog = false">取消</el-button>
				</el-form-item>
			</el-form>

			<el-dialog title="实名认证" :visible.sync="authDialog" append-to-body width="470px">
				<span>用户未验证，扫描下方二维码进行身份验证</span>
				<div style="text-align: center" class="mt15">
					<img :src="authImg">
				</div>
			</el-dialog>
		</div>
	</el-drawer>
</template>

<script>
import UploadImgWx from "views/bussiness/components/UploadImgWx.vue"
import { addLiveRoom, checkAuth, editLiveRoom } from "api/business/broadcast/live"
import moment from "moment"

// moment().add(10*60*1000, 'milliseconds').format("YYYY-MM-DD HH:mm:ss")

const dataParams = {
	id: "",
	name: "", // 房间名字
	coverImg: "", // 通过 uploadfile 上传，填写 mediaID
	coverImgUrl: "",
	startTime: "", // 开始时间
	endTime: "", // 结束时间
	anchorName: "", // 主播昵称
	anchorWechat: "", // 主播微信号
	subAnchorWechat: "", // 主播副号微信号
	createrWechat: "", // 创建者微信号
	shareImg: "", // 通过 uploadfile 上传，填写 mediaID
	shareImgUrl: "",
	feedsImg: "", // 通过 uploadfile 上传，填写 mediaID
	feedsImgUrl: "",
	isFeedsPublic: true, // 是否开启官方收录，1 开启，0 关闭
	type: 0, // 直播类型，1 推流 0 手机直播
	closeLike: false, // 是否关闭点赞 1：关闭,0:开启
	closeGoods: false, // 是否关闭商品货架，1：关闭
	closeComment: false, // 是否开启评论，1：关闭
	closeReplay: false, // 是否关闭回放 1 关闭
	closeShare: false, //  是否关闭分享 1 关闭
	closeKf: false // 是否关闭客服，1 关闭
}

export default {
	name: "RoomAddEdit",
	components: {
		UploadImgWx
	},

	directives: {},

	data() {
		return {
			title: "新增",
			addEditDialog: false,
			loading: false,

			dataForm: Object.assign({}, dataParams),

			rules: {
				name: [{ required: true, message: "请输入直播间标题", trigger: ["blur", "change"] }],
				coverImg: [{ required: true, message: "请上传直播间背景图", trigger: "change" }],
				startTime: [
					{ required: true, message: "请输入直播开始时间", trigger: "change" },
					{
						validator: (rule, value, callback) => {
							let current = new Date().getTime()
							let startTime = new Date(value).getTime()

							if (startTime <= current) {
								callback(new Error("开始时间需要在当前时间10分钟之后"))
							}

							let diff = new Date(value).getTime() - current

							if (diff < 10 * 60 * 1000) {
								callback(new Error("开始时间需要在当前时间10分钟之后"))
							} else {
								callback()
							}
						},
						trigger: "change"
					}
				],
				endTime: [
					{ required: true, message: "请输入直播结束时间", trigger: "change" },
					{
						validator: (rule, value, callback) => {
							let startTime = new Date(this.dataForm.startTime).getTime()
							let endTime = new Date(value).getTime()

							if (endTime <= startTime) {
								callback(new Error("结束时间不能早于开始时间"))
							}

							let diff = endTime - startTime

							if (diff < 30 * 60 * 1000) {
								callback(new Error("开播时间和结束时间间隔不得短于30分钟"))
							}

							if (diff > 24 * 60 * 60 * 1000) {
								callback(new Error("开播时间和结束时间间隔不得超过24小时"))
							} else {
								callback()
							}
						},
						trigger: "change"
					}
				],
				anchorName: [{ required: true, message: "请输入主播昵称", trigger: ["blur", "change"] }],
				anchorWechat: [
					{ required: true, message: "请输入主播微信号", trigger: ["blur"] },
					{
						validator: (rule, value, callback) => {
							if (value) {
								checkAuth({
									username: value
								})
									.then(res => {
										if (res.data.errcode != 0) {
											if (res.data.msg == "username invalid") {
												callback(new Error("无效的微信号"))
											} else {
												callback(new Error("该用户还没通过实名认证"))
											}
										} else {
											callback()
										}
									})
									.catch(() => {
										callback(new Error())
									})
							}
						},
						trigger: ["blur"]
					}
				],
				subAnchorWechat: [
					{ required: false },
					{
						validator: (rule, value, callback) => {
							if (value) {
								checkAuth({
									username: value
								})
									.then(res => {
										if (res.data.errcode != 0) {
											if (res.data.msg == "username invalid") {
												callback(new Error("无效的微信号"))
											} else {
												callback(new Error("该用户还没通过实名认证"))
											}
										} else {
											callback()
										}
									})
									.catch(() => {
										callback(new Error())
									})
							} else {
								callback()
							}
						},
						trigger: ["blur"]
					}
				],
				shareImg: [{ required: true, message: "请上传直播间分享图", trigger: "change" }],
				feedsImg: [{ required: true, message: "请上传购物直播频道封面图", trigger: "change" }],
				type: [{ required: true, message: "请选择直播间类型", trigger: "change" }],
				closeLike: [{ required: true, message: "请选择是否关闭点赞", trigger: "change" }],
				closeGoods: [{ required: true, message: "请选择是否关闭货架", trigger: "change" }],
				closeComment: [{ required: true, message: "请选择是否关闭评论", trigger: "change" }]
			},

			pickerOptions: {
				disabledDate(time) {
					return (
						time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24) ||
						time.getTime() > moment().add(6, "months")
					)
				}
			},

			authDialog: false,
			authImg: ""
		}
	},

	mounted() {},

	methods: {
		add() {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.dataForm = JSON.parse(JSON.stringify(dataParams))
			})
		},

		edit(recode) {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.dataForm = JSON.parse(JSON.stringify(recode))
			})
		},

		handleAuth(value) {
			if (!value) {
				this.$notify.error({
					title: "错误",
					message: "请先输入微信号"
				})
				return
			}
			checkAuth({
				username: value
			}).then(res => {
				if (res.data.errcode != 0 && res.data.url) {
					this.authImg = res.data.url
					this.authDialog = true
				}
			})
		},

		handleSumbit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					this.dataForm.id
						? editLiveRoom(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改成功"
								})

								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.loading = false
							})
						: addLiveRoom(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "新增成功"
								})

								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.loading = false
							})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.span-tip {
	display: inline-block;
	margin-left: 8px;
	color: #acacac;
	font-size: 12px;
}

.p-tip {
	color: #acacac;
	font-size: 12px;
}
</style>
