<template>
	<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
		<el-table-column slot="head" width="55">
			<template slot-scope="scope">
				<el-radio v-model="checkedId" :label="scope.row.id" @change.native="getInfo(scope.row)">
					<span />
				</el-radio>
			</template>
		</el-table-column>
		<template slot="pic" slot-scope="{ row }" label="轮播图">
			<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
		</template>
	</mvk-table>
</template>

<script>
import { seckillProductList } from "api/business/marketing/seckill"
export default {
	name: "SeckillList",
	props: {
		checkedId: {
			type: [Number, String],
			default: 0
		}
	},
	data() {
		return {
			templateRadio: 0,
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: "50"
				},
				{
					prop: "seckillName",
					label: "秒杀名称",
					width: "150",
					tooltip: true
				},
				{
					prop: "rangeDate",
					label: "活动期间",
					formatter: value => {
						return value.startTime.substr(0, 10) + "~" + value.endTime.substr(0, 10)
					},
					width: "180"
				},
				{
					prop: "rangeTime",
					label: "秒杀时段",
					width: "100"
				},
				{
					prop: "pic",
					label: "商品图片",
					slot: true
				},
				{
					prop: "title",
					label: "活动标题",
					width: "200",
					tooltip: true
				},
				{
					prop: "brief",
					label: "活动简介",
					width: "200",
					tooltip: true
				},
				{
					prop: "sellingPrice",
					label: "原价"
				},
				{
					prop: "spikePrice",
					label: "秒杀价"
				},
				{
					prop: "limitedStock",
					label: "限量"
				},
				{
					prop: "limitedSurplus",
					label: "限量剩余"
				},
				{
					prop: "stat",
					label: "秒杀状态"
				}
			],

			tableDataFunc: parameter => {
				return seckillProductList(Object.assign(parameter)).then(res => {
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {
		getInfo(info) {
			this.$emit("getInfo", info)
		}
	}
}
</script>

<style lang="scss" scoped></style>
