<template>
	<div class="mvk-layout-content-card">
		<el-form ref="pwForm" :model="pwForm" :rules="pwRules" status-icon :inline-message="true" label-width="150px">
			<el-form-item label="旧密码" prop="oldPassword">
				<el-input
					v-model="pwForm.oldPassword"
					type="password"
					placeholder="请输入旧密码"
					autocomplete="off"
					show-password
					class="inputWidth"
				/>
			</el-form-item>
			<el-form-item label="新密码" prop="newPassword">
				<el-input
					v-model="pwForm.newPassword"
					type="password"
					placeholder="请输入新密码"
					autocomplete="off"
					show-password
					clearable
					class="inputWidth"
					:minlength="8"
				/>
				<p class="cr_999 fs12">密码不少于8位，由数字+字母组成</p>
			</el-form-item>
			<el-form-item label="密码强度">
				<el-progress
					:percentage="percentage"
					:stroke-width="26"
					:color="customColor"
					:format="progressFormat"
					class="inputWidth"
				/>
			</el-form-item>

			<el-form-item label="确认密码" prop="confirmPassword">
				<el-input
					v-model="pwForm.confirmPassword"
					type="password"
					placeholder="请重新输入密码"
					autocomplete="off"
					show-password
					class="inputWidth"
					:minlength="8"
				/>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" class="form-submit" @click="handleSubmit('pwForm')">确认</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { resetPW } from "api/basePage/user.js"

// 高强度：数字+字母+特殊符号，不少于8位字符
const strongRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/

// 中强度：数字+字母（包含大小写），不少于8位字符
const mediumRegex = /^(?=.{8,})((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).*$/

// 必须是数字+字母组合，特殊字符可有可无
const passRegex = /^(?![0-9]+$)(?![a-zA-Z]+$)([0-9A-Za-z\\W].*$)/

export default {
	name: "",
	data() {
		return {
			pwForm: {
				oldPassword: "",
				newPassword: "",
				confirmPassword: ""
			},
			pwRules: {
				oldPassword: [
					{
						required: true,
						trigger: "blur",
						message: "请输入旧密码"
					}
				],
				newPassword: [
					{
						required: true,
						trigger: ["blur", "change"],
						validator: (rule, value, callback) => {
							if (value === "") {
								callback(new Error("请输入新密码"))
							} else if (value.length < 8) {
								callback(new Error("密码长度不少于8位"))
							} else if (!passRegex.test(value)) {
								callback(new Error("密码必须包含数字和字母"))
							} else if (value == this.pwForm.oldPassword) {
								callback(new Error("新密码不能和旧密码相同"))
							} else {
								if (this.pwForm.confirmPassword !== "") {
									this.$refs.pwForm.validateField("confirmPassword")
								} else {
									callback()
								}
							}
						}
					}
				],
				confirmPassword: [
					{
						required: true,
						trigger: "blur",
						validator: (rule, value, callback) => {
							if (value === "") {
								callback(new Error("请再次输入密码"))
							} else if (value !== this.pwForm.newPassword) {
								callback(new Error("两次输入密码不一致!"))
							} else {
								callback()
							}
						}
					}
				]
			},
			percentage: 0,
			format: "",
			customColor: "#f56c6c"
		}
	},
	watch: {
		"pwForm.newPassword"(newValue) {
			this.checkStrong(newValue)
		}
	},
	methods: {
		handleSubmit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					resetPW(this.pwForm)
						.then(res => {
							if (res.status == "BAD_REQUEST") {
								this.$notify.warning({
									title: res.message
								})
							} else {
								this.$notify.success({
									title: "密码修改成功"
								})
								this.$emit("editPwSuccess")
							}
						})
						.catch(() => {
							this.$notify.error({
								title: "密码修改失败"
							})
						})
				} else {
					return false
				}
			})
		},
		// 密码校验
		checkStrong(value) {
			if (strongRegex.test(value)) {
				this.customColor = "#67c23a"
				this.percentage = 100
				this.format = "强"
			} else if (mediumRegex.test(value)) {
				this.customColor = "#e6a23c"
				this.percentage = 66
				this.format = "中"
			} else {
				this.customColor = "#f56c6c"
				this.percentage = 30
				this.format = "弱"
			}
		},

		progressFormat() {
			return this.format
		}
	},
	mounted() {}
}
</script>

<style lang="scss" scoped></style>
