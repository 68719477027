import { randomCode } from "@/utils/utils"
// 步骤1：商品信息的方法
export const step1Method = {
	methods: {
		// 新增商品规格
		addAtrr() {
			if (this.selectAttr == "") return
			if (this.formValidate.checkAttr.indexOf(this.selectAttr) > -1) return
			this.formValidate.checkAttr.push(this.selectAttr)

			this.mulAttr.push({
				attrId: this.selectAttr,
				attrName: this.prodList.filter(item => item.id == this.selectAttr)[0].name,
				skuValue: this.prodList.filter(item => item.id == this.selectAttr).map(item => item.values)[0].map(item => item.name)
			})

			this.changeSkuValue()

			this.$refs.formValidate.validateField("checkAttr")
		},

		// 删除商品规格
		deleteAtrr(index) {
			this.formValidate.checkAttr.splice(index, 1)
			this.mulAttr.splice(index, 1)
			this.changeSkuValue()
		},

		// 商品规格改变后，更新skus
		changeSkuValue() {
			const skuValue = this.attrFormat(this.mulAttr, this.formValidate.skus)
			// this.formValidate.skuValue = skuValue
			this.$set(this.formValidate, "skuValue", skuValue)
		},

		// 添加多规格时，格式化表格值
		attrFormat(arr, skus) {
			if (arr == undefined) return
			let data = []
			const res = []

			let prodName = this.formValidate.prodName

			return format(arr, prodName, skus)

			function format(arr, prodName, skus) {

				if (arr.length > 1) {
					arr.forEach((v, i) => {
						if (i === 0) {
							data = arr[i]["skuValue"]
						}
						const tmp = []
						data.forEach(function(vv) {
							arr[i + 1] && arr[i + 1]["skuValue"] && arr[i + 1]["skuValue"].forEach(g => {
								const rep2 = (i !== 0 ? "" : arr[i]["attrName"] + "_") + vv + "$&" + arr[i + 1]["attrName"] + "_" + g
								tmp.push(rep2)
								if (i === (arr.length - 2)) {

									let properties = {}
									rep2.split("$&").forEach(h => {
										const rep3 = h.split("_")
										properties[rep3[0]] = rep3.length > 1 ? rep3[1] : ""
									})
									let isExit = false

									for (var si = 0; si < skus.length; si++) {
										let tempSku = skus[si]

										for (var pi in Object.values(properties)) {
											if (tempSku["value" + pi] == Object.values(properties)[pi]) {
												isExit = true
											} else {
												isExit = false
												break
											}
										}
										if (isExit) {
											res.push(tempSku)
											break
										}

									}
									if (!isExit) {
										const rep4 = {
											tableKey: randomCode(),
											id: "",
											pic: "",
											sellingPrice: 999,
											costPrice: 999,
											marketPrice: 999,
											internalPrice: 999,
											actualStocks: 0,
											skuName: "",
											weight: 0,
											volume: 0,
											prodName: prodName,
											properties: properties
										}
										// rep2.split('$&').forEach((h, k) => {
										//     const rep3 = h.split('_')
										//     if (!rep4['properties']) rep4['properties'] = {}
										//     rep4['properties'][rep3[0]] = rep3.length > 1 ? rep3[1] : ''
										// })
										let skuName = ""
										Object.values(rep4.properties).forEach((v, i) => {
											rep4["value" + i] = v

											skuName += v + "+"
										})

										if (rep4.skuName == "") {
											rep4.skuName = skuName.substr(0, skuName.length - 1)
										}
										res.push(rep4)
									}
								}
							})
						})
						data = tmp.length ? tmp : []
					})
				} else {
					const dataArr = []
					arr.forEach(v => {
						v["skuValue"].forEach((vv, kk) => {

							// 先复制原有的
							let isExit = false
							let properties = { [v["attrName"]]: vv }
							Object.values(properties).forEach((v, i) => {
								skus.forEach(item => {
									if (item["value" + i] == v) {
										res[kk] = item
										isExit = true
									}
								})
							})

							if (!isExit) {
								dataArr[kk] = v["attrName"] + "_" + vv
								res[kk] = {
									tableKey: randomCode(),
									id: "",
									pic: "",
									sellingPrice: 999,
									costPrice: 999,
									marketPrice: 999,
									internalPrice: 999,
									actualStocks: 0,
									skuName: vv,
									weight: 0,
									volume: 0,
									prodName: prodName,
									properties: { [v["attrName"]]: vv }
								}
								Object.values(res[kk].properties).forEach((v, i) => {
									res[kk]["value" + i] = v
								})
							}

						})
					})
					data.push(dataArr.join("$&"))
				}
				return res
			}
		},

		// 详情的时候直接调用
		attrFormatByDetail() {

		},

		// 删除表格中的属性
		delAttrTable(index) {
			this.formValidate.skuValue.splice(index, 1)
		},

		getImageBase64(value) {
			// this.formValidate.pic = value
			if (this.formValidate.singleProp) {
				this.formValidate.singleSkus[0].pic = value
			}

		},

		getSkuImage(value, index) {
			this.formValidate.skuValue[index].pic = value
		}
	}
}
