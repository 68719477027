<template>
	<el-drawer v-loading="loading" title="出货价设置" :visible.sync="onDrawer" size="50%">
		<div class="drawer-content">
			<el-form ref="goodsform" :model="goodsform" :rules="rules" :inline="false">
				<el-card
					v-for="(goods, index) in goodsform.goodslist"
					:key="index"
					shadow="always"
					:body-style="{ padding: '12px' }"
					class="mt15 custom-card"
				>
					<div slot="header">
						<span>{{ goods.prodName }}</span>
					</div>
					<el-form-item
						label="商品分类："
						:prop="'goodslist.' + index + '.categoryId'"
						:rules="[{ required: true, message: '请设置商品分类', trigger: 'change' }]"
						style="display: flex"
					>
						<prod-category-tree v-model="goods.categoryId" />
					</el-form-item>
					<el-form-item>
						<el-table :data="goods.skus" border stripe>
							<el-table-column label="SKU名称" prop="skuName" />
							<el-table-column label="成本价（元）" prop="costPrice" />
							<el-table-column label="售价（元）" prop="sellingPrice">
								<template slot-scope="scope">
									<el-form-item
										:rules="[{ required: true, message: '请设置SKU的售价', trigger: 'change' }]"
										:prop="'goodslist.' + index + '.skus.' + scope.$index + '.sellingPrice'"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.sellingPrice"
											size="small"
											placeholder="设置售价"
											type="number"
											:min="scope.row.costPrice"
											:precision="2"
											:step="0.01"
											step-strictly
										/>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="市场价（元）" prop="marketPrice">
								<template slot-scope="scope">
									<el-form-item
										:rules="[{ required: true, message: '请设置SKU的市场价', trigger: 'change' }]"
										:prop="'goodslist.' + index + '.skus.' + scope.$index + '.marketPrice'"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.marketPrice"
											size="small"
											placeholder="设置市场价"
											type="number"
											:min="0.01"
											:precision="2"
											:step="0.01"
											step-strictly
										/>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="内购价（元）" prop="internalPrice">
								<template slot-scope="scope">
									<el-form-item
										:rules="[{ required: true, message: '请设置SKU的内购价', trigger: 'change' }]"
										:prop="'goodslist.' + index + '.skus.' + scope.$index + '.internalPrice'"
										style="margin-bottom: 0"
									>
										<el-input-number
											v-model="scope.row.internalPrice"
											size="small"
											placeholder="设置内购价"
											type="number"
											:min="0.01"
											:precision="2"
											:step="0.01"
											step-strictly
										/>
									</el-form-item>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-card>
			</el-form>
		</div>
		<div class="custom-drawer-footer">
			<div class="flex-row" style="justify-content: space-between">
				<el-button type="primary" @click="handleSubmit('goodsform')">设置</el-button>
				<el-button type="text" @click="onDrawer = false">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { goodsPriceSetting } from "api/business/selfManager/goods.js"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"

export default {
	name: "PriceSetting",
	components: { ProdCategoryTree },
	data() {
		return {
			onDrawer: false,
			loading: false,

			goodsform: {
				goodslist: []
			},
			rules: {}
		}
	},
	computed: {},
	watch: {},
	mounted() {},
	methods: {
		open(record) {
			this.onDrawer = true
			this.goodsform.goodslist = JSON.parse(JSON.stringify(record))
			console.log("this.goodsform.goodslist", this.goodsform.goodslist)
		},

		handleSubmit(name) {
			console.log("handleSubmitthis.goodsform.goodslist", this.goodsform.goodslist)
			this.$refs[name].validate(valid => {
				if (valid) {
					let params = {}
					params.products = []

					this.goodsform.goodslist.forEach(item => {
						let temp = {}
						temp.id = item.id
						temp.categoryId = item.categoryId
						temp.skus = []

						item.skus.forEach(sku => {
							let skuJson = {}
							skuJson.id = sku.id
							skuJson.internalPrice = sku.internalPrice
							skuJson.marketPrice = sku.marketPrice
							skuJson.sellingPrice = sku.sellingPrice

							temp.skus.push(skuJson)
						})

						params.products.push(temp)
					})

					// console.log(params);
					this.loading = true
					goodsPriceSetting(params)
						.then(res => {
							if (res) {
								this.$notify.success({
									title: "成功",
									message: "设置成功"
								})
								this.onDrawer = false
								this.$emit("saveSuccess")
							}
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
