<template>
	<!-- 仓库中商品 -->
	<div>
		<div class="flex-row" style="justify-content: space-between">
			<!-- <router-link :to=" { path:'/selfManager/creatProduct' } " v-has="'pms:product:add'">
				<el-button size="small" round type="primary" class="mr10">添加商品</el-button>
			</router-link> -->
			<el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="所属供应商">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
						<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="商品分类">
					<prod-category-tree v-model="queryParam.categoryId" check-strictly />
				</el-form-item>
				<el-form-item label="商品名称">
					<el-input v-model="queryParam.prodName" placeholder="输入商品名称搜索" clearable />
				</el-form-item>
				<el-form-item label="配送方式">
					<el-select
						v-model="queryParam.deliveryMode"
						placeholder="配送方式"
						clearable
						filterable
						style="width: 110px"
					>
						<el-option v-for="(val, key, index) in deliveryMode" :key="index" :label="val" :value="key" />
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<el-button type="primary" :disabled="tableCheck.length == 0" round @click="handleMoveOff"> 批量移除 </el-button>

		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			header-cell-class-name="custom-table1"
			class="mt15"
			@selection-change="handleSelectionChange"
		>
			<el-table-column slot="head" type="selection" width="55" />
			<el-table-column slot="head" type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.skus" border stripe>
						<el-table-column v-for="col in fixedColumn" :key="col.prop" :prop="col.prop" :label="col.label" :width="col.width" :min-width="col.minWidth">
							<template slot-scope="{ row }">
								<div v-if="col.prop == 'skuName'" class="flex-row">
									<el-image
										style="min-width: 30px; height: 30px; width: 30px"
										:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
										:preview-src-list="[row.pic]"
									/>
									<div style="margin-left: 2px">{{ row.skuName }}</div>
								</div>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<template slot="prodName" slot-scope="{ row }" label="商品图">
				<div class="flex-row">
					<el-image
						style="min-width: 30px; height: 30px; width: 30px"
						:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
						:preview-src-list="[row.pic]"
					/>
					<div style="margin-left: 4px">{{ row.prodName }}</div>
				</div>
			</template>
			<template slot="supplierName" slot-scope="{ row }" label="供应商">
				<div v-if="row.source === 0"><span class="source source0">内</span>{{ row.supplierName }}</div>
				<div v-else><span class="source source1">外</span>{{ row.supplierName }}</div>
			</template>
			<el-table-column label="操作" width="230" align="center" header-align="center">
				<template slot-scope="{ row }">
					<!-- <el-button v-if="row.source == 1" type="text" @click="handlePriceSetting(row)">
						定价归类
					</el-button> -->

					<el-button
						v-has="'pms:product:shelf'"
						type="text"
						class="mr10"
						:disabled="isDisableOn(row)"
						@click="handleGoodsOn(row.id, row)"
					>
						上架
					</el-button>

					<router-link
						v-has="'pms:product:detail'"
						:to="{ path: '/selfManager/creatProduct/' + row.id + '/true/false' }"
						class="link"
					>
						<el-button type="text" size="small">详情</el-button>
					</router-link>

					<!-- <router-link
						:to="{ path: '/selfManager/creatProduct/' + row.id }"
						class="link"
						v-has="'pms:product:edit'"
					> -->
					<!-- <el-button v-has="'pms:product:edit'" type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button> -->
					<!-- </router-link> -->

					<!-- <delete-button
						:id="row.id"
						v-has="'pms:product:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					/> -->
				</template>
			</el-table-column>
		</mvk-table>
		<!--上架-->
		<goods-on ref="goodsOnRef" @OnSuccess="searchList" />
		<!--定价-->
		<price-setting ref="pricesettingRef" @saveSuccess="handleRefresh" />
	</div>
</template>

<script>
import { deleteProd, wareProdList, batchMoveOffStationPool } from "api/business/selfManager/goods.js"

import GoodsOn from "./goodsOn.vue"
import PriceSetting from "./priceSetting"

import { commonMixin } from "./mixin"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import { deliveryMode } from "utils/constants"

// 仓库中的商品
export default {
	name: "WareGoods",
	components: {
		GoodsOn,
		PriceSetting,
		ProdCategoryTree
	},
	mixins: [commonMixin], // 搜索条件中的店铺以及供应商索引
	data() {
		return {
			deliveryMode,
			tableColumn: [
				{ prop: "id", label: "商品ID", width: "80" },
				// { prop: "pic", label: "商品图" },
				{ prop: "prodName", label: "商品名称", slot: true },
				{
					prop: "deliveryMode",
					label: "配送方式",
					width: 80,
					align: "center",
					formatter: cellValue => {
						switch (cellValue.deliveryMode) {
							case 0:
								return "邮寄"
							case 1:
								return "自提"
							default:
								return "任意"
						}
					}
				},
				{ prop: "categoryTree", label: "分类" },
				{ prop: "supplierName", label: "供应商", slot: true },
				{
					prop: "source",
					label: "来源",
					align: "center",
					formatter: cellValue => {
						if (cellValue.source === 1) {
							return "云链美物平台"
						} else if (cellValue.source === 2) {
							return "商户平台"
						} else if (cellValue.source === 0) {
							return "自营平台"
						}
					}
				}
				// { prop: "orderNum", label: "排序", width: "80" }
			],

			queryParam: {
				// deliveryMode: 0,
				isLevel: true,
				supplierId: "",
				prodName: "",
				categoryId: "",
				deliveryMode: ""
			},

			tableDataFunc: parameter => {
				return wareProdList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			fixedColumn: [
				{ prop: "id", label: "SKUId" ,width: 86 },
				// { prop: "pic", label: "图片" },
				{ prop: "skuName", label: "SKU名称", minWidth: 280 },
				{ prop: "sellingPrice", label: "售价" },
				{ prop: "costPrice", label: "成本价" },
				{ prop: "marketPrice", label: "市场价" },
				{ prop: "internalPrice", label: "内购价" },
				{ prop: "actualStocks", label: "库存" },
				{ prop: "volume", label: "体积" },
				{ prop: "weight", label: "重量" }
			],
			tableCheck: [] // 选中的商品
		}
	},
	watch: {},
	computed: {},
	methods: {
		handleDelete(data) {
			deleteProd(data.id)
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "删除成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "删除失败"
					})
				})
		},

		handleEdit(row) {
			if (row.source == 1) {
				// 来自供应链的商品
				this.$router.push({ path: "/selfManager/creatProduct/" + row.id + "/true/false/true" })
			} else {
				this.$router.push({ path: "/selfManager/creatProduct/" + row.id })
			}
		},

		handleGoodsOn(id, row) {
			this.$refs.goodsOnRef.open(id, row)
		},

		// 待定价
		handlePriceSetting(row) {
			this.$refs.pricesettingRef.open([row])
		},

		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},
		// 是否可以上架
		isDisableOn(row) {
			if (row.source === 1) {
				return !row.installed
			} else {
				return false
			}
		},

		handleSelectionChange(val) {
			this.tableCheck = val
		},

		handleMoveOff() {
			batchMoveOffStationPool(this.tableCheck.map(item => item.id)).then(() => {
				this.$notify.success({
					title: "移出成功"
				})
				this.handleRefresh()
			})
		}
	},
	created() {},
	mounted() {}
}
</script>
<style lang="scss" scoped>
.link + .link {
	margin-left: 8px;
}

// .el-form-item--small.el-form-item {
// 	margin-bottom: 0;
// }
.source {
	display: inline-block;
	margin-right: 4px;
	line-height: 1;
	border: 1px solid #4f8aff;
	color: #4f8aff;
	padding: 4px;
	border-radius: 50px;
	font-size: 12px;
}
.source0 {
	color: #80b568;
	border-color: #80b568;
}
</style>
