<template>
	<div>
		<el-drawer title="商品列表" :visible.sync="Model" :append-to-body="true" size="50%">
			<div class="height100" style="padding-bottom: 50px">
				<div class="flex">
					<el-form size="small" label-width="150px" :inline="true">
						<el-form-item label="专题名称：">
							<el-input v-model="queryParam.name" />
						</el-form-item>
						<el-form-item label="创建时间：">
							<el-date-picker
								v-model="timeVal"
								:default-time="['00:00:00', '23:59:59']"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd"
								size="small"
								type="daterange"
								placement="bottom-end"
								placeholder="统计时间"
								style="width: 220px"
								@change="onchangeTime"
							/>
						</el-form-item>
					</el-form>
				</div>
				<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" height="640">
					<el-table-column slot="head" width="55">
						<template slot-scope="scope">
							<el-radio v-model="templateRadio" :label="scope.row.id" @change.native="getInfo(scope.row)">
								<span />
							</el-radio>
						</template>
					</el-table-column>
					<template slot="images" slot-scope="{ row }" label="轮播图">
						<el-image
							v-for="(pic, index) in row.imagesArray"
							:key="index"
							style="width: 30px; height: 30px"
							:src="pic  + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
							:preview-src-list="row.imagesArray"
						/>
					</template>
					<template slot="isAvailable" slot-scope="{ row }" label="状态">
						<el-switch v-model="row.isAvailable" disabled />
					</template>
				</mvk-table>
			</div>
			<div class="custom-drawer-footer">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit">确定</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { getTopicList } from "api/business/marketing/topic.js"
export default {
	name: "TopicModel",
	props: {
		checked: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},

	directives: {},

	data() {
		return {
			Model: false,
			queryParam: {
				name: "",
				startTime: "",
				endTime: "",
				isAvailable: true
			},
			tableColumn: [
				{ prop: "id", label: "ID" },
				{ prop: "name", label: "专题名称" },
				{ prop: "images", label: "轮播图", slot: true },
				{ prop: "proCount", label: "专题商品数" },
				{ prop: "isAvailable", label: "状态", slot: true },
				{ prop: "creationDate", label: "创建时间" }
			],
			// tableDataFunc: (parameter) => {
			// 	return getTopicList(Object.assign(parameter, this.queryParam)).then((res) => {
			// 		res.data.list &&
			// 			res.data.list.forEach((item) => {
			// 				item.imagesArray = item.images.split(",")
			// 			})
			// 		return res.data
			// 	})
			// },
			timeVal: [],
			checkedId: "",
			info: "",
			templateRadio: 0
		}
	},
	computed: {
		tableDataFunc() {
			let temp = JSON.parse(JSON.stringify(this.queryParam))
			return parameter => {
				return getTopicList(Object.assign(parameter, temp)).then(res => {
					res.data.list &&
						res.data.list.forEach(item => {
							item.imagesArray = item.images.split(",")
						})
					return res.data
				})
			}
		}
	},
	// watch: {
	// 	queryParam() {
	// 		console.log(1233)
	// 		this.$refs.mvkTable.refresh()
	// 	}
	// },
	methods: {
		open() {
			this.Model = true
		},

		getInfo(info) {
			this.info = info
		},

		handleSubmit() {
			this.$emit("success", this.info)
			this.Model = false
		},
		onchangeTime(e) {
			this.queryParam.startTime = e ? e[0] : ""
			this.queryParam.endTime = e ? e[1] : ""
		},
		handleRefresh() {
			this.$refs.mvkTable.refresh()
		}
	}
}
</script>

<style lang="scss" scoped></style>
