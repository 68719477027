<template>
	<!-- 查询用户电子券 -->
	<div class="mvk-layout-content-card">
		<!-- 筛选 -->
		<el-form size="small" label-width="100px" :inline="true">
			<el-form-item label="电子券标识">
				<el-input v-model="queryParam.voucher" placeholder="可输入电子券标识搜索" size="small" clearable />
			</el-form-item>
			<el-form-item label="券类型">
				<el-select v-model="queryParam.couCategory" placeholder="券类型" clearable>
					<el-option v-for="(item, ikey) in couponCategory" :key="ikey" :label="item" :value="ikey" />
				</el-select>
			</el-form-item>
			<el-form-item label="电子券类型">
				<el-select v-model="queryParam.bizType" placeholder="电子券类型" clearable>
					<el-option v-for="(item, ikey) in bizType" :key="ikey" :label="item" :value="ikey" />
				</el-select>
			</el-form-item>
		</el-form>
		<!-- 切换 -->
		<el-tabs v-model="queryParam.state">
			<el-tab-pane label="未使用" name="10" />
			<el-tab-pane label="已使用" name="20" />
			<el-tab-pane label="临期券" name="30" />
			<el-tab-pane label="已过期" name="40" />
			<el-tab-pane label="已注销" name="50" />
		</el-tabs>

		<!-- 内容 -->

		<!-- 电子券列表 -->
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" />
	</div>
</template>

<script>
import { queryEcouponList } from "api/business/marketing/coupon"
import { voucherStateList, voucherCodeList, voucherTypeList } from "@/utils/constants"

const tableColumnModel = [
	{
		prop: "id",
		label: "ID",
		width: 60
	},
	{
		prop: "voucher",
		label: "电子券标识",
		width: 200
	},
	{
		prop: "alias",
		label: "券类型别名",
		width: 110,
		align: "center"
	},
	{
		prop: "couCategory",
		label: "券类型",
		width: 86,
		align: "center",
		formatter: cellValue => {
			return voucherTypeList[cellValue.couCategory]
		}
	},
	{
		prop: "bizType",
		label: "电子券类型",
		width: 86,
		align: "center",
		formatter: cellValue => {
			return voucherCodeList[cellValue.bizType]
		}
	},
	{
		prop: "faceValue",
		label: "面值金额",
		width: 86,
		align: "center",
		formatter: cellValue => {
			return new Intl.NumberFormat("zh-CN", { style: "currency", currency: "CNY" }).format(cellValue.faceValue)
		}
	},
	{
		prop: "couDiscountValue",
		label: "折扣率",
		width: 86,
		align: "center"
	},
	{
		prop: "state",
		label: "电子券状态",
		width: 86,
		align: "center",
		formatter: cellValue => {
			return voucherStateList[cellValue.state]
		}
	},
	{
		prop: "distrState",
		label: "派发状态",
		width: 86,
		align: "center",
		formatter: cellValue => {
			if (cellValue.distrState == 10) {
				return "未派发"
			} else if (cellValue.distrState == 20) {
				return "已派发"
			}
		}
	},
	{
		prop: "start",
		label: "电子券有效期",
		formatter: row => {
			return row.start + "~" + row.end
		}
	},
	{
		prop: "createTime",
		label: "券生成时间",
		formatter: cellValue => {
			return cellValue.createTime && cellValue.createTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
		}
	}
]

const tableColumnModel_hexiao = tableColumnModel.concat([
	{
		prop: "stationName",
		label: "核销站点名称"
	},
	{
		prop: "usedTime",
		label: "核销时间",
		formatter: cellValue => {
			return cellValue.usedTime && cellValue.usedTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
		}
	}
])

export default {
	data() {
		return {
			queryParam: {
				voucher: "",
				couCategory: "",
				bizType: "",
				state: "10"
			},

			couponCategory: voucherTypeList,
			bizType: voucherCodeList,
			ecouponStateList: voucherStateList,

			tabsValue: 10
		}
	},
	computed: {
		tableColumn() {
			if (this.queryParam.state == "20") {
				return tableColumnModel_hexiao
			} else {
				return tableColumnModel
			}
		},
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))
			params.state = parseInt(params.state)
			return parameter => {
				return queryEcouponList(Object.assign(parameter, params)).then(res => {
					return res.data
				})
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped></style>
