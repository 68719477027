<template>
    <div class="mvk-layout-content-card">
        <div class="add-btn flex-row" style="justify-content: space-between;">
            <el-button v-has="'sms:seckill:add'" type="primary" round @click="handleAdd">
                新增秒杀配置
            </el-button>

            <el-form :model="queryParam" :inline="true">
                <el-form-item label="状态：">
                    <el-select v-model="queryParam.status" placeholder="请选择" clearable @change="selectList">
                        <el-option label="关闭" :value="false" />
                        <el-option label="开启" :value="true" />
                    </el-select>
                </el-form-item>
                <el-form-item label="秒杀名称：">
                    <el-input v-model="queryParam.seckillName">
                        <el-button slot="append" icon="el-icon-search" @click="selectList" />
                    </el-input>
                </el-form-item>
            </el-form>
        </div>

        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
            <template slot="pic" slot-scope="{row}" label="轮播图">
                <el-image
                    v-for="(pic,index) in row.pic"
                    :key="index"
                    style="width:30px;height:30px"
                    :src="pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
                    :preview-src-list="row.pic"
                />
            </template>
            <template slot="status" slot-scope="{row}" label="状态">
                <el-switch v-model="row.status" :disabled="!hasBtnPermission('sms:seckill:updateStatus')" @change="changeStatus(row)" />
            </template>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button v-has="'sms:seckill:edit'" type="text" size="small" class="mr10" @click="handleEdit(row)">编辑</el-button>
                    <router-link v-has="'sms:seckillProduct:add'" :to="{ path:'/marketingManager/seckill/seckillProduct/create/' + row.id}">
                        <el-button type="text" size="small">添加商品</el-button>
                    </router-link>
                    <delete-button :id="row.id" v-has="'sms:seckill:delete'" button-type="text" size="small" @delete="handleDelete">删除</delete-button>
                </template>
            </el-table-column>
        </mvk-table>

        <add-edit-dialog ref="modalForm" @saveSuccess="selectList" />
    </div>
</template>

<script>
import { seckillConfigList, configStatusChange, seckillConfigDelect } from "api/business/marketing/seckill"
import { listMixin } from "views/mixin/listMixin"
import { permissionMixin } from "views/mixin/permissionMixin"

export default {
    name: "SeckillConfig",
    mixins: [listMixin, permissionMixin],
    components: {
        "add-edit-dialog": () => import("./configAddEdit.vue")
    },

    directives: {  },

    data() {
        return {
            tableColumn: [{
                prop: "id",
                label: "ID"
            }, {
                prop: "seckillName",
                label: "秒杀名称"
            }, {
                prop: "rangeTime",
                label: "秒杀时段"
            }, {
                prop: "pic",
                label: "轮播图",
                slot: true
            }, {
                prop: "status",
                label: "状态",
                slot: true
            }, {
                prop: "creationDate",
                label: "创建时间"
            }],

            // 查询条件参数
            queryParam: {
                status: "",
                seckillName: ""
            },

            tableDataFunc: parameter => {
                return seckillConfigList(Object.assign(parameter, this.queryParam))
                    .then(res => {
                        if (res.data.list && res.data.list.length > 0) {
                            res.data.list.forEach(item => {
                                item.pic = item.pic.split(",")
                            })
                        }
                        return res.data
                    })
            }
        }
    },

    mounted() {

    },

    methods: {
        selectList() {
            this.$refs.mvkTable.refresh()
        },

        changeStatus(row) {
            configStatusChange({
                id: row.id,
                status: row.status
            }).then(() => {
                this.$notify.success({
                    title: "成功",
                    message: "修改成功"
                })

                this.selectList()
            })
        },

        handleDelete(data) {
            seckillConfigDelect(data.id).then(() => {
                this.$notify.success("删除成功")
                this.$refs.mvkTable.refresh()
            })
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
