import fetch from "@/axios/fetch"

export function getMessageTemplate(data) { // 获取模板列表
    return fetch({
        url: "/messageTemplate",
        method: "get",
        params: data
    })
}

export function saveMessageTemplate(data) { // 新增消息模板
    return fetch({
        url: "/messageTemplate",
        method: "post",
        data
    })
}
export function putMessageTemplate(data) { // 修改消息模板
    return fetch({
        url: "/messageTemplate",
        method: "put",
        data
    })
}
export function messageTemplateDetail(id) { // 查看详情
    return fetch({
        url: "/messageTemplate/" + id,
        method: "get"
    })
}

export function changeMessageTemplateType(id) { // 修改消息模板
    return fetch({
        url: `/messageTemplate/${id}/enabled`,
        method: "put"
    })
}