import fetch from "@/axios/fetch"

export function getStationList(params) { // 附近油站列表
    return fetch({
        url: "/bmsGss",
        method: "get",
        params
    })
}

export function AddStation(data) {
    return fetch({
        url: "/bmsGss",
        method: "post",
        data
    })
}

export function EditStation(data) {
    return fetch({
        url: "/bmsGss",
        method: "put",
        data
    })
}

export function DeleteStation(id) {
    return fetch({
        url: "/bmsGss/" + id,
        method: "delete"
    })
}
