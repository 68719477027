<template>
	<div>
		<el-form ref="form" :model="formDict" :rules="rules" label-width="80px" :inline="false">
			<el-form-item label="名称" prop="configKey">
				<el-input v-model="formDict.configKey" placeholder="名称" :disabled="formDict.id != ''" />
			</el-form-item>
			<el-form-item label="值" prop="configValue">
				<el-input v-model="formDict.configValue" placeholder="值" />
			</el-form-item>
			<el-form-item label="状态" prop="isValid">
				<el-switch v-model="formDict.isValid" />
			</el-form-item>
			<el-form-item label="备注" prop="remark">
				<el-input v-model="formDict.remark" type="textarea" :rows="2" placeholder="备注" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" v-throttle="onSubmit"> 确定 </el-button>
				<el-button @click="cancle">取消</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { addDictType, editDictType } from "api/basePage/dictionary"
export default {
	name: "AddDictionaryType",
	props: {
		addOrEdit: {
			type: String,
			default: "add"
		}
	},
	directives: {},
	data() {
		return {
			formDict: {
				id: "",
				configKey: "",
				configValue: "",
				remark: "",
				isValid: true
			},
			rules: {
				configKey: {
					required: true,
					message: "请输入名称",
					trigger: "blur"
				},
				configValue: {
					required: true,
					message: "请输入值",
					trigger: "blur"
				}
			}
		}
	},
	mounted() {
		this.formDict = this.$attrs.dictInfo
	},
	methods: {
		onSubmit() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.addOrEdit == "add") {
						addDictType(this.formDict).then(() => {
							this.$notify.success({
								title: "成功",
								message: "添加成功"
							})

							this.$emit("success")
						})
					} else {
						editDictType(this.formDict).then(() => {
							this.$notify.success({
								title: "成功",
								message: "修改成功"
							})

							this.$emit("success")
						})
					}
				} else {
					return false
				}
			})
		},

		cancle() {
			this.$emit("cancle")
		}
	}
}
</script>

<style lang="scss" scoped></style>
