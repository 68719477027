<template>
	<div class="mvk-layout-content-card">
		<!-- 派发页面 -->
		<!-- 筛选 -->
		<el-form size="small" label-width="100px">
			<el-collapse-transition>
				<div v-show="showfilter">
					<div class="filter flex-row flex-wrap">
						<el-form-item label="派发任务名称" class="width100">
							<el-input
								v-model="queryParam.ecouponDistrName"
								placeholder="请输入派发任务名称"
								size="small"
								clearable
							/>
						</el-form-item>
						<el-form-item label="电子券标识" class="width100">
							<el-input
								v-model="queryParam.ecouponVoucher"
								placeholder="请输入电子券标识"
								size="small"
								clearable
							/>
						</el-form-item>
						<el-form-item label="派发手机号" class="width100">
							<el-input
								v-model="queryParam.userPhone"
								placeholder="请输入派发手机号"
								size="small"
								clearable
							/>
						</el-form-item>
						<el-form-item label="电子券状态" class="width100">
							<el-select
								v-model="queryParam.status"
								placeholder="电子券状态"
								clearable
								filterable
								style="width: 100%"
							>
								<el-option
									v-for="(item, ikey) in ecouponStateList"
									:key="ikey"
									:label="item"
									:value="ikey"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="领取会员" class="width100">
							<el-input v-model="queryParam.userName" placeholder="领取会员昵称" size="small" clearable />
						</el-form-item>
					</div>

					<!-- <el-button v-show="showfilter" class="mb20" type="primary" @click="switchFilter()">折叠筛选</el-button> -->
				</div>
			</el-collapse-transition>
		</el-form>
		<!-- 内容 -->
		<mvk-table :table-column="tableColumn" size="mini" class="mt20" :table-data-func="tableDataFunc">
			<template slot="pic" slot-scope="{ row }">
				<div class="flex-row">
					<el-image
						style="min-width: 40px; height: 40px; width: 40px"
						:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'"
						:preview-src-list="[row.pic]"
					/>
					<div style="margin-left: 4px">
						<p>{{ row.userName }}</p>
					</div>
				</div>
			</template>
		</mvk-table>
	</div>
</template>

<script>
import { ecouponRecords } from "api/business/marketing/coupon"

const ecouponMap = {
	0: "已使用",
	1: "未使用",
	"-1": "已过期",
	2: "临期",
	3: "已注销"
}

export default {
	data() {
		return {
			showfilter: true,
			ecouponStateList: ecouponMap,
			timeVal: [],

			// 查询条件参数
			queryParam: {
				ecouponDistrName: "",
				ecouponVoucher: "",
				userPhone: "",
				status: "",
				userName: ""
			},

			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 76
				},
				{
					prop: "pic",
					label: "会员",
					slot: true
				},
				{
					prop: "userPhone",
					label: "派发手机号",
					width: 100
				},
				{
					prop: "ecouponVoucher",
					label: "电子券标识",
					width: 200
				},
				{
					prop: "ecouponAlias",
					label: "电子券别名"
				},
				{
					prop: "ecouponDistrName",
					label: "派发任务名称"
				},
				{
					prop: "ecouponDistrNum",
					label: "发放张数",
					align: "center",
					width: 100
				},

				{
					prop: "ecouponDistrCreateAt",
					label: "派发时间",
					formatter: cellValue => {
						return (
							cellValue.ecouponDistrCreateAt &&
							cellValue.ecouponDistrCreateAt.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
						)
					}
				},
				{
					prop: "ecouponStart",
					label: "电子券开始时间",
					align: "center"
				},
				{
					prop: "ecouponEnd",
					label: "电子券结束时间",
					align: "center"
				},
				{
					prop: "status",
					label: "状态",
					width: 80,
					align: "center",
					formatter: cellValue => {
						return ecouponMap[cellValue.status]
					}
				}
			]
		}
	},
	computed: {
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))
			return parameter => {
				return ecouponRecords(Object.assign(parameter, params)).then(res => {
					return res.data
				})
			}
		}
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
.el-form-item--small.el-form-item {
	margin-top: 18px;
	margin-bottom: 0;
}
.filter .el-form-item {
	width: 280px;
	margin-right: 10px;
}
</style>
