<template>
	<div>
		<div class="flex flex-sb">
			<div v-if="!readOnly" class="flex flex-column-center">
				<el-button type="primary" @click="changeGood">添加商品</el-button>
				<el-button type="primary" @click="batchDelete">批量移除</el-button>
			</div>
			<router-link :to="{ path: '/keyAccountManager/franchiseStore' }">
				<el-button>返回列表</el-button>
			</router-link>
		</div>

		<el-table
			ref="table"
			:data="form.productList"
			class="mt15 border-table"
			:row-class-name="tableRowClassName"
			header-cell-class-name="custom-table1"
		>
			<el-table-column
				v-if="!readOnly"
				type="selection"
				width="55"
			/>
			<el-table-column type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.skus" stripe>
						<el-table-column
							v-for="col in fixedColumn"
							:key="col.prop"
							:prop="col.prop"
							:label="col.label"
							:width="col.width"
						>
							<template slot-scope="{ row }">
								<el-image
									v-if="col.prop == 'pic'"
									style="width: 30px; height: 30px"
									:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
									:preview-src-list="[row.pic]"
								/>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<el-table-column prop="id" label="ID" width="76" />
			<el-table-column prop="pic" label="商品图">
				<template slot-scope="{ row }">
					<el-image style="width: 30px; height: 30px" :src="row.pic" :preview-src-list="[row.pic]" />
				</template>
			</el-table-column>
			<el-table-column prop="prodName" label="商品名称" />
			<el-table-column v-if="!readOnly" label="操作" width="230" align="center" header-align="center">
				<template slot-scope="scope">
					<delete-button
						:index="scope.$index"
						button-type="text"
						size="small"
						@delete="handleProductDelete"
					/>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination-contain">
			<el-pagination
				background
				:current-page.sync="pageNum"
				:page-size="pageSize"
				layout="total,prev, pager, next"
				:total="total"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
			/>
		</div>
		<!--展示仓库中的商品-->
		<goods-list-model
			ref="goodsList"
			:multi="true"
			type="ware"
			:checked="checkedGoods"
			:disabled-product="disabledProduct"
			@getGoodsList="getGoodsItem"
		/>
	</div>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"

import { addProducts, productsList, removeProducts } from "api/business/selfManager/keyAccount"

export default {
	components: { GoodsListModel },
	props: {
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			form: {
				productList: [],
				deleteIds: []
			},
			checkedGoods: [], // 选中产品
			fixedColumn: [
				{ prop: "id", label: "SKUId" },
				{ prop: "pic", label: "图片" },
				{ prop: "skuName", label: "SKU名称" },
				{ prop: "sellingPrice", label: "售价" },
				{ prop: "marketPrice", label: "市场价" },
				{ prop: "actualStocks", label: "库存" },
				{ prop: "volume", label: "体积" },
				{ prop: "weight", label: "重量" }
			],
			deleteIds: [],

			pageNum: 1,
			pageSize: 10,
			total: 0,

			loading: false
		}
	},
	computed: {
		disabledProduct() {
			return this.form.productList.map(e => e.id)
		}
	},
	mounted() {
		this.$route.query.id && this.getList()
	},
	methods: {
		getList() {
			this.form.productList = []
			const params = {
				shopId: this.$route.query.id,
				pageNum: this.pageNum - 1,
				pageSize: this.pageSize
			}
			productsList(params).then(r => {
				if (r.status === "OK") {
					this.form.productList = r.data.list
					this.total = r.data.totalElements
				}
			}).finally(() => {
				this.loading = false
			})
		},
		// 添加打开商品列
		changeGood() {
			this.$refs.goodsList.open()
		},
		// 选择商品
		getGoodsItem(goodsInfo) {
			let checkedProdIds = this.form.productList.map(e => e.id)
			let tempInfo = []
			goodsInfo &&
				goodsInfo.forEach(item => {
					let index = checkedProdIds.indexOf(item.id)
					if (index > -1) {
						if (this.form.productList[index].skus.length == item.skus.length) {
							this.$message.warning(`已经选择了ID为${item.id}的商品,已剔除相同商品`)
							return
						}
					} else {
						tempInfo.push(item)
					}
				})
			if (tempInfo.length === 0) return
			this.updateProduct(tempInfo.map(e => e.id)).then(() => {
				this.getList()
			}).catch(() => {
			})
		},
		async updateProduct(productIds) {
			return await addProducts({ productIds }, this.$route.query.id).then(res => {
				if (res.status === "OK") return true
				else return false
			})
		},
		// 用于左侧勾选
		tableRowClassName(row) {
			// 设置row对象的index
			row.row.index = row.rowIndex
		},
		// 商品删除
		handleProductDelete(data) {
			const  id = this.form.productList[data.index].prodId
			removeProducts({ productIds: [id] }, this.$route.query.id).then(r => {
				if (r.status === "OK") {
					this.$message.success("删除成功")
					this.getList()
				}
			})
		},
		// 批量移除
		batchDelete() {
			const list = this.$refs.table.selection.map(e => e.prodId)
			console.log(list)
			removeProducts({ productIds: list }, this.$route.query.id).then(r => {
				if (r.status === "OK") {
					this.$message.success("删除成功")
					this.getList()
				}
			})
		},
		// 每页显示个数
		handleSizeChange(val) {
			this.pageSize =  val
			this.pageNum = 1
			this.getList()
		},
		// 当前页变化
		handleCurrentChange(val) {
			this.pageNum = val
			this.getList()
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
