<template>
	<div class="mvk-layout-content-card">
		<div class="flex">
			<el-button v-has="'sys:banner:add'" type="primary" class="mb20" @click="handleAdd"> 新增 </el-button>
			<el-form size="small" label-width="150px" :inline="true">
				<el-form-item label="Banner位置" prop="bannerAddress">
					<el-select v-model="queryParam.bannerAddress" placeholder="Banner位置" clearable>
						<el-option
							v-for="item in bannerAddress"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="链接类型" prop="refType">
					<el-select v-model="queryParam.refType" placeholder="链接类型" clearable>
						<el-option
							v-for="item in refTypeArray"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<template slot="img" slot-scope="{ row }" label="Banner图片">
				<el-image
					v-if="row.linkForm != '20'"
					style="width: 30px; height: 30px"
					:src="row.img  + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
					:preview-src-list="[row.img]"
				/>
				<video v-else width="320" height="240" controls>
					<source :src="row.img" type="video/MP4">
				</video>
			</template>

			<el-table-column label="操作" width="150">
				<template slot-scope="{ row }">
					<el-button v-has="'sys:banner:edit'" type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
					<delete-button
						:id="row.id"
						v-has="'sys:banner:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
	</div>
</template>

<script>
import { listMixin } from "views/mixin/listMixin"
import { getBannerList, deleteBanner } from "api/basePage/banner"
import { bannerLocation, refTypeArray } from "@/utils/constants"
import { debounce } from "utils/utils"
export default {
	name: "BannerSetting",
	components: {
		"add-edit-dialog": () => import("./addEdit.vue")
	},
	mixins: [listMixin],
	props: {},
	data() {
		return {
			// 查询条件参数
			queryParam: {
				bannerAddress: "",
				refType: ""
			},
			bannerAddress: [],
			refTypeArray,
			tableColumn: [
				{ prop: "id", label: "Id", width: "50" },
				{
					prop: "bannerAddress",
					label: "Banner位置",
					width: "150",
					formatter: cellValue => {
						return bannerLocation[cellValue.bannerAddress]
					}
				},
				{
					prop: "linkForm",
					label: "链接形式",
					width: "150",
					formatter: cellValue => {
						let linkFormMap = {
							10: "图片",
							20: "视频",
							30: "无链接"
						}
						return linkFormMap[cellValue.linkForm]
					}
				},
				{
					prop: "refType",
					label: "链接类型",
					formatter: cellValue => {
						const temp = refTypeArray.find(e => e.value === cellValue.refType)
						return temp ? temp.label : ""
					}
				},
				{ prop: "img", label: "Banner图片", slot: true, minWidth: 120 },
				{ prop: "sort", label: "展示排序", width: "76", align: "center" },
				{ prop: "creationDate", label: "上传日期", width: "150" }
			],

			tableDataFunc: parameter => {
				return getBannerList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			}
		}
	},
	watch: {
		queryParam: {
			handler: debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},
	computed: {},
	methods: {
		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
		},

		handleDelete(data) {
			deleteBanner(data.id).then(() => {
				this.$notify.success("删除成功")
				this.$refs.mvkTable.refresh()
			})
		}
	},
	created() {},
	mounted() {
		for (var key in bannerLocation) {
			this.bannerAddress.push({
				value: Number(key),
				label: bannerLocation[key]
			})
		}
	}
}
</script>
<style lang="scss" scoped></style>
