import fetch from "@/axios/fetch"

export function organitionTree(params) { // 组织架构树
	return fetch({
		url: "/organization/list_with_child",
		method: "get",
		params
	})
}

export function organitionAdd(data) { // 新增
	return fetch({
		url: "/organization/add",
		method: "post",
		data
	})
}

export function organitionUpdate(data) { // 更新
	return fetch({
		url: "/organization/update",
		method: "put",
		data
	})
}

export function organitionDelete(id) { // 删除
	return fetch({
		url: "/organization/delete/" + id,
		method: "delete"
	})
}

export function organitionUser(params) { // 组织架构用户
	return fetch({
		url: "/organization/user-list",
		method: "get",
		params
	})
}

