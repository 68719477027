<template>
	<div class="mvk-layout-content-card">
		<div class="add-btn flex-row" style="justify-content: space-between">
			<el-button v-has="'sms:coupon:add'" size="small" type="primary" round @click="handleAdd('')">
				添加优惠劵
			</el-button>
			<el-form ref="form" :model="queryParam" :inline="true">
				<el-form-item label="状态：">
					<el-select
						v-model="queryParam.status"
						placeholder="请选择"
						clearable
						style="width: 110px"
						@change="changeSelectStatus"
					>
						<el-option label="未开启" :value="false" />
						<el-option label="开启" :value="true" />
					</el-select>
				</el-form-item>
				<el-form-item label="类型：">
					<el-select
						v-model="queryParam.types"
						placeholder="请选择"
						clearable
						style="width: 110px"
						@change="changeSelectStatus"
					>
						<el-option label="通用券" :value="10" />
						<el-option label="商品券" :value="20" />
						<el-option label="品类券" :value="30" />
						<el-option label="大客户券" :value="40" />
					</el-select>
				</el-form-item>
				<el-form-item v-if="queryParam.types === 40">
					<el-select
						v-model="queryParam.shopId"
						value-key="id"
						filterable
						placeholder="选择可使用的店铺"
						clearable
						@change="changeSelectStatus"
					>
						<el-option
							v-for="item in keyAccountShopList"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="优惠券名称：">
					<el-input v-model="queryParam.name" placeholder="可输入优惠券名称搜索">
						<el-button slot="append" icon="el-icon-search" @click="changeSelectStatus" />
					</el-input>
				</el-form-item>
			</el-form>
		</div>

		<!-- <router-link :to=" { path: '/marketingManager/coupon/listAdd' } " v-has="'sms:coupon:add'">
            <el-button size="small" type="primary">添加优惠劵</el-button>
        </router-link> -->

		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt20">
			<template slot="receiveTime" slot-scope="{ row }" label="领取时间">
				<span v-if="!row.isTime">不限时</span>
				<div v-else-if="row.isTime">
					{{ row.receiveTime.split(",")[0] }} - {{ row.receiveTime.split(",")[1] }}
				</div>
				<span v-else />
			</template>
			<template slot="termOfValidity" slot-scope="{ row }" label="使用时间">
				<span v-if="row.validityType == 0">{{ row.termOfValidity }} 天</span>
				<div v-else-if="row.validityType == 1">
					{{ row.termOfValidity.split(",")[0] }} - {{ row.termOfValidity.split(",")[1] }}
				</div>
				<span v-else>无限期</span>
			</template>
			<template slot="pushNum" slot-scope="{ row }">
				<span v-if="!row.isLimit">不限量</span>
				<div v-else>
					<span class="fa">发布：{{ row.pushNum }}</span>
					<span class="sheng">剩余：{{ row.surplusNum == null ? row.pushNum : row.surplusNum }}</span>
				</div>
			</template>
			<template slot="score" slot-scope="{ row }" label="状态">
				<el-switch
					v-model="row.status"
					:disabled="!hasBtnPermission('sms:coupon:updateStatus')"
					@change="handleStatusChange(row.id, row.status)"
				/>
			</template>
			<el-table-column label="操作" width="180">
				<template slot-scope="{ row }">
					<el-button type="text" @click="handleDeclareEdit(row.id,row.name,row.declares)">
						说明
					</el-button>
					<el-button v-has="'sms:coupon:userRecords'" type="text" @click="receive(row.id)">
						领取记录
					</el-button>
					<!-- <router-link :to=" { path: '/marketingManager/coupon/listAdd/' + row.id } " v-has="'sms:coupon:detail'">
                        <el-button type="text" size="small" v-if="row.status">复制</el-button>
                    </router-link> -->
					<el-button
						v-if="row.status"
						v-has="'sms:coupon:detail'"
						type="text"
						size="small"
						@click="handleAdd(row)"
					>
						复制
					</el-button>
					<delete-button
						:id="row.id"
						v-has="'sms:coupon:delete'"
						button-type="text"
						size="small"
						@delete="handleDelete"
					>
						删除
					</delete-button>
				</template>
			</el-table-column>
		</mvk-table>

		<single-record-list v-if="hasBtnPermission('sms:coupon:userRecords')" ref="recordRef" />

		<!-- 优惠券说明 -->
		<el-dialog title="优惠券使用说明" width="600px" :visible.sync="dialogShow">
			<el-form :model="declareParams">
				<el-form-item label="优惠券名称：" label-width="200">
					{{ declareParams.name }}
				</el-form-item>
				<el-form-item label="" label-width="200">
					<el-input v-model="declareParams.declares" 
						type="textarea"
						placeholder="请输入使用说明"
						maxlength="500" rows="6"
						resize="none"
						show-word-limit autocomplete="off"
					/>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogShow = false">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="declareSubmit()">确 定</el-button>
			</div>
		</el-dialog>

		<add-edit
			v-if="hasBtnPermission('sms:coupon:add') || hasBtnPermission('sms:coupon:detail')"
			ref="addRef"
			@saveSuccess="changeSelectStatus"
		/>
	</div>
</template>

<script>
import { couponList, couponDelete, changeStatus, couponDeclare } from "api/business/marketing/coupon"
import SingleRecordList from "./singleRecordList.vue"
import AddEdit from "./addEdit.vue"
import { permissionMixin } from "views/mixin/permissionMixin"
import { mapState, mapActions } from "vuex"

export default {
	name: "CouponList",

	components: {
		SingleRecordList,
		AddEdit
	},
	mixins: [permissionMixin],
	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "name",
					label: "名称"
				},
				{
					prop: "types",
					label: "类型",
					formatter: cellValue => {
						if (cellValue.types == 10) {
							return "通用券"
						} else if (cellValue.types == 20) {
							return "商品券"
						} else if (cellValue.types == 30) {
							return "品类券"
						} else if (cellValue.types == 40) {
							return "大客户券"
						} else {
							return cellValue.types
						}
					}
				},
				{
					prop: "faceValue",
					label: "面值"
				},
				{
					prop: "obtainMethod",
					label: "领取方式",
					formatter: cellValue => {
						if (cellValue.obtainMethod == 10) {
							return "手动领取"
						} else if (cellValue.obtainMethod == 20) {
							return "新人券"
						} else if (cellValue.obtainMethod == 30) {
							return "赠送券"
						} else if (cellValue.obtainMethod == 40) {
							return "活动券"
						} else {
							return cellValue.obtainMethod
						}
					}
				},
				{
					prop: "receiveTime",
					label: "领取日期",
					width: "260",
					slot: true
				},
				{
					prop: "termOfValidity",
					label: "使用时间",
					width: "260",
					slot: true
				},
				{
					prop: "pushNum",
					label: "发布数量",
					slot: true
				},
				{
					prop: "declares",
					label: "说明",
					width: 120
				},
				{
					prop: "score",
					label: "是否开启",
					slot: true
				}
			],

			// 查询条件参数
			queryParam: {
				status: "",
				name: "",
				types: "",
				shopId: ""
			},

			tableDataFunc: parameter => {
				return couponList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			recordId: "",

			// 优惠券说明
			dialogShow: false,
			loading: false,
			declareParams: {
				id: null,
				name: "",
				declares: ""
			}
		}
	},

	computed: {
		...mapState("dict", ["shoplist", "keyAccountShopList"])
	},
	mounted() {},

	methods: {
		...mapActions("dict", ["getShopList", "getKeyAccountShopList"]),

		handleAdd(recode) {
			this.$refs.addRef.open(recode)
		},

		handleDelete(data) {
			couponDelete(data.id).then(() => {
				this.$notify.success("删除成功")
				this.$refs.mvkTable.refresh()
			})
		},

		handleStatusChange(id, status) {
			changeStatus({
				id: id,
				status: status
			})
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "修改成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "修改失败"
					})
				})
		},

		changeSelectStatus() {
			if (this.queryParam.types === 40 && this.keyAccountShopList.length === 0) {
				this.getKeyAccountShopList()
			}
			this.$refs.mvkTable.refresh()
		},

		receive(id) {
			this.recordId = id
			this.$nextTick(function() {
				this.$refs.recordRef.open(id)
			})
		},

		// 打开优惠券说明
		handleDeclareEdit(id, name, declares) {
			this.dialogShow = true
			this.declareParams.id = id
			this.declareParams.name = name 
			this.declareParams.declares = declares || ""
		},
		// 优惠券说明提交
		declareSubmit() { 
			this.loading = true
			couponDeclare(this.declareParams).then(() => { 
				this.$notify.success({
					title: "成功",
					message: "保存成功"
				})
				this.dialogShow = false
				this.$refs.mvkTable.refresh()

			}).finally(() => { this.loading = false })
		}
	}
}
</script>

<style lang="scss" scoped>
.fa {
	color: #0a6aa1;
	display: block;
}
.sheng {
	color: #ff0000;
	display: block;
}
</style>
