<template>
	<el-dialog :title="title" :visible.sync="addEditDialog" append-to-body width="600px">
		<el-form ref="AddEditForm" :model="AddEditForm" :rules="Rules" label-width="120px">
			<el-form-item prop="pid" label="上级分类">
				<el-cascader v-model="AddEditForm.pid" :options="treeData" :props="categoryProps" style="width: 100%" />
			</el-form-item>
			<el-form-item prop="name" label="分类名称">
				<mvk-input v-model.trim="AddEditForm.name" placeholder="分类名称" />
			</el-form-item>
			<el-form-item label="排序">
				<el-input-number v-model="AddEditForm.sort" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" class="form-submit" @click="handleSubmit('AddEditForm')">保存</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { AddCategory, EditCategory } from "api/business/selfManager/picture.js"

export default {
	name: "CategoryAddEdit",
	props: {
		treeData: {
			type: Array,
			require: true,
			default: []
		}
	},
	components: {},

	directives: {},

	data() {
		return {
			title: "新增",
			addEditDialog: false,
			AddEditForm: {
				pid: "",
				name: "",
				type: 2,
				sort: 1,
				status: 1,
				url: "url",
				id: ""
			},
			Rules: {
				// pid: [{
				//     required: true,
				//     message: "请选择上级分类",
				//     trigger: ['blur','change']
				// }],
				name: [
					{
						required: true,
						message: "请输入分类名称",
						trigger: ["blur", "change"]
					}
				]
			},

			categoryProps: {
				value: "id",
				label: "name",
				children: "child",
				expandTrigger: "hover",
				checkStrictly: true,
				emitPath: false
			}
		}
	},

	mounted() {},

	methods: {
		add() {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.$refs["AddEditForm"].resetFields()
			})
		},
		edit(record) {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.AddEditForm = JSON.parse(JSON.stringify(record))
			})
		},

		handleSubmit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.AddEditForm.pid == 10000) this.AddEditForm.pid = 0

					let editParams = JSON.parse(JSON.stringify(this.AddEditForm))
					delete editParams.path

					if (editParams.status == null) {
						editParams.status = 1
					}

					this.AddEditForm.id
						? EditCategory(editParams).then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
						  })
						: AddCategory(this.AddEditForm).then(() => {
								this.$notify.success({
									title: "成功",
									message: "添加成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
						  })
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
