import axios from "axios"
import { MessageBox } from "element-ui"
import router from "@/router"
import { getToken, setToken, removeToken } from "api/auth"
import { debounce } from "@/utils/utils"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import store from "store"
// 创建axios实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // api的base_url
	timeout: 300 * 1000 // 请求超时时间 5分钟
})
// window.isLogin = false // 用于同时调接口，然后返回login页面时，不要重复提示

const debErrMsg = debounce((message, title) => {
	MessageBox.alert(message, title)
}, 300)
// 取消请求并删除所有请求记录
const clearRequest = () => {
	store.state.app.cancalRequest.map((cancel) => {
		if (typeof cancel === "function") {
			cancel("强制取消了请求")
		} else {
			console.log("没有可取消的请求")
		}
	})
	store.dispatch("app/clearCancelRequest")
}
// request请求拦截器
service.interceptors.request.use(
	(config) => {
		NProgress.start()
		if (getToken() && getToken() != "null") {
			config.headers["Authorization"] = "Bearer " + getToken()
		}
		config.cancelToken = new axios.CancelToken((c) => {
			store.commit("app/SET_CANCALREQUEST", c)
		})
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

// respone响应拦截器
service.interceptors.response.use(
	(response) => {
		NProgress.done()
		// 对响应数据做点什么
		if (response.status == "200") {
			if (response.data && response.data.token) {
				setToken(response.data.token)
			}

			return response.data

			// if (response.data.code == 200) {

			// }else {
			// 	if(response.data.data){
			// 		MessageBox.alert(response.data.data.message || response.data.data,'提示');
			// 	}else {
			// 		MessageBox.alert(response.data.message,'提示');
			// 	}
			// }
		} else {
			var message = response.message
			if (message == "") {
				switch (response.status) {
					case 400:
						message = response.data.message || "错误请求"
						break
					case 401:
						// if (!window.isLogin) {
						// window.isLogin = true
						clearRequest()
						message = response.data.message || "未授权，请重新登录"
						setTimeout(() => {
							removeToken()
							router.push("/")
						}, 100)
						// }

						break
					case 403:
						message = response.data.message || "拒绝访问"
						break
					case 404:
						message = response.data.message || "请求错误,未找到该资源"
						break
					case 405:
						message = response.data.message || "请求方法未允许"
						break
					case 408:
						message = response.data.message || "请求超时"
						break
					case 500:
						message = response.data.message || "服务器端出错"
						break
					case 501:
						message = response.data.message || "网络未实现"
						break
					case 502:
						message = response.data.message || "网络错误"
						break
					case 503:
						message = response.data.message || "服务不可用"
						break
					case 504:
						message = response.data.message || "网络超时"
						break
					case 505:
						message = response.data.message || "http版本不支持该请求"
						break
					default:
						message = response.data.message || "连接到服务器失败"
				}
			}

			debErrMsg(message, "提示")
		}
	},
	async (error) => {
		NProgress.done()
		// 错误的请求结果处理，这里的代码根据后台的状态码来决定错误的输出信息
		console.log("error", error.toString())
		if (error && error.response) {
			//   responseType == 'blob'
			let responseDataMessage = error.response.data.message
			const BlobtoString = async (data) => {
				let blob = new Blob([data])
				let reader = new FileReader()
				reader.readAsText(blob, "utf-8")
				return new Promise((resolve) => (reader.onload = () => resolve(JSON.parse(reader.result)?.message)))
			}
			if (error.response.config.responseType == "blob") {
				responseDataMessage = await BlobtoString(error.response.data)
			}
			switch (error.response.status) {
				case 400:
					error.message = responseDataMessage || "错误请求"
					break
				case 401:
					// if (!window.isLogin) {
					// console.log("看看有多少个程序呀", store.state.app.cancalRequest)
					clearRequest()
					error.message = responseDataMessage || "未授权，请重新登录"
					setTimeout(() => {
						removeToken()
						router.push("/")
					}, 100)
					// }
					break
				case 403:
					error.message = responseDataMessage || "拒绝访问"
					break
				case 404:
					error.message = responseDataMessage || "请求错误,未找到该资源"
					break
				case 405:
					error.message = responseDataMessage || "请求方法未允许"
					break
				case 408:
					error.message = responseDataMessage || "请求超时"
					break
				case 500:
					error.message = responseDataMessage || "服务器端出错"
					break
				case 501:
					error.message = responseDataMessage || "网络未实现"
					break
				case 502:
					error.message = responseDataMessage || "网络错误"
					break
				case 503:
					error.message = responseDataMessage || "服务不可用"
					break
				case 504:
					error.message = responseDataMessage || "网络超时"
					break
				case 505:
					error.message = responseDataMessage || "http版本不支持该请求"
					break
				default:
					error.message = `连接错误${error.response.status}`
			}
		} else {
			// console.log("2", error)
			// if (error.message !== "请求中断") error.message = "连接到服务器失败"
		}
		if (error.message !== "请求中断" && error.message !== "强制取消了请求") {
			debErrMsg(error.message, "提示")
		}
		if (axios.isCancel(error)) {
			return Promise.reject(error)
		} else {
			return Promise.reject(error)
		}
	}
)
export default service
