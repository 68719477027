<template>
	<div class="mvk-layout-content-card">
		<div v-show="!showDetail">
			<el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="礼品卡名称">
					<el-input v-model="queryParam.name" placeholder="输入礼品卡名称搜索" clearable />
				</el-form-item>
				<el-form-item label="卡类型">
					<el-select v-model="queryParam.type" placeholder="卡类型" clearable>
						<el-option v-for="(item, ikey) in giftCardTypeMap" :key="ikey" :label="item" :value="ikey" />
					</el-select>
				</el-form-item>
				<el-form-item label="创建时间">
					<el-date-picker
						v-model="timeVal"
						:default-time="['00:00:00', '23:59:59']"
						value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd"
						size="small"
						type="daterange"
						placement="bottom-end"
						placeholder="自定义时间"
						style="width: 220px"
						@change="onchangeTime"
					/>
				</el-form-item>
			</el-form>
			<el-button size="small" type="primary" round @click="handleAdd"> 新增礼品卡 </el-button>
			<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
				<template slot="productVOList" slot-scope="{ row }">
					<div v-if="row.productVOList && row.productVOList.length > 0" class="flex-row">
						<el-image
							style="min-width: 40px; height: 40px; width: 40px"
							:src="row.productVOList[0].pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/40x'"
							:preview-src-list="[row.productVOList[0].pic]"
						/>
						<div style="margin-left: 4px">
							<p>{{ row.productVOList[0].prodName }}</p>
						</div>
					</div>
				</template>
				<el-table-column label="操作" width="230" align="center" header-align="center">
					<template slot-scope="{ row }">
						<el-button type="text" size="small" :disabled="!row.isEdit" @click="handleEdit(row)">
							编辑
						</el-button>
						<el-button type="text" size="small" @click="handleView(row)"> 详情 </el-button>
						<el-button type="text" size="small" @click="handleDownload(row)"> 下载卡密 </el-button>
					</template>
				</el-table-column>
			</mvk-table>
			<add-edit ref="modalForm" @saveSuccess="handleRefresh" />
		</div>
		<gift-card-detail
			v-show="showDetail"
			ref="cardDetail"
			:group-id="currentGroupId"
			@goback="showDetail = !showDetail"
		/>
	</div>
</template>

<script>
import { listMixin } from "views/mixin/listMixin"
import { blobTofile } from "@/utils/utils"
import { giftCardType } from "@/utils/constants"
import { getGiftCardGroupList, giftCardExport } from "api/business/marketing/giftCard.js"
export default {
	name: "GiftCardIndex",
	components: {
		"add-edit": () => import("./addEdit.vue"),
		"gift-card-detail": () => import("./detail.vue")
	},
	mixins: [listMixin],
	data() {
		return {
			showDetail: false,
			queryParam: {
				name: "",
				type: "",
				createTimeStart: "",
				createTimeEnd: ""
			},
			timeVal: [],
			giftCardTypeMap: giftCardType,
			// eslint-disable-next-line no-unused-vars
			tableColumn: [
				{
					prop: "id",
					label: "ID"
				},
				{
					prop: "name",
					label: "礼品卡名称"
				},
				{
					prop: "productVOList",
					label: "商品信息",
					slot: true,
					tooltip: true,
					minWidth: 140
				},
				{
					prop: "num",
					label: "数量"
				},
				{
					prop: "type",
					label: "卡类型",
					formatter: row => {
						return giftCardType[row.type]
					}
				},
				{
					prop: "amount",
					label: "金额"
				},
				{
					prop: "creationDate",
					label: "创建时间",
					width: 150
				},
				{
					prop: "startTime",
					label: "礼品卡有效期",
					formatter: row => {
						if (row.startTime && row.endTime) {
							return row.startTime + "~" + row.endTime
						} else {
							return "无限期"
						}
					},
					width: 300
				},
				{
					prop: "remark",
					label: "备注"
				}
			],

			currentGroupId: 0
		}
	},
	computed: {
		tableDataFunc() {
			let temp = JSON.parse(JSON.stringify(this.queryParam))
			return parameter => {
				return getGiftCardGroupList(Object.assign(parameter, temp)).then(res => {
					res.data.list &&
						res.data.list.forEach(item => {
							item.startTime = item.startTime
								? item.startTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
								: ""

							item.endTime = item.endTime ? item.endTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "") : ""
						})
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {
		handleRefresh() {
			this.$refs.mvkTable.refresh()
		},

		handleDownload(row) {
			let fileName = row.name + "卡密.xls"

			this.$confirm("下载卡密文件之后就不允许再对礼品卡进行修改, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					giftCardExport({
						pageNum: 0,
						pageSize: row.num,
						groupId: row.id
					}).then(res => {
						blobTofile(res, fileName, "application/vnd.ms-excel")
						this.handleRefresh()
					})
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					})
				})
		},

		handleView(row) {
			this.showDetail = true
			this.currentGroupId = row.id
			this.$refs.cardDetail.view(row)
		},

		onchangeTime(e) {
			this.queryParam.createTimeStart = e ? e[0] : ""
			this.queryParam.createTimeEnd = e ? e[1] : ""
		}
	}
}
</script>

<style lang="scss" scoped></style>
