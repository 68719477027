<template>
	<el-input
		v-bind="$attrs"
		oninput="value=value.replace(/[^\d^\.]/g,'')"
		v-on="$listeners"
		@change="checkValue"
		@focus="foucusInputNum"
		@blur="blurInputNum"
	/>
</template>

<script>
export default {
	name: "MvkinputNum",
	methods: {
		checkValue(value) {
			const min = this.$attrs.min
			const max = this.$attrs.max
			if (value < min) {
				this.$emit("input", min)
			} else if (value > max) {
				this.$emit("input", max)
			}
		},
		foucusInputNum(e) {
			const value = e.currentTarget.value
			if (value === "0" || value === "") {
				return value
			} else {
				return ""
			}
		},
		blurInputNum(e) {
			const value = e.currentTarget.value
			const reg = /^[+-]?((\d*((\.\d{1,})$)|(\d+$)))/
			if (!reg.test(value) && value !== 0 && value !== "") {
				this.$message.error("请输入正确的数值")
				e.target.focus()
				return e.currentTarget.value = ""
			}
		}
	}
}
</script>

<style lang="scss" scoped>
    
</style>