<template>
	<el-drawer :title="title" :visible.sync="addEditVisible" size="400px" :append-to-body="true">
		<div class="tips">标题不在滚动条内中出现</div>
		<el-form ref="form" :model="form" :rules="isDisabled ? {} : rules" label-width="80px" :inline="false">
			<el-form-item label="标题" prop="title">
				<el-input v-model="form.title" :readonly="isDisabled" class="width100" />
			</el-form-item>
			<el-form-item label="详细内容" prop="content">
				<el-input
					v-model="form.content"
					:readonly="isDisabled"
					type="textarea"
					maxlength="255"
					rows="5"
					class="width100"
				/>
			</el-form-item>
			<div v-if="!isDisabled" class="custom-drawer-footer" style="position: static">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit"> 保存 </el-button>
				</div>
			</div>
		</el-form>
	</el-drawer>
</template>
<script>
import * as api from "api/business/marketing/article"
export default {
	name: "ArticleEdit",
	props: {},
	data() {
		return {
			title: "新增",
			addEditVisible: false,
			isDisabled: false,
			form: {
				id: "",
				title: "",
				content: ""
			},
			rules: {
				title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
				content: [{ required: true, message: "详细内容不能为空", trigger: "blur" }]
			}
		}
	},
	watch: {
		tabsActiveName(newVal, oldVal) {
			if (newVal && newVal !== oldVal) {
				this.activeName = newVal
			}
		}
	},
	methods: {
		add() {
			this.title = "新增"
			this.addEditVisible = true
		},
		edit(item, readOnly) {
			this.title = "编辑"
			this.isDisabled = readOnly || false
			this.form = JSON.parse(JSON.stringify(item))
			this.addEditVisible = true
		},
		closeDrawer() {
			this.addEditVisible = false
			this.$emit("saveSuccess")
		},
		handleClick(tab, event) {
			console.log(tab, event)
		},
		handleSubmit() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					api.setArticle(this.form)
						.then(() => {
							this.$notify.success({
								title: "保存成功"
							})
							this.closeDrawer()
							this.form = {
								id: "",
								title: "",
								content: ""
							}
						})
						.catch(err => console.log("err", err))
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.tips{
    margin-left: 80px;
    margin-bottom: 6px;
    font-size: 12px;
    color: #999;
}
</style>
