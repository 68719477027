<template>
	<div>
		<el-select v-bind="$attrs" v-on="$listeners">
			<el-option v-for="(item, index) in listData" :key="index" :label="item.label" :value="item.value" />
			<el-pagination
				:current-page.sync="pageNum"
				:page-size="pageSize"
				background
				small
				layout="total, prev, pager, next"
				:total="total"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
			/>
		</el-select>
	</div>
</template>

<script>
// 带有分页的select下拉框
export default {
	name: "SelectPage",
	props: {
		listDataFunc: {
			// 下拉框的数据接口
			type: Function,
			required: true
		},
		initData: {
			type: Array,
			default: () => {
				return []
			}
		},
		initTotal: {
			type: Number,
			default: 0
		}
	},
	computed: {
		params() {
			return {
				pageNum: this.pageNum - 1,
				pageSize: this.pageSize
			}
		}
	},
	data() {
		return {
			loading: false,

			listData: [], // 下拉框列表数据

			pageNum: 1,
			pageSize: 10,
			total: 0
		}
	},
	created() {
		if (this.initData.length === 0) {
			this.loadData()
		} else {
			this.listData = this.initData
			this.total = this.initTotal
		}
	},

	mounted() {},

	methods: {
		refresh() {
			this.pageNum = 1
			this.loadData()
		},

		// 加载下拉框数据接口，获得返回数据
		loadData(selectData) {
			this.loading = true
			let dataPromise = this.listDataFunc(this.params)

			dataPromise
				.then(res => {
					this.listData = (res.list || res.content).map(e => {
						const value = JSON.stringify({ courierCompany: e.expName, courierCompanyCode: e.expCode })
						const label = e.expName
						return { label, value }
					})
					// 确保数据中不重复
					this.total = res.totalElements
					const expCache = localStorage.getItem("defaultExpress")
					const temp = JSON.parse(expCache)
					if (expCache && !this.listData.some(e => e.label === temp.courierCompany)) {
						const item = {
							label: temp.courierCompany,
							value: expCache
						}
						this.listData.unshift(item)
					}
					if (selectData) {
						const data = JSON.parse(selectData)
						this.listData.unshift({ label: data.courierCompany, value: selectData })
					}
					// ------ end ------
					this.total = res.totalElements
				})
				.finally(() => {
					this.loading = false
				})
		},

		// 每页显示个数
		handleSizeChange(val) {
			this.pageSize = val
			this.pageNum = 1
			this.loadData()
		},
		// 当前页变化
		handleCurrentChange(val) {
			this.pageNum = val
			this.loadData(this.$attrs.value)
		}
	}
}
</script>
