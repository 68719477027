<template>
	<mvk-table
		ref="mvkTable"
		:tableColumn="tableColumn"
		:tableDataFunc="tableDataFunc"
		row-key="id"
		:tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
	>
		<el-table-column slot="head">
			<template slot-scope="scope">
				<el-radio v-model="checkedId" :label="scope.row.id" @change.native="getInfo(scope.row)">
					<span />
				</el-radio>
			</template>
		</el-table-column>
	</mvk-table>
</template>

<script>
import { proCategoryList } from "api/business/selfManager/category.js"

export default {
	name: "AdvertCategory",
	props: {
		checkedId: {
			type: Number | String,
			default: 0
		}
	},
	data() {
		return {
			templateRadio: 0,
			tableColumn: [
				{
					prop: "name",
					label: "名称"
				},
				{
					prop: "level",
					label: "类型",
					formatter: (row, column, cellValue, index) => {
						if (cellValue == 1) {
							return "一级分类"
						} else if (cellValue == 2) {
							return "二级分类"
						} else if (cellValue == 3) {
							return "三级分类"
						}
					}
				}
			],
			tableDataFunc: (parameter) => {
				return proCategoryList(parameter).then((res) => {
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {
		getInfo(info) {
			console.log("info", info)
			this.$emit("getInfo", info)
		}
	}
}
</script>

<style lang="scss" scoped></style>
