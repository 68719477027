<template>
	<div class="mvk-layout-content-card">
		<div class="flex">
			<el-button type="primary" class="mb20" @click="openDialog"> 新增会员 </el-button>
			<el-button type="success" class="mb20" :disabled="selectList.length == 0" @click="handleSave">
				批量移除
			</el-button>
			<el-button type="primary" class="mb20" @click="toDownloadTemplate"> 导入模板下载 </el-button>
			<el-upload
				ref="upload"
				action
				:headers="uploadHeaders"
				:http-request="handleUpload"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
				:on-preview="handlePreview"
				:limit="1"
				:on-remove="handleRemove"
				:file-list="fileList"
				style="display: flex;margin-left: 10px;"
			>
				<el-button slot="trigger" size="small" type="primary" :disabled="!!fileList.length">批量导入会员</el-button>
			</el-upload>
		</div>
		<el-form size="small" :inline="true">
			<el-form-item label="请输入姓名：" style="min-width: 120px">
				<el-input v-model="queryParams.realName" clearable />
			</el-form-item>
			<el-form-item label="请输入手机号：" style="min-width: 120px">
				<el-input v-model="queryParams.mobile" clearable />
			</el-form-item>
		</el-form>

		<mvk-table ref="mvkTable" :table-data-func="tableDataFunc" row-key="id" height="540" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="40" :reserve-selection="true" />
			<el-table-column prop="id" width="100" label="ID" />
			<el-table-column label="会员姓名" prop="realName" min-width="120"></el-table-column>
			<el-table-column label="会员手机号码" prop="mobile" width="130" />
			<el-table-column label="累计领取次数/金额" width="280" align="center" header-align="center" >
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleReceive(row)">{{row.totalTimes || 0}}次/{{row.totalAmount || 0}}元</el-button>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="230" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button v-if="!row.isDisable" type="text" size="small" @click="handleDisable(row)"> 禁用 </el-button>
					<span v-if="row.isDisable" style="color: #ccc;padding-right: 10px;">已禁用</span>
					<el-button v-if="!row.isEnroll" type="text" size="small" @click="handleDel(row)"> 移除 </el-button>
					<el-button type="text" size="small" @click="handleSport(row)"> 运动记录 </el-button>
				</template>
			</el-table-column>
		</mvk-table>


		<el-dialog title="新增会员" :visible.sync="dialogFormVisible" append-to-body>
			<el-form :model="formData" :rules="rules" label-width="180px">
				<el-form-item label="手机号" prop="mobile">
					<el-input v-model="formData.mobile" autocomplete="off" class="inputWidth"></el-input>
				</el-form-item>
				<el-form-item label="真实姓名" prop="mobile">
					<el-input v-model="formData.realName" autocomplete="off" class="inputWidth"></el-input>
				</el-form-item>
				<el-form-item label="会员昵称">
					<el-input v-model="formData.nickName" autocomplete="off" class="inputWidth"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancleDialog">取 消</el-button>
				<el-button type="primary" @click="confirmSubmit">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="领取记录" :visible.sync="dialogReceiveVisible" append-to-body>
			<div style="margin-bottom: 10px;">姓名：{{receiveData.realName}} &nbsp&nbsp&nbsp&nbsp&nbsp累计领取次数/金额：{{receiveData.totalTimes }}次/{{receiveData.totalAmount}}元 &nbsp&nbsp&nbsp&nbsp&nbsp本周领取次数/金额：{{receiveData.weekTimes}}次/{{receiveData.weekAmount}}元</div>
			<mvk-table ref="mvkTableReceive" :table-data-func="tableDataFuncReceive" row-key="id" height="240" :noPage="true">
				<el-table-column type="index" width="100" label="序号" />
				<el-table-column label="时间" prop="timestamp" min-width="120"></el-table-column>
				<el-table-column label="领取金额" width="120" align="center" header-align="center" >
					<template slot-scope="{ row }">
						{{row.money || 0}}元
					</template>
				</el-table-column>
			</mvk-table>
		</el-dialog>

		<el-dialog title="运动记录" :visible.sync="dialogSportVisible" append-to-body>
			<div style="margin-bottom: 10px;">姓名：{{sportData.realName}} &nbsp&nbsp&nbsp&nbsp&nbsp累计运动天数/达标数：{{sportData.totalDays }}天/{{sportData.standardDays}}天 &nbsp&nbsp&nbsp&nbsp&nbsp本周运动天数/达标数：{{sportData.weekDays}}天/{{sportData.weekStandarDays}}天</div>
			<mvk-table ref="mvkTableSport" :table-data-func="tableDataFuncSport" row-key="id" height="240" :noPage="true">
				<el-table-column type="index" width="100" label="序号" />
				<el-table-column label="时间" prop="timestamp" min-width="120"></el-table-column>
				<el-table-column label="运动步数" prop="steps" width="120" />
				<el-table-column label="达标情况" width="120" align="center" header-align="center" >
					<template slot-scope="{ row }">
						{{row.isStandard ? '已达标' : '未达标'}}
					</template>
				</el-table-column>
				<el-table-column label="领取情况" width="120" align="center" header-align="center" >
					<template slot-scope="{ row }">
						{{row.money || 0}}元
					</template>
				</el-table-column>
			</mvk-table>
		</el-dialog>

	</div>
</template>
<script>
import { getToken } from "api/auth"
import { getWeRunMemberList, getWeRunMemberDownloadTemplate, addWeRunMember, deleteWeRunMember, disableWeRunMember, uploadWeRunMember, getWeRunReceiveRecord, getWeRunSportRecord } from "api/business/marketing/weRun"
import { debounce } from "utils/utils"
import { listMixin } from "views/mixin/listMixin"
import { blobTofile } from "@/utils/utils"

export default {
	name: "WeRunMember",
	mixins: [listMixin],
	components: {
		"we-edit-drawer": () => import("./weEditDrawer.vue")
	},
	props: {
		weRunId: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			fileList: [],
			uploadHeaders: { "X-Token": getToken() },

			dialogFormVisible: false,
			queryParams: {
				enrollId: null,
				realName: '',
				mobile: '',
			},
			tableData: [],
			selectList: [],
			formData: {
				// 活动ID
				enrollId: null,
                // 手机号
                mobile: '',
                // 会员昵称
                nickName: '',
				// 真实姓名
				realName: '',
			},
			rules:{
				mobile: [{ required: true, message: "请填写手机号", trigger: "blur" }],
				realName: [{ required: true, message: '请填写真实姓名', trigger: 'blur' }],
			},
			tableDataFunc: parameter => {
				this.pageNum = parameter.pageNum
				this.pageSize = parameter.pageSize
				this.queryParams.enrollId = this.weRunId

				return this.weRunId ? getWeRunMemberList(Object.assign(parameter, this.queryParams)).then(res => {
					return res.data
				}) : []
			},
			pageNum: 0, // 只用于导出传参
			pageSize: 0, // 只用于导出传参

			dialogReceiveVisible: false,
			queryParamsReceive: {
				enrollId: '',
				mobile: '',
			},
			receiveData: {
				totalTimes: 0,
				totalAmount: 0,
				weekAmount: 0,
				weekTimes: 0,
				realName: '',
			},
			tableDataFuncReceive: parameter => {
				this.pageNumReceive = parameter.pageNum
				this.pageSizeReceive = parameter.pageSize
				this.queryParamsReceive.enrollId = this.weRunId

				return this.weRunId ? getWeRunReceiveRecord(Object.assign(parameter, this.queryParamsReceive)).then(res => {
					this.receiveData.weekAmount = res.data.weekAmount
					this.receiveData.weekTimes = res.data.weekTimes
					return {list: res.data.enrollUserRecordVOS, totalElements: res.data.enrollUserRecordVOS.length}
				}) : []
			},
			pageNumReceive: 0, // 只用于导出传参
			pageSizeReceive: 0, // 只用于导出传参

			dialogSportVisible: false,
			queryParamsSport: {
				enrollId: '',
				mobile: '',
			},
			sportData: {
				weekStandarDays: 0,
				weekDays: 0,
				totalDays: 0,
				standardDays: 0,
				realName: '',
			},
			tableDataFuncSport: parameter => {
				this.pageNumSport = parameter.pageNum
				this.pageSizeSport = parameter.pageSize
				this.queryParamsSport.enrollId = this.weRunId

				return this.weRunId ? getWeRunSportRecord(Object.assign(parameter, this.queryParamsSport)).then(res => {
					this.sportData.weekStandarDays = res.data.weekStandarDays
					this.sportData.weekDays = res.data.weekDays
					this.sportData.totalDays = res.data.totalDays
					this.sportData.standardDays = res.data.standardDays
					return {list: res.data.enrollUserStepsVOS, totalElements: res.data.enrollUserStepsVOS.length}
				}) : []
			},
			pageNumSport: 0, // 只用于导出传参
			pageSizeSport: 0, // 只用于导出传参
		}
	},
	watch: {
		queryParams: {
			handler: debounce(function() {
				this.getTableData()
			}, 300),
			deep: true
		},
		weRunId(newVal, oldVal) {
			if(newVal && newVal !== oldVal) {
				this.getTableData()
			}
		}
	},

	mounted() {
		if(this.weRunId) {
			this.getTableData()
		}
	},

	methods: {

		handleReceive(item) {
			this.receiveData.realName = item.realName || item.nickName
			this.receiveData.totalTimes = item.totalTimes
			this.receiveData.totalAmount = item.totalAmount
			this.queryParamsReceive.enrollId = item.enrollId
			this.queryParamsReceive.mobile = item.mobile
			this.dialogReceiveVisible = true
			this.$refs.mvkTableReceive.refresh()
		},

		handleSport(item) {
			this.sportData.realName = item.realName || item.nickName
			this.queryParamsSport.enrollId = item.enrollId
			this.queryParamsSport.mobile = item.mobile
			this.dialogSportVisible = true
			this.$refs.mvkTableSport.refresh()
		},

		openDialog() {
			this.dialogFormVisible = true
		},

		cancleDialog() {
			this.dialogFormVisible = false
		},

		confirmSubmit() {
			addWeRunMember({...this.formData, enrollId: this.weRunId}).then(r => {
				this.$notify.success({
					title: "保存成功"
				})
				this.dialogFormVisible = false
				this.getTableData()
			}).catch(err => console.log(err))
		},

		getTableData() {
			this.$refs.mvkTable.refresh()
		},

        handleDel(item) {
			deleteWeRunMember({enrollId: this.weRunId, ids: item.id}).then(r => {
				this.getTableData()
			}).catch(err => console.log(err))
		},

		handleDisable(item) {
			disableWeRunMember({enrollId: this.weRunId, ids: item.id}).then(r => {
				this.getTableData()
			}).catch(err => console.log(err))
		},

		handleBitchEdit() {
			this.tableData.forEach(item => {
				item.editFlag = true
			})
		},

		handleSelectionChange(val) {
			this.selectList = val
		},

		toDownloadTemplate() {
			getWeRunMemberDownloadTemplate().then(r => {
				blobTofile(r, "会员信息导入模板", "application/vnd.ms-excel")
			})
		},

		handleSave() {
			console.log('this.selectList', this.selectList)
			let ids = []
			this.selectList.forEach(item => ids.push(item.id))
			let s = ids.join(',')
			s = s.slice(0, s.length)
			// to-do 调保存接口
			deleteWeRunMember({
				enrollId: this.weRunId,
				ids: s
			}).then(() => {
				// 成功后，刷新数据
				this.getTableData()
			})
		},
		handlePreview(file) {
			console.log(file)
		},
		handleRemove(file, fileList) {
			console.log(file, fileList)
		},
		handleUpload(param) {
			const formData = new FormData()
			formData.append("file", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			// this.fileList = [formData]
			const params = {
				enrollId: this.weRunId,
			}
			uploadWeRunMember(formData, params).then(res => {
				this.fileList = [{
					name: res.data.fileName,
					url: res.data.url
				}]
				this.info.fileUri = res.data.url
				// this.$nextTick(() => {
				// 	this.$refs.template.validateField("fileUri")
				// })
			}).finally(() => {
				loading.close()
				this.getTableData()
			})
		},
	}
}
</script>
<style>

</style>
