<template>
	<div>
		<el-form>
			<el-form-item label="活动方式：" prop="radio" >
				<el-radio-group v-model="radio" :disabled="isEdit">
					<el-radio label="1">方案一</el-radio>
					<el-radio label="2">方案二</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>
		<block v-if="radio === '1'">
			<div class="tag-title">全局配置</div>
			<el-form ref="form" :model="form" :rules="rules1" label-width="180px" :inline="false">
				<el-form-item label="运动金使用门槛：" prop="is_threshold">
					<div style="display: flex; align-items: center;">
						<el-radio v-model="form.is_threshold" :label="false">无门槛</el-radio>
						<el-radio v-model="form.is_threshold" :label="true">有门槛</el-radio>
						<div :class="{ 'visibility-hidden': !form.is_threshold }">消费满 <el-input-number
								v-model="form.money" :min="1" :max="100000"></el-input-number> 元可用</div>
					</div>
				</el-form-item>
				<el-form-item label="运动金有效期：" prop="timeVal">
					<el-date-picker v-model="form.timeVal" :default-time="['00:00:00', '23:59:59']"
						value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" size="small" type="daterange"
						placement="bottom-end" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 230px" />
				</el-form-item>
				<!-- <el-form-item label="可用中油店铺：" prop="shopIds">
				<el-select v-model="form.shopIds" placeholder="选择门店" clearable filterable multiple collapse-tags>
					<el-option v-for="item in shoplist" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item> -->
				<el-form-item label="可用大客户店铺：" prop="shops">
					<el-select v-model="form.shops" placeholder="选择门店" clearable filterable collapse-tags>
						<el-option v-for="item in keyAccountShopList" :key="item.id" :label="item.name"
							:value="item.id" />
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="运动金可用店铺：" prop="shopIds">
				<el-button type="primary" @click="handleOpenShop"> 选择门店 </el-button>
			</el-form-item> -->

				<el-form-item label="每周可补录次数：" prop="replacement_times">
					<el-input-number v-model="form.replacement_times" :disabled="isEdit" :min="1"
						:max="6"></el-input-number>
					<span style="color: #999;">（* 注意：可补录次数只可新增不可修改）</span>
				</el-form-item>
				<el-form-item label="运动金单日领取条件：" prop="rules">
					每日运动步数满 <el-input-number v-model="form.steps" :min="1"></el-input-number> 步可领取 <el-input-number
						v-model="form.sports_gold" :min="1"></el-input-number> 元
				</el-form-item>
			</el-form>

			<div class="tag-title">运动金发放配置</div>
			<el-form ref="form2" :model="form2" :rules="rules3" :inline="false">
				<el-table :data="form2.enroll_gold_config_list" style="width: 100%">
					<el-table-column type="index" width="100" label="序号">
					</el-table-column>
					<el-table-column prop="gold_name" label="运动金名称" width="180" header-align="center">
						<template slot-scope="scope">
							<el-form-item :prop="'enroll_gold_config_list.' + scope.$index + '.gold_name'" :rules="rules2.gold_name">
								<el-input v-model="scope.row.gold_name"></el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="face_value" label="面值">
						<template slot-scope="scope">
							<el-form-item :prop="'enroll_gold_config_list.' + scope.$index + '.face_value'" :rules="rules2.face_value">
								<el-input v-model="scope.row.face_value" :disabled="true"
									style="width: 100px;"></el-input> 元
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="day" label="发放条件" header-align="center">
						<template slot-scope="scope">
							<el-form-item :prop="'enroll_gold_config_list.' + scope.$index + '.day'" :rules="rules2.day">
								统计周期内达标满 <el-input v-model="scope.row.day" :disabled="true" style="width: 60px;"
									class="rules-day"></el-input> 天系统自动发放
							</el-form-item>
						</template>
					</el-table-column>
				</el-table>
			</el-form>

			<div class="custom-drawer-footer" style="position: static;">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit"> 保存 </el-button>
				</div>
			</div>
		</block>
		<block v-if="radio === '2'">
			<div class="tag-title">活动配置</div>
			<el-form ref="form3" :model="form3" :rules="rules3" label-width="180px" :inline="false">
				<el-form-item label="运动金使用门槛：" prop="is_threshold">
					<div style="display: flex; align-items: center;">
						<el-radio v-model="form3.is_threshold" :label="false">无门槛</el-radio>
						<el-radio v-model="form3.is_threshold" :label="true">有门槛</el-radio>
						<div :class="{ 'visibility-hidden': !form3.is_threshold }">消费满 <el-input-number
								v-model="form3.money" :min="1" :max="100000"></el-input-number> 元可用</div>
					</div>
				</el-form-item>
				<el-form-item label="运动金有效期：" prop="timeVal">
					<el-date-picker v-model="form3.timeVal" :default-time="['00:00:00', '23:59:59']"
						value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" size="small" type="daterange"
						placement="bottom-end" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 230px" />
				</el-form-item>
				<el-form-item label="可用大客户店铺：" prop="shops">
					<el-select v-model="form3.shops" placeholder="选择门店" clearable filterable collapse-tags>
						<el-option v-for="item in keyAccountShopList" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="可补录次数：" prop="replacement_times">
					<el-input-number v-model="form3.replacement_times" :disabled="isEdit" :min="1"></el-input-number>
					<span style="color: #999;">（* 注意：可补录次数只可新增不可修改）</span>
				</el-form-item>
				<el-form-item label="连续运动：" prop="continuous_steps_days">
					<el-input-number v-model="form3.continuous_steps_days" :min="1"></el-input-number> 个 
					<el-select v-model="form3.date_type" style="width: 100px;margin-left: 20px;">
						<el-option value="3" label="自然月"></el-option>
						<el-option value="2" label="自然周"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="每日运动步数满" prop="rules">
					<el-row style="margin-bottom: 10px;" v-for="(item, index) in form3.conditionsList" :key="index">
						<el-input-number v-model="item.every_day_steps" :min="1"></el-input-number> 步，可领取
						<el-input-number v-model="item.sports_gold" :min="1"></el-input-number> 元 <el-input
							style="width: 150px;" placeholder="奖励金名称" v-model="item.gold_name"></el-input> 奖励金 <el-button
							type="primary" icon="el-icon-plus" circle @click="addSet()"></el-button><el-button
							v-if="form3.conditionsList.length > 1" type="danger" icon="el-icon-delete" circle
							@click="subSet(index)"></el-button>
					</el-row>
				</el-form-item>
			</el-form>
			<div class="custom-drawer-footer" style="position: static;">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit2"> 保存 </el-button>
				</div>
			</div>
		</block>
		<!-- <el-dialog title="运动金选择门店" :visible.sync="dialogShopVisible" append-to-body>

		</el-dialog> -->
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import UploadImage from "views/bussiness/components/UploadImage"
import {addWeRunGold2, updateWeRunGold2, getWeRunGoldDetail2 } from "api/business/marketing/weRun"

export default {
	name: "WeRunGold",
	components: {
		UploadImage
	},
	props: {
		weRunId: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			id: '',
			radio: '1',
			form: {
				is_threshold: false, // 是否有门槛
				money: "", //  门槛金额
				start_Time: "", // 运动金有效期开始时间
				end_Time: "", // 运动金有效期结束时间
				replacement_times: "", // 可补领次数 最多6次
				shops: "", // 可用中油店铺id集合
				shopIds: [], 
				steps: "", // 每日满足步数
				sports_gold: "", // 满足步数发放金额
				timeVal: []
			},
			form2: {
				enroll_gold_config_list: []
			},
			form3: {
				is_threshold: false,  // 是否有门槛
				money: "",  // 门槛金额
				start_time: "", // 运动进有效期开始时间
				end_time: "", // 运动进有效期结束时间
				replacement_times: "", // 运动进可补领的次数
				shopIds: [], // 可用中油店铺id集合
				shops: "", // 可用中油店铺id集合
				continuous_steps_days: '', // 连续运动天数
				date_type: 3, // 连续运动类型
				conditionsList: [{ every_day_steps: "", sports_gold: "", gold_name: "" }],
				timeVal: [],
			},

			rules1: {
				is_threshold: [{ required: true, message: "请选择运动金使用门槛", trigger: "change" }],
				timeVal: [{ required: true, message: '请选择运动金有效期', trigger: 'change' }],
				shops: [{ required: true, message: "请选择运动金可用店铺", trigger: "change" }],
				replacement_times: [{ required: true, message: "每周可补录次数不能为空", trigger: "blur" }],
			},
			rules2: {
				gold_name: [{ required: true, message: "运动金名称不能为空", trigger: "blur" }],
				face_value: [{ required: true, message: "面值不能为空", trigger: "blur" }],
				day: [{ required: true, message: "满足天数不能为空", trigger: "blur" }]
			},

			rules3: {
				is_threshold: [{ required: true, message: "请选择运动金使用门槛", trigger: "change" }],
				timeVal: [{ required: true, message: '请选择运动金有效期', trigger: 'change' }],
				shops: [{ required: true, message: "请选择运动金可用店铺", trigger: "change" }],
				replacement_times: [{ required: true, message: "每周可补录次数不能为空", trigger: "blur" }],
				continuous_steps_days: [{ required: true, message: "连续运动天数不能为空", trigger: "blur" }],
				date_type: [{ required: true, message: "连续运动类型不能为空", trigger: "blur" }]
			},
			isEdit: false,
			dialogShopVisible: false
		}
	},
	computed: {
		...mapState("dict", ["shoplist", "keyAccountShopList"]),
	},
	mounted() {
		if (this.shoplist.length == 0) {
			this.getShopList()
		}
		if (this.keyAccountShopList == 0) {
			this.getKeyAccountShopList()
		}
		// 初始化金额规则
		if (this.weRunId) {
			this.getDetail()
		}
	},
	watch: {
		weRunId(newVal, oldVal) {
			if (newVal && newVal !== oldVal) {
				this.getDetail()
			}
		},
		'form.sports_gold'(newVal, oldVal) {
			if (newVal && newVal !== oldVal) {
				console.log('newVal', newVal)
				this.initRules()
			}
		}
	},
	methods: {
		...mapActions("dict", ["getShopList", "getKeyAccountShopList"]),
		handleSubmit() {
			let flag = false
			this.$refs["form"].validate(valid => {
				if (valid) {
					flag = true
				} else {
					flag = false
				}
			})

			this.$refs["form2"].validate(valid => {
				if (valid) {
					flag = true
				} else {
					flag = false
				}
			})

			if (this.form2.enroll_gold_config_list.length === 0) {
				this.$notify.error({ title: "请设置运动金发放配置" })
				return
			}

			if (flag) {
				this.form.start_time = this.form.timeVal[0]
				this.form.end_time = this.form.timeVal[1]
				// let shopIds = this.form.shopIds.join(',')
				// this.form.shops = shopIds.slice(0, shopIds.length)
				// let shopIds2 = this.form.shopIds2.join(',')
				// this.form.majorShops = shopIds2.slice(0, shopIds2.length)
				this.form.shops = this.form.shops + ''
				if (!this.id) {
					// addWeRunConfig({ ...this.form, enrollId: this.weRunId }).then(r => {
					// 	addWeRunGold(this.form2.tableData).then(r => {
					// 		this.$notify.success({ title: "设置成功" })
					// 	}).catch(err => console.log(err))
					// }).catch(err => console.log(err))
					this.form.enroll_gold_config_list = this.form2.enroll_gold_config_list;
					addWeRunGold2({
						enrollId: this.weRunId,
						rule_type: this.radio,
						rule_detail: JSON.stringify(this.form)
					}).then(res => {
						this.$notify.success({ title: "设置成功" })
					})
				} else {
					updateWeRunGold2({
						id: this.id,
						enrollId: this.weRunId,
						rule_type: this.radio,
						rule_detail: JSON.stringify(this.form)
					 }).then(r => {
						this.$notify.success({ title: "设置成功" })
					}).catch(err => console.log(err))
				}
			} else {
				this.$notify.error({ title: "请填写资料完整" })
			}
		},
		handleSubmit2() {
			let flag = false
			this.$refs["form3"].validate(valid => {
				if (valid) {
					flag = true
				} else {
					flag = false
				}
			})

			if (flag) {
				this.form3.start_time = this.form3.timeVal[0];
				this.form3.end_time = this.form3.timeVal[1];
				this.form3.shops = this.form3.shops + '';
				if (!this.id) {
					addWeRunGold2({
						enrollId: this.weRunId,
						rule_type: this.radio,
						rule_detail: JSON.stringify(this.form3)
					}).then(res => {
						this.$notify.success({ title: "设置成功" })
					})
				} else {
					updateWeRunGold2({
						id: this.id,
						enrollId: this.weRunId,
						rule_type: this.radio,
						rule_detail: JSON.stringify(this.form3)
					 }).then(r => {
						this.$notify.success({ title: "设置成功" })
					}).catch(err => console.log(err))
				}
			} else {
				this.$notify.error({ title: "请填写资料完整" })
			}
		},

		initRules() {
			const tempArr = []
			for (let i = 1; i < 8; i++) {
				const face_value = this.form.sports_gold * i
				tempArr.push({
					day: i,
					face_value,
					gold_name: null,
					id: null
				})
			}
			this.form2.enroll_gold_config_list = tempArr
		},
		getDetail() {
			// getWeRunConfigDetail({ id: this.weRunId }).then(r => {
			// 	if (r.data.id) {
			// 		const startTime = r.data.startTime
			// 		const endTime = r.data.endTime
			// 		const timeVal1 = [startTime, endTime]
			// 		// let shopIds = r.data.shops.split(',')
			// 		// shopIds = shopIds.map(v => Number(v))

			// 		// let shopIds2 = r.data.majorShops ? r.data.majorShops.split(',') : []
			// 		// shopIds2 = shopIds2.map(v => Number(v))

			// 		this.form = {
			// 			...r.data,
			// 			shops: Number(r.data.shops),
			// 			// shopIds,
			// 			// shopIds2,
			// 			timeVal1
			// 		}
			// 	}
			// }).catch(err => console.log(err))

			// getWeRunGoldDetail({ id: this.weRunId }).then(r => {
			// 	if (r.data.length !== 0) {
			// 		this.form2.tableData = r.data
			// 		this.isEdit = true
			// 	} else {
			// 		this.isEdit = false
			// 	}
			// }).catch(err => console.log(err))

			getWeRunGoldDetail2({ id: this.weRunId }).then(res => {
				if (res.data.id) {
					this.isEdit = true;
					this.id = res.data.id;
					let type = res.data.rule_type + '';
					this.radio = type;
					let jsonData = JSON.parse(res.data.rule_detail);
					if (type == "2") {
						this.form3 = {
							...jsonData,
							shops: Number(jsonData.shops),
						};
					}
					if (type == "1") {
						this.form = {
							...jsonData,
							shops: Number(jsonData.shops)
						}
						setTimeout(()=>{
							this.form2.enroll_gold_config_list = this.form.enroll_gold_config_list
						},200)
					}
				} else {
					this.isEdit = false
				}
			}).catch(err => console.log(err))
		},
		handleOpenShop() {
			this.dialogShopVisible = true
		},

		addSet() {
			let obj = { continuous_steps_days: "", every_day_steps: "", sports_gold: "", gold_name: "" };
			this.form3.conditionsList.push(obj);
		},

		subSet(index) {
			this.form3.conditionsList.splice(index, 1);
		}
	}
}
</script>

<style lang="scss">
.tag-title {
	border-left: 5px solid #4F8AFF;
	padding-left: 10px;
	margin-top: 10px;
	margin-bottom: 20px;
}

.visibility-hidden {
	visibility: hidden;
}

.rules-day {
	input {
		text-align: center;
	}
}
</style>
