<template>
	<div class="container">
		<el-form ref="dataForm" :model="dataForm" :rules="readOnly ? {} : dataRule" label-width="130px" class="form-box">
			<el-form-item label="店铺名称" prop="name">
				<mvk-input v-model.trim="dataForm.name" maxlength="30" style="max-width: 400px;" :readonly="readOnly" placeholder="请输入店铺名称" />
			</el-form-item>
			<el-form-item label="单位名称" prop="company">
				<mvk-input v-model.trim="dataForm.company" maxlength="30" style="max-width: 400px;" :readonly="readOnly" placeholder="请输入单位名称" />
			</el-form-item>
			<el-form-item label="负责人" prop="person">
				<mvk-input v-model.trim="dataForm.person" maxlength="8" style="max-width: 400px;" :readonly="readOnly" placeholder="请输入负责人" />
			</el-form-item>
			<el-form-item label="负责人电话" prop="phone">
				<el-input v-model="dataForm.phone" maxlength="16" style="max-width: 400px;" :readonly="readOnly" placeholder="请输入负责人电话" />
			</el-form-item>
			<el-form-item label="客服1" prop="customerOneTel">
				<el-input v-model="dataForm.customerOne" maxlength="16" style="max-width: 120px;" :readonly="readOnly" placeholder="客服1昵称" />
				<el-input v-model="dataForm.customerOneTel" maxlength="16" style="margin-left:10px;max-width: 270px;" :readonly="readOnly" placeholder="客服1电话" />
			</el-form-item>
			<el-form-item label="客服2" prop="customerTwoTel">
				<el-input v-model="dataForm.customerTwo" maxlength="16" style="max-width: 120px;" :readonly="readOnly" placeholder="客服2昵称" />
				<el-input v-model="dataForm.customerTwoTel" maxlength="16" style="margin-left:10px;max-width: 270px;" :readonly="readOnly" placeholder="客服2电话" />
			</el-form-item>
			<div style="padding: 0 0 10px 130px; font-size: 12px; color: #999">例如：0757-12345678 或 13012345678</div>
			<el-form-item label="店铺状态" prop="enabled">
				<el-radio-group v-model="dataForm.enabled" :disabled="readOnly">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item>
				<el-button v-if="!readOnly" type="primary" :disabled="absolutely" class="w100"
					@click="Submit('dataForm')"
				>
					保存
				</el-button>

				<!-- <el-popconfirm v-if="!absolutely" title="已修改未保存，是否关闭?" @confirm="close()">
					<template #reference>
						<el-button class="ml20 ">关闭</el-button>
					</template>
				</el-popconfirm>
				<el-button v-else class="ml20 " @click="close()">关闭</el-button> -->
				<el-button :class="{ 'ml20': !readOnly }" @click="close()">关闭</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import * as api from "@/api/business/selfManager/labour"
import { mapActions } from "vuex"

export default {
	props: {
		readOnly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dataForm: this.dataParams(),
			dataRule: {
				name: [{ required: true, message: "请输入店铺名称", trigger: "change" }],
				company: [{ required: true, message: "请输入单位名称", trigger: "change" }],
				person: [{ required: true, message: "请输入负责人", trigger: "change" }],
				phone: [{ required: true, message: "请输入负责人电话", trigger: "change" }]
			},
			// 底片数据
			original: {}
		}
	},
	computed: {
		absolutely() {
			return JSON.stringify(this.original) === JSON.stringify(this.dataForm)
		}

	},
	created() {
		this.type = this.$route.params.type
		this.$route.query.id && this.getDetail(this.$route.query.id)

	},

	methods: {
		...mapActions("dict", ["getLabourShopList"]),
		dataParams() {
			return {
				id: "",
				name: "", // 大哥大小店
				company: "",  // "广东大哥大小店"
				person: "", // 大哥大
				phone: "", // 13016610207
				enabled: false,
				customerOne:"",
				customerOneTel:"",
				customerTwo:"",
				customerTwoTel:""
			}
		},

		async getDetail(id) {
			await api.laborShopDetail({ id }).then(res => {
				if (res.status === "OK") {
					this.original = JSON.parse(JSON.stringify(res.data))
					this.dataForm = res.data
				} else this.$router.back()
			})
		},
		Submit() {
			this.$refs.dataForm.validate(valid => {
				if (valid) {
					if (this.dataForm.id) {
						// 修改
						const params = JSON.parse(JSON.stringify(this.dataForm))
						delete params.type
						api.EditLaborShop(params).then(r => { 
							if (r.status === "OK") {
								this.$message.success("保存成功")
								this.getLabourShopList()
								this.getDetail(this.$route.query.id)
							}
						})
					} else {
						// 新增
						api.AddLaborShop(this.dataForm).then(r => {
							if (r.status === "OK") {
								this.$message.success("保存成功")
								// 改变url
								this.$router.push({ path: "/marketingManager/labour/labourEdit/edit", query: { id: r.data } })
								// 重新获取已经保存的详情
								this.getDetail(r.data)
							}
						})
					}
				}
			})
		},
		close() {
			// 对比空载数据
			const identical = JSON.stringify(this.dataForm) == JSON.stringify(this.dataParams())
			if (this.dataForm.id) {
				// 如果曾保存过（以路由跳转方式导致多了一层，所以改为路径跳转而不是后退）
				this.$router.push("/marketingManager/labour/list")
			} else if (!identical) {
				// 如果跟空载数据不一样则提示
				this.$confirm("此操作表格内容不被保存, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						this.$router.go(-1)
					})
					.catch(() => { })
			} else {
				// 跟空载数据一样直接后退
				this.$router.go(-1)

			}
		}
	}
}
</script>

<style lang="scss" scoped>
.form-box {
	padding: 20px 30px 30px 0;
	background-color: #fff;
}
</style>