import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./permission"
import xss from "xss"
import ElementUI from "element-ui"
// import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/scss/element-ui/element-variables.scss"
Vue.use(ElementUI, { size: "small" })

import "@/assets/scss/index.scss" // global css

import "@/components/components"

import VueUeditorWrap from "vue-ueditor-wrap"

import "@/utils/directive"

Vue.component("VueUeditorWrap", VueUeditorWrap)

import { formatTime } from "./utils/validate"
Vue.filter("formatTime", function(time) {
	if (!time) {
		return ""
	}
	return formatTime(time)
})

Vue.prototype.xss = xss
import { deteleModal } from "@/libs/public"
Vue.prototype.$deteleModal = deteleModal

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app")
