<template>
	<div id="map" style="width: 600px; height: 300px" />
</template>

<script>
import maps from "qqmap"
export default {
	name: "QqMap",

	components: {},

	directives: {},

	data() {
		return {
			map: null,
			markerLayer: null,
			getAddCode: null,
			getAddress: null,
			LatLng: {
				lat: 0,
				lng: 0,
				address: ""
			}
		}
	},

	mounted() {},

	methods: {
		// 根据经纬度展示地图，并可以通过选择地图某个点，进行地址更改
		showMap(lat, lng) {
			var vm = this

			// maps.init("D75BZ-2MJ65-4H2I4-QDIKP-HT5R2-RMB6Y", () => {

			// })

			var center

			if (!lat || !lng) {
				// 默认北京天安门
				center = new qq.maps.LatLng(39.90856, 116.397133)
			} else {
				center = new qq.maps.LatLng(lat, lng)
			}

			vm.map = new qq.maps.Map(document.getElementById("map"), {
				center: center, // 设置地图中心点坐标
				zoom: 15, // 设置地图缩放级别
				viewMode: "2D"
			})

			var size = new qq.maps.Size(53, 52)

			if (lat && lng) {
				vm.markerLayer = new qq.maps.Marker({
					map: vm.map,
					position: center
				})

				vm.markerLayer.setIcon(
					new qq.maps.MarkerImage("https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerNew.png", size)
				)
			}

			qq.maps.event.addListener(this.map, "click", function (evt) {
				// console.log(evt);
				vm.LatLng.lat = evt.latLng.getLat().toFixed(6)
				vm.LatLng.lng = evt.latLng.getLng().toFixed(6)
				vm.$emit("address", vm.LatLng)
				vm.getAddressCode()
				if (vm.markerLayer) {
					vm.markerLayer.setMap(null)
					vm.markerLayer = null
				}

				vm.markerLayer = new qq.maps.Marker({
					map: vm.map,
					position: evt.latLng
				})
				vm.markerLayer.setIcon(
					new qq.maps.MarkerImage("https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerNew.png", size)
				)
			})

			vm.getAddress = new qq.maps.Geocoder({
				complete: function (res) {
					// console.log(res);
					vm.map.setCenter(res.detail.location)
					vm.LatLng.address = res.detail.address
					vm.LatLng.lng = res.detail.location.lng
					vm.LatLng.lat = res.detail.location.lat
					vm.$emit("address", vm.LatLng)
					if (vm.markerLayer) {
						vm.markerLayer.setMap(null)
						vm.markerLayer = null
					}
					const latLng = new qq.maps.LatLng(res.detail.location.lat, res.detail.location.lng)
					vm.markerLayer = new qq.maps.Marker({
						map: vm.map,
						position: latLng
					})
					vm.markerLayer.setIcon(
						new qq.maps.MarkerImage(
							"https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerNew.png",
							size
						)
					)
				}
			})

			vm.getAddCode = new qq.maps.Geocoder({
				complete: function (res) {
					// console.log(res);
					vm.LatLng.address = res.detail.nearPois[0].address
					vm.LatLng.lng = res.detail.nearPois[0].latLng.lng
					vm.LatLng.lat = res.detail.nearPois[0].latLng.lat

					vm.$emit("address", vm.LatLng)
				}
			})
		},

		// 通过经纬度获取地址
		getAddressCode() {
			var lat = parseFloat(this.LatLng.lat)
			var lng = parseFloat(this.LatLng.lng)
			var latLng = new qq.maps.LatLng(lat, lng)
			// 调用获取位置方法
			this.getAddCode.getAddress(latLng)
		},

		// 通过地址获取经纬度(对外接口)
		selectAddress(address) {
			console.log(address)
			this.LatLng.address = address
			this.getAddress.getLocation(address)
		}
	}
}
</script>

<style lang="scss" scoped></style>
