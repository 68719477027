<template>
	<el-cascader
		v-bind="$attrs"
		:options="prodCateList"
		:props="props2"
		clearable
		class="width100"
		:show-all-levels="false"
		:disabled="disabled"
		v-on="$listeners"
		@change="change"
	/>
</template>

<script>
import { proCategoryList } from "api/business/selfManager/category.js"
export default {
	name: "ProdCategoryTree",
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		checkStrictly: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: false
		}
	},
	components: {},
	directives: {},
	data() {
		return {
			prodCateList: [],
			props2: {
				children: "child",
				label: "name",
				value: "id",
				emitPath: false,
				checkStrictly: this.checkStrictly,
				multiple: this.multiple
			}
		}
	},
	mounted() {
		this.getCategory()
	},
	methods: {
		getCategory() {
			proCategoryList({
				pageNum: 0,
				pageSize: 1000
			}).then(res => {
				this.prodCateList = this.handleList(res.data.list)
			})
		},
		change(e) { 
			this.$emit("change", e)
		},
		// 没有child时删除child字段
		handleList(list) {
			var vm = this
			if (list.length > 0) {
				list.forEach(item => {
					if (item.child && item.child != null && item.child.length > 0) {
						vm.handleList(item.child)
					} else {
						delete item.child
					}
				})
			}
			return list
		}
	}
}
</script>

<style lang="scss" scoped></style>
