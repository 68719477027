import moment from "moment"

const today = moment().startOf("day").format("YYYY-MM-DD")
const yesterday = moment().startOf("day").subtract(1, "days").format("YYYY-MM-DD")

/**
 * 正则
 */

// 移动号码 区号+13位 / 13位
export const mobileReg = /^(0|86|17951)?(13[0-9]|15[012356789]|17[01678]|18[0-9]|14[57])[0-9]{8}$/
// 固化号码 0757-12345678
export const telReg = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/

/**
 * 时间选择器
 */
export const DateList = [
	{ text: "全部", value: [] },
	{ text: "今天", value: [today] },
	{ text: "昨天", value: [yesterday] },
	{ text: "最近7天", value: [moment().subtract(7, "days").format("YYYY-MM-DD"), today] },
	{ text: "最近30天", value: [moment().subtract(30, "days").format("YYYY-MM-DD"), today] },
	{ text: "本月", value: [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")] },
	{ text: "本年", value: [moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")] }
]

export const dateTimeList = [
	{ text: "全部", value: "" },
	{ text: "今天", value: "today" },
	{ text: "昨天", value: "yesterday" },
	{ text: "最近7天", value: "7" },
	{ text: "最近30天", value: "30" },
	{ text: "本月", value: "month" },
	{ text: "本年", value: "year" }
]

// 店铺类型
export const shopType = {
	0: "普通",
	1: "内购",
	2: "大客户店",
	3: "自营店"
}

// 订单状态
export const orderStatus = {
	1: "待付款",
	2: "待发货",
	3: "待收货",
	// 4: "待评价",
	5: "交易完成",
	6: "交易关闭",
	7: "申请退款",
	8: "退款中",
	9: "已退款",
	10: "退款失败",
	11: "待自提",
	12: "拒绝退款",
	// 13: "待分享"
}

export const orderCloseType = {
	0: "超时未支付",
	1: "退款关闭",
	2: "买家取消",
	3: "已通过货到付款交易",
	4: "店长取消"
}

export const payType = {
	0: "微信支付",
	1: "积分支付",
	2: "积分 + 现金支付",
	3: "余额支付",
	4: "通用支付"
}

export const consolidatedType = {
	1: "通用金 + 微信支付",
	2: "通用金 + 余额支付"
}
export const pointType = {
	1: "积分兑换",
	2: "积分+现金兑换"
}
export const deliveryMode = {
	0: "邮寄",
	1: "自提"
}

export const rechargePayType = {
	0: "微信支付"
}

export const orderType = {
	0: "普通订单",
	1: "秒杀订单",
	2: "内购订单",
	3: "直播订单",
	4: "新人专享",
	5: "拼团订单",
	6: "积分订单",
	7: "满减订单",
	8: "大客户订单",
	9: "专题订单",
	10: "虚拟订单",
	11: "通用订单"
}

export const shippingList = [
	{
		value: 0,
		label: "全国包邮"
	}, {
		value: 1,
		label: "包邮"
	}, {
		value: 2,
		label: "部分地区包邮"
	}
]

export const bankList = [
	{
		value: 1,
		label: "中国银行"
	}, {
		value: 2,
		label: "中国工商银行"
	}, {
		value: 3,
		label: "中国建设银行"
	}, {
		value: 4,
		label: "中国农业银行"
	}, {
		value: 5,
		label: "招商银行"
	}, {
		value: 6,
		label: "中国民生银行"
	}
]

export const seckillRangeDate = {
	0: "0:00",
	1: "1:00",
	2: "2:00",
	3: "3:00",
	4: "4:00",
	5: "5:00",
	6: "6:00",
	7: "7:00",
	8: "8:00",
	9: "9:00",
	10: "10:00",
	11: "11:00",
	12: "12:00",
	13: "13:00",
	14: "14:00",
	15: "15:00",
	16: "16:00",
	17: "17:00",
	18: "18:00",
	19: "19:00",
	20: "20:00",
	21: "21:00",
	22: "22:00",
	23: "23:00"
}

export const iconList = [
	"baobiaofenxi",
	"caidan",
	"code",
	"dianpuguanli",
	"dingdanguanli",
	"huiyuanguanli",
	"mima",
	"quanxianguanli",
	"role",
	"shangjiaguanli",
	"shangpinguanli",
	"shenghuojiaofei",
	"shouji",
	"shouye",
	"sys",
	"tuichu",
	"yingxiaoguanli",
	"yonghu",
	"zhanghao",
	"zhibo",
	"ziyingguanli",
	"peizhiguanli"
]

export const bannerLocation = {
	10: "首页",
	20: "秒杀",
	30: "拼团",
	40: "满减",
	50: "新人专享",
	60: "特价推荐",
	70: "排行榜",
	80: "积分兑换商品"
}
export const pickerOptions = {
	shortcuts: [{
		text: "最近一周",
		onClick(picker) {
			const end = new Date()
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
			picker.$emit("pick", [start, end])
		}
	}, {
		text: "最近一个月",
		onClick(picker) {
			const end = new Date()
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
			picker.$emit("pick", [start, end])
		}
	}, {
		text: "最近三个月",
		onClick(picker) {
			const end = new Date()
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
			picker.$emit("pick", [start, end])
		}
	}, {
		text: "最近半年",
		onClick(picker) {
			const end = new Date()
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 182)
			picker.$emit("pick", [start, end])
		}
	}, {
		text: "最近一年",
		onClick(picker) {
			const end = new Date()
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
			picker.$emit("pick", [start, end])
		}
	}]
}

// 电子券类型编码 - 枚举值
export const voucherCodeList = {
	4001: "油品券",
	4002: "汽油券",
	4003: "柴油券",
	4004: "非油券",
	4006: "异业券"
}
// 电子券券类型
export const voucherTypeList = {
	10: "满减券",
	40: "折扣券"
}
// 电子券有效期类型
export const voucherValidType = {
	1: "固定",
	2: "动态"
}
// 电子券状态
export const voucherStateList = {
	10: "未使用",
	20: "已使用",
	30: "临期",
	40: "已过期",
	50: "已注销"
	// 90: "已撤销"
}
// 电子券派发状态
export const distrStateList = {
	10: "未派发",
	20: "已派发"
}

// 大客户派发状态枚举

export const distributeState = [
	{ label: "已过期", value: -1, color: "#909399" },
	{ label: "已使用", value: 0, color: "#409EFF" },
	{ label: "未使用", value: 1, color: "#E6A23C" },
	{ label: "电子券临期", value: 2, color: "#333" },
	{ label: "电子券已注销", value: 3, color: "#333" }
]
/**
 *	首页banner链接广告类型
 */
export const refTypeArray = [
	{ label: "无链接", value: 0 },
	{ label: "商品链接", value: 1 },
	{ label: "附近门店", value: 9 },
	{ label: "新人专享", value: 7 },
	{ label: "秒杀活动", value: 3 },
	{ label: "拼团活动", value: 4 },
	{ label: "满减活动", value: 10 },
	{ label: "直播间", value: 6 },
	{ label: "专题活动", value: 11 },
	{ label: "URL链接", value: 12 },
	{ label: "通用店铺", value: 13 },
	{ label: "抽奖活动", value: 14 },
	{ label: "跳转小程序", value: 15 }

]

export const giftCardType = {
	1: "实体卡",
	2: "电子卡"
}

export const ticketType = {
	1: "增值税电子普通发票",
	2: "增值税电子专用发票",
	3: "增值税普通发票",
	4: "增值税专用发票",
	8: "增值税电子普通发票（成品油）",
	9: "成品油普通发票 (卷式)",
	10: "成品油普通发票",
	11: "成品油专用发票",
	12: "增值税普通发票 (卷式)"
}

export const virtualType = [
	{ label: "全部", value: "" },
	{ label: "直充", value: 1 },
	{ label: "卡密", value: 2 },
	{ label: "其他", value: 3 }
]
export const virtualOrderType = {
	1: "待付款",
	2: "处理中",
	5: "交易成功",
	6: "交易失败"
}
