import fetch from "@/axios/fetch"

export function getHolidayActivityList (params) { // 获取节日活动列表
	return fetch({
		url: "/holidayActivity",
		method: "get",
		params
	})
}

export function saveHolidayActivity (data) { // 保存节日活动
	return fetch({
		url: "/holidayActivity",
		method: "post",
		data
	})
}

export function deleteHolidayActivity (id) { // 删除节日活动
	return fetch({
		url: "/holidayActivity/" + id,
		method: "delete",
	})
}

export function disabledHolidayActivity (id) { // 失效节日活动
	return fetch({
		url: "/holidayActivity/" + id + '/disabled',
		method: "put",
	})
}


export function getHolidayList (params) { // 获取节日列表
	return fetch({
		url: "/holiday",
		method: "get",
		params
	})
}

export function saveHoliday (data) { // 保存节日
	return fetch({
		url: "/holiday",
		method: "post",
		data
	})
}

export function deleteHoliday (id) { // 删除节日
	return fetch({
		url: "/holiday/" + id,
		method: "delete",
	})
}
