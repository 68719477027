<template>
	<div class="mvk-layout-content-card">
		<div>
			<div class="flex">
				<el-form size="small" label-width="120px" :inline="true">
					<el-collapse-transition>
						<div v-show="filterShow">
							<div>
								<el-form-item label="订单时间区间：">
									<el-radio-group
										v-model="dateLimit"
										type="button"
										class="mr20"
										size="small"
										@change="onchangeDate"
									>
										<el-radio-button v-for="(item, i) in dateList" :key="i" :label="item.value">
											{{ item.text }}
										</el-radio-button>
									</el-radio-group>
									<el-date-picker
										v-model="timeVal"
										:default-time="['00:00:00', '23:59:59']"
										value-format="yyyy-MM-dd"
										format="yyyy-MM-dd"
										size="small"
										type="daterange"
										placement="bottom-end"
										start-placeholder="开始日期"
										end-placeholder="结束日期"
										style="width: 220px"
										@change="onchangeTime"
									/>
								</el-form-item>
							</div>
							<el-form-item label="订单号：">
								<el-input v-model="queryParam.code" placeholder="请输入订单号" size="small" clearable style="min-width: 220px;" />
							</el-form-item>
							<el-form-item label="商品名称：">
								<el-input v-model="queryParam.productName" placeholder="请输入商品名称" size="small" clearable style="min-width: 220px;" />
							</el-form-item>
							<el-form-item label="所属供应商">
								<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable style="min-width: 220px;">
									<el-option
										v-for="item in supplierlist"
										:key="item.id"
										:label="item.name"
										:value="item.id"
									/>
								</el-select>
							</el-form-item>
							<el-form-item label="所属组织架构：">
								<organizationTreeSelect v-model="queryParam.organId" />
							</el-form-item>
							<el-form-item label="核销员：">
								<el-input v-model="queryParam.pickByName" placeholder="请输入核销员" size="small" clearable style="min-width: 220px;" />
							</el-form-item>
							<el-form-item label="核销员手机号：">
								<el-input v-model="queryParam.mobile" placeholder="请输入核销员手机" size="small" clearable style="min-width: 220px;" />
							</el-form-item>
						</div>
					</el-collapse-transition>
					<div class="mb10">
						<el-button type="primary" @click="filterShow = !filterShow"> 折叠筛选</el-button>
						<el-button type="primary" @click="handleExport()"> 导出报表</el-button>
						<el-button type="primary" @click="showSummary = true"> 生成汇总表</el-button>
						<span class="tips ml10">当前查询到{{ totalElements }}条记录，其中核销商品数{{ prodTotal }}个，核销总金额{{ totalGoodsAmount }}元</span>
					</div>
				</el-form>
			</div>

			<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" border height="540" />
		</div>

		<dialog-summary
			ref="dataSummary"
			v-model="showSummary"
			:supplierlist="supplierlist"
		/>
	</div>
</template>

<script>
import * as utils from "@/utils/utils"
import { GetDateTimeValue } from "@/utils/dateUtils"
import { getPickExport, getPickListsss } from "api/business/financeManage/pick.js"
import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"
import dialogSummary from "./dialogSummary.vue"
import { mapState, mapActions } from "vuex"
import { dateTimeList } from "@/utils/constants"
export default {
	name: "PickStatistics",
	components: {
		organizationTreeSelect,
		dialogSummary
	},
	data() {
		return {
			dateList: dateTimeList,
			filterShow: true,
			timeVal: [],
			dateLimit: "30",
			queryParam: {
				organId: "",
				supplierId: "",
				code: "",
				productName: "",
				pickByName: "",
				mobile: "",
				startDate: "",
				endDate: ""
			},
			tableColumn: [
				{ prop: "id", label: "ID" },
				{ prop: "orderCode", label: "订单编号", width: 190 },
				{ prop: "prodName", label: "商品名称", minWidth: 100 },
				{ prop: "skuName", label: "商品SKU", minWidth: 100 },
				{ prop: "supplierName", label: "供应商", minWidth: 100 },
				{ prop: "shopName", label: "店铺名称" },
				{ prop: "pickByName", label: "核销员" },
				{ prop: "prodCount", label: "商品数量" },
				{ prop: "creationDate", label: "核销时间", width: 140 },
				{ prop: "goodsAmount", label: "核销金额", align: "right" },
				{
					prop: "unQuality",
					label: "本次核销/剩余核销",
					width: 132,
					align: "center",
					formatter: row => {
						return row.quality + "/" + row.unQuality
					}
				},
				{ prop: "mobile", label: "会员手机号", width: 96 }
			],
			tableDataObj: [],
			totalElements: 0,
			totalGoodsAmount: 0,
			prodTotal: 0,
			
			showSummary: false,

			pageNum: 0, // 只用于导出传参
			pageSize: 0 // 只用于导出传参
		}
	},
	computed: {
		...mapState("dict", ["supplierlist"]),
		tableDataFunc() {
			let parameTemp = JSON.parse(JSON.stringify(this.queryParam))
			return parameter => {
				this.pageNum = parameter.pageNum
				this.pageSize = parameter.pageSize
				return getPickListsss(Object.assign(parameTemp, parameter)).then(res => {
					this.tableDataObj = res.data
					// 核销商品总数
					this.totalElements = res.data.list.length
					// 核销总商品数
					this.prodTotal = res.data.list.reduce((sum, e) => { return sum + e.quality }, 0) 
					// 核销总金额
					this.totalGoodsAmount = utils.countTotal(res.data.list, "goodsAmount").toFixed(2)
					return res.data
				})
			}
		}
	},
	created() {
		// 设置初始化日期
		this.timeVal = GetDateTimeValue("30", "YYYY-MM-DD")
		this.queryParam.startDate = GetDateTimeValue("30", "YYYY-MM-DD")[0]
		this.queryParam.endDate = GetDateTimeValue("30", "YYYY-MM-DD")[1]

		// 获取供应商
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	},

	methods: {
		...mapActions("dict", ["getSupplierList"]),
		onchangeDate(val) {
			if (val) {
				let time = GetDateTimeValue(val, "YYYY-MM-DD")
				this.timeVal = time
				this.$set(this.queryParam, "startDate", time[0])
				this.$set(this.queryParam, "endDate", time[1])
			} else {
				this.$set(this.queryParam, "startDate", "")
				this.$set(this.queryParam, "endDate", "")
				this.timeVal = []
			}

		},
		onchangeTime(e) {
			this.timeVal = e
			this.dateLimit = e ? null : ""
			this.$set(this.queryParam, "startDate", e ? e[0] : "")
			this.$set(this.queryParam, "endDate", e ? e[1] : "")
		},
		// 导出
		handleExport() {
			getPickExport(Object.assign(this.queryParam, { pageNum: this.pageNum, pageSize: this.pageSize })).then(res => {
				utils.blobTofile(res, "核销报表.xls", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.tips{
	font-size: 12px;
	color: #555;
}
</style>
