import { render, staticRenderFns } from "./seckillProduct.vue?vue&type=template&id=2924a006&scoped=true"
import script from "./seckillProduct.vue?vue&type=script&lang=js"
export * from "./seckillProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2924a006",
  null
  
)

export default component.exports