<template>
	<div>
		<el-drawer title="商品列表" :visible.sync="goodsListModel" :append-to-body="true" size="50%">
			<div class="height100" style="padding-bottom: 50px">
				<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
					<el-table-column slot="head" width="55">
						<template slot-scope="scope">
							<el-radio
								v-model="templateRadio"
								:label="scope.row.key"
								@change.native="getGoodsInfo(scope.row)"
							>
								<span />
							</el-radio>
						</template>
					</el-table-column>
					<template slot="pic" slot-scope="{ row }">
						<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
					</template>
				</mvk-table>
			</div>
			<div class="custom-drawer-footer">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit">确定</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { saleProdList } from "api/business/selfManager/goods.js"

export default {
	name: "GoodsListModel",
	props: {
		checked: {
			type: Array,
			default: () => []
		}
	},
	components: {},

	directives: {},

	data() {
		return {
			goodsListModel: false,
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 50
				},
				{
					prop: "prodName",
					label: "商品名称"
				},
				{
					prop: "pic",
					label: "商品图片",
					slot: true
				},
				{
					prop: "shopName",
					label: "店铺"
				},
				{
					prop: "price",
					label: "商品价格"
				}
			],

			// 只查询上架的商品
			queryParam: {
				status: "1"
			},
			templateRadio: 0,
			multiCheck: [],
			multiCheckIds: []
		}
	},
	computed: {
		tableDataFunc() {
			// 查询在售中的商品
			return parameter => {
				return saleProdList(Object.assign(parameter, { internal: false })).then(res => {
					res.data.list &&
						res.data.list.forEach(item => {
							item.key = item.id + "$$$" + item.shopId
						})
					return res.data
				})
			}
		}
	},
	watch: {},
	mounted() {
		// console.log(this)
		// console.log(this.$attrs)
	},

	methods: {
		open() {
			var vm = this
			// console.log(this)
			// console.log(this.$attrs)
			this.goodsListModel = true

			this.$nextTick(() => {
				if (this.multiCheckIds.length != this.checked.length) {
					if (this.checked.length > 0) {
						this.checked.forEach(item => {
							// console.log(item)
							vm.$refs.mvkTable.$refs.tableTemplate.toggleRowSelection(item)
						})
					} else {
						this.$nextTick(function() {
							this.$refs.mvkTable.$refs.tableTemplate.clearSelection()
						})
					}
				}
			})
		},

		getGoodsInfo(row) {
			this.multiCheck = row
			this.$emit("getGoodsItem", row)
			this.goodsListModel = false
		},

		handleSubmit() {
			this.$emit("getGoodsList", this.multiCheck)
			this.goodsListModel = false
		}
	}
}
</script>

<style lang="scss" scoped></style>
