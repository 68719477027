<template>
	<div class="labour_box">
		<div class="flex ">
			<!-- <div class="filter-box">
				<span>派发时间</span>
			</div> -->

			<!-- <div class="item_total flex ">
				<div>商品总金额：<span>100元</span></div>
				<div>实付总金额：<span>100元</span></div>
				<div>优惠总金额：<span>100元</span></div>
				<div>成本总金额：<span>100元</span></div>
			</div> -->

			<el-form ref="form" :model="queryParam" :inline="true" class="ml10 flex-row">
				<el-form-item label="订单时间" style="min-width: 100px">
				<el-date-picker v-model="timeVal" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
					type="daterange" start-placeholder="开始时间" end-placeholder="结束时间" placement="bottom-end"
					style="width: 230px"  @change="onchangeTime" />
				</el-form-item>
				<el-form-item label="订单号" style="min-width: 120px">
					<el-input v-model="queryParam.code" clearable placeholder="输入订单号进行搜索" style="width: 163px;"/>
				</el-form-item>
				<el-form-item label="手机号" style="min-width: 120px">
					<el-input v-model="queryParam.phone" clearable placeholder="输入手机号进行搜索" style="width: 163px;"/>
				</el-form-item>
				<el-form-item label="订单状态">
					<el-select
						v-model="queryParam.status"
						placeholder="请选择"
						style="width: 100px"
						clearable
					>
						<el-option v-for="(value,key) in orderStatus" :key="key" :label="value" :value="key" />
					</el-select>
				</el-form-item>
				<el-form-item label="供应商">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable
						style="width: 100%">
						<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="">
					<!-- <el-button type="primary" @click="exportSelect()"> 导出报表</el-button> -->
					<el-button type="primary" @click="onSubmit('memberForm')"> 导出报表</el-button>
					<el-button type="primary" @click="exportSelect()" icon="el-icon-arrow-down" style="margin-left:2px;"></el-button>
				</el-form-item>
			</el-form>

		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<!--自定义操作-->
			<template slot="status" slot-scope="{ row }">
				<el-tag class="mr10">{{ row.status | OrderStatus }}</el-tag>
				<span v-show="row.subStatus"> <el-tag type="danger">{{ row.subStatus | OrderStatus }}</el-tag></span>
			</template>
			<template slot="orderCode" slot-scope="{ row }" label="订单">
				<!-- <div v-for="(code,index) in row._orderCode" :key="index">
					<router-link :to="{ path: '/selfManager/order', query: {code} }" class="order-link">{{ code }}</router-link>
				</div> -->
				<router-link v-if="row._orderCode.length"
					:to="{ path: '/selfManager/order', query: { code: row._orderCode.join() } }" class="order-link">
					{{
						row._orderCode.length
					}}个订单
				</router-link>
				<span v-else> - </span>
			</template>
			<!-- <template slot="termOfValidity" slot-scope="{ row }" label="有效期">
				<span>{{ row.termOfValidity == 1 ? '无限期' : row.termOfValidity }}</span>
			</template> -->
		</mvk-table>
		<el-dialog title="导出 - 页码选择" :visible.sync="showExport" width="300px" top="20vh">
			<el-form ref="memberForm" :model="memberForm" label-width="80px" :inline="false">
				<el-form-item label="页码" prop="pageNum">
					<el-input-number v-model="memberForm.pageNum" :min="1" :max="999" />
				</el-form-item>
				<el-form-item label="条数" prop="pageSize">
					<el-select v-model="memberForm.pageSize"  @change="memberForm.pageNum = 1"
						style="width: 100px;text-align: right;">
						<el-option v-for="num in pageSizeList" :key="num" :label="num" :value="num" style="text-align: right;"/>
					</el-select>
					条/页
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showExport = false">关闭</el-button>
				<el-button type="primary" :loading="loading" @click="onSubmit('memberForm')">导出</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { payType, consolidatedType, orderType, orderStatus } from "@/utils/constants"
import * as api from "@/api/business/selfManager/labour"
// import { getOrder, getOrderExport } from "api/business/selfManager/keyAccount"
import { blobTofile,debounce } from "@/utils/utils"
import { mapState, mapActions } from "vuex"

export default {
	name: "",
	data() {
		return {
			timeVal: [],
			queryParam: {
				code:'',
				phone:'',
				status:'',
				supplierId: ''
			},
			orderStatus,
			consolidatedType,
			tableColumn: [
				{ prop: "code", label: "订单编号", width: 200 },
				{ prop: "memberMobile", label: "买家手机号", width: 120 },
				{
					prop: "payType", label: "支付类型", width: 120,
					formatter: cellvalue => {
						let value = cellvalue.payType
						if (cellvalue.consolidatedType) return consolidatedType[cellvalue.consolidatedType]
						else return payType[value]
					}
				},
				{
					prop: "payMoney", label: "商品总金额", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.payMoney || 0) + "元"
					}
				},
				{
					prop: "costPrice", label: "成本金额", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.costPrice || 0) + "元"
					}
				},
				{
					prop: "couponMoney", label: "优惠金额", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.couponMoney || 0) + "元"
					}
				},
				{
					prop: "actualMoney", label: "实付金额", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.actualMoney || 0) + "元"
					}
				},
				{
					prop: "prodCount", label: "商品数量", width: 80, align: "center"
				},
				{
					prop: "unit", label: "单位", width: 80, align: "center"
				},
				{
					prop: "skuName", label: "商品规格", width: 110, align: "center"
				},
				{
					prop: "sellingPrice", label: "售价单价", width: 80, align: "center",
					formatter: cellvalue => {
						return (cellvalue.sellingPrice || 0) + "元"
					}
				},
				{ prop: "status", label: "订单状态", slot: true, minWidth: 76, align: "center" },
				{ prop: "supplierName", label: "供应商" },
				{ prop: "prodName", label: "商品名称" },

				{ prop: "creationDate", label: "创建时间", width: 150 }
			],

			tableDataFunc: async parameter => {
				if (this.$route.query.id) {
					return api.getOrder(Object.assign(parameter, this.queryParam), this.$route.query.id).then(res => {
						return res.data
					})
				} else {
					return {}
				}
			},

			// 导出-页码选择
			showExport: false,
			loading: false,
			pageSizeList: [100,  500, 1000,5000],
			memberForm: {
				pageNum: 1,
				pageSize: 1000
			}
		}
	},
	filters: {
		OrderStatus(type) {
			return orderStatus[type]
		}
	},
	watch: {
		queryParam: {
			handler: debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},
	created(){

		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	},
	computed: {
		...mapState("dict", ["supplierlist"])
	},
	methods: {
		...mapActions("dict", ["getSupplierList"]),
		getList() {
			this.$refs.mvkTable.refresh()
		},
		onchangeTime(e) {
			this.timeVal = e
			// this.queryParam.time = e ? e[0] + "~" + e[1] : ""
			this.$set(this.queryParam,"time",e ? e[0] + "~" + e[1] : "")
		},
		exportSelect() {
			this.showExport = true
		},
		handleExport() {
			const params = JSON.parse(JSON.stringify(this.memberForm))
			params.pageNum -= 1
			const data = Object.assign(this.queryParam, params)
			api.getOrderExport(this.queryParam, this.$route.query.id).then(res => {
				blobTofile(res, "通用店铺订单明细.xls", "application/vnd.ms-excel")
			})
		},
		onSubmit() {
			this.handleExport()
		}
	}
}
</script>

<style lang="scss" scoped>
.filter-box {
	display: flex;
	align-items: center;
	margin-right: 10px;

	span {
		font-size: 14px;
		color: #333;
	}
}

.item_total {
	margin-left: 10px;
	padding: 4px;
	background-color: #fff;
	color: #999;
	font-size: 12px;

	div {
		margin-right: 14px;
	}

	span {
		color: #000;
		font-size: 15px;
	}
}

.order-link {
	color: #4B5EFF;
}

.labour_box ::v-deep .el-dialog__footer {
	margin-top: -30px;
}
</style>
