<template>
	<!-- 出售中商品 -->
	<div>
		<div class="flex-row" style="justify-content: space-between">
			<!-- <router-link :to="{ path: '/selfManager/creatProduct' }" v-has="'pms:product:add'">
				<el-button size="small" round type="primary" class="mr10">添加商品</el-button>
			</router-link> -->
			<el-form size="small" label-width="100px" :inline="true">
				<el-form-item label="所属组织架构">
					<!-- <el-select v-model="queryParam.shopId" placeholder="所属门店" clearable filterable>
						<el-option v-for="item in shoplist" :key="item.id" :label="item.name" :value="item.id" />
					</el-select> -->
					<organizationTreeSelect v-model="queryParam.organId" style="width: 150px" />
				</el-form-item>
				<el-form-item label="所属供应商" label-width="84px">
					<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable>
						<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="商品分类" label-width="70px">
					<prod-category-tree v-model="queryParam.categoryId" check-strictly />
				</el-form-item>
				<el-form-item label="商品名称" label-width="70px">
					<el-input v-model="queryParam.prodName" placeholder="输入商品名称搜索" clearable style="width: 150px" />
				</el-form-item>
				<el-form-item label="配送方式" label-width="70px">
					<el-select
						v-model="queryParam.deliveryMode"
						placeholder="配送方式"
						clearable
						filterable
						style="width: 110px"
					>
						<el-option v-for="(val, key, index) in deliveryMode" :key="index" :label="val" :value="key" />
					</el-select>
				</el-form-item>
				<el-button
					plain
					type="primary"
					round
					class="ml10"
					@click="exportGoodsList()"
				>
					导出商品列表
				</el-button>
			</el-form>
		</div>
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			header-cell-class-name="custom-table1"
		>
			<el-table-column slot="head" type="expand">
				<template slot-scope="props">
					<el-table :data="props.row.skus" border stripe>
						<el-table-column
							v-for="col in fixedColumn"
							:key="col.prop"
							:prop="col.prop"
							:label="col.label"
							:width="col.width"
							:min-width="col.minWidth"
						>
							<template slot-scope="{ row }">
								<div v-if="col.prop == 'skuName'" class="flex-row">
									<el-image
										style="min-width: 30px; height: 30px; width: 30px"
										:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
										:preview-src-list="[row.pic]"
									/>
									<div style="margin-left: 4px">{{ row.skuName }}</div>
								</div>
								<span v-else>
									{{ row[col.prop] }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<template slot="prodName" slot-scope="{ row }" label="商品图">
				<div class="flex-row">
					<el-image
						style="min-width: 30px; height: 30px; width: 30px"
						:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
						:preview-src-list="[row.pic]"
					/>
					<div style="margin-left: 4px">{{ row.prodName }}</div>
				</div>
			</template>
			<template slot="supplierName" slot-scope="{ row }" label="供应商">
				<div v-if="row.source === 0"><span class="source source0">内</span>{{ row.supplierName }}</div>
				<div v-else><span class="source source1">外</span>{{ row.supplierName }}</div>
			</template>
			<template slot="categoryId" slot-scope="{ row }" label="分类">
				<div>{{ row.id }}</div>
			</template>
			<template slot="pic" slot-scope="{ row }" label="商品图">
				<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
			</template>
			<el-table-column label="操作" width="160" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" class="mr8" @click="addInventory(row)"> 追加上架数 </el-button>
					<el-button
						v-has="'pms:product:offshelf'"
						type="text"
						class="mr8"
						@click="handleGoodsOff(row.prodId)"
					>
						下架
					</el-button>
					<router-link
						v-has="'pms:product:detail'"
						:to="{ path: '/selfManager/creatProduct/' + row.prodId + '/true/false' }"
						class="link"
					>
						<el-button type="text" size="small">详情</el-button>
					</router-link>
				</template>
			</el-table-column>
		</mvk-table>
		<!-- 追加上架数量 -->
		<goodsStockAdd ref="goodsStockAdd" @success="searchList" />
		<!--下架-->
		<goods-off ref="goodsOffRef" @OffSuccess="searchList" />
	</div>
</template>

<script>
import { saleProdList } from "api/business/selfManager/goods.js"

import GoodsOff from "./goodsOff.vue"
import goodsStockAdd from "./goodsStockAdd.vue"
import { deliveryMode } from "utils/constants"
import { commonMixin } from "./mixin"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"
import { exportSaleGoodsList } from "api/business/selfManager/goods.js"
import { blobTofile } from "@/utils/utils"
// 在售中的商品
export default {
	name: "SaleGoods",
	components: {
		GoodsOff,
		goodsStockAdd,
		ProdCategoryTree,
		organizationTreeSelect
	},
	mixins: [commonMixin], // 搜索条件中的店铺以及供应商逻辑
	data() {
		return {
			deliveryMode,
			tableColumn: [
				{ prop: "prodId", label: "商品ID", width: "80" },
				// { prop: "pic", label: "商品图" },
				{ prop: "prodName", label: "商品名称", slot: true },
				{
					prop: "deliveryMode",
					label: "配送方式",
					width: 86,
					align: "center",
					formatter: cellValue => {
						switch (cellValue.deliveryMode) {
							case 0:
								return "邮寄"
							case 1:
								return "自提"
							default:
								return "任意"
						}
					}
				},
				{ prop: "shopName", label: "店铺名称" },
				{ prop: "categoryTree", label: "分类" },
				{ prop: "supplierName", label: "供应商", slot: true },
				{
					prop: "source",
					label: "来源",
					width: 102,
					formatter: cellValue => {
						if (cellValue.source === 1) {
							return "云链美物平台"
						} else if (cellValue.source === 2) {
							return "商户平台"
						} else if (cellValue.source === 0) {
							return "自营平台"
						}
					}
				}
				// { prop: "orderNum", label: "排序", width: "50" }
			],

			queryParam: {
				shopId: "",
				supplierId: "",
				prodName: "",
				categoryId: "",
				deliveryMode: "",
				organizId: ""
			},

			tableDataFunc: parameter => {
				return saleProdList(Object.assign(parameter, this.queryParam)).then(res => {
					return res.data
				})
			},

			fixedColumn: [
				{ prop: "id", label: "SKUId", width: 76 },
				// { prop: "pic", label: "图片" },
				{ prop: "skuName", label: "SKU名称", minWidth: 280 },
				// { prop: "skuName", label: "SKU名称" },
				{ prop: "sellingPrice", label: "售价" },
				{ prop: "costPrice", label: "成本价" },
				{ prop: "marketPrice", label: "市场价" },
				{ prop: "internalPrice", label: "内购价" },
				{ prop: "limitCount", label: "上架数量" },
				{ prop: "stocks", label: "剩余数量" },
				{ prop: "volume", label: "体积" },
				{ prop: "weight", label: "重量" }
			]
		}
	},
	watch: {},

	methods: {
		addInventory(row) {
			this.$refs.goodsStockAdd.open(row)
		},
		handleGoodsOff(id) {
			this.$refs.goodsOffRef.open(id)
		},
		// 导出出售中商品列表
		exportGoodsList() {
			exportSaleGoodsList(this.queryParam).then(res => {
				blobTofile(res, "出售中商品列表", "application/vnd.ms-excel")
			})
		}
	},
	created() {}
}
</script>

<style lang="scss" scoped>
.source {
	display: inline-block;
	margin-right: 4px;
	line-height: 1;
	border: 1px solid #4f8aff;
	color: #4f8aff;
	padding: 4px;
	border-radius: 50px;
	font-size: 12px;
}
.source0 {
	color: #80b568;
	border-color: #80b568;
}
</style>
