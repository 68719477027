<template>
	<div class="height100" style="height: 300px" />
</template>
<script>
const echarts = require("echarts/lib/echarts")
require("echarts/lib/component/grid")
require("echarts/lib/component/tooltip")
require("echarts/lib/component/legend")
require("echarts/lib/chart/line")
require("echarts/lib/chart/bar")
require("echarts/lib/component/title")
import { debounce } from "utils/utils"

/**
 * 维度的数据是横坐标
 * 指标的数据是纵坐标
 */
export default {
	name: "LineChart",
	props: {
		xdata: {
			type: Array,
			require: true
		},
		ydata: {
			type: Array,
			require: true
		}
	},
	computed: {},
	watch: {
		ydata: {
			handler() {
				this.series = []
				this.ydata.forEach(item => {
					this.series.push({
						type: item.type ? item.type : "line",
						name: item.name,
						// smooth: true,
						symbolSize: 6,
						// symbol: 'circle',
						barWidth: 30,
						showSymbol: true,
						data: item.value
					})
				})

				this.$nextTick(() => {
					if (this.series.length > 0) {
						this.initChart()
					}
				})
			},
			deep: true,
			immediate: true
		}
	},
	data() {
		return {
			chart: null,
			series: []
		}
	},
	mounted() {
		window.addEventListener("resize", this.resize)
	},
	methods: {
		initChart() {
			this.chart = echarts.init(this.$el)
			this.chart.clear()
			this.chart.setOption({
				title: {
					left: "center"
				},
				tooltip: {
					trigger: "axis"
					// formatter: function (params) {
					//     params = params[0]
					//     return params.axisValueLabel + '</br>' + params.data + _this.uint
					// },
				},
				legend: {
					show: true,
					type: "scroll",
					orient: "horizontal",
					// right: 10,
					top: 20,
					// bottom: 20,
					data: this.ydata.map(item => item.name)
				},
				grid: {
					left: "8%",
					right: "8%",
					bottom: "8%",
					containLabel: true
				},
				xAxis: {
					// type: 'category',
					// boundaryGap: true,
					axisLine: {
						show: true
					},
					data: this.xdata,
					axisLabel: {
						interval: this.interval,
						rotate: 60,
						showMaxLabel: true
						// color:_this.color[2]
					}
					// boundaryGap: false
				},
				yAxis: [
					{
						type: "value",
						// axisLine: {
						// 	show: true
						// },
						// name: '单位：' + this.uint,
						splitLine: {
							// 坐标轴在 grid 区域中的分隔线
							show: true
						},
						scale: true
					}
				],
				series: this.series
			})
		},
		// 增加防抖
		resize: debounce(function() {
			this.chart && this.chart.resize()
		}, 300)
	},
	beforeDestroy() {
		if (!this.chart) {
			return
		}
		window.removeEventListener("resize", this.resize, true)
		this.chart.dispose()
		this.chart = null
	}
}
</script>
<style lang="scss" scoped></style>
