<template>
	<div class="container">
		<el-tabs v-model="activeName" @tab-click="changeTab">
			<el-tab-pane label="基本信息" name="base">
				<base-info :info="info" :read-only="type == 'detail'" />
			</el-tab-pane>
			<!-- <el-tab-pane label="商品信息" name="goods"> -->
			<el-tab-pane label="商品信息" name="goods" :disabled="type == 'add'">
				<goods-info ref="goods" :read-only="type == 'detail'" />
			</el-tab-pane>
			<el-tab-pane label="会员信息" name="member" :disabled="type == 'add'">
				<member-info v-if="'member' === activeName" ref="member" :read-only="type == 'detail'" />
			</el-tab-pane>
			<el-tab-pane label="使用记录" name="uselist" :disabled="type == 'add'">
				<use-info v-if="'uselist' === activeName" ref="uselist" :read-only="readOnly" />
			</el-tab-pane>
			<el-tab-pane label="派发记录" name="disLabour" :disabled="type == 'add'">
				<distribute v-if="'disLabour' === activeName" ref="disLabour" :read-only="type == 'detail'" />
			</el-tab-pane>
			<el-tab-pane label="订单明细" name="orderDetail" :disabled="type == 'add'">
				<order-detail v-if="'orderDetail' === activeName" ref="orderDetail" :read-only="type == 'detail'" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
export default {
	components: {
		"base-info": () => import("./base.vue"),
		"goods-info": () => import("./goods.vue"),
		"member-info": () => import("./member.vue"),
		"use-info": () => import("./use.vue"),
		"distribute": () => import("./distribute.vue"),
		"order-detail": () => import("./orderDetail.vue")

	},
	data() {
		return {
			activeName: this.$router.history.current.query.activeName || "base",
			type: "add", // add、edit、detail
			dataForm: {},
			dataRule: {},
			info: null,
			readOnly: false
		}
	},
	created() {
		this.type = this.$route.params.type
	},
	computed: {
		id() {
			return this.$route.query.id
		}
	},
	methods: {
		changeTab() {
			const id = this.id
			const params = Object.assign(this.$route.query, { activeName: this.activeName })
			this.$router.push({
				url: this.$route.path,
				query: params
			})
			switch (this.activeName) {
				case "goods":
					id && this.$refs.goods?.getList(id)
					break
				case "member":
					id && this.$refs.member?.getList(id)
					break
				case "uselist":
					id && this.$refs.uselist?.getList(id)
					break
				case "disLabour":
					id && this.$refs.disLabour?.getList(id)
					break
				case "orderDetail":
					id && this.$refs.disLabour?.getList(id)
					break
				default:
					break
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	padding: 4px;
}
</style>
