<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" size="50%">
		<div class="drawer-content">
			<el-form
				ref="dataForm"
				v-loading="loading"
				:model="dataForm"
				:rules="dataRules"
				label-width="150px"
				class="demo-ruleForm"
			>
				<el-form-item label="油站名称：" prop="gsName">
					<mvk-input v-model.trim="dataForm.gsName" placeholder="油站名称" class="inputWidth" />
				</el-form-item>
				<el-form-item label="特别说明：" prop="labelName">
					<mvk-input v-model.trim="dataForm.labelName" placeholder="特别说明" class="inputWidth" />
				</el-form-item>
				<el-form-item label="联系电话：" prop="phone">
					<el-input v-model="dataForm.phone" placeholder="联系电话" class="inputWidth" />
				</el-form-item>
				<el-form-item label="油号：" prop="gsModelArry">
					<tag-add v-model="dataForm.gsModelArry" />
				</el-form-item>
				<el-form-item label="店铺地址：" prop="gsAddrs">
					<mvk-input
						v-model.trim="dataForm.gsAddrs"
						type="textarea"
						placeholder="店铺地址"
						class="inputWidth mb15"
						@change="addressChange"
					/>
					<qq-map ref="stationMap" @address="getAddress" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" round v-throttle="submitForm">确 定</el-button>
					<el-button round @click="addEditDialog = false">取 消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-drawer>
</template>

<script>
import { AddStation, EditStation } from "api/business/selfManager/station"

export default {
	name: "StationAddEdit",

	components: {
		"qq-map": () => import("views/bussiness/components/QQMap"),
		"tag-add": () => import("@/components/TagAdd")
	},

	directives: {},

	data() {
		return {
			title: "新增",
			addEditDialog: false,
			loading: false,

			dataForm: {
				id: "",
				gsName: "",
				phone: "",
				labelName: "",
				gsModel: "",
				gsModelArry: [],
				lng: "",
				lat: "",
				gsAddrs: ""
			},
			dataRules: {
				gsName: [
					{
						required: true,
						message: "请输入油站名称",
						trigger: "blur"
					}
				],
				phone: [
					{
						required: true,
						message: "请输入联系电话",
						trigger: "blur"
					}
				],
				gsAddrs: [
					{
						required: true,
						message: "请输入油站地址",
						trigger: "blur"
					}
				],
				gsModelArry: [
					{
						required: true,
						message: "请输入油号",
						trigger: "change"
					}
				]
			}
		}
	},

	mounted() {},

	methods: {
		add() {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.$refs.stationMap.showMap()
				this.dataForm = {
					id: "",
					gsName: "",
					phone: "",
					labelName: "",
					gsModel: "",
					gsModelArry: [],
					lng: "",
					lat: "",
					gsAddrs: ""
				}
			})
		},
		edit(record) {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.dataForm = JSON.parse(JSON.stringify(record))
				this.$set(this.dataForm, "gsModelArry", this.dataForm.gsModel.split(";"))
				this.$refs.stationMap.showMap(this.dataForm.lat, this.dataForm.lng)
			})
		},

		addressChange() {
			this.$refs.stationMap.selectAddress(this.dataForm.gsAddrs)
		},

		getAddress(data) {
			this.dataForm.lng = data.lng
			this.dataForm.lat = data.lat
			this.dataForm.gsAddrs = data.address
		},

		submitForm() {
			this.$refs["dataForm"].validate((valid) => {
				if (valid) {
					this.dataForm.gsModel = this.dataForm.gsModelArry.join(";")

					console.log(this.dataForm.gsModel)
					if (this.dataForm.id) {
						EditStation(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "修改成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "修改失败"
								})
							})
					} else {
						// 新增
						AddStation(this.dataForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "添加成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.$notify.error({
									title: "错误",
									message: "添加失败"
								})
							})
					}
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
