import fetch from "@/axios/fetch"

// 订单结算

export function getSettleOrderList(data) { // 结算订单列表
	return fetch({
		url: "/settlement/list",
		method: "post",
		data
	})
}

export function getSettleDetailList(params) { // 结算明细列表
	return fetch({
		url: "/settlement/settlement_details_list",
		method: "get",
		params
	})
}

export function orderSettleReport(data) { // 生成报表
	return fetch({
		url: "/settlement/gen_settle_report",
		method: "post",
		data
	})
}

export function orderSettle(data) { // 订单结算
	return fetch({
		url: "/settlement/settle",
		method: "put",
		data
	})
}

export function getSettleReport(data) { // 结算报表列表
	return fetch({
		url: "/settlement/settle-report",
		method: "post",
		data
	})
}

export function getSettlementDetailExport(data) { // 结算明细导出
	return fetch({
		url: "/settlement/export_settlement_details_list",
		method: "post",
		data,
		responseType: "blob"
	})
}

export function getExportSettlement(data) { // 结算报表导出
	return fetch({
		url: "/settlement/export-settle",
		method: "post",
		data,
		responseType: "blob"
	})
}

export function getExportReportDetail(params) { // 报表明细导出
	return fetch({
		url: "/settlement/export-items",
		method: "get",
		params,
		responseType: "blob"
	})
}

export function cancelReport(reportCode) { // 取消结算报表
	return fetch({
		url: "/settlement/export-cancel/" + reportCode,
		method: "delete"
	})
}

export function getStatistics(params) { // 报表明细统计数据
	return fetch({
		url: "/settlement/settlement_details_statistics",
		method: "get",
		params
	})
}
