import fetch from "@/axios/fetch"
// 地区管理

export function getAreaToParent(pcode = 0) { // 查询子级地区
    return fetch({
        url: "/area/children",
        method: "get",
        params: { pcode }
    })
}

export function getAreaToLevel(params) { // 分页查询同一层级地区
    return fetch({
        url: "/area/level",
        method: "get",
        params
    })
}
export function getNotification(params) { // 获取通知配置
    return fetch({
        url: "/notification/setting",
        method: "get",
        params
    })
}

export function setNotification(data) { // 保存通知配置
    return fetch({
        url: "/notification/save",
        method: "post",
        data
    })
}

