import fetch from "@/axios/fetch"

export function lotteryList(params) { // 查询抽奖活动列表
	return fetch({
		url: "/lottery",
		method: "get",
		params
	})
}
export function addLottery(data) { // 新增抽奖活动信息
	return fetch({
		url: "/lottery",
		method: "post",
		data
	})
}
export function editLottery(data) { // 编辑抽奖活动信息
	return fetch({
		url: "/lottery",
		method: "put",
		data
	})
}
export function lotteryOpen(id) { // 是否启用
	return fetch({
		url: "/lottery/enabled/" + id,
		method: "put"
	})
}
export function setPrizes(data) { // 设置奖品
	return fetch({
		url: "/lottery/set/prizes",
		method: "post",
		data
	})
}
export function getPrizes(id) { // 获取奖品信息
	return fetch({
		url: "/lottery/get/prizes/" + id,
		method: "get"
	})
}

export function lotteryById(id) { // 根据ID查询
	return fetch({
		url: "/lottery/" + id,
		method: "get"
	})
}
