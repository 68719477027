import fetch from "@/axios/fetch"

export function couponList(data) { // 优惠券列表
	return fetch({
		url: "/coupons",
		method: "get",
		params: data
	})
}

export function couponDelete(id) { // 优惠券删除
	return fetch({
		url: "/coupons/" + id,
		method: "delete"
	})
}

export function couponAdd(data) { // 优惠券添加
	return fetch({
		url: "/coupons",
		method: "post",
		data
	})
}

export function changeStatus(data) { // 修改状态
	return fetch({
		url: "/coupons/update_status",
		method: "put",
		data
	})
}
export function couponDeclare(data) { // 优惠券说明
	return fetch({
		url: "/coupons/declare",
		method: "put",
		data
	})
}

export function couponInfo(id) { // 优惠券详情
	return fetch({
		url: "/coupons/info/" + id,
		method: "get"
	})
}

export function singleRecordList(params, id) { // 单个优惠券领取记录
	return fetch({
		url: "/coupons/" + id,
		method: "get",
		params
	})
}

export function userRecords(params) { // 会员优惠券领取记录
	return fetch({
		url: "/userRecords",
		method: "get",
		params
	})
}

// ============================================= 电子券系列 =============================================

export function queryEcouponList(params) { // 获取电子券列表
	return fetch({
		url: "/coupon/list",
		method: "get",
		params
	})
}
export function queryListByAlias(params) { // 根据券类型及其他条件查询
	return fetch({
		url: "/coupon/list-by-alias",
		method: "get",
		params
	})
}
export function couponCance(data) { // 注销电子券(可批量)
	return fetch({
		url: "/coupon/cance",
		method: "post",
		data
	})
}

// =============== 电子券 - 类型
export function getEcouponList(params) { // 批量获取电子券
	return fetch({
		url: "/coupon/type/ecoupon-list",
		method: "get",
		params
	})
}

export function ecouponAliasList(params) { // 获取券类型别名-全部
	return fetch({
		url: "/coupon/type/All",
		method: "get",
		params
	})
}
export function flushCouponType(data) { // 同步刷新
	return fetch({
		url: "/coupon/type/flush",
		method: "post",
		data
	})
}

export function queryEcouponTypeList(params) { // 根据条件查询列表
	return fetch({
		url: "/coupon/type/list",
		method: "get",
		params
	})
}
export function couponTypeDetail(alias) { // 查询电子券类型详情
	return fetch({
		url: "/coupon/type/" + alias,
		method: "get"
	})
}

export function ecouponRecords(params) { // 查询电子券领取记录
	return fetch({
		url: "/couponUserRecords",
		method: "get",
		params
	})
}
export function ecouponRules(alias) { // 根据电子券类型查询发放规则
	return fetch({
		url: "/coupon/rules/" + alias,
		method: "get"
	})
}
export function ecouponRulesEdit(data) { // 保存或修改发放队则
	return fetch({
		url: "/coupon/rules/update",
		method: "post",
		data
	})
}
export function resetRules(alias) { // 初始化规则
	return fetch({
		url: "/coupon/rules/init/" + alias,
		method: "post"
	})
}
// ====================== 电子券派发记录接口

export function couponDistrAdd(data) { // 派发电子券
	return fetch({
		url: "/coupon/distr/add",
		method: "post",
		data
	})
}

export function couponDistrDownload() { // 下载派发电子券excel模板
	return fetch({
		url: "/coupon/distr/download",
		method: "get",
		responseType: "arraybuffer"
	})
}
export function couponDistrList(params) { // 查询电子券派发记录列表分页
	return fetch({
		url: "/coupon/distr/list",
		method: "get",
		params
	})
}

// 电子券用户领取记录接口
export function couponUserRecords(params) { // 分页查询
	return fetch({
		url: "/couponUserRecords",
		method: "get",
		params
	})
}

export function getEcouponBill(params) { // 统计报表
	return fetch({
		url: "/coupon/bill/list",
		method: "get",
		params
	})
}

export function downloadEcouponBill(params) { // 下载统计报表
	return fetch({
		url: "/coupon/bill/download-new",
		method: "get",
		params,
		responseType: "blob"
	})
}
