<template>
	<div id="app" style="display: none">
		<router-view />
	</div>
</template>
<script>
export default {
	updated() {
		document.getElementById("app").style.display = "block";
		document.getElementById("loading").style.display = "none";
	}
}
</script>
<style lang="scss"></style>
