<template>
	<!-- <el-card :body-style="{padding:'15px'}"> -->
	<transition name="fade-transform" mode="out-in">
		<keep-alive v-if="$route.meta.keepAlive">
			<router-view :key="key" />
		</keep-alive>
		<router-view v-else :key="key" />
	</transition>
	<!-- </el-card> -->
</template>

<script>
export default {
	name: "AppMain",
	components: {
	},
	computed: {
		key() {
			return this.$route.path
		}
	}
}
</script>

<style lang="scss" scope>
</style>