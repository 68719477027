<template>
	<div class="mvk-layout-content-card">
		<div class="options flex flex-sb mb10">
			<div>
				<el-button size="small" type="primary" round @click="handleAdd()">
					增加标签
				</el-button>
			</div>
			<div>
				<el-input v-model="queryParam.name" placeholder="请输入分类名称查找" prefix-icon="el-icon-search" clearable
					@input="search()"
				/>
			</div>
		</div>
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
		>
			<!--自定义操作-->
			<template slot="enabled" slot-scope="{ row }" label="开启状态">
				<el-switch
					v-model="row.enabled"
					@change="handleStatusChange(row.id)"
				/>
			</template>
			<template slot="productNum" slot-scope="{ row }">
				<router-link v-if="row.productNum"
					:to="{ path: '/marketingManager/labour/labourEdit/detail', query: { id: row.id,activeName:'goods' } }" class="link"
				>
					{{ row.productNum }}个
				</router-link>
				<span v-else> - </span>
			</template>
			<el-table-column label="操作" width="80">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleEdit(row)">
						编辑
					</el-button>
				</template>
			</el-table-column>
		</mvk-table>

		<add-edit-dialog ref="modalForm" @saveSuccess="handleAddOrEditSave" />
	</div>
</template>

<script>
import * as api from "api/business/selfManager/label.js"

import { listMixin } from "views/mixin/listMixin"
import { permissionMixin } from "views/mixin/permissionMixin"
import { debounce } from "utils/utils"

export default {
	components: {
		"add-edit-dialog": () => import("./addEdit.vue")
	},
	props: {},
	mixins: [listMixin, permissionMixin],
	data() {
		return {
			// 查询条件参数
			queryParam: { name: "" },
			tableColumn: [
				{
					prop: "name",
					label: "名称"
				},
				{
					prop: "enabled",
					label: "状态",
					slot: true
				},
				{
					prop: "sort",
					label: "排序"
				},
				{
					prop: "productNums",
					label: "店铺商品",
					align: "center",
					// width: 76,
					slot: true
				}
			],
			tableDataFunc: parameter => {
				return api.getLabelList(Object.assign(parameter, this.queryParam)).then(res => {
					let resData = JSON.parse(JSON.stringify(res.data.list))
					sessionStorage.setItem("eshop-labelList", JSON.stringify(resData))
					return res.data
				})
			}
		}
	},
	watch: {},
	computed: {},
	methods: {
		search: debounce(function() {
			this.$refs.mvkTable.refresh()
		}, 300),
		// 上架、下架操作
		handleStatusChange(id) {
			api.enabledLabel(id)
				.then(() => {
					this.$notify.success({
						title: "成功",
						message: "修改成功"
					})
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					this.$notify.error({
						title: "错误",
						message: "修改失败"
					})
				})
		}
	},
	created() {},
	mounted() {}
}
</script>
<style lang="scss" scoped></style>
