<template>
	<div class="mvk-layout-content-card">
		<el-form ref="form" :model="form" label-width="80px" :inline="false">
			<el-form-item label="新人专享" prop="open">
				<el-radio-group v-model="form.open">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="选择商品" prop="newcomersProductList">
				<div class="flex flex-column-center">
					<div class="upLoadPicBox" @click="changeGood">
						<div class="upLoad">
							<i class="el-icon-camera cameraIconfont" />
						</div>
					</div>
					<div style="margin-left: 20px; color: #acacac">
						新用户商品最多可选择10个，商品规格可设置新用户价格和新用户限购数
					</div>
				</div>

				<el-table
					:data="form.newcomersProductList"
					class="mt15 border-table"
					header-cell-class-name="custom-table1"
				>
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-table :data="props.row.newcomersSkuList" stripe>
								<el-table-column
									v-for="col in fixedColumn"
									:key="col.prop"
									:prop="col.prop"
									:label="col.label"
								>
									<template slot-scope="{ row }">
										<el-image
											v-if="col.prop == 'pic'"
											style="width: 30px; height: 30px"
											:src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
											:preview-src-list="[row.pic]"
										/>
										<span v-else>
											{{ row[col.prop] }}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="price" label="新用户价格">
									<template slot-scope="scope">
										<el-input-number
											v-model="scope.row['price']"
											size="small"
											:min="0"
											:precision="2"
											:step="0.1"
										/>
									</template>
								</el-table-column>
								<el-table-column prop="limits" label="新用户限购数">
									<template slot-scope="scope">
										<el-input-number
											v-model="scope.row['limits']"
											size="small"
											type="number"
											:min="1"
											:step="1"
											step-strictly
										/>
									</template>
								</el-table-column>
								<el-table-column>
									<template slot-scope="scope">
										<delete-button
											:product-index="props.$index"
											:sku="scope"
											button-type="text"
											size="small"
											@delete="handleSkuDelete"
										/>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</el-table-column>
					<el-table-column prop="id" label="ID" width="180" />
					<el-table-column prop="pic" label="商品图">
						<template slot-scope="{ row }">
							<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
						</template>
					</el-table-column>
					<el-table-column prop="prodName" label="商品名称" />
					<el-table-column label="操作" width="230" align="center" header-align="center">
						<template slot-scope="scope">
							<delete-button
								:index="scope.$index"
								button-type="text"
								size="small"
								@delete="handleProductDelete"
							/>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>
			<el-form-item label="结算优惠">
				<el-checkbox-group v-model="checkList">
					<el-checkbox label="coupon">优惠券</el-checkbox>
					<el-checkbox label="fullReduction">满减</el-checkbox>
					<el-checkbox label="hongbao">红包</el-checkbox>
					<el-checkbox label="cashBack">订单返现</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="handleSubmit">保存</el-button>
			</el-form-item>
		</el-form>
		<!--展示仓库中的商品-->
		<goods-list-model
			ref="goodsList"
			:multi="true"
			type="ware"
			supplier
			:checked="checkedGoods"
			@getGoodsList="getGoodsItem"
		/>
	</div>
</template>

<script>
import GoodsListModel from "views/bussiness/components/GoodsListModel.vue"
import { getNewComersConfig, setNewComersConfig } from "api/business/marketing/newcomers"
// eslint-disable-next-line no-unused-vars
import { debounce } from "utils/utils"
export default {
	name: "NewPeopleIndex",

	data() {
		return {
			form: {
				open: true,
				cashBack: false,
				coupon: false,
				fullReduction: false,
				hongbao: false,
				newcomersProductList: [],
				deleteIds: []
			},
			checkedGoods: [],
			fixedColumn: [
				{ prop: "skuId", label: "SKUId" },
				{ prop: "pic", label: "图片" },
				{ prop: "skuName", label: "SKU名称" },
				{ prop: "sellingPrice", label: "售价" }
			],
			checkList: []
		}
	},
	components: {
		GoodsListModel
	},
	mounted() {
		this.getConfig()
	},

	methods: {
		changeGood() {
			this.$refs.goodsList.open()
		},
		// 选择商品
		getGoodsItem(goodsInfo) {
			// console.log(goodsInfo)
			if (this.form.newcomersProductList.length + goodsInfo.length > 10) {
				this.$message.warning("最多只能选10个商品")
				return
			}

			let checkedProdIds = this.form.newcomersProductList.map(item => item.id)

			let tempInfo = []

			goodsInfo &&
				goodsInfo.forEach(item => {
					let index = checkedProdIds.indexOf(item.id)
					if (index > -1) {
						if (this.form.newcomersProductList[index].newcomersSkuList.length == item.skus.length) {
							this.$message.warning(`已经选择了ID为${item.id}的商品,已剔除该商品`)
							return
						}
					} else {
						tempInfo.push(item)
					}
				})

			this.form.newcomersProductList = this.form.newcomersProductList.concat(tempInfo)
			this.form.newcomersProductList.forEach(item => {
				if (item.skus) {
					item.skus.forEach((sku, index) => {
						sku.price = 0.01
						sku.limits = 1
						sku.skuId = sku.id
						sku.id = ""
					})

					item.newcomersSkuList = item.skus
				}
			})
		},

		getConfig() {
			getNewComersConfig().then(res => {
				this.form = res.data

				this.form.newcomersProductList.forEach(item => {
					item.id = item.productId
				})

				if (this.form.cashBack) {
					this.checkList.push("cashBack")
				}
				if (this.form.coupon) {
					this.checkList.push("coupon")
				}
				if (this.form.fullReduction) {
					this.checkList.push("fullReduction")
				}
				if (this.form.hongbao) {
					this.checkList.push("hongbao")
				}

				this.$set(this, "checkList", this.checkList)

				this.form.deleteIds = []
			})
		},
		// 单纯sku删除
		handleSkuDelete(data) {
			// console.log(data)
			let productIndex = data.productIndex
			let skuIndex = data.sku.$index
			if (data.sku.row.id != "") {
				this.form.deleteIds.push(data.sku.row.id)
			}

			this.form.newcomersProductList[productIndex].newcomersSkuList.splice(skuIndex, 1)
		},
		// 商品删除
		handleProductDelete(data) {
			let index = data.index

			let product = this.form.newcomersProductList[index]
			let delectIds = []
			product.newcomersSkuList &&
				product.newcomersSkuList.forEach(sku => {
					if (sku.id != "") {
						delectIds.push(sku.id)
					}
				})

			this.form.deleteIds = this.form.deleteIds.concat(delectIds)

			this.form.newcomersProductList.splice(index, 1)
		},

		handleSubmit() {
			if (this.form.newcomersProductList.length > 10) {
				this.$message.warning("最多只能选10个商品")
				return
			}

			this.form.cashBack = this.checkList.indexOf("cashBack") > -1
			this.form.coupon = this.checkList.indexOf("coupon") > -1
			this.form.fullReduction = this.checkList.indexOf("fullReduction") > -1
			this.form.hongbao = this.checkList.indexOf("hongbao") > -1

			console.log(this.form)
			let newcomersSkuList = []
			this.form.newcomersProductList.forEach(item => {
				item.newcomersSkuList.forEach(sku => {
					let temp = {}
					temp.productId = item.id
					temp.id = sku.id
					temp.skuId = sku.skuId
					temp.price = sku.price
					temp.limits = sku.limits

					newcomersSkuList.push(temp)
				})
			})

			this.form.newcomersSkuList = newcomersSkuList

			if (this.form.newcomersSkuList.length == 0) {
				this.$message.warning("请选择商品")
				return
			}

			setNewComersConfig(this.form)
				.then(() => {
					this.$notify.success({
						title: "保存成功"
					})
					this.getConfig()
				})
				.catch(() => {
					this.$notify.error({
						title: "保存失败"
					})
				})
		}
	}
}
</script>

<style lang="sass" scoped></style>
