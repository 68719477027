<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" :destroy-on-close="true" size="50%">
		<el-form ref="bannerForm" v-loading="loading" :model="bannerForm" :rules="bannerRules" label-width="150px">
			<el-form-item label="Banner位置" prop="bannerAddress">
				<el-select v-model="bannerForm.bannerAddress" placeholder="Banner位置">
					<el-option
						v-for="item in bannerAddress"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</el-form-item>
			<el-form-item label="链接形式" prop="linkForm">
				<el-radio-group v-model="bannerForm.linkForm" @change="linkChange">
					<el-radio :label="10">图片</el-radio>
					<el-radio :label="20">视频</el-radio>
					<!-- <el-radio :label="30">无链接</el-radio> -->
				</el-radio-group>
			</el-form-item>
			<el-form-item label="链接类型" prop="refType">
				<el-select v-model="bannerForm.refType" placeholder="请选择链接类型" @change="refTypeChange">
					<el-option v-for="item in refTypeArray" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
			</el-form-item>
			<!-- 链接描述 > 商品 -->
			<el-form-item
				v-show="bannerForm.refType === 1"
				label="选择商品"
				prop="linkAddress"
				:rules="bannerForm.refType == 1 ? bannerRules.linkAddress : [{ required: false }]"
			>
				<div class="flex-row">
					<div class="upLoadPicBox" @click="changeGood">
						<div class="upLoad">
							<img v-if="goodsInfo.id != ''" :src="goodsInfo.pic">
							<i v-else class="el-icon-camera cameraIconfont" />
						</div>
					</div>
				</div>
			</el-form-item>
			<!-- 链接描述 > 直播间 -->
			<el-form-item
				v-show="bannerForm.refType === 6"
				label="选择直播间"
				prop="refNo"
				:rules="
					bannerForm.refType == 6
						? [{ required: true, message: '请选择直播间', trigger: 'change' }]
						: [{ required: false }]
				"
			>
				<div class="flex-row">
					<div class="upLoadPicBox" @click="openLiveRoow">
						<div class="upLoad">
							<img v-if="liveInfo && liveInfo.coverImgUrl != ''" :src="liveInfo.coverImgUrl">
							<i v-else class="el-icon-video-camera cameraIconfont" />
						</div>
						<span class="ml10">{{ bannerForm.refDesc }}</span>
					</div>
				</div>
			</el-form-item>
			<!-- 链接描述 > 专题活动 -->
			<el-form-item
				v-show="bannerForm.refType === 11"
				label="选择专题活动"
				prop="refNo"
				:rules="
					bannerForm.refType == 11
						? [{ required: true, message: '请选择专题活动', trigger: 'change' }]
						: [{ required: false }]
				"
			>
				<div class="flex-row">
					<div class="upLoadPicBox" @click="openTopicModel">
						<div class="upLoad">
							<img v-if="topicInfo && topicInfo.imagesArray.length > 0" :src="topicInfo.imagesArray[0]">
							<i v-else class="el-icon-plus cameraIconfont" />
						</div>
						<span class="ml10">{{ bannerForm.refDesc }}</span>
					</div>
				</div>
			</el-form-item>
			<el-form-item v-show="bannerForm.refType === 12" label="URL链接" prop="url">
				<el-input v-model="bannerForm.url" clearable style="width:300px;" /> <div style="color: #aaa;font-size: 13px;">* URL地址必须在小程序的业务域名中进行配置才能生效</div>
			</el-form-item>
			<el-form-item v-show="bannerForm.refType === 13" label="通用店铺" prop="url">
				<el-select v-model="bannerForm.refNo" placeholder="请选择店铺">
					<el-option v-for="(item,index) in labourShopList" :key="index" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			<el-form-item v-show="bannerForm.refType === 15" label="小程序AppID" prop="url">
				<el-input v-model="bannerForm.refNo" clearable style="width:300px;" /> <div style="color: #aaa;font-size: 13px;">* 条件：打开同一公众号下关联的另一个小程序。（注：必须是同一公众号下，而非同个 open 账号下）</div>
				<el-input v-model="bannerForm.url" clearable style="width:300px;" /> <div style="color: #aaa;font-size: 13px;">默认：小程序跳转路径pages/index</div>
			</el-form-item>
			<!-- <el-form-item v-show="bannerForm.refType === 14" label="抽奖活动" prop="url">
				<el-select v-model="bannerForm.refNo" placeholder="请选择店铺">
					<el-option v-for="(item,index) in luckDrawList" :key="index" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item> -->
			<el-form-item label="展示排序" prop="sort">
				<el-radio-group v-model="bannerForm.sort">
					<el-radio-button :label="1" />
					<el-radio-button :label="2" />
					<el-radio-button :label="3" />
					<el-radio-button :label="4" />
				</el-radio-group>
			</el-form-item>
			<el-form-item
				v-show="bannerForm.linkForm == 10"
				label="BANNER图片上传"
				prop="img"
				:rules="
					bannerForm.linkForm == 10
						? [{ required: true, message: '请上传图片', trigger: 'change' }]
						: [{ required: false }]
				"
			>
				<upload-image ref="imageModel" v-model="bannerForm.img" :image-url="bannerForm.img" />
				<p style="color: #aaa">图片建议1.25:1的尺寸，像素大小为：360x288</p>
			</el-form-item>
			<el-form-item
				v-show="bannerForm.linkForm == 20"
				label="BANNER视频上传"
				prop="linkAddress"
				:rules="
					bannerForm.linkForm == 20
						? [{ required: true, message: '请上传视频', trigger: 'change' }]
						: [{ required: false }]
				"
			>
				<el-upload
					action
					:http-request="handleUploadForm"
					:headers="uploadHeaders"
					:before-upload="beforeUploadVideo"
					:show-file-list="false"
				>
					<el-button size="small" type="primary">点击上传</el-button>
					<p slot="tip" style="color: #aaa">只能上传mp4视频文件，且不超过5MB</p>
				</el-upload>

				<video v-if="fileInfo && fileInfo.url" width="320" height="240" controls :src="fileInfo.url">
					<!-- <source  :type="fileInfo.type" /> -->
				</video>
			</el-form-item>
			<el-form-item>
				<el-button v-throttle="handleSubmit" type="primary">保存</el-button>
			</el-form-item>
		</el-form>

		<!--展示仓库中的商品-->
		<goods-list-model ref="goodsList" :checked="checkedGoods" @getGoodsItem="getGoodsItem" />
		<!-- 直播列表框 -->
		<lives-list-model ref="liveModel" :checked="liveInfo" @success="submitLiveRoow" />

		<!-- 专题活动列表框 -->
		<topic-list-model ref="topicModel" :checked="liveInfo" @success="getTopicItem" />
	</el-drawer>
</template>

<script>
import UploadImage from "views/bussiness/components/UploadImage"

import { addBanner, editBanner } from "api/basePage/banner"

import { getToken } from "api/auth"

import { fileUploadApi } from "api/business/selfManager/picture"
import { laborShop } from "api/business/selfManager/labour"
import { bannerLocation, refTypeArray } from "@/utils/constants"

const formDemo = {
	id: "",
	bannerAddress: 10,
	linkForm: 10,
	linkAddress: "",
	sort: "",
	img: "",
	videoUrl: "",
	imgUrl: "",
	address: "",
	refType: null,
	refNo: "",
	url: ""
}

export default {
	name: "BannerAddEdit",
	components: {
		GoodsListModel: () => import("./goodsList.vue"),
		LivesListModel: () => import("./liveList.vue"),
		TopicListModel: () => import("./topicList.vue"),
		UploadImage
	},
	data() {
		return {
			loading: false,
			title: "新增",
			addEditDialog: false,
			bannerForm: Object.assign({}, formDemo),
			checkedGoods: [],
			bannerRules: {
				bannerAddress: [
					{
						required: true,
						message: "请选择Banner位置",
						trigger: "change"
					}
				],
				linkForm: [
					{
						required: true,
						message: "请选择链接形式",
						trigger: "change"
					}
				],
				sort: [
					{
						required: true,
						message: "请选择展示排序",
						trigger: "change"
					}
				],
				linkAddress: [
					{
						required: true,
						message: "请选择商品",
						trigger: "change"
					}
				]
			},
			bannerAddress: [],
			refTypeArray,
			goodsInfo: {
				id: ""
			},

			uploadHeaders: { "X-Token": getToken() },

			fileInfo: {},
			linkFormCache: 10,
			checkedId: "",
			liveInfo: null,
			topicInfo: null,
			labourShopList: [] // 通用店铺列表
			// luckDrawList: [] // 抽奖活动列表
		}
	},

	mounted() {
		for (var key in bannerLocation) {
			this.bannerAddress.push({
				value: Number(key),
				label: bannerLocation[key]
			})
		}
		this.getLabourShopList()
		// this.getLuckDrawList()
	},

	methods: {
		add() {
			this.addEditDialog = true
			this.bannerForm = JSON.parse(JSON.stringify(formDemo))
			this.fileInfo = {}
			this.goodsInfo = { id: "" }
			this.linkFormCache = this.bannerForm.linkForm
		},
		edit(record) {
			this.addEditDialog = true
			this.checkedGoods = []
			this.goodsInfo = { id: "" }
			this.$nextTick(() => {
				this.bannerForm = JSON.parse(JSON.stringify(record))
				if (this.bannerForm.linkForm == 10 && this.bannerForm.refType === 1) {
					this.goodsInfo = JSON.parse(this.bannerForm.linkAddress)
					this.bannerForm.videoUrl = ""
					this.bannerForm.imgUrl = this.bannerForm.img
					this.bannerForm.address = this.bannerForm.linkAddress
					this.fileInfo = {}
					this.checkedGoods.push(this.goodsInfo.id)
				} else {
					this.fileInfo.url = this.bannerForm.linkAddress
					this.bannerForm.videoUrl = this.bannerForm.linkAddress
					this.bannerForm.imgUrl = ""
					this.bannerForm.address = ""
					// this.bannerForm.img = ''
				}

				this.linkFormCache = this.bannerForm.linkForm
			})
		},

		linkChange() {
			this.bannerForm.img = ""
			this.bannerForm.linkAddress = ""
		},
		// 改变链接类型
		refTypeChange() {
			this.bannerForm.refDesc = ""
			this.bannerForm.refNo = ""
		},

		changeGood() {
			this.$refs.goodsList.open()
		},

		getGoodsItem(goodsInfo) {
			this.goodsInfo = goodsInfo
			let temp = {}
			temp.id = this.goodsInfo.prodId
			temp.shopId = this.goodsInfo.shopId
			temp.pic = this.goodsInfo.pic
			// this.bannerForm.refNo = JSON.stringify(temp)
			this.bannerForm.linkAddress = JSON.stringify(temp)
			this.bannerForm.address = JSON.stringify(temp)

			this.$refs.bannerForm.validateField("linkAddress")
		},

		beforeUploadVideo(file) {
			// console.log(file)
			const isLt10M = file.size / 1024 / 1024 < 5
			// if (["video/mp4"].indexOf(file.type) == -1) {
			// 	this.$message.error("请上传正确的视频格式")
			// 	return false
			// }
			if (!isLt10M) {
				this.$message.error("上传视频大小不能超过5MB!")
				return false
			}
		},

		handleUploadForm(param) {
			const formData = new FormData()
			const data = {
				model: "banner",
				pid: 0
			}
			formData.append("multipart", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			fileUploadApi(formData, data)
				.then(res => {
					loading.close()
					this.$message.success("上传成功")
					this.$nextTick(() => {
						this.$set(this, "fileInfo", res.data)
						// this.fileInfo = res.data
						this.bannerForm.videoUrl = res.data.url
						this.bannerForm.linkAddress = res.data.url
						this.bannerForm.img = res.data.url
					})
				})
				.catch(() => {
					loading.close()
				})
		},

		handleSubmit() {
			if (this.bannerForm.linkForm == 20) {
				// 视频
				this.bannerForm.linkAddress = this.bannerForm.videoUrl
				this.bannerForm.img = this.bannerForm.videoUrl
			} else if (this.bannerForm.refType == 0) {
				// 无链接
				this.bannerForm.linkAddress = ""
				this.bannerForm.address = ""
			}

			var bannerList = []
			bannerList.push(this.bannerForm)
			this.$refs["bannerForm"].validate(valid => {
				if (valid) {
					// console.log(this.bannerForm)
					this.bannerForm.id
						? editBanner(this.bannerForm)
							.then(() => {
								this.$notify.success({
									title: "成功",
									message: "编辑成功"
								})
								this.loading = false
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.loading = false
							})
						: addBanner(this.bannerForm)
							.then(() => {
								this.loading = false
								this.$notify.success({
									title: "成功",
									message: "添加成功"
								})
								this.addEditDialog = false
								this.$emit("saveSuccess")
							})
							.catch(() => {
								this.loading = false
							})
				} else {
					return false
				}
			})
		},

		openLiveRoow() {
			this.$refs.liveModel.open(this.liveInfo)
		},
		submitLiveRoow(info) {
			this.liveInfo = info
			this.bannerForm.refDesc = info.name
			this.bannerForm.refNo = info.wechatRoomId
			console.log("info", info)
		},

		openTopicModel() {
			this.$refs.topicModel.open()
		},

		getTopicItem(info) {
			this.topicInfo = info
			this.bannerForm.refDesc = info.name
			this.bannerForm.refNo = info.id
		},
		getLabourShopList() {
			if (this.labourShopList.length > 0) return
			laborShop({ pageNum: 0, pageSize: 100 }).then(res => {
				this.labourShopList = res.data.list.filter(e => e.enabled)
				this.labourShopList.map(e => e.id = e.id.toString())
			})
		}
		// getLuckDrawList() {
		// 	if (this.luckDrawList.length > 0) return
		// 	lotteryList({ pageNum: 0, pageSize: 100 }).then(res => {
		// 		this.luckDrawList = res.data.list.filter(e => e.enabled)
		// 		this.luckDrawList.map(e => e.id = e.id.toString())
		// 	})
		// }

	}
}
</script>

<style lang="scss" scoped>
.upLoadPicBox {
	display: flex;
	align-items: flex-end;
}
</style>
