<template>
    <el-drawer
        :title="title"
        :visible.sync="addEditVisible"
        size="40%"
    >
        <el-form ref="addEditForm" :model="addEditForm" :rules="rules" label-width="80px" :inline="false">
            <el-form-item label="秒杀名称" prop="seckillName">
                <el-input v-model="addEditForm.seckillName" placeholder="秒杀名称" class="inputWidth" />
            </el-form-item>
            <el-form-item label="时间范围" prop="rangeTimeArr">
                <!-- <el-time-picker
                    is-range
                    v-model="addEditForm.rangeTimeArr"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="HH:mm"
                    format="HH:mm"
                    >
                </el-time-picker> -->
                <el-select v-model="addEditForm.startTime" placeholder="开始时段" clearable filterable style="width:100px;" @change="rangeTimeChange">
                    <el-option v-for="(item,index) in Object.values(rangeTime)"
                               :key="index"
                               :label="item"
                               :value="index"
                    />
                </el-select>
                <span style="width:20px;display: inline-block;text-align: center;">-</span>
                <el-select v-model="addEditForm.endTime" placeholder="结束时段" clearable filterable style="width:100px;" @change="rangeTimeChange">
                    <el-option v-for="(item,index) in Object.values(rangeTime)"
                               :key="index"
                               :label="item"
                               :value="index"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="轮播图" prop="picArr">
                <upload-image ref="imageDragModel"
                              v-model="addEditForm.picArr"
                              :image-array="addEditForm.picArr"
                              :limit="100"
                />
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-switch v-model="addEditForm.status" />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit('addEditForm')">保存</el-button>
                <el-button @click="addEditVisible = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import UploadImage from "views/bussiness/components/UploadImage"

import { seckillConfigEdit, seckillConfigAdd } from "api/business/marketing/seckill"

import { seckillRangeDate } from "@/utils/constants"

export default {
    name: "ConfigAddEdit",

    components: {
        UploadImage
    },

    directives: {  },

    data() {
        return {
            title: "新增",
            addEditVisible: false,
            addEditForm: {
                id: "",
                seckillName: "",
                rangeTime: "",
                startTime: "",
                endTime: "",
                pic: "",
                picArr: [],
                status: true
            },
            rules: {
                seckillName: [{
                    required: true,
                    message: "请输入秒杀名称",
                    trigger: "blur"
                }],
                rangeTimeArr: [{
                    required: true,
                    validator: (rule, value, callback) => {
                        if (this.addEditForm.startTime.toString()  == "" || this.addEditForm.endTime.toString() == "") {
                            callback(new Error("请选择时间范围"))
                        } else {
                            callback()
                        }
                    },
                    trigger: "change"
                }],
                picArr: [{
                    required: true,
                    message: "请选择轮播图",
                    trigger: "change"
                }]
            },
            rangeTime: seckillRangeDate
        }
    },

    mounted() {

    },

    methods: {
        add() {
            this.addEditVisible = true
            this.$nextTick(() => {
                this.addEditForm = {
                    id: "",
                    seckillName: "",
                    rangeTime: "",
                    startTime: "",
                    endTime: "",
                    pic: "",
                    picArr: [],
                    status: true
                }
            })

        },
        edit(record) {
            this.addEditVisible = true
            this.$nextTick(() => {
                this.addEditForm = JSON.parse(JSON.stringify(record))

                this.addEditForm.picArr = this.addEditForm.pic
                // this.addEditForm.rangeTimeArr = this.addEditForm.rangeTime.split('-')
                // this.$set(this.addEditForm, 'rangeTimeArr', this.addEditForm.rangeTime.split('-'));
            })
        },

        rangeTimeChange() {
            if (this.addEditForm.endTime && this.addEditForm.startTime >= this.addEditForm.endTime) {
                this.$notify.warning({
                    title: "提示",
                    message: "开始时段不能大于结束时段"
                })

                this.addEditForm.endTime = ""
            }
        },

        onSubmit(formName) {
            this.rangeTimeChange()
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.addEditForm.rangeTime = this.addEditForm.startTime + ":00 - " + this.addEditForm.endTime + ":00"
                    this.addEditForm.pic = this.addEditForm.picArr.join(",")

                    // this.addEditForm.startTime = parseInt(this.addEditForm.rangeTimeArr[0].split(':')[0])

                    // this.addEditForm.endTime = parseInt(this.addEditForm.rangeTimeArr[1].split(':')[0])

                    this.addEditForm.id
                        ? seckillConfigEdit(this.addEditForm).then(() => {
                            this.$notify.success({
                                title: "成功",
                                message: "修改成功"
                            })
                            this.addEditVisible = false
                            this.$emit("saveSuccess")
                        })
                        : seckillConfigAdd(this.addEditForm).then(() => {
                            this.$notify.success({
                                title: "成功",
                                message: "新增成功"
                            })
                            this.addEditVisible = false
                            this.$emit("saveSuccess")
                        })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
