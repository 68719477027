import fetch from "@/axios/fetch"

export function getGiftCardGroupList(params) { // 获取礼品卡任务列表
	return fetch({
		url: "/gift_cards/group_list",
		method: "get",
		params
	})
}

export function addGiftCardGroup(data) { // 新增礼品卡任务
	return fetch({
		url: "/gift_cards/group_add",
		method: "post",
		data
	})
}

export function editGiftCardGroup(data) { // 修改礼品卡任务
	return fetch({
		url: "/gift_cards/group_update",
		method: "put",
		data
	})
}

export function getGiftCardList(params) { // 获取礼品卡列表
	return fetch({
		url: "/gift_cards/list",
		method: "get",
		params
	})
}

export function giftCardExport(params) { // 礼品卡导出
	return fetch({
		url: "/gift_cards/gift_cards_export",
		method: "get",
		params,
		responseType: "blob"
	})
}

