<template>
	<div class="mvk-layout-content-card">
		<el-form size="small" :inline="true">
			<el-form-item label="活动名称：" style="min-width: 120px">
				<el-input v-model="queryParams.name" clearable />
			</el-form-item>
			<el-form-item label="活动状态：" style="min-width: 120px">
				<el-select v-model="queryParams.status" clearable placeholder="请选择">
					<el-option
						v-for="item in statusOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</el-form-item>
			<el-form-item label="活动有效期">
				<el-date-picker
					v-model="timeVal"
					:default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					size="small"
					type="daterange"
					placement="bottom-end"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					class="ml10"
					style="width: 230px"
					@change="onchangeTime"
				/>
			</el-form-item>
			<el-form-item label="联系人：" style="min-width: 120px">
				<el-input v-model="queryParams.contact" clearable />
			</el-form-item>
			<el-form-item label="联系手机号：" style="min-width: 120px">
				<el-input v-model="queryParams.mobile" clearable />
			</el-form-item>
		</el-form>

		<div class="flex">
			<el-button type="primary" class="mb20" @click="handleAdd"> 新增健步走活动 </el-button>
		</div>

		<mvk-table ref="mvkTable" :table-data-func="tableDataFunc" row-key="id" height="540">
			<el-table-column prop="id" width="60" label="ID" align="center" header-align="center" />
			<el-table-column label="活动名称" prop="name" min-width="120" />
			<el-table-column label="活动成员" width="100" prop="count" />
			<el-table-column label="联系人" prop="contact" width="86" />
			<el-table-column label="联系人电话" prop="mobile" width="130" />
			<el-table-column label="活动有效期" width="280" align="center" header-align="center">
				<template slot-scope="{ row }">
					{{ row.startTime }}-{{ row.endTime }}
				</template>
			</el-table-column>
			<el-table-column label="活动状态" prop="status" width="130">
				<template slot-scope="{ row }">
					<el-tag effect="plain" :type="normalizeTagType(row.status)">{{ normalizeStatus(row.status) }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="是否启用" prop="deleted" width="130">
				<template slot-scope="{ row }">
					<el-switch
						v-model="row.isValid"
						active-color="#4F8AFF"
						inactive-color="#ccc"
						@change="changeSwitch(row)"
					/>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="300" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleAction(row, 'first')"> 活动信息 </el-button>
					<el-button type="text" size="small" @click="handleAction(row, 'second')"> 会员信息 </el-button>
					<el-button type="text" size="small" @click="handleAction(row, 'third')"> 运动金 </el-button>
					<!-- <el-popconfirm
						title="确定要删除该活动吗？"
						@confirm="handleDel(row)"
					>
						<el-button slot="reference" type="danger" size="mini" style="margin-left: 10px;"> 删除 </el-button>
					</el-popconfirm> -->
				</template>
			</el-table-column>
		</mvk-table>
		<we-edit-drawer ref="modalForm" :tabs-active-name="activeName" @saveSuccess="refresh" />
	</div>
</template>

<script>
import { getWeRunList, updateWeRunEnable, delWeRun } from "api/business/marketing/weRun"
import { debounce } from "utils/utils"
import { listMixin } from "views/mixin/listMixin"

export default {
	name: "WeRunList",
	mixins: [listMixin],
	components: {
		"we-edit-drawer": () => import("./weEditDrawer.vue")
	},
	data() {
		return {
			timeVal: [],
			queryParams: {
				// 联系手机
				mobile: "",
				// 联系人
				contact: "",
				// 开启是 false ，关闭是 true
				isDeleted: false,
				name: "",
				// 状态 0 未开始 1 进行中 2 已结束 3 已关闭
				status: "",
				startDate: "",
				endDate: ""
			},
			tableData: [],
			selectList: [],
			activeName: "",
			tableDataFunc: parameter => {
				this.pageNum = parameter.pageNum
				this.pageSize = parameter.pageSize
				return getWeRunList(Object.assign(parameter, this.queryParams)).then(res => {
					const list = res.data.list.map(o => {
						o.deleted = !o.deleted
						return o
					})
					return { ...res.data, list }
				})
			},
			pageNum: 0, // 只用于导出传参
			pageSize: 0, // 只用于导出传参
			statusOptions: [
				{ label: "全部", value: "" },
				{ label: "未开始", value: 0 },
				{ label: "进行中", value: 1 },
				{ label: "已结束", value: 2 },
				{ label: "已关闭", value: 3 }
			]
		}
	},
	watch: {
		queryParams: {
			handler: debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},

	mounted() {
		// this.getTableData()
	},

	methods: {
		getTableData() {
			this.$refs.mvkTable.refresh()
		},

		handleAction(row, activeName) {
			this.activeName = activeName
			this.handleEdit(row)
		},

		refresh() {
			this.getTableData()
		},

		normalizeStatus(status) {
			switch (status) {
				case 0:
					return "未开始"
				case 1:
					return "进行中"
				case 2:
					return "已结束"
				case 3:
					return "已关闭"
			}
		},

		normalizeTagType(status) {
			switch (status) {
				case 0:
					return ""
				case 1:
					return "success"
				case 2:
					return "info"
				case 3:
					return "warning"
			}
		},

		changeSwitch(row) {
			updateWeRunEnable({ id: row.id, isEnable: row.isValid }).then(r => {
				console.log(r)
			}).catch(err => console.log(err))
		},

		onchangeTime(e) {
			this.timeVal = e
			this.queryParams.startDate = e ? e[0] : ""
			this.queryParams.endDate = e ? e[1] : ""
		},

		handleDel(row) {
			delWeRun(row.id).then(r => {
				this.getTableData()
			}).catch(err => console.log(err))
		}
	}
}
</script>

<style lang="scss" scoped></style>
