export const userAnalysisData = {
	"status": 200,
	"msg": "ok",
	"data": {
		"xAxis": [
			// "02-10",
			// "02-11",
			// "02-12",
			// "02-13",
			// "02-14",
			// "02-15"
		],
		"series": [
			{
				"name": "新增用户数",
				"value": [
					// 36,
					// 50,
					// 18,
					// 10,
					// 28,
					// 25
				]
			},
			{
				"name": "访客数",
				"value": [
					// 97,
					// 123,
					// 67,
					// 51,
					// 102,
					// 73
				]
			},
			{
				"name": "成交用户数",
				"value": [
					// 3,
					// 3,
					// 3,
					// 0,
					// 2,
					// 3
				]
			},
			{
				"name": "充值用户",
				"value": [
					// 0,
					// 0,
					// 1,
					// 0,
					// 0,
					// 1
				]
			}
		]
	}
}

export const goodsAnalysisData = {
	"status": 200,
	"msg": "ok",
	"data": {
		"xAxis": [
			// "02-10",
			// "02-11",
			// "02-12"
		],
		"series": [
			{
				"name": "商品浏览量",
				"value": [
					// 236,
					// 242,
					// 250
				]
			},
			{
				"name": "商品访客量",
				"value": [
					// 63,
					// 61,
					// 45
				]
			},
			{
				"name": "支付金额",
				"value": [
					// 2554,
					// 203.09,
					// 8322.02
				]
			},
			{
				"name": "退款金额",
				"value": [
					// 0,
					// 107.08,
					// 0
				]
			}
		]
	}
}

export const goodsAnalysisTable = {
	data: {
		list: [{
			pic: "http://tstatic.mevikycloud.com/image/product/2022/03/02/0af6cce49eff4de3abd2881ec34f0294b84fan3ucz.png",
			name: "密度家居现代简约橘粉色玻璃花器摆件创意客厅餐桌花艺术陈设装饰",
			browse: "10",
			user: 366,
			cart: "56",
			changes: "0.03",
			orders: "92",
			pay: "18",
			payPrice: "1606.44"
		}],
		totalElements: 1,
		totalPages: 1
	},
	msg: "ok",
	status: 200
}
