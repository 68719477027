<template>
    <div class="mvk-layout-content-card">
        <el-button v-has="'ums:group:add'" type="primary" round class="mb15" @click="handleAddEdit()">
            新增会员分组
        </el-button>

        <mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc">
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button v-has="'ums:group:edit'" type="text" size="small" @click="handleAddEdit(row)">编辑</el-button>
                    <delete-button :id="row.id" v-has="'ums:group:delete'" button-type="text" size="small" @delete="handleDelete">删除</delete-button>
                </template>
            </el-table-column>
        </mvk-table>
    </div>
</template>

<script>
import { addGroup, groupList, editGroup, deleteGroup } from "api/business/member/group.js"
export default {
    name: "MemberGroupIndex",

    components: {  },

    directives: {  },

    data() {
        return {
            tableColumn: [{
                prop: "id",
                label: "ID"
            }, {
                prop: "name",
                label: "分组名称"
            }],

            tableDataFunc: parameter => {
                return groupList(Object.assign(parameter))
                    .then(res => {
                        return res.data
                    })
            }
        }
    },

    mounted() {

    },

    methods: {
        handleAddEdit(row) {
            this.$prompt("分组名称", row ? "修改" : "新增", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputErrorMessage: "请输入分组名称",
                roundButton: true,
                inputType: "text",
                inputValue: row ? row.name : "",
                inputPlaceholder: "请输入分组名称",
                inputValidator: value => {
                    if (!value) {
                        return "输入不能为空"
                    }
                    if (value.trim() === "") {
                        return "不能输入空格"
                    }
                    let reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/
                    if (reg.test(value)) {
                        return "不能输入特殊字符"
                    }
                }
            }).then(({ value }) => {
                row
                    ? editGroup({
                        id: row.id,
                        name: value
                    }).then(() => {
                        this.$notify.success("修改成功")
                        this.$refs.mvkTable.refresh()
                    })
                    : addGroup({
                        id: "",
                        name: value
                    }).then(() => {
                        this.$notify.success("新增成功")
                        this.$refs.mvkTable.refresh()
                    })
            }).catch(() => {
                this.$message.info("取消输入")
            })
        },

        handleDelete(data) {
            deleteGroup(data.id).then(() => {
                this.$notify.success("删除成功")
                this.$refs.mvkTable.refresh()
            })
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
