<template>
	<div>
		派发统计（废弃）
	</div>
</template>

<script>
export default {
        
}
</script>

<style lang="scss" scoped>

</style>