<template>
	<el-drawer :title="title" :visible.sync="addEditDialog" size="80%">
		<el-form
			ref="dataForm"
			:model="dataForm"
			:rules="dataRule"
			label-width="130px"
			class="mod-menu"
		>
			<el-form-item label="菜单类型" prop="type">
				<el-radio-group v-model="dataForm.type">
					<el-radio v-for="(type, index) in typeList" :key="index" :label="index">{{ type }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="大客户店铺名称" prop="name">
				<mvk-input v-model.trim="dataForm.name" placeholder="英文名称" />
			</el-form-item>
			<el-form-item label="大客户企业名称" prop="title">
				<mvk-input v-model.trim="dataForm.title" placeholder="中文名称" />
			</el-form-item>
			<el-form-item label="大客户联系人" prop="title">
				<mvk-input v-model.trim="dataForm.title" placeholder="中文名称" />
			</el-form-item>
			<el-form-item label="联系人电话" prop="title">
				<mvk-input v-model.trim="dataForm.title" placeholder="中文名称" />
			</el-form-item>
			<el-form-item label="大客户企业地址" prop="title">
				<mvk-input v-model.trim="dataForm.title" placeholder="中文名称" />
			</el-form-item>
			<el-form-item label="大客户销售人" prop="title">
				<mvk-input v-model.trim="dataForm.title" placeholder="中文名称" />
			</el-form-item>
			<el-form-item label="店铺状态" prop="type">
				<el-radio-group v-model="dataForm.type">
					<el-radio :label="1">开启</el-radio>
					<el-radio :label="0">关闭</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="Submit('dataForm')">保存</el-button>
				<el-button @click="addEditDialog = false">关闭</el-button>
			</el-form-item>
		</el-form>
	</el-drawer>
</template>

<script>
import { iconList } from "@/utils/constants"

import { mapState, mapActions } from "vuex"
import { treeToList } from "@/utils/utils"

export default {
	components: {},

	computed: {
		...mapState("dict", ["menulist"]),

		menuTreeList() {
			return treeToList(this.menulist, [])
		}
	},
	data() {
		return {
			title: "新增",
			addEditDialog: false,
			loading: false,

			dataForm: this.dataParams(),
			typeList: ["基本信息", "商品信息", "会员信息"],
			iconList: iconList,
			dataRule: {
				type: [{ required: true, message: "请选择菜单类型", trigger: "change" }],
				name: [{ required: true, message: "请输入英文名称", trigger: "blur" }],
				title: [{ required: true, message: "请输入中文名称", trigger: "blur" }],
				parentName: [{ required: true, message: "上级菜单不能为空", trigger: "change" }],
				path: [{ required: true, message: "菜单URL不能为空", trigger: "blur" }],
				component: [{ required: true, message: "前端组件不能为空", trigger: "blur" }]
			},
			menuList: [],
			menuListTreeProps: {
				label: "title",
				children: "children"
			}
		}
	},

	mounted() {},

	methods: {
		...mapActions("dict", ["getMenuList"]),
		Submit() {},
		dataParams() {
			return {
                
				id: "",
				type: 0,
				name: "",
				title: "",
				sort: 0
			}
		},
		add() {
			this.addEditDialog = true
			this.$nextTick(() => {
				this.dataForm = this.dataParams()
			})
		},
		edit(record) {
			this.addEditDialog = true
			// console.log("编辑", record)
			this.$nextTick(() => {
				this.dataForm = JSON.parse(JSON.stringify(record))
			})
		}
	}
}
</script>

<style lang="scss">
</style>
