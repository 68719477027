<template>
	<div>
		<!-- 仓库中商品组件 -->
		<div v-if="newModel" v-loading="productLoadding">
			<div v-if="!showTable" class="flex-row warp">
				<template v-if="currentProductList.length">
					<div v-for="(item, index) in currentProductList" :key="index" class="pictrue">
						<el-tooltip class="item" effect="dark" :content="item.prodName" placement="top-start">
							<el-image :src="item.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/58x'" :preview-src-list="[item.pic]" style="width:58px;height:58px" />
						</el-tooltip>
						<i v-if="!readOnly" class="el-icon-error btndel" @click="handleGoodsRemove(index)" />
					</div>
				</template>
				<div v-if="!readOnly" class="upLoadPicBox" @click="open()">
					<div class="upLoad">
						<i class="el-icon-camera cameraIconfont" />
					</div>
				</div>
			</div>
			<div v-else>
				<el-button v-if="!readOnly" size="mini" type="primary" class="mb10" @click="open()"> 添加 </el-button>
				<el-table :data="
					currentProductList.slice(
						(shopData.pageNum - 1) * shopData.pageSize,
						shopData.pageNum * shopData.pageSize
					)
				" style="width: 100%" size="mini" border
				>
					<el-table-column label="图片">
						<template slot-scope="{ row }">
							<div class="flex-row">
								<el-image style="min-width: 30px; height: 30px; width: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'"
									:preview-src-list="[row.pic]"
								/>
								<div style="margin-left: 4px">{{ row.prodName }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="供应商" width="135">
						<template slot-scope="{ row }" label="供应商">
							<div v-if="row.source === 0">
								<span class="source source0">内</span>{{ row.supplierName }}
							</div>
							<div v-if="row.source === 1">
								<span class="source source1">外</span>{{ row.supplierName }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="categoryTree" label="分类" />
					<el-table-column v-if="!readOnly" label="操作" width="77">
						<template slot-scope="scope">
							<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">
								删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination :current-page.sync="shopData.pageNum" :page-size="shopData.pageSize"
					layout="pager, next, jumper" :total="currentProductList.length"
					@current-change="handleCurrentChange"
				/>
			</div>
		</div>
		<!-- 抽屉 -->
		<el-drawer title="商品列表" :visible.sync="goodsListModel" :append-to-body="true" size="720px">
			<el-form size="small" label-width="80px" :inline="true">
				<el-row>
					<el-col :span="12" :offset="0">
						<el-form-item label="商品名称">
							<el-input v-model="queryParam.prodName" placeholder="输入商品名搜索" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12" :offset="0">
						<el-form-item v-if="!listReadOnly" label="商品分类">
							<prod-category-tree v-model="queryParam.categoryId" check-strictly />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="type === 'labour' || supplier">
					<el-col :span="12">
						<el-form-item label="供应商">
							<el-select v-model="queryParam.supplierId" placeholder="所属供应商" clearable filterable style="width:240px">
								<el-option v-for="item in supplierlist" :key="item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col v-if="showIsLevel" :span="12" :offset="0">
						<el-form-item label="已入站：" prop="isLevel">
							<el-select v-model="queryParam.isLevel" placeholder="是否已入站" :disabled="isDisabled" clearable style="width:140px">
								<el-option label="全部" :value="null" />
								<el-option label="是" :value="true" />
								<el-option label="否" :value="false" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="showDeliveryMode || showIsLevel">
					<el-col v-if="showDeliveryMode && type !== 'labour'" :span="12" :offset="0">
						<el-form-item label="配送：" prop="deliveryMode">
							<el-select v-model="queryParam.deliveryMode" placeholder="配送方式" :disabled="isDisabled" clearable style="width:140px">
								<el-option label="邮寄" :value="0" />
								<el-option label="自提" :value="1" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="" style="padding-bottom: 50px">
				<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc"
					:checked-id="newModel ? cacheIds : checked" class="mt15" @selection-change="handleSelectionChange"
					@select-all="selectItemAll" @currentChangeSync="checkSelectSync(multiCheckIds)"
					@select="selectItem"
				>
					<template v-if="!listReadOnly">
						<el-table-column v-if="multi" slot="head" type="selection" :selectable="selectable"
							width="55"
						/>
						<el-table-column v-else slot="head" width="55">
							<template slot-scope="scope">
								<el-radio v-model="templateRadio" :label="scope.row.id"
									@change.native="getGoodsInfo(scope.row)"
								>
									<span />
								</el-radio>
							</template>
						</el-table-column>
					</template>
					<template slot="pic" slot-scope="{ row }">
						<el-image style="width: 30px; height: 30px" :src="row.pic + '?imageMogr2/format/webp/interlace/1/rquality/90/thumbnail/30x'" :preview-src-list="[row.pic]" />
					</template>
				</mvk-table>
			</div>
			<div class="custom-drawer-footer">
				<div class="flex-row" style="justify-content: space-between">
					<el-button type="primary" @click="handleSubmit">确定</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { prodList, wareProdList, saleProdList } from "api/business/selfManager/goods.js"
import { productsList } from "api/business/selfManager/keyAccount.js"
// import { productsList as labourProductsList } from "api/business/selfManager/labour.js"
import * as utils from "utils/utils"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import { mapState, mapActions } from "vuex"
export default {
	name: "GoodsListModel",
	props: {
		multi: {
			// true:多选，false：单选
			type: Boolean,
			default: true
		},
		checked: {
			type: Array,
			default() {
				return []
			}
		},
		// 在售商品 = sale
		// 仓库中商品 = ware ？
		// 大客户 = major
		// 通用商品 = babour
		type: {
			type: String,
			default: "sale"
		},
		// 是否显示供应商筛选(非通用)
		supplier: {
			type: Boolean,
			default: false
		},
		disabledProduct: {
			type: Array,
			default() {
				return []
			}
		},
		shopId: {
			type: Number
		},
		// 主要，分页依然是保留选择
		selected: {
			type: Array,
			default() {
				return []
			}
		},
		newModel: {
			type: Boolean,
			default: false
		},
		showTable: {
			type: Boolean,
			default: false
		},
		deliveryMode: {
			// 0:邮寄，1：自提，-1：既不是邮寄又不是自提
			type: Number,
			default: 0
		},
		showDeliveryMode: {
			type: Boolean,
			default: true
		},
		showIsLevel: {
			type: Boolean,
			default: true
		},
		// 只读， 用于新模式中（选择商品）
		readOnly: {
			type: Boolean,
			default: false
		},
		// 列表只读
		listReadOnly: {
			type: Boolean,
			default: false
		}

	},
	components: { ProdCategoryTree },

	directives: {},

	data() {
		return {
			goodsListModel: false,

			wareColumn: [
				{
					prop: "id",
					label: "ID",
					width: 50
				},
				{
					prop: "prodName",
					label: "商品名称",
					minWidth: 120
				},
				{
					prop: "pic",
					label: "商品图片",
					align: "center",
					width: 80,
					slot: true
				},
				// {
				// 	prop: "deliveryMode",
				// 	label: "配送方式",
				// 	width: 80,
				// 	align: "center",
				// 	formatter: cellValue => {
				// 		switch (cellValue.deliveryMode) {
				// 			case 0:
				// 				return "邮寄"
				// 			case 1:
				// 				return "自提"
				// 			default:
				// 				return "任意"
				// 		}
				// 	}
				// },
				// {
				// 	prop: "isLevel",
				// 	label: "已入站池",
				// 	align: "center",
				// 	width: 80,
				// 	formatter: row => {
				// 		if (row.isLevel) {
				// 			return "已入"
				// 		} else {
				// 			return "--"
				// 		}
				// 	}
				// },
				{
					prop: "costPrice",
					label: "成本价",
					align: "center",
					width: 80,
					formatter: row => {
						if (row.skus && row.skus[0]) {
							return row.skus[0].costPrice
						}
					}
				},
				{
					prop: "sellingPrice",
					label: "售卖价",
					align: "center",
					width: 80,
					formatter: row => {
						if (row.skus && row.skus[0]) {
							return row.skus[0].sellingPrice
						}
					}
				}
			],

			saleColumn: [
				{
					prop: "id",
					label: "ID",
					width: 50
				},
				{
					prop: "prodName",
					label: "商品名称"
				},
				{
					prop: "pic",
					label: "商品图片",
					slot: true
				},
				{
					prop: "shopName",
					label: "店铺"
				},
				{
					prop: "price",
					label: "商品价格"
				}
			],

			queryParam: {
				status: "1", // 只查询上架的商品
				prodName: "",
				categoryId: "",
				supplierId: "",
				isLevel: "",

				// 0: "邮寄",
				// 1: "自提"
				deliveryMode: ""
			},
			templateRadio: 0,
			multiCheck: [],
			multiCheckIds: [],

			// 新改版 - 商品选择（已勾选）
			checkedId: [],
			cacheIds: [],
			currentProductList: [],
			productLoadding: false,
			shopData: {
				pageNum: 1,
				pageSize: document.documentElement.clientHeight > 960 ? 10 : 7
			}
		}
	},
	computed: {
		...mapState("dict", ["supplierlist"]),
		tableDataFunc() {
			if (this.type == "sale") {
				// 查询在售中的商品
				return parameter => {
					return saleProdList(
						Object.assign(parameter, {
							prodName: this.queryParam.prodName,
							categoryId: this.queryParam.categoryId
						})
					).then(res => {
						return res.data
					})
				}
			} else if (this.type == "ware") {
				// 查询仓库中的商品(需过滤自提商品)
				return parameter => {
					// console.log("parameter", parameter)
					let temp = {}
					if (this.deliveryMode == 0 || this.deliveryMode == 1) {
						temp = Object.assign(parameter, {
							deliveryMode: this.queryParam.deliveryMode || this.deliveryMode,
							prodName: this.queryParam.prodName,
							categoryId: this.queryParam.categoryId,
							supplierId: this.queryParam.supplierId,
						})
					} else if (this.listReadOnly) {
						temp = Object.assign(parameter, {
							productIds: this.selected.join(),
							prodName: this.queryParam.prodName,
							pageSize: 10
						})
					} else {
						temp = Object.assign(parameter, {
							prodName: this.queryParam.prodName,
							categoryId: this.queryParam.categoryId
						})
					}
					if (temp.deliveryMode === -1) { delete temp.deliveryMode }
					return wareProdList(temp).then(res => {
						return res.data
					})
				}
			} else if (this.type == "major" && this.shopId) {
				console.log("type major")
				// 大客户店铺的商品
				return parameter => {
					return productsList(
						Object.assign(parameter, {
							shopId: this.shopId,
							prodName: this.queryParam.prodName,
							categoryId: this.queryParam.categoryId,
							isLevel: this.queryParam.isLevel
						})
					).then(res => {
						res.data.list = res.data.list.map(e => {
							e.orginId = e.id
							e.id = e.prodId
							return e
						})
						return res.data
					})
				}
			} else if (this.type == "labour") {
				// 通用店铺的商品
				return parameter => {
					return wareProdList(
						Object.assign(parameter, {
							deliveryMode: this.deliveryMode,
							prodName: this.queryParam.prodName,
							categoryId: this.queryParam.categoryId,
							supplierId: this.queryParam.supplierId,
							isLevel: this.queryParam.isLevel
							// productIds: this.queryParam.productIds
						})
					).then(res => {
						return res.data
					})
				}
			} else {
				console.log("type else")
				return parameter => {
					return prodList(Object.assign(parameter, this.queryParam)).then(res => {
						return res.data
					})
				}
			}
		},

		tableColumn() {
			if (this.type == "sale") {
				return this.saleColumn
			} else {
				return this.wareColumn
			}
		}
	},

	watch: {
		queryParam: {
			// 增加防抖
			handler: utils.debounce(function() {
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		},
		selected(newVal) {
			if (!this.listReadOnly) this.getCurrentProductItem(newVal)
		}
	},
	created() {
		if (this.type === "labour") {
			this.wareColumn.push({
				prop: "supplierName",
				label: "供应商",
				minWidth: 120
			})
		}

	},
	mounted() {
		// 如已勾选过商品，则显示出来
		if (this.newModel && this.selected.length) {
			this.getCurrentProductItem(this.selected)
		}
		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	},

	methods: {
		...mapActions("dict", ["getSupplierList"]),

		/**
		 * 根据条件禁用行复选框
		 * 函数返回值为false则禁用选择(反之亦然)
		 * @param {Object} row - 行数据
		 * @param {String} index - 索引值
		 * @return Boolean
		 */
		selectable: function(row) {
			if (this.disabledProduct.length > 0 && this.disabledProduct.includes(row.id)) {
				return false
			} else {
				return true
			}
			// 函数必须有返回值且是布尔值
			// 页面刷新后该函数会执行 N 次进行判断(N 为表格行数)
			// 如果没有返回值则默认返回false(全部无法选中)
		},
		open() {
			this.goodsListModel = true
			this.checkSelectSync()
			if (this.selected) this.cacheIds = this.selected
		},
		checkSelectSync(multiCheckIds) {
			this.$nextTick(() => {
				if (multiCheckIds) {
					this.checkedId = multiCheckIds
					return
				}
				if (this.multiCheckIds.length != this.checked.length && !this.newModel) {
					if (this.checked.length > 0) {
						this.checkedId = this.checked
					} else {
						this.$nextTick(function() {
							this.$refs.mvkTable.$refs.tableTemplate.clearSelection()
						})
					}
				}
				if (this.multiCheckIds.length != this.selected.length && this.newModel) {
					if (this.selected.length > 0) {
						this.checkedId = this.selected
					} else {
						this.$nextTick(function() {
							this.$refs.mvkTable.$refs.tableTemplate.clearSelection()
						})
					}
				}
			})
		},
		// 选择/取消项
		selectItem(list, row) {
			// 所选列中无则增加，有则去掉
			const isIndex = this.cacheIds.findIndex(e => e == row.id)
			isIndex > -1 && this.cacheIds.splice(isIndex, 1)
		},
		// 全选/取消全选
		selectItemAll(list) {
			let idsArr = list.map(e => e.id)
			if (idsArr.length) {
				this.cacheIds = utils.getUnion(this.cacheIds, idsArr)
			} else {
				idsArr = this.$refs.mvkTable.tableData.map(e => e.id)
				this.cacheIds = utils.arrSubtract(this.cacheIds, idsArr)
			}
		},

		getGoodsInfo(row) {
			this.multiCheck = row
			this.$emit("getGoodsItem", row)
			this.goodsListModel = false
		},
		// 获取数组中的商品ID列表
		getCurrentProductItem(ids = []) {
			if (ids.length === 0) {
				this.currentProductList = []
				return
			}
			this.productLoadding = true
			wareProdList({
				deliveryMode: this.deliveryMode === -1 ? "" : this.deliveryMode,
				productIds: ids.join(),
				pageNum: 0,
				pageSize: ids.length
			})
				.then(res => {
					this.currentProductList = res.data.list
					this.$emit("getMultiGoodsInfo", this.currentProductList)

				})
				.finally(() => {
					this.productLoadding = false
				})
		},
		handleGoodsRemove(index) {
			const delItem = this.currentProductList.splice(index, 1)[0]
			// console.log(this.selected.filter(e => e.id !== delItem.id))
			const newArr = this.selected.filter(e => e !== delItem.id)
			this.$emit("update:selected", newArr)
			this.cacheIds = newArr
			// this.$nextTick(() => {
			this.$refs.mvkTable?.selectForIds()
			// })
		},

		handleSelectionChange(val) {
			this.multiCheckIds = val.map(item => item.id)
			this.multiCheck = val
			this.$emit("getMultiGoodsInfo", val)
			this.checkedId = this.multiCheckIds

			// 开始保留当前所有选择
			this.cacheIds = utils.removeRepeat(this.cacheIds.concat(this.multiCheckIds))
		},

		handleSubmit() {
			this.$emit("getGoodsList", this.multiCheck)
			this.$emit("update:selected", this.cacheIds)
			this.goodsListModel = false
		},
		// 在表格中,删除已选商品
		handleDelete(index, row) {
			// const delItem = this.currentProductList.splice(index, 1)[0]

			// console.log("row", row)
			const newArr = this.selected.filter(e => e != row.id)
			// console.log("newArr", newArr)
			this.$emit("update:selected", newArr)
			this.cacheIds = newArr
			this.$nextTick(() => {
				this.$refs.mvkTable && this.$refs.mvkTable.selectForIds()
			})
		},
		handleCurrentChange(val) {
			this.shopData.pageNum = val
		}
	}
}
</script>

<style lang="scss" scoped>
.min_mask {
	width: 200px;
}

.source {
	display: inline-block;
	margin-right: 4px;
	line-height: 1;
	border: 1px solid #4f8aff;
	color: #4f8aff;
	padding: 4px;
	border-radius: 50px;
	font-size: 12px;
}

.source0 {
	color: #80b568;
	border-color: #80b568;
}

.pictrue {
	margin-bottom: 16px;
}

</style>
