<template>
	<div class="mvk-layout-content-card">
		<div class="add-btn flex-row flex-a-c" style="justify-content: space-between">
			<div class="flex-row">
				<el-button
					v-has="'bms:shop:invitation'"
					type="primary"
					round
					size="small"
					@click="handleInvitate('mini')"
				>
					<!-- 新增店铺(小程序入口) -->
					新增店铺
				</el-button>
				<!-- <el-button
					v-has="'bms:shop:invitation'"
					type="success"
					round
					size="small"
					@click="handleInvitate('h5')"
				>
					新增店铺(h5入口)
				</el-button> -->

				<el-button type="primary" round size="small" @click="handleAddClerk()"> 新增店员 </el-button>

				<el-upload
					v-has="'bms:shop:invitation'"
					action
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
					:http-request="handleUpload"
					:headers="uploadHeaders"
					:show-file-list="false"
				>
					<el-button type="primary" round size="small" plain style="margin-left: 10px">导入店铺</el-button>
				</el-upload>

				<!-- 导入模版下载 -->
				<el-button round size="small" class="ml10" @click="toDownloadTemplate()"> 导入模板下载 </el-button>
				<!-- 二维码下载 -->
				<el-button
					round
					size="small"
					class="ml10"
					:disabled="selectList.length == 0"
					@click="getQRCodesForIds()"
				>
					批量二维码下载
				</el-button>
			</div>

			<el-form size="small" label-width="100px" :inline="true" @submit.native.prevent>
				<el-form-item label="组织架构" style="margin-bottom: 10px">
					<organizationTreeSelect v-model="queryParam.organizId" />
				</el-form-item>
				<el-form-item label="门店名称">
					<el-input v-model="queryParam.name" placeholder="输入店名快搜索" clearable />
				</el-form-item>
			</el-form>
		</div>
		<mvk-table
			ref="mvkTable"
			:table-column="tableColumn"
			:table-data-func="tableDataFunc"
			class="mt20"
			highlight-current-row
			@expand-change="handledClerk"
			@selection-change="handleSelectionChange"
		>
			<el-table-column slot="expand" type="expand" width="0">
				<template slot-scope="props">
					<div class="childCellBox">
						<el-table v-loading="props.row.loading" :data="props.row.clerkData" size="mini">
							<el-table-column label="" width="86px" />
							<el-table-column property="userId" label="会员ID" width="66px" />
							<el-table-column property="realName" label="店员姓名" align="left" width="150" />
							<el-table-column property="nickName" label="店员昵称" width="150" />

							<el-table-column label="职级" width="260">
								<template slot-scope="{ row }">
									<el-tag v-if="row.grade == 'MANAGER'">店长</el-tag>
									<el-tag v-else-if="row.grade == 'ASSISTANT'" type="success">店员</el-tag>
									<el-tag v-else type="warning">{{ row.grade }}</el-tag>
								</template>
							</el-table-column>
							<el-table-column property="mobile" label="手机号" width="110" />
							<el-table-column property="creationDate" label="加入时间" />
							<el-table-column label="" width="70" />
							<el-table-column property="created" label="操作" width="140">
								<template slot-scope="{ row }">
									<delete-button
										v-if="row.grade !== 'MANAGER'"
										:id="row.id"
										button-type="text"
										size="small"
										@delete="handleClerkDelete"
									>
										删除
									</delete-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</template>
			</el-table-column>
			<el-table-column slot="head" type="selection" width="38" />

			<template slot="gsModel" slot-scope="{ row }">
				<el-tag v-for="(item, index) in row.gsModelArr" :key="index">{{ item }}</el-tag>
			</template>
			<template slot="enabled" slot-scope="{ row }">
				<el-switch
					v-model="row.enabled"
					:disabled="!hasBtnPermission('bms:shop:enabled')"
					@change="changeEnabled(row)"
				/>
			</template>
			<template slot="creationDate" slot-scope="{ row }">
				{{ row.creationDate | formatTime }}
			</template>
			<template slot="expireDate" slot-scope="{ row }">
				{{ row.expireDate | formatTime }}
			</template>
			<el-table-column label="操作" width="140">
				<template slot-scope="{ row }">
					<!--type = 1 为内购店铺，内购店铺系统预设，不可删除，不可修改-->
					<el-button
						v-if="row.type != 1 && row.type != 3"
						v-has="'bms:shop:edit'"
						type="text"
						size="small"
						@click="handleEdit(row)"
					>
						编辑
					</el-button>
					<el-button v-has="'bms:shop:detail'" type="text" size="small" @click="handleDetail(row)">
						详情
					</el-button>
					<el-button type="text" size="small" @click="getQRCodeForOne(row)"> 二维码 </el-button>
				</template>
			</el-table-column>
		</mvk-table>

		<el-dialog
			title="邀请"
			:visible.sync="addDialog"
			append-to-body
			width="40%"
			top="50px"
			:close-on-click-modal="false"
		>
			<div class="flex-column flex-center">
				<vue-qr ref="Qrcode" :text="qrcodeText" qid="testQrId" />
				<!-- <p>门店开通入口：{{qrcodeText}}</p> -->

				<p>邀请码：{{ invitationCode }}</p>
				<p>请将二维码和邀请码发送给应邀人员，进行门店绑定</p>
			</div>
		</el-dialog>

		<shop-edit v-if="hasBtnPermission('bms:shop:edit')" ref="modalForm" @saveSuccess="handleAddOrEditSave" />

		<el-dialog
			title="新增邀请"
			:visible.sync="invitationDialog"
			:close-on-click-modal="false"
			top="50px"
			width="40%"
		>
			<el-form
				ref="invitationForm"
				:model="invitationForm"
				:rules="invitationRules"
				label-width="80px"
				:inline="false"
			>
				<el-form-item label="受邀人数" prop="numCount">
					<!-- <el-input type="number" v-model="invitationForm.numCount" placeholder="受邀人数" class="inputWidth" min="1"></el-input> -->
					<el-input-number
						v-model="invitationForm.numCount"
						size="small"
						type="number"
						:min="1"
						:step="1"
						step-strictly
					/>
				</el-form-item>
				<el-form-item label="失效时间" prop="endDate">
					<el-date-picker
						v-model="invitationForm.endDate"
						:picker-options="pickerOptions"
						value-format="yyyy-MM-dd HH:mm:ss"
						type="datetime"
						placeholder="失效时间"
						class="inputWidth"
					/>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onInvitationSubmit('invitationForm')">新增</el-button>
					<el-button @click="invitationDialog = false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<add-edit-dialog ref="clerkModalForm" @saveSuccess="handleAddOrEditSave" />
	</div>
</template>

<script>
import {
	invitation,
	getWxCode,
	shopList,
	shopEnabled,
	shopImport,
	shopTemplateDownload,
	getQRCodes,
	getQRCode
} from "api/business/selfManager/shop.js"
import { listMixin } from "views/mixin/listMixin"
import { permissionMixin } from "views/mixin/permissionMixin"
import VueQr from "vue-qr"
import ShopEdit from "./edit.vue"
import { getToken } from "api/auth"
import { blobTofile } from "@/utils/utils"

// import { organitionTree } from "api/basePage/organition"
// eslint-disable-next-line no-unused-vars
import { clerkList, getDetailShop, deleteClerk } from "api/business/selfManager/clerk"
import organizationTreeSelect from "views/bussiness/components/organizationTreeSelect"
export default {
	name: "ShopIndex",
	mixins: [listMixin, permissionMixin],
	components: {
		ShopEdit,
		VueQr,
		"add-edit-dialog": () => import("../clerk/addEdit.vue"),
		organizationTreeSelect
	},
	props: {},
	data() {
		return {
			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 66
				},
				{
					prop: "name",
					label: "名称",
					width: 150
				},
				{
					prop: "number",
					label: "店铺编号",
					width: 150
				},
				{
					prop: "gsModel",
					label: "油号",
					slot: true,
					width: 150
				},
				{
					prop: "shopManagerName",
					label: "站长",
					width: 110
				},
				{
					prop: "shopManagerMobile",
					label: "站长联系",
					width: 110
				},
				{
					prop: "creationDate",
					label: "创建日期"
				},
				{
					prop: "enabled",
					label: "是否启用",
					slot: true,
					width: 70
				}
			],
			queryParam: {
				name: "",
				organizId: ""
			},
			tableDataFunc: parameter => {
				return shopList(Object.assign(parameter, this.queryParam)).then(res => {
					res.data.list = res.data.list.map(e => {
						if (e.gsModel) e.gsModelArr = e.gsModel.split(";")

						// 增加员工表格
						e.clerkData = []
						e.loading = false
						return e
					})
					return res.data
				})
			},

			addDialog: false,

			// qrcodeText: "",
			// https://sxyadmin.mevikycloud.com
			devMiniQrcodeText: "https://tmini.mevikycloud.com/shopReg",
			proMiniQrcodeText: "https://sxymini.mevikycloud.com/shopReg",
			h5QrcodeText: "https://th5.mevikycloud.com/pages/shop/register/index", // 测试环境
			h5QrcodeTextProd: "https://h5.mevikycloud.com/pages/shop/register/index", // 正式环境
			invitationPath: "mini", // 默认是小程序入口
			wxcode: "",
			invitationCode: "",

			invitationDialog: false,
			invitationForm: {
				numCount: 10,
				endDate: ""
			},
			invitationRules: {
				numCount: [
					{
						required: true,
						message: "受邀人数",
						trigger: "blur"
					}
				],
				endDate: [
					{
						required: true,
						message: "失效时间",
						trigger: "change"
					}
				]
			},

			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < new Date().setTime(new Date().getTime() - 3600 * 1000 * 24)
				}
			},

			mode: process.env.NODE_ENV,
			uploadHeaders: { "X-Token": getToken() },
			selectList: []
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.$refs.mvkTable.refresh()
			},
			deep: true
		}
	},
	computed: {
		qrcodeText() {
			let code = ""
			if (this.invitationCode) code = "?shopReg=" + this.invitationCode
			if (this.invitationPath == "mini") {
				return (this.mode === "production" ? this.proMiniQrcodeText : this.devMiniQrcodeText) + code
			} else {
				return this.h5QrcodeText + code
			}
		}
	},
	methods: {
		handleSelectionChange(val) {
			this.selectList = val
		},
		handleInvitate(value) {
			this.invitationPath = value
			this.invitationDialog = true
		},
		handleAddClerk() {
			this.$refs.clerkModalForm.add()
		},
		onInvitationSubmit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					invitation(this.invitationForm).then(res => {
						this.invitationCode = res.data
						this.invitationDialog = false
						this.addDialog = true
					})
				} else {
					return false
				}
			})
		},

		getInvitationWxcode() {
			getWxCode().then(res => {
				this.wxcode = res.data
			})
		},

		changeEnabled(row) {
			if (!row.enabled) {
				this.$prompt(`请手动录入关停店铺名称:${row.name}`, "店铺启停", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputType: "text",
					inputValidator: value => {
						if (!value) {
							return "输入不能为空"
						} else if (value !== row.name) {
							return "输入的店铺名称不正确"
						}
					},
					roundButton: true
				})
					.then(() => {
						this.changeShopEnabled(row.id)
					})
					.catch(() => {
						row.enabled = true
					})
			} else {
				this.changeShopEnabled(row.id)
			}
		},

		changeShopEnabled(id) {
			shopEnabled(id).then(() => {
				this.$notify.success({
					title: "成功",
					message: "启停成功"
				})
			})
		},

		handleAddOrEditSave() {
			this.$refs.mvkTable.refresh()
		},

		handleDetail(row) {
			this.$refs.modalForm.edit(row)
			this.$refs.modalForm.title = "详情"
		},
		// 导入店铺
		handleUpload(param) {
			const formData = new FormData()
			formData.append("file", param.file)
			let loading = this.$loading({
				lock: true,
				text: "上传中，请稍候...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)"
			})
			shopImport(formData, this.$route.query.id)
				.then(res => {
					loading.close()
					if (res.status === "OK") {
						this.$notify.success({
							title: "成功",
							message: "导入成功"
						})
					}
					this.$refs.mvkTable.refresh()
				})
				.catch(() => {
					loading.close()
				})
		},

		// 导入模板下载
		toDownloadTemplate() {
			shopTemplateDownload().then(r => {
				blobTofile(r, "店铺导入模版", "application/vnd.ms-excel")
			})
		},
		// 批量下载店铺二维码
		getQRCodesForIds() {
			const ids = this.selectList.map(e => e.id).join(",")
			getQRCodes(ids).then(r => {
				blobTofile(r, "店铺二维码集", "application/zip")
			})
		},
		// 下载单个店铺二维码
		getQRCodeForOne(row) {
			getQRCode(row.id).then(r => {
				blobTofile(r, row.name + "店铺.png", "application/x-png")
			})
		},
		handledClerk(row) {
			this.$set(row, "loading", true)
			const index = this.$refs.mvkTable.tableData.findIndex(e => e.id === row.id)
			clerkList({ shopId: row.id, pageSize: 999 })
				.then(res => {
					const data = res.data.content
					this.$refs.mvkTable.tableData[index].clerkData = data
				})
				.finally(() => {
					this.$set(row, "loading", false)
				})
		},
		handleClerkDelete(res) {
			// const propsRow = res["props-row"]
			// console.log(res, propsRow)
			// getDetailShop({
			// 	userId: res.row.userId,
			// 	grade: res.row.grade,
			// 	pageNum: 0,
			// 	pageSize: 100
			// }).then(r => {
			// 	const list = r.data.content
			// 	const item = list.find(e =>  e.shopName == propsRow.name)
			deleteClerk(res.id).then(resolve => {
				if (resolve.status === "OK") {
					this.$notify.success({
						title: "删除成功"
					})
					this.$refs.mvkTable.refresh()
				}
			})
			// })
		}
	},
	created() {},
	mounted() {
		if (this.mode == "production") {
			this.h5QrcodeText = this.h5QrcodeTextProd
		}
		this.getInvitationWxcode()

		// this.loadCategoryList()
		// this.echoDataSet()
	}
}
</script>
<style lang="scss" scoped>
p + p {
	margin-top: 15px;
}
::v-deep .expanded .el-table__expanded-cell[class*="cell"] {
	padding: 0 !important;
}
::v-deep .el-select-dropdown__item {
	padding: 0 !important;
}
</style>

<style>
.tree-select .el-select-dropdown__item {
	background-color: #fff;
	padding: 0 8px;
}
</style>
