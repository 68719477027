import fetch from "@/axios/fetch"

export function orderList(data) { // 订单列表
	return fetch({
		url: "/order/search",
		method: "post",
		data
	})
}
export function orderDetails(id) { // 订单详情
	return fetch({
		url: "/order/" + id,
		method: "get"
	})
}

export function orderSend(data) { // 订单发货
	return fetch({
		url: "/order/ship",
		method: "put",
		data
	})
}
export function orderSendUp(data) { // 订单快递单号修改
	return fetch({
		url: "/order/couier/up",
		method: "put",
		data
	})
}
export function batchShip(data) { // 订单批量发货
	return fetch({
		url: "/order/batchShip",
		method: "put",
		data
	})
}

export function orderRemark(data) { // 订单备注
	return fetch({
		url: "/order/remarks",
		method: "put",
		data
	})
}

export function agreeOrderRefund(id) { // 订单退款
	return fetch({
		url: "/order/agree/refund/" + id,
		method: "put"
	})
}

export function refuseOrderRefund(id, reason) { // 拒绝订单退款
	return fetch({
		url: "/order/refuse/refund/" + id,
		method: "put",
		data: reason
	})
}

export function getOrderExport(data) { // 订单导出
	return fetch({
		url: "/order/export-order",
		method: "post",
		data,
		responseType: "blob"
	})
}

export function getOrderExcelExport(data) { // 发货订单导出
	return fetch({
		url: "/order/export",
		method: "post",
		data,
		responseType: "blob"
	})
}

export function getOrderExcelImport(data) { // 发货订单导入
	return fetch({
		url: "/order/upload-ship",
		method: "put",
		data
	})
}

export function virtualOrderList(data) { // 虚拟订单列表
	return fetch({
		url: "/order/search/virtual",
		method: "post",
		data
	})
}
export function orderByRefund(params) { // 主动退款
	return fetch({
		url: "/order/refund/" + params.id,
		method: "PUT"
	})
}