import fetch from "@/axios/fetch"

export function invitation(data) { // 邀请新增
	return fetch({
		url: "/shop/invitation",
		method: "post",
		data
	})
}

export function getWxCode() { // 获取邀请微信二维码
	return fetch({
		url: "/shop/wxCode",
		method: "get"
	})
}

export function shopList(params) { // 店铺列表
	return fetch({
		url: "/shop",
		method: "get",
		params
	})
}

export function shopInfo(id) { // 店铺详情
	return fetch({
		url: "/shop/" + id,
		method: "get"
	})
}

export function shopEnabled(id) { // 店铺启停
	return fetch({
		url: "/shop/enabled/" + id,
		method: "put"
	})
}

export function shopEdit(data) { // 店铺修改
	return fetch({
		url: "/shop",
		method: "put",
		data
	})
}
export function shopImport(data) { // 店铺修改
	return fetch({
		url: "/shop/upload",
		method: "put",
		data
	})
}
export function shopTemplateDownload() { // 店铺列表
	return fetch({
		url: "/shop/template/download",
		method: "get",
		responseType: "arraybuffer"
	})
}
export function getQRCodes(ids) { // 批量下载大客户专属店二维码
	return fetch({
		url: "/shop/getQRCodes",
		method: "get",
		params: { ids },
		responseType: "arraybuffer"
	})
}
export function getQRCode(id) { // 下载单个店铺二维码
	return fetch({
		url: `/shop/${id}/getQRCode`,
		method: "get",
		responseType: "arraybuffer"
	})
}