<template>
	<div>
		<div>
			<el-radio-group v-model="queryParam.status">
				<el-radio-button label="">全部</el-radio-button>
				<el-radio-button :label="1">未使用</el-radio-button>
				<el-radio-button :label="0">已使用</el-radio-button>
				<el-radio-button :label="-1">已过期</el-radio-button>
			</el-radio-group>
		</div>
		<div class="flex mt10">
			<div class="filter-box">
				<span>券面值</span>
				<el-input v-model="queryParam.faceValue" v-number-input.float="0" placeholder="可输入券面值查询" clearable
					class="ml10 w200" maxlength="20"
				/>
			</div>
			<div class="filter-box">
				<span>电话</span>
				<el-input v-model="queryParam.memberMobile" v-number-input.float="0" placeholder="可输入电话查询" clearable
					class="ml10 w200" maxlength="20"
				/>
			</div>
			<div class="filter-box">
				<span>派发时间</span>
				<el-date-picker v-model="timeVal" :default-time="['00:00:00', '23:59:59']"
					value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd" size="small" type="daterange"
					start-placeholder="开始时间" end-placeholder="结束时间" placement="bottom-end" style="width: 240px"
					class="ml10" @change="onchangeTime"
				/>
			</div>
			<el-button type="primary" @click="handleExport()"> 导出报表</el-button>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<template slot="orderCode" slot-scope="{ row }" label="订单">
				<!-- <div v-for="(code,index) in row._orderCode" :key="index">
					<router-link :to="{ path: '/selfManager/order', query: {code} }" class="order-link">{{ code }}</router-link>
				</div> -->
				<router-link v-if="row._orderCode.length"
					:to="{ path: '/selfManager/order', query: { code: row._orderCode.join() } }" class="order-link"
				>
					{{
						row._orderCode.length
					}}个订单
				</router-link>
				<span v-else> - </span>
			</template>
			<template slot="status" slot-scope="{ row }" label="使用状态">
				<span :style="{ color: row._color }">{{ row._statusName }}</span>
			</template>
			<!-- <template slot="termOfValidity" slot-scope="{ row }" label="有效期">
				<span>{{ row.termOfValidity == 1 ? '无限期' : row.termOfValidity }}</span>
			</template> -->
		</mvk-table>
	</div>
</template>

<script>
import { distributeState } from "@/utils/constants"
import { distributeRecordsList, majorShopCouponUseExport } from "api/business/selfManager/keyAccount"
import { blobTofile } from "@/utils/utils"

export default {
	name: "",
	data() {
		return {
			distributeState,
			timeVal: [],
			queryParam: {
				distributeStartTime: "",
				distributeEndTime: "",
				status: "",
				memberMobile: "",
				faceValue: ""
			},
			tableColumn: [
				{ prop: "memberName", label: "姓名", width: 86 },
				{ prop: "memberMobile", label: "电话", width: 100 },
				{ prop: "orderCode", label: "订单", width: 96, slot: true, showOverflowTooltip: false, align: "center" },
				{ prop: "couponName", label: "券名称" },
				{
					prop: "faceValue", label: "券面值", width: 86, align: "center", formatter: value => {
						return value.faceValue ? (value.faceValue || 0) + "元" : "-"
					}
				},
				{
					prop: "actualMoney", label: "实际支付", width: 86, align: "center", formatter: value => {
						return value.actualMoney ? (value.actualMoney || 0) + "元" : ""
					}
				},
				{ prop: "distributeDate", label: "派发时间", width: 140 },
				{ prop: "_termOfValidity", label: "有效期" },
				{ prop: "status", label: "使用状态", width: 86, slot: true, align: "center" }
				// { prop: "memberMobile", label: "备注" }
			],

			tableDataFunc: async parameter => {
				if (this.$route.query.id) {
					return distributeRecordsList(Object.assign(parameter, this.queryParam), this.$route.query.id).then(res => {
						res.data.list = res.data.list.map(e => {
							const obj = distributeState.find(el => e.status !== null && el.value !== null && (el.value == e.status))
							e._statusName = obj?.label || ""
							e._color = obj?.color || ""
							e._orderCode = e.orderCode ? e.orderCode.split(",") : []
							if (e.termOfValidity == 1) {
								e._termOfValidity = "无限期"
							} else if (e.termOfValidity) {
								e._termOfValidity = e.termOfValidity.split(",").join(" 到 ")
							}
							return e
						})
						return res.data
					})
				} else {
					return {}
				}
			}
		}
	},
	watch: {
		queryParam: {
			handler() {
				this.$refs.mvkTable.refresh()
			},
			deep: true
		}
	},
	methods: {
		getList() {
			this.$refs.mvkTable.refresh()
		},
		onchangeTime(e) {
			this.timeVal = e
			this.queryParam.distributeStartTime = e ? e[0] : ""
			this.queryParam.distributeEndTime = e ? e[1] : ""
		},
		handleExport() {
			majorShopCouponUseExport(this.$route.query.id, this.queryParam).then(res => {
				blobTofile(res, "使用记录.xls", "application/vnd.ms-excel")
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.filter-box {
	display: flex;
	align-items: center;
	margin-right: 10px;

	span {
		font-size: 14px;
		color: #333;
	}
}

.order-link {
	color: #4B5EFF;
}
</style>