import Cookies from "js-cookie"

const TokenKey = "eshop-Token"

const RefreshTokenKey = "eshop-RToken"

export function getToken() {
	return localStorage.getItem(TokenKey)
}

export function setToken(token) {
	return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
	return localStorage.removeItem(TokenKey)
}	

export function getRefreshToken() {
	return localStorage.getItem(RefreshTokenKey)
}

export function setRefreshToken(token) {
	return localStorage.setItem(RefreshTokenKey, token)
}

export function removeRefreshToken() {
	return localStorage.removeItem(RefreshTokenKey)
}

export function removeAllSessionItem() {
	Object.keys(localStorage).forEach(key => {
		if (key.indexOf("eshop") > -1) {
			localStorage.removeItem(key)
		}
	})
	return true
}

const expiresAtKey = "Expiration"

export function getExpires() {
	return Cookies.get(expiresAtKey)
}

export function setExpires(expiresAt, expires) {
	return Cookies.set(expiresAtKey, expiresAt, { expires: expires })
}

export function removeExpires() {
	return Cookies.remove(expiresAtKey)
}
