<template>
    <el-drawer :title="title" :visible.sync="addEditDialog" size="40%">
        <el-form
            ref="formValidate"
            v-loading="loading"
            :model="formValidate"
            :rules="Rules"
            label-width="150px"
            class="demo-ruleForm"
        >
            <el-form-item label="bean名称" prop="beanName">
                <el-input
                    v-model="formValidate.beanName"
                    placeholder="spring bean名称，如：testTask"
                    class="inputWidth"
                    :disabled="formValidate.jobId != ''"
                />
            </el-form-item>
            <el-form-item label="方法名称" prop="methodName">
                <el-input
                    v-model="formValidate.methodName"
                    placeholder="方法名称"
                    class="inputWidth"
                    :disabled="formValidate.jobId != ''"
                />
            </el-form-item>
            <el-form-item label="参数" prop="params">
                <el-input v-model="formValidate.params" placeholder="参数" class="inputWidth" />
            </el-form-item>
            <el-form-item label="cron表达式" prop="cronExpression">
                <el-input v-model="formValidate.cronExpression" placeholder="如： 0 0 12**?" class="inputWidth" />
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                    v-model="formValidate.remark"
                    type="textarea"
                    :rows="2"
                    placeholder="备注"
                    class="inputWidth"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" round @click="submitForm('formValidate')">确 定</el-button>
                <el-button round @click="resetForm('formValidate')">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
import { AddSchedule, EditSchedule } from "api/basePage/schedule"

const obj = {
    jobId: "",
    beanName: "",
    methodName: "",
    params: "",
    cronExpression: "",
    remark: "",
    status: 1
}

export default {
    name: "GradeAddEdit",
    components: {},
    data() {
        return {
            title: "新增",
            addEditDialog: false,
            formValidate: Object.assign({}, obj),
            Rules: {
                beanName: [{ required: true, message: "请输入spring bean名称", trigger: "blur" }],
                methodName: [{ required: true, message: "请输入方法名称", trigger: "blur" }]
            },
            loading: false
        }
    },
    computed: {},
    methods: {
        add() {
            this.addEditDialog = true
            this.$nextTick(() => {
                this.$refs["formValidate"].resetFields()
            })
        },
        edit(record) {
            this.addEditDialog = true
            this.$nextTick(() => {
                this.formValidate = JSON.parse(JSON.stringify(record))
            })
        },

        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.loading = true
                    this.formValidate.jobId
                        ? EditSchedule(this.formValidate)
                            .then(() => {
                                this.$notify.success({
                                    title: "成功",
                                    message: "编辑成功"
                                })
                                this.loading = false
                                this.addEditDialog = false
                                this.formValidate = Object.assign({}, obj)
                                this.$emit("saveSuccess")
                            })
                            .catch(() => {
                                this.loading = false
                            })
                        : AddSchedule(this.formValidate)
                            .then(() => {
                                this.loading = false
                                this.$notify.success({
                                    title: "成功",
                                    message: "添加成功"
                                })
                                this.addEditDialog = false
                                this.$emit("saveSuccess")
                            })
                            .catch(() => {
                                this.loading = false
                            })
                } else {
                    return false
                }
            })
        },

        resetForm(formName) {
            this.addEditDialog = false
            this.$refs[formName].resetFields()
        }
    },
    mounted() {}
}
</script>

<style lang="scss" scoped></style>
