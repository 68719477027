var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mvk-layout-content-card"},[_c('div',{staticClass:"options flex flex-sb"},[_c('div',[_c('el-button',{attrs:{"size":"small","type":"primary","round":""},on:{"click":function($event){return _vm.handleEdit('add')}}},[_vm._v(" 新增通用用品店 ")])],1),_c('div',[_c('el-input',{attrs:{"placeholder":"请输入店铺名称查找","prefix-icon":"el-icon-search","clearable":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.queryParam.name),callback:function ($$v) {_vm.$set(_vm.queryParam, "name", $$v)},expression:"queryParam.name"}})],1)]),_c('mvk-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.switchLoading),expression:"switchLoading"}],ref:"mvkTable",staticClass:"mt20",attrs:{"table-column":_vm.tableColumn,"table-data-func":_vm.tableDataFunc},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"productNum",fn:function({ row }){return [(row.productNum)?_c('router-link',{staticClass:"link",attrs:{"to":{
					path: '/marketingManager/labour/labourEdit/detail',
					query: { id: row.id, activeName: 'goods' }
				}}},[_vm._v(" "+_vm._s(row.productNum)+"个 ")]):_c('span',[_vm._v(" - ")])]}},{key:"memberNum",fn:function({ row }){return [(row.memberNum)?_c('router-link',{staticClass:"link",attrs:{"to":{
					path: '/marketingManager/labour/labourEdit/detail',
					query: { id: row.id, activeName: 'member' }
				}}},[_vm._v(" "+_vm._s(row.memberNum)+"人 ")]):_c('span',[_vm._v(" - ")])]}},{key:"enabled",fn:function({ row }){return [_c('el-switch',{attrs:{"active-color":"#4F8AFF","inactive-color":"#ccc"},on:{"change":function($event){return _vm.changeSwitch(row)}},model:{value:(row.enabled),callback:function ($$v) {_vm.$set(row, "enabled", $$v)},expression:"row.enabled"}})]}}])},[_c('el-table-column',{attrs:{"slot":"head","type":"selection","width":"38"},slot:"head"}),_c('el-table-column',{attrs:{"label":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleEdit('edit', row)}}},[_vm._v(" 编辑 ")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleEdit('detail', row)}}},[_vm._v(" 详情 ")]),_c('el-popover',{attrs:{"trigger":"hover"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('el-button',{staticClass:"ml10",attrs:{"type":"text"},on:{"click":function($event){return _vm.handleExport(row)}}},[_vm._v("二维码")])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"flex-column flex-a-c"},[_c('div',{staticClass:"qr_title"},[_vm._v(_vm._s(row.name))]),_c('div',{staticClass:"qr_desc"},[_vm._v("微信扫描二维码进入专属店铺")]),_c('vue-qr',{ref:"Qrcode",attrs:{"text":'https://sxymini.mevikycloud.com/labor?shopId=' + row.id,"qid":"testQrId","margin":10,"size":160}})],1)]},proxy:true}],null,true)})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }