import { render, staticRenderFns } from "./InputUploadImage.vue?vue&type=template&id=12600d00&scoped=true"
import script from "./InputUploadImage.vue?vue&type=script&lang=js"
export * from "./InputUploadImage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12600d00",
  null
  
)

export default component.exports