import { mapState, mapActions } from "vuex"
import { debounce } from "utils/utils"

export const commonMixin = {
	data() {
		return {

		}
	},
	computed: {
		...mapState("dict", ["shoplist", "supplierlist"])
	},
	methods: {
		...mapActions("dict", ["getShopList", "getSupplierList"]),

		searchList() {
			this.$refs.mvkTable.refresh()
		}
	},
	watch: {
		queryParam: {
			// 增加防抖
			handler: debounce(function() {
				this.searchList()
			}, 300),
			deep: true
		}
	},
	mounted() {
		if (this.shoplist.length == 0) {
			this.getShopList()
		}

		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}
	}
}
