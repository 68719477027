<template>
	<div class="mvk-layout-content-card">
		<el-steps :active="currentTab" align-center finish-status="success">
			<el-step title="商品信息" />
			<el-step title="商品详情" />
			<el-step title="其他设置" />
		</el-steps>
		<el-form
			ref="formValidate"
			v-loading="fullscreenLoading"
			class="formValidate mt15"
			:rules="ruleValidate"
			:model="formValidate"
			label-width="126px"
			@submit.native.prevent
		>
			<el-row v-show="currentTab === 0" :gutter="24">
				<!-- 商品信息-->
				<el-col v-bind="grid2">
					<el-form-item label="商品名称：" prop="prodName">
						<el-input
							v-model="formValidate.prodName"
							maxlength="249"
							placeholder="请输入商品名称"
							:disabled="!supplyEdit && isDisabled"
						/>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2">
					<el-form-item label="商品分类：" prop="categoryId">
						<!-- <el-cascader v-model="formValidate.categoryId"
                            :options="prodCateList"
                            :props="props2"
                            clearable
                            class="width100"
                            :show-all-levels="false"
                            :disabled="isDisabled"/> -->
						<prod-category-tree v-model="formValidate.categoryId" :disabled="isDisabled" />
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2">
					<el-form-item label="商品关键字：">
						<el-input
							v-model="formValidate.keyword"
							placeholder="请输入商品关键字"
							:disabled="!supplyEdit && isDisabled"
						/>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2">
					<!-- <el-form-item label="商品单位：" prop="unit">
                        <mvk-input v-model.trim="formValidate.unit" placeholder="请输入单位" :disabled="isDisabled"/>
                    </el-form-item> -->

					<el-form-item label="所属供应商：" prop="supplierId">
						<el-select
							v-model="formValidate.supplierId"
							placeholder="所属供应商"
							clearable
							filterable
							:disabled="isDisabled"
							class="width100"
						>
							<el-option
								v-for="item in supplierlist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2">
					<el-form-item label="商品简介：">
						<el-input
							v-model="formValidate.brief"
							type="textarea"
							maxlength="250"
							:rows="3"
							placeholder="请输入商品简介"
							:disabled="!supplyEdit && isDisabled"
						/>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2">
					<el-form-item label="商品封面图：" prop="pic">
						<!-- <input-upload-image :imageBase64="formValidate.pic" @imageValue="getImageBase64" :disabled="isDisabled"></input-upload-image> -->

						<upload-image
							ref="imageModel"
							v-model="formValidate.pic"
							:image-url="formValidate.pic"
							:disabled="!supplyEdit && isDisabled"
							:preview="isDisabled"
							@imageValue="getImageBase64"
						/>
						<span class="cr_999 fs12">建议像素800*800，大小不超过2M</span>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="商品轮播图：" prop="sliderImages">
						<!-- <upload-drag-image :imageArray = "formValidate.sliderImages" :disabled="isDisabled"></upload-drag-image> -->

						<upload-image
							ref="imageDragModel"
							v-model="formValidate.sliderImages"
							:image-array="formValidate.sliderImages"
							:disabled="!supplyEdit && isDisabled"
							:draggable="true"
							:limit="10"
						/>
						<span class="cr_999 fs12">建议像素800*800，大小不超过2M</span>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="配送方式：" prop="deliveryMode">
						<el-radio-group v-model="formValidate.deliveryMode" :disabled="!supplyEdit && isDisabled">
							<el-radio :label="0">邮寄</el-radio>
							<el-radio :label="1">自提</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="起购数量：" props="minimumPurchasing">
						<el-input-number
							v-model="formValidate.minimumPurchasing"
							:disabled="!supplyEdit && isDisabled"
							size="small"
							type="number"
							:min="0"
							:step="1"
						/>

						<span class="ml20 cr_999">设置为0则不限制</span>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item v-show="formValidate.deliveryMode == 0" label="运费模板：" prop="deliveryTemplateId">
						<el-select
							v-model="formValidate.deliveryTemplateId"
							placeholder="请选择"
							class="mr20"
							:disabled="!supplyEdit && isDisabled"
						>
							<el-option
								v-for="item in shippingList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col v-show="formValidate.deliveryMode == 0" :span="12">
					<el-form-item label="增减步长：" props="minimumPurchasing">
						<el-input-number
							v-model="formValidate.stepNum"
							:disabled="!supplyEdit && isDisabled"
							size="small"
							type="number"
							:min="1"
							:step="1"
						/>
						<span class="ml20 cr_999" @click="formValidate.minimumPurchasing = formValidate.stepNum || 1">
							购买数量依步数增加
						</span>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2" :span="12">
					<el-form-item
						v-show="formValidate.deliveryMode == 0"
						label="不配送区域："
						prop="deliveryTemplateId"
					>
						<el-select
							v-model="selectArea"
							:disabled="!supplyEdit && isDisabled"
							multiple
							placeholder="请选择区域"
							style="width: 100%"
							clearable
							@change="changeArea"
							@visible-change="getAreaList"
						>
							<el-option
								v-for="item in areaList"
								:key="item.code"
								:label="item.name"
								:value="item.name"
							/>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2" :span="12">
					<el-form-item label="商品标签：" props="labels">
						<el-select v-model="formValidate.labels" multiple placeholder="请选择标签" @change="changeLabel($event)">
							<el-option v-for="item in labelOptions" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
						<span class="ml20 cr_999">* 标注标签</span>
					</el-form-item>
				</el-col>
				<el-col v-show="formValidate.deliveryMode !== 0" :span="12">
					<el-form-item label="增减步长：" props="minimumPurchasing">
						<el-input-number
							v-model="formValidate.stepNum"
							:disabled="!supplyEdit && isDisabled"
							size="small"
							type="number"
							:min="1"
							:step="1"
						/>
						<span class="ml20 cr_999" @click="formValidate.minimumPurchasing = formValidate.stepNum || 1">
							购买数量依步数增加
						</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="24">
				<el-col v-bind="grid2">
					<el-form-item label="商品税率：" prop="taxRate" :rules="formValidate.taxRate || formValidate.categories ? ruleValidate.taxRate : []">
						<el-input-number v-show="!isDisabled" v-model="formValidate.taxRate" :min="0" :max=".5" :step="0.01" label="商品税率" @change="checkRate" />
						<span class=" cr_999" :class="{ml20: !isDisabled }" >税率{{ Math.floor(formValidate.taxRate * 100) }}%</span>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid2">
					<el-form-item label="商品分类编码：" prop="categories" :rules="formValidate.taxRate || formValidate.categories ? ruleValidate.categories :[] ">
						<el-input
							v-model.number="formValidate.categories"
							type="text"
							:readonly="isDisabled "
							placeholder="商品分类编码"
						/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col v-bind="grid2">
					<el-form-item label="单位：" props="labels">
						<el-input
							v-model="formValidate.unit"
							type="text"
							:readonly="isDisabled"
							placeholder="货物单位"
							clearable
							style="width:120px"
						/>
						<span class=" cr_999 fs12" :class="{ml20: !isDisabled }" > 例如：克/斤/个/颗/箱/瓶</span>
					</el-form-item>
				</el-col>
				<el-col v-bind="grid">
					<el-form-item label="编码名称：" prop="categoriesName" >
						<el-input
							v-model="formValidate.categoriesName"
							type="text"
							:readonly="isDisabled"
							placeholder="商品税收分类名称"
						/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-show="currentTab === 0" :gutter="24">
				<el-col v-bind="grid2">
					<el-form-item label="商品规格：" props="singleProp">
						<el-radio-group v-model="formValidate.singleProp" :disabled="isDisabled || edit">
							<el-radio :label="true" class="radio">单规格</el-radio>
							<el-radio :label="false">多规格</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<!-- 多规格添加-->
				<el-col v-if="!formValidate.singleProp" :span="24" class="noForm">
					<el-form-item
						label="选择规格："
						prop="checkAttr"
						:rules="[{ required: true, message: '选择规格', trigger: 'blur' }]"
					>
						<div v-show="!isDisabled && !$route.params.id">
							<el-select 
							v-model="selectAttr"
							:disabled="isDisabled"
							:loading="prodListLoading"
							remote
							filterable
							:remote-method="getProdList"
							>
								<el-option
									v-for="item in prodList"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								/>
							</el-select>
							<el-button type="primary" class="ml8" :disabled="!selectAttr" @click="addAtrr">
								确定
							</el-button>
							<el-button  type="success" class="ml8" @click="handlAddProp">添加规格</el-button>
							<span class="ml20 cr_999">* 例如：尺寸、颜色、重量</span>
						</div>
						<div v-for="(item, index) in mulAttr" :key="item.attrName" :class="{ 'mt10': !isDisabled && !$route.params.id }">
							<p>
								{{ item.attrName }}
								<i
									v-show="!isDisabled && !$route.params.id"
									class="el-icon-circle-close ml8"
									style="cursor: pointer"
									@click="deleteAtrr(index)"
								/>
							</p>
							<tag-add
								v-model="item.skuValue"
								:show-add-btn="!isDisabled && !$route.params.id"
								@deleteTag="changeSkuValue"
								@addTag="changeSkuValue"
							/>
						</div>
					</el-form-item>
					<el-form-item />
				</el-col>
				<!--详情中的商品规格-->
				<el-col  v-if="edit || copyFlag" >
					<el-form-item label="商品属性：">
						<el-table :data="formValidate.skus" border size="mini">
							<!--动态表列-->
							<el-table-column
								v-for="(item, iii) in dynamicColumns"
								:key="iii"
								align="center"
								:label="dynamicColumns[iii].title"
								min-width="80"
							>
								<template slot-scope="scope">
									{{ scope.row[iii] }}
								</template>
							</el-table-column>

							<el-table-column key="pic" align="center" label="图片" min-width="80">
								<template slot-scope="scope">
									<div class="flex flex-center">
										<upload-image
											:ref="'uploadImage' + scope.$index"
											v-model="scope.row.pic"
											:image-url="scope.row.pic"
											:disabled="isDisabled"
										/>
									</div>
								</template>
							</el-table-column>
							<el-table-column
								v-for="item in fixedColumn"
								:key="item.prop"
								:label="item.label"
								:prop="item.prop"
								align="center"
								min-width="156"
							>
								<template slot-scope="scope">
									<template v-if="item.label === '库存'">
										<el-input-number
											v-model="scope.row[item.prop]"
											size="small"
											type="number"
											:min="0"
											:step="1"
											:disabled="isDisabled"
										/>
									</template>
									<span v-else>{{ scope.row[item.prop] }}</span>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-col>

				<!--编辑中的商品规格-->
				<el-col v-else :span="24">
					<!-- 单规格表格-->
					<el-form-item v-if="formValidate.singleProp" key="20" label="商品属性：">
						<el-table :data="formValidate.singleSkus" border size="mini">
							<el-table-column align="center" label="图片" min-width="80">
								<template slot-scope="scope">
									<el-form-item
										:rules="[{ required: true, message: '请上传图片', trigger: 'change' }]"
										:prop="'singleSkus.' + scope.$index + '.pic'"
										class="mb0"
									>
										<div class="flex flex-center">
											<upload-image
												:ref="'uploadImage' + scope.$index"
												v-model="scope.row.pic"
												:image-url="scope.row.pic"
												:disabled="isDisabled"
											/>
										</div>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column
								v-for="item in fixedColumn"
								:key="item.prop"
								:label="item.label"
								:prop="item.prop"
								align="center"
								min-width="156"
							>
								<template slot-scope="scope">
									<template
										v-if="
											item.label === '售价' ||
												item.label === '成本价' ||
												item.label === '市场价' ||
												item.label === '内购价'
										"
									>
										<el-form-item
											:rules="[
												{ required: true, message: '请输入' + item.label, trigger: 'blur' },
												{ validator: validatorPrice }
											]"
											:prop="'singleSkus.' + scope.$index + '.' + item.prop"
										>
											<el-input-number
												v-model="scope.row[item.prop]"
												size="small"
												type="number"
												:min="0.01"
												:precision="2"
												:step="0.01"
												:disabled="isDisabled"
											/>
										</el-form-item>
									</template>
									<template v-else-if="item.label === '库存'">
										<el-form-item
											:rules="[
												{ required: true, message: '请输入' + item.label, trigger: 'blur' },
												{ validator: stockValid }
											]"
											:prop="'singleSkus.' + scope.$index + '.' + item.prop"
										>
											<el-input-number
												v-model="scope.row[item.prop]"
												size="small"
												type="number"
												:min="0"
												:step="1"
												:disabled="isDisabled"
											/>
										</el-form-item>
									</template>
									<template v-else-if="item.label === 'SKU名称'">
										<el-form-item
											:rules="ruleValidate.skuName"
											:prop="'singleSkus.' + scope.$index + '.' + item.prop"
										>
											<el-input
												v-model="scope.row[item.prop]"
												type="textarea"
												:disabled="isDisabled"
											/>
										</el-form-item>
									</template>
									<template v-else>
										<el-form-item class="mb0">
											<el-input
												v-model="scope.row[item.prop]"
												type="number"
												min="0"
												class="priceBox"
												:disabled="isDisabled"
											/>
										</el-form-item>
									</template>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
					<!--多规格表格-->
					<el-form-item v-else key="21" label="商品属性：" prop="skuValue">
						<el-table :data="formValidate.skuValue" border size="mini">
							<!--动态表列-->
							<el-table-column
								v-for="(item, iii) in dynamicColumns"
								:key="iii"
								align="center"
								:label="dynamicColumns[iii].title"
								min-width="80"
							>
								<template slot-scope="scope">
									{{ scope.row[iii] }}
								</template>
							</el-table-column>

							<el-table-column key="pic" align="center" label="图片" min-width="80">
								<template slot-scope="scope">
									<el-form-item
										:key="`skuValue.${scope.row.tableKey}.pic`"
										:rules="ruleValidate.pic"
										:prop="`skuValue.${scope.$index}.pic`"
										class="mb0"
									>
										<div class="flex flex-center">
											<upload-image
												:ref="'uploadImage' + scope.$index"
												v-model="scope.row.pic"
												:image-url="scope.row.pic"
												:disabled="isDisabled"
											/>
										</div>
									</el-form-item>
								</template>
							</el-table-column>
							<!--固定表列-->
							<el-table-column
								v-for="item in fixedColumn"
								:key="item.prop"
								:label="item.label"
								:prop="item.prop"
								align="center"
								min-width="156"
							>
								<template slot-scope="scope">
									<template
										v-if="
											item.label === '售价' ||
												item.label === '成本价' ||
												item.label === '市场价' ||
												item.label === '内购价'
										"
									>
										<el-form-item
											:key="'skuValue.' + scope.row.tableKey + '.' + item.prop"
											:rules="[
												{ required: true, message: '请输入' + item.label, trigger: 'blur' },
												{ validator: validatorPrice }
											]"
											:prop="'skuValue.' + scope.$index + '.' + item.prop"
										>
											<el-input-number
												v-model="scope.row[item.prop]"
												size="small"
												type="number"
												:min="0.01"
												:precision="2"
												:step="0.01"
												:disabled="isDisabled"
												step-strictly
											/>
										</el-form-item>
									</template>
									<template v-else-if="item.label === '库存'">
										<el-form-item
											:key="'skuValue.' + scope.row.tableKey + '.' + item.prop"
											:rules="[
												{ required: true, message: '请输入' + item.label, trigger: 'change' },
												{ validator: stockValid, trigger: 'change' }
											]"
											:prop="'skuValue.' + scope.$index + '.' + item.prop"
										>
											<el-input-number
												v-model="scope.row[item.prop]"
												size="small"
												type="number"
												:min="0"
												:step="1"
												:disabled="isDisabled"
											/>
										</el-form-item>
									</template>
									<template v-else-if="item.label === 'SKU名称'">
										<el-form-item
											:key="'skuValue.' + scope.row.tableKey + '.' + item.prop"
											:rules="ruleValidate.skuName"
											:prop="'skuValue.' + scope.$index + '.' + item.prop"
										>
											<mvk-input
												v-model="scope.row[item.prop]"
												type="textarea"
												:disabled="isDisabled"
											/>
										</el-form-item>
									</template>
									<template v-else>
										<el-form-item class="mb0">
											<el-input
												v-model="scope.row[item.prop]"
												type="number"
												class="priceBox"
												min="0"
												:disabled="isDisabled"
											/>
										</el-form-item>
									</template>
								</template>
							</el-table-column>
							<el-table-column v-if="!isDisabled" key="15" align="center" label="操作" min-width="80">
								<template slot-scope="scope">
									<el-button type="text" class="submission" @click="delAttrTable(scope.$index)">
										删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-col>
			</el-row>
			<!-- 商品详情-->
			<el-row v-show="currentTab === 1">
				<el-col :span="24">
					<el-form-item label="商品详情：">
						<div
							v-if="$route.params.id && !supplyEdit && $route.params.isDisabled"
							style="height: 600px; overflow-y: auto"
							v-html="formValidate.content"
						/>
						<!-- <ueditor-from v-else v-model="formValidate.content" :content="formValidate.content" /> -->
						<WangEditor
							v-else
							style="width: 100%"
							:content="formValidate.content"
							@editorContent="getEditorContent"
						/>
					</el-form-item>
				</el-col>
			</el-row>
			<!-- 其他设置-->
			<el-row v-show="currentTab === 2">
				<el-col :span="24">
					<el-col v-bind="grid">
						<el-form-item label="排序：">
							<el-input-number
								v-model="formValidate.orderNum"
								:max="9999"
								placeholder="请输入排序"
								:disabled="!supplyEdit && isDisabled"
							/>
						</el-form-item>
					</el-col>
					<!-- <el-col v-bind="grid"  v-has="'pms:product:virtual_sales'">
						<el-form-item label="虚拟销量：">
							<el-input-number
								v-model="formValidate.virtualSales"
								placeholder="请输入排序"
								:disabled="!supplyEdit && isDisabled"
							/>
						</el-form-item>
					</el-col> -->
				</el-col>
				<el-col :span="24">
					<el-form-item label="商品推荐：">
						<el-checkbox-group
							v-model="checkboxGroup"
							size="small"
							:disabled="!supplyEdit && isDisabled"
							@change="onChangeGroup"
						>
							<el-checkbox
								v-for="(item, index) in recommend"
								:key="index"
								:disabled="item.disabled"
								:label="item.value"
							>
								{{ item.name }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item style="position: relative; z-index: 1001">
				<el-popover
					v-if="!isDisabled"
					v-model="editVisible"
					placement="top"
					width="160"
				>
					<p class="fs12">这是编辑状态并未保存，确定关闭吗？</p>
					<div style="text-align: right; margin: 10px 0 0 0">
						<el-button size="mini" type="text" @click="editVisible = false">取消</el-button>
						<el-button type="primary" size="mini" @click="$router.go(-1)">确定</el-button>
					</div>
					<el-button slot="reference" class="mr20">关闭</el-button>
				</el-popover>
				<el-button v-else class="mr20" @click="$router.go(-1)">关闭</el-button>
				<el-button v-show="currentTab > 0" @click="handleSubmitUp">上一步</el-button>
				<el-button v-show="currentTab < 2" type="primary" @click="handleSubmitNest('formValidate')">
					下一步
				</el-button>
				<template v-if="supplyEdit || !$route.params.isDisabled">
					<el-button
						v-show="currentTab == 2 || $route.params.id"
						type="primary"
						@click="handleSubmit('formValidate')"
					>
						提交
					</el-button>
				</template>
			</el-form-item>
		</el-form>
		<prop-add ref="propAddRef" @saveSuccess="getProdList" />
	</div>
</template>

<script>
import { proCategoryList } from "api/business/selfManager/category.js"
import { prodList } from "api/business/selfManager/prod.js"
import { createProd, editProd, prodDetail } from "api/business/selfManager/goods.js"
import {  getLabelList as getlabelApi  } from "api/business/selfManager/label.js"

import PropAdd from "../../prod/addEdit.vue"
const defaultObj = {
	id: "",
	prodName: "", // 商品名称
	categoryId: "", // 商品分类id
	keyword: "", // 商品关键字
	unit: "", // 商品单位
	brief: "", // 商品简介
	pic: "", // 商品封面图
	imgs: [], // 商品轮播图
	deliveryMode: 0, // 配送方式
	deliveryTemplateId: 0, // 运费模板
	singleProp: true, // 商品规格：true：单规格，false：多规格
	minimumPurchasing: 0, // 商品最低起购件数 ,0为不限制
	labels: [], // 标签
	stepNum: 1,
	restrictedAreas: "",
	skuValue: [],
	// 单规格
	singleSkus: [
		{
			id: "",
			pic: "", // sku图片
			sellingPrice: 999, // 售价
			costPrice: 999, // 成本价
			marketPrice: 999, // 市场价
			internalPrice: 999, // 内购价
			actualStocks: 0, // 库存
			properties: "",
			prodName: "",
			skuName: "", // 商品sku
			weight: 0, // 商品重量
			volume: 0, // 商品体积
			status: 1 // 状态：1上架，0下架
		}
	],

	skus: [],
	deleteBySku: [],

	content: "", // 详细内容（富文本）
	orderNum: 0, // 排序
	// virtualSales: 0, // 销量
	hot: false, // 是否热门
	selected: false, // 是否精选
	special: false,
	propJson: "", // mulAttr转字符串存后台

	sliderImages: [],

	supplierId: "",
	checkAttr: [],

	taxRate: "",
	categories: "",
	categoriesName:''
}

const proOptions = [
	{ name: "是否热卖", value: "hot" },
	{ name: "是否精选", value: "selected" },
	{ name: "特价推荐", value: "special" }
]

import UploadImage from "views/bussiness/components/UploadImage"
import ProdCategoryTree from "views/bussiness/components/ProdCategoryTree"
import TagAdd from "@/components/TagAdd"
import { getAreaToParent } from "api/basePage/base"
import { step1Method } from "./step1.js"

// import UeditorFrom from "@/components/UeditorFrom"
import WangEditor from "@/components/wangEditor/index.vue"

import { isJson } from "@/utils/utils"

import { mapState, mapActions } from "vuex"

export default {
	name: "CreateProduct",
	components: {
		UploadImage,
		TagAdd,
		// UeditorFrom,
		ProdCategoryTree,
		WangEditor,
		PropAdd
	},
	mixins: [step1Method],
	computed: {
		...mapState("dict", ["supplierlist"]),
		skuValue() {
			const obj = Object.assign({}, defaultObj.skuValue[0])
			delete obj.pic
			return obj
		},

		// 动态表格列，根据所选规格动态变化
		dynamicColumns() {
			let temp = {}
			this.mulAttr.forEach((item, index) => {
				temp["value" + index] = { title: item.attrName }
			})

			return temp
		}
	},
	data() {
		return {
			currentTab: 0,
			fullscreenLoading: false,
			editVisible: false,
			grid: { xl: 8, lg: 8, md: 12, sm: 24, xs: 24 },
			grid2: { xl: 10, lg: 12, md: 12, sm: 24, xs: 24 },
			formValidate: JSON.parse(JSON.stringify(defaultObj)),
			ruleValidate: {
				prodName: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
				categoryId: [{ required: true, message: "请选择商品分类", trigger: "change" }],
				keyword: [{ required: true, message: "请输入商品关键字", trigger: "blur" }],
				unit: [{ required: true, message: "请输入商品单位", trigger: "blur" }],
				brief: [{ required: true, message: "请输入商品简介", trigger: "blur" }],
				deliveryMode: [{ required: true, message: "请选择配送方式", trigger: "change" }],
				taxRate: [{ required: true, message: "请输入税率", trigger: "change" }],
				categories: [{ required: true, message: "请输入商品分类编码", trigger: "change" }],
				// deliveryTemplateId: [
				//     { required: true, message: '请选择运费模板', trigger: 'change' }
				// ],
				pic: [{ required: true, message: "请上传商品图", trigger: "change" }],
				sliderImages: [{ required: true, message: "请上传商品轮播图", type: "array", trigger: "change" }],
				skuName: [{ required: true, message: "请输入SKU名称", trigger: "change" }],
				supplierId: [{ required: true, message: "请选择供应商", trigger: "change" }]
			},
			fixedColumn: [
				{
					prop: "sellingPrice",
					label: "售价"
				},
				{
					prop: "costPrice",
					label: "成本价"
				},
				{
					prop: "marketPrice",
					label: "市场价"
				},
				{
					prop: "internalPrice",
					label: "内购价"
				},
				{
					prop: "actualStocks",
					label: "库存"
				},
				{
					prop: "skuName",
					label: "SKU名称"
				},
				{
					prop: "weight",
					label: "重量(KG)"
				},
				{
					prop: "volume",
					label: "体积(m³)"
				}
			],

			stockValid: (rule, value, callback) => {
				if (value == 0) {
					callback(new Error("库存不能为0"))
					// this.$notify.warning({
					//     title:'提示',
					//     message:'库存不能为0'
					// })
				} else {
					callback()
				}
			},

			shippingList: [
				{
					value: 0,
					label: "全国包邮"
				},
				{
					value: 1,
					label: "包邮"
				},
				{
					value: 2,
					label: "部分地区包邮"
				}
			],
			prodCateList: [],
			props2: {
				children: "child",
				label: "name",
				value: "id",
				multiple: false,
				emitPath: false
			},

			prodList: [], // 商品规格
			prodListLoading: false, // 获取规格动画


			checkboxGroup: [],
			recommend: proOptions,

			isDisabled: false, // 查看详情
			edit: false, // 普通编辑
			supplyEdit: false, // 供应商商品编辑

			detailFirstFlag: false,

			selectAttr: "", // 多规格时选择的规格
			mulAttr: [],

			areaList: [], // 限购地区列表
			selectArea: [], // 地区选择项
			copyFlag: false,

			// 分类
			labelOptions: []
			
		}
	},
	watch: {},
	created() {
	},
	mounted() {
		if (this.$route.params.id) {
			// this.setTagsViewTitle("商品编辑")
			this.getDetailInfo()
			// this.edit = true

			if (this.$route.query.copyFlag) {
				this.setTagsViewTitle("商品复制")
				this.edit = true
				this.copyFlag = true
			} else {
				this.setTagsViewTitle("商品编辑")
				this.edit = true
				this.copyFlag = false
			}
		} else { 
			this.dataReStore()
		}
		
		if (this.$route.params.isDisabled) {
			this.isDisabled = true
			this.setTagsViewTitle("商品详情")
		}

		if (this.$route.params.supplyEdit) {
			this.setTagsViewTitle("商品编辑")
			this.supplyEdit = true
		}

		// this.getCategory()
		this.getProdList()

		if (this.supplierlist.length == 0) {
			this.getSupplierList()
		}

		this.getLabelList()
	},
	methods: {
		...mapActions("dict", ["getSupplierList"]),
		// 获取中国省份
		getAreaList(open) {
			if (!open && this.areaList.length > 0) return
			getAreaToParent().then(r => {
				try {
					this.areaList = r.data
				} catch (error) {
					console.log("获取地区失败", error)
				}
			})
		},
		// 改变不配送区域
		changeArea(val) {
			this.formValidate.restrictedAreas = val.join()
		},
		validatorPrice(rule, value, callback) {
			// 带参props分割
			const strObjProToArr = rule.field.split(".")
			// 获取下标(多规格)
			const index = Number(strObjProToArr[1])
			// 输入框对应对象字段
			const inputName = strObjProToArr[2]
			let item = null
			// 清除错误信息
			const clearErrorText = () => {
				const sellingPrice = `${strObjProToArr[0]}.${strObjProToArr[1]}.sellingPrice`
				const costPrice = `${strObjProToArr[0]}.${strObjProToArr[1]}.costPrice`
				this.$refs.formValidate.clearValidate(sellingPrice)
				this.$refs.formValidate.clearValidate(costPrice)
			}
			// 只针对售价和成本价
			if (inputName === "costPrice" || inputName === "sellingPrice") {
				if (this.formValidate.singleProp) {
					// 如果单规格
					item = this.formValidate.singleSkus[0]
					if (item.sellingPrice < item.costPrice) {
						callback(new Error("售价不可低于成本价"))
					} else {
						clearErrorText()
						callback()
					}
				} else {
					// 多规格
					item = this.formValidate.skuValue[index]
					if (item.sellingPrice < item.costPrice) {
						callback(new Error("售价不可低于成本价"))
					} else {
						clearErrorText()
						callback()
					}
				}
			} else {
				callback()
			}
		},
		getCategory() {
			proCategoryList().then(res => {
				this.prodCateList = res.data.list
			})
		},
		// 获取规格列表 + 搜索
		getProdList(value) {
			const params = {
				pageNum: 0,
				pageSize: 10,
				keyWord: value || ''
			}
			prodList(params).then(res => {
				this.prodList = res.data.list
			})
		},
		// 远程搜素规格列表
		remoteMethodGetProdList(){
			// console.log('规格');
			prodList({
				pageNum: 0,
				pageSize: 30,

			}).then(res => {
				this.prodList = res.data.list
			})
		},
		checkRate(val) { 
			this.$nextTick(() => { 
				this.formValidate.taxRate = Math.trunc(val * 100) / 100
			})
		},
		setTagsViewTitle(title) {
			const route = Object.assign({}, this.$route, { title: `${title} - ${this.$route.params.id}` })
			this.$store.dispatch("tagsView/updateVisitedView", route)
		},

		getDetailInfo() {
			this.fullscreenLoading = true

			prodDetail(this.$route.params.id, {
				deleted: this.$route.params.enabled
			}).then(res => {
				let temp = JSON.parse(JSON.stringify(defaultObj))
				this.formValidate = Object.assign(temp, res.data)

				// 复制途径进来的
				if (this.$route.query.copyFlag) {
					this.formValidate.id = ""
					this.formValidate.status = ""

					this.formValidate.skus.forEach(item => {
						item.id = ""
					})
				}

				if (this.formValidate.singleProp) {
					// 单规格处理
					this.$set(this.formValidate, "checkAttr", [])

					this.formValidate.singleSkus = this.formValidate.skus

					this.formValidate.skuValue = JSON.parse(JSON.stringify(defaultObj.skuValue))
				} else {
					// 多规格处理
					this.detailFirstFlag = true
					if (this.formValidate.propJson != "" && this.formValidate.propJson != "[]") {
						let propJsonArr = JSON.parse(this.formValidate.propJson)
						let tmp = []
						propJsonArr.forEach(item => {
							tmp.push(JSON.parse(item))
						})

						this.$set(this, "mulAttr", tmp)
						this.$set(
							this.formValidate,
							"checkAttr",
							this.mulAttr.map(item => item.attrId)
						)
					} else {
						this.$set(this, "mulAttr", [])
						this.$set(this.formValidate, "checkAttr", [])
					}

					this.formValidate.skus.forEach(itemJson => {
						if (itemJson.properties != "") {
							itemJson.properties = JSON.parse(itemJson.properties)

							Object.values(itemJson.properties).forEach((v, i) => {
								itemJson["value" + i] = v
							})
						}
					})

					this.formValidate.singleSkus = JSON.parse(JSON.stringify(defaultObj.singleSkus))

					this.formValidate.skuValue = this.formValidate.skus
				}
				if (!this.formValidate.stepNum) this.formValidate.stepNum = 1
				this.$set(this.formValidate, "sliderImages", JSON.parse(this.formValidate.imgs))

				if (this.formValidate.hot) {
					this.checkboxGroup.push("hot")
				}
				if (this.formValidate.selected) {
					this.checkboxGroup.push("selected")
				}
				if (this.formValidate.special) {
					this.checkboxGroup.push("special")
				}

				if (this.formValidate.restrictedAreas) {
					this.selectArea = this.formValidate.restrictedAreas.split(",")
				}
				this.formValidate.deleteBySku = this.formValidate.skus.map(item => item.id)

				this.fullscreenLoading = false
			})
		},

		handleSubmitUp() {
			if (this.currentTab-- < 0) this.currentTab = 0
		},
		handleSubmitNest(name) {
			// if (this.formValidate.taxRate || this.formValidate.categories) { 
			// 	this.$refs[name].rules.taxRate[0].required = true
			// 	this.$refs[name].rules.categories[0].required = true
			// }
			const nextStep = () => {
				if (this.currentTab++ > 2) this.currentTab = 0
			}
			if (!this.isDisabled) {
				// 如果是浏览则不进行校验
				this.$refs[name].validate(valid => {
					if (valid) {
						nextStep()
					} else {
						console.log("验证不通过")
					}
				})
			} else {
				nextStep()
			}
		},
		handleSubmit(name) {
			let vm = this
			this.$refs[name].validate(valid => {
				if (valid) {
					if (this.formValidate.singleProp) {
						// 单规格处理

						this.formValidate.skus = this.formValidate.singleSkus
						this.formValidate.propJson = "[]"
					} else {
						// 多规格处理
						this.formValidate.skus = this.formValidate.skuValue

						let temp = []
						this.mulAttr.forEach(item => {
							temp.push(JSON.stringify(item))
						})
						this.formValidate.propJson = JSON.stringify(temp)
					}

					this.formValidate.imgs = JSON.stringify(this.formValidate.sliderImages)

					this.formValidate.skus.forEach(itemJson => {
						if (isJson(itemJson.properties)) {
							itemJson.properties = JSON.stringify(itemJson.properties)
						}
					})

					let skuIds = this.formValidate.skus.map(item => item.id)
					skuIds.forEach(skuid => {
						let findIndex = vm.formValidate.deleteBySku.findIndex(item => item === skuid)

						if (findIndex > -1) {
							vm.formValidate.deleteBySku.splice(findIndex, 1)
						}
					})

					let prodName = this.formValidate.prodName
					this.formValidate.skus.forEach(item => {
						item.prodName = prodName
					})

					this.fullscreenLoading = true
					if (this.formValidate.id) {
						editProd(this.formValidate)
							.then(async() => {
								// this.dataReStore()
								this.$notify.success("编辑成功")
								// this.$router.push({ path: "/selfManager/goodManager" })
								this.$router.go(-1)
								this.fullscreenLoading = false
							})
							.catch(() => {
								this.fullscreenLoading = false
							})
					} else {
						createProd(this.formValidate)
							.then(async() => {
								// this.dataReStore()
								this.$notify.success("新增成功")
								// this.$router.push({ path: "/selfManager/goodManager" })
								this.$router.go(-1)

								this.fullscreenLoading = false
							})
							.catch(() => {
								this.fullscreenLoading = false
							})
					}
				} else {
					return false
				}
			})
		},
		// 重置数据
		dataReStore() {
			this.formValidate = JSON.parse(JSON.stringify(defaultObj))
		},
		onChangeGroup() {
			this.checkboxGroup.includes("hot") ? (this.formValidate.hot = true) : (this.formValidate.hot = false)
			this.checkboxGroup.includes("selected")
				? (this.formValidate.selected = true)
				: (this.formValidate.selected = false)
			this.checkboxGroup.includes("special")
				? (this.formValidate.special = true)
				: (this.formValidate.special = false)
		},

		openPicModel() {
			this.$refs.imageModel.open()
		},

		handlAddProp() {
			this.$refs.propAddRef.add()
		},
		getEditorContent(data) {
			this.formValidate.content = data
		},
		changeLabel(val) {
			console.log("val", val)
		},

		getLabelList() {
			getlabelApi({ pageSize: 1000, pageNum: 0 }).then(res => {
				if (res.status === "OK") {
					this.labelOptions = res.data.list.filter(e => e.enabled)
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.pictrue {
	width: 40px;
	height: 40px;
	border: 1px dotted rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	background: rgba(0, 0, 0, 0.02);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	img {
		width: 100%;
		height: 100%;
	}
}

.mb0 {
	margin-bottom: 0;
}
</style>
