<template>
	<div class="mvk-layout-content-card">
		<!-- 电子券筛选 -->
		<el-form size="small" label-width="100px">
			<el-collapse-transition>
				<div v-show="showfilter">
					<div class="filter flex-row flex-wrap">
						<el-form-item label="券名称" class="width100">
							<el-input v-model="queryParam.title" placeholder="请输入券名称" size="small" clearable />
						</el-form-item>
						<!-- <el-form-item label="券类型别名" class="width100">
							<el-input v-model="queryParam.alias" placeholder="请输入券名称" size="small" clearable />
						</el-form-item> -->
						<el-form-item label="券使用类型" class="width100">
							<el-select
								v-model="queryParam.bizType"
								placeholder="电子券类型"
								clearable
								filterable
								style="width: 100%"
							>
								<el-option
									v-for="(val, key, i) in voucherCodeList"
									:key="i"
									:label="val"
									:value="key"
								/>
							</el-select>
						</el-form-item>
						<el-form-item label="券类型" class="width100">
							<el-select
								v-model="queryParam.couCategory"
								placeholder="券类型"
								clearable
								filterable
								style="width: 100%"
							>
								<el-option
									v-for="(val, key, i) in voucherTypeList"
									:key="i"
									:label="val"
									:value="key"
								/>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="券有效期类型" class="width100">
							<el-select v-model="queryParam.type" placeholder="券有效期类型" clearable filterable style="width: 100%">
								<el-option v-for="(val,key,i) in voucherValidType" :key="i" :label="val" :value="key" />
							</el-select>
						</el-form-item> -->
						<!-- <el-form-item label="面额金额" class="width100">
							<el-input v-model="queryParam.code" placeholder="请输入面额金额" size="small" clearable style="width:140px" />
						</el-form-item>
						<el-form-item label="所属门店：" class="width100">
							<el-select v-model="queryParam.shopId" placeholder="所属门店" clearable filterable>
								<el-option v-for="item in []" :key="item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item> -->
						<el-form-item label="券有效期：" style="width: auto">
							<div class="flex">
								<el-select
									v-model="queryParam.validType"
									placeholder="类型"
									clearable
									filterable
									style="min-width: 86px; width: 86px"
									@clear="clearValidDate"
								>
									<el-option
										v-for="(val, key, i) in voucherValidType"
										:key="i"
										:label="val"
										:value="key"
									/>
								</el-select>
								<el-input
									v-if="queryParam.validType == 2"
									v-model="queryParam.day"
									class="ml10"
									placeholder="请输入有效天数"
									size="small"
									clearable
								/>
								<div v-else>
									<el-date-picker
										v-model="queryParam.startDate"
										:disabled="!queryParam.validType"
										value-format="yyyy-MM-dd"
										size="small"
										type="date"
										placeholder="开始日期"
										class="ml10"
										style="width: 130px"
									/>
									<el-date-picker
										v-model="queryParam.endDate"
										:disabled="!queryParam.validType"
										value-format="yyyy-MM-dd"
										size="small"
										type="date"
										placeholder="结束日期"
										class="ml10"
										style="width: 130px"
									/>
								</div>
							</div>
						</el-form-item>
						<div class="mt10 ml10 flex flex-a-e">
							<el-button icon="el-icon-delete" @click="clearFilter()">重置</el-button>
							<!-- <el-button type="default" @click="test1()">静态页面进去详情</el-button> -->
							<el-button type="warning" icon="el-icon-refresh-right" @click="refreshEcoupon()">
								同步刷新
							</el-button>
							<!-- 测试 -->
							<!-- <el-button type="danger" size="small" @click="test()">测试专用</el-button> -->
						</div>
					</div>

					<!-- <el-button v-show="showfilter" class="mb20" type="primary" @click="switchFilter()">折叠筛选</el-button> -->
				</div>
			</el-collapse-transition>
			<div>
				<!-- <el-button class="mb20" type="primary" @click="switchFilter()">{{ !showfilter?'更多筛选':'折叠筛选' }}</el-button> -->
				<!-- <el-button class="mb20" type="primary" :disabled="selectList.length == 0" @click="openDelivers()">批量发货</el-button> -->
			</div>
		</el-form>
		<!-- 电子券列表 -->
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt20">
			<template slot="receiveTime" slot-scope="{ row }" label="领取时间">
				<span v-if="!row.isTime">不限时</span>
				<div v-else-if="row.isTime">
					{{ row.receiveTime.split(",")[0] }} - {{ row.receiveTime.split(",")[1] }}
				</div>
				<span v-else />
			</template>
			<template slot="termOfValidity" slot-scope="{ row }" label="使用时间">
				<span v-if="row.validityType == 0">{{ row.termOfValidity }} 天</span>
				<div v-else-if="row.validityType == 1">
					{{ row.termOfValidity.split(",")[0] }} - {{ row.termOfValidity.split(",")[1] }}
				</div>
				<span v-else>无限期</span>
			</template>
			<template slot="pushNum" slot-scope="{ row }">
				<span v-if="!row.isLimit">不限量</span>
				<div v-else>
					<span class="fa">发布：{{ row.pushNum }}</span>
					<span class="sheng">剩余：{{ row.surplusNum == null ? row.pushNum : row.surplusNum }}</span>
				</div>
			</template>
			<el-table-column label="操作" width="210">
				<template slot-scope="{ row }">
					<!-- <router-link :to=" { path: '/marketingManager/coupon/listAdd/' + row.id } " v-has="'sms:coupon:detail'">
                        <el-button type="text" size="small" v-if="row.status">复制</el-button>
                    </router-link> -->
					<el-button type="text" size="small" @click="handleDetail(row.alias)">详情</el-button>
					<el-button type="text" size="small" @click="handleRule(row)">编辑发放规则</el-button>
					<el-button v-if="env == 'development' || env == 'alpha'" type="text" size="small"  @click="handleApplyNum(row)">申请批次号</el-button>
				</template>
			</el-table-column>
		</mvk-table>

		<!-- 申请批次号 -->
		<el-dialog title="申请批次号" :visible.sync="showDialog" width="520px">
			<el-form ref="batchForm" size="small" label-width="100px" :rules="batchRule" :model="batchParams">
				<el-form-item label="批次名称" prop="applyName">
					<el-input
						v-model="batchParams.applyName"
						placeholder="劵名称+日期年月日+自定义批次"
						size="small"
						clearable
					/>
				</el-form-item>
				<el-form-item label="券类型别名" prop="ecouponTypeAlis">
					{{ batchParams.ecouponTypeAlis }}
					<!-- <el-input
						v-model="batchParams.ecouponTypeAlis"
						placeholder="根据列表前端带参，填入券类型别名。 例如：paichbwsy"
						size="small"
						clearable
					/> -->
				</el-form-item>
				<!-- <el-form-item label="使用类型" prop="bizType">
					<el-select v-model="batchParams.bizType" placeholder="请选择使用类型" style="width:280px;">
						<el-option v-for="(val, key, i) in voucherCodeList" :key="i" :label="val" :value="key" />
					</el-select>
				</el-form-item> -->
				<el-form-item label="数量" prop="applyNum">
					<!-- <el-input
						v-model="batchParams.applyNum"
						type="number"
						:min="1"
						:max="20"
						placeholder="数量上限20，一次仅可申请20张代金券"
						size="small"
						clearable
						@blur="maxInput"
					/> -->
					<mvk-input-num
						v-model="batchParams.applyNum"
						:min="1"
						:max="20"
						placeholder="数量上限20，一次仅可申请20张代金券"
						size="small"
						clearable
						style="width: 280px"
					/>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button
					@click="showDialog = false;resetInput('batchParams')"
				>取 消</el-button>
				<el-button type="primary" :loading="loadding" @click="submitBatch()">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 编辑发放规则 -->
		<rulesDrawerBoxVue
			ref="ruleBox"
			@success="() => {$refs.mvkTable.refresh()}"
		/>
	</div>
</template>

<script>
import { voucherCodeList, voucherTypeList, dateTimeList, voucherValidType } from "@/utils/constants"
import { queryEcouponTypeList, flushCouponType, getEcouponList } from "api/business/marketing/coupon"
import { debounce } from "utils/utils"
import rulesDrawerBoxVue from "./components/rulesDrawerBox.vue"
export default {
	components: { rulesDrawerBoxVue },
	data() {
		return {
			env: process.env.NODE_ENV,
			showfilter: true,
			dateList: dateTimeList,
			voucherCodeList,
			voucherTypeList,
			voucherValidType,
			timeVal: [],

			// 查询条件参数
			queryParam: {
				title: "",
				alias: "",
				bizType: "",
				couCategory: "",
				validType: "1",
				startDate: "",
				endDate: "",
				day: ""
			},

			tableColumn: [
				{
					prop: "id",
					label: "ID",
					width: 76
				},
				{
					prop: "title",
					label: "劵名称"
				},
				{
					prop: "alias",
					label: "券类型别名"
				},
				{
					prop: "couCategory",
					label: "券类型",
					formatter: row => {
						return voucherTypeList[row.couCategory]
					}
				},
				{
					prop: "bizTypeName",
					label: "使用类型",
					width: 86,
					align: "center"
				},
				{
					prop: "faceValue",
					label: "面值金额"
				},
				{
					prop: "couDiscountValue",
					label: "折扣率"
				},
				{
					prop: "couDiscountMaxValue",
					label: "最大折扣金额",
					width: 120,
					align: "center"
				},
				{
					prop: "cnt",
					label: "库存数量"
				},
				{
					prop: "declares",
					label: "券使用说明",
					width: "160",
					vHtml: true
				},
				{
					prop: "bizTypeDesc",
					label: "使用类型描述",
					minWidth: 120
				},
				{
					prop: "validDate",
					label: "劵有效期",
					width: 170,
					formatter: cellValue => {
						if (cellValue.validType == 1) {
							// 固定
							return cellValue.validDate.replace("#", " - ")
						} else if (cellValue.validType == 2) {
							// 动态
							return cellValue.validDate + "天"
						}
					}
				}
			],

			// eslint-disable-next-line no-unused-vars
			tableDataFunc: parameter => {
				const param = JSON.parse(JSON.stringify(this.queryParam))
				if (param.validType == 2 && !param.day) param.validType = ""
				return queryEcouponTypeList(Object.assign(parameter, param)).then(res => {
					return res.data
				})
				// return new Promise(resolve => {
				// 	return resolve()
				// })
			},

			// 申请批次号
			showDialog: false,
			batchParams: {
				applyName: "",
				ecouponTypeAlis: "",
				bizType: "",
				applyNum: ""
			},
			batchRule: {
				applyName: [
					{
						required: true,
						message: "请输入批次名称",
						trigger: "blur"
					}
				],
				ecouponTypeAlis: [
					{
						required: true,
						message: "请输入券类型别名",
						trigger: "blur"
					}
				],
				// bizType: [
				// 	{
				// 		required: true,
				// 		message: "使用类型不能为空",
				// 		trigger: "change"
				// 	}
				// ],
				applyNum: [
					{
						required: true,
						message: "请输入申请数量",
						trigger: "change"
					}
				]
			},
			loadding: false
		}
	},
	computed: {
		bindQueryParam: function () {
			return JSON.stringify(this.queryParam)
		}
	},
	watch: {
		bindQueryParam: {
			// 增加防抖
			// handler: debounce(function(oldval, newVal) {
			// 	console.log("oldval,newVal", JSON.parse(JSON.stringify(oldval)), JSON.parse(JSON.stringify(newVal)))
			// 	this.$refs.mvkTable.refresh()
			// }, 300),
			// 增加防抖
			handler: debounce(function () {
				// console.log(newVal)
				// const newParams = JSON.parse(newVal)
				this.$refs.mvkTable.refresh()
			}, 300),
			deep: true
		}
	},
	created() { },
	methods: {
		// 折叠展开筛选
		switchFilter() {
			this.showfilter = !this.showfilter
		},
		clearFilter() {
			this.timeVal = []
			Object.keys(this.queryParam).forEach(key => (this.queryParam[key] = ""))
		},
		clearValidDate() {
			this.queryParam.day = ""
			this.queryParam.startDate = ""
			this.queryParam.endDate = ""
		},
		// 券有效期
		onchangeTime(e) {
			this.timeVal = e
			// this.dateLimit = e ? null : ""
			this.queryParam.startDate = e ? e[0] : ""
			this.queryParam.endDate = e ? e[1] : ""
		},

		// 详情
		handleDetail(alias) {
			this.$router.push("/marketingManager/voucher/detail?alias=" + alias)
		},
		// 编辑发放规则
		handleRule(row) {
			this.$refs.ruleBox.handleOpen(row)
		},
		// 打开批次申请窗口
		handleApplyNum(row) {
			this.batchParams.ecouponTypeAlis = row.alias
			this.batchParams.bizType = row.bizType
			this.showDialog = true
		},
		// 提交申请
		submitBatch() {
			this.$refs.batchForm.validate(valid => {
				if (valid) {
					this.loadding = true
					getEcouponList(this.batchParams)
						.then(res => {
							if (res.status === "OK") {
								this.$notify.success({
									title: "成功",
									message: "申请请求已经发送，请耐心等待！"
								})
								this.showDialog = false
								this.$refs.mvkTable.refresh()
							}
						})
						.finally(() => {
							this.loadding = false
						})
				}
			})
		},
		refreshEcoupon() {
			flushCouponType().then(r => {
				console.log("同步刷新", r)
				this.$refs.mvkTable.refresh()
			})
		},
		// 限制申请数量范围
		maxInput() {
			if (this.batchParams.applyNum < 1) {
				this.batchParams.applyNum = 1
			} else if (this.batchParams.applyNum > 20) {
				this.batchParams.applyNum = 20
			}
		},
		// 清除输入框
		resetInput(obj) {
			Object.keys(this[obj]).forEach(key => (this[obj][key] = ""))
		},
		// 测试
		test1() {
			this.$router.push("/marketingManager/voucher/detail?id=123")
		},
		// test
		test() {
			this.$refs.ruleBox.show = true
		}
	}
}
</script>

<style lang="scss" scoped>
.filter {
	margin-top: -14px;
}

.el-form-item--small.el-form-item {
	margin-top: 18px;
	margin-bottom: 0;
}

.filter .el-form-item {
	width: 320px;
	margin-right: 10px;
}
</style>
