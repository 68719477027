<template>
	<div class="mvk-layout-content-card">
		<div class="add-btn flex-row" style="justify-content: space-between">
			<el-button size="small" type="primary" round @click="handleAdd"> 新增节日活动 </el-button>
			<el-form ref="form" :model="queryParam" :inline="true">
				<el-form-item label="活动状态：">
					<el-select v-model="queryParam.status" placeholder="请选择" clearable>
						<el-option label="进行中" :value="1" />
						<el-option label="已结束" :value="2" />
						<el-option label="已失效" :value="3" />
					</el-select>
				</el-form-item>
				<el-form-item label="活动名称：">
					<el-input v-model="queryParam.name"> </el-input>
				</el-form-item>
			</el-form>
		</div>
		<mvk-table ref="mvkTable" :table-column="tableColumn" :table-data-func="tableDataFunc" class="mt15">
			<el-table-column label="操作" width="230" align="center" header-align="center">
				<template slot-scope="{ row }">
					<el-button type="text" size="small" @click="handleEdit(row)" v-if="row.status != 3">
						编辑
					</el-button>
					<el-button type="text" size="small" @click="handleDisabled(row.id)" v-if="row.status != 3">
						失效
					</el-button>
					<delete-button :id="row.id" button-type="text" size="small" @delete="handleDelete" />
				</template>
			</el-table-column>
		</mvk-table>
		<add-edit-dialog ref="modalForm" @saveSuccess="refresh" />
	</div>
</template>

<script>
import { getHolidayActivityList, deleteHolidayActivity, disabledHolidayActivity } from "api/business/marketing/holiday"
import { listMixin } from "views/mixin/listMixin"
export default {
	name: "HolidayActivityList",
	mixins: [listMixin],
	components: {
		"add-edit-dialog": () => import("./add.vue")
	},
	data() {
		return {
			// 查询条件参数
			queryParam: {
				name: "",
				status: ""
			},
			tableColumn: [
				{
					prop: "id",
					label: "活动ID"
				},
				{
					prop: "name",
					label: "活动名称"
				},
				{
					prop: "holidayName",
					label: "节日名称"
				},
				{
					prop: "issueDate",
					label: "活动时间"
				},
				{
					prop: "prizeType",
					label: "活动奖品",
					formatter: (value) => {
						let temp = {
							1: "优惠券",
							2: "积分",
							3: "优惠券+积分"
						}
						return temp[value.prizeType]
					}
				},
				{
					prop: "status",
					label: "活动状态",
					formatter: (value) => {
						let temp = {
							1: "进行中",
							2: "已结束",
							3: "已失效"
						}
						return temp[value.status]
					}
				},
				{
					prop: "dayLeft",
					label: "距离活动开始剩余（天）"
				}
			]
		}
	},
	computed: {
		tableDataFunc() {
			let params = JSON.parse(JSON.stringify(this.queryParam))
			return (parameter) => {
				return getHolidayActivityList(Object.assign(parameter, params)).then((res) => {
					return res.data
				})
			}
		}
	},

	mounted() {},

	methods: {
		refresh() {
			this.$refs.mvkTable.refresh()
		},

		handleDelete(data) {
			deleteHolidayActivity(data.id).then(() => {
				this.$notify.success({
					title: "删除成功"
				})
				this.$refs.mvkTable.refresh()
			})
		},

		handleDisabled(id) {
			disabledHolidayActivity(id).then(() => {
				this.$notify.success({
					title: "操作成功"
				})
				this.$refs.mvkTable.refresh()
			})
		}
	}
}
</script>

<style lang="scss" scoped></style>
